import { useEffect, useRef } from "react";
import { sleep } from "./utils";
const ResizablePanels = (props) => {
  const componentIsMounted = useRef(false);

  useEffect(() => {
    document.onmousemove = null;
    document.onmouseup = null;

    let xResizing = false;
    let xLastDown = 0;
    let xMove = 0;
    let xDrag = document.getElementById("dragX");
    let leftPanel = document.getElementById("panelLeft");
    let xInitialLeftWidth = 0;
    let xInitialRightWidth = 0;
    let leftWidthLast = null;
    let rightPanel = document.getElementById("panelRight");
    let lastRightResize = new Date();

    let yResizing = false;
    let yLastDown = 0;
    let yMove = 0;
    let yDrag = document.getElementById("dragY");
    let topPanel = document.getElementById("panelTop");
    let yInitialTopHeight = 0;
    let topHeightLast = null;
    let bottomPanel = document.getElementById("panelBottom");
    let lastTopResize = new Date();

    if (leftPanel && rightPanel) {
      let initialValue = props.leftPanelSizeId ? localStorage.getItem(props.leftPanelSizeId) : props.panel.leftWidth;
      if (initialValue) {
        leftPanel.style.width = `${initialValue}px`;
        rightPanel.style.width = `calc(100% - ${+initialValue + 7}px)`;
      } else {
        leftPanel.style.width = `${props.leftPanelSizeDefault}px`;
        rightPanel.style.width = `calc(100% - ${props.leftPanelSizeDefault + 7}px)`;
      }
      if (props.rightWidthOnChange) {
        props.rightWidthOnChange(+rightPanel.clientWidth);
      }
      if (props.leftWidthOnChange) {
        props.leftWidthOnChange(+leftPanel.clientWidth);
      }
    }

    if (topPanel && bottomPanel) {
      let initialValue = props.topPanelSizeId ? localStorage.getItem(props.topPanelSizeId) : props.panel.topHeight;
      if (initialValue) {
        topPanel.style.height = `${initialValue}px`;
      } else {
        topPanel.style.height = props.topPanelSizeDefault;
      }
      if (props.topHeightOnChange) {
        props.topHeightOnChange(+topPanel.clientHeight);
      }
      if (props.bottomWidthOnChange) {
        props.bottomWidthOnChange(+bottomPanel.clientWidth);
      }
    }
    if (xDrag) {
      xDrag.onmousedown = function (e) {
        if (xResizing) {
          return;
        }
        if (!leftPanel) {
          return;
        }
        xResizing = true;
        xLastDown = e.clientX;
        xMove = 0;
        leftWidthLast = null;
        xInitialLeftWidth = leftPanel.clientWidth;
        xInitialRightWidth = rightPanel.clientWidth;
      };
    }
    if (yDrag) {
      yDrag.onmousedown = function (e) {
        if (yResizing) {
          return;
        }
        if (!topPanel) {
          return;
        }
        yResizing = true;
        yLastDown = e.clientY;
        yMove = 0;
        topHeightLast = null;
        yInitialTopHeight = topPanel.clientHeight;
      };
    }

    document.onmousemove = (e) => {
      if (xResizing) {
        xMove = e.clientX - xLastDown;
        let leftWidthToBe = Math.max(0, xInitialLeftWidth + xMove);

        if (props.minLeft && leftWidthToBe < props.minLeft) {
          return;
        }

        let rightWidthToBe = Math.max(0, xInitialRightWidth - xMove);
        if (props.minRight && rightWidthToBe < props.minRight) {
          return;
        }

        leftWidthLast = leftWidthToBe;
        leftPanel.style.width = `${leftWidthLast}px`;
        rightPanel.style.width = `calc(100% - ${leftWidthLast + 7}px)`;
        let now = new Date();
        if ((props.rightWidthOnChange || props.leftWidthOnChange) && lastRightResize < now) {
          if (props.rightWidthOnChange) {
            props.rightWidthOnChange(+rightPanel.clientWidth);
          }
          if (props.leftWidthOnChange) {
            props.leftWidthOnChange(+leftPanel.clientWidth);
          }

          lastRightResize = now;
          lastRightResize.setMilliseconds(lastRightResize.getMilliseconds() + 100);
        }
      } else if (yResizing) {
        yMove = yLastDown - e.clientY;

        let topHeightToBe = Math.max(0, yInitialTopHeight - yMove);
        if (props.minTop && topHeightToBe < props.minTop) {
          return;
        }
        topHeightLast = topHeightToBe;
        topPanel.style.height = `${topHeightLast}px`;
        let now = new Date();
        if (props.topHeightOnChange && lastTopResize < now) {
          props.topHeightOnChange(+topPanel.clientHeight);
          lastTopResize = now;
          lastTopResize.setMilliseconds(lastTopResize.getMilliseconds() + 100);
        }
      }
    };

    document.onmouseup = (e) => {
      if (xResizing) {
        xResizing = false;
        if (leftWidthLast) {
          if (props.leftPanelSizeId) {
            localStorage.setItem(props.leftPanelSizeId, leftWidthLast);
          } else {
            props.panel.leftWidth = leftWidthLast;
            props.storePanel();
          }

          leftWidthLast = null;
          if (props.rightWidthOnChange) {
            props.rightWidthOnChange(+rightPanel.clientWidth);
          }
          if (props.leftWidthOnChange) {
            props.leftWidthOnChange(+leftPanel.clientWidth);
          }
        }
      } else if (yResizing) {
        yResizing = false;
        if (topHeightLast) {
          if (props.topPanelSizeId) {
            localStorage.setItem(props.topPanelSizeId, topHeightLast);
          } else {
            props.panel.topHeight = topHeightLast;
            props.storePanel();
          }

          topHeightLast = null;
          if (props.topHeightOnChange) {
            props.topHeightOnChange(+topPanel.clientHeight);
          }
        }
      }
    };

    return () => {
      document.onmousemove = null;
      document.onmouseup = null;
    };
  }, [props.leftPanelSizeId, props.topPanelSizeId, props.panel]);

  useEffect(() => {
    let xDrag = document.getElementById("dragX");
    let leftPanel = document.getElementById("panelLeft");
    let rightPanel = document.getElementById("panelRight");
    let bottomPanel = document.getElementById("panelBottom");
    let topPanel = document.getElementById("panelTop");
    let yDrag = document.getElementById("dragY");

    if (props.panelLeftExpanded) {
      xDrag.style.display = "none";
      rightPanel.style.display = "none";
      leftPanel.style.display = "block";
      leftPanel.style.width = "100%";

      yDrag.style.display = "none";
      bottomPanel.style.display = "none";
      topPanel.style.height = "calc(100vh - 100px)";
    } else if (props.panelRightExpanded) {
      xDrag.style.display = "none";
      leftPanel.style.display = "none";
      rightPanel.style.display = "block";
      rightPanel.style.width = "100%";

      yDrag.style.display = "none";
      bottomPanel.style.display = "none";
      topPanel.style.height = "calc(100vh - 100px)";
    } else if (props.panelBottomExpanded) {
      if (topPanel) {
        topPanel.style.display = "none";
        topPanel.style.height = 0;
      }
      if (leftPanel) {
        leftPanel.style.display = "none";
      }
      if (rightPanel) {
        rightPanel.style.display = "none";
      }
      yDrag.style.display = "none";
    } else if (props.panel.type === 22) {
      xDrag.style.display = "none";
      rightPanel.style.display = "none";
      leftPanel.style.display = "block";
      leftPanel.style.width = "100%";

      yDrag.style.display = "block";
      bottomPanel.style.display = "block";
      let initialValue = props.topPanelSizeId ? localStorage.getItem(props.topPanelSizeId) : props.panel.topHeight;
      if (initialValue) {
        topPanel.style.height = `${initialValue}px`;
      } else {
        topPanel.style.height = props.topPanelSizeDefault;
      }
    } else if (props.panel.type === 2) {
      xDrag.style.display = "inline-block";
      rightPanel.style.display = "inline-block";
      leftPanel.style.display = "inline-block";
      let initialLeftPanel = props.leftPanelSizeId
        ? localStorage.getItem(props.leftPanelSizeId)
        : props.panel.leftWidth;
      if (initialLeftPanel) {
        leftPanel.style.width = `${initialLeftPanel}px`;
        rightPanel.style.width = `calc(100% - ${+initialLeftPanel + 7}px)`;
      } else {
        leftPanel.style.width = `${props.leftPanelSizeDefault}px`;
        rightPanel.style.width = `calc(100% - ${props.leftPanelSizeDefault + 7}px)`;
      }

      topPanel.style.height = "calc(100vh - 100px)";
      yDrag.style.display = "none";
      bottomPanel.style.display = "none";
    } else {
      if (xDrag) {
        xDrag.style.display = "inline-block";
      }
      if (leftPanel) {
        leftPanel.style.display = "inline-block";
      }
      if (rightPanel) {
        rightPanel.style.display = "inline-block";
      }
      let initialLeftPanel = props.leftPanelSizeId
        ? localStorage.getItem(props.leftPanelSizeId)
        : props.panel.leftWidth;
      if (initialLeftPanel) {
        if (leftPanel && rightPanel) {
          leftPanel.style.width = `${initialLeftPanel}px`;
          rightPanel.style.width = `calc(100% - ${+initialLeftPanel + 7}px)`;
        }
      } else {
        if (leftPanel && rightPanel) {
          leftPanel.style.width = `${props.leftPanelSizeDefault}px`;
          rightPanel.style.width = `calc(100% - ${props.leftPanelSizeDefault + 7}px)`;
        }
      }

      let initialTopHeight = props.topPanelSizeId ? localStorage.getItem(props.topPanelSizeId) : props.panel.topHeight;
      yDrag.style.display = "block";
      bottomPanel.style.display = "block";
      if (topPanel) {
        if (initialTopHeight) {
          topPanel.style.height = `${initialTopHeight}px`;
        } else {
          topPanel.style.height = props.topPanelSizeDefault;
        }
      }
    }

    //Send callback size info
    if (props.topHeightOnChange) {
      props.topHeightOnChange(+topPanel.clientHeight);
    }
    if (props.bottomWidthOnChange && bottomPanel) {
      props.bottomWidthOnChange(+bottomPanel.clientWidth);
    }
    if (props.rightWidthOnChange && rightPanel) {
      props.rightWidthOnChange(+rightPanel.clientWidth);
    }
    if (props.leftWidthOnChange && leftPanel) {
      props.leftWidthOnChange(+leftPanel.clientWidth);
    }
  }, [props.panel, props.panelLeftExpanded, props.panelRightExpanded, props.panelBottomExpanded]);

  useEffect(() => {
    componentIsMounted.current = true;
    const sendSizeChange = (e) => {
      let rightPanel = document.getElementById("panelRight");
      let topPanel = document.getElementById("panelTop");
      let bottomPanel = document.getElementById("panelBottom");
      let leftPanel = document.getElementById("panelLeft");

      if (rightPanel) {
        if (props.rightWidthOnChange) {
          props.rightWidthOnChange(+rightPanel.clientWidth);
        }
        if (props.leftWidthOnChange) {
          props.leftWidthOnChange(+leftPanel.clientWidth);
        }
        if (props.topHeightOnChange && topPanel) {
          props.topHeightOnChange(+topPanel.clientHeight);
        }
        if (props.bottomWidthOnChange && bottomPanel) {
          props.bottomWidthOnChange(+bottomPanel.clientWidth);
        }
      }
    };

    window.addEventListener("resize", sendSizeChange);

    (async () => {
      let topPanel = document.getElementById("panelTop");
      let bottomPanel = document.getElementById("panelBottom");
      let lastTopPanelSize = topPanel.clientWidth;
      while (true) {
        if (!componentIsMounted.current) {
          break;
        }
        let currentTopPanelSize = topPanel.clientWidth;
        if (currentTopPanelSize !== lastTopPanelSize) {
          lastTopPanelSize = currentTopPanelSize;

          if (topPanel) {
            if (props.rightWidthOnChange) {
              let rightPanel = document.getElementById("panelRight");
              if (rightPanel) {
                props.rightWidthOnChange(+rightPanel.clientWidth);
              }
              let leftPanel = document.getElementById("panelLeft");
              if (props.leftWidthOnChange) {
                props.leftWidthOnChange(+leftPanel.clientWidth);
              }
            }
          }

          if (bottomPanel) {
            if (props.bottomWidthOnChange) {
              props.bottomWidthOnChange(+bottomPanel.clientWidth);
            }
          }
        }
        await sleep(500);
      }
    })();
    return () => {
      componentIsMounted.current = false;
      window.removeEventListener("resize", sendSizeChange);
    };
  }, []);
  return "";
};

export default ResizablePanels;
