import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlus,
  faPaperclip,
  faExclamationTriangle,
  faInfoCircle,
  faPlusCircle,
  faTimes,
  faHandPointRight,
  faSearchPlus,
  faStar,
  faArrowRight,
  faPuzzlePiece,
  faEdit,
  faCog,
  faTrophy,
  faFolderPlus,
  faQuestionCircle,
  faFolderMinus,
  faTimesCircle,
  faArrowsAltV,
  faChartLine,
  faClone,
  faCompressAlt,
  faExpandArrowsAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  indicators,
  indicatorsFull,
  timeframes,
  exchanges,
  conditionDirections,
  optimizeIntervals,
} from "./constants/consts.json";
import {
  generateUniqueId,
  checkStrategyParams,
  showInfoDialog,
  resetIndicatorSetup,
  getFromLocalStorage,
  showConfirmDialog,
  saveStrategyGroup,
  deleteStrategyGroup,
} from "./utils.js";
import $ from "jquery";
import { Modal, ModalConfirm } from "./Modal.js";
import { Dropdown, DropdownWithSearch } from "./Dropdown.js";
import getExchange from "./Exchange.js";
import { Rules, RuleSaved } from "./Rules.js";
import { RadioButtons } from "./RadioButtons.js";
import { getTimeframes } from "./tatools.js";
import getEcbDatabase from "./EcbDatabase";
import { globalContext } from "./Context.js";
import templateStratgies from "./template-strategies.js";
import { strategyVersion, panelsOptions } from "./StrategiesMain.js";
import { updateStrategy, stopBacktest } from "./Bot";

function copyStrategy(strategy) {
  let newStrategy = null;
  if (strategy) {
    newStrategy = { ...strategy, buyRules: [], sellRules: [] };

    const copyRule = (rule) => {
      let newRule = { ...rule, conditionsOrder: [] };
      rule.conditionsOrder.forEach((condition) => {
        newRule[condition] = { ...rule[condition] };
        newRule.conditionsOrder.push(condition);
      });
      return newRule;
    };

    newStrategy.buyRules = strategy.buyRules.map(copyRule);
    newStrategy.sellRules = strategy.sellRules.map(copyRule);
  }
  return newStrategy;
}

const AddRulePopup = (props) => {
  const [filter, setFilter] = useState("");

  return (
    <Modal
      id={props.id}
      closeOnEnter={true}
      center={true}
      header={
        <span>
          <FontAwesomeIcon icon={faPaperclip} /> Choose Rule Type
        </span>
      }
      content={
        <div>
          <div className="mb-3 text-left">
            <FontAwesomeIcon className="text-info ml-2" icon={faSearch} />
            <div className="d-inline-block input-group-sm">
              <input
                type="text"
                className="btn-bottom-outline-info"
                value={filter}
                placeholder="Search"
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className="text-nowrap bg-new-dark intro rounded overflow-auto  strategy-choose-ind">
            <ul className="p-2 list-unstyled">
              {indicatorsFull
                .filter((indicator) => indicator.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1)
                .map((indicator) =>
                  indicator === indicatorsFull[0] ? (
                    ""
                  ) : (
                    <li className="p-0" key={indicator}>
                      <a
                        href="#/"
                        className="p-1 text-light-gray hover-bg-light-green rounded d-inline-block border-bottom-transparent"
                        title="Add Indicator"
                        onClick={(e) => {
                          e.preventDefault();
                          document.activeElement.blur();
                          let newRules = [];
                          let indicatorToUse = indicators[indicatorsFull.indexOf(indicator)];
                          let newRule = resetIndicatorSetup(
                            {
                              id: generateUniqueId(props.rules, "rule", "id"),
                              timeframe: props.demo ? "1 hour" : props.lastTimeframeRef.current,
                              mode: props.lastModeRef.current,
                              indicator: indicatorToUse,
                              conditionsOrder: [],
                            },
                            indicatorToUse
                          );
                          newRules.push(newRule);
                          props.rules.forEach((rule) => newRules.push(rule));
                          props.setRules(newRules);

                          $(`#${props.id}`).modal("toggle");
                        }}
                      >
                        <div className="col-rule-btn align-top mr-2">
                          <FontAwesomeIcon className="text-light-green small" icon={faPlus} />
                        </div>
                        <div className="col-rule text-justify text-wrap">
                          {indicator === "Manual order"
                            ? props.ruleType === "buy"
                              ? "Manual buy"
                              : "Manual sell"
                            : indicator}
                        </div>
                      </a>
                    </li>
                  )
                )}
            </ul>
          </div>
        </div>
      }
      closeFn={() => {
        props.setShowOverlay(false);
        $("body").addClass("modal-open");
      }}
    />
  );
};

const StrategyToEdit = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const lastTimeframeRef = useRef(
    getTimeframes(props.strategy).length > 0 ? getTimeframes(props.strategy)[0] : timeframes[0]
  );
  const lastModeRef = useRef(props.strategy.buyRules.length > 0 ? props.strategy.buyRules[0].mode : "close");
  const [strategy, setStrategy] = useState(copyStrategy(props.strategy));
  const [pairs, setPairs] = useState([[], {}]);
  const [pair, setPair] = useState(props.strategy.pair);
  const [showOverlay, setShowOverlay] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ header: "", content: "" });
  const [confirm, setConfirm] = useState({ header: "", content: "" });
  const [saveOverride, setSaveOverride] = useState(false);
  const [showCondition, setShowCondition] = useState(props.strategy.conditionPair ? true : false);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    $("#editStrategyModal").on("hidden.bs.modal", function (e) {
      if (e.target.id === "editStrategyModal") {
        props.setStrategyToEdit(null);
      }
    });
    return () => {
      if ($("#editStrategyModal").is(":visible")) {
        $("#editStrategyModal").modal("hide");
      }
    };
  }, []);

  useEffect(() => {
    if (props.strategy) {
      if (!$("#editStrategyModal").is(":visible")) {
        $("#editStrategyModal").modal({
          show: true,
          keyboard: false,
          backdrop: "static",
        });
      }
      //setStrategy(props.strategy);
      //setPair(props.strategy.pair);
      //setShowOverlay(false);
      setErrorMsg({ header: "", content: "" });
      setConfirm({ header: "", content: "" });
    }
  }, [props.strategy]);

  useEffect(() => {
    if (strategy.exchange !== exchanges[0]) {
      (async () => {
        let pairs = await getExchange(strategy.exchange, state.app).getPairs();
        if (componentIsMounted.current && pairs && pairs[0]) {
          setPairs(pairs);
        }
      })();
    }
  }, [strategy.exchange]);

  const showErrorMsg = (header, content) => {
    if ($("#editStrategyModal").is(":visible")) {
      if (!$("#editStrategyErrorDialog").is(":visible")) {
        setErrorMsg({ header: header, content: content });
        setShowOverlay(true);
        $("#editStrategyErrorDialog").modal("toggle");
      }
    }
  };

  const showConfirmMsg = (header, content, func) => {
    if ($("#editStrategyModal").is(":visible")) {
      if (!$("#editStrategyModalConfirm").is(":visible")) {
        setConfirm({ header: header, content: content, func: func });
        setShowOverlay(true);
        $("#editStrategyModalConfirm").modal("toggle");
      }
    }
  };

  return (
    <div
      className="modal large fade-in-fast"
      id="editStrategyModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="editStrategy"
      aria-hidden="true"
      data-keyboard="false"
    >
      <div className="modal-dialog modal-large" role="document">
        <div className="modal-content user-select-none ">
          <div className={`overlay-div ${showOverlay ? "" : "d-none"}`}></div>
          <div className="modal-header">
            <h4 className="modal-title text-center w-100 m-auto" id="editStrategy">
              {props.editType}
            </h4>

            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body text-left px-lg-5">
            <div className="bg-new-dark text-white py-4 intro rounded overflow-auto  strategy-edit">
              <Modal
                id="editStrategyErrorDialog"
                header={errorMsg.header}
                content={errorMsg.content}
                closeFn={() => {
                  setShowOverlay(false);
                  $("body").addClass("modal-open");
                }}
              />
              <ModalConfirm
                id={"editStrategyModalConfirm"}
                header={confirm.header}
                content={confirm.content}
                closeFn={() => {
                  setShowOverlay(false);
                  $("body").addClass("modal-open");
                }}
                func={() => {
                  if (confirm.func) {
                    confirm.func();
                  }
                }}
              />

              {props.saveFromOptimization && (
                <div className="text-center mb-3 py-1">
                  <RadioButtons
                    options={[
                      {
                        optionClasses: "text-white",
                        checkedColor: "text-light-green",
                        uncheckedColor: "text-white",
                        checked: !saveOverride,
                        check: () => setSaveOverride(false),
                        text: "Save as New",
                      },
                      {
                        optionClasses: "text-white",
                        checkedColor: "text-light-green",
                        uncheckedColor: "text-white",
                        checked: saveOverride,
                        check: () => setSaveOverride(true),
                        text: "Overwrite Existing",
                      },
                    ]}
                  />
                </div>
              )}

              <div className="custom-hr">
                <div className="mb-4 mb-lg-2">
                  <label className="col-strategy-label" htmlFor="newStrategyName">
                    Name:
                  </label>
                  <div className="col-strategy-input">
                    <input
                      type="text"
                      className="strategy text-left required w-100 min-w-150 w-75-p px-2"
                      id="newStrategyName"
                      placeholder="<Required>"
                      value={strategy.name}
                      autoComplete="off"
                      onChange={(e) => {
                        let value = e.target.value.trim();
                        if (value.length > 300) {
                          value = value.substr(0, 300);
                        }
                        setStrategy({ ...strategy, name: e.target.value });
                      }}
                    ></input>
                  </div>
                </div>

                <div className="mb-4 mb-lg-2">
                  <label className="col-strategy-label">Exchange:</label>
                  <div className="col-strategy-input">
                    <Dropdown
                      btnClasses="border-bottom border-light min-w-150 px-2"
                      placeholder="required"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded min-w-150"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={strategy.exchange}
                      setSelected={(selected) => {
                        if (props.demo || props.freeBacktest) {
                          showErrorMsg(
                            <span className="text-info">
                              <FontAwesomeIcon icon={faExclamationTriangle} /> Demo mode
                            </span>,
                            "Cannot be changed in DEMO mode!"
                          );
                          return;
                        }

                        if (state.simStatuses[props.strategy.id] || state.liveStatuses[props.strategy.id]) {
                          showErrorMsg(
                            <span className="text-info">
                              <FontAwesomeIcon icon={faExclamationTriangle} /> Trading strategy
                            </span>,
                            "Cannot change the exchange of a trading strategy. Remove the execution to edit the exchange."
                          );
                          return;
                        }
                        if (strategy.exchange !== selected) {
                          setPair("");
                          setShowCondition(false);
                          setStrategy({
                            ...strategy,
                            pair: "",
                            exchange: selected,
                            conditionDirection: conditionDirections[0],
                            conditionValue: "",
                            conditionPair: "",
                          });
                        }
                      }}
                      items={exchanges}
                    />
                  </div>
                </div>

                <div className="mb-4 mb-lg-2">
                  <label className="col-strategy-label">Pair:</label>
                  <div className="col-strategy-input">
                    <DropdownWithSearch
                      classesInput="strategy text-left min-w-150 px-2 required"
                      dropMenuClasses="bg-white white border border-light rounded max-h-180 min-w-150"
                      dropMenuItemsClasses="text-dark border-bottom border-light-green items-light-green"
                      selected={pair}
                      hide={props.demo || state.simStatuses[props.strategy.id] || state.liveStatuses[props.strategy.id]}
                      setSelected={(pair) => {
                        if (props.demo) {
                          showErrorMsg(
                            <span className="text-info">
                              <FontAwesomeIcon icon={faExclamationTriangle} /> Demo mode
                            </span>,
                            "Cannot be changed in DEMO mode!"
                          );
                          return;
                        }

                        if (state.simStatuses[props.strategy.id] || state.liveStatuses[props.strategy.id]) {
                          showErrorMsg(
                            <span className="text-info">
                              <FontAwesomeIcon icon={faExclamationTriangle} /> Trading strategy
                            </span>,
                            "Cannot change the pair of a trading strategy. Remove the execution to edit the pair."
                          );
                          return;
                        }
                        if (strategy.exchange === exchanges[0]) {
                          showErrorMsg(
                            <span className="text-danger">
                              <FontAwesomeIcon icon={faExclamationTriangle} /> Missing exchange
                            </span>,
                            "Choose an exchange before choosing a trading pair."
                          );
                          return;
                        }
                        setPair(pair);
                        setStrategy({
                          ...strategy,
                          pair: pair,
                          pairDetails: pairs[1][pair],
                        });
                      }}
                      items={pairs[0]}
                    />
                  </div>
                </div>
              </div>
              <AddRulePopup
                id="chooseBuyRule"
                ruleType="buy"
                lastTimeframeRef={lastTimeframeRef}
                lastModeRef={lastModeRef}
                rules={strategy.buyRules}
                demo={props.demo}
                setRules={(newRules) => {
                  setStrategy({ ...strategy, buyRules: newRules });
                }}
                setShowOverlay={setShowOverlay}
              />
              <div className="custom-hr">
                <div className="mx-2 mx-sm-3">
                  <div className="">
                    <table className="table table-new-dark bg-transparent table-borderless">
                      <tbody>
                        <tr>
                          <td className="text-left text-nowrap pl-0">Buy Rules:</td>
                          <td className="text-left text-nowrap w-lg-100 min-w-lg-max-260 w-lg-max-100">
                            <a
                              href="#/"
                              className="p-1 hover-green text-white rounded ml-neg1"
                              title="Add Rule"
                              data-toggle="modal"
                              data-target="#chooseBuyRule"
                              onClick={(e) => {
                                e.preventDefault();
                                document.activeElement.blur();
                                setShowOverlay(true);
                              }}
                            >
                              <FontAwesomeIcon className="text-light-green d-inline-block" icon={faPlusCircle} /> Add
                            </a>
                          </td>
                          <td className="text-nowrap pr-0 pr-lg-3">
                            {strategy.buyRules.length > 0 && (
                              <>
                                <span className="d-none d-lg-inline-block">Validate rule on:</span>
                                <span className="d-lg-none">Validate:</span>
                                <a
                                  href="#/"
                                  className="text-info ml-2"
                                  title="Info"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    document.activeElement.blur();
                                    showErrorMsg(
                                      <span className="text-info">
                                        <FontAwesomeIcon icon={faInfoCircle} /> Rule Validation mode
                                      </span>,
                                      <div>
                                        <ul>
                                          <li>candle close - check if the rule is fulfilled only on candle close.</li>
                                          <li>
                                            current price - check if the rule is fulfilled on every tick of the price.
                                          </li>
                                        </ul>
                                        Example with rule{" "}
                                        <span className="text-info">
                                          "Buy when RSI is crossing 35 from bottom to top"
                                        </span>
                                        . The previous candle RSi is 30 and the price moves upwards making the current
                                        value of the RSI 36.
                                        <br />
                                        The <span className="text-info">current price</span> option will validate the
                                        rule as fulfilled.
                                        <br />
                                        The <span className="text-info">candle close</span> option will wait the candle
                                        to close to check the rule. If the RSI is still obove 35 the rule will be
                                        validated as fulfilled. If the RSI is below 35 the rule will be validated as NOT
                                        fulfilled.
                                      </div>
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon icon={faInfoCircle} />
                                </a>
                              </>
                            )}
                          </td>
                        </tr>
                        <Rules
                          demo={props.demo}
                          showErrorMsg={showErrorMsg}
                          showConfirmMsg={showConfirmMsg}
                          rules={strategy.buyRules}
                          saveRules={(rules) => {
                            setStrategy({ ...strategy, buyRules: rules });
                          }}
                          setShowOverlay={setShowOverlay}
                          lastTimeframeRef={lastTimeframeRef}
                          lastModeRef={lastModeRef}
                          strategy={strategy}
                          ruleType="buy"
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="mb-4 mb-lg-2 pt-3">
                  <label className="col-strategy-label align-top">
                    Trailing Buy:
                    <a
                      href="#/"
                      className="text-info ml-3"
                      title="Info"
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                        showErrorMsg(
                          <span className="text-info">
                            <FontAwesomeIcon icon={faInfoCircle} /> Trailing Buy
                          </span>,
                          <div>
                            When all buy rules are met the bot will wait for the price to go up by the provided
                            percentage. The percentage is calculated from the lowest point after fulfillment of the buy
                            rules. The trailing buy timer sets how long the bot will wait for the price to reach the set
                            percentage. If the price does not go up within the provided time the BUY signal will be
                            disregarded. Then the bot will check the rules again and start over.
                            <br />
                            <br />
                            Examples:
                            <p>
                              Trailing buy is 1% and the timer is set to 5 minutes.
                              <br />
                              All BUY rules are met and the current price of the pair is 100.
                            </p>
                            <ul>
                              <li className="mb-1">
                                <span className="underlined">Scenario 1: </span>
                                <br />
                                While the timer is active the price starts to rise. It reaches 101 (1% rise from 100).
                                The bot places the BUY order.
                              </li>
                              <li className="mb-1">
                                <span className="underlined">Scenario 2: </span>
                                <br />
                                While the timer is active, first the price starts to fall and goes to 95. Then it
                                changes direction and starts to rise. It reaches 95.95 (1% rise from 95, percentage is
                                calculated from the lowest point after fulfillment of the buy rules). The bot places the
                                BUY order.
                              </li>
                              <li className="mb-1">
                                <span className="underlined">Scenario 3: </span>
                                <br />
                                While the timer is active, the price starts to fall. After the timer finishes the price
                                is still below the 1% rise. The bot disregards the BUY signal. Then the bot starts over
                                with check of the BUY rules.
                              </li>
                            </ul>
                          </div>
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </a>
                  </label>
                </div>
                <div className="text-nowrap mb-4 mb-lg-2">
                  <label className="col-strategy-label align-top">
                    <FontAwesomeIcon className="text-light-green mr-2" icon={faHandPointRight} />
                    price rise:
                  </label>
                  <div className="col-strategy-input text-nowrap">
                    <input
                      type="number"
                      className={`strategy text-center px-2 ${strategy.tBuyWait ? "required" : "optional"}`}
                      placeholder={strategy.tBuyWait ? "<Required>" : "<Optional>"}
                      value={strategy.tBuy || strategy.tBuy === 0 ? strategy.tBuy : ""}
                      onChange={(e) => {
                        let value = Math.abs(parseFloat(e.target.value));
                        setStrategy({
                          ...strategy,
                          tBuy: isNaN(value) ? "" : value,
                        });
                      }}
                    ></input>{" "}
                    %
                  </div>
                </div>

                <div className="text-nowrap mb-4 mb-lg-2">
                  <label className="col-strategy-label align-top">
                    <FontAwesomeIcon className="text-light-green mr-2" icon={faHandPointRight} />
                    timer:
                  </label>
                  <div className="col-strategy-input text-nowrap">
                    <input
                      type="number"
                      className={`strategy text-center px-2 ${strategy.tBuy ? "required" : "optional"}`}
                      placeholder={strategy.tBuy ? "<Required>" : "<Optional>"}
                      value={strategy.tBuyWait || strategy.tBuyWait === 0 ? strategy.tBuyWait : ""}
                      onChange={(e) => {
                        let value = Math.abs(parseInt(e.target.value));
                        setStrategy({
                          ...strategy,
                          tBuyWait: isNaN(value) ? "" : value,
                        });
                      }}
                    ></input>{" "}
                    minutes
                  </div>
                </div>

                <div className="mb-4 mb-lg-2 pt-3">
                  <label className="col-strategy-label align-top">
                    Trade cooldown:
                    <a
                      href="#/"
                      className="text-info ml-3"
                      title="Info"
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                        showErrorMsg(
                          <span className="text-info">
                            <FontAwesomeIcon icon={faInfoCircle} /> Trade cooldown
                          </span>,
                          <div>
                            Time in minutes that the bot will wait after a winning or losing trade before checking the
                            BUY rules for a new trade.
                            <br />
                            <br />
                            Examples:
                            <ul>
                              <li>
                                The cooldown <b>after loss</b> is set to 20 minutes. A trade is closed with a{" "}
                                <b>negative</b> result at 18:00. The bot will not open a new trade before 18:20,
                                regardless of whether the BUY rules have been met.
                              </li>
                              <li>
                                The cooldown <b>after win</b> is set to 20 minutes. A trade is closed with a{" "}
                                <b>positive</b> result at 18:00. The bot will not open a new trade before 18:20,
                                regardless of whether the BUY rules have been met.
                              </li>
                            </ul>
                          </div>
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </a>
                  </label>
                </div>
                <div className="text-nowrap mb-4 mb-lg-2">
                  <label className="col-strategy-label align-top">
                    <FontAwesomeIcon className="text-light-green mr-2" icon={faHandPointRight} />
                    after win:
                  </label>
                  <div className="col-strategy-input text-nowrap">
                    <input
                      type="number"
                      className="strategy text-center px-2 optional"
                      placeholder="<Optional>"
                      value={strategy.wCooldown || strategy.wCooldown === 0 ? strategy.wCooldown : ""}
                      onChange={(e) => {
                        let value = Math.abs(parseInt(e.target.value));
                        setStrategy({
                          ...strategy,
                          wCooldown: isNaN(value) ? "" : value,
                        });
                      }}
                    ></input>{" "}
                    minutes
                  </div>
                </div>
                <div className="text-nowrap ">
                  <label className="col-strategy-label align-top">
                    <FontAwesomeIcon className="text-light-green mr-2" icon={faHandPointRight} />
                    after loss:
                  </label>
                  <div className="col-strategy-input text-nowrap">
                    <input
                      type="number"
                      className="strategy text-center px-2 optional"
                      placeholder="<Optional>"
                      value={strategy.slCooldown || strategy.slCooldown === 0 ? strategy.slCooldown : ""}
                      onChange={(e) => {
                        let value = Math.abs(parseInt(e.target.value));
                        setStrategy({
                          ...strategy,
                          slCooldown: isNaN(value) ? "" : value,
                        });
                      }}
                    ></input>{" "}
                    minutes
                  </div>
                </div>
              </div>
              <AddRulePopup
                id="chooseSellRule"
                ruleType="sell"
                lastTimeframeRef={lastTimeframeRef}
                lastModeRef={lastModeRef}
                rules={strategy.sellRules}
                demo={props.demo}
                setRules={(newRules) => {
                  setStrategy({ ...strategy, sellRules: newRules });
                }}
                setShowOverlay={setShowOverlay}
              />
              <div className="">
                <div className="mx-2 mx-sm-3">
                  <div className="">
                    <table className="table table-new-dark bg-transparent table-borderless">
                      <tbody>
                        <tr>
                          <td className="text-left text-nowrap pl-0">Sell Rules:</td>
                          <td className="text-left text-nowrap w-lg-100 min-w-lg-max-260 w-lg-max-100">
                            <a
                              href="#/"
                              className="p-1 hover-green text-white rounded ml-neg1"
                              title="Add Rule"
                              data-toggle="modal"
                              data-target="#chooseSellRule"
                              onClick={(e) => {
                                e.preventDefault();
                                document.activeElement.blur();
                                setShowOverlay(true);
                              }}
                            >
                              <FontAwesomeIcon className="text-light-green d-inline-block" icon={faPlusCircle} /> Add
                            </a>
                          </td>
                          <td className="text-nowrap">
                            {strategy.sellRules.length > 0 && (
                              <>
                                <span className="d-none d-lg-inline-block">Validate rule on:</span>
                                <span className="d-lg-none">Validate:</span>
                                <a
                                  href="#/"
                                  className="text-info ml-2"
                                  title="Info"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    document.activeElement.blur();
                                    showErrorMsg(
                                      <span className="text-info">
                                        <FontAwesomeIcon icon={faInfoCircle} /> Rule Validation mode
                                      </span>,
                                      <div>
                                        <ul>
                                          <li>candle close - check if the rule is fulfilled only on candle close.</li>
                                          <li>
                                            current price - check if the rule is fulfilled on every tick of the price.
                                          </li>
                                        </ul>
                                        Example with rule{" "}
                                        <span className="text-info">
                                          "Sell when RSI is crossing 35 from bottom to top"
                                        </span>
                                        . The previous candle RSi is 30 and the price moves upwards making the current
                                        value of the RSI 36.
                                        <br />
                                        The <span className="text-info">current price</span> option will validate the
                                        rule as fulfilled.
                                        <br />
                                        The <span className="text-info">candle close</span> option will wait the candle
                                        to close to check the rule. If the RSI is still obove 35 the rule will be
                                        validated as fulfilled. If the RSI is below 35 the rule will be validated as NOT
                                        fulfilled.
                                      </div>
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon icon={faInfoCircle} />
                                </a>
                              </>
                            )}
                          </td>
                        </tr>
                        <Rules
                          demo={props.demo}
                          showErrorMsg={showErrorMsg}
                          showConfirmMsg={showConfirmMsg}
                          rules={strategy.sellRules}
                          saveRules={(rules) => {
                            setStrategy({ ...strategy, sellRules: rules });
                          }}
                          setShowOverlay={setShowOverlay}
                          lastTimeframeRef={lastTimeframeRef}
                          lastModeRef={lastModeRef}
                          strategy={strategy}
                          ruleType="sell"
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
                <div>
                  <div className="d-lg-inline-block text-nowrap mb-4 mb-lg-3 mr-lg-5 pt-3">
                    <label className="col-strategy-label align-top">Stoploss:</label>
                    <div className="col-strategy-input text-nowrap">
                      <input
                        type="number"
                        className="strategy text-center px-2 optional"
                        placeholder="<Optional>"
                        value={strategy.stoploss || strategy.stoploss === 0 ? strategy.stoploss : ""}
                        onChange={(e) => {
                          let value = Math.abs(parseFloat(e.target.value));
                          setStrategy({
                            ...strategy,
                            stoploss: isNaN(value) ? "" : value,
                          });
                        }}
                      ></input>{" "}
                      %
                    </div>
                  </div>

                  <div className="d-lg-inline-block text-nowrap mb-4 mb-lg-3">
                    <label className="col-strategy-label align-top text-wrap">Stoploss timer:</label>
                    <div className="col-strategy-input text-nowrap">
                      <input
                        type="number"
                        className="strategy text-center px-2 optional"
                        placeholder="<Optional>"
                        value={strategy.slTimer || strategy.slTimer === 0 ? strategy.slTimer : ""}
                        onChange={(e) => {
                          let value = Math.abs(parseInt(e.target.value));
                          setStrategy({
                            ...strategy,
                            slTimer: isNaN(value) || value === 0 ? "" : value,
                          });
                        }}
                      ></input>{" "}
                      minutes
                      <a
                        href="#/"
                        className="text-info ml-3"
                        title="Info"
                        onClick={(e) => {
                          e.preventDefault();
                          document.activeElement.blur();
                          showErrorMsg(
                            <span className="text-info">
                              <FontAwesomeIcon icon={faInfoCircle} /> Stoploss Confirmation Timer
                            </span>,
                            <div>
                              <p>
                                When the Stoploss price is reached the bot will wait the specified minutes. After the
                                wait time has finished the bot will place the sell order only if the stoploss is
                                confirmed - price is still at the stoploss or below it.
                              </p>
                              Example:
                              <br />
                              The stoploss is set at price 10, the timer is set to 2 minutes. The price starts to fall
                              and reaches 10. The bot starts the timer. After the time is up:
                              <ul>
                                <li>
                                  Scenario 1:
                                  <p>
                                    During the wait time the price has gone back up and is now at 15. The bot doesn't
                                    place a sell order and continues to wait for a new opportunity to sell.
                                  </p>
                                </li>
                                <li>
                                  Scenario 2:
                                  <p>
                                    During the wait time the price has fallen even further and is now at 9. The bot
                                    proceeds with a sell order to close the trade.
                                  </p>
                                </li>
                              </ul>
                            </div>
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </a>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-lg-inline-block text-nowrap mb-4 mb-lg-3 mr-lg-5">
                    <label className="col-strategy-label align-top">Target:</label>
                    <div className="col-strategy-input text-nowrap">
                      <input
                        type="number"
                        id="editStrategyTargetInput"
                        className={`strategy text-center px-2 ${strategy.trailingTarget ? "required" : "optional"}`}
                        placeholder={strategy.trailingTarget ? "<Required>" : "<Optional>"}
                        value={strategy.target || strategy.target === 0 ? strategy.target : ""}
                        onChange={(e) => {
                          let value = Math.abs(parseFloat(e.target.value));
                          setStrategy({
                            ...strategy,
                            target: isNaN(value) ? "" : value,
                          });
                        }}
                      ></input>{" "}
                      %
                    </div>
                  </div>

                  <div className="d-lg-inline-block text-nowrap mb-4 mb-lg-3">
                    <label className="col-strategy-label align-top">Trailing tgt:</label>
                    <div className="col-strategy-input text-nowrap">
                      <input
                        type="number"
                        id="editStrategyTrailingTargetInput"
                        className="strategy text-center px-2 optional"
                        placeholder="<Optional>"
                        value={strategy.trailingTarget || strategy.trailingTarget === 0 ? strategy.trailingTarget : ""}
                        onChange={(e) => {
                          let value = Math.abs(parseFloat(e.target.value));
                          setStrategy({
                            ...strategy,
                            trailingTarget: isNaN(value) ? "" : value,
                          });
                        }}
                      ></input>{" "}
                      %{" "}
                      <a
                        href="#/"
                        className="text-info ml-3"
                        title="Info"
                        onClick={(e) => {
                          e.preventDefault();
                          document.activeElement.blur();
                          showErrorMsg(
                            <span className="text-info">
                              <FontAwesomeIcon icon={faInfoCircle} /> Trailing Target
                            </span>,
                            <div>
                              The bot will not place a SELL order when the Target is reached. Instead, when the target
                              is reached the bot will place a trailing sell order (trailing target). The percent is the
                              distance between the trailing sell order and the highest price reached after the target.
                              The trailing target will move and follow only a rising price at the set distance. It will
                              stay in place if the price is falling. If the price reaches the trailing target on its way
                              down, the bot will execute the sell order.
                              <br />
                              <br />
                              Example:
                              <p>
                                Target is 2%. Trailing target is 1%. <br />
                                The BUY rules are met and the current price is 100. <br />
                                Then the price rises to 102 (2% above 100, target is reached). The trailing target is
                                activated and set initially at 100.98 (1% below 102).
                              </p>
                              <ul>
                                <li className="mb-1">
                                  <span className="underlined">Scenario 1: </span>
                                  <br />
                                  The price directly starts to fall. It reaches the trailing target at 100.98. The bot
                                  executes a sell order.
                                </li>
                                <li className="mb-1">
                                  <span className="underlined">Scenario 2: </span>
                                  <br />
                                  First, the price continues to rise. The trailing target follows it at 1% below. Then
                                  at some point, the price changes direction and starts to fall. At this moment the
                                  trailing target is at 110 and holds this value while the price is falling. The price
                                  reaches 110. The bot executes a sell order.
                                </li>
                              </ul>
                            </div>
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="text-nowrap ">
                  <label className="col-strategy-label align-top">Time Stop:</label>
                  <div className="col-strategy-input text-nowrap">
                    <input
                      type="number"
                      className="strategy text-center px-2 optional"
                      placeholder="<Optional>"
                      value={strategy.timeClose ? strategy.timeClose : ""}
                      onChange={(e) => {
                        let value = Math.abs(parseInt(e.target.value));
                        setStrategy({
                          ...strategy,
                          timeClose: isNaN(value) ? "" : value,
                        });
                      }}
                    ></input>{" "}
                    hours
                  </div>
                </div>
              </div>

              {false && (
                <div className="text-nowrap mb-4 mb-lg-2">
                  <label className="col-strategy-label">Condition:</label>
                  <div className="col-strategy-input">
                    <a
                      href="#/"
                      className="p-1 hover-green text-white rounded ml-neg1"
                      title="Add Condition"
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();

                        if (props.demo) {
                          showInfoDialog(
                            dispatch,
                            <div className="text-info">
                              <FontAwesomeIcon icon={faExclamationTriangle} /> Demo mode
                            </div>,
                            "Cannot be used in DEMO mode!"
                          );
                          return;
                        }

                        if (strategy.exchange === exchanges[0]) {
                          showErrorMsg(
                            <span className="text-danger">
                              <FontAwesomeIcon icon={faExclamationTriangle} /> Missing exchange
                            </span>,
                            "Choose an exchange first."
                          );
                          return;
                        }
                        setShowCondition(true);
                      }}
                    >
                      <FontAwesomeIcon className="text-light-green d-inline-block" icon={faPlusCircle} /> Add
                    </a>
                  </div>
                </div>
              )}

              {showCondition && (
                <div className="mb-4 mb-lg-2">
                  <label className="col-strategy-label align-top text-right">
                    <a
                      href="#/"
                      className="text-danger mr-3"
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                        setStrategy({
                          ...strategy,
                          conditionPair: "",
                          conditionDirection: "",
                          conditionValue: "",
                        });
                        setShowCondition(false);
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </a>
                  </label>
                  <div className="col-strategy-input text-justify">
                    Freeze trading while{" "}
                    <DropdownWithSearch
                      classesInput="strategy text-left min-w-150 px-2 required"
                      dropMenuClasses="bg-white white border border-light rounded max-h-180 min-w-150"
                      dropMenuItemsClasses="text-dark border-bottom border-light-green"
                      selected={strategy.conditionPair ? strategy.conditionPair : ""}
                      setSelected={(pair) => {
                        setStrategy({ ...strategy, conditionPair: pair });
                      }}
                      items={pairs[0]}
                    />{" "}
                    is{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light min-w-150 px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded min-w-150"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={strategy.conditionDirection ? strategy.conditionDirection : conditionDirections[0]}
                      setSelected={(selected) => {
                        setStrategy({
                          ...strategy,
                          conditionDirection: selected,
                        });
                      }}
                      items={conditionDirections}
                    />{" "}
                    by more than{" "}
                    <input
                      type="number"
                      className={`strategy required ${strategy.conditionValue ? "w-50" : ""}`}
                      value={strategy.conditionValue ? strategy.conditionValue : ""}
                      placeholder="<Required>"
                      onChange={(e) => {
                        let value = Math.abs(parseFloat(e.target.value));
                        setStrategy({ ...strategy, conditionValue: value });
                      }}
                    />
                    %
                    {strategy.conditionDirection && (
                      <>
                        {" "}
                        from its{" "}
                        {strategy.conditionDirection === "up"
                          ? "lowest"
                          : strategy.conditionDirection === "down"
                          ? "highest"
                          : "highest / lowest"}{" "}
                        price, observed within the last 24 hours.
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-sm btn-new-dark col-4 col-sm-2"
              onClick={async () => {
                let [isValid, errorMsg] = await checkStrategyParams(strategy, pairs);
                if (!isValid) {
                  showErrorMsg(
                    <span className="text-danger">
                      <FontAwesomeIcon icon={faExclamationTriangle} /> Invalid strategy
                    </span>,
                    errorMsg
                  );
                  return;
                } else {
                  strategy.editDate = new Date().getTime();
                  strategy.buyRules.forEach((rule) => {
                    rule.saved = true;
                    for (let i = 1; i < 10; i++) {
                      if (rule[`condition${i}`]) {
                        rule[`condition${i}`].saved = true;
                      }
                    }
                  });
                  strategy.sellRules.forEach((rule) => {
                    rule.saved = true;
                    for (let i = 1; i < 10; i++) {
                      if (rule[`condition${i}`]) {
                        rule[`condition${i}`].saved = true;
                      }
                    }
                  });
                  let strategyToSave = strategy;
                  if (props.saveFromOptimization && !saveOverride) {
                    strategyToSave = {
                      ...strategy,
                      id: generateUniqueId(state.strategies, state.user.id, "id"),
                      sim: {
                        maxDrawdown: "",
                        maxLoss: "",
                        maxNegTrades: "",
                        commissionRate: "",
                        orderDetails: { type: "market", value: "" },
                        tradeSize: { type: "base", value: "" },
                      },
                      live: {
                        maxDrawdown: "",
                        maxLoss: "",
                        maxNegTrades: "",
                        commissionRate: "",
                        orderDetails: { type: "market", value: "" },
                        tradeSize: { type: "base", value: "" },
                      },
                    };
                  }
                  await stopBacktest(strategyToSave.id, dispatch);
                  await updateStrategy(strategyToSave);
                  await getEcbDatabase().deleteBacktests(strategyToSave.id);
                  dispatch({
                    type: "strategy",
                    payload: strategyToSave,
                  });
                  //props.setSelectedStrategyId(null);
                  props.setSelectedStrategyId(strategyToSave.id);
                  props.addStrategyToActiveGroup(strategyToSave);

                  if ($("#editStrategyModal").is(":visible")) {
                    $("#editStrategyModal").modal("toggle");
                  }
                }
              }}
            >
              Save
            </button>
            <button type="button" className="btn btn-sm btn-new-dark col-4 col-sm-2" data-dismiss="modal">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const InspectStrategyPopup = (props) => {
  useEffect(() => {
    if (props.strategy) {
      if (!$("#inspectStrategy").is(":visible")) {
        $("#inspectStrategy").modal("toggle");
      }
    }
  }, [props.strategy]);

  useEffect(() => {
    $("#inspectStrategy").on("hidden.bs.modal", function (e) {
      if (e.target.id === "inspectStrategy") {
        props.setStrategyToInspect(null);
      }
    });
    return () => {
      return () => {
        if ($("#inspectStrategy").is(":visible")) {
          $("#inspectStrategy").modal("hide");
        }
      };
    };
  }, []);

  return (
    <div
      className="modal fade"
      id="inspectStrategy"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-large" role="document">
        <div className="modal-content modal-content-slide-down">
          <div className="modal-header">
            <h4 className="modal-title text-center w-100 m-auto" id="modalLabel">
              <FontAwesomeIcon icon={faSearchPlus} /> Strategy
            </h4>
            <button type="button" className="close" aria-label="Close" data-dismiss="modal" onClick={() => {}}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body text-left mx-lg-5">
            <div className="bg-new-dark text-white py-4 intro rounded">
              <InspectStrategy
                showName={true}
                labelsColor="text-light-green"
                setStrategyToInspect={props.setStrategyToInspect}
                strategy={props.strategy}
                setStrategyToEdit={props.setStrategyToEdit}
                setEditType={props.setEditType}
                setSaveFromOptimization={props.setSaveFromOptimization}
              />
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-sm btn-dark col-4 col-sm-2"
              data-dismiss="modal"
              onClick={() => {}}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const InspectStrategy = (props) => {
  return (
    <div className="text-left">
      {props.showName && <h5 className="text-center pl-3 mb-3">{props.strategy.name}</h5>}
      <div className={`custom-hr ${props.labelsColor === "text-info" ? "alt" : ""}`}>
        <div className="">
          <label className="col-strategy-label alt align-top">Exchange:</label>
          <div className={`col-strategy-input ${props.labelsColor}`}>{props.strategy.exchange}</div>
        </div>
        <div className="">
          <label className="col-strategy-label alt align-top">Pair:</label>
          <div className={`col-strategy-input ${props.labelsColor}`}>{props.strategy.pair}</div>
        </div>
      </div>
      <div className={`custom-hr ${props.labelsColor === "text-info" ? "alt" : ""}`}>
        <div className="mx-2 mx-sm-3">
          <div className="">
            <table className="table table-new-dark bg-transparent table-borderless">
              <tbody>
                <tr>
                  <td className="text-left text-nowrap pl-0 pt-0">Buy Rules:</td>
                  <td className="text-left text-nowrap w-lg-100 min-w-lg-max-260 w-lg-max-100 pt-0"></td>
                  <td className="d-none d-lg-table-cell text-left text-nowrap pt-0">Validate rule on:</td>
                  <td className="d-lg-none text-left text-nowrap pt-0">Validate:</td>
                </tr>
                {props.strategy.buyRules.map((el) => (
                  <tr key={el.id}>
                    <td className="text-right pt-0 pb-2">
                      <FontAwesomeIcon className={props.labelsColor} icon={faHandPointRight} />
                    </td>
                    <td className="text-left pt-0 pb-2">
                      <RuleSaved strategy={props.strategy} rule={el} ruleType="buy" labelsColor={props.labelsColor} />
                    </td>
                    <td className={`text-left pt-0 pb-2 ${props.labelsColor}`}>
                      {el.indicator !== "Manual order" && (
                        <>{el.mode === "current" ? "current price" : "candle close"}</>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {props.strategy.tBuyWait !== 0 && props.strategy.tBuy && (
          <>
            <div className="mb-2">
              <label className="col-strategy-label alt align-top">Trailing Buy:</label>
            </div>
            <div className="mb-2">
              <label className="col-strategy-label alt align-top">
                <FontAwesomeIcon className="text-light-green mr-2" icon={faHandPointRight} />
                price rise:
              </label>
              <div className={`col-strategy-input ${props.labelsColor}`}>{props.strategy.tBuy.toFixed(2)}%</div>
            </div>
            <div className="mb-3">
              <label className="col-strategy-label alt align-top">
                <FontAwesomeIcon className="text-light-green mr-2" icon={faHandPointRight} />
                timer:
              </label>
              <div className={`col-strategy-input ${props.labelsColor}`}>
                {props.strategy.tBuyWait} {props.strategy.tBuyWait > 1 ? "minutes" : "minute"}
              </div>
            </div>
          </>
        )}
        {(props.strategy.wCooldown || props.strategy.slCooldown) && (
          <>
            <div className="mb-2">
              <label className="col-strategy-label alt align-top">Trade cooldown:</label>
            </div>
            {props.strategy.wCooldown && (
              <div className="mb-2">
                <label className="col-strategy-label alt align-top">
                  <FontAwesomeIcon className="text-light-green mr-2" icon={faHandPointRight} />
                  after win:
                </label>
                <div className={`col-strategy-input ${props.labelsColor}`}>
                  {props.strategy.wCooldown} {props.strategy.wCooldown > 1 ? "minutes" : "minute"}
                </div>
              </div>
            )}
            {props.strategy.slCooldown && (
              <div className="mb-2">
                <label className="col-strategy-label alt align-top">
                  <FontAwesomeIcon className="text-light-green mr-2" icon={faHandPointRight} />
                  after loss:
                </label>
                <div className={`col-strategy-input ${props.labelsColor}`}>
                  {props.strategy.slCooldown} {props.strategy.slCooldown > 1 ? "minutes" : "minute"}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div
        className={` ${
          props.strategy.conditionPair ? `custom-hr ${props.labelsColor === "text-info" ? "alt" : ""}` : ""
        }`}
      >
        {props.strategy.sellRules.length > 0 && (
          <div className="mx-2 mx-sm-3 mb-2">
            <div className="">
              <table className="table table-new-dark bg-transparent table-borderless">
                <tbody>
                  <tr>
                    <td className="text-left text-nowrap pl-0 pt-0">Sell Rules:</td>
                    <td className="text-left text-nowrap w-lg-100 min-w-lg-max-260 w-lg-max-100 pt-0"></td>
                    <td className="d-none d-lg-table-cell text-left text-nowrap pt-0">Validate rule on:</td>
                    <td className="d-lg-none text-left text-nowrap pt-0">Validate:</td>
                  </tr>
                  {props.strategy.sellRules.map((el) => (
                    <tr key={el.id}>
                      <td className="text-right pt-0 pb-2">
                        <FontAwesomeIcon className={props.labelsColor} icon={faHandPointRight} />
                      </td>
                      <td className="text-left pt-0 pb-2">
                        <RuleSaved strategy={props.strategy} rule={el} ruleType="buy" labelsColor={props.labelsColor} />
                      </td>
                      <td className={`text-left pt-0 pb-2 ${props.labelsColor}`}>
                        {el.indicator !== "Manual order" && (
                          <>{el.mode === "current" ? "current price" : "candle close"}</>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {props.strategy.stoploss !== 0 && props.strategy.stoploss && (
          <div>
            <div className="d-lg-inline-block mb-2">
              <label className="col-strategy-label alt align-top">Stoploss:</label>
              <div className={`col-strategy-input ${props.labelsColor}`}>{props.strategy.stoploss.toFixed(2)}%</div>
            </div>

            {props.strategy.slTimer !== 0 && props.strategy.slTimer && (
              <div className="d-lg-inline-block mb-2 ml-lg-3">
                <label className="col-strategy-label alt align-top">Stoploss timer:</label>
                <div className={`col-strategy-input ${props.labelsColor}`}>
                  {props.strategy.slTimer.toFixed(0)} {props.strategy.slTimer === 1 ? "minute" : "minutes"}
                </div>
              </div>
            )}
          </div>
        )}
        {props.strategy.target !== 0 && props.strategy.target && (
          <div>
            <div className="d-lg-inline-block mb-2">
              <label className="col-strategy-label alt align-top">Target:</label>
              <div className={`col-strategy-input ${props.labelsColor}`}>{props.strategy.target.toFixed(2)}%</div>
            </div>

            {props.strategy.trailingTarget !== 0 && props.strategy.trailingTarget && (
              <div className="d-lg-inline-block mb-2 ml-lg-3">
                <label className="col-strategy-label alt align-top">Trailing tgt:</label>
                <div className={`col-strategy-input ${props.labelsColor}`}>
                  {props.strategy.trailingTarget.toFixed(2)}%
                </div>
              </div>
            )}
          </div>
        )}
        {props.strategy.timeClose !== 0 && props.strategy.timeClose && (
          <div className="mb-2">
            <label className="col-strategy-label alt align-top">Time Stop:</label>
            <div className={`col-strategy-input ${props.labelsColor}`}>
              {props.strategy.timeClose.toFixed(0)} {props.strategy.timeClose === 1 ? "hour" : "hours"}
            </div>
          </div>
        )}
      </div>
      {props.strategy.conditionPair && (
        <div className="mb-2">
          <label className="col-strategy-label alt align-top">Condition:</label>
          <div className="col-strategy-input text-justify">
            Freeze trading while <span className={props.labelsColor}>{props.strategy.conditionPair}</span> price is{" "}
            <span className={props.labelsColor}>{props.strategy.conditionDirection}</span> by more than{" "}
            <span className={props.labelsColor}>{props.strategy.conditionValue.toFixed(2)}%</span> from its{" "}
            {props.strategy.conditionDirection === "up" ? "lowest" : "highest"} price within the last 24 hours.
          </div>
        </div>
      )}
    </div>
  );
};

function getFilteredSortedStrategiesIds(strategies, strategiesFilter, strategiesSort, simStatuses, liveStatuses) {
  return strategies
    .filter((strategy) => {
      if (!strategiesFilter) {
        return true;
      }
      if (!strategy) {
        return false;
      }
      return (
        strategy.name.toLowerCase().indexOf(strategiesFilter.toLowerCase()) !== -1 ||
        strategy.pair.toLowerCase().indexOf(strategiesFilter.toLowerCase()) !== -1 ||
        strategy.buyRules.find((rule) => {
          if (!rule.timeframe) {
            return false;
          }
          return (
            rule.indicator.toLowerCase().indexOf(strategiesFilter.toLowerCase()) !== -1 ||
            rule.timeframe.toLowerCase().indexOf(strategiesFilter.toLowerCase()) !== -1
          );
        }) ||
        strategy.sellRules.find((rule) => {
          if (!rule.timeframe) {
            return false;
          }
          return (
            rule.indicator.toLowerCase().indexOf(strategiesFilter.toLowerCase()) !== -1 ||
            rule.timeframe.toLowerCase().indexOf(strategiesFilter.toLowerCase()) !== -1
          );
        })
      );
    })
    .sort((a, b) => {
      switch (strategiesSort) {
        case "name":
          return a.name.localeCompare(b.name);
        case "nameR":
          return b.name.localeCompare(a.name);
        case "ex":
          return a.exchange.localeCompare(b.exchange);
        case "exR":
          return b.exchange.localeCompare(a.exchange);
        case "pair":
          return a.pair.localeCompare(b.pair);
        case "pairR":
          return b.pair.localeCompare(a.pair);
        case "trading":
          let aTrading = liveStatuses[a.id] ? (simStatuses[a.id] ? 2 : 3) : simStatuses[a.id] ? 1 : 0;
          let bTrading = liveStatuses[b.id] ? (simStatuses[b.id] ? 2 : 3) : simStatuses[b.id] ? 1 : 0;
          return bTrading - aTrading;
        case "tradingR":
          let aTradingR = liveStatuses[a.id] ? (simStatuses[a.id] ? 2 : 3) : simStatuses[a.id] ? 1 : 0;
          let bTradingR = liveStatuses[b.id] ? (simStatuses[b.id] ? 2 : 3) : simStatuses[b.id] ? 1 : 0;
          return aTradingR - bTradingR;
        default:
          return a.name.localeCompare(b.name);
      }
    })
    .map((el) => el.id);
}

const StrategyGroups = (props) => {
  const { state, dispatch } = useContext(globalContext);
  const [strategySearch, setStrategySearch] = useState("");
  const [checkAllStrategies, setCheckAllStrategies] = useState(false);
  const [pages, setPages] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [strategiesPerPage, setStrategiesPerPage] = useState(
    getFromLocalStorage("strategiesPerPage", 10, "number", true)
  );
  const [strategiesFilter, setStrategiesFilter] = useState("");
  const [strategiesIds, setStrategiesIds] = useState([]);
  const [checkedStrategies, setCheckedStrategies] = useState([]);
  const [page, setPage] = useState(0);
  const [dropdownHeightSubtract] = useState(118);
  const [strategiesSort, setStrategiesSort] = useState(getFromLocalStorage("strategiesSort"), "name");
  const [groupName, setGroupName] = useState("");
  const [editGroup, setEditGroup] = useState(false);
  const [addStrategiesToNewGroup, setAddStrategiesToNewGroup] = useState(false);
  const [editGroupStrategies, setEditGroupStrategies] = useState([]);

  useEffect(() => {
    if (props.activeGroup) {
      setStrategiesIds(
        getFilteredSortedStrategiesIds(
          state.strategies.filter((el) => props.activeGroup.strategies.includes(el.id)),
          strategiesFilter,
          strategiesSort,
          state.simStatuses,
          state.liveStatuses
        )
      );
      setCheckAllStrategies(false);
      setCheckedStrategies([]);
    }
  }, [props.activeGroup, state.strategies, strategiesSort, state.simStatuses, state.liveStatuses]);

  useEffect(() => {
    let pages = [];
    for (let i = 0; i < strategiesIds.length / (strategiesPerPage ? strategiesPerPage : strategiesIds.length); i++) {
      pages.push(i);
    }
    setPages(pages);
    setStartIndex((strategiesPerPage ? strategiesPerPage : 0) * page);
    setEndIndex(
      (strategiesPerPage ? strategiesPerPage : 0) * page +
        (strategiesPerPage ? strategiesPerPage : strategiesIds.length)
    );
    if (page > pages.length - 1) {
      setPage(Math.max(pages.length - 1, 0));
    }
  }, [page, strategiesIds, strategiesPerPage]);

  const createNewStrategyCallback = useCallback(() => {
    props.setSaveFromOptimization(false);
    props.setEditType(
      <>
        <FontAwesomeIcon icon={faPuzzlePiece} /> New Strategy
      </>
    );
    props.setStrategyToEdit({
      id: generateUniqueId(state.strategies, state.user.id, "id"),
      editDate: new Date().getTime(),
      version: strategyVersion,
      name: "",
      buyRules: [],
      sellRules: [],
      tBuy: "",
      tBuyWait: "",
      stoploss: "",
      target: "",
      trailingTarget: "",
      timeClose: "",
      exchange: state.demo || props.freeBacktest ? exchanges[1] : exchanges[0],
      pair: state.demo ? "BTCUSDT" : "",
      pairDetails: state.demo
        ? {
            baseMinQty: 0.000001,
            baseMaxQty: 9000,
            baseStepSize: 0.000001,
            minPrice: 0.01,
            maxPrice: 1000000,
            tickSize: 0.01,
            baseDecimal: 6,
            quotedDecimal: 2,
            baseAsset: "BTC",
            quoteAsset: "USDT",
          }
        : null,
      userId: state.user.id,
      sim: {
        maxDrawdown: "",
        maxLoss: "",
        maxNegTrades: "",
        commissionRate: "",
        orderDetails: { type: "market", value: "" },
        tradeSize: { type: "base", value: "" },
      },
      live: {
        maxDrawdown: "",
        maxLoss: "",
        maxNegTrades: "",
        commissionRate: "",
        orderDetails: { type: "market", value: "" },
        tradeSize: { type: "base", value: "" },
      },
    });
  }, [state.strategies, state.user, state.demo, props.freeBacktest]);

  const loadTemplateCallback = useCallback(() => {
    if (state.demo) {
      showInfoDialog(
        dispatch,
        <span className="text-info">
          <FontAwesomeIcon icon={faExclamationTriangle} /> Demo mode
        </span>,
        "Cannot be used in DEMO mode!"
      );
      return;
    }

    showInfoDialog(
      dispatch,
      <div className="">
        <FontAwesomeIcon className="text-info mr-2" icon={faStar} />
        Load template
      </div>,
      <div>
        <h5 className="text-center mb-3">
          Load template {<FontAwesomeIcon className="text-info" icon={faArrowRight} />} choose pair{" "}
          {<FontAwesomeIcon className="text-info" icon={faArrowRight} />} optimize{" "}
          {<FontAwesomeIcon className="text-info" icon={faArrowRight} />} trade!
        </h5>
        <div className="table-responsive rounded user-select-none overflow-auto max-h-60-vh">
          <table className="table table-sm table-new-dark table-striped small ">
            <thead className="thead-new-dark">
              <tr>
                <th className="text-left pl-3">Strategy</th>
                <th className="text-left d-none d-lg-table-cell">Description</th>
                <th className="text-left d-none d-lg-table-cell"></th>
              </tr>
            </thead>
            <tbody>
              {templateStratgies.map((template) => {
                return (
                  <tr key={template.name}>
                    <td className="text-left pl-3 pr-5">{template.name}</td>
                    <td className="text-left d-none d-lg-table-cell pr-5">{template.desc}</td>
                    <td className="text-right pr-3">
                      <button
                        type="button"
                        className="btn btn-sm btn-info"
                        onClick={() => {
                          if ($("#infoDialog").is(":visible")) {
                            $("#infoDialog").modal("toggle");
                          }
                          props.setEditType(
                            <>
                              <FontAwesomeIcon icon={faStar} /> Create from Template
                            </>
                          );
                          let strategy = {
                            ...template.strategy,
                            userId: state.user.id,
                            version: strategyVersion,
                            exchange: props.freeBacktest ? exchanges[1] : exchanges[0],
                            optimize: optimizeIntervals[0],
                            conditionDirection: "",
                            id: generateUniqueId(state.strategies, state.user.id, "id"),
                            editDate: new Date().getTime(),
                            sim: {
                              maxDrawdown: "",
                              maxLoss: "",
                              maxNegTrades: "",
                              commissionRate: "",
                              orderDetails: { type: "market", value: "" },
                              tradeSize: { type: "base", value: "" },
                            },
                            live: {
                              maxDrawdown: "",
                              maxLoss: "",
                              maxNegTrades: "",
                              commissionRate: "",
                              orderDetails: { type: "market", value: "" },
                              tradeSize: { type: "base", value: "" },
                            },
                          };
                          props.setSaveFromOptimization(false);
                          props.setStrategyToEdit(strategy);
                        }}
                      >
                        Load
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>,
      true,
      true
    );
  }, [state.strategies, state.user, state.demo, props.freeBacktest]);

  return (
    <>
      {!state.user.isAdmin && (
        <div className="d-flex pb-2">
          <button
            className="btn btn-sm btn-outline-info bg-new-dark hover-light fade-in-fast mr-3"
            onClick={(e) => {
              createNewStrategyCallback();
            }}
          >
            Create
            <FontAwesomeIcon icon={faPlus} className="ml-2" />
          </button>
          <button
            className="btn btn-sm btn-outline-info bg-new-dark hover-light fade-in-fast mr-3"
            onClick={async () => {
              loadTemplateCallback();
            }}
          >
            Template
            <FontAwesomeIcon icon={faStar} className="ml-2" />
          </button>

          {props.selectedStrategy && (
            <button
              className="btn btn-sm btn-outline-info bg-new-dark hover-light fade-in-fast"
              onClick={(e) => {
                e.preventDefault();
                document.activeElement.blur();

                if (
                  !state.app &&
                  !state.demo &&
                  (state.simStatuses[props.selectedStrategy.id] || state.liveStatuses[props.selectedStrategy.id])
                ) {
                  showInfoDialog(
                    dispatch,
                    <span className="text-danger">
                      <FontAwesomeIcon icon={faExclamationTriangle} /> Cannot edit in browser
                    </span>,
                    <>Cannot edit strategies used in live or simulated trading in the browser.</>
                  );
                  return;
                }

                props.setStrategyToEdit(props.selectedStrategy);
                props.setSaveFromOptimization(false);
                props.setEditType(
                  <>
                    <FontAwesomeIcon icon={faEdit} /> Edit Strategy
                  </>
                );
              }}
            >
              Edit
              <FontAwesomeIcon icon={faEdit} className="ml-2" />
            </button>
          )}
          {props.setPanelExpanded && (
            <a
              className="text-light-green ml-auto"
              href="/#"
              title={props.panelExpanded ? "Collapse" : "Expand"}
              onClick={(e) => {
                e.preventDefault();
                document.activeElement.blur();
                props.setPanelExpanded(!props.panelExpanded);
              }}
            >
              {props.panelExpanded ? (
                <FontAwesomeIcon className="small fade-in" icon={faCompressAlt} />
              ) : (
                <FontAwesomeIcon className="small fade-in" icon={faExpandArrowsAlt} />
              )}
            </a>
          )}
        </div>
      )}

      {!props.hideGroup && (
        <div className="d-flex justify-content-between flex-wrap my-1">
          <Dropdown
            btnClasses="d-flex text-light hover-border-bottom info pl-0 pb-2"
            arrow
            arrowClasses="mt-1"
            selectedClasses="text-truncate max-w-270 mr-1"
            menuClasses={`bg-new-dark border border-secondary rounded small w-250 py-1 ${
              props.panelHeight ? "" : "max-h-250"
            }`}
            menuItemsClasses="text-light hover-info px-3 py-1"
            menuStyle={
              props.panelHeight
                ? {
                    maxHeight: Math.max(100, props.panelHeight - dropdownHeightSubtract),
                  }
                : {}
            }
            selected={props.activeGroup ? props.activeGroup.name : state.strategyGroups[0].name}
            setSelected={(group) => {
              props.setActiveGroupIdCallback(group.id);
            }}
            items={state.strategyGroups.filter((gr) => !gr.isDefault)}
            additionalOptions={[
              {
                label: (
                  <div className="d-flex">
                    Create Group
                    <FontAwesomeIcon className="ml-auto mt-1" icon={faPlus} />
                  </div>
                ),
                classes: "text-info border-bottom border-secondary px-3 pt-1 pb-2",
                fn: () => {
                  if (!$("#setGroup").is(":visible")) {
                    $("#setGroup").modal("toggle");
                  }
                },
              },
              {
                label: state.strategyGroups[0].name,
                classes: "text-light hover-info px-3 pt-2 pb-1",
                fn: () => {
                  props.setActiveGroupIdCallback(state.strategyGroups[0].id);
                },
              },
              {
                label: state.strategyGroups[1].name,
                classes: "text-light hover-info px-3 pt-1 pb-2",
                fn: () => {
                  props.setActiveGroupIdCallback(state.strategyGroups[1].id);
                },
              },
              {
                label: state.strategyGroups[2].name,
                classes: "text-light hover-info px-3 pt-1 pb-2",
                fn: () => {
                  props.setActiveGroupIdCallback(state.strategyGroups[2].id);
                },
              },
              {
                label: state.strategyGroups[3].name,
                classes: `text-light hover-info px-3 pt-1 pb-2 ${
                  state.strategyGroups.length > 4 ? "border-bottom border-secondary mb-1" : ""
                }`,
                fn: () => {
                  props.setActiveGroupIdCallback(state.strategyGroups[3].id);
                },
              },
            ]}
          />

          {props.activeGroup && !props.activeGroup.isDefault && (
            <>
              <div className="ml-2">
                <DropdownWithSearch
                  classesInput="search-bar text-left h-33 w-150 pb-2 px-2"
                  dropMenuClasses={`bg-new-dark border border-secondary rounded w-250 small right-0 ${
                    props.panelHeight ? "" : "max-h-250"
                  }`}
                  dropMenuItemsClasses="text-light items-info text-wrap"
                  menuStyle={
                    props.panelHeight
                      ? {
                          maxHeight: Math.max(100, props.panelHeight - dropdownHeightSubtract),
                        }
                      : {}
                  }
                  placeholder="Add to group +"
                  selected={strategySearch}
                  setSelected={(strategy) => {
                    if (!props.activeGroup.strategies.includes(strategy.id)) {
                      props.activeGroup.strategies.push(strategy.id);
                      props.activeGroup.editDate = new Date().getTime();
                      saveStrategyGroup(props.activeGroup, state.user.token, dispatch);

                      props.setActiveGroup({ ...props.activeGroup });
                    }
                    setStrategySearch("");
                  }}
                  deleteSearchText={true}
                  closeOnEsc={true}
                  showOnFocus={true}
                  onBlur={() => {}}
                  items={state.strategies.map((el) => ({
                    id: el.id,
                    name: el.name,
                  }))}
                />
              </div>

              <Dropdown
                btnClasses="text-info hover-light"
                selectedClasses=""
                menuClasses="bg-new-dark border border-secondary rounded small text-nowrap  right-0 py-1"
                menuItemsClasses="text-light hover-info px-3 py-1"
                selected={<FontAwesomeIcon className="hover-light" icon={faCog} />}
                setSelected={() => {}}
                items={[]}
                additionalOptions={[
                  {
                    label: "Edit group",
                    classes: "text-info border-bottom border-secondary px-3 pt-1 pb-2",
                    fn: () => {
                      setGroupName(props.activeGroup.name);
                      setEditGroup(true);
                      setEditGroupStrategies(
                        state.strategies.filter((el) => props.activeGroup.strategies.includes(el.id))
                      );
                      if (!$("#setGroup").is(":visible")) {
                        $("#setGroup").modal("toggle");
                      }
                    },
                  },
                  {
                    label: "Delete group",
                    classes: "text-info px-3 pt-2 pb-1",
                    fn: () => {
                      setCheckAllStrategies(false);
                      setCheckedStrategies([]);
                      deleteStrategyGroup(props.activeGroup.id, state.user.token, dispatch);
                      props.setActiveGroupIdCallback(state.strategyGroups[0].id);
                    },
                  },
                ]}
              />
            </>
          )}
        </div>
      )}
      <div className="d-flex text-nowrap user-select-none my-2 ml-1">
        {!state.user.isAdmin && (
          <div className="">
            <div className="custom-control custom-checkbox d-inline-block" key="All">
              <input
                type="checkbox"
                // disabled={strategiesIds.length===0}
                className="custom-control-input"
                id="allStr1"
                checked={checkAllStrategies}
                onChange={(e) => {
                  if (checkAllStrategies) {
                    setCheckedStrategies([]);
                  } else {
                    setCheckedStrategies(strategiesIds.slice(startIndex, endIndex));
                  }
                  setCheckAllStrategies(!checkAllStrategies);
                }}
              />
              <label
                type="text"
                className="custom-control-label font-weight-light font-italic"
                htmlFor="allStr1"
              ></label>
            </div>
            {!props.hideGroup && (
              <>
                {false && (
                  <a
                    href="#/"
                    className="text-info mr-2"
                    title="Use in Contest"
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      if (checkedStrategies.length === 0) {
                        return;
                      } else {
                        showConfirmDialog(
                          dispatch,
                          <span className="text-info">
                            <FontAwesomeIcon icon={faTrophy} /> Use in the Contest?
                          </span>,
                          <>
                            Use the selected {checkedStrategies.length === 1 ? "strategy" : "strategies"} in the
                            contest?
                          </>,
                          () => {
                            checkedStrategies.forEach((strategyId) =>
                              dispatch({
                                type: "addContestStrategy",
                                payload: {
                                  ...state.strategies.find((el) => el.id === strategyId),
                                  id: generateUniqueId(state.contestStrategies, state.user.id, "id"),
                                  creationDate: new Date().getTime(),
                                  editDate: new Date().getTime(),
                                  exchange: "Binance",
                                  pair: state.contest.pair,
                                  pairDetails: state.contest.pairDetails,
                                  sim: {
                                    maxDrawdown: "",
                                    maxLoss: "",
                                    maxNegTrades: "",
                                    commissionRate: "",
                                    orderDetails: { type: "market", value: "" },
                                    tradeSize: { type: "base", value: "" },
                                  },
                                  live: {
                                    maxDrawdown: "",
                                    maxLoss: "",
                                    maxNegTrades: "",
                                    commissionRate: "",
                                    orderDetails: { type: "market", value: "" },
                                    tradeSize: { type: "base", value: "" },
                                  },
                                },
                              })
                            );
                          }
                        );
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faTrophy} />
                  </a>
                )}

                <Dropdown
                  btnClasses={`text-info mr-2 ${checkedStrategies.length === 0 ? "opacity-5" : "opacity-1"}`}
                  disabled={checkedStrategies.length === 0}
                  title="Add selected to group"
                  selectedClasses=""
                  menuClasses={`bg-new-dark border border-secondary rounded text-wrap small w-250 py-1 ${
                    props.panelHeight ? "" : "max-h-250"
                  }`}
                  menuItemsClasses="text-light hover-info px-3 py-1"
                  menuStyle={
                    props.panelHeight
                      ? {
                          maxHeight: Math.max(100, props.panelHeight - dropdownHeightSubtract - 32),
                        }
                      : {}
                  }
                  selected={
                    <FontAwesomeIcon
                      onClick={(e) => {
                        if (checkedStrategies.length === 0) {
                          showInfoDialog(
                            dispatch,
                            <span className="text-info">
                              <FontAwesomeIcon icon={faInfoCircle} /> No Strategies Selected
                            </span>,
                            <>Select strategies first.</>
                          );
                        }
                      }}
                      icon={faFolderPlus}
                    />
                  }
                  setSelected={(group) => {
                    checkedStrategies.forEach((id) => {
                      if (!group.strategies.includes(id)) {
                        group.strategies.push(id);
                      }
                    });
                    group.editDate = new Date().getTime();
                    saveStrategyGroup(group, state.user.token, dispatch);
                    showInfoDialog(
                      dispatch,
                      <span className="text-info">
                        <FontAwesomeIcon icon={faInfoCircle} /> Info
                      </span>,
                      <>
                        {checkedStrategies.length} strategies were added to group {group.name}
                      </>
                    );
                  }}
                  items={state.strategyGroups.filter((gr) => !gr.isDefault)}
                  additionalOptions={[
                    {
                      label: (
                        <div className="d-flex">
                          Add to new group
                          <FontAwesomeIcon className="ml-auto mt-1" icon={faPlus} />
                        </div>
                      ),
                      classes: "text-info border-bottom border-secondary px-3 pt-1 pb-2",
                      fn: () => {
                        setGroupName("");
                        setAddStrategiesToNewGroup(true);
                        if (!$("#setGroup").is(":visible")) {
                          $("#setGroup").modal("toggle");
                        }
                      },
                    },
                  ]}
                />
                <a
                  href="#/"
                  className={`text-info mr-2 ${
                    checkedStrategies.length === 0 || (props.activeGroup && props.activeGroup.isDefault)
                      ? "opacity-5"
                      : "opacity-1"
                  }`}
                  title="Remove selected from group"
                  onClick={(e) => {
                    e.preventDefault();
                    document.activeElement.blur();
                    if (props.activeGroup.isDefault) {
                      return;
                    }
                    if (checkedStrategies.length === 0) {
                      showInfoDialog(
                        dispatch,
                        <span className="text-info">
                          <FontAwesomeIcon icon={faInfoCircle} /> No Strategies Selected
                        </span>,
                        <>Select strategies first.</>
                      );
                      return;
                    } else {
                      showConfirmDialog(
                        dispatch,
                        <span className="text-info">
                          <FontAwesomeIcon icon={faQuestionCircle} /> Remove strategies from group
                        </span>,
                        <>
                          Are you sure you want to remove the selected strategies from the {props.activeGroup.name}{" "}
                          group?
                        </>,
                        () => {
                          props.activeGroup.strategies = props.activeGroup.strategies.filter(
                            (el) => !checkedStrategies.includes(el)
                          );
                          props.activeGroup.editDate = new Date().getTime();
                          saveStrategyGroup(props.activeGroup, state.user.token, dispatch);
                          setCheckedStrategies([]);
                          setCheckAllStrategies(false);
                        }
                      );
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faFolderMinus} />
                </a>
              </>
            )}
            <a
              href="#/"
              className={`text-danger ${checkedStrategies.length === 0 ? "opacity-5" : "opacity-1"}`}
              title="Delete selected strategies"
              onClick={(e) => {
                e.preventDefault();
                document.activeElement.blur();
                if (checkedStrategies.length === 0) {
                  showInfoDialog(
                    dispatch,
                    <span className="text-info">
                      <FontAwesomeIcon icon={faInfoCircle} /> No Strategies Selected
                    </span>,
                    <>Select strategies first.</>
                  );
                  return;
                } else {
                  showConfirmDialog(
                    dispatch,
                    <span className="text-info">
                      <FontAwesomeIcon icon={faQuestionCircle} /> Delete selected strategies
                    </span>,
                    <>Are you sure you want to delete the selected strategies?</>,
                    () => {
                      let notDeleted = [];

                      let toDelete = checkedStrategies.filter((strategyId) => {
                        let result = state.app || (!state.simStatuses[strategyId] && !state.liveStatuses[strategyId]);
                        if (!result) {
                          notDeleted.push(strategyId);
                        }
                        return result;
                      });

                      dispatch({
                        type: "removeStrategies",
                        payload: toDelete,
                      });

                      if (
                        checkedStrategies.includes(props.selectedStrategyId) &&
                        !notDeleted.includes(props.selectedStrategyId)
                      ) {
                        props.setSelectedStrategyIdCallback(null);
                      }
                      if (notDeleted.length > 0) {
                        showInfoDialog(
                          dispatch,
                          <span className="text-danger">
                            <FontAwesomeIcon icon={faExclamationTriangle} /> Cannot delete in browser
                          </span>,
                          <>Cannot delete strategies used in live or simulated trading in the browser.</>
                        );
                      }
                      setCheckedStrategies([]);
                      setCheckAllStrategies(false);
                    }
                  );
                }
              }}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </a>
          </div>
        )}

        <div className="align-center mr-auto ml-2">
          <FontAwesomeIcon className="text-info" icon={faSearch} />
          <input
            type="text"
            disabled={props.activeGroup ? props.activeGroup.strategies.length === 0 : false}
            className="search-bar text-left small w-100-px px-1"
            value={strategiesFilter}
            placeholder="Search in group"
            onChange={(e) => {
              setStrategiesFilter(e.target.value);
              setStrategiesIds(
                getFilteredSortedStrategiesIds(
                  state.strategies.filter((el) => props.activeGroup.strategies.includes(el.id)),
                  e.target.value,
                  strategiesSort,
                  state.simStatuses,
                  state.liveStatuses
                )
              );
              setCheckAllStrategies(false);
              setCheckedStrategies([]);
            }}
          ></input>
        </div>
        <div className="text-nowrap user-select-none ml-auto">
          <span className="small mr-2">Show:</span>
          <div className="d-inline-block">
            <input
              type="number"
              className="search-bar text-left small w-25-px"
              value={strategiesPerPage}
              placeholder="All"
              onChange={(e) => {
                let value = Math.abs(Number.parseInt(e.target.value));
                let strPerPage = !isNaN(value) && value > 0 ? value : "";
                setStrategiesPerPage(strPerPage);
                localStorage.setItem("strategiesPerPage", strPerPage);
                setCheckAllStrategies(false);
                setCheckedStrategies([]);
              }}
            ></input>
          </div>
        </div>
      </div>
      <div
        className={`table-responsive border border-dark rounded user-select-none overflow-auto ${
          props.panelHeight ? "" : "max-h-fullscreen"
        }`}
        style={props.panelHeight ? { maxHeight: Math.max(100, props.panelHeight - 159) } : {}}
      >
        <table className="table rounded table-sm small table-new-dark table-hover sortable-table mb-0">
          <thead className="thead-new-dark">
            <tr className="user-select-none">
              {!state.user.isAdmin && <th></th>}
              <th
                className="text-left text-nowrap"
                onClick={() => {
                  let newSort = strategiesSort !== "name" ? "name" : "nameR";
                  localStorage.setItem("strategiesSort", newSort);
                  setStrategiesSort(newSort);
                }}
              >
                Strategy
                {(strategiesSort === "name" || strategiesSort === "nameR") && (
                  <FontAwesomeIcon className="ml-2" icon={faArrowsAltV} />
                )}
              </th>
              <th
                className="text-left text-nowrap d-none d-lg-table-cell"
                onClick={() => {
                  let newSort = strategiesSort !== "ex" ? "ex" : "exR";
                  localStorage.setItem("strategiesSort", newSort);
                  setStrategiesSort(newSort);
                }}
              >
                Exchange
                {(strategiesSort === "ex" || strategiesSort === "exR") && (
                  <FontAwesomeIcon className="ml-2" icon={faArrowsAltV} />
                )}
              </th>
              <th
                className="text-left text-nowrap"
                onClick={() => {
                  let newSort = strategiesSort !== "pair" ? "pair" : "pairR";
                  localStorage.setItem("strategiesSort", newSort);
                  setStrategiesSort(newSort);
                }}
              >
                Pair
                {(strategiesSort === "pair" || strategiesSort === "pairR") && (
                  <FontAwesomeIcon className="ml-2" icon={faArrowsAltV} />
                )}
              </th>
              <th
                className="text-left text-nowrap"
                onClick={() => {
                  let newSort = strategiesSort !== "trading" ? "trading" : "tradingR";
                  localStorage.setItem("strategiesSort", newSort);
                  setStrategiesSort(newSort);
                }}
              >
                <FontAwesomeIcon className="" icon={faChartLine} />
                {(strategiesSort === "trading" || strategiesSort === "tradingR") && (
                  <FontAwesomeIcon className="ml-2" icon={faArrowsAltV} />
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {state.strategies.length > 0 ? (
              <>
                {strategiesIds.slice(startIndex, endIndex).map((id) => {
                  let strategy = state.strategies.find((el) => el.id === id);
                  if (!strategy) {
                    return null;
                  }
                  let isChecked = checkedStrategies.includes(strategy.id);
                  return (
                    <tr key={strategy.id} className={strategy.id === props.selectedStrategyId ? "selected" : ""}>
                      {!state.user.isAdmin && (
                        <td className="text-left text-nowrap ">
                          <div className="custom-control custom-checkbox d-inline-block">
                            <input
                              type="checkbox"
                              id={`${strategy.id}Check`}
                              className="custom-control-input"
                              checked={isChecked}
                              onChange={(e) => {
                                if (isChecked) {
                                  let newCheckedStrategies = checkedStrategies.filter((el) => el !== strategy.id);
                                  setCheckedStrategies(newCheckedStrategies);
                                  setCheckAllStrategies(false);
                                } else {
                                  let newCheckedStrategies = [...checkedStrategies, strategy.id];
                                  if (
                                    newCheckedStrategies.length === strategiesIds.slice(startIndex, endIndex).length
                                  ) {
                                    setCheckAllStrategies(true);
                                  }
                                  setCheckedStrategies(newCheckedStrategies);
                                }
                              }}
                            />
                            <label
                              type="text"
                              className="custom-control-label font-weight-light font-italic"
                              htmlFor={`${strategy.id}Check`}
                            ></label>
                          </div>
                          <a
                            href="#/"
                            className="text-info align-middle"
                            title="Duplicate"
                            onClick={(e) => {
                              e.preventDefault();
                              document.activeElement.blur();
                              let strategyTmp = {
                                ...strategy,
                                buyRules: strategy.buyRules.map((el) => ({
                                  ...el,
                                })),
                                sellRules: strategy.sellRules.map((el) => ({
                                  ...el,
                                })),
                                id: generateUniqueId(state.strategies, state.user.id, "id"),
                                editDate: new Date().getTime(),
                                sim: {
                                  maxDrawdown: "",
                                  maxLoss: "",
                                  maxNegTrades: "",
                                  commissionRate: "",
                                  orderDetails: { type: "market", value: "" },
                                  tradeSize: { type: "base", value: "" },
                                },
                                live: {
                                  maxDrawdown: "",
                                  maxLoss: "",
                                  maxNegTrades: "",
                                  commissionRate: "",
                                  orderDetails: { type: "market", value: "" },
                                  tradeSize: { type: "base", value: "" },
                                },
                              };
                              props.setSaveFromOptimization(false);
                              props.setEditType(
                                <>
                                  <FontAwesomeIcon icon={faClone} /> Duplicate Strategy
                                </>
                              );
                              props.setStrategyToEdit(strategyTmp);
                            }}
                          >
                            <FontAwesomeIcon icon={faClone} />
                          </a>
                        </td>
                      )}

                      <td
                        className="text-left text-wrap cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          document.activeElement.blur();
                          props.setSelectedStrategyIdCallback(id);
                          if (props.setActiveSubPageCallback) {
                            props.setActiveSubPageCallback(panelsOptions[2]);
                          }
                        }}
                      >
                        {strategy.name}
                      </td>

                      <td
                        className="text-left text-nowrap d-none d-lg-table-cell cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          document.activeElement.blur();
                          props.setSelectedStrategyIdCallback(id);
                          if (props.setActiveSubPageCallback) {
                            props.setActiveSubPageCallback(panelsOptions[2]);
                          }
                        }}
                      >
                        {strategy.exchange}
                      </td>
                      <td
                        className="text-left cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          document.activeElement.blur();
                          props.setSelectedStrategyIdCallback(id);
                          if (props.setActiveSubPageCallback) {
                            props.setActiveSubPageCallback(panelsOptions[2]);
                          }
                        }}
                      >
                        {strategy.pair}
                      </td>
                      <td
                        className="text-left cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          document.activeElement.blur();
                          props.setSelectedStrategyIdCallback(id);
                          if (props.setActiveSubPageCallback) {
                            props.setActiveSubPageCallback(panelsOptions[2]);
                          }
                        }}
                      >
                        {state.liveStatuses[strategy.id] ? (
                          state.simStatuses[strategy.id] ? (
                            <span className="cursor-help" title="In Live Trading and Simulation">
                              L S
                            </span>
                          ) : (
                            <span className="cursor-help" title="In Live Trading">
                              L
                            </span>
                          )
                        ) : state.simStatuses[strategy.id] ? (
                          <span className="cursor-help" title="In Simulation">
                            S
                          </span>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  );
                })}
                {props.activeGroup &&
                  props.activeGroup.strategies.length === 0 &&
                  (props.activeGroup.id === "0_01" || props.activeGroup.id === "0_02" ? (
                    <tr>
                      <td colSpan="4" className="align-middle">
                        <div className="text-secondary text-center px-5">
                          No strategies in live or simulation trading
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="4" className="align-middle">
                        <div className="text-secondary text-center px-5">Add strategies to your group</div>
                      </td>
                    </tr>
                  ))}
              </>
            ) : (
              <tr className="no-hover">
                <td colSpan="4" className="text-center align-middle">
                  <h5 className="text-secondary pt-2 px-sm-5 pt-sm-4">Your strategies will go here. </h5>
                  <div className="text-secondary pb-2 pb-sm-4">
                    <a
                      className="text-info mr-2"
                      href="#/"
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                        createNewStrategyCallback();
                      }}
                    >
                      Create New
                    </a>
                    or
                    <a
                      className="text-info ml-2"
                      href="#/"
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                        loadTemplateCallback();
                      }}
                    >
                      Load Template
                    </a>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {pages.length > 0 && (
        <div className="overflow-auto my-2">
          <nav aria-label="page navigation">
            <ul className="pagination">
              <li className="page-item" key="prev">
                <a
                  className="page-link small px-2 py-1"
                  aria-label="Previous"
                  href="#/"
                  onClick={(e) => {
                    e.preventDefault();
                    if (page > 0) {
                      setPage(page - 1);
                      setCheckAllStrategies(false);
                      setCheckedStrategies([]);
                    }
                  }}
                >
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </a>
              </li>
              {pages.map((el) => {
                return (
                  <li className={"page-item " + (page === el ? "active" : "")} key={el}>
                    <a
                      className="page-link small px-2 py-1"
                      href="#/"
                      onClick={(e) => {
                        e.preventDefault();
                        setPage(el);
                        setCheckAllStrategies(false);
                        setCheckedStrategies([]);
                      }}
                    >
                      {el + 1}
                    </a>
                  </li>
                );
              })}

              <li className="page-item" key="next">
                <a
                  className="page-link small px-2 py-1"
                  href="#/"
                  aria-label="Next"
                  onClick={(e) => {
                    e.preventDefault();
                    if (
                      page <
                      Math.floor(strategiesIds.length / (strategiesPerPage ? strategiesPerPage : strategiesIds.length))
                    ) {
                      setPage(page + 1);
                      setCheckAllStrategies(false);
                      setCheckedStrategies([]);
                    }
                  }}
                >
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      )}

      <ModalConfirm
        id="setGroup"
        dark
        header={editGroup ? "Edit Group" : `Create group${addStrategiesToNewGroup ? " and add selected" : ""}`}
        okText="OK"
        content={
          <div className="overflow-auto d-flex max-h-fullscreen">
            <div className="px-3">Name:</div>
            <div className="flex-grow-1 mr-3">
              <input
                type="text"
                placeholder="Enter group name"
                className="text-light placeholder-standard border-bottom-light hover-border-bottom-info focus-border-bottom-info small w-100"
                value={groupName}
                onChange={(e) => {
                  setGroupName(e.target.value);
                }}
              />
            </div>
            {editGroup && (
              <div className="mt-4 ml-3">
                Strategies currently in the group:
                {editGroupStrategies.map((el, index) => {
                  return (
                    <div key={index} className="d-flex small text-nowrap mt-2 mx-3">
                      <a
                        href="#/"
                        className="text-danger"
                        title="Remove strategies from group"
                        onClick={(e) => {
                          e.preventDefault();
                          document.activeElement.blur();
                          setEditGroupStrategies(editGroupStrategies.filter((strategy) => strategy.id !== el.id));
                        }}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </a>
                      <div className="text-wrap ml-2">{el.name}</div>
                    </div>
                  );
                })}
              </div>
            )}
            {addStrategiesToNewGroup && (
              <div className="mt-4 ml-3">
                Strategies:
                {checkedStrategies.map((el, index) => {
                  let strategy = state.strategies.find((strategy) => strategy.id === el);
                  return (
                    <div key={index} className="d-flex small text-nowrap mt-2 mx-3">
                      <FontAwesomeIcon className="text-success mt-1" icon={faPlus} />
                      <div className="text-wrap ml-2">{strategy.name}</div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        }
        okDisabled = {!groupName}
        func={() => {
          if (editGroup) {
            props.activeGroup.name = groupName;
            props.activeGroup.editDate = new Date().getTime();
            let newGroup = {
              ...props.activeGroup,
              strategies: editGroupStrategies.map((el) => el.id),
            };
            setEditGroup(false);
            saveStrategyGroup(newGroup, state.user.token, dispatch);
            props.setActiveGroup(newGroup);
          } else {
            let id = `${state.user.id}_${new Date().getTime()}`;
            let gr = {
              id: id,
              name: groupName,
              strategies: [],
              userId: state.user.id,
              editDate: new Date().getTime(),
            };
            if (addStrategiesToNewGroup) {
              setAddStrategiesToNewGroup(false);
              gr.strategies = checkedStrategies.map((el) => el);
              saveStrategyGroup(gr, state.user.token, dispatch);
              showInfoDialog(
                dispatch,
                <span className="text-info">
                  <FontAwesomeIcon icon={faInfoCircle} /> Info
                </span>,
                <>Group {groupName} was created.</>
              );
            } else {
              saveStrategyGroup(gr, state.user.token, dispatch);
              props.setActiveGroupIdCallback(id);
              props.setActiveGroup({ ...gr });
              props.setSelectedStrategyIdCallback(null);
              props.selectedStrategyIdRef.current = null;
            }
          }
          setGroupName("");
        }}
        closeFn={() => {
          setEditGroup(false);
          setAddStrategiesToNewGroup(false);
          setEditGroupStrategies([]);
        }}
      />
    </>
  );
};
export { copyStrategy, AddRulePopup, StrategyToEdit, InspectStrategy, InspectStrategyPopup, StrategyGroups };
