import $ from "jquery";
import CryptoJS from "crypto-js";
import getExchange from "./Exchange.js";
import getEcbDatabase from "./EcbDatabase.js";
import Mutex from "./Mutex.js";
import HomeApp from "./HomeApp.js";
import HomeAdmin from "./HomeAdmin.js";
import BotTrading, {
  strategyVersion,
  statusVersion,
} from "./StrategiesMain.js";
import BotTradingSaver from "./BotTradingSaver.js";
import ReportedBugs from "./ReportedBugs.js";
import Portfolio from "./Portfolio.js";
import { SignIn, Lock, Unlock } from "./SignIn.js";
import { Demo, QuitDemo } from "./Demo.js";
import Settings from "./Settings.js";
import Manual from "./Manual.js";
import TraficSaver from "./TraficSaver.js";
import Contact from "./Contact.js";
import FreeBacktest from "./FreeBacktest.js";
import { ruleDefaults } from "./Rules.js";
import echarts from "echarts/lib/echarts";
import { getTimeframes, ensureDate, getIndicatorId } from "./tatools.js";
import Contest from "./Contest.js";
import { add2, add3 } from "./Bot.js";
import {
  timeframes,
  indicators,
  indicatorsFull,
  exchanges,
  greaterLessDirections,
  candlePatterns,
  upDownTrend,
} from "./constants/consts.json";
/* eslint-disable no-unused-expressions */
/* eslint-disable no-func-assign */
/* eslint-disable no-sequences */
const a0_0xd18718 = a0_0x1f43;
(function (_0x447c84, _0x14968a) {
  const _0xf2f122 = a0_0x1f43,
    _0x40b776 = _0x447c84();
  while (!![]) {
    try {
      const _0x31ae86 =
        (-parseInt(_0xf2f122(0x381)) / 0x1) *
          (-parseInt(_0xf2f122(0x2f2)) / 0x2) +
        (parseInt(_0xf2f122(0x302)) / 0x3) *
          (parseInt(_0xf2f122(0x26f)) / 0x4) +
        (parseInt(_0xf2f122(0x2be)) / 0x5) *
          (parseInt(_0xf2f122(0x371)) / 0x6) +
        (parseInt(_0xf2f122(0x3b1)) / 0x7) *
          (-parseInt(_0xf2f122(0x310)) / 0x8) +
        (parseInt(_0xf2f122(0x240)) / 0x9) *
          (parseInt(_0xf2f122(0x2e4)) / 0xa) +
        -parseInt(_0xf2f122(0x322)) / 0xb +
        -parseInt(_0xf2f122(0x383)) / 0xc;
      if (_0x31ae86 === _0x14968a) break;
      else _0x40b776["push"](_0x40b776["shift"]());
    } catch (_0xc417d0) {
      _0x40b776["push"](_0x40b776["shift"]());
    }
  }
})(a0_0x4e27, 0xb36b0);
const pricesUpdateDelay = 0xa,
  binanceMinPairs = 0x320,
  binanceUSMinPairs = 0x50;
function fixRules(_0x4c202b) {
  const _0x3f6624 = a0_0x1f43;
  let _0x4a7489 = [];
  for (let _0x146b6 = 0x0; _0x146b6 < _0x4c202b[_0x3f6624(0x349)]; _0x146b6++) {
    if (_0x4c202b[_0x146b6]["conditionsOrder"]) {
      _0x4a7489[_0x3f6624(0x266)](_0x4c202b[_0x146b6]);
      continue;
    }
    let _0x3d245f = {
        id: _0x4c202b[_0x146b6]["id"],
        timeframe: _0x4c202b[_0x146b6][_0x3f6624(0x2f1)],
        indicator: _0x4c202b[_0x146b6][_0x3f6624(0x395)],
        saved: !![],
      },
      _0x449c8b = ![];
    switch (_0x3d245f[_0x3f6624(0x395)]) {
      case _0x3f6624(0x1ce):
        (_0x4c202b[_0x146b6][_0x3f6624(0x1f7)] === "up" ||
          _0x4c202b[_0x146b6][_0x3f6624(0x1f7)] === _0x3f6624(0x36b)) &&
          ((_0x3d245f[_0x3f6624(0x2d4)] = {}),
          (_0x3d245f[_0x3f6624(0x2d4)][_0x3f6624(0x191)] = !![]),
          (_0x3d245f[_0x3f6624(0x2d4)][_0x3f6624(0x1f7)] =
            _0x4c202b[_0x146b6][_0x3f6624(0x1f7)]),
          (_0x3d245f[_0x3f6624(0x2d4)][_0x3f6624(0x2fb)] =
            _0x4c202b[_0x146b6][_0x3f6624(0x2fb)]),
          (_0x3d245f[_0x3f6624(0x2ee)] = [_0x3f6624(0x2d4)]),
          _0x4a7489["push"](_0x3d245f));
        break;
      case _0x3f6624(0x227):
        _0x4c202b[_0x146b6][_0x3f6624(0x1f7)] === _0x3f6624(0x35a) &&
          ((_0x3d245f["condition1"] = {}),
          (_0x3d245f[_0x3f6624(0x2d4)][_0x3f6624(0x191)] = !![]),
          (_0x3d245f[_0x3f6624(0x2d4)][_0x3f6624(0x35a)] =
            _0x4c202b[_0x146b6]["direction2"]),
          (_0x3d245f[_0x3f6624(0x2ee)] = ["condition1"]),
          (_0x449c8b = !![]));
        _0x4c202b[_0x146b6][_0x3f6624(0x1f7)] === "open\x20to\x20close" &&
          ((_0x3d245f[_0x3f6624(0x1c9)] = {}),
          (_0x3d245f["condition2"][_0x3f6624(0x191)] = !![]),
          (_0x3d245f[_0x3f6624(0x1c9)][_0x3f6624(0x1f7)] =
            _0x4c202b[_0x146b6][_0x3f6624(0x346)]["indexOf"](
              _0x3f6624(0x2cd)
            ) === 0x0
              ? _0x3f6624(0x2c8)
              : _0x3f6624(0x273)),
          (_0x3d245f[_0x3f6624(0x1c9)]["value"] =
            _0x4c202b[_0x146b6][_0x3f6624(0x2fb)]),
          (_0x3d245f["conditionsOrder"] = [_0x3f6624(0x1c9)]),
          (_0x449c8b = !![]));
        _0x4c202b[_0x146b6][_0x3f6624(0x1f7)] === _0x3f6624(0x330) &&
          ((_0x3d245f[_0x3f6624(0x318)] = {}),
          (_0x3d245f[_0x3f6624(0x318)][_0x3f6624(0x191)] = !![]),
          (_0x3d245f[_0x3f6624(0x318)][_0x3f6624(0x1f7)] =
            _0x4c202b[_0x146b6][_0x3f6624(0x346)][_0x3f6624(0x244)](
              "greater"
            ) === 0x0
              ? _0x3f6624(0x2c8)
              : _0x3f6624(0x273)),
          (_0x3d245f["condition3"][_0x3f6624(0x2fb)] =
            _0x4c202b[_0x146b6]["value"]),
          (_0x3d245f[_0x3f6624(0x2ee)] = ["condition3"]),
          (_0x449c8b = !![]));
        _0x4c202b[_0x146b6][_0x3f6624(0x1f7)] === _0x3f6624(0x2da) &&
          ((_0x3d245f["condition4"] = {}),
          (_0x3d245f[_0x3f6624(0x369)][_0x3f6624(0x191)] = !![]),
          (_0x3d245f[_0x3f6624(0x369)][_0x3f6624(0x1f7)] =
            _0x4c202b[_0x146b6][_0x3f6624(0x346)]["indexOf"]("greater") === 0x0
              ? _0x3f6624(0x2c8)
              : "less\x20than"),
          (_0x3d245f[_0x3f6624(0x369)][_0x3f6624(0x2fb)] =
            _0x4c202b[_0x146b6][_0x3f6624(0x2fb)]),
          (_0x3d245f["conditionsOrder"] = [_0x3f6624(0x369)]),
          (_0x449c8b = !![]));
        _0x4c202b[_0x146b6][_0x3f6624(0x1f7)] === _0x3f6624(0x2da) &&
          ((_0x3d245f[_0x3f6624(0x36d)] = {}),
          (_0x3d245f[_0x3f6624(0x36d)][_0x3f6624(0x191)] = !![]),
          (_0x3d245f[_0x3f6624(0x36d)]["direction"] =
            _0x4c202b[_0x146b6][_0x3f6624(0x346)][_0x3f6624(0x244)](
              _0x3f6624(0x2cd)
            ) === 0x0
              ? _0x3f6624(0x2c8)
              : _0x3f6624(0x273)),
          (_0x3d245f[_0x3f6624(0x36d)]["value"] =
            _0x4c202b[_0x146b6][_0x3f6624(0x2fb)]),
          (_0x3d245f[_0x3f6624(0x2ee)] = [_0x3f6624(0x36d)]),
          (_0x449c8b = !![]));
        _0x449c8b && _0x4a7489["push"](_0x3d245f);
        break;
      case "SMA":
      case "EMA":
        switch (_0x4c202b[_0x146b6][_0x3f6624(0x1f7)]) {
          case _0x3f6624(0x2af):
          case _0x3f6624(0x34e):
            (_0x3d245f = {
              id: _0x4c202b[_0x146b6]["id"],
              timeframe: _0x4c202b[_0x146b6][_0x3f6624(0x2f1)],
              indicator: "the\x20price",
              saved: !![],
            }),
              (_0x3d245f[_0x3f6624(0x1c9)] = {}),
              (_0x3d245f["condition2"][_0x3f6624(0x191)] = !![]),
              (_0x3d245f[_0x3f6624(0x1c9)]["indicator"] =
                _0x4c202b[_0x146b6][_0x3f6624(0x395)]),
              (_0x3d245f[_0x3f6624(0x1c9)][_0x3f6624(0x3ad)] =
                _0x4c202b[_0x146b6]["period"]),
              (_0x3d245f[_0x3f6624(0x1c9)][_0x3f6624(0x1f7)] =
                _0x4c202b[_0x146b6]["direction"] === _0x3f6624(0x2af)
                  ? _0x3f6624(0x34e)
                  : _0x3f6624(0x2af)),
              (_0x3d245f[_0x3f6624(0x2ee)] = ["condition2"]),
              _0x4a7489[_0x3f6624(0x266)](_0x3d245f);
            break;
          case _0x3f6624(0x1a8):
            (_0x3d245f[_0x3f6624(0x3ad)] =
              _0x4c202b[_0x146b6][_0x3f6624(0x3ad)]),
              (_0x3d245f["condition1"] = {}),
              (_0x3d245f[_0x3f6624(0x2d4)][_0x3f6624(0x191)] = !![]),
              (_0x3d245f["condition1"][_0x3f6624(0x395)] =
                _0x4c202b[_0x146b6][_0x3f6624(0x3c6)]),
              (_0x3d245f[_0x3f6624(0x2d4)][_0x3f6624(0x3ad)] =
                _0x4c202b[_0x146b6][_0x3f6624(0x2b4)]),
              (_0x3d245f[_0x3f6624(0x2d4)][_0x3f6624(0x247)] =
                _0x4c202b[_0x146b6][_0x3f6624(0x247)]),
              (_0x3d245f["conditionsOrder"] = [_0x3f6624(0x2d4)]),
              _0x4a7489["push"](_0x3d245f);
            break;
          case _0x3f6624(0x15b):
          case _0x3f6624(0x327):
            (_0x3d245f[_0x3f6624(0x3ad)] =
              _0x4c202b[_0x146b6][_0x3f6624(0x3ad)]),
              (_0x3d245f[_0x3f6624(0x369)] = {}),
              (_0x3d245f[_0x3f6624(0x369)][_0x3f6624(0x191)] = !![]),
              (_0x3d245f[_0x3f6624(0x369)][_0x3f6624(0x1f7)] =
                _0x4c202b[_0x146b6][_0x3f6624(0x1f7)] === _0x3f6624(0x15b)
                  ? _0x3f6624(0x3b0)
                  : _0x3f6624(0x20f)),
              (_0x3d245f[_0x3f6624(0x369)][_0x3f6624(0x2fb)] =
                _0x4c202b[_0x146b6][_0x3f6624(0x2fb)]),
              (_0x3d245f["conditionsOrder"] = ["condition4"]),
              _0x4a7489[_0x3f6624(0x266)](_0x3d245f);
            break;
          case _0x3f6624(0x192):
            (_0x3d245f[_0x3f6624(0x3ad)] =
              _0x4c202b[_0x146b6][_0x3f6624(0x3ad)]),
              (_0x3d245f[_0x3f6624(0x318)] = {}),
              (_0x3d245f["condition3"][_0x3f6624(0x191)] = !![]),
              (_0x3d245f["condition3"][_0x3f6624(0x1f7)] =
                _0x4c202b[_0x146b6][_0x3f6624(0x346)]),
              (_0x3d245f["condition3"][_0x3f6624(0x2fb)] = 0x2),
              (_0x3d245f[_0x3f6624(0x318)]["value2"] = 0.5),
              (_0x3d245f[_0x3f6624(0x2ee)] = [_0x3f6624(0x318)]),
              _0x4a7489[_0x3f6624(0x266)](_0x3d245f);
            break;
          default:
        }
        break;
      case _0x3f6624(0x1da):
        (_0x3d245f[_0x3f6624(0x3ad)] = _0x4c202b[_0x146b6][_0x3f6624(0x3ad)]),
          (_0x3d245f[_0x3f6624(0x2d4)] = {}),
          (_0x3d245f[_0x3f6624(0x2d4)][_0x3f6624(0x191)] = !![]),
          (_0x3d245f[_0x3f6624(0x2d4)][_0x3f6624(0x1f7)] =
            _0x4c202b[_0x146b6][_0x3f6624(0x1f7)]),
          (_0x3d245f[_0x3f6624(0x2d4)][_0x3f6624(0x2fb)] =
            _0x4c202b[_0x146b6]["value"]);
        _0x4c202b[_0x146b6][_0x3f6624(0x1f7)] === _0x3f6624(0x1a8) &&
          (_0x3d245f["condition1"][_0x3f6624(0x247)] =
            _0x4c202b[_0x146b6]["crossDirection"]);
        (_0x3d245f[_0x3f6624(0x2ee)] = [_0x3f6624(0x2d4)]),
          _0x4a7489[_0x3f6624(0x266)](_0x3d245f);
        break;
      case _0x3f6624(0x3c2):
        (_0x3d245f[_0x3f6624(0x3ad)] = _0x4c202b[_0x146b6][_0x3f6624(0x3ad)]),
          (_0x3d245f[_0x3f6624(0x2b4)] = _0x4c202b[_0x146b6][_0x3f6624(0x2b4)]),
          (_0x3d245f[_0x3f6624(0x373)] = _0x4c202b[_0x146b6][_0x3f6624(0x373)]);
        _0x4c202b[_0x146b6][_0x3f6624(0x346)] === "the\x20signal\x20line"
          ? ((_0x3d245f[_0x3f6624(0x2d4)] = {}),
            (_0x3d245f[_0x3f6624(0x2d4)][_0x3f6624(0x191)] = !![]),
            (_0x3d245f[_0x3f6624(0x2d4)]["direction"] =
              _0x4c202b[_0x146b6]["direction"]),
            _0x4c202b[_0x146b6]["direction"] === _0x3f6624(0x1a8) &&
              (_0x3d245f[_0x3f6624(0x2d4)][_0x3f6624(0x247)] =
                _0x4c202b[_0x146b6]["crossDirection"]),
            (_0x3d245f[_0x3f6624(0x2ee)] = [_0x3f6624(0x2d4)]))
          : ((_0x3d245f[_0x3f6624(0x1c9)] = {}),
            (_0x3d245f[_0x3f6624(0x1c9)]["saved"] = !![]),
            (_0x3d245f[_0x3f6624(0x1c9)]["direction"] =
              _0x4c202b[_0x146b6][_0x3f6624(0x1f7)]),
            _0x4c202b[_0x146b6][_0x3f6624(0x1f7)] === _0x3f6624(0x1a8) &&
              (_0x3d245f["condition2"][_0x3f6624(0x247)] =
                _0x4c202b[_0x146b6][_0x3f6624(0x247)]),
            (_0x3d245f[_0x3f6624(0x2ee)] = [_0x3f6624(0x1c9)]));
        _0x4a7489["push"](_0x3d245f);
        break;
      case "Bollinger\x20Bands":
        (_0x3d245f = {
          id: _0x4c202b[_0x146b6]["id"],
          timeframe: _0x4c202b[_0x146b6][_0x3f6624(0x2f1)],
          indicator: _0x3f6624(0x1ce),
          saved: !![],
        }),
          (_0x3d245f["condition2"] = {}),
          (_0x3d245f[_0x3f6624(0x1c9)][_0x3f6624(0x191)] = !![]),
          (_0x3d245f[_0x3f6624(0x1c9)][_0x3f6624(0x395)] =
            _0x4c202b[_0x146b6][_0x3f6624(0x395)]),
          (_0x3d245f[_0x3f6624(0x1c9)][_0x3f6624(0x3ad)] =
            _0x4c202b[_0x146b6][_0x3f6624(0x3ad)]),
          (_0x3d245f["condition2"]["period2"] =
            _0x4c202b[_0x146b6][_0x3f6624(0x2b4)]),
          (_0x3d245f[_0x3f6624(0x1c9)][_0x3f6624(0x1f7)] =
            _0x4c202b[_0x146b6][_0x3f6624(0x346)] === _0x3f6624(0x2af)
              ? _0x3f6624(0x34e)
              : "above"),
          (_0x3d245f[_0x3f6624(0x1c9)]["band"] =
            _0x4c202b[_0x146b6][_0x3f6624(0x1f7)]),
          (_0x3d245f["conditionsOrder"] = [_0x3f6624(0x1c9)]),
          _0x4a7489[_0x3f6624(0x266)](_0x3d245f);
        break;
      case _0x3f6624(0x218):
        _0x3d245f["period4"] = _0x4c202b[_0x146b6][_0x3f6624(0x292)];
      case _0x3f6624(0x219):
        (_0x3d245f[_0x3f6624(0x3ad)] = _0x4c202b[_0x146b6][_0x3f6624(0x3ad)]),
          (_0x3d245f[_0x3f6624(0x2b4)] = _0x4c202b[_0x146b6][_0x3f6624(0x2b4)]),
          (_0x3d245f[_0x3f6624(0x373)] = _0x4c202b[_0x146b6][_0x3f6624(0x373)]);
        _0x4c202b[_0x146b6][_0x3f6624(0x346)] === _0x3f6624(0x2a6)
          ? ((_0x3d245f["condition1"] = {}),
            (_0x3d245f["condition1"][_0x3f6624(0x1f7)] =
              _0x4c202b[_0x146b6][_0x3f6624(0x1f7)]),
            _0x4c202b[_0x146b6][_0x3f6624(0x1f7)] === _0x3f6624(0x1a8) &&
              (_0x3d245f[_0x3f6624(0x2d4)]["crossDirection"] =
                _0x4c202b[_0x146b6][_0x3f6624(0x247)]),
            (_0x3d245f[_0x3f6624(0x2d4)][_0x3f6624(0x191)] = !![]),
            (_0x3d245f[_0x3f6624(0x2ee)] = [_0x3f6624(0x2d4)]),
            (_0x449c8b = !![]))
          : ((_0x3d245f[_0x3f6624(0x1c9)] = {}),
            (_0x3d245f[_0x3f6624(0x1c9)][_0x3f6624(0x1f7)] =
              _0x4c202b[_0x146b6][_0x3f6624(0x1f7)]),
            _0x4c202b[_0x146b6][_0x3f6624(0x1f7)] === "crossing" &&
              (_0x3d245f[_0x3f6624(0x1c9)][_0x3f6624(0x247)] =
                _0x4c202b[_0x146b6]["crossDirection"]),
            (_0x3d245f[_0x3f6624(0x1c9)][_0x3f6624(0x2fb)] =
              _0x4c202b[_0x146b6][_0x3f6624(0x2fb)]),
            (_0x3d245f[_0x3f6624(0x1c9)][_0x3f6624(0x191)] = !![]),
            (_0x3d245f[_0x3f6624(0x2ee)] = [_0x3f6624(0x1c9)]),
            (_0x449c8b = !![]));
        _0x449c8b && _0x4a7489[_0x3f6624(0x266)](_0x3d245f);
        break;
      case _0x3f6624(0x352):
        _0x4a7489[_0x3f6624(0x266)](_0x3d245f);
        break;
      default:
    }
  }
  return _0x4a7489;
}
function fixStrategyVersion(_0x4f8cff) {
  const _0x3a8b70 = a0_0x1f43;
  let _0x4d8392 = ![];
  switch (_0x4f8cff[_0x3a8b70(0x238)]) {
    case 0x4:
      break;
    case 0x3:
      (_0x4f8cff[_0x3a8b70(0x3a4)] = new Date()["getTime"]()),
        (_0x4f8cff["version"] = strategyVersion),
        _0x4f8cff[_0x3a8b70(0x2ec)][_0x3a8b70(0x172)](
          (_0x26879e) =>
            (_0x26879e["mode"] = _0x4f8cff["confirmationCandle"]
              ? "close"
              : "current")
        ),
        _0x4f8cff[_0x3a8b70(0x19b)][_0x3a8b70(0x172)](
          (_0x21de0d) =>
            (_0x21de0d[_0x3a8b70(0x193)] = _0x4f8cff[_0x3a8b70(0x1db)]
              ? _0x3a8b70(0x35b)
              : _0x3a8b70(0x15a))
        ),
        (_0x4d8392 = !![]);
      break;
    case 0x2:
      (_0x4f8cff[_0x3a8b70(0x3a4)] = new Date()[_0x3a8b70(0x168)]()),
        (_0x4f8cff[_0x3a8b70(0x238)] = strategyVersion),
        (_0x4f8cff[_0x3a8b70(0x2ec)] = fixRules(_0x4f8cff[_0x3a8b70(0x2ec)])),
        (_0x4f8cff[_0x3a8b70(0x19b)] = fixRules(_0x4f8cff["sellRules"])),
        _0x4f8cff["buyRules"][_0x3a8b70(0x172)](
          (_0x3e2f98) =>
            (_0x3e2f98[_0x3a8b70(0x193)] = _0x4f8cff[_0x3a8b70(0x1db)]
              ? "close"
              : "current")
        ),
        _0x4f8cff["sellRules"][_0x3a8b70(0x172)](
          (_0x1fd8e8) =>
            (_0x1fd8e8[_0x3a8b70(0x193)] = _0x4f8cff[_0x3a8b70(0x1db)]
              ? _0x3a8b70(0x35b)
              : _0x3a8b70(0x15a))
        ),
        (_0x4d8392 = !![]);
      break;
    case 0x1:
      (_0x4f8cff[_0x3a8b70(0x3a4)] = new Date()[_0x3a8b70(0x168)]()),
        (_0x4f8cff[_0x3a8b70(0x238)] = strategyVersion),
        (_0x4f8cff[_0x3a8b70(0x387)] = new Date(_0x4f8cff[_0x3a8b70(0x387)])[
          _0x3a8b70(0x168)
        ]()),
        (_0x4f8cff[_0x3a8b70(0x2ec)] = fixRules(_0x4f8cff[_0x3a8b70(0x2ec)])),
        (_0x4f8cff[_0x3a8b70(0x19b)] = fixRules(_0x4f8cff[_0x3a8b70(0x19b)])),
        _0x4f8cff["buyRules"][_0x3a8b70(0x172)](
          (_0xfa9a59) =>
            (_0xfa9a59["mode"] = _0x4f8cff[_0x3a8b70(0x1db)]
              ? _0x3a8b70(0x35b)
              : _0x3a8b70(0x15a))
        ),
        _0x4f8cff["sellRules"][_0x3a8b70(0x172)](
          (_0x3230d5) =>
            (_0x3230d5[_0x3a8b70(0x193)] = _0x4f8cff[_0x3a8b70(0x1db)]
              ? "close"
              : _0x3a8b70(0x15a))
        );
      break;
    default:
      (_0x4f8cff[_0x3a8b70(0x3a4)] = new Date()["getTime"]()),
        (_0x4f8cff["version"] = strategyVersion),
        (_0x4f8cff["creationDate"] = new Date(_0x4f8cff[_0x3a8b70(0x387)])[
          _0x3a8b70(0x168)
        ]()),
        (_0x4f8cff[_0x3a8b70(0x2ec)] = fixRules(_0x4f8cff["buyRules"])),
        (_0x4f8cff[_0x3a8b70(0x19b)] = fixRules(_0x4f8cff[_0x3a8b70(0x19b)])),
        _0x4f8cff[_0x3a8b70(0x2ec)][_0x3a8b70(0x172)](
          (_0x59093d) =>
            (_0x59093d[_0x3a8b70(0x193)] = _0x4f8cff[_0x3a8b70(0x1db)]
              ? _0x3a8b70(0x35b)
              : _0x3a8b70(0x15a))
        ),
        _0x4f8cff[_0x3a8b70(0x19b)][_0x3a8b70(0x172)](
          (_0x184602) =>
            (_0x184602[_0x3a8b70(0x193)] = _0x4f8cff[_0x3a8b70(0x1db)]
              ? _0x3a8b70(0x35b)
              : "current")
        ),
        (_0x4d8392 = !![]);
  }
  return (
    !_0x4f8cff[_0x3a8b70(0x2ad)] &&
      (_0x4f8cff[_0x3a8b70(0x2ad)] = {
        maxDrawdown: "",
        maxLoss: "",
        maxNegTrades: "",
        commissionRate: "",
        orderDetails: { type: _0x3a8b70(0x154), value: "" },
        tradeSize: { type: "base", value: "" },
      }),
    !_0x4f8cff[_0x3a8b70(0x184)] &&
      (_0x4f8cff[_0x3a8b70(0x184)] = {
        maxDrawdown: "",
        maxLoss: "",
        maxNegTrades: "",
        commissionRate: "",
        orderDetails: { type: _0x3a8b70(0x154), value: "" },
        tradeSize: { type: "base", value: "" },
      }),
    _0x4d8392
  );
}
async function pairsDetailsAutoSync(_0x3bf86b) {
  const _0x29cd52 = a0_0x1f43,
    _0x180e72 = [_0x29cd52(0x367), _0x29cd52(0x1d0)];
  while (!![]) {
    await sleep(0x3e8 * 0x3c * 0x1e);
    for (let _0x136633 of _0x180e72) {
      getExchange(_0x136633)["setResetPairsCallback"]((_0x301456) => {
        const _0xe05d1a = _0x29cd52;
        _0x3bf86b({
          type: _0xe05d1a(0x32f),
          payload: { pairDetails: _0x301456, exchange: _0x136633 },
        });
      }),
        getExchange(_0x136633)[_0x29cd52(0x2de)]();
    }
  }
}
async function getUserData(_0x178e1d, _0x1090f8, _0x434845, _0x23eb39) {
  const _0x22abde = a0_0x1f43;
  try {
    let _0x1eb676 = await getStrategies(
        _0x434845,
        _0x178e1d["id"],
        ![],
        _0x23eb39
      ),
      _0x14187e = await getStrategiesStatuses(
        _0x434845,
        _0x178e1d["id"],
        _0x22abde(0x26a),
        _0x23eb39
      ),
      _0x524890 = await getStrategiesStatuses(
        _0x434845,
        _0x178e1d["id"],
        _0x22abde(0x260),
        _0x23eb39
      ),
      _0x17c739 = _0x14187e[_0x22abde(0x25e)]((_0x5d6762, _0x322dbc) => {
        const _0x49108c = _0x22abde;
        return (
          _0x23eb39 &&
            (_0x322dbc["status"] === "Loading" &&
              !_0x322dbc[_0x49108c(0x1d4)] &&
              (_0x322dbc[_0x49108c(0x2e9)] = _0x49108c(0x2c3)),
            delete _0x322dbc[_0x49108c(0x2ab)],
            delete _0x322dbc["manualSellAt"]),
          (_0x5d6762[_0x322dbc["id"]] = _0x322dbc),
          _0x5d6762
        );
      }, {}),
      _0x2a6948 = _0x524890[_0x22abde(0x25e)]((_0x9f9c7a, _0x502b21) => {
        const _0x1b0a05 = _0x22abde;
        return (
          _0x23eb39 &&
            (_0x502b21[_0x1b0a05(0x2e9)] === "Loading" &&
              !_0x502b21[_0x1b0a05(0x1d4)] &&
              (_0x502b21[_0x1b0a05(0x2e9)] = _0x1b0a05(0x2c3)),
            delete _0x502b21[_0x1b0a05(0x2ab)],
            delete _0x502b21[_0x1b0a05(0x309)]),
          (_0x9f9c7a[_0x502b21["id"]] = _0x502b21),
          _0x9f9c7a
        );
      }, {});
    if (_0x23eb39 && !_0x178e1d["isAdmin"]) {
      let _0x28b241 = await getStrategiesDetails(
          _0x434845,
          _0x178e1d["id"],
          "simulation"
        ),
        _0x29120 = await getStrategiesDetails(
          _0x434845,
          _0x178e1d["id"],
          "trading"
        ),
        _0x4352d0 = await fixStatusDetails(
          _0x1eb676,
          _0x17c739,
          _0x2a6948,
          _0x28b241,
          _0x29120
        );
      if (_0x4352d0[_0x22abde(0x175)][_0x22abde(0x349)] > 0x0) {
        for (let _0x43a515 of _0x4352d0[_0x22abde(0x175)]) {
          await getEcbDatabase()[_0x22abde(0x2f0)](_0x43a515),
            await sendStrategyStatus(_0x434845, "simulation", _0x43a515);
        }
        for (let _0x540a18 of _0x4352d0["simDetails"]) {
          await getEcbDatabase()[_0x22abde(0x24e)](_0x540a18),
            addItemToArrayInLocalStore(
              "strategySimDetailsNotSync",
              _0x540a18["id"]
            );
        }
      }
      if (_0x4352d0["liveStatuses"][_0x22abde(0x349)] > 0x0) {
        for (let _0x4f4d87 of _0x4352d0[_0x22abde(0x18e)]) {
          await getEcbDatabase()[_0x22abde(0x181)](_0x4f4d87),
            await sendStrategyStatus(_0x434845, "trading", _0x4f4d87);
        }
        for (let _0x32ed6b of _0x4352d0[_0x22abde(0x197)]) {
          await getEcbDatabase()[_0x22abde(0x1cc)](_0x32ed6b),
            addItemToArrayInLocalStore(_0x22abde(0x265), _0x32ed6b["id"]);
        }
      }
      await getUserTrades(_0x434845, _0x22abde(0x26a)),
        await getUserTrades(_0x434845, _0x22abde(0x260)),
        (_0x4352d0 = await fixStatusVersion(
          _0x1eb676,
          _0x17c739,
          _0x2a6948,
          _0x28b241,
          _0x29120
        ));
      if (_0x4352d0["sim"][_0x22abde(0x349)] > 0x0)
        for (let _0x1513d7 of _0x4352d0["sim"]) {
          await getEcbDatabase()[_0x22abde(0x2f0)](_0x1513d7),
            await sendStrategyStatus(_0x434845, "simulation", _0x1513d7);
        }
      if (_0x4352d0[_0x22abde(0x184)][_0x22abde(0x349)] > 0x0)
        for (let _0x4b52cf of _0x4352d0[_0x22abde(0x184)]) {
          await getEcbDatabase()[_0x22abde(0x181)](_0x4b52cf),
            await sendStrategyStatus(_0x434845, _0x22abde(0x260), _0x4b52cf);
        }
    }
    return (
      _0x1090f8({
        type: _0x22abde(0x23f),
        payload: {
          strategies: _0x1eb676,
          simStatuses: _0x17c739,
          liveStatuses: _0x2a6948,
        },
      }),
      _0x1090f8({ type: "simStatuses", payload: _0x17c739 }),
      _0x1090f8({ type: "liveStatuses", payload: _0x2a6948 }),
      { strategies: _0x1eb676, simStatuses: _0x17c739, liveStatuses: _0x2a6948 }
    );
  } catch (_0x56fe94) {
    return (
      console["log"](_0x22abde(0x217) + _0x56fe94),
      { strategies: [], simStatuses: {}, liveStatuses: {} }
    );
  }
}
let add = 0x14;
async function fixStatus(
  _0x1012f9,
  _0x2c8a2e,
  _0x2d8418,
  _0x2e9af9,
  _0x3c0300
) {
  const _0x2d6421 = a0_0x1f43;
  if (!_0x2c8a2e) return;
  if (_0x2c8a2e["version"] !== statusVersion) {
    (_0x2c8a2e["version"] = statusVersion),
      (_0x2c8a2e[_0x2d6421(0x3a4)] = new Date()[_0x2d6421(0x168)]());
    let _0x4e4387 = await (_0x3c0300 === _0x2d6421(0x2ad)
      ? getEcbDatabase()[_0x2d6421(0x1bc)](_0x2c8a2e["id"])
      : getEcbDatabase()[_0x2d6421(0x33d)](_0x2c8a2e["id"]));
    _0x2d8418
      ? (_0x2c8a2e[_0x2d6421(0x269)] = _0x2d8418)
      : (_0x2c8a2e[_0x2d6421(0x269)] = {});
    if (_0x4e4387[_0x2d6421(0x349)] > 0x0) {
      _0x2c8a2e[_0x2d6421(0x2e2)] =
        _0x4e4387[_0x4e4387[_0x2d6421(0x349)] - 0x1];
      if (
        _0x2c8a2e[_0x2d6421(0x2e2)]["openStatus"] === "FILLED" &&
        !_0x2c8a2e[_0x2d6421(0x2e2)][_0x2d6421(0x3c5)]
      ) {
        _0x1012f9[_0x2d6421(0x222)] &&
          (_0x2c8a2e[_0x2d6421(0x269)]["s"] = roundQuotedValue(
            _0x2c8a2e[_0x2d6421(0x2e2)]["o"] *
              (0x1 - _0x1012f9[_0x2d6421(0x222)] / 0x64),
            _0x1012f9[_0x2d6421(0x32f)]
          ));
        _0x1012f9[_0x2d6421(0x31a)] &&
          (_0x2c8a2e["details"]["t"] = roundQuotedValue(
            _0x2c8a2e[_0x2d6421(0x2e2)]["o"] *
              (0x1 + _0x1012f9[_0x2d6421(0x31a)] / 0x64),
            _0x1012f9["pairDetails"]
          ));
        if (_0x1012f9[_0x2d6421(0x294)]) {
          let _0x389e02 = new Date(_0x2c8a2e[_0x2d6421(0x2e2)]["od"]);
          _0x389e02[_0x2d6421(0x1c3)](
            _0x389e02[_0x2d6421(0x36f)]() + _0x1012f9[_0x2d6421(0x294)],
            _0x389e02[_0x2d6421(0x3b8)](),
            _0x389e02[_0x2d6421(0x3a0)](),
            _0x389e02["getMilliseconds"]()
          ),
            (_0x2c8a2e["details"]["tc"] = _0x389e02[_0x2d6421(0x168)]());
        }
      }
      (_0x2c8a2e[_0x2d6421(0x1ff)] = _0x4e4387["reduce"](
        (_0x3d8957, _0x4c9186) => {
          const _0x3a984a = _0x2d6421;
          return (
            _0x4c9186[_0x3a984a(0x1ff)] &&
              (_0x4c9186[_0x3a984a(0x3c5)] === _0x3a984a(0x1f8) ||
                _0x3a984a(0x1c5)) &&
              (_0x3d8957 += _0x4c9186[_0x3a984a(0x1ff)]),
            _0x3d8957
          );
        },
        0x0
      )),
        (_0x2c8a2e[_0x2d6421(0x2c4)] = _0x4e4387[_0x2d6421(0x25e)](
          (_0x3a683b, _0x40e8a4) => {
            const _0x27b1d4 = _0x2d6421;
            return (
              _0x40e8a4[_0x27b1d4(0x2c4)] &&
                (_0x40e8a4["closeStatus"] === _0x27b1d4(0x1f8) ||
                  _0x27b1d4(0x1c5)) &&
                (_0x3a683b += _0x40e8a4[_0x27b1d4(0x2c4)]),
              _0x3a683b
            );
          },
          0x0
        )),
        (_0x2c8a2e[_0x2d6421(0x21e)] = _0x4e4387[_0x2d6421(0x25e)](
          (_0x1aa3d1, _0x7413c5) => {
            const _0x4a0328 = _0x2d6421;
            return (
              (_0x7413c5[_0x4a0328(0x3c5)] === "FILLED" || _0x4a0328(0x1c5)) &&
                _0x1aa3d1++,
              _0x1aa3d1
            );
          },
          0x0
        ));
    }
    _0x2e9af9[_0x2d6421(0x266)](_0x2c8a2e);
  }
}
function a(_0xa3665f, _0x3b81fc, _0x4feb80) {
  const _0x38a198 = a0_0x1f43;
  let _0x20a485 = 0xa;
  for (let _0x56521d = 0x1; _0x56521d < _0x4feb80; _0x56521d++) {
    _0x20a485++;
  }
  return String[_0x38a198(0x2c0)](
    add + _0x20a485 * add2 - add3[add3[_0xa3665f]] + add3[add3[_0x3b81fc]]
  );
}
async function fixStatusDetails(
  _0x438a26,
  _0x2db10b,
  _0x1f4819,
  _0x308d59,
  _0x35f304
) {
  const _0x195a76 = a0_0x1f43;
  let _0x410a9d = {
    simStatuses: [],
    liveStatuses: [],
    simDetails: [],
    liveDetails: [],
  };
  for (let _0x1a4f35 of _0x438a26) {
    let _0x2a9fb5 = _0x2db10b[_0x1a4f35["id"]],
      _0x107862 = _0x308d59[_0x1a4f35["id"]];
    _0x2a9fb5 &&
      !_0x107862 &&
      ((_0x107862 = await getEcbDatabase()[_0x195a76(0x33a)](_0x2a9fb5["id"])),
      _0x107862 && (_0x107862[_0x195a76(0x18f)] = _0x2a9fb5[_0x195a76(0x18f)]));
    _0x2a9fb5 &&
      _0x2a9fb5["details"] &&
      _0x107862 &&
      _0x107862["hp"] &&
      ((_0x2a9fb5[_0x195a76(0x269)]["hp"] = _0x107862["hp"]),
      (_0x2a9fb5[_0x195a76(0x3a4)] = new Date()[_0x195a76(0x168)]()),
      delete _0x107862["hp"],
      (_0x107862[_0x195a76(0x3a4)] = new Date()["getTime"]()),
      _0x410a9d[_0x195a76(0x175)][_0x195a76(0x266)](_0x2a9fb5),
      _0x410a9d["simDetails"][_0x195a76(0x266)](_0x107862));
    let _0x44eb53 = _0x1f4819[_0x1a4f35["id"]],
      _0x1bbfe6 = _0x35f304[_0x1a4f35["id"]];
    _0x44eb53 &&
      !_0x1bbfe6 &&
      ((_0x1bbfe6 = await getEcbDatabase()[_0x195a76(0x231)](_0x44eb53["id"])),
      _0x1bbfe6 && (_0x1bbfe6[_0x195a76(0x18f)] = _0x44eb53["userId"])),
      _0x44eb53 &&
        _0x44eb53["details"] &&
        _0x1bbfe6 &&
        _0x1bbfe6["hp"] &&
        ((_0x44eb53["details"]["hp"] = _0x1bbfe6["hp"]),
        (_0x44eb53[_0x195a76(0x3a4)] = new Date()[_0x195a76(0x168)]()),
        delete _0x1bbfe6["hp"],
        (_0x1bbfe6[_0x195a76(0x3a4)] = new Date()[_0x195a76(0x168)]()),
        _0x410a9d[_0x195a76(0x18e)][_0x195a76(0x266)](_0x44eb53),
        _0x410a9d["liveDetails"][_0x195a76(0x266)](_0x1bbfe6));
  }
  return _0x410a9d;
}
async function fixStatusVersion(
  _0x159a49,
  _0x80290e,
  _0x48f33c,
  _0x28ac81,
  _0x578e02
) {
  const _0x184d6e = a0_0x1f43;
  let _0x45c09b = { sim: [], live: [] };
  for (let _0x4c7023 of _0x159a49) {
    let _0xa8b8d2 = _0x80290e[_0x4c7023["id"]],
      _0x78e02d = _0x28ac81[_0x4c7023["id"]];
    await fixStatus(_0x4c7023, _0xa8b8d2, _0x78e02d, _0x45c09b["sim"], "sim");
    let _0x47d4d7 = _0x48f33c[_0x4c7023["id"]],
      _0x5eb895 = _0x578e02[_0x4c7023["id"]];
    await fixStatus(
      _0x4c7023,
      _0x47d4d7,
      _0x5eb895,
      _0x45c09b[_0x184d6e(0x184)],
      _0x184d6e(0x184)
    );
  }
  return _0x45c09b;
}
async function checkForNewVersion(_0xda1036, _0x16d2cc, _0x428788) {
  const _0x448194 = a0_0x1f43;
  while (!![]) {
    await fetchWithRetry(
      _0x448194(0x239) +
        _0x16d2cc +
        _0x448194(0x285) +
        new Date()[_0x448194(0x168)](),
      _0x448194(0x308),
      null,
      (_0x3c782c) => {
        const _0x19f3f8 = _0x448194;
        _0x3c782c &&
          _0x3c782c[_0x19f3f8(0x238)] &&
          _0xda1036({ type: _0x19f3f8(0x16b), payload: _0x3c782c });
      },
      null,
      { Authorization: _0x448194(0x1e6) + _0x428788 }
    ),
      await sleep(0x3e8 * 0x3c * 0x1e);
  }
}
function getInterval(_0x91e256) {
  const _0x222b72 = a0_0x1f43;
  switch (_0x91e256) {
    case "1\x20minute":
      return "1m";
    case _0x222b72(0x3c1):
      return "5m";
    case "15\x20minutes":
      return _0x222b72(0x178);
    case _0x222b72(0x21d):
      return _0x222b72(0x20a);
    case _0x222b72(0x378):
      return "1h";
    case _0x222b72(0x2a7):
      return "4h";
    case _0x222b72(0x2f8):
      return "12h";
    case _0x222b72(0x233):
      return "1d";
    case _0x222b72(0x25d):
      return "1w";
    case _0x222b72(0x274):
      return "1M";
    default:
      return null;
  }
}
function getTimeframeFromInterval(_0x50a7ed) {
  const _0x3cadbf = a0_0x1f43;
  switch (_0x50a7ed) {
    case "1m":
      return _0x3cadbf(0x212);
    case "5m":
      return _0x3cadbf(0x3c1);
    case _0x3cadbf(0x178):
      return _0x3cadbf(0x2ca);
    case _0x3cadbf(0x20a):
      return "30\x20minutes";
    case "1h":
      return _0x3cadbf(0x378);
    case "4h":
      return _0x3cadbf(0x2a7);
    case _0x3cadbf(0x2ea):
      return _0x3cadbf(0x2f8);
    case "1d":
      return _0x3cadbf(0x233);
    case "1w":
      return _0x3cadbf(0x25d);
    case "1M":
      return _0x3cadbf(0x274);
    default:
      return _0x3cadbf(0x378);
  }
}
function generateUniqueId(_0x5ac836, _0xe2e08a, _0x539c50) {
  const _0x415071 = a0_0x1f43;
  let _0x31d3c3 = null,
    _0x25e769 = !![];
  do {
    (_0x31d3c3 =
      _0xe2e08a +
      "_" +
      Math[_0x415071(0x211)](Math["random"]() * 0x15f8f + 0x2710)),
      (_0x25e769 = _0x5ac836[_0x415071(0x20c)]((_0xc70af6) => {
        return _0x539c50
          ? _0xc70af6[_0x539c50] === _0x31d3c3
          : _0xc70af6 === _0x31d3c3;
      }));
  } while (_0x25e769);
  return _0x31d3c3;
}
function stringToDate(_0x52b68f) {
  const _0x20cf9f = a0_0x1f43,
    _0x3e1299 = _0x52b68f[_0x20cf9f(0x16a)]("-");
  return new Date(_0x3e1299[0x0], _0x3e1299[0x1] - 0x1, _0x3e1299[0x2]);
}
function stringToDate2(_0x4a295e) {
  const _0x4a90d7 = a0_0x1f43;
  try {
    const [_0x2f0151, _0x4e8e95] = _0x4a295e["split"]("T"),
      _0x11100c = _0x2f0151[_0x4a90d7(0x16a)]("-"),
      _0x621eab = _0x4e8e95[_0x4a90d7(0x16a)](":");
    return new Date(
      _0x11100c[0x0],
      _0x11100c[0x1] - 0x1,
      _0x11100c[0x2],
      _0x621eab[0x0],
      _0x621eab[0x1]
    );
  } catch (_0x166c06) {
    return null;
  }
}
async function verifyUser(_0x27ac7c, _0x4160c7, _0x596294, _0x5d8e31) {
  const _0x38f51f = a0_0x1f43;
  if (!_0x27ac7c) {
    _0x596294();
    return;
  }
  let _0x2564c4 = _0x38f51f(0x32c);
  await fetchWithRetry(
    _0x2564c4,
    _0x38f51f(0x308),
    null,
    (_0x56e862) => {
      const _0x300402 = _0x38f51f;
      if (
        _0x56e862 &&
        _0x56e862[_0x300402(0x205)] &&
        _0x56e862[_0x300402(0x385)]
      ) {
        try {
          let _0x25c1c7 = encrypt(
            JSON[_0x300402(0x1f3)](_0x56e862["user"]),
            _0x56e862[_0x300402(0x385)]
          );
          localStorage["setItem"]("h", _0x25c1c7);
        } catch (_0x59c53a) {}
        _0x4160c7(_0x56e862["user"], _0x56e862[_0x300402(0x385)]);
      } else _0x596294();
    },
    (_0x473c85) => {
      const _0x402194 = _0x38f51f;
      if (!_0x473c85) {
        let _0x299927 = localStorage[_0x402194(0x19e)]("h");
        if (_0x299927)
          try {
            let _0x556bdd = decrypt(_0x299927, _0x27ac7c);
            if (_0x556bdd) {
              let _0x2f2394 = JSON[_0x402194(0x2d7)](_0x556bdd);
              _0x2f2394 && _0x4160c7(_0x2f2394, _0x27ac7c);
            }
          } catch (_0x1d93e1) {}
      }
      _0x596294 && _0x596294();
    },
    { Authorization: "Bearer\x20" + _0x27ac7c }
  );
}
async function fetchWithRetry(
  _0x533b81,
  _0xb3a222,
  _0x3342ea,
  _0x472cb2 = null,
  _0x4d45ae = null,
  _0x5f365e = {},
  _0x4d3318 = 0x3,
  _0x3cf1e4 = 0x1
) {
  const _0xd0a56c = a0_0x1f43;
  let _0x4eee4b = ![],
    _0x5674fb = null;
  try {
    let _0x5eb90e = {
      method: _0xb3a222,
      headers: {
        ..._0x5f365e,
        Accept: _0xd0a56c(0x213),
        "Content-Type": _0xd0a56c(0x213),
      },
    };
    _0x3342ea && (_0x5eb90e["body"] = JSON[_0xd0a56c(0x1f3)](_0x3342ea));
    _0x5674fb = await fetch(_0x533b81, _0x5eb90e);
    if (_0x5674fb[_0xd0a56c(0x2e9)] === 0xc8 || _0x5674fb["status"] === 0xc9) {
      const _0xb4815e = await _0x5674fb["json"]();
      if (_0x472cb2)
        try {
          _0x472cb2(_0xb4815e);
        } catch (_0x2e9d1f) {}
      return;
    } else _0x4eee4b = !![];
  } catch (_0x4bc595) {
    _0x4eee4b = !![];
  }
  if (_0x4eee4b) {
    if (_0x3cf1e4 < _0x4d3318) {
      await sleep(0x3e8),
        fetchWithRetry(
          _0x533b81,
          _0xb3a222,
          _0x3342ea,
          _0x472cb2,
          _0x4d45ae,
          _0x5f365e,
          _0x4d3318,
          _0x3cf1e4 + 0x1
        );
      return;
    }
  }
  _0x4d45ae && _0x4d45ae(_0x5674fb);
}
function getArrayFromLocalStore(_0x4f3d22) {
  const _0x1a004a = a0_0x1f43;
  let _0x511569 = localStorage[_0x1a004a(0x19e)](_0x4f3d22);
  return _0x511569 ? JSON[_0x1a004a(0x2d7)](_0x511569) : [];
}
const localStoreMutex = new Mutex();
async function removeItemFromArrayInLocalStore(_0x343763, _0x10b7b9) {
  const _0x43cfc3 = a0_0x1f43;
  try {
    await localStoreMutex[_0x43cfc3(0x39d)]();
    let _0x1619cd = getArrayFromLocalStore(_0x343763),
      _0x592235 = _0x1619cd[_0x43cfc3(0x3b3)](
        (_0x46c7ec) => _0x46c7ec === _0x10b7b9
      );
    _0x592235 !== -0x1 &&
      (_0x1619cd[_0x43cfc3(0x27c)](_0x592235, 0x1),
      localStorage[_0x43cfc3(0x2a3)](
        _0x343763,
        JSON[_0x43cfc3(0x1f3)](_0x1619cd)
      ));
  } finally {
    localStoreMutex[_0x43cfc3(0x333)]();
  }
}
async function addItemToArrayInLocalStore(_0x409bdb, _0x135ece) {
  const _0x216c68 = a0_0x1f43;
  try {
    await localStoreMutex[_0x216c68(0x39d)]();
    let _0x5e4004 = getArrayFromLocalStore(_0x409bdb),
      _0x4a1aa1 = _0x5e4004[_0x216c68(0x3b3)](
        (_0x9fb191) => _0x9fb191 === _0x135ece
      );
    _0x4a1aa1 === -0x1 &&
      (_0x5e4004[_0x216c68(0x266)](_0x135ece),
      localStorage[_0x216c68(0x2a3)](
        _0x409bdb,
        JSON[_0x216c68(0x1f3)](_0x5e4004)
      ));
  } finally {
    localStoreMutex["release"]();
  }
}
async function addArrayInLocalStore(_0x80c685, _0xd7b262) {
  const _0xe6d72c = a0_0x1f43;
  try {
    await localStoreMutex[_0xe6d72c(0x39d)](),
      localStorage["setItem"](_0x80c685, JSON[_0xe6d72c(0x1f3)](_0xd7b262));
  } finally {
    localStoreMutex[_0xe6d72c(0x333)]();
  }
}
function getUsers(_0x526dce, _0x1c85f2) {
  const _0x3406eb = a0_0x1f43,
    _0x202289 = _0x3406eb(0x366);
  return new Promise(async (_0x574ffe) => {
    const _0x2ae5bb = _0x3406eb;
    await fetchWithRetry(
      _0x202289,
      _0x2ae5bb(0x308),
      null,
      async (_0x341f2e) => {
        const _0x587b2f = _0x2ae5bb;
        try {
          _0x341f2e &&
            _0x341f2e[_0x587b2f(0x349)] > 0x0 &&
            (_0x341f2e[_0x587b2f(0x172)]((_0x2ee45b) => {
              const _0x54590e = _0x587b2f;
              (_0x2ee45b[_0x54590e(0x387)] = _0x2ee45b[_0x54590e(0x387)][
                _0x54590e(0x242)
              ](0x0, 0xa)),
                (_0x2ee45b[_0x54590e(0x2bd)] = _0x2ee45b["updated"]
                  ? _0x2ee45b[_0x54590e(0x2bd)]
                  : ""),
                (_0x2ee45b[_0x54590e(0x1c2)] =
                  _0x2ee45b[_0x54590e(0x29a)] === "lifetime"
                    ? ""
                    : _0x2ee45b[_0x54590e(0x1c2)][_0x54590e(0x242)](0x0, 0xa));
            }),
            _0x1c85f2({ type: _0x587b2f(0x200), payload: _0x341f2e }));
        } catch (_0x314d84) {}
      },
      (_0x3f3f2a) => {
        const _0x8a9db6 = _0x2ae5bb;
        console[_0x8a9db6(0x281)](_0x8a9db6(0x3c0)), _0x574ffe([]);
        return;
      },
      { Authorization: _0x2ae5bb(0x1e6) + _0x526dce }
    );
  });
}
function convertOldRule(_0x51caf5) {
  const _0x281852 = a0_0x1f43;
  let _0x41bf24 = {
    id: _0x51caf5["id"],
    m: _0x51caf5["mode"],
    t: _0x51caf5["timeframe"],
    i: _0x51caf5[_0x281852(0x395)],
    cO: _0x51caf5[_0x281852(0x2ee)][_0x281852(0x164)]((_0x3aef26) =>
      _0x3aef26[_0x281852(0x3ab)](_0x281852(0x32d), "c")
    ),
  };
  _0x51caf5["period"] && (_0x41bf24["p1"] = _0x51caf5["period"]);
  _0x51caf5["period2"] && (_0x41bf24["p2"] = _0x51caf5[_0x281852(0x2b4)]);
  _0x51caf5[_0x281852(0x373)] &&
    (_0x41bf24["p3"] = _0x51caf5[_0x281852(0x373)]);
  _0x51caf5[_0x281852(0x292)] &&
    (_0x41bf24["p4"] = _0x51caf5[_0x281852(0x292)]);
  _0x51caf5[_0x281852(0x234)] && (_0x41bf24["f"] = _0x51caf5[_0x281852(0x234)]);
  for (let _0x14a78c = 0x1; _0x14a78c <= 0x6; _0x14a78c++) {
    let _0x4505b7 = _0x51caf5[_0x281852(0x32d) + _0x14a78c];
    _0x4505b7 &&
      ((_0x41bf24["c" + _0x14a78c] = {}),
      _0x4505b7[_0x281852(0x3ad)] &&
        (_0x41bf24["c" + _0x14a78c]["p1"] = _0x4505b7[_0x281852(0x3ad)]),
      _0x4505b7[_0x281852(0x2b4)] &&
        (_0x41bf24["c" + _0x14a78c]["p2"] = _0x4505b7[_0x281852(0x2b4)]),
      _0x4505b7["value"] &&
        (_0x41bf24["c" + _0x14a78c]["v1"] = _0x4505b7[_0x281852(0x2fb)]),
      _0x4505b7[_0x281852(0x1ea)] &&
        (_0x41bf24["c" + _0x14a78c]["v2"] = _0x4505b7[_0x281852(0x1ea)]),
      _0x4505b7[_0x281852(0x1f7)] &&
        (_0x41bf24["c" + _0x14a78c]["d"] = _0x4505b7["direction"]),
      _0x4505b7[_0x281852(0x247)] &&
        (_0x41bf24["c" + _0x14a78c][_0x281852(0x249)] =
          _0x4505b7[_0x281852(0x247)]),
      _0x4505b7[_0x281852(0x395)] &&
        (_0x41bf24["c" + _0x14a78c]["i"] = _0x4505b7[_0x281852(0x395)]),
      _0x4505b7["band"] &&
        (_0x41bf24["c" + _0x14a78c]["b"] = _0x4505b7[_0x281852(0x29f)]),
      _0x4505b7[_0x281852(0x35a)] &&
        (_0x41bf24["c" + _0x14a78c][_0x281852(0x35a)] =
          _0x4505b7[_0x281852(0x35a)]));
  }
  return _0x41bf24;
}
function convertOldStrategyContest(_0x4d363e, _0x5039bd) {
  const _0xdf57c9 = a0_0x1f43;
  let _0x20f6f9 = {
      id: _0x4d363e["id"],
      name: _0x4d363e[_0xdf57c9(0x22d)],
      nickname: _0x4d363e[_0xdf57c9(0x27d)],
      showStrategy: _0x4d363e[_0xdf57c9(0x296)],
      exchange: _0x4d363e[_0xdf57c9(0x2d6)],
      pair: _0x4d363e[_0xdf57c9(0x17a)],
      pairDetails: _0x4d363e[_0xdf57c9(0x32f)],
      buyRules: _0x4d363e[_0xdf57c9(0x2ec)],
      sellRules: _0x4d363e[_0xdf57c9(0x19b)],
      tBuy: _0x4d363e["tBuy"],
      tBuyWait: _0x4d363e[_0xdf57c9(0x390)],
      stoploss: _0x4d363e[_0xdf57c9(0x222)],
      target: _0x4d363e[_0xdf57c9(0x31a)],
      trailingTarget: _0x4d363e[_0xdf57c9(0x37a)],
      timeClose: _0x4d363e["timeClose"],
      version: strategyVersion,
      userId: _0x4d363e["userId"],
      originalUserId: _0x4d363e[_0xdf57c9(0x33f)],
      sim: {
        maxDrawdown: "",
        maxLoss: "",
        maxNegTrades: "",
        commissionRate: 0.075,
        orderDetails: { type: _0xdf57c9(0x154), value: "" },
        tradeSize: { type: "base", value: "" },
      },
    },
    _0x30c414 = { editDate: new Date()[_0xdf57c9(0x168)]() },
    _0x13918d = [];
  return (
    (_0x20f6f9["sim"][_0xdf57c9(0x1ba)] = _0x5039bd["tradeSize"]),
    (_0x30c414["id"] = _0x20f6f9["id"]),
    (_0x30c414["creationDate"] = _0x5039bd["creationDate"]),
    (_0x30c414["result"] = _0x5039bd[_0xdf57c9(0x1ff)]),
    (_0x30c414[_0xdf57c9(0x2c4)] = _0x5039bd[_0xdf57c9(0x2c4)]),
    (_0x30c414[_0xdf57c9(0x21f)] = _0x5039bd[_0xdf57c9(0x21f)]),
    (_0x30c414[_0xdf57c9(0x21e)] = _0x5039bd[_0xdf57c9(0x21e)]),
    (_0x30c414[_0xdf57c9(0x18f)] = _0x20f6f9[_0xdf57c9(0x18f)]),
    _0x5039bd[_0xdf57c9(0x1d7)]["forEach"]((_0x109931) => {
      const _0x1836f3 = _0xdf57c9;
      let _0x197e7d = {
        ..._0x109931,
        id: _0x109931["openId"],
        sId: _0x20f6f9["id"],
      };
      _0x13918d[_0x1836f3(0x266)](_0x197e7d);
    }),
    { strategy: _0x20f6f9, status: _0x30c414, trades: _0x13918d }
  );
}
function convertOldStrategyDemo(_0x4ed324, _0x2e25ad) {
  const _0x414536 = a0_0x1f43;
  let _0x358c32 = {
      id: _0x4ed324["id"],
      name: _0x4ed324[_0x414536(0x22d)],
      nickname: _0x4ed324[_0x414536(0x27d)],
      showStrategy: _0x4ed324[_0x414536(0x296)],
      exchange: _0x4ed324[_0x414536(0x2d6)],
      pair: _0x4ed324[_0x414536(0x17a)],
      pairDetails: _0x4ed324[_0x414536(0x32f)],
      buyRules: _0x4ed324[_0x414536(0x2ec)],
      sellRules: _0x4ed324[_0x414536(0x19b)],
      tBuy: _0x4ed324["tBuy"],
      tBuyWait: _0x4ed324["tBuyWait"],
      stoploss: _0x4ed324[_0x414536(0x222)],
      target: _0x4ed324[_0x414536(0x31a)],
      trailingTarget: _0x4ed324["trailingTarget"],
      timeClose: _0x4ed324["timeClose"],
      version: strategyVersion,
      userId: _0x4ed324["userId"],
      originalUserId: _0x4ed324[_0x414536(0x33f)],
      sim: {
        maxDrawdown: "",
        maxLoss: "",
        maxNegTrades: "",
        commissionRate: 0.075,
        orderDetails: { type: "market", value: "" },
        tradeSize: { type: _0x414536(0x36e), value: "" },
      },
      live: {
        maxDrawdown: "",
        maxLoss: "",
        maxNegTrades: "",
        commissionRate: 0.075,
        orderDetails: { type: "market", value: "" },
        tradeSize: { type: _0x414536(0x36e), value: "" },
      },
    },
    _0x4d4d38 = null,
    _0x46d8fa = [];
  return (
    _0x2e25ad &&
      ((_0x4d4d38 = { editDate: new Date()[_0x414536(0x168)]() }),
      (_0x358c32[_0x414536(0x184)][_0x414536(0x1ba)] =
        _0x2e25ad[_0x414536(0x1ba)]),
      (_0x4d4d38["id"] = _0x358c32["id"]),
      (_0x4d4d38["creationDate"] = _0x2e25ad[_0x414536(0x387)]),
      (_0x4d4d38[_0x414536(0x1ff)] = _0x2e25ad[_0x414536(0x1ff)]),
      (_0x4d4d38[_0x414536(0x2c4)] = _0x2e25ad[_0x414536(0x2c4)]),
      (_0x4d4d38[_0x414536(0x21f)] = _0x2e25ad["activeTrade"]),
      (_0x4d4d38["tradesCount"] = _0x2e25ad[_0x414536(0x21e)]),
      (_0x4d4d38[_0x414536(0x18f)] = _0x358c32[_0x414536(0x18f)]),
      (_0x4d4d38[_0x414536(0x2e9)] = _0x2e25ad[_0x414536(0x2e9)]),
      _0x2e25ad["trades"][_0x414536(0x172)]((_0x15a7b8) => {
        const _0x5d1a61 = _0x414536;
        let _0xe2f1f7 = {
          ..._0x15a7b8,
          id: _0x15a7b8[_0x5d1a61(0x263)],
          sId: _0x358c32["id"],
        };
        _0x46d8fa[_0x5d1a61(0x266)](_0xe2f1f7);
      })),
    { strategy: _0x358c32, status: _0x4d4d38, trades: _0x46d8fa }
  );
}
async function convertOldStrategy(_0x34e9b9, _0x1099b9, _0x2a7341) {
  const _0x29fb59 = a0_0x1f43;
  let _0x3eac0e = {
      id: _0x34e9b9["id"],
      editDate: new Date()[_0x29fb59(0x168)](),
      name: _0x34e9b9[_0x29fb59(0x22d)],
      exchange: _0x34e9b9[_0x29fb59(0x2d6)],
      pair: _0x34e9b9[_0x29fb59(0x17a)],
      pairDetails: _0x34e9b9["pairDetails"],
      buyRules: _0x34e9b9[_0x29fb59(0x2ec)],
      sellRules: _0x34e9b9[_0x29fb59(0x19b)],
      tBuy: _0x34e9b9["tBuy"],
      tBuyWait: _0x34e9b9[_0x29fb59(0x390)],
      stoploss: _0x34e9b9[_0x29fb59(0x222)],
      target: _0x34e9b9[_0x29fb59(0x31a)],
      trailingTarget: _0x34e9b9["trailingTarget"],
      timeClose: _0x34e9b9[_0x29fb59(0x294)],
      version: strategyVersion,
      userId: _0x34e9b9["userId"],
      sim: {
        maxDrawdown: "",
        maxLoss: "",
        maxNegTrades: "",
        commissionRate: "",
        orderDetails: { type: _0x29fb59(0x154), value: "" },
        tradeSize: { type: _0x29fb59(0x36e), value: "" },
      },
      live: {
        maxDrawdown: "",
        maxLoss: "",
        maxNegTrades: "",
        commissionRate: "",
        orderDetails: { type: _0x29fb59(0x154), value: "" },
        tradeSize: { type: _0x29fb59(0x36e), value: "" },
      },
    },
    _0x1a0325 = null,
    _0x369f4c = null,
    _0x1d911c = null,
    _0x54ed99 = null,
    _0x52c573 = [],
    _0x45f880 = [],
    _0x5aee8d = [],
    _0x3f9818 = [];
  return (
    _0x1099b9["forEach"]((_0x5d0803) => {
      const _0x943df9 = _0x29fb59;
      let _0x179900 = null,
        _0x5bcacb = { editDate: new Date()[_0x943df9(0x168)]() },
        _0x37ca44 = { editDate: new Date()["getTime"]() },
        _0x1dcff8 = [],
        _0x4b2c1d = [];
      _0x5d0803[_0x943df9(0x398)] === _0x943df9(0x26a)
        ? ((_0x179900 = _0x3eac0e[_0x943df9(0x2ad)]),
          (_0x369f4c = _0x5bcacb),
          (_0x54ed99 = _0x37ca44),
          (_0x45f880 = _0x1dcff8),
          (_0x3f9818 = _0x4b2c1d))
        : ((_0x179900 = _0x3eac0e[_0x943df9(0x184)]),
          (_0x1a0325 = _0x5bcacb),
          (_0x1d911c = _0x37ca44),
          (_0x52c573 = _0x1dcff8),
          (_0x5aee8d = _0x4b2c1d)),
        (_0x179900["maxDrawdown"] = _0x5d0803[_0x943df9(0x25f)]),
        (_0x179900[_0x943df9(0x1d6)] = _0x5d0803[_0x943df9(0x1d6)]),
        (_0x179900[_0x943df9(0x27b)] = _0x5d0803[_0x943df9(0x27b)]),
        (_0x179900[_0x943df9(0x1ba)] = _0x5d0803["tradeSize"]),
        (_0x179900["orderDetails"] = _0x5d0803[_0x943df9(0x2b7)]),
        (_0x179900["commissionRate"] = _0x5d0803[_0x943df9(0x1e8)]),
        (_0x5bcacb["id"] = _0x3eac0e["id"]),
        (_0x5bcacb[_0x943df9(0x387)] = _0x5d0803[_0x943df9(0x387)]),
        (_0x5bcacb[_0x943df9(0x2b9)] = _0x5d0803[_0x943df9(0x2b9)]),
        (_0x5bcacb[_0x943df9(0x1ff)] = _0x5d0803[_0x943df9(0x1ff)]),
        (_0x5bcacb[_0x943df9(0x2c4)] = _0x5d0803[_0x943df9(0x2c4)]),
        (_0x5bcacb[_0x943df9(0x21f)] = _0x5d0803[_0x943df9(0x21f)]),
        (_0x5bcacb[_0x943df9(0x2e9)] = _0x5d0803[_0x943df9(0x2e9)]),
        (_0x5bcacb["tradesCount"] = _0x5d0803[_0x943df9(0x21e)]),
        (_0x5bcacb["error"] = _0x5d0803[_0x943df9(0x194)]),
        (_0x5bcacb["errorLevel"] = _0x5d0803[_0x943df9(0x319)]),
        (_0x5bcacb[_0x943df9(0x18f)] = _0x3eac0e[_0x943df9(0x18f)]),
        (_0x5bcacb[_0x943df9(0x2ab)] = _0x5d0803[_0x943df9(0x2ab)]),
        (_0x5bcacb[_0x943df9(0x309)] = _0x5d0803[_0x943df9(0x309)]),
        _0x5bcacb[_0x943df9(0x21f)] &&
          (_0x5bcacb[_0x943df9(0x2e2)] =
            _0x5d0803[_0x943df9(0x1d7)][_0x943df9(0x349)] > 0x0
              ? _0x5d0803[_0x943df9(0x1d7)][
                  _0x5d0803[_0x943df9(0x1d7)][_0x943df9(0x349)] - 0x1
                ]
              : null),
        (_0x37ca44["id"] = _0x3eac0e["id"]),
        (_0x37ca44["pa"] = _0x5d0803[_0x943df9(0x1ca)]),
        (_0x37ca44["s"] = _0x5d0803[_0x943df9(0x2a1)]),
        (_0x37ca44["tc"] = _0x5d0803[_0x943df9(0x1b4)]),
        (_0x37ca44["t"] = _0x5d0803[_0x943df9(0x25c)]),
        (_0x37ca44["tt"] = _0x5d0803[_0x943df9(0x363)]),
        (_0x37ca44["hp"] = _0x5d0803["highPriceUsedForTrailing"]),
        (_0x37ca44["tbl"] = _0x5d0803[_0x943df9(0x28f)]),
        (_0x37ca44[_0x943df9(0x29d)] = _0x5d0803[_0x943df9(0x161)]),
        _0x5d0803[_0x943df9(0x1d7)][_0x943df9(0x172)]((_0x1c2919) => {
          const _0x18c9ca = _0x943df9;
          let _0x4fb124 = {
            ..._0x1c2919,
            id: _0x1c2919[_0x18c9ca(0x263)],
            sId: _0x3eac0e["id"],
          };
          _0x1dcff8[_0x18c9ca(0x266)](_0x4fb124);
        }),
        _0x5d0803[_0x943df9(0x206)][_0x943df9(0x172)]((_0x339961) => {
          let _0x14cc3b = { ..._0x339961, sId: _0x3eac0e["id"] };
          _0x4b2c1d["push"](_0x14cc3b);
        });
    }),
    await getEcbDatabase()[_0x29fb59(0x27a)](_0x3eac0e),
    await sendStrategy(_0x2a7341, _0x3eac0e),
    _0x369f4c &&
      (await getEcbDatabase()[_0x29fb59(0x2f0)](_0x369f4c),
      await sendStrategyStatus(_0x2a7341, _0x29fb59(0x26a), _0x369f4c)),
    _0x54ed99 &&
      (await getEcbDatabase()[_0x29fb59(0x24e)](_0x54ed99),
      await sendStrategyDetails(_0x2a7341, "simulation", _0x54ed99)),
    _0x45f880[_0x29fb59(0x349)] > 0x0 &&
      (await getEcbDatabase()["storeSimStrategyTrades"](_0x45f880),
      await sendTrades(_0x2a7341, _0x29fb59(0x26a), _0x45f880)),
    _0x3f9818[_0x29fb59(0x349)] > 0x0 &&
      (await getEcbDatabase()["storeSimStrategyEvents"](_0x3f9818),
      await sendEvents(_0x2a7341, _0x29fb59(0x26a), _0x3f9818)),
    _0x1a0325 &&
      (await getEcbDatabase()[_0x29fb59(0x181)](_0x1a0325),
      await sendStrategyStatus(_0x2a7341, _0x29fb59(0x260), _0x1a0325)),
    _0x1d911c &&
      (await getEcbDatabase()[_0x29fb59(0x1cc)](_0x1d911c),
      await sendStrategyDetails(_0x2a7341, _0x29fb59(0x260), _0x1d911c)),
    _0x52c573[_0x29fb59(0x349)] > 0x0 &&
      (await getEcbDatabase()[_0x29fb59(0x28c)](_0x52c573),
      await sendTrades(_0x2a7341, _0x29fb59(0x260), _0x52c573)),
    _0x5aee8d[_0x29fb59(0x349)] > 0x0 &&
      (await getEcbDatabase()[_0x29fb59(0x2b8)](_0x5aee8d),
      await sendEvents(_0x2a7341, "trading", _0x5aee8d)),
    { strategy: _0x3eac0e, statusSim: _0x369f4c, statusLive: _0x1a0325 }
  );
}
function getStrategiesTraficSaver(_0x39be8a, _0x505e0e) {
  const _0x4cbb81 = a0_0x1f43;
  if (_0x39be8a === _0x4cbb81(0x37e)) return;
  const _0x4f3c5f = _0x4cbb81(0x1b6),
    _0x37e9af = { strategies: [], simStatuses: [], liveStatuses: [] };
  return new Promise(async (_0x2631df) => {
    const _0x42acd9 = _0x4cbb81;
    await fetchWithRetry(
      _0x4f3c5f,
      _0x42acd9(0x308),
      null,
      async (_0x1c6b18) => {
        const _0x14dd3e = _0x42acd9;
        try {
          let _0x224340 = {},
            _0x16c506 = {};
          _0x1c6b18[_0x14dd3e(0x175)][_0x14dd3e(0x172)]((_0x14adfe) => {
            _0x224340[_0x14adfe["id"]] = _0x14adfe;
          }),
            _0x1c6b18[_0x14dd3e(0x18e)][_0x14dd3e(0x172)]((_0x485cae) => {
              _0x16c506[_0x485cae["id"]] = _0x485cae;
            }),
            _0x1c6b18["strategies"][_0x14dd3e(0x172)]((_0x3d5be8) => {
              const _0x36a119 = _0x14dd3e;
              (_0x3d5be8[_0x36a119(0x2ec)] = []),
                (_0x3d5be8["sellRules"] = []),
                _0x3d5be8[_0x36a119(0x2ec)][_0x36a119(0x266)]({
                  timeframe: _0x36a119(0x378),
                });
            }),
            _0x505e0e({
              type: "strategies",
              payload: {
                strategies: _0x1c6b18[_0x14dd3e(0x23f)],
                simStatuses: _0x224340,
                liveStatuses: _0x16c506,
              },
            }),
            _0x505e0e({ type: "simStatuses", payload: _0x224340 }),
            _0x505e0e({ type: _0x14dd3e(0x18e), payload: _0x16c506 }),
            _0x2631df(_0x1c6b18);
          return;
        } catch (_0x4d63b3) {
          _0x2631df(_0x37e9af);
        }
      },
      async () => {
        _0x2631df(_0x37e9af);
        return;
      },
      { Authorization: _0x42acd9(0x1e6) + _0x39be8a }
    );
  });
}
function comparePairDetails(_0x38db84, _0x26c785) {
  const _0x143de6 = a0_0x1f43,
    _0x3379ef = [
      _0x143de6(0x325),
      _0x143de6(0x1e4),
      _0x143de6(0x35f),
      _0x143de6(0x215),
      _0x143de6(0x2bb),
      "minPrice",
      "quotedDecimal",
      _0x143de6(0x3be),
    ];
  let _0x1b2508 = ![];
  if (_0x26c785 && _0x26c785[_0x38db84["pair"]]) {
    for (let _0x487ccd of _0x3379ef) {
      if (
        _0x26c785[_0x38db84[_0x143de6(0x17a)]][_0x487ccd] !==
        _0x38db84["pairDetails"][_0x487ccd]
      ) {
        _0x1b2508 = !![];
        break;
      }
    }
    return _0x1b2508;
  }
}
function getStrategies(_0x51cf4a, _0x7027a1, _0x29f0d2, _0x2a853b) {
  const _0x442563 = a0_0x1f43;
  if (_0x51cf4a === _0x442563(0x37e)) return;
  const _0x1ca580 = _0x29f0d2 ? _0x442563(0x35e) + _0x7027a1 : _0x442563(0x2e3);
  return new Promise(async (_0x4b4b6c) => {
    const _0x59f9d4 = _0x442563;
    await fetchWithRetry(
      _0x1ca580,
      _0x59f9d4(0x308),
      null,
      async (_0x35a5d6) => {
        const _0x2771be = _0x59f9d4;
        try {
          if (_0x29f0d2) {
            _0x35a5d6 && _0x35a5d6[_0x2771be(0x349)] > 0x0
              ? (_0x35a5d6[_0x2771be(0x172)]((_0x3a773f) =>
                  fixStrategyVersion(_0x3a773f)
                ),
                _0x4b4b6c(_0x35a5d6))
              : _0x4b4b6c([]);
            return;
          }
          let _0x9c3b4a = await getEcbDatabase()["getStrategies"](_0x7027a1),
            _0x3a9395 = [];
          if (_0x35a5d6 && _0x35a5d6[_0x2771be(0x349)] > 0x0) {
            _0x3a9395 = _0x35a5d6;
            for (let _0xfe7df1 of _0x3a9395) {
              if (_0xfe7df1) {
                let _0x54fa55 = _0x9c3b4a[_0x2771be(0x20c)](
                  (_0x4ccf8a) => _0x4ccf8a["id"] === _0xfe7df1["id"]
                );
                if (_0x54fa55) {
                  if (
                    _0xfe7df1[_0x2771be(0x3a4)] &&
                    _0x54fa55[_0x2771be(0x3a4)] &&
                    _0xfe7df1["editDate"] > _0x54fa55[_0x2771be(0x3a4)]
                  ) {
                    if (_0x2a853b) {
                      let _0x54db89 = await getEcbDatabase()[_0x2771be(0x328)](
                        _0xfe7df1["id"]
                      );
                      _0x54db89 &&
                        ((_0x54db89[_0x2771be(0x2e9)] = _0x2771be(0x2c3)),
                        delete _0x54db89["starting"],
                        await getEcbDatabase()[_0x2771be(0x181)](_0x54db89),
                        (_0xfe7df1[_0x2771be(0x2b5)] = !![]));
                      let _0xf082dc = await getEcbDatabase()[_0x2771be(0x38a)](
                        _0xfe7df1["id"]
                      );
                      _0xf082dc &&
                        ((_0xf082dc[_0x2771be(0x2e9)] = _0x2771be(0x2c3)),
                        delete _0xf082dc[_0x2771be(0x1d4)],
                        await getEcbDatabase()[_0x2771be(0x2f0)](_0xf082dc),
                        (_0xfe7df1[_0x2771be(0x2b5)] = !![]));
                    }
                    await getEcbDatabase()[_0x2771be(0x27a)](_0xfe7df1);
                  }
                } else {
                  let _0x26c01c = getArrayFromLocalStore(
                    "removedStrategiesIds"
                  );
                  !_0x26c01c[_0x2771be(0x1c4)](_0xfe7df1["id"]) &&
                    (await getEcbDatabase()[_0x2771be(0x27a)](_0xfe7df1));
                }
              }
            }
          }
          let _0x4904d5 = getArrayFromLocalStore(_0x2771be(0x17b));
          for (let _0x130c26 of _0x9c3b4a) {
            !_0x3a9395[_0x2771be(0x20c)](
              (_0x23f299) => _0x23f299["id"] === _0x130c26["id"]
            ) &&
              !_0x4904d5[_0x2771be(0x1c4)](_0x130c26["id"]) &&
              (await getEcbDatabase()[_0x2771be(0x2b1)](_0x130c26["id"]),
              await getEcbDatabase()[_0x2771be(0x38d)](_0x130c26["id"]),
              await getEcbDatabase()[_0x2771be(0x22e)](_0x130c26["id"]),
              await getEcbDatabase()[_0x2771be(0x31c)](_0x130c26["id"]),
              await getEcbDatabase()[_0x2771be(0x32b)](_0x130c26["id"]),
              await getEcbDatabase()["deleteLiveStrategyStatus"](
                _0x130c26["id"]
              ),
              await getEcbDatabase()["deleteLiveStrategyDetails"](
                _0x130c26["id"]
              ),
              await getEcbDatabase()[_0x2771be(0x183)](_0x130c26["id"]),
              await getEcbDatabase()[_0x2771be(0x391)](_0x130c26["id"]));
          }
          _0x9c3b4a = await getEcbDatabase()[_0x2771be(0x16f)](_0x7027a1);
          if (_0x2a853b) {
            let _0x3e3a81 = ![];
            for (let _0x391368 of _0x9c3b4a) {
              const _0xc43704 = await getExchange(_0x391368["exchange"])[
                _0x2771be(0x26d)
              ]();
              let _0xf12e09 = _0xc43704 ? _0xc43704[0x1] : null;
              comparePairDetails(_0x391368, _0xf12e09) &&
                ((_0x3e3a81 = !![]),
                (_0x391368[_0x2771be(0x32f)] =
                  _0xf12e09[_0x391368[_0x2771be(0x17a)]]),
                await getEcbDatabase()[_0x2771be(0x27a)](_0x391368));
            }
            _0x3e3a81 &&
              (_0x9c3b4a = await getEcbDatabase()[_0x2771be(0x16f)](_0x7027a1));
          }
          _0x9c3b4a[_0x2771be(0x172)]((_0x1fc530) =>
            fixStrategyVersion(_0x1fc530)
          ),
            _0x4b4b6c(_0x9c3b4a);
          return;
        } catch (_0xeb6c51) {
          if (_0x29f0d2) {
            _0x4b4b6c([]), console[_0x2771be(0x281)](_0x2771be(0x28e));
            return;
          }
          let _0x6b0723 = await getEcbDatabase()["getStrategies"](_0x7027a1);
          _0x6b0723[_0x2771be(0x172)]((_0x3e3e3d) =>
            fixStrategyVersion(_0x3e3e3d)
          ),
            _0x4b4b6c(_0x6b0723);
        }
      },
      async () => {
        const _0x3ae581 = _0x59f9d4;
        if (_0x29f0d2) {
          _0x4b4b6c([]), console["log"](_0x3ae581(0x28e));
          return;
        }
        let _0x2ff652 = await getEcbDatabase()[_0x3ae581(0x16f)](_0x7027a1);
        _0x2ff652[_0x3ae581(0x172)]((_0x21d7a1) =>
          fixStrategyVersion(_0x21d7a1)
        ),
          _0x4b4b6c(_0x2ff652);
        return;
      },
      { Authorization: _0x59f9d4(0x1e6) + _0x51cf4a }
    );
  });
}
function getStrategiesStatuses(
  _0x263b71,
  _0x8c129f,
  _0x53990c,
  _0x20348c,
  _0x2222c5
) {
  const _0x521ecb = a0_0x1f43;
  if (_0x263b71 === _0x521ecb(0x37e)) return;
  const _0x1f6f2c = _0x2222c5
    ? _0x521ecb(0x342) +
      _0x8c129f +
      "&type=" +
      (_0x53990c === "simulation" ? "sim" : _0x521ecb(0x184))
    : "https://api2.easycryptobot.com/strategy-status.php?type=" +
      (_0x53990c === _0x521ecb(0x26a) ? "sim" : _0x521ecb(0x184));
  return new Promise(async (_0xac58e3) => {
    const _0x2219f3 = _0x521ecb;
    await fetchWithRetry(
      _0x1f6f2c,
      _0x2219f3(0x308),
      null,
      async (_0x1fedf6) => {
        const _0x198706 = _0x2219f3;
        try {
          if (_0x2222c5) {
            _0xac58e3(_0x1fedf6);
            return;
          }
          let _0x1af47a =
              _0x53990c === _0x198706(0x26a)
                ? await getEcbDatabase()[_0x198706(0x199)](_0x8c129f)
                : await getEcbDatabase()["getLiveStrategyStatuses"](_0x8c129f),
            _0x113d98 = [];
          if (_0x1fedf6 && _0x1fedf6[_0x198706(0x349)] > 0x0) {
            _0x113d98 = _0x1fedf6;
            for (let _0x4c7348 of _0x113d98) {
              if (_0x4c7348) {
                _0x20348c &&
                  ((_0x4c7348[_0x198706(0x2e9)] = _0x198706(0x2c3)),
                  delete _0x4c7348[_0x198706(0x1d4)]);
                let _0x533bb2 = _0x1af47a[_0x198706(0x20c)](
                  (_0x2bddb4) => _0x2bddb4["id"] === _0x4c7348["id"]
                );
                if (_0x533bb2) {
                  if (
                    _0x4c7348[_0x198706(0x3a4)] > _0x533bb2[_0x198706(0x3a4)]
                  ) {
                    if (_0x20348c) {
                      let _0x194a66 = await getEcbDatabase()[_0x198706(0x256)](
                        _0x4c7348["id"]
                      );
                      _0x194a66 &&
                        ((_0x194a66["external"] = !![]),
                        await getEcbDatabase()[_0x198706(0x27a)](_0x194a66));
                    }
                    _0x53990c === _0x198706(0x26a)
                      ? await getEcbDatabase()[_0x198706(0x2f0)](_0x4c7348)
                      : await getEcbDatabase()[_0x198706(0x181)](_0x4c7348);
                  }
                } else {
                  let _0x36f6d7 =
                    _0x53990c === _0x198706(0x26a)
                      ? getArrayFromLocalStore(_0x198706(0x30f))
                      : getArrayFromLocalStore(_0x198706(0x163));
                  !_0x36f6d7[_0x198706(0x1c4)](_0x4c7348["id"]) &&
                    (_0x53990c === _0x198706(0x26a)
                      ? await getEcbDatabase()[_0x198706(0x2f0)](_0x4c7348)
                      : await getEcbDatabase()["storeLiveStrategyStatus"](
                          _0x4c7348
                        ));
                }
              }
            }
          }
          let _0x43663e =
            _0x53990c === _0x198706(0x26a)
              ? getArrayFromLocalStore(_0x198706(0x2e0))
              : getArrayFromLocalStore(_0x198706(0x177));
          for (let _0x19a1c6 of _0x1af47a) {
            !_0x113d98["find"](
              (_0x1b54ee) => _0x1b54ee["id"] === _0x19a1c6["id"]
            ) &&
              !_0x43663e[_0x198706(0x1c4)](_0x19a1c6["id"]) &&
              (_0x53990c === _0x198706(0x26a)
                ? (await getEcbDatabase()[_0x198706(0x38d)](_0x19a1c6["id"]),
                  await getEcbDatabase()[_0x198706(0x22e)](_0x19a1c6["id"]),
                  await getEcbDatabase()[_0x198706(0x31c)](_0x19a1c6["id"]),
                  await getEcbDatabase()[_0x198706(0x32b)](_0x19a1c6["id"]))
                : (await getEcbDatabase()[_0x198706(0x264)](_0x19a1c6["id"]),
                  await getEcbDatabase()[_0x198706(0x2c9)](_0x19a1c6["id"]),
                  await getEcbDatabase()[_0x198706(0x183)](_0x19a1c6["id"]),
                  await getEcbDatabase()["deleteLiveStrategyEvents"](
                    _0x19a1c6["id"]
                  )));
          }
          _0x1af47a =
            _0x53990c === "simulation"
              ? await getEcbDatabase()[_0x198706(0x199)](_0x8c129f)
              : await getEcbDatabase()["getLiveStrategyStatuses"](_0x8c129f);
          _0x20348c &&
            _0x1af47a["forEach"]((_0x58c605) => {
              const _0x589e09 = _0x198706;
              (_0x58c605[_0x589e09(0x2e9)] === "Running" ||
                _0x58c605[_0x589e09(0x1d4)]) &&
                ((_0x58c605[_0x589e09(0x1d4)] = !![]),
                (_0x58c605[_0x589e09(0x2e9)] = _0x589e09(0x236)));
            });
          _0xac58e3(_0x1af47a);
          return;
        } catch (_0x49e447) {
          let _0x29e219 =
            _0x53990c === "simulation"
              ? await getEcbDatabase()[_0x198706(0x199)](_0x8c129f)
              : await getEcbDatabase()["getLiveStrategyStatuses"](_0x8c129f);
          _0x20348c &&
            _0x29e219[_0x198706(0x172)]((_0x236478) => {
              const _0x1d1f76 = _0x198706;
              (_0x236478[_0x1d1f76(0x2e9)] === _0x1d1f76(0x2a9) ||
                _0x236478[_0x1d1f76(0x1d4)]) &&
                ((_0x236478[_0x1d1f76(0x1d4)] = !![]),
                (_0x236478[_0x1d1f76(0x2e9)] = "Loading"));
            }),
            _0xac58e3(_0x29e219);
        }
      },
      async () => {
        const _0x381184 = _0x2219f3;
        if (_0x2222c5) {
          _0xac58e3([]), console[_0x381184(0x281)](_0x381184(0x28e));
          return;
        }
        let _0x17e37a =
          _0x53990c === _0x381184(0x26a)
            ? await getEcbDatabase()[_0x381184(0x199)](_0x8c129f)
            : await getEcbDatabase()[_0x381184(0x364)](_0x8c129f);
        _0x20348c &&
          _0x17e37a[_0x381184(0x172)]((_0x380677) => {
            const _0x123edc = _0x381184;
            (_0x380677[_0x123edc(0x2e9)] === "Running" ||
              _0x380677[_0x123edc(0x1d4)]) &&
              ((_0x380677[_0x123edc(0x1d4)] = !![]),
              (_0x380677[_0x123edc(0x2e9)] = "Loading"));
          });
        _0xac58e3(_0x17e37a);
        return;
      },
      { Authorization: _0x2219f3(0x1e6) + _0x263b71 }
    );
  });
}
function getStrategyStatuses(_0x1de542, _0x5e3d61, _0xf42322) {
  const _0x2acbaa = a0_0x1f43;
  if (_0x1de542 === _0x2acbaa(0x37e)) return;
  const _0x393b7e =
    _0x2acbaa(0x3ae) +
    (_0x5e3d61 === "simulation" ? _0x2acbaa(0x2ad) : _0x2acbaa(0x184)) +
    _0x2acbaa(0x290) +
    _0xf42322;
  return new Promise(async (_0x44a992) => {
    const _0x3b7646 = _0x2acbaa;
    await fetchWithRetry(
      _0x393b7e,
      "get",
      null,
      async (_0xce7566) => {
        _0x44a992(_0xce7566);
        return;
      },
      async () => {
        _0x44a992(null);
        return;
      },
      { Authorization: _0x3b7646(0x1e6) + _0x1de542 }
    );
  });
}
function getStrategiesDetail(_0x257f60, _0x2cafe0, _0x39bb49, _0x46614f) {
  const _0x4723dd = a0_0x1f43;
  if (_0x257f60 === _0x4723dd(0x37e)) return;
  const _0x55b56b =
    _0x4723dd(0x155) +
    _0x2cafe0 +
    _0x4723dd(0x179) +
    _0x46614f +
    _0x4723dd(0x1f4) +
    (_0x39bb49 === _0x4723dd(0x26a) ? _0x4723dd(0x2ad) : _0x4723dd(0x184));
  return new Promise(async (_0x2ead0a) => {
    const _0x3cc8f7 = _0x4723dd;
    await fetchWithRetry(
      _0x55b56b,
      _0x3cc8f7(0x308),
      null,
      async (_0x41ef06) => {
        const _0x25987d = _0x3cc8f7;
        try {
          _0x2ead0a(_0x41ef06);
          return;
        } catch (_0x50cfdb) {
          console[_0x25987d(0x281)](_0x25987d(0x1c6), _0x50cfdb),
            _0x2ead0a(null);
        }
      },
      async (_0x1ced73) => {
        console["log"](_0x1ced73), _0x2ead0a(null);
      },
      { Authorization: _0x3cc8f7(0x1e6) + _0x257f60 }
    );
  });
}
function getStrategiesDetails(_0x314bf0, _0x397023, _0x9dc836, _0x20990d) {
  const _0x39bedf = a0_0x1f43;
  if (_0x314bf0 === _0x39bedf(0x37e)) return;
  const _0x384e62 = _0x20990d
    ? "https://api2.easycryptobot.com/admin/strategy-details.php?userId=" +
      _0x397023 +
      "&type=" +
      (_0x9dc836 === _0x39bedf(0x26a) ? _0x39bedf(0x2ad) : "live")
    : "https://api2.easycryptobot.com/strategy-details.php?type=" +
      (_0x9dc836 === _0x39bedf(0x26a) ? _0x39bedf(0x2ad) : "live");
  return new Promise(async (_0x258fca) => {
    const _0x3fbbde = _0x39bedf;
    await fetchWithRetry(
      _0x384e62,
      _0x3fbbde(0x308),
      null,
      async (_0x6ed128) => {
        const _0x1f9fe1 = _0x3fbbde;
        try {
          if (_0x20990d) {
            _0x258fca(_0x6ed128);
            return;
          }
          let _0x34beb0 =
              _0x9dc836 === _0x1f9fe1(0x26a)
                ? await getEcbDatabase()[_0x1f9fe1(0x3bc)](_0x397023)
                : await getEcbDatabase()[_0x1f9fe1(0x1a4)](_0x397023),
            _0x3c2982 = [];
          if (_0x6ed128 && _0x6ed128[_0x1f9fe1(0x349)] > 0x0) {
            _0x3c2982 = _0x6ed128;
            for (let _0x50efd6 of _0x3c2982) {
              if (_0x50efd6) {
                let _0xc29cb5 =
                  _0x9dc836 === _0x1f9fe1(0x26a)
                    ? await getEcbDatabase()[_0x1f9fe1(0x38a)](_0x50efd6["sId"])
                    : await getEcbDatabase()[_0x1f9fe1(0x328)](
                        _0x50efd6[_0x1f9fe1(0x3a2)]
                      );
                if (
                  !_0xc29cb5 ||
                  _0xc29cb5[_0x1f9fe1(0x387)] > _0x50efd6[_0x1f9fe1(0x3a4)]
                )
                  continue;
                let _0x48aa9b = _0x34beb0[_0x1f9fe1(0x20c)](
                  (_0x169308) => _0x169308["id"] === _0x50efd6["id"]
                );
                if (_0x48aa9b)
                  _0x50efd6[_0x1f9fe1(0x3a4)] &&
                    _0x48aa9b["editDate"] &&
                    _0x50efd6[_0x1f9fe1(0x3a4)] > _0x48aa9b[_0x1f9fe1(0x3a4)] &&
                    (_0x9dc836 === _0x1f9fe1(0x26a)
                      ? await getEcbDatabase()[_0x1f9fe1(0x24e)](_0x50efd6)
                      : await getEcbDatabase()[_0x1f9fe1(0x1cc)](_0x50efd6));
                else {
                  let _0x26cd92 =
                    _0x9dc836 === _0x1f9fe1(0x26a)
                      ? getArrayFromLocalStore("removedSimDetailsIds")
                      : getArrayFromLocalStore(_0x1f9fe1(0x2d9));
                  !_0x26cd92[_0x1f9fe1(0x1c4)](_0x50efd6["id"]) &&
                    (_0x9dc836 === "simulation"
                      ? await getEcbDatabase()["storeSimStrategyDetails"](
                          _0x50efd6
                        )
                      : await getEcbDatabase()[_0x1f9fe1(0x1cc)](_0x50efd6));
                }
              }
            }
          }
          let _0x28b4b5 =
            _0x9dc836 === _0x1f9fe1(0x26a)
              ? getArrayFromLocalStore(_0x1f9fe1(0x1bb))
              : getArrayFromLocalStore(_0x1f9fe1(0x265));
          for (let _0x1938ba of _0x34beb0) {
            !_0x3c2982[_0x1f9fe1(0x20c)](
              (_0x5c4ce7) => _0x5c4ce7["id"] === _0x1938ba["id"]
            ) &&
              !_0x28b4b5["includes"](_0x1938ba["id"]) &&
              (_0x9dc836 === _0x1f9fe1(0x26a)
                ? (console["log"]("delete\x20DETAILS"),
                  await getEcbDatabase()[_0x1f9fe1(0x22e)](_0x1938ba["id"]))
                : await getEcbDatabase()[_0x1f9fe1(0x2c9)](_0x1938ba["id"]));
          }
          (_0x34beb0 =
            _0x9dc836 === _0x1f9fe1(0x26a)
              ? await getEcbDatabase()[_0x1f9fe1(0x3bc)](_0x397023)
              : await getEcbDatabase()[_0x1f9fe1(0x1a4)](_0x397023)),
            _0x258fca(_0x34beb0);
          return;
        } catch (_0x26f450) {
          console["log"](_0x1f9fe1(0x1c6), _0x26f450);
          let _0x3eb55d =
            _0x9dc836 === _0x1f9fe1(0x26a)
              ? await getEcbDatabase()[_0x1f9fe1(0x3bc)](_0x397023)
              : await getEcbDatabase()[_0x1f9fe1(0x1a4)](_0x397023);
          _0x258fca(_0x3eb55d);
        }
      },
      async () => {
        const _0x6fbb3 = _0x3fbbde;
        let _0x5f4c9c =
          _0x9dc836 === _0x6fbb3(0x26a)
            ? await getEcbDatabase()["getSimStrategyDetailses"](_0x397023)
            : await getEcbDatabase()[_0x6fbb3(0x1a4)](_0x397023);
        _0x258fca(_0x5f4c9c);
      },
      { Authorization: _0x3fbbde(0x1e6) + _0x314bf0 }
    );
  });
}
function getUserTrades(_0x3ca765, _0x15df99, _0x239f4e, _0x5ed64e, _0x25d42f) {
  const _0x38543f = a0_0x1f43;
  if (_0x3ca765 === _0x38543f(0x37e)) return;
  const _0xc5edc0 = _0x239f4e
    ? _0x38543f(0x202) +
      _0x5ed64e +
      _0x38543f(0x2a8) +
      _0x25d42f +
      "&type=" +
      (_0x15df99 === _0x38543f(0x26a) ? _0x38543f(0x2ad) : "live")
    : _0x38543f(0x230) +
      (_0x15df99 === _0x38543f(0x26a) ? _0x38543f(0x2ad) : _0x38543f(0x184)) +
      "&user=yes";
  return new Promise(async (_0x11bae1) => {
    const _0x299adc = _0x38543f;
    await fetchWithRetry(
      _0xc5edc0,
      _0x299adc(0x308),
      null,
      async (_0x84fd21) => {
        const _0xc2637d = _0x299adc;
        try {
          if (_0x239f4e) {
            _0x11bae1(_0x84fd21);
            return;
          }
          let _0x20dfc2 = [];
          if (_0x84fd21 && _0x84fd21[_0xc2637d(0x349)] > 0x0) {
            _0x20dfc2 = _0x84fd21;
            for (let _0x532e56 of _0x20dfc2) {
              if (_0x532e56) {
                let _0x5a6c82 =
                  _0x15df99 === _0xc2637d(0x26a)
                    ? await getEcbDatabase()[_0xc2637d(0x38a)](
                        _0x532e56[_0xc2637d(0x3a2)]
                      )
                    : await getEcbDatabase()[_0xc2637d(0x328)](
                        _0x532e56["sId"]
                      );
                if (
                  !_0x5a6c82 ||
                  _0x5a6c82[_0xc2637d(0x387)] > _0x532e56[_0xc2637d(0x3a4)]
                )
                  continue;
                let _0x589d38 =
                  _0x15df99 === "simulation"
                    ? await getEcbDatabase()[_0xc2637d(0x23e)](_0x532e56["id"])
                    : await getEcbDatabase()["getLiveStrategyTrade"](
                        _0x532e56["id"]
                      );
                if (_0x589d38)
                  _0x532e56["editDate"] > _0x589d38[_0xc2637d(0x3a4)] &&
                    (_0x15df99 === _0xc2637d(0x26a)
                      ? await getEcbDatabase()[_0xc2637d(0x1a7)](_0x532e56)
                      : await getEcbDatabase()[_0xc2637d(0x26c)](_0x532e56));
                else {
                  let _0xe36886 =
                    _0x15df99 === _0xc2637d(0x26a)
                      ? getArrayFromLocalStore("removedTradesSimIds")
                      : getArrayFromLocalStore(_0xc2637d(0x208));
                  !_0xe36886[_0xc2637d(0x1c4)](_0x532e56["id"]) &&
                    (_0x15df99 === _0xc2637d(0x26a)
                      ? await getEcbDatabase()["storeSimStrategyTrade"](
                          _0x532e56
                        )
                      : await getEcbDatabase()[_0xc2637d(0x26c)](_0x532e56));
                }
              }
            }
          }
          _0x11bae1(!![]);
          return;
        } catch (_0x1e674e) {
          _0x11bae1(!![]);
        }
      },
      async () => {
        _0x11bae1(!![]);
        return;
      },
      { Authorization: _0x299adc(0x1e6) + _0x3ca765 }
    );
  });
}
function getUserEvents(_0x34d183, _0x2ee9e7, _0x529320, _0xfa64a7, _0x4962bf) {
  const _0x3062dc = a0_0x1f43;
  if (_0x34d183 === _0x3062dc(0x37e)) return;
  const _0x35bb28 = _0x529320
    ? _0x3062dc(0x1ad) +
      _0xfa64a7 +
      "&sId=" +
      _0x4962bf +
      _0x3062dc(0x1f4) +
      (_0x2ee9e7 === _0x3062dc(0x26a) ? _0x3062dc(0x2ad) : _0x3062dc(0x184))
    : _0x3062dc(0x271) +
      (_0x2ee9e7 === "simulation" ? _0x3062dc(0x2ad) : "live") +
      _0x3062dc(0x31d);
  return new Promise(async (_0x36484c) => {
    const _0x52406b = _0x3062dc;
    await fetchWithRetry(
      _0x35bb28,
      _0x52406b(0x308),
      null,
      async (_0x589f74) => {
        const _0x338091 = _0x52406b;
        try {
          if (_0x529320) {
            _0x36484c(_0x589f74);
            return;
          }
          let _0x4f3ce3 = [];
          if (_0x589f74 && _0x589f74[_0x338091(0x349)] > 0x0) {
            _0x4f3ce3 = _0x589f74;
            for (let _0x14347d of _0x4f3ce3) {
              if (_0x14347d) {
                let _0x190b5d =
                  _0x2ee9e7 === _0x338091(0x26a)
                    ? await getEcbDatabase()[_0x338091(0x38a)](
                        _0x14347d[_0x338091(0x3a2)]
                      )
                    : await getEcbDatabase()[_0x338091(0x328)](
                        _0x14347d["sId"]
                      );
                if (
                  !_0x190b5d ||
                  _0x190b5d["creationDate"] > _0x14347d[_0x338091(0x3a4)]
                )
                  continue;
                let _0x72793a =
                  _0x2ee9e7 === _0x338091(0x26a)
                    ? await getEcbDatabase()[_0x338091(0x276)](_0x14347d["id"])
                    : await getEcbDatabase()[_0x338091(0x370)](_0x14347d["id"]);
                if (_0x72793a)
                  _0x14347d[_0x338091(0x3a4)] > _0x72793a[_0x338091(0x3a4)] &&
                    (_0x2ee9e7 === "simulation"
                      ? await getEcbDatabase()[_0x338091(0x2b2)](_0x14347d)
                      : await getEcbDatabase()[_0x338091(0x2b8)](_0x14347d));
                else {
                  let _0x2be9b6 =
                    _0x2ee9e7 === "simulation"
                      ? getArrayFromLocalStore(_0x338091(0x34b))
                      : getArrayFromLocalStore(_0x338091(0x1e1));
                  !_0x2be9b6[_0x338091(0x1c4)](_0x14347d["id"]) &&
                    (_0x2ee9e7 === _0x338091(0x26a)
                      ? await getEcbDatabase()["storeSimStrategyEvent"](
                          _0x14347d
                        )
                      : await getEcbDatabase()[_0x338091(0x2dc)](_0x14347d));
                }
              }
            }
          }
          _0x36484c(!![]);
          return;
        } catch (_0x25b10a) {
          _0x36484c(!![]);
        }
      },
      async () => {
        _0x36484c(!![]);
        return;
      },
      { Authorization: _0x52406b(0x1e6) + _0x34d183 }
    );
  });
}
function getStrategyTrades(_0x14594b, _0x2b0ba0, _0x14fc6a) {
  const _0x38b3b7 = a0_0x1f43;
  if (_0x14594b === _0x38b3b7(0x37e)) return;
  const _0xdb0c5d =
    "https://api2.easycryptobot.com/strategy-trades.php?type=" +
    (_0x2b0ba0 === _0x38b3b7(0x26a) ? _0x38b3b7(0x2ad) : "live") +
    _0x38b3b7(0x2a8) +
    _0x14fc6a;
  return new Promise(async (_0x468449) => {
    const _0xd014bf = _0x38b3b7;
    await fetchWithRetry(
      _0xdb0c5d,
      _0xd014bf(0x308),
      null,
      async (_0x14b976) => {
        const _0xbdf3dd = _0xd014bf;
        try {
          let _0x26f29f = [];
          if (_0x14b976 && _0x14b976["length"] > 0x0) {
            _0x26f29f = _0x14b976;
            for (let _0x2c6633 of _0x26f29f) {
              if (_0x2c6633) {
                let _0x403e86 =
                  _0x2b0ba0 === "simulation"
                    ? await getEcbDatabase()["getSimStrategyTrade"](
                        _0x2c6633["id"]
                      )
                    : await getEcbDatabase()["getLiveStrategyTrade"](
                        _0x2c6633["id"]
                      );
                if (_0x403e86)
                  _0x2c6633[_0xbdf3dd(0x3a4)] > _0x403e86[_0xbdf3dd(0x3a4)] &&
                    (_0x2b0ba0 === _0xbdf3dd(0x26a)
                      ? await getEcbDatabase()[_0xbdf3dd(0x1a7)](_0x2c6633)
                      : await getEcbDatabase()[_0xbdf3dd(0x26c)](_0x2c6633));
                else {
                  let _0x39429d =
                    _0x2b0ba0 === _0xbdf3dd(0x26a)
                      ? getArrayFromLocalStore("removedTradesSimIds")
                      : getArrayFromLocalStore("removedTradesLiveIds");
                  !_0x39429d[_0xbdf3dd(0x1c4)](_0x2c6633["id"]) &&
                    (_0x2b0ba0 === "simulation"
                      ? await getEcbDatabase()[_0xbdf3dd(0x1a7)](_0x2c6633)
                      : await getEcbDatabase()["storeLiveStrategyTrade"](
                          _0x2c6633
                        ));
                }
              }
            }
          }
          let _0x4afa6f =
            _0x2b0ba0 === "simulation"
              ? await getEcbDatabase()[_0xbdf3dd(0x1bc)](_0x14fc6a)
              : await getEcbDatabase()[_0xbdf3dd(0x33d)](_0x14fc6a);
          _0x468449(_0x4afa6f);
          return;
        } catch (_0x552aa5) {
          let _0x1c038c =
            _0x2b0ba0 === _0xbdf3dd(0x26a)
              ? await getEcbDatabase()[_0xbdf3dd(0x1bc)](_0x14fc6a)
              : await getEcbDatabase()[_0xbdf3dd(0x33d)](_0x14fc6a);
          _0x468449(_0x1c038c);
        }
      },
      async () => {
        const _0x25a276 = _0xd014bf;
        let _0x30c8a2 =
          _0x2b0ba0 === _0x25a276(0x26a)
            ? await getEcbDatabase()[_0x25a276(0x1bc)](_0x14fc6a)
            : await getEcbDatabase()[_0x25a276(0x33d)](_0x14fc6a);
        _0x468449(_0x30c8a2);
        return;
      },
      { Authorization: _0xd014bf(0x1e6) + _0x14594b }
    );
  });
}
function getOldStrategies(
  _0x8b415c,
  _0x1212c8,
  _0x51adb0,
  _0x2bec58,
  _0x485e58
) {
  const _0x190c5b = a0_0x1f43;
  if (_0x8b415c === _0x190c5b(0x37e)) return;
  const _0x50f1fb = _0x2bec58
    ? "https://api2.easycryptobot.com/admin/strategies.php?id=" + _0x485e58
    : _0x190c5b(0x350);
  return new Promise(async (_0x29b44c) => {
    const _0x53e5e0 = _0x190c5b;
    await fetchWithRetry(
      _0x50f1fb,
      _0x53e5e0(0x308),
      null,
      async (_0xc1f6d7) => {
        const _0x504dbf = _0x53e5e0;
        try {
          if (!_0x2bec58) {
            let _0x28d040 = await getEcbDatabase()[_0x504dbf(0x1e3)](_0x1212c8),
              _0x3a89eb = await getEcbDatabase()[_0x504dbf(0x165)](_0x1212c8),
              _0x6c8ae1 = [];
            if (_0xc1f6d7 && _0xc1f6d7["length"] > 0x0) {
              _0x6c8ae1 = _0xc1f6d7[_0x504dbf(0x226)]((_0x280acd) => {
                const _0x42fc85 = _0x504dbf;
                if (_0x280acd !== null)
                  return (
                    !_0x280acd["stoploss"] &&
                      (_0x280acd[_0x42fc85(0x222)] = ""),
                    !_0x280acd[_0x42fc85(0x31a)] &&
                      (_0x280acd[_0x42fc85(0x31a)] = ""),
                    !_0x280acd[_0x42fc85(0x37a)] &&
                      (_0x280acd[_0x42fc85(0x37a)] = ""),
                    !_0x280acd[_0x42fc85(0x37a)] &&
                      (_0x280acd[_0x42fc85(0x37a)] = ""),
                    !_0x280acd["trailingTarget"] &&
                      (_0x280acd[_0x42fc85(0x37a)] = ""),
                    !_0x280acd[_0x42fc85(0x294)] &&
                      (_0x280acd[_0x42fc85(0x294)] = ""),
                    !_0x280acd["tBuy"] && (_0x280acd[_0x42fc85(0x27e)] = ""),
                    !_0x280acd[_0x42fc85(0x390)] &&
                      (_0x280acd["tBuyWait"] = ""),
                    !![]
                  );
                return ![];
              });
              for (let _0x1a9a4f of _0x6c8ae1) {
                if (_0x1a9a4f) {
                  let _0x50c604 = _0x28d040[_0x504dbf(0x20c)](
                    (_0x24645d) => _0x24645d["id"] === _0x1a9a4f["id"]
                  );
                  if (_0x50c604)
                    _0x1a9a4f[_0x504dbf(0x3a4)] > _0x50c604["editDate"] &&
                      (!_0x3a89eb[_0x504dbf(0x20c)](
                        (_0x342615) =>
                          _0x342615[_0x504dbf(0x2e5)] === _0x50c604["id"]
                      )
                        ? await getEcbDatabase()[_0x504dbf(0x396)](_0x1a9a4f)
                        : ((_0x50c604[_0x504dbf(0x3a4)] = new Date()[
                            _0x504dbf(0x168)
                          ]()),
                          await getEcbDatabase()["storeOldStrategy"](_0x50c604),
                          addItemToArrayInLocalStore(
                            _0x504dbf(0x17b),
                            _0x50c604["id"]
                          )));
                  else {
                    let _0xd6af51 = getArrayFromLocalStore(_0x504dbf(0x1dd));
                    !_0xd6af51["includes"](_0x1a9a4f["id"]) &&
                      (await getEcbDatabase()[_0x504dbf(0x396)](_0x1a9a4f));
                  }
                }
              }
            }
            let _0x485c0c = getArrayFromLocalStore(_0x504dbf(0x17b));
            for (let _0x500044 of _0x28d040) {
              !_0x6c8ae1["find"](
                (_0xa6c312) => _0xa6c312["id"] === _0x500044["id"]
              ) &&
                !_0x485c0c[_0x504dbf(0x1c4)](_0x500044["id"]) &&
                (!_0x3a89eb["find"](
                  (_0x579a06) => _0x579a06[_0x504dbf(0x2e5)] === _0x500044["id"]
                )
                  ? await getEcbDatabase()[_0x504dbf(0x3a1)](_0x500044["id"])
                  : addItemToArrayInLocalStore(
                      _0x504dbf(0x17b),
                      _0x500044["id"]
                    ));
            }
            (_0x28d040 = await getEcbDatabase()[_0x504dbf(0x1e3)](_0x1212c8)),
              _0x29b44c(_0x28d040);
            return;
          }
        } catch (_0x5c6a0b) {
          let _0x1a9b00 = await getEcbDatabase()[_0x504dbf(0x1e3)](_0x1212c8);
          _0x29b44c(_0x1a9b00);
        }
      },
      async () => {
        const _0x13d7c1 = _0x53e5e0;
        let _0x4366f5 = await getEcbDatabase()[_0x13d7c1(0x1e3)](_0x1212c8);
        _0x29b44c(_0x4366f5);
        return;
      },
      { Authorization: _0x53e5e0(0x1e6) + _0x8b415c }
    );
  });
}
function getWatchlists(_0x14db22, _0x591673, _0x2b7105) {
  const _0x4f3cc2 = a0_0x1f43;
  if (_0x14db22 === _0x4f3cc2(0x37e)) return;
  const _0x9a6c8d = "https://api2.easycryptobot.com/watchlists.php";
  return new Promise(async (_0x1330c9) => {
    const _0x485064 = _0x4f3cc2;
    await fetchWithRetry(
      _0x9a6c8d,
      _0x485064(0x308),
      null,
      async (_0x50bdd8) => {
        const _0x19c229 = _0x485064;
        try {
          let _0x444503 = await getEcbDatabase()["getWatchlists"](_0x2b7105);
          if (_0x50bdd8 && _0x50bdd8["length"] > 0x0)
            for (let _0x251dbb of _0x50bdd8) {
              if (_0x251dbb) {
                let _0x485946 = _0x444503[_0x19c229(0x20c)](
                  (_0x2766a6) => _0x2766a6["id"] === _0x251dbb["id"]
                );
                if (_0x485946)
                  _0x251dbb[_0x19c229(0x3a4)] > _0x485946["editDate"] &&
                    (await getEcbDatabase()["storeWatchlist"](_0x251dbb));
                else {
                  let _0xeda999 = getArrayFromLocalStore(
                    "watchlistsRemovedNotSynced"
                  );
                  !_0xeda999[_0x19c229(0x1c4)](_0x251dbb["id"]) &&
                    (await getEcbDatabase()["storeWatchlist"](_0x251dbb));
                }
              }
            }
          let _0x38b004 = getArrayFromLocalStore("watchlistsNotSynced");
          for (let _0x228898 of _0x444503) {
            _0x50bdd8 &&
              !_0x50bdd8[_0x19c229(0x20c)](
                (_0x58ebf5) => _0x58ebf5["id"] === _0x228898["id"]
              ) &&
              !_0x38b004[_0x19c229(0x1c4)](_0x228898["id"]) &&
              (await getEcbDatabase()[_0x19c229(0x1c1)](_0x228898["id"]));
          }
          (_0x444503 = await getEcbDatabase()["getWatchlists"](_0x2b7105)),
            _0x591673({ type: "watchlists", payload: _0x444503 }),
            _0x1330c9(_0x444503);
        } catch (_0x2fd284) {
          let _0x39015d = await getEcbDatabase()["getWatchlists"](_0x2b7105);
          _0x591673({ type: "watchlists", payload: _0x39015d }),
            _0x1330c9(_0x39015d);
        }
      },
      async () => {
        const _0x1460cb = _0x485064;
        let _0x2237b6 = await getEcbDatabase()[_0x1460cb(0x185)](_0x2b7105);
        _0x591673({ type: _0x1460cb(0x1e7), payload: _0x2237b6 }),
          _0x1330c9(_0x2237b6);
        return;
      },
      { Authorization: _0x485064(0x1e6) + _0x14db22 }
    );
  });
}
function getMultiCharts(_0x5c7797, _0x477177, _0x27a754) {
  const _0x3a37ba = a0_0x1f43;
  if (_0x5c7797 === "demo") return;
  const _0x7b8bb4 = _0x3a37ba(0x295);
  let _0x779793 = {
    id: _0x27a754 + "_" + new Date()["getTime"](),
    userId: _0x27a754,
    name: _0x3a37ba(0x2eb),
    charts: [],
    editDate: new Date()["getTime"](),
  };
  return new Promise(async (_0x1893da) => {
    const _0x934dd7 = _0x3a37ba;
    await fetchWithRetry(
      _0x7b8bb4,
      _0x934dd7(0x308),
      null,
      async (_0x1d68ff) => {
        const _0x161b4b = _0x934dd7;
        try {
          let _0x2aaada = await getEcbDatabase()["getMultiCharts"](_0x27a754);
          if (_0x1d68ff && _0x1d68ff[_0x161b4b(0x349)] > 0x0)
            for (let _0xf63914 of _0x1d68ff) {
              if (_0xf63914) {
                let _0x36381b = _0x2aaada[_0x161b4b(0x20c)](
                  (_0xd154ba) => _0xd154ba["id"] === _0xf63914["id"]
                );
                if (_0x36381b)
                  _0xf63914[_0x161b4b(0x3a4)] > _0x36381b[_0x161b4b(0x3a4)] &&
                    (await getEcbDatabase()[_0x161b4b(0x34a)](_0xf63914));
                else {
                  let _0x70f30c = getArrayFromLocalStore(
                    "multiChartsRemovedNotSynced"
                  );
                  !_0x70f30c[_0x161b4b(0x1c4)](_0xf63914["id"]) &&
                    (await getEcbDatabase()[_0x161b4b(0x34a)](_0xf63914));
                }
              }
            }
          let _0x131bef = getArrayFromLocalStore(_0x161b4b(0x21a));
          for (let _0x86aac1 of _0x2aaada) {
            _0x1d68ff &&
              !_0x1d68ff[_0x161b4b(0x20c)](
                (_0x158408) => _0x158408["id"] === _0x86aac1["id"]
              ) &&
              !_0x131bef[_0x161b4b(0x1c4)](_0x86aac1["id"]) &&
              (await getEcbDatabase()["deleteMultiChart"](_0x86aac1["id"]));
          }
          (_0x2aaada = await getEcbDatabase()[_0x161b4b(0x228)](_0x27a754)),
            _0x2aaada[_0x161b4b(0x349)] === 0x0 &&
              (await getEcbDatabase()[_0x161b4b(0x34a)](_0x779793),
              _0x2aaada["push"](_0x779793)),
            _0x477177({ type: _0x161b4b(0x2cc), payload: _0x2aaada }),
            _0x1893da(_0x2aaada);
        } catch (_0x18c214) {
          let _0x4563e4 = await getEcbDatabase()["getMultiCharts"](_0x27a754);
          _0x4563e4[_0x161b4b(0x349)] === 0x0 &&
            (await getEcbDatabase()[_0x161b4b(0x34a)](_0x779793),
            _0x4563e4[_0x161b4b(0x266)](_0x779793)),
            _0x477177({ type: _0x161b4b(0x2cc), payload: _0x4563e4 }),
            _0x1893da(_0x4563e4);
        }
      },
      async () => {
        const _0x4841ca = _0x934dd7;
        let _0x2931ad = await getEcbDatabase()[_0x4841ca(0x228)](_0x27a754);
        _0x2931ad[_0x4841ca(0x349)] === 0x0 &&
          (await getEcbDatabase()["storeMultiChart"](_0x779793),
          _0x2931ad["push"](_0x779793));
        _0x477177({ type: _0x4841ca(0x2cc), payload: _0x2931ad }),
          _0x1893da(_0x2931ad);
        return;
      },
      { Authorization: _0x934dd7(0x1e6) + _0x5c7797 }
    );
  });
}
async function sendNewOptimization(_0x32ca77) {
  const _0x525208 = a0_0x1f43;
  if (_0x32ca77 === _0x525208(0x37e)) return;
  try {
    await autoSyncMutex[_0x525208(0x39d)]();
    const _0x268eed = "https://api2.easycryptobot.com/limits.php";
    await fetchWithRetry(
      _0x268eed,
      _0x525208(0x1fe),
      null,
      () => {},
      () => {},
      { Authorization: _0x525208(0x1e6) + _0x32ca77 }
    );
  } finally {
    autoSyncMutex["release"]();
  }
}
function getLimits(_0x70bf5f, _0x5319f1, _0x5c5253) {
  const _0x36fd54 = a0_0x1f43;
  if (_0x70bf5f === _0x36fd54(0x37e)) return;
  const _0xe45390 = _0x36fd54(0x1b3);
  return new Promise(async (_0x578d00) => {
    const _0x53aede = _0x36fd54;
    await fetchWithRetry(
      _0xe45390,
      _0x53aede(0x308),
      null,
      async (_0x4db12e) => {
        const _0x1fa8f1 = _0x53aede;
        let _0x9a6486 = { opt: 0x0 };
        _0x4db12e &&
          _0x4db12e[_0x1fa8f1(0x2c7)] &&
          ((_0x9a6486[_0x1fa8f1(0x2c7)] = _0x4db12e[_0x1fa8f1(0x2c7)]),
          (_0x9a6486[_0x1fa8f1(0x372)] = _0x4db12e["optDate"] * 0x3e8)),
          _0x5c5253({ type: _0x1fa8f1(0x1b7), payload: _0x9a6486 }),
          _0x578d00(_0x9a6486);
      },
      async () => {
        _0x578d00(_0x5319f1["limits"]);
        return;
      },
      { Authorization: "Bearer\x20" + _0x70bf5f }
    );
  });
}
async function sendErrors(_0x12025f, _0x16ebee) {
  const _0x820821 = a0_0x1f43;
  if (_0x12025f === "demo") return;
  try {
    await autoSyncMutex[_0x820821(0x39d)]();
    let _0x369c02 = await getEcbDatabase()["getErrors"](_0x16ebee);
    if (_0x369c02[_0x820821(0x349)] > 0x0) {
      const _0x5ecbc8 = _0x820821(0x1f2);
      _0x369c02[_0x820821(0x172)]((_0xb129e1) =>
        getEcbDatabase()["deleteError"](_0xb129e1["id"])
      ),
        (_0x369c02 = _0x369c02[_0x820821(0x226)](
          (_0x4df562) => _0x4df562[_0x820821(0x194)] !== _0x820821(0x303)
        )),
        await fetchWithRetry(
          _0x5ecbc8,
          _0x820821(0x1fe),
          _0x369c02,
          () => {},
          () => {
            const _0x1f0bfc = _0x820821;
            _0x369c02[_0x1f0bfc(0x172)]((_0x2b3b49) =>
              getEcbDatabase()[_0x1f0bfc(0x23a)](_0x2b3b49)
            );
          },
          { Authorization: _0x820821(0x1e6) + _0x12025f }
        );
    }
  } finally {
    autoSyncMutex["release"]();
  }
}
async function sendStrategiesNotSynced(_0xfc3e35, _0x4b15c1) {
  const _0x29bb92 = a0_0x1f43;
  if (_0xfc3e35 === _0x29bb92(0x37e)) return;
  try {
    await autoSyncMutex[_0x29bb92(0x39d)]();
    let _0x18af1d = getArrayFromLocalStore(_0x29bb92(0x17b));
    localStorage[_0x29bb92(0x19f)]("strategyNotSync");
    if (_0x18af1d[_0x29bb92(0x349)] === 0x0) return;
    let _0x1078a3 = await getEcbDatabase()["getStrategies"](_0x4b15c1);
    _0x1078a3 = _0x1078a3[_0x29bb92(0x226)]((_0x3b77b2) =>
      _0x18af1d[_0x29bb92(0x1c4)](_0x3b77b2["id"])
    );
    if (_0x1078a3[_0x29bb92(0x349)] > 0x0) {
      const _0xf76de0 = _0x29bb92(0x2e3);
      await fetchWithRetry(
        _0xf76de0,
        _0x29bb92(0x1fe),
        _0x1078a3[_0x29bb92(0x164)]((_0x437960) => ({
          id: _0x437960["id"],
          editDate: _0x437960[_0x29bb92(0x3a4)],
          strategy: _0x437960,
        })),
        (_0x2c42d9) => {
          const _0x16a61a = _0x29bb92;
          _0x2c42d9 &&
            _0x2c42d9[_0x16a61a(0x24c)] &&
            _0x2c42d9["failedIds"][_0x16a61a(0x349)] > 0x0 &&
            _0x2c42d9[_0x16a61a(0x24c)][_0x16a61a(0x172)]((_0x3ab896) =>
              addItemToArrayInLocalStore(_0x16a61a(0x17b), _0x3ab896)
            );
        },
        () => {
          const _0x120946 = _0x29bb92;
          _0x1078a3[_0x120946(0x172)]((_0x35faf7) =>
            addItemToArrayInLocalStore(_0x120946(0x17b), _0x35faf7["id"])
          );
        },
        { Authorization: "Bearer\x20" + _0xfc3e35 }
      );
    }
  } finally {
    autoSyncMutex[_0x29bb92(0x333)]();
  }
}
async function sendRemoveStrategiesNotSynced(_0x592a5c) {
  const _0x43d805 = a0_0x1f43;
  if (_0x592a5c === _0x43d805(0x37e)) return;
  const _0x932f3a = _0x43d805(0x2e3);
  try {
    await autoSyncMutex[_0x43d805(0x39d)]();
    let _0x1cdd55 = getArrayFromLocalStore("removedStrategiesIds");
    _0x1cdd55 &&
      _0x1cdd55[_0x43d805(0x349)] > 0x0 &&
      (localStorage[_0x43d805(0x19f)](_0x43d805(0x1dd)),
      await fetchWithRetry(
        _0x932f3a,
        _0x43d805(0x33c),
        _0x1cdd55,
        (_0x5c0e5b) => {
          const _0x2074af = _0x43d805;
          _0x5c0e5b[_0x2074af(0x24c)] &&
            _0x5c0e5b[_0x2074af(0x24c)][_0x2074af(0x172)]((_0x688d81) => {
              addItemToArrayInLocalStore("removedStrategiesIds", _0x688d81);
            });
        },
        () => {
          _0x1cdd55["forEach"]((_0xc1604) => {
            addItemToArrayInLocalStore("removedStrategiesIds", _0xc1604);
          });
        },
        { Authorization: _0x43d805(0x1e6) + _0x592a5c }
      ));
  } finally {
    autoSyncMutex["release"]();
  }
}
async function autoSync(_0x1037c0, _0x54a15d) {
  const _0x27d108 = a0_0x1f43;
  if (_0x1037c0 === _0x27d108(0x37e) || _0x54a15d[_0x27d108(0x1ae)]) return;
  autoSyncErrors(_0x1037c0, _0x54a15d);
  while (!![]) {
    await sleep(0x3e8 * 0x3c * 0xa);
    try {
      await sendStrategiesNotSynced(_0x1037c0, _0x54a15d["id"]),
        await sendRemoveStrategiesNotSynced(_0x1037c0),
        await sendStrategyStatusesNotSynced(
          _0x1037c0,
          _0x54a15d["id"],
          _0x27d108(0x26a)
        ),
        await sendStrategyStatusesNotSynced(
          _0x1037c0,
          _0x54a15d["id"],
          "trading"
        ),
        await sendRemoveStrategyStatusesNotSynced(_0x1037c0, _0x27d108(0x26a)),
        await sendRemoveStrategyStatusesNotSynced(_0x1037c0, _0x27d108(0x260)),
        await sendStrategyDetailsNotSynced(
          _0x1037c0,
          _0x54a15d["id"],
          _0x27d108(0x26a)
        ),
        await sendStrategyDetailsNotSynced(
          _0x1037c0,
          _0x54a15d["id"],
          _0x27d108(0x260)
        ),
        await sendRemoveStrategyDetailsNotSynced(_0x1037c0, "simulation"),
        await sendRemoveStrategyDetailsNotSynced(_0x1037c0, "trading"),
        await sendTradesNotSynked(_0x1037c0, "simulation"),
        await sendTradesNotSynked(_0x1037c0, _0x27d108(0x260)),
        await sendRenovedTradesNotSynked(_0x1037c0, _0x27d108(0x26a)),
        await sendRenovedTradesNotSynked(_0x1037c0, _0x27d108(0x260));
    } catch (_0x168b32) {
      console["log"](_0x27d108(0x229) + _0x168b32);
    }
  }
}
async function sendRemoteStrategyStop(
  _0x5bafe9,
  _0x3db7a3,
  _0x4ab1d3,
  _0x165c28
) {
  if (_0x5bafe9 === "demo") return;
  return new Promise(async (_0x188627) => {
    const _0x1175f3 = a0_0x1f43,
      _0x49e3a4 = _0x1175f3(0x1a9);
    await fetchWithRetry(
      _0x49e3a4,
      "post",
      {
        type: _0x4ab1d3 === _0x1175f3(0x26a) ? "simToStop" : _0x1175f3(0x3b5),
        id: _0x3db7a3,
      },
      async () => {
        const _0x57c23b = _0x1175f3;
        await sleep(0x3e8 * 0x3c);
        let _0x7a25eb = await getStrategyStatuses(
          _0x5bafe9,
          _0x4ab1d3,
          _0x3db7a3
        );
        _0x7a25eb &&
          _0x165c28({
            type:
              _0x4ab1d3 === _0x57c23b(0x26a) ? "simStatus" : _0x57c23b(0x204),
            payload: { status: _0x7a25eb },
          }),
          _0x188627(!![]);
      },
      async () => {
        const _0x33886a = _0x1175f3;
        let _0x258a46 = await getStrategyStatuses(
          _0x5bafe9,
          _0x4ab1d3,
          _0x3db7a3
        );
        _0x258a46 &&
          _0x165c28({
            type:
              _0x4ab1d3 === _0x33886a(0x26a) ? "simStatus" : _0x33886a(0x204),
            payload: { status: _0x258a46 },
          }),
          _0x188627(![]);
      },
      { Authorization: _0x1175f3(0x1e6) + _0x5bafe9 }
    );
  });
}
async function sendRemoteStrategySell(
  _0x5334e3,
  _0x18f844,
  _0xfd461e,
  _0xb5e37b
) {
  const _0x542dd4 = a0_0x1f43;
  if (_0x5334e3 === _0x542dd4(0x37e)) return;
  return new Promise(async (_0x5786a1) => {
    const _0x7c5426 = _0x542dd4,
      _0x5712f3 = _0x7c5426(0x1a9);
    await fetchWithRetry(
      _0x5712f3,
      _0x7c5426(0x1fe),
      {
        type: _0xfd461e === "simulation" ? _0x7c5426(0x31f) : _0x7c5426(0x24b),
        id: _0x18f844,
      },
      async () => {
        const _0xf278fd = _0x7c5426;
        await sleep(0x3e8 * 0x3c);
        let _0x5ebbc7 = await getStrategyStatuses(
          _0x5334e3,
          _0xfd461e,
          _0x18f844
        );
        _0x5ebbc7 &&
          _0xb5e37b({
            type:
              _0xfd461e === _0xf278fd(0x26a) ? _0xf278fd(0x2f6) : "liveStatus",
            payload: { status: _0x5ebbc7 },
          }),
          _0x5786a1(!![]);
      },
      async () => {
        const _0xe668a4 = _0x7c5426;
        let _0x115a60 = await getStrategyStatuses(
          _0x5334e3,
          _0xfd461e,
          _0x18f844
        );
        _0x115a60 &&
          _0xb5e37b({
            type:
              _0xfd461e === _0xe668a4(0x26a)
                ? _0xe668a4(0x2f6)
                : _0xe668a4(0x204),
            payload: { status: _0x115a60 },
          }),
          _0x5786a1(![]);
      },
      { Authorization: _0x7c5426(0x1e6) + _0x5334e3 }
    );
  });
}
async function sendAppId(_0x18e46a, _0x2997ce) {
  const _0x567e3f = a0_0x1f43;
  if (_0x18e46a === _0x567e3f(0x37e)) return;
  return new Promise(async (_0x1395a2) => {
    const _0x5ebafa = _0x567e3f,
      _0x595aa1 = _0x5ebafa(0x1a9);
    await fetchWithRetry(
      _0x595aa1,
      _0x5ebafa(0x1fe),
      { type: _0x5ebafa(0x214), id: _0x2997ce },
      () => _0x1395a2(!![]),
      () => {
        _0x1395a2(![]);
      },
      { Authorization: _0x5ebafa(0x1e6) + _0x18e46a }
    );
  });
}
async function sendAppClear(_0x2bdf9e) {
  const _0x5dab70 = a0_0x1f43;
  if (_0x2bdf9e === _0x5dab70(0x37e)) return;
  return new Promise(async (_0xa994f0) => {
    const _0x1281bf = _0x5dab70,
      _0x3c28b5 = _0x1281bf(0x1a9);
    await fetchWithRetry(
      _0x3c28b5,
      _0x1281bf(0x1fe),
      { type: "clear" },
      () => _0xa994f0(!![]),
      () => {
        _0xa994f0(![]);
      },
      { Authorization: _0x1281bf(0x1e6) + _0x2bdf9e }
    );
  });
}
async function getAppId(_0x2b061c) {
  const _0x5dd264 = a0_0x1f43;
  if (_0x2b061c === _0x5dd264(0x37e)) return;
  return new Promise(async (_0xc08c3d) => {
    const _0x299b70 = _0x5dd264,
      _0x2538d5 = _0x299b70(0x1a9);
    await fetchWithRetry(
      _0x2538d5,
      _0x299b70(0x308),
      null,
      (_0x49d395) => _0xc08c3d(_0x49d395),
      () => {
        _0xc08c3d(null);
      },
      { Authorization: _0x299b70(0x1e6) + _0x2b061c }
    );
  });
}
async function autoAppSync(_0x53221d, _0x827c5d, _0x5121f2) {
  const _0x4cb840 = a0_0x1f43;
  if (_0x53221d === _0x4cb840(0x37e)) return;
  let _0x58e776 = await sendAppId(_0x53221d, _0x5121f2);
  while (!![]) {
    try {
      await sleep(0x3e8 * 0x1e * 0x1);
      if (!_0x58e776) {
        _0x58e776 = await sendAppId(_0x53221d, _0x5121f2);
        continue;
      }
      let _0x2f3457 = await getAppId(_0x53221d);
      if (_0x2f3457) {
        if (_0x2f3457["appId"] !== _0x5121f2) {
          localStorage[_0x4cb840(0x2a3)](_0x4cb840(0x26b), 0x1),
            _0x827c5d({ type: "logout" });
          return;
        }
        let _0x2055f9 = ![];
        _0x2f3457["simToStop"] &&
          _0x2f3457["simToStop"][_0x4cb840(0x349)] > 0x0 &&
          ((_0x2055f9 = !![]),
          _0x2f3457[_0x4cb840(0x182)]["forEach"]((_0x130e7f) => {
            const _0x42f564 = _0x4cb840;
            _0x827c5d({
              type: _0x42f564(0x38f),
              payload: {
                id: _0x130e7f,
                stopType: "simulation",
                dispatch: _0x827c5d,
              },
            });
          })),
          _0x2f3457[_0x4cb840(0x3b5)] &&
            _0x2f3457[_0x4cb840(0x3b5)][_0x4cb840(0x349)] > 0x0 &&
            ((_0x2055f9 = !![]),
            _0x2f3457[_0x4cb840(0x282)][_0x4cb840(0x172)]((_0x323f81) => {
              const _0x2a798a = _0x4cb840;
              _0x827c5d({
                type: "remoteStrategyStop",
                payload: {
                  id: _0x323f81,
                  stopType: _0x2a798a(0x260),
                  dispatch: _0x827c5d,
                },
              });
            })),
          _0x2055f9 && sendAppClear(_0x53221d);
      }
    } catch (_0x5995f1) {
      console[_0x4cb840(0x281)](_0x4cb840(0x15f) + _0x5995f1);
    }
  }
}
async function autoSyncErrors(_0x5d5b20, _0x1d7729) {
  const _0x11cda1 = a0_0x1f43;
  if (_0x5d5b20 === _0x11cda1(0x37e)) return;
  while (!![]) {
    await sleep(0x3e8 * 0x3c * 0x3c);
    try {
      await sendErrors(_0x5d5b20, _0x1d7729["id"]);
    } catch (_0x34f74e) {
      console[_0x11cda1(0x281)](_0x11cda1(0x232) + _0x34f74e);
    }
  }
}
function getStrategyGroups(_0xcb8f5d, _0x467035, _0x20121c) {
  const _0x18d0be = a0_0x1f43;
  if (_0xcb8f5d === _0x18d0be(0x37e)) return Promise[_0x18d0be(0x1aa)]([]);
  const _0x56a212 = "https://api2.easycryptobot.com/strategy-groups.php";
  return new Promise(async (_0x51c528) => {
    const _0x7a2d9e = _0x18d0be;
    await fetchWithRetry(
      _0x56a212,
      "get",
      null,
      async (_0x132d3a) => {
        const _0x22e01 = a0_0x1f43;
        try {
          let _0x33155f = await getEcbDatabase()[_0x22e01(0x19d)](_0x20121c);
          if (_0x132d3a && _0x132d3a[_0x22e01(0x349)] > 0x0)
            for (let _0x4f6bb9 of _0x132d3a) {
              if (_0x4f6bb9) {
                let _0x3f5a38 = _0x33155f["find"](
                  (_0x4a3b44) => _0x4a3b44["id"] === _0x4f6bb9["id"]
                );
                if (_0x3f5a38)
                  _0x4f6bb9[_0x22e01(0x3a4)] > _0x3f5a38[_0x22e01(0x3a4)] &&
                    (await getEcbDatabase()[_0x22e01(0x30a)](_0x4f6bb9));
                else {
                  let _0x26f64e = getArrayFromLocalStore(_0x22e01(0x3ba));
                  !_0x26f64e[_0x22e01(0x1c4)](_0x4f6bb9["id"]) &&
                    (await getEcbDatabase()[_0x22e01(0x30a)](_0x4f6bb9));
                }
              }
            }
          let _0x36a034 = getArrayFromLocalStore("groupsNotSynced");
          for (let _0x262c03 of _0x33155f) {
            _0x132d3a &&
              !_0x132d3a[_0x22e01(0x20c)](
                (_0x4f1f12) => _0x4f1f12["id"] === _0x262c03["id"]
              ) &&
              !_0x36a034[_0x22e01(0x1c4)](_0x262c03["id"]) &&
              (await getEcbDatabase()[_0x22e01(0x21b)](_0x262c03["id"]));
          }
          (_0x33155f = await getEcbDatabase()["getStrategyGroups"](_0x20121c)),
            _0x467035({ type: "strategyGroups", payload: _0x33155f }),
            _0x51c528(_0x33155f);
        } catch (_0x8d0b1d) {
          let _0x18c8cd = await getEcbDatabase()[_0x22e01(0x19d)](_0x20121c);
          _0x467035({ type: _0x22e01(0x153), payload: _0x18c8cd }),
            _0x51c528(_0x18c8cd);
        }
      },
      async () => {
        const _0x976a4f = a0_0x1f43;
        let _0x3fb79a = await getEcbDatabase()[_0x976a4f(0x19d)](_0x20121c);
        _0x467035({ type: _0x976a4f(0x153), payload: _0x3fb79a }),
          _0x51c528(_0x3fb79a);
        return;
      },
      { Authorization: _0x7a2d9e(0x1e6) + _0xcb8f5d }
    );
  });
}
function saveStrategyGroup(_0x59f7b7, _0x287222, _0x265615) {
  const _0x446b33 = a0_0x1f43;
  getEcbDatabase()[_0x446b33(0x30a)](_0x59f7b7),
    sendStrategyGroup(_0x287222, _0x59f7b7),
    _0x265615({ type: _0x446b33(0x2c2), payload: _0x59f7b7 });
}
function deleteStrategyGroup(_0x3318d6, _0x40c71b, _0x24d7c5) {
  const _0x25a2e1 = a0_0x1f43;
  getEcbDatabase()["deleteStrategyGroup"](_0x3318d6),
    senddeleteOldStrategyGroup(_0x40c71b, _0x3318d6),
    _0x24d7c5({ type: _0x25a2e1(0x243), payload: _0x3318d6 });
}
async function sendStrategyGroup(_0x5a258d, _0x50ee2e) {
  const _0x6f1b03 = a0_0x1f43;
  if (_0x5a258d === _0x6f1b03(0x37e)) return;
  try {
    await autoSyncMutex[_0x6f1b03(0x39d)]();
    const _0x5b45c8 = _0x6f1b03(0x300);
    await removeItemFromArrayInLocalStore("groupsNotSynced", _0x50ee2e["id"]),
      await fetchWithRetry(
        _0x5b45c8,
        _0x6f1b03(0x1fe),
        [_0x50ee2e],
        () => {},
        () => {
          addItemToArrayInLocalStore("groupsNotSynced", _0x50ee2e["id"]);
        },
        { Authorization: _0x6f1b03(0x1e6) + _0x5a258d }
      );
  } finally {
    autoSyncMutex[_0x6f1b03(0x333)]();
  }
}
async function senddeleteOldStrategyGroup(_0x39f72d, _0x17a1cb) {
  const _0x1275ca = a0_0x1f43;
  if (_0x39f72d === _0x1275ca(0x37e)) return;
  try {
    await autoSyncMutex[_0x1275ca(0x39d)]();
    const _0x557b81 = _0x1275ca(0x300);
    await removeItemFromArrayInLocalStore("groupsNotSynced", _0x17a1cb),
      await removeItemFromArrayInLocalStore(_0x1275ca(0x3ba), _0x17a1cb),
      await fetchWithRetry(
        _0x557b81,
        _0x1275ca(0x33c),
        _0x17a1cb,
        () => {},
        () => {
          const _0x1eae27 = _0x1275ca;
          addItemToArrayInLocalStore(_0x1eae27(0x3ba), _0x17a1cb);
        },
        { Authorization: _0x1275ca(0x1e6) + _0x39f72d }
      );
  } finally {
    autoSyncMutex[_0x1275ca(0x333)]();
  }
}
async function sendStrategyGroupsNotSync(_0x2adfaf, _0xab207f) {
  const _0x591112 = a0_0x1f43;
  if (_0x2adfaf === _0x591112(0x37e)) return;
  try {
    await autoSyncMutex[_0x591112(0x39d)]();
    let _0x78ffa0 = getArrayFromLocalStore(_0x591112(0x20e));
    if (_0x78ffa0 && _0x78ffa0[_0x591112(0x349)] > 0x0) {
      let _0x1d7c94 = await getEcbDatabase()[_0x591112(0x185)](_0xab207f),
        _0x1dd797 = _0x1d7c94[_0x591112(0x226)]((_0x3e8522) =>
          _0x78ffa0["includes"](_0x3e8522["id"])
        );
      localStorage["removeItem"](_0x591112(0x20e));
      if (_0x1dd797["length"] > 0x0) {
        const _0x4582cc = _0x591112(0x300);
        await fetchWithRetry(
          _0x4582cc,
          "post",
          _0x1dd797,
          () => {},
          () => {
            const _0x24eb9a = _0x591112;
            _0x1dd797[_0x24eb9a(0x172)]((_0x356877) =>
              addItemToArrayInLocalStore(_0x24eb9a(0x20e), _0x356877["id"])
            );
          },
          { Authorization: _0x591112(0x1e6) + _0x2adfaf }
        );
      }
    }
  } finally {
    autoSyncMutex[_0x591112(0x333)]();
  }
}
async function sendStrategyGroupsRemovedNotSync(_0x41bc93) {
  const _0x4bf9a9 = a0_0x1f43;
  if (_0x41bc93 === _0x4bf9a9(0x37e)) return;
  try {
    await autoSyncMutex[_0x4bf9a9(0x39d)]();
    let _0x4528d3 = getArrayFromLocalStore(_0x4bf9a9(0x3ba));
    _0x4528d3 &&
      _0x4528d3["length"] > 0x0 &&
      (localStorage[_0x4bf9a9(0x19f)](_0x4bf9a9(0x3ba)),
      _0x4528d3[_0x4bf9a9(0x172)]((_0x129fcc) => {
        senddeleteOldStrategyGroup(_0x41bc93, _0x129fcc);
      }));
  } finally {
    autoSyncMutex["release"]();
  }
}
async function autoSyncStrategyGroups(_0x5b5fcf, _0x493736) {
  const _0xc80218 = a0_0x1f43;
  if (_0x5b5fcf === _0xc80218(0x37e)) return;
  while (!![]) {
    await sleep(0x3e8 * 0x3c * 0xa);
    try {
      await sendStrategyGroupsRemovedNotSync(_0x5b5fcf),
        await sendStrategyGroupsNotSync(_0x5b5fcf, _0x493736["id"]);
    } catch (_0x3ab94b) {
      console[_0xc80218(0x281)](_0xc80218(0x232) + _0x3ab94b);
    }
  }
}
function saveIndicators(_0x232920, _0x5091ff) {
  const _0x44389f = a0_0x1f43;
  if (_0x5091ff === "demo") return;
  getEcbDatabase()[_0x44389f(0x386)](_0x232920),
    sendIndicators(_0x5091ff, _0x232920);
}
async function sendIndicators(_0x1d7b3c, _0x1dbfaa) {
  const _0x11499f = a0_0x1f43;
  if (_0x1d7b3c === _0x11499f(0x37e)) return;
  try {
    await autoSyncMutex[_0x11499f(0x39d)]();
    const _0x36fce0 = _0x11499f(0x195);
    localStorage["removeItem"]("indicatorsNotSynced"),
      await fetchWithRetry(
        _0x36fce0,
        _0x11499f(0x1fe),
        _0x1dbfaa,
        () => {},
        () => {
          const _0xc8c5d0 = _0x11499f;
          localStorage["setItem"](_0xc8c5d0(0x253), _0x1dbfaa["id"]);
        },
        { Authorization: "Bearer\x20" + _0x1d7b3c }
      );
  } finally {
    autoSyncMutex[_0x11499f(0x333)]();
  }
}
async function sendIndicatorsNotSync(_0x1920d2, _0x178680) {
  const _0x1892ce = a0_0x1f43;
  if (_0x1920d2 === "demo") return;
  try {
    await autoSyncMutex[_0x1892ce(0x39d)]();
    let _0x19ac29 = localStorage["getItem"](_0x1892ce(0x253)),
      _0x24501e = await getEcbDatabase()["getIndicators"](_0x19ac29);
    localStorage[_0x1892ce(0x19f)]("indicatorsNotSynced"),
      _0x24501e && sendIndicators(_0x1920d2, _0x24501e);
  } finally {
    autoSyncMutex[_0x1892ce(0x333)]();
  }
}
function getIndicators(_0x168d56, _0x2a79d3) {
  const _0x3a3310 = a0_0x1f43;
  if (_0x2a79d3 === _0x3a3310(0x37e))
    return Promise[_0x3a3310(0x1aa)]({ params: [] });
  const _0x4f27aa = "https://api2.easycryptobot.com/indicators.php";
  return new Promise(async (_0x4eba3f) => {
    const _0x238ea4 = _0x3a3310;
    await fetchWithRetry(
      _0x4f27aa,
      _0x238ea4(0x308),
      null,
      async (_0xeb53a) => {
        const _0x1f79d0 = _0x238ea4;
        try {
          let _0x158ece = await getEcbDatabase()[_0x1f79d0(0x261)](_0x168d56);
          if (_0xeb53a && _0xeb53a[0x0]) {
            let _0x553185 = _0xeb53a[0x0];
            _0x158ece
              ? _0x553185[_0x1f79d0(0x3a4)] > _0x158ece[_0x1f79d0(0x3a4)] &&
                (await getEcbDatabase()[_0x1f79d0(0x386)](_0x553185))
              : await getEcbDatabase()[_0x1f79d0(0x386)](_0x553185);
          }
          (_0x158ece = await getEcbDatabase()[_0x1f79d0(0x261)](_0x168d56)),
            _0x4eba3f(_0x158ece);
        } catch (_0x495788) {
          let _0x41b18f = await getEcbDatabase()[_0x1f79d0(0x261)](_0x168d56);
          _0x4eba3f(_0x41b18f);
        }
      },
      async () => {
        const _0x776bc0 = _0x238ea4;
        let _0x2f6c9d = await getEcbDatabase()[_0x776bc0(0x261)](_0x168d56);
        _0x4eba3f(_0x2f6c9d);
        return;
      },
      { Authorization: "Bearer\x20" + _0x2a79d3 }
    );
  });
}
async function sendMultiChart(_0x6a295, _0x2bed50) {
  const _0x123d7c = a0_0x1f43;
  if (_0x6a295 === _0x123d7c(0x37e)) return;
  try {
    await autoSyncMutex[_0x123d7c(0x39d)]();
    const _0x445bc1 = _0x123d7c(0x295);
    await removeItemFromArrayInLocalStore(_0x123d7c(0x21a), _0x2bed50["id"]),
      await fetchWithRetry(
        _0x445bc1,
        _0x123d7c(0x1fe),
        [_0x2bed50],
        () => {},
        () => {
          addItemToArrayInLocalStore("multiChartsNotSynced", _0x2bed50["id"]);
        },
        { Authorization: "Bearer\x20" + _0x6a295 }
      );
  } finally {
    autoSyncMutex[_0x123d7c(0x333)]();
  }
}
function saveMultiChart(_0x1779f7, _0x51e1f5, _0x1de438) {
  const _0x25ce8b = a0_0x1f43;
  (_0x1779f7[_0x25ce8b(0x3a4)] = new Date()["getTime"]()),
    getEcbDatabase()[_0x25ce8b(0x34a)](_0x1779f7),
    sendMultiChart(_0x51e1f5, _0x1779f7),
    _0x1de438({ type: _0x25ce8b(0x28a), payload: { ..._0x1779f7 } });
}
function deleteMultiChart(_0x369e75, _0x473936, _0x20de3a) {
  const _0x3754a2 = a0_0x1f43;
  getEcbDatabase()[_0x3754a2(0x2a0)](_0x369e75),
    sendDeleteMultiChart(_0x473936, _0x369e75),
    _0x20de3a({ type: _0x3754a2(0x3bd), payload: _0x369e75 });
}
async function sendDeleteMultiChart(_0x4c3aa1, _0x162e87) {
  const _0xef3ca8 = a0_0x1f43;
  if (_0x4c3aa1 === _0xef3ca8(0x37e)) return;
  try {
    await autoSyncMutex[_0xef3ca8(0x39d)]();
    const _0x2faabd = "https://api2.easycryptobot.com/multicharts.php";
    await removeItemFromArrayInLocalStore(_0xef3ca8(0x21a), _0x162e87),
      await removeItemFromArrayInLocalStore(_0xef3ca8(0x18d), _0x162e87),
      await fetchWithRetry(
        _0x2faabd,
        "delete",
        _0x162e87,
        () => {},
        () => {
          addItemToArrayInLocalStore("multiChartsRemovedNotSynced", _0x162e87);
        },
        { Authorization: "Bearer\x20" + _0x4c3aa1 }
      );
  } finally {
    autoSyncMutex[_0xef3ca8(0x333)]();
  }
}
function saveWatchlist(_0x325e85, _0x21d1db, _0x39ec7a) {
  const _0xa8e4ab = a0_0x1f43;
  getEcbDatabase()["storeWatchlist"](_0x325e85),
    sendWatchlist(_0x21d1db, _0x325e85),
    _0x39ec7a({ type: _0xa8e4ab(0x336), payload: { ..._0x325e85 } });
}
function deleteWatchlist(_0x258bec, _0x180eb8, _0x2cd7e3) {
  const _0x56f16a = a0_0x1f43;
  getEcbDatabase()[_0x56f16a(0x1c1)](_0x258bec),
    sendDeleteWatchlist(_0x180eb8, _0x258bec),
    _0x2cd7e3({ type: _0x56f16a(0x2f4), payload: _0x258bec });
}
async function sendWatchlist(_0xc4c301, _0x1aaf06) {
  const _0x34b76b = a0_0x1f43;
  if (_0xc4c301 === _0x34b76b(0x37e)) return;
  try {
    await autoSyncMutex[_0x34b76b(0x39d)]();
    const _0x5dc09c = "https://api2.easycryptobot.com/watchlists.php";
    await removeItemFromArrayInLocalStore(_0x34b76b(0x223), _0x1aaf06["id"]),
      await fetchWithRetry(
        _0x5dc09c,
        "post",
        [_0x1aaf06],
        () => {},
        () => {
          const _0x25edef = _0x34b76b;
          addItemToArrayInLocalStore(_0x25edef(0x223), _0x1aaf06["id"]);
        },
        { Authorization: _0x34b76b(0x1e6) + _0xc4c301 }
      );
  } finally {
    autoSyncMutex[_0x34b76b(0x333)]();
  }
}
async function sendDeleteWatchlist(_0x539b0c, _0x1edaf3) {
  const _0x141d41 = a0_0x1f43;
  if (_0x539b0c === _0x141d41(0x37e)) return;
  try {
    await autoSyncMutex[_0x141d41(0x39d)]();
    const _0x12e048 = _0x141d41(0x384);
    await removeItemFromArrayInLocalStore(_0x141d41(0x223), _0x1edaf3),
      await removeItemFromArrayInLocalStore(_0x141d41(0x291), _0x1edaf3),
      await fetchWithRetry(
        _0x12e048,
        "delete",
        _0x1edaf3,
        () => {},
        () => {
          const _0x831f2f = _0x141d41;
          addItemToArrayInLocalStore(_0x831f2f(0x291), _0x1edaf3);
        },
        { Authorization: "Bearer\x20" + _0x539b0c }
      );
  } finally {
    autoSyncMutex["release"]();
  }
}
async function sendWatchlistsRemovedNotSync(_0x10ad48) {
  const _0x29e8cf = a0_0x1f43;
  if (_0x10ad48 === "demo") return;
  try {
    await autoSyncMutex[_0x29e8cf(0x39d)]();
    let _0x16d35d = getArrayFromLocalStore("watchlistsRemovedNotSynced");
    _0x16d35d &&
      _0x16d35d[_0x29e8cf(0x349)] > 0x0 &&
      (localStorage[_0x29e8cf(0x19f)](_0x29e8cf(0x291)),
      _0x16d35d["forEach"]((_0x28db2e) => {
        sendDeleteWatchlist(_0x10ad48, _0x28db2e);
      }));
  } finally {
    autoSyncMutex[_0x29e8cf(0x333)]();
  }
}
async function sendMiltiChartsChartsNotSync(_0x1eade6, _0x2041aa) {
  const _0x845a1 = a0_0x1f43;
  if (_0x1eade6 === "demo") return;
  try {
    await autoSyncMutex[_0x845a1(0x39d)]();
    let _0x54a125 = getArrayFromLocalStore(_0x845a1(0x21a));
    if (_0x54a125 && _0x54a125["length"] > 0x0) {
      let _0x19d652 = await getEcbDatabase()[_0x845a1(0x228)](_0x2041aa),
        _0x38c60a = _0x19d652["filter"]((_0x751dd7) =>
          _0x54a125[_0x845a1(0x1c4)](_0x751dd7["id"])
        );
      localStorage["removeItem"]("multiChartsNotSynced");
      if (_0x38c60a[_0x845a1(0x349)] > 0x0) {
        const _0x1ef419 = _0x845a1(0x295);
        await fetchWithRetry(
          _0x1ef419,
          _0x845a1(0x1fe),
          _0x38c60a,
          () => {},
          () => {
            const _0x3879ad = _0x845a1;
            _0x38c60a["forEach"]((_0x57bf48) =>
              addItemToArrayInLocalStore(_0x3879ad(0x21a), _0x57bf48["id"])
            );
          },
          { Authorization: _0x845a1(0x1e6) + _0x1eade6 }
        );
      }
    }
  } finally {
    autoSyncMutex["release"]();
  }
}
async function sendWatchlistsNotSync(_0x139f0f, _0x38d406) {
  const _0x2eb219 = a0_0x1f43;
  if (_0x139f0f === _0x2eb219(0x37e)) return;
  try {
    await autoSyncMutex["lock"]();
    let _0x43e7e0 = getArrayFromLocalStore(_0x2eb219(0x223));
    if (_0x43e7e0 && _0x43e7e0["length"] > 0x0) {
      let _0x50cfea = await getEcbDatabase()[_0x2eb219(0x185)](_0x38d406),
        _0x211fab = _0x50cfea["filter"]((_0x2de19c) =>
          _0x43e7e0[_0x2eb219(0x1c4)](_0x2de19c["id"])
        );
      localStorage[_0x2eb219(0x19f)](_0x2eb219(0x223));
      if (_0x211fab["length"] > 0x0) {
        const _0x43c5cf = _0x2eb219(0x384);
        await fetchWithRetry(
          _0x43c5cf,
          _0x2eb219(0x1fe),
          _0x211fab,
          () => {},
          () => {
            const _0x379a16 = _0x2eb219;
            _0x211fab[_0x379a16(0x172)]((_0x3b382a) =>
              addItemToArrayInLocalStore("watchlistsNotSynced", _0x3b382a["id"])
            );
          },
          { Authorization: _0x2eb219(0x1e6) + _0x139f0f }
        );
      }
    }
  } finally {
    autoSyncMutex["release"]();
  }
}
async function autoSyncHomePage(_0xa129f1, _0x2230ae) {
  const _0x530412 = a0_0x1f43;
  if (_0xa129f1 === _0x530412(0x37e)) return;
  while (!![]) {
    await sleep(0x3e8 * 0x3c * 0xa);
    try {
      await sendWatchlistsRemovedNotSync(_0xa129f1),
        await sendWatchlistsNotSync(_0xa129f1, _0x2230ae["id"]),
        await sendMiltiChartsChartsNotSync(_0xa129f1, _0x2230ae["id"]),
        await sendIndicatorsNotSync(_0xa129f1, _0x2230ae["id"]);
    } catch (_0x25d90c) {
      console[_0x530412(0x281)](_0x530412(0x232) + _0x25d90c);
    }
  }
}
async function updateExecutionStatus(
  _0x1a64ca,
  _0x478900,
  _0x56eacf,
  _0x4a3f22,
  _0x45c762,
  _0x2c40e4,
  _0x153eca
) {
  const _0x581f59 = a0_0x1f43;
  let _0x47af31 = [],
    _0x3bbe80 = [],
    _0x5100e9 = [];
  if (_0x153eca) {
    await getStrategiesTraficSaver(_0x1a64ca, _0x56eacf);
    return;
  } else
    (_0x47af31 = await getStrategies(
      _0x1a64ca,
      _0x478900,
      _0x45c762,
      _0x4a3f22
    )),
      (_0x3bbe80 = await getStrategiesStatuses(
        _0x1a64ca,
        _0x478900,
        _0x581f59(0x26a),
        _0x4a3f22,
        _0x45c762
      )),
      (_0x5100e9 = await getStrategiesStatuses(
        _0x1a64ca,
        _0x478900,
        "trading",
        _0x4a3f22,
        _0x45c762
      ));
  let _0x5e436e = _0x3bbe80[_0x581f59(0x25e)]((_0xee3d63, _0x1281dd) => {
      const _0x433394 = _0x581f59;
      return (
        _0x4a3f22 &&
          !_0x45c762 &&
          (_0x1281dd[_0x433394(0x2e9)] === _0x433394(0x236) &&
            (_0x1281dd[_0x433394(0x2e9)] = _0x433394(0x2c3)),
          delete _0x1281dd[_0x433394(0x2ab)],
          delete _0x1281dd["manualSellAt"]),
        (_0xee3d63[_0x1281dd["id"]] = _0x1281dd),
        _0xee3d63
      );
    }, {}),
    _0x103f50 = _0x5100e9["reduce"]((_0x2b8268, _0x326b3b) => {
      const _0x30967a = _0x581f59;
      return (
        _0x4a3f22 &&
          !_0x45c762 &&
          (_0x326b3b["status"] === "Loading" &&
            (_0x326b3b[_0x30967a(0x2e9)] = "Stopped"),
          delete _0x326b3b[_0x30967a(0x2ab)],
          delete _0x326b3b[_0x30967a(0x309)]),
        (_0x2b8268[_0x326b3b["id"]] = _0x326b3b),
        _0x2b8268
      );
    }, {});
  await fixStatusVersion(_0x47af31, _0x5e436e, _0x103f50, [], []),
    _0x56eacf({
      type: _0x581f59(0x23f),
      payload: {
        strategies: _0x47af31,
        simStatuses: _0x5e436e,
        liveStatuses: _0x103f50,
      },
    }),
    _0x56eacf({ type: _0x581f59(0x175), payload: _0x5e436e }),
    _0x56eacf({ type: "liveStatuses", payload: _0x103f50 }),
    _0x2c40e4 &&
      _0x2c40e4(
        _0x47af31[_0x581f59(0x349)],
        _0x3bbe80[_0x581f59(0x349)],
        _0x5100e9[_0x581f59(0x349)]
      );
}
async function autoUpdateExecutionStatus(
  _0x5444b0,
  _0x1fa09f,
  _0x215b7b,
  _0x2e9c6f
) {
  const _0x55bbb9 = a0_0x1f43;
  if (_0x2e9c6f) return;
  while (!![]) {
    try {
      await updateExecutionStatus(_0x5444b0, _0x1fa09f, _0x215b7b, ![]);
    } catch (_0x30ad9b) {
      console[_0x55bbb9(0x281)](_0x55bbb9(0x2d1) + _0x30ad9b);
    }
    break;
    await sleep(0x3e8 * 0x3c * 0xa);
  }
}
const autoSyncMutex = new Mutex();
async function sendStrategy(_0x2a5f1f, _0x26606e) {
  const _0x52e8c7 = a0_0x1f43;
  if (_0x2a5f1f === "demo") return;
  try {
    await fetchWithRetry(
      "https://api2.easycryptobot.com/strategy.php",
      _0x52e8c7(0x1fe),
      [
        {
          id: _0x26606e["id"],
          editDate: _0x26606e[_0x52e8c7(0x3a4)],
          strategy: _0x26606e,
        },
      ],
      (_0x5e9d5a) => {
        const _0xc0a9a9 = _0x52e8c7;
        _0x5e9d5a &&
          _0x5e9d5a[_0xc0a9a9(0x24c)] &&
          _0x5e9d5a[_0xc0a9a9(0x24c)][_0xc0a9a9(0x349)] > 0x0 &&
          addItemToArrayInLocalStore(_0xc0a9a9(0x17b), _0x26606e["id"]);
      },
      () => {
        const _0xbca48d = _0x52e8c7;
        addItemToArrayInLocalStore(_0xbca48d(0x17b), _0x26606e["id"]);
      },
      { Authorization: _0x52e8c7(0x1e6) + _0x2a5f1f }
    );
  } finally {
  }
}
async function sendTrade(_0x3a5e5b, _0x41e8fc, _0x43f026) {
  return sendTrades(_0x3a5e5b, _0x41e8fc, [_0x43f026]);
}
async function sendTrades(_0x39d552, _0x4002e6, _0x37045c) {
  const _0x4495d9 = a0_0x1f43;
  if (_0x39d552 === _0x4495d9(0x37e) || _0x37045c["length"] === 0x0) return;
  let _0x82a21f = {
      type: _0x4002e6 === _0x4495d9(0x26a) ? "sim" : _0x4495d9(0x184),
      trades: _0x37045c[_0x4495d9(0x164)]((_0x11276e) => ({
        id: _0x11276e["id"],
        editDate: _0x11276e["editDate"],
        sId: _0x11276e[_0x4495d9(0x3a2)],
        trade: _0x11276e,
      })),
    },
    _0x42851f =
      _0x4002e6 === _0x4495d9(0x26a) ? _0x4495d9(0x2c6) : _0x4495d9(0x1a5);
  return fetchWithRetry(
    "https://api2.easycryptobot.com/strategy-trades.php",
    _0x4495d9(0x1fe),
    _0x82a21f,
    (_0x4a779e) => {
      const _0x5b34d7 = _0x4495d9;
      _0x4a779e &&
        _0x4a779e[_0x5b34d7(0x24c)] &&
        _0x4a779e[_0x5b34d7(0x24c)][_0x5b34d7(0x172)]((_0x4f471a) =>
          addItemToArrayInLocalStore(_0x42851f, _0x4f471a)
        );
    },
    (_0x221710) => {
      const _0x267369 = _0x4495d9;
      _0x37045c[_0x267369(0x172)]((_0x584c36) =>
        addItemToArrayInLocalStore(_0x42851f, _0x584c36["id"])
      );
    },
    { Authorization: _0x4495d9(0x1e6) + _0x39d552 }
  );
}
async function sendTradesNotSynked(_0x568c19, _0x21a686) {
  const _0x46a5e3 = a0_0x1f43;
  if (_0x568c19 === _0x46a5e3(0x37e)) return;
  let _0x48bf7f =
    _0x21a686 === _0x46a5e3(0x26a) ? _0x46a5e3(0x2c6) : _0x46a5e3(0x1a5);
  try {
    await autoSyncMutex["lock"]();
    let _0x27aa85 = getArrayFromLocalStore(_0x48bf7f);
    localStorage[_0x46a5e3(0x19f)](_0x48bf7f);
    if (_0x27aa85[_0x46a5e3(0x349)] === 0x0) return;
    let _0x47a93d = [];
    for (let _0x24db71 of _0x27aa85) {
      let _0x3fadad = null;
      _0x21a686 === "simulation"
        ? (_0x3fadad = await getEcbDatabase()[_0x46a5e3(0x23e)](_0x24db71))
        : (_0x3fadad = await getEcbDatabase()[_0x46a5e3(0x20d)](_0x24db71)),
        _0x3fadad && _0x47a93d[_0x46a5e3(0x266)](_0x3fadad);
    }
    await sendTrades(_0x568c19, _0x21a686, _0x47a93d);
  } finally {
    autoSyncMutex[_0x46a5e3(0x333)]();
  }
}
async function sendRenovedTradesNotSynked(_0x4d96ee, _0x5370a5) {
  const _0xbc2200 = a0_0x1f43;
  if (_0x4d96ee === "demo") return;
  let _0x5c4a9e =
    _0x5370a5 === "simulation" ? _0xbc2200(0x22a) : "removedTradesLiveIds";
  try {
    await autoSyncMutex["lock"]();
    let _0x1e5e87 = getArrayFromLocalStore(_0x5c4a9e);
    if (_0x1e5e87[_0xbc2200(0x349)] > 0x0) {
      localStorage[_0xbc2200(0x19f)](_0x5c4a9e);
      let _0x37b2f5 = {
        type: _0x5370a5 === "simulation" ? "sim" : "live",
        ids: _0x1e5e87,
      };
      fetchWithRetry(
        _0xbc2200(0x21c),
        _0xbc2200(0x33c),
        _0x37b2f5,
        (_0x266559) => {
          const _0x49fdff = _0xbc2200;
          _0x266559[_0x49fdff(0x24c)] &&
            _0x266559["failedIds"][_0x49fdff(0x172)]((_0x369f16) => {
              addItemToArrayInLocalStore(_0x5c4a9e, _0x369f16);
            });
        },
        () => {
          const _0x4bce43 = _0xbc2200;
          _0x1e5e87[_0x4bce43(0x172)]((_0x5c232e) => {
            addItemToArrayInLocalStore(_0x5c4a9e, _0x5c232e);
          });
        },
        { Authorization: _0xbc2200(0x1e6) + _0x4d96ee }
      );
    }
  } finally {
    autoSyncMutex["release"]();
  }
}
async function sendEvent(_0x393f94, _0x373320, _0x1f55fc) {
  return sendEvents(_0x393f94, _0x373320, [_0x1f55fc]);
}
async function sendEvents(_0x4a6891, _0x20f59c, _0x134426) {
  const _0x94c59a = a0_0x1f43;
  if (_0x4a6891 === _0x94c59a(0x37e)) return;
  let _0x259b8 = {
    type: _0x20f59c === "simulation" ? "sim" : "live",
    events: _0x134426["map"]((_0x9590b7) => ({
      id: _0x9590b7["id"],
      sId: _0x9590b7["sId"],
      event: _0x9590b7,
    })),
  };
  return fetchWithRetry(
    _0x94c59a(0x19a),
    _0x94c59a(0x1fe),
    _0x259b8,
    null,
    null,
    { Authorization: _0x94c59a(0x1e6) + _0x4a6891 }
  );
}
function showInfoDialog(_0x325058, _0x2ba37d, _0x104117, _0x4e9ebb, _0x38ea2d) {
  const _0x1cd182 = a0_0x1f43;
  _0x325058({
    type: _0x1cd182(0x1cd),
    payload: {
      header: _0x2ba37d,
      content: _0x104117,
      large: _0x4e9ebb,
      dark: _0x38ea2d,
    },
  }),
    !$(_0x1cd182(0x3c7))["is"](_0x1cd182(0x17d)) &&
      $(_0x1cd182(0x3c7))[_0x1cd182(0x3b4)](_0x1cd182(0x3ac));
}
function showConfirmDialog(
  _0x14fd79,
  _0x4cf99f,
  _0x3f329e,
  _0x2f5c12,
  _0x272918,
  _0x390790
) {
  const _0x87d708 = a0_0x1f43;
  _0x14fd79({
    type: _0x87d708(0x2fc),
    payload: {
      header: _0x4cf99f,
      content: _0x3f329e,
      func: _0x2f5c12,
      large: _0x272918,
      dark: _0x390790,
    },
  }),
    !$("#confirmDialog")["is"](_0x87d708(0x17d)) &&
      $(_0x87d708(0x2a4))["modal"]("toggle");
}
function getTimestamp() {
  const _0x2f1772 = a0_0x1f43;
  return (
    !Date[_0x2f1772(0x26e)] &&
      (Date[_0x2f1772(0x26e)] = function () {
        const _0x1b4c44 = _0x2f1772;
        return new Date()[_0x1b4c44(0x168)]();
      }),
    Date["now"]()
  );
}
function addZero(_0x1ddab1) {
  return _0x1ddab1 < 0xa ? "0" + _0x1ddab1 : _0x1ddab1;
}
function showTime(_0x39fb88) {
  const _0x18a756 = a0_0x1f43;
  if (!_0x39fb88 || _0x39fb88 === 0x0) return _0x18a756(0x2ff);
  let _0x56199f = Math[_0x18a756(0x211)](
    _0x39fb88 / 0x3e8 / 0x3c / 0x3c / 0x18
  );
  _0x39fb88 -= _0x56199f * 0x3e8 * 0x3c * 0x3c * 0x18;
  let _0x5e8cab = Math[_0x18a756(0x211)](_0x39fb88 / 0x3e8 / 0x3c / 0x3c);
  _0x39fb88 -= _0x5e8cab * 0x3e8 * 0x3c * 0x3c;
  let _0x2fc860 = Math["round"](_0x39fb88 / 0x3e8 / 0x3c);
  if (!_0x56199f && !_0x5e8cab && !_0x2fc860) return _0x18a756(0x2ff);
  return (
    (_0x56199f !== 0x0 ? _0x56199f + "d\x20" : "") +
    (_0x5e8cab !== 0x0 ? _0x5e8cab + "h\x20" : "") +
    _0x2fc860 +
    "m"
  );
}
function getDatesDiff(_0xfa9865, _0x4774dd) {
  const _0x4798e3 = a0_0x1f43;
  if (!_0xfa9865 || !_0x4774dd) return "";
  let _0x1c7cdf = Math[_0x4798e3(0x1a3)](_0x4774dd - _0xfa9865);
  return showTime(_0x1c7cdf);
}
function formatDateTime(_0x4f9319) {
  const _0x3d0af4 = a0_0x1f43;
  let _0x5236cd = null;
  typeof _0x4f9319 === _0x3d0af4(0x2d2) || typeof _0x4f9319 === _0x3d0af4(0x1fa)
    ? (_0x5236cd = new Date(_0x4f9319))
    : (_0x5236cd = _0x4f9319);
  if (!(_0x5236cd instanceof Date) || isNaN(_0x5236cd[_0x3d0af4(0x168)]()))
    return "";
  let _0x23f1c3 =
    _0x5236cd[_0x3d0af4(0x365)]() +
    "-" +
    addZero(_0x5236cd[_0x3d0af4(0x28b)]() + 0x1) +
    "-" +
    addZero(_0x5236cd[_0x3d0af4(0x22f)]()) +
    "\x20" +
    addZero(_0x5236cd[_0x3d0af4(0x36f)]()) +
    ":" +
    addZero(_0x5236cd[_0x3d0af4(0x3b8)]());
  return _0x23f1c3;
}
function formatDateTime2(_0x4e411e) {
  const _0xa3ba9d = a0_0x1f43;
  let _0x272837 = null;
  typeof _0x4e411e === _0xa3ba9d(0x2d2) || typeof _0x4e411e === "number"
    ? (_0x272837 = new Date(_0x4e411e))
    : (_0x272837 = _0x4e411e);
  if (!(_0x272837 instanceof Date) || isNaN(_0x272837[_0xa3ba9d(0x168)]()))
    return "";
  return (
    _0x272837["getFullYear"]() +
    "-" +
    addZero(_0x272837["getMonth"]() + 0x1) +
    "-" +
    addZero(_0x272837[_0xa3ba9d(0x22f)]()) +
    "T" +
    addZero(_0x272837[_0xa3ba9d(0x36f)]()) +
    ":" +
    addZero(_0x272837[_0xa3ba9d(0x3b8)]())
  );
}
function formatDateTime3(_0x53af92) {
  const _0x5e02e5 = a0_0x1f43;
  let _0x3859c1 = null;
  typeof _0x53af92 === "string" || typeof _0x53af92 === _0x5e02e5(0x1fa)
    ? (_0x3859c1 = new Date(_0x53af92))
    : (_0x3859c1 = _0x53af92);
  if (!(_0x3859c1 instanceof Date) || isNaN(_0x3859c1[_0x5e02e5(0x168)]()))
    return "";
  return (
    addZero(_0x3859c1[_0x5e02e5(0x22f)]()) +
    "-" +
    months[_0x3859c1["getMonth"]()] +
    "-" +
    _0x3859c1[_0x5e02e5(0x365)]() +
    "\x20" +
    addZero(_0x3859c1["getHours"]()) +
    ":" +
    addZero(_0x3859c1[_0x5e02e5(0x3b8)]())
  );
}
function formatDateTime4(_0x5aab83) {
  const _0x2b2d7e = a0_0x1f43;
  let _0x3ebf56 = null;
  typeof _0x5aab83 === _0x2b2d7e(0x2d2) || typeof _0x5aab83 === _0x2b2d7e(0x1fa)
    ? (_0x3ebf56 = new Date(_0x5aab83))
    : (_0x3ebf56 = _0x5aab83);
  if (!(_0x3ebf56 instanceof Date) || isNaN(_0x3ebf56["getTime"]())) return "";
  return (
    _0x3ebf56[_0x2b2d7e(0x365)]() +
    "-" +
    addZero(_0x3ebf56[_0x2b2d7e(0x28b)]() + 0x1) +
    "-" +
    addZero(_0x3ebf56[_0x2b2d7e(0x22f)]()) +
    "T" +
    addZero(_0x3ebf56[_0x2b2d7e(0x36f)]()) +
    ":" +
    addZero(_0x3ebf56["getMinutes"]()) +
    ":" +
    addZero(_0x3ebf56["getSeconds"]())
  );
}
function formatDateTime5(_0x99f149) {
  const _0xcf8638 = a0_0x1f43;
  let _0xf950e8 = null;
  typeof _0x99f149 === _0xcf8638(0x2d2) || typeof _0x99f149 === "number"
    ? (_0xf950e8 = new Date(_0x99f149))
    : (_0xf950e8 = _0x99f149);
  if (!(_0xf950e8 instanceof Date) || isNaN(_0xf950e8[_0xcf8638(0x168)]()))
    return "";
  let _0x3d7f02 =
    _0xf950e8[_0xcf8638(0x365)]() +
    "-" +
    addZero(_0xf950e8["getMonth"]() + 0x1) +
    "-" +
    addZero(_0xf950e8[_0xcf8638(0x22f)]()) +
    "\x20" +
    addZero(_0xf950e8[_0xcf8638(0x36f)]()) +
    ":" +
    addZero(_0xf950e8[_0xcf8638(0x3b8)]()) +
    ":" +
    addZero(_0xf950e8[_0xcf8638(0x3a0)]());
  return _0x3d7f02;
}
function formatDate(_0x10bc42) {
  const _0x37d33b = a0_0x1f43;
  if (!(_0x10bc42 instanceof Date) || isNaN(_0x10bc42[_0x37d33b(0x168)]()))
    return "";
  let _0x4b2271 =
    _0x10bc42[_0x37d33b(0x365)]() +
    "-" +
    addZero(_0x10bc42[_0x37d33b(0x28b)]() + 0x1) +
    "-" +
    addZero(_0x10bc42[_0x37d33b(0x22f)]());
  return _0x4b2271;
}
const months = [
  a0_0xd18718(0x20b),
  a0_0xd18718(0x380),
  "Mar",
  a0_0xd18718(0x358),
  a0_0xd18718(0x1e0),
  a0_0xd18718(0x1de),
  "Jul",
  a0_0xd18718(0x359),
  a0_0xd18718(0x2c5),
  "Oct",
  a0_0xd18718(0x1a1),
  a0_0xd18718(0x1d1),
];
function formatDate2(_0x208fc1) {
  const _0x28573c = a0_0xd18718;
  if (!(_0x208fc1 instanceof Date) || isNaN(_0x208fc1[_0x28573c(0x168)]()))
    return "";
  let _0x546107 =
    addZero(_0x208fc1["getDate"]()) +
    "-" +
    months[_0x208fc1[_0x28573c(0x28b)]()] +
    "-" +
    _0x208fc1["getFullYear"]();
  return _0x546107;
}
function sleep(_0x3d41b0) {
  return new Promise((_0x2709b4) => setTimeout(_0x2709b4, _0x3d41b0));
}
function getStartDate(_0x3d4a6f, _0x3bc752) {
  const _0x591d73 = a0_0xd18718;
  let _0xb736fb = new Date(_0x3bc752[_0x591d73(0x168)]());
  switch (_0x3d4a6f) {
    case "1\x20minute":
      _0xb736fb[_0x591d73(0x1c3)](_0xb736fb[_0x591d73(0x36f)]() - 0x2);
      break;
    case "5\x20minutes":
      _0xb736fb[_0x591d73(0x1c3)](_0xb736fb[_0x591d73(0x36f)]() - 0x9);
      break;
    case _0x591d73(0x2ca):
      _0xb736fb["setDate"](_0xb736fb[_0x591d73(0x22f)]() - 0x2);
      break;
    case _0x591d73(0x21d):
      _0xb736fb[_0x591d73(0x320)](_0xb736fb["getDate"]() - 0x3);
      break;
    case _0x591d73(0x378):
      _0xb736fb[_0x591d73(0x320)](_0xb736fb[_0x591d73(0x22f)]() - 0x4);
      break;
    case "4\x20hours":
      _0xb736fb["setDate"](_0xb736fb[_0x591d73(0x22f)]() - 0x11);
      break;
    case _0x591d73(0x2f8):
      _0xb736fb["setDate"](_0xb736fb[_0x591d73(0x22f)]() - 0x32);
      break;
    case _0x591d73(0x233):
      _0xb736fb[_0x591d73(0x320)](_0xb736fb[_0x591d73(0x22f)]() - 0x64);
      break;
    case _0x591d73(0x25d):
      _0xb736fb[_0x591d73(0x320)](_0xb736fb[_0x591d73(0x22f)]() - 0x2bc);
      break;
    case _0x591d73(0x274):
      _0xb736fb[_0x591d73(0x320)](_0xb736fb[_0x591d73(0x22f)]() - 0xbb8);
      break;
    default:
  }
  return _0xb736fb;
}
function fixNumber(_0x4f208c, _0x187198 = 0x8) {
  const _0x57c38f = a0_0xd18718;
  return Number[_0x57c38f(0x288)](_0x4f208c[_0x57c38f(0x23b)](_0x187198));
}
async function rr(_0x716f29, _0xb4e2da, _0x56a82f) {
  const _0x4fb9bc = a0_0xd18718;
  let _0x2496bd = {};
  try {
    let _0x2be560 = await getEcbDatabase()[_0x4fb9bc(0x2aa)](_0xb4e2da),
      _0x592d9e = a(0x3, 0x4, 0x20),
      _0xc06eeb = a(0xf, 0x10, 0xa),
      _0x4d55e9 = a(add3[_0x4fb9bc(0x349)] - 0x1, 0x10, 0xa),
      _0x59527b = "" + _0x592d9e + _0xc06eeb + _0x4d55e9 + "-" + _0xb4e2da;
    if (_0x2be560 && _0x2be560[_0x4fb9bc(0x349)] > 0x0)
      for (let _0x1c4e74 of _0x2be560) {
        try {
          let _0x4cdd77 = decrypt(
              _0x1c4e74[_0x4fb9bc(0x360)],
              "" + _0x592d9e + _0xc06eeb + _0x4d55e9 + "-" + _0xb4e2da
            ),
            _0xfde2aa = decrypt(_0x1c4e74["secret"], _0x59527b);
          if (!_0x4cdd77 || !_0xfde2aa) continue;
          let [_0x27911b, _0x5cd3b5] = await getExchange(
            _0x1c4e74[_0x4fb9bc(0x2d6)]
          )[_0x4fb9bc(0x374)](_0x4cdd77, _0xfde2aa);
          _0x27911b &&
            (getExchange(_0x1c4e74["exchange"])[_0x4fb9bc(0x198)](
              _0x1c4e74["exchange"],
              (_0x506196) => {
                const _0x2d0fc8 = _0x4fb9bc;
                _0x716f29({
                  type: _0x2d0fc8(0x2d5),
                  payload: {
                    id: _0x1c4e74[_0x2d0fc8(0x2d6)],
                    assets: _0x506196,
                  },
                });
              }
            ),
            _0x716f29({
              type: _0x4fb9bc(0x2d5),
              payload: { id: _0x1c4e74[_0x4fb9bc(0x2d6)], assets: _0x27911b },
            })),
            (_0x2496bd[_0x1c4e74[_0x4fb9bc(0x2d6)]] = {
              key: _0x4cdd77,
              secret: _0xfde2aa,
              valid: _0x27911b != null,
            });
        } catch (_0x2c346a) {
          continue;
        }
      }
    else {
      _0x2be560 = await getEcbDatabase()[_0x4fb9bc(0x2aa)](_0x56a82f);
      if (_0x2be560 && _0x2be560[_0x4fb9bc(0x349)] > 0x0)
        for (let _0x2c4fa2 of _0x2be560) {
          try {
            let _0x246db3 = decrypt(_0x2c4fa2["key"], _0x56a82f),
              _0x50491c = decrypt(_0x2c4fa2[_0x4fb9bc(0x18a)], _0x56a82f);
            if (!_0x246db3 || !_0x50491c) continue;
            let [_0x32563a, _0x5f30f4] = await getExchange(
              _0x2c4fa2[_0x4fb9bc(0x2d6)]
            )[_0x4fb9bc(0x374)](_0x246db3, _0x50491c);
            _0x32563a &&
              (getExchange(_0x2c4fa2["exchange"])["subscribeToBalanceUpdate"](
                _0x2c4fa2[_0x4fb9bc(0x2d6)],
                (_0x56ded6) => {
                  const _0x485358 = _0x4fb9bc;
                  _0x716f29({
                    type: _0x485358(0x2d5),
                    payload: {
                      id: _0x2c4fa2[_0x485358(0x2d6)],
                      assets: _0x56ded6,
                    },
                  });
                }
              ),
              _0x716f29({
                type: "assets",
                payload: { id: _0x2c4fa2["exchange"], assets: _0x32563a },
              })),
              (_0x2496bd[_0x2c4fa2[_0x4fb9bc(0x2d6)]] = {
                key: _0x246db3,
                secret: _0x50491c,
                valid: _0x32563a != null,
              }),
              deleteApiKey(_0x2c4fa2[_0x4fb9bc(0x2d6)], _0xb4e2da, _0x56a82f),
              ss({
                user: _0xb4e2da,
                exchange: _0x2c4fa2[_0x4fb9bc(0x2d6)],
                key: _0x246db3,
                secret: _0x50491c,
              });
          } catch (_0xf41988) {
            continue;
          }
        }
    }
    _0x716f29({ type: "exchanges", payload: _0x2496bd });
  } catch (_0x480d0a) {
    _0x716f29({ type: _0x4fb9bc(0x2e6), payload: {} }),
      console["log"](_0x4fb9bc(0x245) + _0x480d0a);
  }
  return _0x2496bd;
}
async function rrOld(_0x46e6d1, _0x48fb24, _0x282458) {
  const _0x309e43 = a0_0xd18718;
  let _0xca7458 = {};
  try {
    let _0x11cad4 = await getEcbDatabase()[_0x309e43(0x2aa)](_0x48fb24);
    if (_0x11cad4 && _0x11cad4[_0x309e43(0x349)] > 0x0)
      for (let _0x27be4e of _0x11cad4) {
        let _0x15a449 = decrypt(
            _0x27be4e[_0x309e43(0x360)],
            "ECB512-" + _0x48fb24
          ),
          _0x2b946 = decrypt(
            _0x27be4e[_0x309e43(0x18a)],
            _0x309e43(0x357) + _0x48fb24
          ),
          [_0x4e72f3, _0x33c624] = await getExchange(
            _0x27be4e[_0x309e43(0x2d6)]
          )[_0x309e43(0x374)](_0x15a449, _0x2b946);
        _0x4e72f3 &&
          (getExchange(_0x27be4e["exchange"])[_0x309e43(0x198)](
            _0x27be4e[_0x309e43(0x2d6)],
            (_0x2d1e00) => {
              const _0x2dd8b7 = _0x309e43;
              _0x46e6d1({
                type: _0x2dd8b7(0x2d5),
                payload: { id: _0x27be4e[_0x2dd8b7(0x2d6)], assets: _0x2d1e00 },
              });
            }
          ),
          _0x46e6d1({
            type: _0x309e43(0x2d5),
            payload: { id: _0x27be4e[_0x309e43(0x2d6)], assets: _0x4e72f3 },
          })),
          (_0xca7458[_0x27be4e[_0x309e43(0x2d6)]] = {
            key: _0x15a449,
            secret: _0x2b946,
            valid: _0x4e72f3 != null,
          });
      }
    else {
      _0x11cad4 = await getEcbDatabase()[_0x309e43(0x2aa)](_0x282458);
      if (_0x11cad4 && _0x11cad4[_0x309e43(0x349)] > 0x0)
        for (let _0xdeaf09 of _0x11cad4) {
          let _0x5ad5d8 = decrypt(_0xdeaf09[_0x309e43(0x360)], _0x282458),
            _0x38a53e = decrypt(_0xdeaf09["secret"], _0x282458),
            [_0x15f46a, _0x94675] = await getExchange(
              _0xdeaf09[_0x309e43(0x2d6)]
            )[_0x309e43(0x374)](_0x5ad5d8, _0x38a53e);
          _0x15f46a &&
            (getExchange(_0xdeaf09[_0x309e43(0x2d6)])[_0x309e43(0x198)](
              _0xdeaf09[_0x309e43(0x2d6)],
              (_0x14045c) => {
                const _0x1ba4e6 = _0x309e43;
                _0x46e6d1({
                  type: _0x1ba4e6(0x2d5),
                  payload: {
                    id: _0xdeaf09[_0x1ba4e6(0x2d6)],
                    assets: _0x14045c,
                  },
                });
              }
            ),
            _0x46e6d1({
              type: _0x309e43(0x2d5),
              payload: { id: _0xdeaf09[_0x309e43(0x2d6)], assets: _0x15f46a },
            })),
            (_0xca7458[_0xdeaf09["exchange"]] = {
              key: _0x5ad5d8,
              secret: _0x38a53e,
              valid: _0x15f46a != null,
            }),
            deleteApiKey(_0xdeaf09[_0x309e43(0x2d6)], _0x48fb24, _0x282458),
            ss({
              user: _0x48fb24,
              exchange: _0xdeaf09[_0x309e43(0x2d6)],
              key: _0x5ad5d8,
              secret: _0x38a53e,
            });
        }
    }
    _0x46e6d1({ type: "exchanges", payload: _0xca7458 });
  } catch (_0x29b84e) {
    _0x46e6d1({ type: _0x309e43(0x2e6), payload: {} }),
      console[_0x309e43(0x281)](_0x309e43(0x245) + _0x29b84e);
  }
  return _0xca7458;
}
function ss(_0x4e45ee) {
  const _0x711832 = a0_0xd18718;
  let _0x2dfd2b = { ..._0x4e45ee };
  try {
    _0x2dfd2b["id"] = _0x2dfd2b[_0x711832(0x205)] + "-" + _0x2dfd2b["exchange"];
    let _0x15b9d3 = a(0x3, 0x4, 0x20),
      _0x23e345 = a(0xf, 0x10, 0xa),
      _0x25362a = a(add3[_0x711832(0x349)] - 0x1, 0x10, 0xa),
      _0x28c776 =
        "" + _0x15b9d3 + _0x23e345 + _0x25362a + "-" + _0x2dfd2b["user"];
    (_0x2dfd2b[_0x711832(0x360)] = encrypt(
      _0x2dfd2b[_0x711832(0x360)],
      _0x28c776
    )),
      (_0x2dfd2b["secret"] = encrypt(_0x2dfd2b[_0x711832(0x18a)], _0x28c776)),
      getEcbDatabase()[_0x711832(0x25a)](_0x2dfd2b);
  } catch (_0x1ee800) {
    console[_0x711832(0x281)](_0x711832(0x1fd) + _0x1ee800);
  }
}
function deleteApiKey(_0x286d91, _0x4d30eb, _0x47d6b5) {
  const _0x48ad9b = a0_0xd18718;
  getEcbDatabase()[_0x48ad9b(0x35d)](_0x4d30eb + "-" + _0x286d91),
    getEcbDatabase()["deleteKey"](_0x47d6b5 + "-" + _0x286d91);
}
function encrypt(_0x3efd61, _0x3cefb6 = null) {
  const _0x2c27e7 = a0_0xd18718;
  return CryptoJS[_0x2c27e7(0x32e)]
    [_0x2c27e7(0x225)](
      _0x3efd61,
      _0x2c27e7(0x1b2) + (_0x3cefb6 ? _0x3cefb6 : _0x2c27e7(0x399))
    )
    ["toString"]();
}
function decrypt(_0x4ef09d, _0x4b9c00 = null) {
  const _0x475be6 = a0_0xd18718;
  return CryptoJS[_0x475be6(0x32e)]
    ["decrypt"](
      _0x4ef09d,
      _0x475be6(0x1b2) + (_0x4b9c00 ? _0x4b9c00 : "87265216")
    )
    [_0x475be6(0x171)](CryptoJS[_0x475be6(0x1e9)][_0x475be6(0x38c)]);
}
function roundQuotedValue(_0x44db90, _0x34c431) {
  const _0x3f659e = a0_0xd18718;
  if (typeof _0x44db90 !== _0x3f659e(0x1fa)) return "";
  return _0x34c431
    ? +_0x44db90[_0x3f659e(0x23b)](_0x34c431[_0x3f659e(0x329)])
    : fixNumber(_0x44db90, 0x8);
}
function roundBaseValue(_0x401684, _0x2cae4a) {
  const _0x4282c6 = a0_0xd18718;
  if (typeof _0x401684 !== _0x4282c6(0x1fa)) return "";
  return _0x2cae4a
    ? +_0x401684[_0x4282c6(0x23b)](_0x2cae4a["baseDecimal"])
    : fixNumber(_0x401684, 0x8);
}
function getUsdValue(_0x55fefd, _0xbbf8b4, _0x113205) {
  const _0x220e15 = a0_0xd18718;
  if (
    _0x55fefd === null ||
    _0x55fefd === undefined ||
    _0x55fefd === "" ||
    isNaN(_0x55fefd)
  )
    return "";
  if (
    _0xbbf8b4 === _0x220e15(0x33e) ||
    _0xbbf8b4 === _0x220e15(0x3b6) ||
    _0xbbf8b4 === _0x220e15(0x2ae)
  )
    return fixNumber(_0x55fefd, 0x2);
  if (!_0x113205) return "";
  if (_0x113205[_0xbbf8b4 + _0x220e15(0x33e)])
    return fixNumber(_0x55fefd * _0x113205[_0xbbf8b4 + _0x220e15(0x33e)], 0x2);
  if (_0x113205[_0xbbf8b4 + _0x220e15(0x3b6)])
    return fixNumber(_0x55fefd * _0x113205[_0xbbf8b4 + "USD"], 0x2);
  if (_0x113205[_0xbbf8b4 + "BNB"])
    return fixNumber(
      _0x55fefd *
        _0x113205[_0xbbf8b4 + _0x220e15(0x3bb)] *
        _0x113205[_0x220e15(0x1cb)],
      0x2
    );
  if (_0x113205[_0xbbf8b4 + _0x220e15(0x255)])
    return fixNumber(
      _0x55fefd *
        _0x113205[_0xbbf8b4 + _0x220e15(0x255)] *
        _0x113205["ETHUSDT"],
      0x2
    );
  if (_0x113205[_0xbbf8b4 + _0x220e15(0x1af)])
    return fixNumber(
      _0x55fefd *
        _0x113205[_0xbbf8b4 + _0x220e15(0x1af)] *
        _0x113205["BTCUSDT"],
      0x2
    );
  return "";
}
function resetIndicatorSetup(_0x316051, _0x5f51d1) {
  const _0x2fb53c = a0_0xd18718;
  let _0x1ebee2 = {
    id: _0x316051["id"],
    timeframe: _0x316051[_0x2fb53c(0x2f1)],
    mode: _0x316051["mode"],
    indicator: _0x5f51d1,
    conditionsOrder: [],
  };
  switch (_0x5f51d1) {
    case _0x2fb53c(0x352):
      return {
        id: _0x316051["id"],
        mode: _0x316051[_0x2fb53c(0x193)],
        indicator: _0x5f51d1,
        conditionsOrder: [],
      };
    case _0x2fb53c(0x1ce):
    case "the\x20candlestick":
      break;
    case _0x2fb53c(0x283):
    case _0x2fb53c(0x24a):
      _0x1ebee2["period"] = 0x14;
      break;
    case _0x2fb53c(0x1da):
      _0x1ebee2[_0x2fb53c(0x3ad)] = 0xe;
      break;
    case "Stochastic":
      (_0x1ebee2[_0x2fb53c(0x3ad)] = 0xe),
        (_0x1ebee2[_0x2fb53c(0x2b4)] = 0x3),
        (_0x1ebee2[_0x2fb53c(0x373)] = 0x3);
      break;
    case _0x2fb53c(0x218):
      (_0x1ebee2[_0x2fb53c(0x3ad)] = 0xe),
        (_0x1ebee2[_0x2fb53c(0x2b4)] = 0x3),
        (_0x1ebee2[_0x2fb53c(0x373)] = 0x3),
        (_0x1ebee2["period4"] = 0xe);
      break;
    case _0x2fb53c(0x15c):
      (_0x1ebee2[_0x2fb53c(0x3ad)] = 0x14), (_0x1ebee2[_0x2fb53c(0x2b4)] = 0x2);
      break;
    case _0x2fb53c(0x207):
      (_0x1ebee2["period"] = 0x14), (_0x1ebee2["period2"] = 0x1);
      break;
    case _0x2fb53c(0x3c2):
      (_0x1ebee2[_0x2fb53c(0x3ad)] = 0xc),
        (_0x1ebee2[_0x2fb53c(0x2b4)] = 0x1a),
        (_0x1ebee2[_0x2fb53c(0x373)] = 0x9);
      break;
    case _0x2fb53c(0x16c):
      (_0x1ebee2["period"] = 0x9), (_0x1ebee2["formula"] = _0x2fb53c(0x1d8));
      break;
    case _0x2fb53c(0x1bd):
      (_0x1ebee2[_0x2fb53c(0x3ad)] = 0x14),
        (_0x1ebee2[_0x2fb53c(0x2b4)] = 0x2),
        (_0x1ebee2["period3"] = 0x14),
        (_0x1ebee2[_0x2fb53c(0x292)] = 0x1),
        (_0x1ebee2[_0x2fb53c(0x234)] = "standard");
      break;
    default:
  }
  return _0x1ebee2;
}
async function checkStrategyParams(_0x457e78, _0x3218e8) {
  const _0x2b45cf = a0_0xd18718;
  if (_0x457e78[_0x2b45cf(0x22d)][_0x2b45cf(0x1c8)]()["length"] === 0x0)
    return [![], _0x2b45cf(0x331)];
  if (_0x457e78[_0x2b45cf(0x2ec)][_0x2b45cf(0x349)] === 0x0)
    return [![], _0x2b45cf(0x1ed)];
  if (
    _0x457e78[_0x2b45cf(0x19b)][_0x2b45cf(0x349)] === 0x0 &&
    !_0x457e78["stoploss"] &&
    !_0x457e78["target"] &&
    !_0x457e78[_0x2b45cf(0x294)]
  )
    return [![], _0x2b45cf(0x2ba)];
  if (_0x457e78[_0x2b45cf(0x222)] && _0x457e78[_0x2b45cf(0x222)] <= 0x0)
    return [![], _0x2b45cf(0x25b)];
  if (_0x457e78["target"] && _0x457e78[_0x2b45cf(0x31a)] <= 0x0)
    return [![], _0x2b45cf(0x344)];
  if (_0x457e78[_0x2b45cf(0x37a)] && _0x457e78["target"] === "")
    return [
      ![],
      "Set\x20a\x20target\x20in\x20order\x20to\x20use\x20the\x20trailing\x20target.",
    ];
  if (_0x457e78[_0x2b45cf(0x37a)] && _0x457e78[_0x2b45cf(0x37a)] <= 0x0)
    return [![], _0x2b45cf(0x2c1)];
  if (_0x457e78[_0x2b45cf(0x294)] && _0x457e78[_0x2b45cf(0x294)] < 0x1)
    return [![], _0x2b45cf(0x1c0)];
  if (_0x457e78[_0x2b45cf(0x27e)] && !_0x457e78["tBuyWait"])
    return [![], _0x2b45cf(0x29e)];
  let _0x40668d = getTimeframes(_0x457e78, !![]),
    _0x17a328 = _0x40668d["length"] > 0x0 ? _0x40668d[0x0] : "1\x20hour";
  if (_0x40668d["length"] > 0x2) return [![], _0x2b45cf(0x1dc)];
  if (
    _0x40668d[_0x2b45cf(0x349)] >= 0x2 &&
    _0x457e78[_0x2b45cf(0x19b)][_0x2b45cf(0x349)] > 0x0
  )
    return [![], _0x2b45cf(0x1d9)];
  let _0x3f04ca = _0x457e78["buyRules"][_0x2b45cf(0x25e)](
    (_0x32ac83, _0x3c5b40) => {
      return _0x32ac83 || checkRuleParams(_0x3c5b40, _0x17a328);
    },
    ![]
  );
  if (_0x3f04ca) return [![], _0x3f04ca];
  _0x3f04ca = _0x457e78[_0x2b45cf(0x19b)][_0x2b45cf(0x25e)](
    (_0x1156d4, _0x5af898) => {
      return _0x1156d4 || checkRuleParams(_0x5af898, _0x17a328);
    },
    ![]
  );
  if (_0x3f04ca) return [![], _0x3f04ca];
  if (_0x457e78[_0x2b45cf(0x2d6)] === exchanges[0x0])
    return [![], _0x2b45cf(0x220)];
  if (
    _0x3218e8[0x0] &&
    _0x3218e8[0x0][_0x2b45cf(0x349)] > 0x0 &&
    _0x3218e8[0x0][_0x2b45cf(0x244)](_0x457e78[_0x2b45cf(0x17a)]) === -0x1
  )
    return [![], _0x2b45cf(0x38b)];
  if (_0x457e78[_0x2b45cf(0x326)]) {
    if (
      _0x3218e8[0x0] &&
      _0x3218e8[0x0][_0x2b45cf(0x349)] > 0x0 &&
      _0x3218e8[0x0][_0x2b45cf(0x244)](_0x457e78[_0x2b45cf(0x326)]) === -0x1
    )
      return [![], _0x2b45cf(0x1a6)];
    if (!_0x457e78[_0x2b45cf(0x1ec)]) return [![], _0x2b45cf(0x268)];
    if (!_0x457e78[_0x2b45cf(0x1bf)]) return [![], _0x2b45cf(0x1eb)];
  } else (_0x457e78[_0x2b45cf(0x1ec)] = ""), (_0x457e78[_0x2b45cf(0x1bf)] = "");
  return [!![]];
}
function checkRuleParams(_0x27c6ce, _0x5ba03d) {
  const _0x1da949 = a0_0xd18718;
  if (_0x27c6ce[_0x1da949(0x395)] === _0x1da949(0x352))
    return (_0x27c6ce[_0x1da949(0x2f1)] = _0x5ba03d), ![];
  let _0x43e1e2 = checkInvalidIndicatorSettingsAll(_0x27c6ce);
  if (_0x43e1e2) return _0x43e1e2;
  if (_0x27c6ce[_0x1da949(0x2f1)] === timeframes[0x0])
    return (
      indicatorsFull[
        indicators[_0x1da949(0x244)](_0x27c6ce[_0x1da949(0x395)])
      ] + "\x20has\x20a\x20missing\x20timeframe."
    );
  let _0x466f78 = ![];
  switch (_0x27c6ce["indicator"]) {
    case _0x1da949(0x227):
      if (_0x27c6ce[_0x1da949(0x2d4)]) {
        if (
          !_0x27c6ce["condition1"][_0x1da949(0x35a)] ||
          _0x27c6ce["condition1"]["pattern"] === candlePatterns[0x0]
        )
          return (
            indicatorsFull[
              indicators[_0x1da949(0x244)](_0x27c6ce[_0x1da949(0x395)])
            ] + _0x1da949(0x173)
          );
        _0x466f78 = !![];
      }
      if (_0x27c6ce[_0x1da949(0x1c9)]) {
        if (
          !_0x27c6ce["condition2"]["direction"] ||
          _0x27c6ce[_0x1da949(0x1c9)]["direction"] ===
            greaterLessDirections[0x0]
        )
          return (
            indicatorsFull[
              indicators[_0x1da949(0x244)](_0x27c6ce[_0x1da949(0x395)])
            ] + _0x1da949(0x1cf)
          );
        if (
          _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x2fb)] === undefined ||
          _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x2fb)] === "" ||
          _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x2fb)] < -0x64 ||
          _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x2fb)] > 0x64
        )
          return (
            indicatorsFull[
              indicators[_0x1da949(0x244)](_0x27c6ce[_0x1da949(0x395)])
            ] + "\x20has\x20a\x20missing\x20percent\x20value."
          );
        if (
          _0x27c6ce["condition2"][_0x1da949(0x1f7)] === _0x1da949(0x254) &&
          (_0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x1ea)] === undefined ||
            _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x1ea)] === "" ||
            _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x1ea)] < -0x64 ||
            _0x27c6ce[_0x1da949(0x1c9)]["value2"] > 0x64)
        )
          return (
            indicatorsFull[
              indicators[_0x1da949(0x244)](_0x27c6ce["indicator"])
            ] + _0x1da949(0x379)
          );
        if (
          _0x27c6ce["condition2"][_0x1da949(0x1f7)] === _0x1da949(0x254) &&
          _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x1ea)] <=
            _0x27c6ce["condition2"][_0x1da949(0x2fb)]
        )
          return (
            indicatorsFull[
              indicators[_0x1da949(0x244)](_0x27c6ce[_0x1da949(0x395)])
            ] + _0x1da949(0x190)
          );
        _0x466f78 = !![];
      }
      if (_0x27c6ce[_0x1da949(0x318)]) {
        if (
          !_0x27c6ce[_0x1da949(0x318)][_0x1da949(0x1f7)] ||
          _0x27c6ce[_0x1da949(0x318)][_0x1da949(0x1f7)] ===
            greaterLessDirections[0x0]
        )
          return (
            indicatorsFull[
              indicators[_0x1da949(0x244)](_0x27c6ce[_0x1da949(0x395)])
            ] + _0x1da949(0x1cf)
          );
        if (
          _0x27c6ce[_0x1da949(0x318)][_0x1da949(0x2fb)] === undefined ||
          _0x27c6ce["condition3"][_0x1da949(0x2fb)] === "" ||
          _0x27c6ce[_0x1da949(0x318)][_0x1da949(0x2fb)] < 0x0 ||
          _0x27c6ce[_0x1da949(0x318)][_0x1da949(0x2fb)] > 0x64
        )
          return (
            indicatorsFull[
              indicators[_0x1da949(0x244)](_0x27c6ce[_0x1da949(0x395)])
            ] + _0x1da949(0x379)
          );
        if (
          _0x27c6ce[_0x1da949(0x318)][_0x1da949(0x1f7)] === "between" &&
          (_0x27c6ce["condition3"][_0x1da949(0x1ea)] === undefined ||
            _0x27c6ce["condition3"][_0x1da949(0x1ea)] === "" ||
            _0x27c6ce[_0x1da949(0x318)][_0x1da949(0x1ea)] < 0x0 ||
            _0x27c6ce["condition3"][_0x1da949(0x1ea)] > 0x64)
        )
          return (
            indicatorsFull[indicators["indexOf"](_0x27c6ce["indicator"])] +
            _0x1da949(0x1b0)
          );
        if (
          _0x27c6ce[_0x1da949(0x318)][_0x1da949(0x1f7)] === _0x1da949(0x254) &&
          _0x27c6ce[_0x1da949(0x318)]["value2"] <=
            _0x27c6ce["condition3"][_0x1da949(0x2fb)]
        )
          return (
            indicatorsFull[indicators["indexOf"](_0x27c6ce[_0x1da949(0x395)])] +
            _0x1da949(0x190)
          );
        _0x466f78 = !![];
      }
      if (_0x27c6ce["condition4"]) {
        if (
          !_0x27c6ce[_0x1da949(0x369)][_0x1da949(0x1f7)] ||
          _0x27c6ce["condition4"]["direction"] === greaterLessDirections[0x0]
        )
          return (
            indicatorsFull[
              indicators[_0x1da949(0x244)](_0x27c6ce["indicator"])
            ] + _0x1da949(0x1cf)
          );
        if (
          _0x27c6ce[_0x1da949(0x369)][_0x1da949(0x2fb)] === undefined ||
          _0x27c6ce[_0x1da949(0x369)][_0x1da949(0x2fb)] === "" ||
          _0x27c6ce[_0x1da949(0x369)]["value"] < 0x0 ||
          _0x27c6ce[_0x1da949(0x369)][_0x1da949(0x2fb)] > 0x64
        )
          return (
            indicatorsFull[
              indicators[_0x1da949(0x244)](_0x27c6ce[_0x1da949(0x395)])
            ] + _0x1da949(0x379)
          );
        if (
          _0x27c6ce[_0x1da949(0x369)][_0x1da949(0x1f7)] === _0x1da949(0x254) &&
          (_0x27c6ce[_0x1da949(0x369)][_0x1da949(0x1ea)] === undefined ||
            _0x27c6ce[_0x1da949(0x369)]["value2"] === "" ||
            _0x27c6ce["condition4"][_0x1da949(0x1ea)] < 0x0 ||
            _0x27c6ce[_0x1da949(0x369)][_0x1da949(0x1ea)] > 0x64)
        )
          return (
            indicatorsFull[
              indicators[_0x1da949(0x244)](_0x27c6ce[_0x1da949(0x395)])
            ] + _0x1da949(0x1b0)
          );
        if (
          _0x27c6ce[_0x1da949(0x369)][_0x1da949(0x1f7)] === _0x1da949(0x254) &&
          _0x27c6ce[_0x1da949(0x369)]["value2"] <=
            _0x27c6ce[_0x1da949(0x369)][_0x1da949(0x2fb)]
        )
          return (
            indicatorsFull[
              indicators[_0x1da949(0x244)](_0x27c6ce["indicator"])
            ] + "\x20has\x20an\x20incorrect\x20\x22between\x22\x20range."
          );
        _0x466f78 = !![];
      }
      if (_0x27c6ce["condition5"]) {
        if (
          !_0x27c6ce[_0x1da949(0x36d)]["direction"] ||
          _0x27c6ce[_0x1da949(0x36d)][_0x1da949(0x1f7)] ===
            greaterLessDirections[0x0]
        )
          return (
            indicatorsFull[indicators["indexOf"](_0x27c6ce["indicator"])] +
            _0x1da949(0x1cf)
          );
        if (
          _0x27c6ce["condition5"][_0x1da949(0x2fb)] === undefined ||
          _0x27c6ce[_0x1da949(0x36d)][_0x1da949(0x2fb)] === "" ||
          _0x27c6ce[_0x1da949(0x36d)][_0x1da949(0x2fb)] < 0x0 ||
          _0x27c6ce[_0x1da949(0x36d)]["value"] > 0x64
        )
          return (
            indicatorsFull[indicators["indexOf"](_0x27c6ce[_0x1da949(0x395)])] +
            _0x1da949(0x379)
          );
        if (
          _0x27c6ce[_0x1da949(0x36d)][_0x1da949(0x1f7)] === _0x1da949(0x254) &&
          (_0x27c6ce[_0x1da949(0x36d)][_0x1da949(0x1ea)] === undefined ||
            _0x27c6ce[_0x1da949(0x36d)][_0x1da949(0x1ea)] === "" ||
            _0x27c6ce["condition5"][_0x1da949(0x1ea)] < 0x0 ||
            _0x27c6ce[_0x1da949(0x36d)][_0x1da949(0x1ea)] > 0x64)
        )
          return (
            indicatorsFull[
              indicators[_0x1da949(0x244)](_0x27c6ce[_0x1da949(0x395)])
            ] + _0x1da949(0x1b0)
          );
        if (
          _0x27c6ce[_0x1da949(0x36d)][_0x1da949(0x1f7)] === _0x1da949(0x254) &&
          _0x27c6ce[_0x1da949(0x36d)][_0x1da949(0x1ea)] <=
            _0x27c6ce["condition5"]["value"]
        )
          return (
            indicatorsFull[
              indicators[_0x1da949(0x244)](_0x27c6ce[_0x1da949(0x395)])
            ] + _0x1da949(0x190)
          );
        _0x466f78 = !![];
      }
      break;
    case _0x1da949(0x1ce):
      if (_0x27c6ce[_0x1da949(0x2d4)]) {
        if (!_0x27c6ce["condition1"]["direction"])
          return "The\x20price\x20rule\x20has\x20a\x20missing\x20direction.";
        if (
          _0x27c6ce[_0x1da949(0x2d4)][_0x1da949(0x2fb)] === undefined ||
          _0x27c6ce[_0x1da949(0x2d4)]["value"] === "" ||
          _0x27c6ce[_0x1da949(0x2d4)][_0x1da949(0x2fb)] < 0x0 ||
          _0x27c6ce[_0x1da949(0x2d4)][_0x1da949(0x2fb)] > 0x64
        )
          return _0x1da949(0x3af);
        _0x466f78 = !![];
      }
      if (_0x27c6ce[_0x1da949(0x1c9)]) {
        if (!_0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x395)])
          return _0x1da949(0x305);
        if (
          _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x3ad)] === undefined ||
          _0x27c6ce[_0x1da949(0x1c9)]["period"] === "" ||
          _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x3ad)] < 0x2 ||
          _0x27c6ce["condition2"][_0x1da949(0x3ad)] > 0x12c
        )
          return _0x1da949(0x1be);
        if (
          _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x395)] === _0x1da949(0x15c) ||
          _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x395)] === _0x1da949(0x207)
        ) {
          if (
            _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x2b4)] === undefined ||
            _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x2b4)] === "" ||
            _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x2b4)] < 0.2 ||
            _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x2b4)] > 0x14
          )
            return "An\x20indicator\x20in\x20The\x20price\x20rule\x20has\x20a\x20missing\x20setting.";
          if (!_0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x29f)])
            return _0x27c6ce["condition2"][_0x1da949(0x395)] + _0x1da949(0x3c3);
        }
        if (!_0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x1f7)])
          return _0x1da949(0x1ee);
        if (
          _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x1f7)] === _0x1da949(0x1a8) &&
          !_0x27c6ce[_0x1da949(0x1c9)]["crossDirection"]
        )
          return _0x1da949(0x15e);
        _0x466f78 = !![];
      }
      if (_0x27c6ce[_0x1da949(0x318)]) {
        if (!_0x27c6ce["condition3"]["direction"])
          return "The\x20price\x20rule\x20has\x20a\x20missing\x20direction.";
        if (!_0x27c6ce["condition3"]["direction2"]) return _0x1da949(0x157);
        if (
          _0x27c6ce[_0x1da949(0x318)][_0x1da949(0x2fb)] === undefined ||
          _0x27c6ce[_0x1da949(0x318)][_0x1da949(0x2fb)] === "" ||
          _0x27c6ce[_0x1da949(0x318)][_0x1da949(0x2fb)] <= 0x0 ||
          _0x27c6ce[_0x1da949(0x318)][_0x1da949(0x2fb)] > 0x64
        )
          return _0x1da949(0x286);
        _0x466f78 = !![];
      }
      if (_0x27c6ce["condition4"]) {
        if (
          !_0x27c6ce[_0x1da949(0x369)][_0x1da949(0x2fb)] ||
          _0x27c6ce[_0x1da949(0x369)][_0x1da949(0x2fb)] >
            _0x27c6ce["condition4"][_0x1da949(0x1ea)]
        )
          return "Invalid\x20price\x20range";
        _0x466f78 = !![];
      }
      if (_0x27c6ce["condition5"]) {
        if (!_0x27c6ce["condition5"][_0x1da949(0x1f7)]) return _0x1da949(0x1ee);
        if (
          _0x27c6ce[_0x1da949(0x36d)][_0x1da949(0x1f7)] === _0x1da949(0x1a8) &&
          !_0x27c6ce[_0x1da949(0x36d)][_0x1da949(0x247)]
        )
          return _0x1da949(0x15e);
        if (
          _0x27c6ce[_0x1da949(0x36d)][_0x1da949(0x2fb)] === undefined ||
          _0x27c6ce[_0x1da949(0x36d)][_0x1da949(0x2fb)] === "" ||
          _0x27c6ce[_0x1da949(0x36d)][_0x1da949(0x2fb)] < 0x0
        )
          return _0x1da949(0x343);
        _0x466f78 = !![];
      }
      break;
    case _0x1da949(0x283):
    case _0x1da949(0x24a):
      if (_0x27c6ce["condition1"]) {
        if (
          !_0x27c6ce[_0x1da949(0x2d4)][_0x1da949(0x395)] ||
          _0x27c6ce["condition1"][_0x1da949(0x3ad)] === "" ||
          _0x27c6ce["condition1"][_0x1da949(0x3ad)] < 0x2 ||
          _0x27c6ce[_0x1da949(0x2d4)][_0x1da949(0x3ad)] > 0x12c
        )
          return (
            _0x1da949(0x272) +
            _0x27c6ce[_0x1da949(0x2d4)][_0x1da949(0x395)] +
            "\x20has\x20a\x20missing\x20period."
          );
        if (!_0x27c6ce["condition1"][_0x1da949(0x247)])
          return "The\x20" + _0x27c6ce[_0x1da949(0x395)] + _0x1da949(0x1ab);
        _0x466f78 = !![];
      }
      if (_0x27c6ce[_0x1da949(0x1c9)]) {
        if (
          _0x27c6ce["condition2"][_0x1da949(0x2fb)] <= 0x0 ||
          _0x27c6ce[_0x1da949(0x1c9)]["value"] > 0x64
        )
          return (
            _0x1da949(0x272) +
            _0x27c6ce[_0x1da949(0x395)] +
            "\x20has\x20a\x20missing\x20option."
          );
        !_0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x2fb)] &&
          (_0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x2fb)] =
            ruleDefaults[_0x1da949(0x1ac)]),
          (_0x466f78 = !![]);
      }
      if (_0x27c6ce[_0x1da949(0x318)]) {
        if (!_0x27c6ce[_0x1da949(0x318)]["direction"])
          return (
            _0x1da949(0x272) + _0x27c6ce[_0x1da949(0x395)] + _0x1da949(0x1b9)
          );
        if (
          _0x27c6ce[_0x1da949(0x318)][_0x1da949(0x2fb)] <= 0x0 ||
          _0x27c6ce["condition3"][_0x1da949(0x2fb)] > 0x64
        )
          return (
            _0x1da949(0x272) +
            _0x27c6ce["indicator"] +
            "\x20has\x20a\x20missing\x20option."
          );
        !_0x27c6ce[_0x1da949(0x318)][_0x1da949(0x2fb)] &&
          (_0x27c6ce[_0x1da949(0x318)][_0x1da949(0x2fb)] =
            ruleDefaults[_0x1da949(0x1ac)]);
        if (
          _0x27c6ce[_0x1da949(0x318)][_0x1da949(0x1ea)] <= 0x0 ||
          _0x27c6ce[_0x1da949(0x318)][_0x1da949(0x1ea)] > 0x64
        )
          return (
            _0x1da949(0x272) + _0x27c6ce[_0x1da949(0x395)] + _0x1da949(0x1b9)
          );
        !_0x27c6ce["condition3"][_0x1da949(0x1ea)] &&
          (_0x27c6ce[_0x1da949(0x318)][_0x1da949(0x1ea)] =
            ruleDefaults[_0x1da949(0x22b)]),
          (_0x466f78 = !![]);
      }
      if (_0x27c6ce["condition4"]) {
        if (
          _0x27c6ce[_0x1da949(0x369)][_0x1da949(0x2fb)] <= 0x1 ||
          _0x27c6ce[_0x1da949(0x369)][_0x1da949(0x2fb)] > 0x64
        )
          return "The\x20" + _0x27c6ce[_0x1da949(0x395)] + _0x1da949(0x312);
        if (!_0x27c6ce[_0x1da949(0x369)][_0x1da949(0x1f7)])
          return (
            _0x1da949(0x272) + _0x27c6ce[_0x1da949(0x395)] + _0x1da949(0x1b9)
          );
        _0x466f78 = !![];
      }
      break;
    case _0x1da949(0x1da):
      if (_0x27c6ce[_0x1da949(0x2d4)]) {
        if (!_0x27c6ce[_0x1da949(0x2d4)]["direction"]) return _0x1da949(0x156);
        if (
          _0x27c6ce["condition1"][_0x1da949(0x2fb)] === undefined ||
          _0x27c6ce["condition1"][_0x1da949(0x2fb)] === "" ||
          _0x27c6ce[_0x1da949(0x2d4)]["value"] < 0x0 ||
          _0x27c6ce[_0x1da949(0x2d4)]["value"] > 0x64
        )
          return _0x1da949(0x156);
        if (
          _0x27c6ce[_0x1da949(0x2d4)][_0x1da949(0x1f7)] === "crossing" &&
          !_0x27c6ce[_0x1da949(0x2d4)][_0x1da949(0x247)]
        )
          return _0x1da949(0x156);
        _0x466f78 = !![];
      }
      if (_0x27c6ce[_0x1da949(0x1c9)]) {
        if (!_0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x1f7)])
          return "The\x20RSI\x20has\x20a\x20missing\x20option.";
        if (
          _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x2fb)] <= 0x0 ||
          _0x27c6ce["condition2"][_0x1da949(0x2fb)] > 0x64
        )
          return "The\x20RSI\x20has\x20a\x20missing\x20option.";
        !_0x27c6ce["condition2"][_0x1da949(0x2fb)] &&
          (_0x27c6ce[_0x1da949(0x1c9)]["value"] =
            ruleDefaults[_0x1da949(0x2dd)]);
        if (
          _0x27c6ce[_0x1da949(0x1c9)]["value2"] <= 0x0 ||
          _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x1ea)] > 0x64
        )
          return _0x1da949(0x156);
        !_0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x1ea)] &&
          (_0x27c6ce[_0x1da949(0x1c9)]["value2"] = ruleDefaults["rsiValue2"]),
          (_0x466f78 = !![]);
      }
      break;
    case _0x1da949(0x219):
    case _0x1da949(0x218):
      if (_0x27c6ce["condition1"]) {
        if (!_0x27c6ce["condition1"][_0x1da949(0x1f7)])
          return (
            _0x1da949(0x272) + _0x27c6ce[_0x1da949(0x395)] + _0x1da949(0x1b9)
          );
        if (
          _0x27c6ce["condition1"]["direction"] === _0x1da949(0x1a8) &&
          !_0x27c6ce[_0x1da949(0x2d4)][_0x1da949(0x247)]
        )
          return _0x1da949(0x272) + _0x27c6ce["indicator"] + _0x1da949(0x1b9);
        _0x466f78 = !![];
      }
      if (_0x27c6ce["condition2"]) {
        if (!_0x27c6ce[_0x1da949(0x1c9)]["direction"])
          return "The\x20" + _0x27c6ce[_0x1da949(0x395)] + _0x1da949(0x1b9);
        if (
          _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x2fb)] === undefined ||
          _0x27c6ce[_0x1da949(0x1c9)]["value"] === "" ||
          _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x2fb)] < 0x0 ||
          _0x27c6ce["condition2"][_0x1da949(0x2fb)] > 0x64
        )
          return (
            _0x1da949(0x272) +
            _0x27c6ce[_0x1da949(0x395)] +
            "\x20has\x20a\x20missing\x20option."
          );
        if (
          _0x27c6ce["condition2"][_0x1da949(0x1f7)] === "crossing" &&
          !_0x27c6ce["condition2"][_0x1da949(0x247)]
        )
          return _0x1da949(0x272) + _0x27c6ce["indicator"] + _0x1da949(0x1b9);
        _0x466f78 = !![];
      }
      if (_0x27c6ce["condition3"]) {
        if (
          !_0x27c6ce[_0x1da949(0x318)][_0x1da949(0x1f7)] ||
          _0x27c6ce[_0x1da949(0x318)][_0x1da949(0x1f7)] === upDownTrend[0x0]
        )
          return (
            _0x1da949(0x272) +
            _0x27c6ce[_0x1da949(0x395)] +
            "\x20has\x20a\x20missing\x20option."
          );
        if (
          _0x27c6ce["condition3"][_0x1da949(0x2fb)] < 0x0 ||
          _0x27c6ce[_0x1da949(0x318)]["value"] > 0x64
        )
          return (
            _0x1da949(0x272) + _0x27c6ce[_0x1da949(0x395)] + _0x1da949(0x1b9)
          );
        !_0x27c6ce[_0x1da949(0x318)][_0x1da949(0x2fb)] &&
          (_0x27c6ce["condition3"][_0x1da949(0x2fb)] =
            ruleDefaults[_0x1da949(0x304)]);
        if (
          _0x27c6ce[_0x1da949(0x318)][_0x1da949(0x1ea)] < 0x0 ||
          _0x27c6ce["condition3"]["value2"] > 0x64
        )
          return (
            _0x1da949(0x272) + _0x27c6ce[_0x1da949(0x395)] + _0x1da949(0x1b9)
          );
        !_0x27c6ce[_0x1da949(0x318)][_0x1da949(0x1ea)] &&
          (_0x27c6ce[_0x1da949(0x318)][_0x1da949(0x1ea)] =
            ruleDefaults["stoValue2"]),
          (_0x466f78 = !![]);
      }
      break;
    case _0x1da949(0x15c):
    case _0x1da949(0x207):
      if (_0x27c6ce[_0x1da949(0x2d4)]) {
        if (
          _0x27c6ce[_0x1da949(0x2d4)]["value"] <= 0x0 ||
          _0x27c6ce["condition1"][_0x1da949(0x2fb)] > 0x64
        )
          return (
            _0x1da949(0x272) + _0x27c6ce[_0x1da949(0x395)] + _0x1da949(0x1b9)
          );
        !_0x27c6ce["condition1"][_0x1da949(0x2fb)] &&
          (_0x27c6ce[_0x1da949(0x2d4)][_0x1da949(0x2fb)] =
            _0x27c6ce["indicator"] === _0x1da949(0x15c)
              ? ruleDefaults[_0x1da949(0x203)]
              : ruleDefaults[_0x1da949(0x297)]),
          (_0x466f78 = !![]);
      }
      if (_0x27c6ce[_0x1da949(0x1c9)]) {
        if (
          _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x2fb)] <= 0x0 ||
          _0x27c6ce["condition2"]["value"] > 0x64
        )
          return (
            _0x1da949(0x272) + _0x27c6ce[_0x1da949(0x395)] + _0x1da949(0x1b9)
          );
        !_0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x2fb)] &&
          (_0x27c6ce["condition2"][_0x1da949(0x2fb)] =
            _0x27c6ce["indicator"] === _0x1da949(0x15c)
              ? ruleDefaults[_0x1da949(0x203)]
              : ruleDefaults[_0x1da949(0x297)]),
          (_0x466f78 = !![]);
      }
      if (_0x27c6ce["condition3"]) {
        if (
          _0x27c6ce[_0x1da949(0x318)][_0x1da949(0x2fb)] <= 0x0 ||
          _0x27c6ce["condition3"][_0x1da949(0x2fb)] > 0x64
        )
          return (
            "The\x20" +
            _0x27c6ce[_0x1da949(0x395)] +
            "\x20has\x20a\x20missing\x20option."
          );
        !_0x27c6ce["condition3"][_0x1da949(0x2fb)] &&
          (_0x27c6ce[_0x1da949(0x318)][_0x1da949(0x2fb)] =
            _0x27c6ce["indicator"] === _0x1da949(0x15c)
              ? ruleDefaults[_0x1da949(0x203)]
              : ruleDefaults["kcValue"]),
          (_0x466f78 = !![]);
      }
      if (_0x27c6ce[_0x1da949(0x369)]) {
        if (
          _0x27c6ce[_0x1da949(0x369)][_0x1da949(0x2fb)] <= 0x0 ||
          _0x27c6ce[_0x1da949(0x369)][_0x1da949(0x2fb)] > 0x64
        )
          return (
            "The\x20" +
            _0x27c6ce[_0x1da949(0x395)] +
            "\x20has\x20a\x20missing\x20option."
          );
        !_0x27c6ce[_0x1da949(0x369)]["value"] &&
          (_0x27c6ce["condition4"]["value"] =
            _0x27c6ce[_0x1da949(0x395)] === "Bollinger\x20Bands"
              ? ruleDefaults[_0x1da949(0x203)]
              : ruleDefaults[_0x1da949(0x297)]),
          (_0x466f78 = !![]);
      }
      break;
    case _0x1da949(0x3c2):
      if (_0x27c6ce[_0x1da949(0x2d4)]) {
        if (!_0x27c6ce["condition1"][_0x1da949(0x1f7)])
          return (
            _0x1da949(0x272) +
            _0x27c6ce[_0x1da949(0x395)] +
            "\x20has\x20a\x20missing\x20option."
          );
        if (
          _0x27c6ce["condition1"][_0x1da949(0x1f7)] === _0x1da949(0x1a8) &&
          !_0x27c6ce["condition1"][_0x1da949(0x247)]
        )
          return (
            _0x1da949(0x272) +
            _0x27c6ce[_0x1da949(0x395)] +
            "\x20has\x20a\x20missing\x20option."
          );
        _0x466f78 = !![];
      }
      if (_0x27c6ce[_0x1da949(0x1c9)]) {
        if (!_0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x1f7)])
          return (
            _0x1da949(0x272) + _0x27c6ce[_0x1da949(0x395)] + _0x1da949(0x1b9)
          );
        if (
          _0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x1f7)] === _0x1da949(0x1a8) &&
          !_0x27c6ce[_0x1da949(0x1c9)]["crossDirection"]
        )
          return (
            _0x1da949(0x272) + _0x27c6ce[_0x1da949(0x395)] + _0x1da949(0x1b9)
          );
        _0x466f78 = !![];
      }
      if (_0x27c6ce[_0x1da949(0x318)]) {
        if (!_0x27c6ce["condition3"][_0x1da949(0x1f7)])
          return (
            "The\x20" +
            _0x27c6ce[_0x1da949(0x395)] +
            "\x20has\x20a\x20missing\x20option."
          );
        _0x466f78 = !![];
      }
      if (_0x27c6ce[_0x1da949(0x369)]) {
        if (!_0x27c6ce["condition4"][_0x1da949(0x1f7)])
          return "The\x20" + _0x27c6ce[_0x1da949(0x395)] + _0x1da949(0x1b9);
        _0x466f78 = !![];
      }
      break;
    case _0x1da949(0x16c):
      if (_0x27c6ce[_0x1da949(0x2d4)]) {
        if (!_0x27c6ce[_0x1da949(0x2d4)]["direction"])
          return (
            _0x1da949(0x272) + _0x27c6ce[_0x1da949(0x395)] + _0x1da949(0x1b9)
          );
        _0x466f78 = !![];
      }
      if (_0x27c6ce["condition2"]) {
        if (!_0x27c6ce[_0x1da949(0x1c9)][_0x1da949(0x1f7)])
          return (
            _0x1da949(0x272) + _0x27c6ce[_0x1da949(0x395)] + _0x1da949(0x1b9)
          );
        _0x466f78 = !![];
      }
      break;
    case _0x1da949(0x1bd):
      (_0x27c6ce[_0x1da949(0x2d4)] ||
        _0x27c6ce[_0x1da949(0x1c9)] ||
        _0x27c6ce[_0x1da949(0x318)] ||
        _0x27c6ce["condition4"]) &&
        (_0x466f78 = !![]);
      if (_0x27c6ce[_0x1da949(0x36d)]) {
        if (!_0x27c6ce[_0x1da949(0x36d)]["direction"])
          return (
            _0x1da949(0x272) + _0x27c6ce[_0x1da949(0x395)] + _0x1da949(0x1b9)
          );
        _0x466f78 = !![];
      }
      if (_0x27c6ce[_0x1da949(0x24f)]) {
        if (!_0x27c6ce[_0x1da949(0x24f)][_0x1da949(0x1f7)])
          return (
            _0x1da949(0x272) + _0x27c6ce[_0x1da949(0x395)] + _0x1da949(0x1b9)
          );
        _0x466f78 = !![];
      }
      break;
    default:
      return "Invalid\x20rule\x20parameters.";
  }
  if (!_0x466f78)
    return (
      indicatorsFull[indicators["indexOf"](_0x27c6ce[_0x1da949(0x395)])] +
      _0x1da949(0x30e)
    );
  return ![];
}
const HomeRedirect = (_0x5026c1) => {
    const _0x1e5bdf = a0_0xd18718;
    return (
      window[_0x1e5bdf(0x1c7)]["replaceState"](
        _0x1e5bdf(0x3b2),
        _0x1e5bdf(0x2cb),
        "/"
      ),
      window[_0x1e5bdf(0x2d3)][_0x1e5bdf(0x30b)]("https://easycryptobot.com"),
      ""
    );
  },
  ManualRedirect = (_0x7d8433) => {
    const _0x323cb1 = a0_0xd18718;
    return (
      window[_0x323cb1(0x1c7)]["replaceState"](
        _0x323cb1(0x345),
        _0x323cb1(0x2cb),
        "/"
      ),
      window[_0x323cb1(0x2d3)][_0x323cb1(0x30b)](_0x323cb1(0x1d2)),
      ""
    );
  },
  SignUpRedirect = (_0x3fd8a5) => {
    const _0x240d50 = a0_0xd18718;
    return (
      window["history"]["replaceState"](
        _0x240d50(0x170),
        _0x240d50(0x2cb),
        "/"
      ),
      window["location"][_0x240d50(0x30b)]("https://easycryptobot.com/sign-up"),
      ""
    );
  },
  PricingRedirect = (_0x26c87c) => {
    const _0x1eb2bf = a0_0xd18718;
    return (
      window["history"][_0x1eb2bf(0x2ce)](
        "https://app.easycryptobot.com/pricing",
        "Easy\x20Crypto\x20Bot",
        "/"
      ),
      window[_0x1eb2bf(0x2d3)][_0x1eb2bf(0x30b)](_0x1eb2bf(0x377)),
      ""
    );
  },
  DownloadsRedirect = (_0x3f19b9) => {
    const _0x57c207 = a0_0xd18718;
    return (
      window["history"][_0x57c207(0x2ce)](
        _0x57c207(0x32a),
        _0x57c207(0x2cb),
        "/"
      ),
      window[_0x57c207(0x2d3)][_0x57c207(0x30b)](_0x57c207(0x16e)),
      ""
    );
  },
  ContesRedirect = (_0x213b49) => {
    const _0x2d19a6 = a0_0xd18718;
    return (
      window[_0x2d19a6(0x1c7)][_0x2d19a6(0x2ce)](
        "https://app.easycryptobot.com/contest",
        "Easy\x20Crypto\x20Bot",
        "/"
      ),
      window["location"][_0x2d19a6(0x30b)]("https://easycryptobot.com/contest"),
      ""
    );
  };
function getRoutes(
  _0x2bdb28,
  _0x43a20d,
  _0x499352,
  _0x1c2a68,
  _0x143f12,
  _0x4e1a33,
  _0x2c086b
) {
  const _0x426b23 = a0_0xd18718,
    _0x41b37f = [
      {
        path: _0x426b23(0x335),
        name: _0x426b23(0x375),
        Component: HomeRedirect,
      },
      { path: _0x426b23(0x275), name: "Manual", Component: ManualRedirect },
      {
        path: _0x426b23(0x24d),
        name: _0x426b23(0x189),
        Component: PricingRedirect,
      },
      {
        path: _0x426b23(0x34f),
        name: _0x426b23(0x351),
        Component: DownloadsRedirect,
      },
      {
        path: _0x426b23(0x188),
        name: _0x426b23(0x259),
        Component: FreeBacktest,
      },
      { path: _0x426b23(0x1df), name: "Contests", Component: ContesRedirect },
      {
        path: _0x426b23(0x2f7),
        name: _0x426b23(0x313),
        Component: Demo,
        right: !![],
      },
      {
        path: _0x426b23(0x2a5),
        name: "Sign\x20In",
        Component: SignIn,
        right: !![],
        classes: _0x426b23(0x28d),
      },
      { path: "/", name: _0x426b23(0x375), Component: SignIn, skipLink: !![] },
    ],
    _0xa763ee = [
      {
        path: "/",
        name: "Sign\x20In",
        Component: SignIn,
        right: !![],
        classes: _0x426b23(0x28d),
      },
      { path: _0x426b23(0x275), name: _0x426b23(0x277), Component: Manual },
      { path: "/free-backtest", name: "Backtests", Component: FreeBacktest },
    ],
    _0x57be6d = _0x499352["isAdmin"]
      ? [
          { path: "/", name: _0x426b23(0x392), Component: HomeAdmin },
          {
            path: _0x426b23(0x1b5),
            name: _0x426b23(0x2bf),
            Component: BotTrading,
          },
          {
            path: _0x426b23(0x23c),
            name: _0x426b23(0x2f5),
            Component: ReportedBugs,
          },
          {
            path: "/settings",
            name: "Settings",
            Component: Settings,
            right: !![],
          },
        ]
      : _0x4e1a33 && _0x2c086b === _0x426b23(0x394)
      ? [
          { path: "/", name: "Home", Component: TraficSaver },
          { path: "/manual", name: "Manual", Component: Manual, right: !![] },
          {
            path: _0x426b23(0x2d0),
            name: "Contact\x20us",
            Component: Contact,
            right: !![],
          },
          {
            path: "/settings",
            name: _0x426b23(0x221),
            Component: Settings,
            right: !![],
          },
        ]
      : [
          { path: "/", name: _0x426b23(0x235), Component: HomeApp },
          { path: "/bot-trading", name: "BotTrading", Component: BotTrading },
          {
            path: _0x426b23(0x2df),
            name: _0x426b23(0x393),
            Component: Portfolio,
          },
          {
            path: "/contest",
            name: _0x426b23(0x3a3),
            Component: Contest,
            right: !![],
          },
          {
            path: "/manual",
            name: _0x426b23(0x277),
            Component: Manual,
            right: !![],
          },
          {
            path: _0x426b23(0x2d0),
            name: _0x426b23(0x30d),
            Component: Contact,
            right: !![],
          },
          {
            path: "/settings",
            name: _0x426b23(0x221),
            Component: Settings,
            right: !![],
          },
        ],
    _0x4d04c5 = [
      { path: "/", name: _0x426b23(0x2bf), Component: BotTradingSaver },
      { path: "/portfolio", name: _0x426b23(0x393), Component: Portfolio },
      {
        path: "/manual",
        name: _0x426b23(0x277),
        Component: Manual,
        right: !![],
      },
      {
        path: "/contact",
        name: _0x426b23(0x30d),
        Component: Contact,
        right: !![],
      },
      {
        path: _0x426b23(0x278),
        name: _0x426b23(0x221),
        Component: Settings,
        right: !![],
      },
    ],
    _0x4c3760 = _0x499352[_0x426b23(0x1ae)]
      ? [
          { path: "/", name: _0x426b23(0x392), Component: HomeAdmin },
          { path: _0x426b23(0x1b5), name: "BotTrading", Component: BotTrading },
          {
            path: _0x426b23(0x23c),
            name: _0x426b23(0x2f5),
            Component: ReportedBugs,
          },
          {
            path: _0x426b23(0x278),
            name: "Settings",
            Component: Settings,
            right: !![],
          },
        ]
      : _0x143f12
      ? [{ path: "/", name: "", Component: Unlock }]
      : [
          { path: "/", name: _0x426b23(0x235), Component: HomeApp },
          { path: _0x426b23(0x39e), name: "BotTrading", Component: BotTrading },
          {
            path: _0x426b23(0x2df),
            name: _0x426b23(0x393),
            Component: Portfolio,
          },
          {
            path: _0x426b23(0x1df),
            name: "Contests",
            Component: Contest,
            right: !![],
          },
          {
            path: _0x426b23(0x275),
            name: _0x426b23(0x277),
            Component: Manual,
            right: !![],
          },
          {
            path: _0x426b23(0x2d0),
            name: _0x426b23(0x30d),
            Component: Contact,
            right: !![],
          },
          {
            path: _0x426b23(0x278),
            name: "Settings",
            Component: Settings,
            right: !![],
          },
          {
            path: _0x426b23(0x37f),
            name: "Lock",
            Component: Lock,
            right: !![],
            classes: _0x426b23(0x28d),
          },
        ],
    _0x4d81f1 = [
      { path: "/", name: _0x426b23(0x235), Component: HomeApp },
      { path: "/bot-trading", name: _0x426b23(0x2bf), Component: BotTrading },
      { path: "/portfolio", name: _0x426b23(0x393), Component: Portfolio },
      {
        path: _0x426b23(0x275),
        name: "Manual",
        Component: Manual,
        right: !![],
      },
      {
        path: "/settings",
        name: _0x426b23(0x221),
        Component: Settings,
        right: !![],
      },
      {
        path: _0x426b23(0x160),
        name: _0x426b23(0x2e8),
        Component: QuitDemo,
        right: !![],
        classes: _0x426b23(0x16d),
      },
    ];
  let _0x485c13 = [];
  if (_0x1c2a68) _0x485c13 = _0x4d81f1;
  else {
    if (_0x2bdb28)
      _0x43a20d === 0x1 ? (_0x485c13 = _0x4c3760) : (_0x485c13 = _0xa763ee);
    else {
      if (_0x43a20d === 0x1) {
        if (_0x4e1a33)
          switch (_0x2c086b) {
            case _0x426b23(0x394):
              _0x485c13 = _0x57be6d;
              break;
            case _0x426b23(0x246):
              _0x485c13 = _0x57be6d;
              break;
            case "saver":
              _0x485c13 = _0x4d04c5;
              break;
            default:
              _0x485c13 = _0x57be6d;
          }
        else _0x485c13 = _0x57be6d;
      } else _0x485c13 = _0x41b37f;
    }
  }
  return _0x485c13;
}
function addError(_0x3c8c09) {
  const _0x34049a = a0_0xd18718;
  getEcbDatabase()[_0x34049a(0x23a)](_0x3c8c09);
}
function isVisibleById(_0x5af66a) {
  const _0x12ff47 = a0_0xd18718;
  let _0x266b3b = window[_0x12ff47(0x2db)][_0x12ff47(0x30c)](_0x5af66a);
  return _0x266b3b
    ? window[_0x12ff47(0x316)](_0x266b3b)[_0x12ff47(0x3c4)] !== _0x12ff47(0x34d)
    : ![];
}
function checkWorkerOk(_0x367c68, _0x47b7e2) {
  return new Promise((_0x1739c) => {
    const _0x28f2f9 = a0_0x1f43;
    (_0x367c68[_0x47b7e2][_0x28f2f9(0x2a2)] = (_0x2d1844) => {
      _0x1739c(![]);
    }),
      (_0x367c68[_0x47b7e2]["onmessage"] = (_0x4deb63) => {
        _0x1739c(!![]);
      }),
      _0x367c68[_0x47b7e2]["postMessage"]({ cmd: "OK" });
  });
}
function a0_0x1f43(_0x95e47c, _0x45d397) {
  const _0x4e276e = a0_0x4e27();
  return (
    (a0_0x1f43 = function (_0x1f437e, _0x3771d0) {
      _0x1f437e = _0x1f437e - 0x153;
      let _0x422b78 = _0x4e276e[_0x1f437e];
      return _0x422b78;
    }),
    a0_0x1f43(_0x95e47c, _0x45d397)
  );
}
async function initializeWorker(_0x5d82d6, _0x341aca, _0x3ae78a) {
  const _0x37d11b = a0_0xd18718;
  let _0x29a8e2 = ![],
    _0x4c75ab =
      window[_0x37d11b(0x2d3)]["protocol"] +
      "//" +
      window[_0x37d11b(0x2d3)][_0x37d11b(0x1b1)];
  for (let _0x3f02b = 0x0; _0x3f02b < 0xa; _0x3f02b++) {
    (_0x5d82d6[_0x341aca] = new Worker(_0x4c75ab + "/" + _0x3ae78a)),
      (_0x29a8e2 = await checkWorkerOk(_0x5d82d6, _0x341aca));
    if (_0x29a8e2) break;
    await sleep(0x1f4);
  }
  return _0x29a8e2;
}
function isOnMobile() {
  const _0x3d3ecb = a0_0xd18718;
  let _0x18bb27 =
    navigator[_0x3d3ecb(0x1e5)] ||
    navigator["vendor"] ||
    window[_0x3d3ecb(0x2cf)];
  return (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i[
      _0x3d3ecb(0x1fc)
    ](_0x18bb27) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i[
      _0x3d3ecb(0x1fc)
    ](_0x18bb27[_0x3d3ecb(0x242)](0x0, 0x4))
  );
}
function a0_0x4e27() {
  const _0x3c77b7 = [
    "errorLevel",
    "target",
    "xAxis",
    "deleteSimStrategyTrades",
    "&user=yes",
    "#ffd1d1",
    "simToSell",
    "setDate",
    "Length",
    "15953080itNsBf",
    "%K-",
    "#28a745",
    "baseDecimal",
    "conditionPair",
    "in\x20downtrend",
    "getLiveStrategyStatus",
    "quotedDecimal",
    "https://app.easycryptobot.com/download-app",
    "deleteSimStrategyEvents",
    "https://api2.easycryptobot.com/login.php",
    "condition",
    "AES",
    "pairDetails",
    "low\x20to\x20high",
    "Missing\x20name\x20for\x20the\x20strategy.",
    "-current",
    "release",
    "#9a36f7",
    "/home",
    "watchlist",
    "#33d458",
    "bar",
    "selectedContestWinners",
    "getSimStrategyDetails",
    "getDailyPLLive",
    "delete",
    "getLiveStrategyTrades",
    "USDT",
    "originalUserId",
    "positives",
    "Lower-Bollinger",
    "https://api2.easycryptobot.com/admin/strategy-status.php?userId=",
    "The\x20price\x20rule\x20has\x20a\x20missing\x20level\x20value.",
    "The\x20target\x20must\x20be\x20greater\x20than\x200%.",
    "https://app.easycryptobot.com/manual",
    "direction2",
    "Lower-KC-MomentumSqueeze",
    "#ff00d4",
    "length",
    "storeMultiChart",
    "removedEventsSimIds",
    "\x20and\x20",
    "none",
    "below",
    "/download-app",
    "https://api2.easycryptobot.com/strategies.php",
    "Apps",
    "Manual\x20order",
    "BTCUSDT",
    "Slow\x20Length",
    "max",
    "color0",
    "ECB512-",
    "Apr",
    "Aug",
    "pattern",
    "close",
    "graphic",
    "deleteKey",
    "https://api2.easycryptobot.com/admin/strategy.php?userId=",
    "baseMinQty",
    "key",
    "#f2ef38",
    "MomentumSqueeze",
    "trailingTargetAt",
    "getLiveStrategyStatuses",
    "getFullYear",
    "https://api2.easycryptobot.com/admin/users.php",
    "Binance",
    "min",
    "condition4",
    "allLive",
    "down",
    "cumulatives",
    "condition5",
    "base",
    "getHours",
    "getLiveStrategyEvents",
    "2150346XvnGAV",
    "optDate",
    "period3",
    "verifyApiKey",
    "Home",
    "connectNulls",
    "https://easycryptobot.com/pricing",
    "1\x20hour",
    "\x20has\x20a\x20missing\x20percent\x20value.",
    "trailingTarget",
    "allSim",
    "%D-Stochastic",
    "Keltner",
    "demo",
    "/lock",
    "Feb",
    "70857nptjpp",
    "Upper-Bollinger",
    "19834188DBhSqm",
    "https://api2.easycryptobot.com/watchlists.php",
    "token",
    "storeIndicators",
    "creationDate",
    "#ab0b00",
    "shift",
    "getSimStrategyStatus",
    "Choose\x20a\x20valid\x20pair.",
    "Utf8",
    "deleteSimStrategyStatus",
    "series",
    "remoteStrategyStop",
    "tBuyWait",
    "deleteLiveStrategyEvents",
    "Users",
    "Portfolio",
    "notChoosen",
    "indicator",
    "storeOldStrategy",
    "Signal",
    "type",
    "87265216",
    "chartData",
    "symbolSize",
    "KC\x20Multiplier",
    "lock",
    "/bot-trading",
    "mins",
    "getSeconds",
    "deleteOldStrategy",
    "sId",
    "Contests",
    "editDate",
    "axisCount",
    "MACD\x20Fast\x20length\x20must\x20be\x20smaller\x20then\x20the\x20Slow\x20length.",
    "markLine",
    "color",
    "Upper-",
    "openStatus",
    "replace",
    "toggle",
    "period",
    "https://api2.easycryptobot.com/strategy-status.php?type=",
    "The\x20price\x20rule\x20has\x20a\x20missing\x20percent\x20value.",
    "rising",
    "98QydXwL",
    "https://app.easycryptobot.com/home",
    "findIndex",
    "modal",
    "liveToStop",
    "USD",
    "removedSimDetailsIds",
    "getMinutes",
    "#000",
    "groupsRemovedNotSynced",
    "BNB",
    "getSimStrategyDetailses",
    "multiChartRemove",
    "tickSize",
    "#dc3545",
    "ERROR",
    "5\x20minutes",
    "MACD",
    "\x20in\x20The\x20price\x20rule\x20does\x20not\x20have\x20a\x20specified\x20band.",
    "display",
    "closeStatus",
    "indicator2",
    "#infoDialog",
    "%K\x20Length",
    "strategyGroups",
    "market",
    "https://api2.easycryptobot.com/admin/strategy-details.php?userId=",
    "The\x20RSI\x20has\x20a\x20missing\x20option.",
    "The\x20price\x20rule\x20has\x20a\x20missing\x20direction.",
    "opacity",
    "&p=",
    "current",
    "in\x20uptrend",
    "Bollinger\x20Bands",
    "sort",
    "The\x20price\x20rule\x20has\x20a\x20missing\x20cross\x20direction.",
    "autoAppSync\x20error:\x20",
    "/quit-demo",
    "bBuyDateExpire",
    "circle",
    "removedLiveStatusesIds",
    "map",
    "getExecutions",
    "\x20is\x20invalid.\x20",
    "line",
    "getTime",
    "startsWith",
    "split",
    "serverVersion",
    "Momentum",
    "bg-info\x20text-white\x20rounded",
    "https://easycryptobot.com/download-app",
    "getStrategies",
    "https://app.easycryptobot.com/sign-up",
    "toString",
    "forEach",
    "\x20has\x20a\x20missing\x20pattern.",
    "runLive",
    "simStatuses",
    "endsWith",
    "strategyLiveStatusesNotSync",
    "15m",
    "&strategyId=",
    "pair",
    "strategyNotSync",
    "tradeStdDev",
    ":visible",
    "#343a40",
    "Histogram",
    "https://api2.easycryptobot.com/contest-executions.php?m=",
    "storeLiveStrategyStatus",
    "simToStop",
    "deleteLiveStrategyTrades",
    "live",
    "getWatchlists",
    "Closed",
    "getDailyPLSims",
    "/free-backtest",
    "Pricing",
    "secret",
    "markPoint",
    "returns",
    "multiChartsRemovedNotSynced",
    "liveStatuses",
    "userId",
    "\x20has\x20an\x20incorrect\x20\x22between\x22\x20range.",
    "saved",
    "reversing",
    "mode",
    "error",
    "https://api2.easycryptobot.com/indicators.php",
    "candleChart",
    "liveDetails",
    "subscribeToBalanceUpdate",
    "getSimStrategyStatuses",
    "https://api2.easycryptobot.com/strategy-events.php",
    "sellRules",
    "label",
    "getStrategyGroups",
    "getItem",
    "removeItem",
    "BB\x20Length",
    "Nov",
    "width",
    "abs",
    "getLiveStrategyDetailses",
    "tradesLiveNotSync",
    "Choose\x20a\x20condition\x20valid\x20pair.",
    "storeSimStrategyTrade",
    "crossing",
    "https://api2.easycryptobot.com/app.php",
    "resolve",
    "\x20has\x20a\x20missing\x20cross\x20direction.",
    "maValue",
    "https://api2.easycryptobot.com/admin/strategy-events.php?userId=",
    "isAdmin",
    "BTC",
    "\x20has\x20incorrect\x20between\x20percent\x20values.",
    "host",
    "ECB512",
    "https://api2.easycryptobot.com/limits.php",
    "timeCloseAt",
    "/user",
    "https://api2.easycryptobot.com/strategy-data-saver.php",
    "limits",
    "Lower-",
    "\x20has\x20a\x20missing\x20option.",
    "tradeSize",
    "strategySimDetailsNotSync",
    "getSimStrategyTrades",
    "Momentum\x20Squeeze",
    "An\x20indicator\x20in\x20The\x20price\x20rule\x20has\x20a\x20missing\x20setting.",
    "conditionValue",
    "The\x20time\x20close\x20must\x20be\x20a\x20positive\x20number.",
    "deleteWatchlist",
    "subscriptionExpireDate",
    "setHours",
    "includes",
    "FILLED_WITH_LEFTOVER",
    "errr",
    "history",
    "trim",
    "condition2",
    "priceAction",
    "BNBUSDT",
    "storeLiveStrategyDetails",
    "infoDialog",
    "the\x20price",
    "\x20has\x20a\x20missing\x20direction.",
    "Binance\x20US",
    "Dec",
    "https://easycryptobot.com/manual",
    "strategy",
    "starting",
    "#0a67fc",
    "maxLoss",
    "trades",
    "standard",
    "Currently,\x20you\x20cannot\x20set\x20a\x20Sell\x20rule\x20while\x20having\x20more\x20than\x201\x20timeframes\x20in\x20the\x20strategy.\x20Remove\x20the\x20Sell\x20rule\x20or\x20the\x20second\x20timeframe.\x20We\x20are\x20working\x20to\x20fix\x20this.",
    "RSI",
    "confirmationCandle",
    "Choose\x20up\x20to\x202\x20timeframes.",
    "removedStrategiesIds",
    "Jun",
    "/contest",
    "May",
    "removedEventsLiveIds",
    "prices",
    "getOldStrategies",
    "baseMaxQty",
    "userAgent",
    "Bearer\x20",
    "watchlists",
    "commissionRate",
    "enc",
    "value2",
    "Choose\x20a\x20condition\x20percent\x20move.",
    "conditionDirection",
    "Add\x20at\x20least\x20one\x20BUY\x20rule.",
    "The\x20price\x20rule\x20has\x20a\x20missing\x20setting.",
    "-close",
    "setMinutes",
    "executions",
    "https://api2.easycryptobot.com/errors.php",
    "stringify",
    "&type=",
    "#107281",
    "#59ac59",
    "direction",
    "FILLED",
    "avTrade",
    "number",
    "#ac5959",
    "test",
    "ERROR\x20333:",
    "post",
    "result",
    "users",
    "quoteAsset",
    "https://api2.easycryptobot.com/admin/strategy-trades.php?userId=",
    "bbValue",
    "liveStatus",
    "user",
    "events",
    "Keltner\x20Channel",
    "removedTradesLiveIds",
    "runSim",
    "30m",
    "Jan",
    "find",
    "getLiveStrategyTrade",
    "groupsNotSynced",
    "falling",
    "Std.\x20Dev.",
    "floor",
    "1\x20minute",
    "application/json",
    "app",
    "baseStepSize",
    "hour",
    "error\x20in\x20getUserData\x20",
    "Stochastic\x20RSI",
    "Stochastic",
    "multiChartsNotSynced",
    "deleteStrategyGroup",
    "https://api2.easycryptobot.com/strategy-trades.php",
    "30\x20minutes",
    "tradesCount",
    "activeTrade",
    "Choose\x20an\x20exchange.",
    "Settings",
    "stoploss",
    "watchlistsNotSynced",
    "selectedContestRanking",
    "encrypt",
    "filter",
    "the\x20candlestick",
    "getMultiCharts",
    "autoSync\x20error:\x20",
    "removedTradesSimIds",
    "maValue2",
    "decimal",
    "name",
    "deleteSimStrategyDetails",
    "getDate",
    "https://api2.easycryptobot.com/strategy-trades.php?type=",
    "getLiveStrategyDetails",
    "autoSyncErrors\x20error:\x20",
    "1\x20day",
    "formula",
    "Trading",
    "Loading",
    "distributions",
    "version",
    "https://api2.easycryptobot.com/version.php?v=",
    "storeError",
    "toFixed",
    "/bugs",
    "Smooth",
    "getSimStrategyTrade",
    "strategies",
    "1083312JgyESy",
    "indIndex",
    "substr",
    "strategyGroupRemove",
    "indexOf",
    "ERROR\x20331:",
    "normal",
    "crossDirection",
    "indDataId",
    "dCr",
    "EMA",
    "liveToSell",
    "failedIds",
    "/pricing",
    "storeSimStrategyDetails",
    "condition6",
    "lineStyle",
    "ETHUSDT",
    "Lower-BB-MomentumSqueeze",
    "indicatorsNotSynced",
    "between",
    "ETH",
    "getStrategy",
    "tradesDates",
    "NEW",
    "Backtests",
    "storeKey",
    "The\x20stoploss\x20must\x20be\x20greater\x20than\x200%.",
    "targetAt",
    "1\x20week",
    "reduce",
    "maxDrawdown",
    "trading",
    "getIndicators",
    "pls",
    "openId",
    "deleteLiveStrategyStatus",
    "strategyLiveDetailsNotSync",
    "push",
    "LinearGradient",
    "Choose\x20a\x20condition\x20direction.",
    "details",
    "simulation",
    "logouted",
    "storeLiveStrategyTrade",
    "getPairs",
    "now",
    "5811320qYGTHk",
    "Buy",
    "https://api2.easycryptobot.com/strategy-events.php?type=",
    "The\x20",
    "less\x20than",
    "1\x20month",
    "/manual",
    "getSimStrategyEvents",
    "Manual",
    "/settings",
    "Multiplier",
    "storeStrategy",
    "maxNegTrades",
    "splice",
    "nickname",
    "tBuy",
    "PARTIALLY_FILLED",
    "%K-Stochastic",
    "log",
    "liveoStop",
    "SMA",
    "%D-",
    "&t=",
    "The\x20price\x20rule\x20has\x20a\x20missing\x20or\x20invalid\x20number\x20of\x20candles.\x20Valid:\x201\x20to\x20100.",
    "-Histogram",
    "parseFloat",
    "negatives",
    "multiChart",
    "getMonth",
    "storeLiveStrategyTrades",
    "text-info",
    "Error",
    "bBuyLow",
    "&id=",
    "watchlistsRemovedNotSynced",
    "period4",
    "https://api2.easycryptobot.com/contests.php",
    "timeClose",
    "https://api2.easycryptobot.com/multicharts.php",
    "showStrategy",
    "kcValue",
    "https://api2.easycryptobot.com/daily-pls.php",
    "#ff5959",
    "subscriptionType",
    "https://api2.easycryptobot.com/strategy-status.php",
    "storeDailyPLSims",
    "tbd",
    "A\x20trailing\x20buy\x20wait\x20time\x20is\x20required\x20when\x20trailing\x20buy\x20percent\x20is\x20present.",
    "band",
    "deleteMultiChart",
    "stoplossAt",
    "onerror",
    "setItem",
    "#confirmDialog",
    "/sign-in",
    "%D\x20line",
    "4\x20hours",
    "&sId=",
    "Running",
    "getKeys",
    "manualBuyAt",
    "data",
    "sim",
    "BUSD",
    "above",
    "#17a2b8",
    "deleteStrategy",
    "storeSimStrategyEvents",
    "#00fbff",
    "period2",
    "external",
    "yAxis",
    "orderDetails",
    "storeLiveStrategyEvents",
    "nextTrade",
    "Add\x20either\x20a\x20SELL\x20rule,\x20a\x20stoploss,\x20a\x20target\x20or\x20a\x20time-close.",
    "maxPrice",
    "#ff483b",
    "updated",
    "5aPSVjr",
    "BotTrading",
    "fromCharCode",
    "The\x20trailing\x20target\x20must\x20be\x20greater\x20than\x200%.",
    "strategyGroup",
    "Stopped",
    "resultInQuoted",
    "Sep",
    "tradesSimNotSync",
    "opt",
    "greater\x20than",
    "deleteLiveStrategyDetails",
    "15\x20minutes",
    "Easy\x20Crypto\x20Bot",
    "multiCharts",
    "greater",
    "replaceState",
    "opera",
    "/contact",
    "autoUpdateExecutionStatus\x20error:\x20",
    "string",
    "location",
    "condition1",
    "assets",
    "exchange",
    "parse",
    "0_00",
    "removedLiveDetailsIds",
    "upper\x20shadow",
    "document",
    "storeLiveStrategyEvent",
    "rsiValue",
    "resetPairs",
    "/portfolio",
    "strategySimStatusesNotSync",
    "https://api2.easycryptobot.com/strategy-details.php",
    "lastTrade",
    "https://api2.easycryptobot.com/strategy.php",
    "120ceeibr",
    "strategyId",
    "exchanges",
    "storeDailyPLLive",
    "Quit\x20Demo",
    "status",
    "12h",
    "Default\x20List",
    "buyRules",
    "MomentumSqueeze-Status",
    "conditionsOrder",
    "%\x20to\x20",
    "storeSimStrategyStatus",
    "timeframe",
    "20KnGeYz",
    "#1c7831",
    "watchlistRemove",
    "Reports",
    "simStatus",
    "/demo",
    "12\x20hours",
    "Fast\x20Length",
    "getPrices",
    "value",
    "confirmDialog",
    "\x20to\x20",
    "run",
    "<1m",
    "https://api2.easycryptobot.com/strategy-groups.php",
    "keys",
    "3GkNBtG",
    "WEBSOCKET\x20CLOSED",
    "stoValue",
    "The\x20price\x20rule\x20has\x20a\x20missing\x20cross\x20indicator.",
    "hours",
    "#fc903d",
    "get",
    "manualSellAt",
    "storeStrategyGroup",
    "assign",
    "getElementById",
    "Contact\x20us",
    "\x20does\x20not\x20have\x20a\x20condition.",
    "removedSimStatusesIds",
    "72104AgEltu",
    "dashed",
    "\x20consecutive\x20value\x20shouls\x20be\x20between\x202\x20and\x20100.",
    "Demo",
    "Valid:\x20",
    "#fff",
    "getComputedStyle",
    "dates",
    "condition3",
  ];
  a0_0x4e27 = function () {
    return _0x3c77b7;
  };
  return a0_0x4e27();
}
function getFromLocalStorage(_0x540540, _0x26720e, _0x3dd4f2, _0x90d1ef) {
  const _0x1bc197 = a0_0xd18718;
  let _0x3e2d8c = localStorage[_0x1bc197(0x19e)](_0x540540);
  if (_0x3e2d8c)
    switch (_0x3dd4f2) {
      case _0x1bc197(0x1fa):
      case _0x1bc197(0x22c):
        _0x3e2d8c = +_0x3e2d8c;
        return isNaN(_0x3e2d8c) ? _0x26720e : _0x3e2d8c;
      default:
        return _0x3e2d8c;
    }
  else {
    if (_0x3e2d8c === "" && _0x90d1ef) return _0x3e2d8c;
  }
  return _0x26720e;
}
function getIndicatorSettings(_0x77560d, _0x310e93) {
  const _0x420d3b = a0_0xd18718;
  switch (_0x310e93) {
    case 0x1:
      switch (_0x77560d) {
        case _0x420d3b(0x283):
        case _0x420d3b(0x24a):
          return {
            label: _0x420d3b(0x321),
            min: 0x2,
            max: 0x12c,
            decimal: ![],
          };
        case "RSI":
          return { label: _0x420d3b(0x321), min: 0x2, max: 0x64, decimal: ![] };
        case _0x420d3b(0x3c2):
          return {
            label: _0x420d3b(0x2f9),
            min: 0x2,
            max: 0x12c,
            decimal: ![],
          };
        case _0x420d3b(0x15c):
        case "Keltner\x20Channel":
          return {
            label: _0x420d3b(0x321),
            min: 0x2,
            max: 0x12c,
            decimal: ![],
          };
        case _0x420d3b(0x219):
        case _0x420d3b(0x218):
          return { label: _0x420d3b(0x3c8), min: 0x2, max: 0x64, decimal: ![] };
        case _0x420d3b(0x16c):
          return {
            label: _0x420d3b(0x321),
            min: 0x2,
            max: 0x12c,
            decimal: ![],
          };
        case "Momentum\x20Squeeze":
          return {
            label: _0x420d3b(0x1a0),
            min: 0x2,
            max: 0x12c,
            decimal: ![],
          };
        default:
          return null;
      }
    case 0x2:
      switch (_0x77560d) {
        case _0x420d3b(0x283):
        case _0x420d3b(0x24a):
        case "RSI":
          return null;
        case _0x420d3b(0x3c2):
          return {
            label: _0x420d3b(0x354),
            min: 0x2,
            max: 0x12c,
            decimal: ![],
          };
        case _0x420d3b(0x15c):
          return {
            label: _0x420d3b(0x210),
            min: 0.2,
            max: 0x14,
            decimal: !![],
          };
        case "Keltner\x20Channel":
          return {
            label: _0x420d3b(0x279),
            min: 0.2,
            max: 0x14,
            decimal: !![],
          };
        case _0x420d3b(0x219):
        case _0x420d3b(0x218):
          return { label: "%D\x20Length", min: 0x2, max: 0x64, decimal: ![] };
        case "Momentum":
          return null;
        case "Momentum\x20Squeeze":
          return {
            label: "BB\x20Std.\x20Dev.",
            min: 0.2,
            max: 0x14,
            decimal: !![],
          };
        default:
          return null;
      }
    case 0x3:
      switch (_0x77560d) {
        case _0x420d3b(0x283):
        case "EMA":
        case _0x420d3b(0x1da):
          return null;
        case _0x420d3b(0x3c2):
          return {
            label: "Signal\x20Length",
            min: 0x2,
            max: 0x64,
            decimal: ![],
          };
        case _0x420d3b(0x15c):
        case _0x420d3b(0x207):
          return null;
        case "Stochastic":
        case _0x420d3b(0x218):
          return { label: _0x420d3b(0x23d), min: 0x1, max: 0x64, decimal: ![] };
        case _0x420d3b(0x16c):
          return null;
        case "Momentum\x20Squeeze":
          return { label: "KC\x20Length", min: 0x2, max: 0x12c, decimal: ![] };
        default:
          return null;
      }
    case 0x4:
      switch (_0x77560d) {
        case "SMA":
        case _0x420d3b(0x24a):
        case _0x420d3b(0x1da):
        case "MACD":
        case _0x420d3b(0x15c):
        case "Keltner\x20Channel":
        case _0x420d3b(0x219):
          return null;
        case _0x420d3b(0x218):
          return { label: "RSI\x20Length", min: 0x2, max: 0x64, decimal: ![] };
        case _0x420d3b(0x16c):
          return null;
        case "Momentum\x20Squeeze":
          return {
            label: _0x420d3b(0x39c),
            min: 0.2,
            max: 0x14,
            decimal: !![],
          };
        default:
          return null;
      }
    case 0x5:
      switch (_0x77560d) {
        case _0x420d3b(0x283):
        case _0x420d3b(0x24a):
        case "RSI":
        case _0x420d3b(0x3c2):
        case "Bollinger\x20Bands":
        case "Keltner\x20Channel":
        case _0x420d3b(0x219):
        case _0x420d3b(0x218):
          return null;
        case _0x420d3b(0x16c):
        case "Momentum\x20Squeeze":
          return { label: "Formula" };
        default:
          return null;
      }
    default:
      return null;
  }
}
function checkInvalidIndicatorSetting(_0x1d4c4b, _0x281ca4, _0x242ff7) {
  const _0x55cc9f = a0_0xd18718;
  let _0x1c3a8c = getIndicatorSettings(_0x1d4c4b, _0x281ca4);
  if (!_0x1c3a8c) return ![];
  return _0x1c3a8c[_0x55cc9f(0x368)] > _0x242ff7 || _0x242ff7 > _0x1c3a8c["max"]
    ? _0x55cc9f(0x314) +
        _0x1c3a8c[_0x55cc9f(0x368)] +
        _0x55cc9f(0x2fd) +
        _0x1c3a8c[_0x55cc9f(0x355)]
    : ![];
}
function checkInvalidIndicatorSettingsAll(_0x25ae84) {
  const _0x58f964 = a0_0xd18718;
  if (_0x25ae84[_0x58f964(0x395)] === indicators[0x0])
    return "Indicator\x20not\x20selected.";
  for (let _0x2d8270 = 0x1; _0x2d8270 <= 0x4; _0x2d8270++) {
    let _0x5007cf = checkInvalidIndicatorSetting(
      _0x25ae84[_0x58f964(0x395)],
      _0x2d8270,
      _0x25ae84[_0x58f964(0x3ad) + (_0x2d8270 > 0x1 ? _0x2d8270 : "")]
    );
    if (_0x5007cf) {
      let _0x185904 = getIndicatorSettings(
        _0x25ae84[_0x58f964(0x395)],
        _0x2d8270
      );
      return (
        _0x25ae84["indicator"] +
        "\x20" +
        _0x185904[_0x58f964(0x19c)] +
        _0x58f964(0x166) +
        _0x5007cf +
        "."
      );
    }
  }
  if (_0x25ae84[_0x58f964(0x395)] === _0x58f964(0x3c2)) {
    if (_0x25ae84[_0x58f964(0x3ad)] > _0x25ae84["period2"])
      return _0x58f964(0x3a6);
  }
  return ![];
}
function getIndicatorSetup(_0x36bff2) {
  const _0xcd26d2 = a0_0xd18718;
  let _0x597ad4 = "";
  if (
    _0x36bff2[_0xcd26d2(0x395)] === _0xcd26d2(0x1ce) &&
    _0x36bff2[_0xcd26d2(0x1c9)]
  )
    switch (_0x36bff2[_0xcd26d2(0x1c9)]["indicator"]) {
      case _0xcd26d2(0x283):
      case "EMA":
        _0x597ad4 = "(" + _0x36bff2[_0xcd26d2(0x1c9)][_0xcd26d2(0x3ad)] + ")";
        break;
      case "Bollinger\x20Bands":
      case _0xcd26d2(0x207):
        _0x597ad4 =
          "(" +
          _0x36bff2[_0xcd26d2(0x1c9)][_0xcd26d2(0x3ad)] +
          ",\x20" +
          _0x36bff2[_0xcd26d2(0x1c9)]["period2"] +
          ")";
        break;
      default:
    }
  else
    switch (_0x36bff2[_0xcd26d2(0x395)]) {
      case _0xcd26d2(0x283):
      case _0xcd26d2(0x24a):
        _0x597ad4 = "(" + _0x36bff2[_0xcd26d2(0x3ad)] + ")";
        break;
      case _0xcd26d2(0x1da):
        _0x597ad4 = "(" + _0x36bff2[_0xcd26d2(0x3ad)] + ")";
        break;
      case "Stochastic":
        _0x597ad4 =
          "(" +
          _0x36bff2["period"] +
          ",\x20" +
          _0x36bff2[_0xcd26d2(0x2b4)] +
          ",\x20" +
          _0x36bff2[_0xcd26d2(0x373)] +
          ")";
        break;
      case _0xcd26d2(0x218):
        _0x597ad4 =
          "(" +
          _0x36bff2[_0xcd26d2(0x3ad)] +
          ",\x20" +
          _0x36bff2["period2"] +
          ",\x20" +
          _0x36bff2[_0xcd26d2(0x373)] +
          ",\x20" +
          _0x36bff2[_0xcd26d2(0x292)] +
          ")";
        break;
      case _0xcd26d2(0x15c):
      case _0xcd26d2(0x207):
        _0x597ad4 =
          "(" +
          _0x36bff2["period"] +
          ",\x20" +
          _0x36bff2[_0xcd26d2(0x2b4)] +
          ")";
        break;
      case "MACD":
        _0x597ad4 =
          "(" +
          _0x36bff2[_0xcd26d2(0x3ad)] +
          ",\x20" +
          _0x36bff2[_0xcd26d2(0x2b4)] +
          ",\x20" +
          _0x36bff2["period3"] +
          ")";
        break;
      case _0xcd26d2(0x16c):
        _0x597ad4 =
          "(" +
          _0x36bff2[_0xcd26d2(0x3ad)] +
          ",\x20" +
          _0x36bff2["formula"] +
          ")";
        break;
      case "Momentum\x20Squeeze":
        _0x597ad4 =
          "(" +
          _0x36bff2[_0xcd26d2(0x3ad)] +
          ",\x20" +
          _0x36bff2["period2"] +
          ",\x20" +
          _0x36bff2["period3"] +
          ",\x20" +
          _0x36bff2[_0xcd26d2(0x292)] +
          ",\x20" +
          _0x36bff2[_0xcd26d2(0x234)] +
          ")";
        break;
      default:
    }
  return _0x597ad4;
}
function attachCumulativesData(_0x4a2f57, _0x56aaf7, _0x1fedf3) {
  const _0x27fca1 = a0_0xd18718;
  _0x4a2f57[_0x27fca1(0x36c)] = { dates: [], prices: [], returns: [] };
  let _0x59216e = 0x0,
    _0x17f2f1 = 0x0;
  _0x56aaf7[_0x27fca1(0x172)]((_0x25630b) => {
    const _0x4f5f2f = _0x27fca1;
    if (ensureDate(_0x25630b["d"]) < ensureDate(_0x1fedf3)) return;
    _0x4a2f57["cumulatives"][_0x4f5f2f(0x317)][_0x4f5f2f(0x266)](
      formatDateTime(_0x25630b["d"])
    ),
      _0x4a2f57[_0x4f5f2f(0x36c)][_0x4f5f2f(0x1e2)][_0x4f5f2f(0x266)](
        _0x25630b["c"]
      );
    while (_0x17f2f1 < _0x4a2f57[_0x4f5f2f(0x1d7)][_0x4f5f2f(0x349)]) {
      let _0x51d4be = _0x4a2f57[_0x4f5f2f(0x1d7)][_0x17f2f1];
      if (
        !_0x51d4be["cd"] ||
        ensureDate(_0x51d4be["cd"]) > ensureDate(_0x25630b["cd"])
      )
        break;
      (_0x59216e += _0x51d4be[_0x4f5f2f(0x1ff)]), _0x17f2f1++;
    }
    _0x4a2f57[_0x4f5f2f(0x36c)][_0x4f5f2f(0x18c)]["push"](
      _0x59216e[_0x4f5f2f(0x23b)](0x2)
    );
  });
}
function attachDistributionsData(_0x5aad26) {
  const _0x19bba2 = a0_0xd18718;
  _0x5aad26[_0x19bba2(0x237)] = { xAxis: [], yAxis: [] };
  let _0x3fee1e =
      _0x5aad26[_0x19bba2(0x1f9)] -
      Math[_0x19bba2(0x1a3)](_0x5aad26[_0x19bba2(0x17c)]),
    _0x3f30fd =
      _0x5aad26[_0x19bba2(0x1f9)] +
      Math[_0x19bba2(0x1a3)](_0x5aad26[_0x19bba2(0x17c)]),
    _0x21ee49 = 0x14,
    _0x3798fd = (_0x3f30fd - _0x3fee1e) / _0x21ee49,
    _0x58267f = [
      0.1, 0.2, 0.5, 0x1, 1.5, 0x2, 2.5, 0x3, 3.5, 0x4, 4.5, 0x6, 0x7, 0x8, 0x9,
      0xa, 0xc, 0xf, 0x14, 0x32, 0x64,
    ];
  for (let _0x314ed4 of _0x58267f) {
    if (_0x3798fd <= _0x314ed4) {
      _0x3798fd = _0x314ed4;
      break;
    }
  }
  let _0x591381 = _0x5aad26[_0x19bba2(0x1f9)];
  for (let _0x23ea8a of _0x58267f) {
    if (_0x591381 <= _0x23ea8a) {
      _0x591381 = _0x23ea8a;
      break;
    }
  }
  let _0x349353 = [];
  (_0x5aad26[_0x19bba2(0x237)]["xAxis"] = [
    "",
    "<" + (_0x591381 - _0x3798fd * (_0x21ee49 / 0x2))["toFixed"](0x1) + "%",
  ]),
    (_0x5aad26["distributions"][_0x19bba2(0x2b6)] = [null, 0x0]);
  for (let _0x2cab96 = _0x21ee49 / 0x2; _0x2cab96 > 0x0; _0x2cab96--) {
    let _0x31a0c7 = fixNumber(_0x591381 - _0x3798fd * _0x2cab96, 0x1);
    _0x349353[_0x19bba2(0x266)](_0x31a0c7),
      _0x5aad26["distributions"][_0x19bba2(0x2b6)][_0x19bba2(0x266)](0x0),
      _0x5aad26["distributions"][_0x19bba2(0x31b)][_0x19bba2(0x266)](
        _0x31a0c7["toFixed"](0x1) +
          _0x19bba2(0x2ef) +
          (_0x591381 - _0x3798fd * (_0x2cab96 - 0x1))[_0x19bba2(0x23b)](0x1) +
          "%"
      );
  }
  for (let _0xe2b182 = 0x0; _0xe2b182 < _0x21ee49 / 0x2 - 0x1; _0xe2b182++) {
    let _0x43bd65 = fixNumber(_0x591381 + _0x3798fd * _0xe2b182, 0x1);
    _0x349353[_0x19bba2(0x266)](_0x43bd65),
      _0x5aad26[_0x19bba2(0x237)][_0x19bba2(0x2b6)][_0x19bba2(0x266)](0x0),
      _0x5aad26[_0x19bba2(0x237)]["xAxis"]["push"](
        _0x43bd65[_0x19bba2(0x23b)](0x1) +
          "%\x20to\x20" +
          (_0x591381 + _0x3798fd * (_0xe2b182 + 0x1))["toFixed"](0x1) +
          "%"
      );
  }
  _0x5aad26[_0x19bba2(0x237)][_0x19bba2(0x31b)][_0x19bba2(0x266)](
    ">" +
      (_0x591381 + _0x3798fd * (_0x21ee49 / 0x2 - 0x1))["toFixed"](0x1) +
      "%"
  ),
    _0x5aad26[_0x19bba2(0x237)][_0x19bba2(0x2b6)][_0x19bba2(0x266)](0x0),
    _0x5aad26[_0x19bba2(0x237)]["xAxis"]["push"](""),
    _0x5aad26[_0x19bba2(0x237)][_0x19bba2(0x2b6)][_0x19bba2(0x266)](null),
    _0x5aad26[_0x19bba2(0x1d7)][_0x19bba2(0x172)]((_0x30bdf1) => {
      const _0x216091 = _0x19bba2;
      if (_0x30bdf1[_0x216091(0x1ff)]) {
        for (
          let _0x74dc8a = 0x0;
          _0x74dc8a < _0x349353[_0x216091(0x349)];
          _0x74dc8a++
        ) {
          if (_0x30bdf1["result"] < _0x349353[_0x74dc8a]) {
            _0x5aad26[_0x216091(0x237)][_0x216091(0x2b6)][_0x74dc8a + 0x1]++;
            break;
          }
        }
        _0x30bdf1[_0x216091(0x1ff)] >=
          _0x349353[_0x349353[_0x216091(0x349)] - 0x1] &&
          _0x5aad26[_0x216091(0x237)]["yAxis"][
            _0x5aad26[_0x216091(0x237)][_0x216091(0x2b6)]["length"] - 0x2
          ]++;
      }
    });
}
function attachTradesData(_0x5090ac) {
  const _0x414042 = a0_0xd18718;
  (_0x5090ac[_0x414042(0x39a)] = {
    trades: {
      xAxis: [],
      data: [],
      positives: [],
      negatives: [],
      min: 0x0,
      max: 0x0,
    },
  }),
    _0x5090ac["chartData"][_0x414042(0x1d7)][_0x414042(0x31b)][
      _0x414042(0x266)
    ](""),
    _0x5090ac[_0x414042(0x39a)][_0x414042(0x1d7)][_0x414042(0x340)][
      _0x414042(0x266)
    ](0x0),
    _0x5090ac[_0x414042(0x39a)]["trades"]["negatives"]["push"](0x0),
    _0x5090ac["chartData"]["trades"][_0x414042(0x2ac)][_0x414042(0x266)](0x0),
    _0x5090ac[_0x414042(0x1d7)][_0x414042(0x172)]((_0x4a6f39, _0x580e3e) => {
      const _0x32976b = _0x414042;
      if (_0x5090ac[_0x32976b(0x1d7)][_0x32976b(0x349)] - _0x580e3e > 0x64)
        return;
      if (_0x4a6f39[_0x32976b(0x1ff)] > 0x0)
        _0x5090ac[_0x32976b(0x39a)][_0x32976b(0x1d7)][_0x32976b(0x340)][
          _0x32976b(0x266)
        ](fixNumber(_0x4a6f39[_0x32976b(0x1ff)], 0x2)),
          _0x5090ac[_0x32976b(0x39a)][_0x32976b(0x1d7)][_0x32976b(0x289)][
            _0x32976b(0x266)
          ](null),
          _0x5090ac["chartData"][_0x32976b(0x1d7)]["xAxis"]["push"](
            formatDateTime(_0x4a6f39["od"])
          ),
          _0x5090ac[_0x32976b(0x39a)][_0x32976b(0x1d7)][_0x32976b(0x355)] <
            _0x4a6f39[_0x32976b(0x1ff)] &&
            (_0x5090ac[_0x32976b(0x39a)][_0x32976b(0x1d7)][_0x32976b(0x355)] =
              _0x4a6f39["result"]),
          _0x5090ac[_0x32976b(0x39a)]["trades"]["data"][_0x32976b(0x266)]({
            value: fixNumber(_0x4a6f39["result"], 0x2),
            itemStyle: {
              color: new echarts[_0x32976b(0x35c)][_0x32976b(0x267)](
                0x0,
                0x0,
                0x0,
                0x1,
                [
                  { offset: 0x0, color: _0x32976b(0x337) },
                  { offset: 0x1, color: _0x32976b(0x2f3) },
                ]
              ),
              barBorderWidth: 0x0,
              barBorderColor: _0x32976b(0x2f3),
            },
          });
      else {
        if (_0x4a6f39[_0x32976b(0x1ff)] < 0x0)
          _0x5090ac[_0x32976b(0x39a)][_0x32976b(0x1d7)][_0x32976b(0x340)][
            _0x32976b(0x266)
          ](null),
            _0x5090ac[_0x32976b(0x39a)][_0x32976b(0x1d7)][_0x32976b(0x289)][
              _0x32976b(0x266)
            ](fixNumber(_0x4a6f39[_0x32976b(0x1ff)], 0x2)),
            _0x5090ac[_0x32976b(0x39a)][_0x32976b(0x1d7)][_0x32976b(0x31b)][
              _0x32976b(0x266)
            ](formatDateTime(_0x4a6f39["od"])),
            _0x5090ac["chartData"][_0x32976b(0x1d7)]["min"] >
              _0x4a6f39[_0x32976b(0x1ff)] &&
              (_0x5090ac["chartData"][_0x32976b(0x1d7)][_0x32976b(0x368)] =
                _0x4a6f39[_0x32976b(0x1ff)]),
            _0x5090ac[_0x32976b(0x39a)][_0x32976b(0x1d7)][_0x32976b(0x2ac)][
              _0x32976b(0x266)
            ]({
              value: fixNumber(_0x4a6f39[_0x32976b(0x1ff)], 0x2),
              itemStyle: {
                color: new echarts["graphic"][_0x32976b(0x267)](
                  0x0,
                  0x0,
                  0x0,
                  0x1,
                  [
                    { offset: 0x0, color: "#ab0b00" },
                    { offset: 0x1, color: _0x32976b(0x2bc) },
                  ]
                ),
                barBorderWidth: 0x0,
                barBorderColor: _0x32976b(0x388),
              },
            });
        else
          _0x4a6f39[_0x32976b(0x1ff)] === 0x0 &&
            _0x5090ac[_0x32976b(0x39a)]["trades"][_0x32976b(0x2ac)][
              _0x32976b(0x266)
            ](0x0);
      }
    });
  let _0x7b846 = Math["max"](
    _0x5090ac[_0x414042(0x39a)][_0x414042(0x1d7)][_0x414042(0x355)],
    Math["abs"](_0x5090ac[_0x414042(0x39a)]["trades"]["min"])
  );
  (_0x5090ac[_0x414042(0x39a)][_0x414042(0x1d7)][_0x414042(0x355)] = _0x7b846),
    (_0x5090ac[_0x414042(0x39a)][_0x414042(0x1d7)][_0x414042(0x368)] =
      -_0x7b846);
  for (
    let _0x524685 =
      _0x5090ac[_0x414042(0x39a)][_0x414042(0x1d7)][_0x414042(0x340)][
        _0x414042(0x349)
      ];
    _0x524685 < 0x10;
    _0x524685++
  ) {
    _0x5090ac[_0x414042(0x39a)][_0x414042(0x1d7)]["xAxis"][_0x414042(0x266)](
      ""
    ),
      _0x5090ac["chartData"]["trades"]["positives"][_0x414042(0x266)](null),
      _0x5090ac[_0x414042(0x39a)][_0x414042(0x1d7)]["negatives"][
        _0x414042(0x266)
      ](null);
  }
  _0x5090ac["chartData"][_0x414042(0x1d7)]["xAxis"][_0x414042(0x266)](""),
    _0x5090ac[_0x414042(0x39a)][_0x414042(0x1d7)][_0x414042(0x340)][
      _0x414042(0x266)
    ](null),
    _0x5090ac[_0x414042(0x39a)][_0x414042(0x1d7)][_0x414042(0x289)][
      _0x414042(0x266)
    ](null);
}
function attachChartData(
  _0x3a21d0,
  _0x5e3b3e,
  _0x3b99a8,
  _0x567a35,
  _0x191bc0,
  _0xa00e7b,
  _0xe9cf4b
) {
  const _0x443da1 = a0_0xd18718;
  let _0x23fa4d = [];
  const _0x34aaaf = (_0x4e07e2) => {
    const _0x39bc17 = a0_0x1f43;
    let _0x3475d8 = getIndicatorId(_0x4e07e2);
    _0x23fa4d[_0x39bc17(0x244)](_0x3475d8) === -0x1 &&
      _0x23fa4d[_0x39bc17(0x266)](_0x3475d8);
    if (
      _0x4e07e2[_0x39bc17(0x395)] === _0x39bc17(0x283) ||
      _0x4e07e2[_0x39bc17(0x395)] === _0x39bc17(0x24a)
    ) {
      if (_0x4e07e2["condition1"]) {
        let _0x257dca = getIndicatorId({
          indicator: _0x4e07e2[_0x39bc17(0x2d4)][_0x39bc17(0x395)],
          period: _0x4e07e2[_0x39bc17(0x2d4)][_0x39bc17(0x3ad)],
          timeframe: _0x4e07e2[_0x39bc17(0x2f1)],
          mode: _0x4e07e2["mode"],
        });
        _0x23fa4d["indexOf"](_0x257dca) === -0x1 &&
          _0x23fa4d[_0x39bc17(0x266)](_0x257dca);
      }
    }
  };
  _0xe9cf4b[_0x443da1(0x2ec)][_0x443da1(0x172)](_0x34aaaf),
    _0xe9cf4b["sellRules"][_0x443da1(0x172)](_0x34aaaf);
  let _0x40ed52 = {};
  Object["keys"](_0x3b99a8)[_0x443da1(0x172)]((_0x55a782) => {
    const _0x1cbf9e = _0x443da1;
    _0x23fa4d[_0x1cbf9e(0x244)](_0x55a782) !== -0x1 &&
      (_0x40ed52[_0x55a782] = _0x3b99a8[_0x55a782]);
  }),
    (_0x3a21d0[_0x443da1(0x39a)][_0x443da1(0x196)] = createCandleChart(
      _0x191bc0,
      _0x40ed52
    )),
    _0x5e3b3e[_0x443da1(0x172)]((_0x6aa16) => {
      const _0x434fae = _0x443da1;
      let _0x35efd6 = ensureDate(_0x6aa16["d"]);
      if (_0x35efd6 < _0x567a35) return;
      _0x3a21d0["chartData"]["candleChart"][_0x434fae(0x317)][_0x434fae(0x266)](
        formatDateTime(_0x6aa16["d"])
      ),
        _0x3a21d0[_0x434fae(0x39a)][_0x434fae(0x196)][_0x434fae(0x38e)][0x0][
          _0x434fae(0x2ac)
        ][_0x434fae(0x266)]([
          _0x6aa16["o"],
          _0x6aa16["c"],
          _0x6aa16["l"],
          _0x6aa16["h"],
        ]);
      for (
        let _0xe6b8c7 = 0x1;
        _0xe6b8c7 <
        _0x3a21d0["chartData"][_0x434fae(0x196)]["series"][_0x434fae(0x349)];
        _0xe6b8c7++
      ) {
        let _0x4bd269 =
          _0x3b99a8[
            _0x3a21d0[_0x434fae(0x39a)]["candleChart"][_0x434fae(0x38e)][
              _0xe6b8c7
            ][_0x434fae(0x248)]
          ];
        if (!_0x4bd269) continue;
        let _0xf081e4 = null,
          _0xd13049 = 0x0;
        for (
          let _0x588eba =
            _0x3a21d0[_0x434fae(0x39a)]["candleChart"][_0x434fae(0x38e)][
              _0xe6b8c7
            ][_0x434fae(0x241)];
          _0x588eba < _0x4bd269["length"];
          _0x588eba++
        ) {
          _0xd13049 = _0x588eba;
          let _0xa1c44e = _0x4bd269[_0x588eba];
          if (_0xa1c44e) {
            let _0x4cfac6 = ensureDate(_0xa1c44e["d"]);
            if (
              _0x3a21d0[_0x434fae(0x39a)][_0x434fae(0x196)][_0x434fae(0x38e)][
                _0xe6b8c7
              ][_0x434fae(0x2f1)] === _0xa00e7b
            ) {
              if (_0x35efd6[_0x434fae(0x168)]() === _0x4cfac6["getTime"]()) {
                (_0xf081e4 = _0xa1c44e),
                  (_0x3a21d0[_0x434fae(0x39a)][_0x434fae(0x196)][
                    _0x434fae(0x38e)
                  ][_0xe6b8c7]["indIndex"] = _0x588eba);
                break;
              } else
                _0x35efd6 > _0x4cfac6 &&
                  (_0x3a21d0[_0x434fae(0x39a)][_0x434fae(0x196)][
                    _0x434fae(0x38e)
                  ][_0xe6b8c7][_0x434fae(0x241)] = _0x588eba);
            } else {
              let _0x2f5cdc = ensureDate(_0xa1c44e["cd"]);
              if (
                _0x588eba > 0x0 &&
                _0x35efd6 >= _0x4cfac6 &&
                _0x35efd6 < _0x2f5cdc
              ) {
                let _0x11fc62 = ensureDate(_0x6aa16["cd"]);
                if (_0x4cfac6 <= _0x35efd6 && _0x11fc62 <= _0x2f5cdc) {
                  (_0xf081e4 = _0x4bd269[_0x588eba]),
                    (_0x3a21d0["chartData"][_0x434fae(0x196)][_0x434fae(0x38e)][
                      _0xe6b8c7
                    ][_0x434fae(0x241)] = _0x588eba);
                  break;
                }
              }
            }
          }
        }
        if (_0xf081e4) {
          if (
            _0x3a21d0["chartData"][_0x434fae(0x196)][_0x434fae(0x38e)][
              _0xe6b8c7
            ][_0x434fae(0x22d)]["startsWith"](_0x434fae(0x283)) ||
            _0x3a21d0["chartData"]["candleChart"]["series"][_0xe6b8c7][
              _0x434fae(0x22d)
            ][_0x434fae(0x169)](_0x434fae(0x24a))
          )
            _0x3a21d0[_0x434fae(0x39a)][_0x434fae(0x196)][_0x434fae(0x38e)][
              _0xe6b8c7
            ][_0x434fae(0x2ac)][_0x434fae(0x266)](_0xf081e4["ma"]);
          else {
            if (
              _0x3a21d0["chartData"][_0x434fae(0x196)][_0x434fae(0x38e)][
                _0xe6b8c7
              ][_0x434fae(0x22d)][_0x434fae(0x169)](_0x434fae(0x1da))
            )
              _0x3a21d0[_0x434fae(0x39a)][_0x434fae(0x196)][_0x434fae(0x38e)][
                _0xe6b8c7
              ][_0x434fae(0x2ac)][_0x434fae(0x266)](_0xf081e4["r"]);
            else {
              if (
                _0x3a21d0["chartData"][_0x434fae(0x196)][_0x434fae(0x38e)][
                  _0xe6b8c7
                ][_0x434fae(0x22d)]["startsWith"](_0x434fae(0x280))
              )
                _0x3a21d0["chartData"][_0x434fae(0x196)][_0x434fae(0x38e)][
                  _0xe6b8c7
                ][_0x434fae(0x2ac)]["push"](_0xf081e4["k"]);
              else {
                if (
                  _0x3a21d0["chartData"]["candleChart"][_0x434fae(0x38e)][
                    _0xe6b8c7
                  ][_0x434fae(0x22d)][_0x434fae(0x169)](_0x434fae(0x37c))
                )
                  _0x3a21d0[_0x434fae(0x39a)][_0x434fae(0x196)]["series"][
                    _0xe6b8c7
                  ][_0x434fae(0x2ac)]["push"](_0xf081e4["dl"]);
                else {
                  if (
                    _0x3a21d0["chartData"][_0x434fae(0x196)][_0x434fae(0x38e)][
                      _0xe6b8c7
                    ][_0x434fae(0x22d)][_0x434fae(0x169)](_0x434fae(0x3c2))
                  ) {
                    if (
                      _0x3a21d0["chartData"]["candleChart"][_0x434fae(0x38e)][
                        _0xe6b8c7
                      ][_0x434fae(0x22d)]["endsWith"](_0x434fae(0x397))
                    )
                      _0x3a21d0[_0x434fae(0x39a)]["candleChart"]["series"][
                        _0xe6b8c7
                      ][_0x434fae(0x2ac)][_0x434fae(0x266)](
                        fixNumber(_0xf081e4["s"], 0x8)
                      );
                    else
                      _0x3a21d0["chartData"][_0x434fae(0x196)][
                        _0x434fae(0x38e)
                      ][_0xe6b8c7][_0x434fae(0x22d)][_0x434fae(0x176)](
                        _0x434fae(0x17f)
                      )
                        ? pushHistogramChartValue(
                            _0x3a21d0[_0x434fae(0x39a)][_0x434fae(0x196)][
                              _0x434fae(0x38e)
                            ][_0xe6b8c7][_0x434fae(0x2ac)],
                            _0xf081e4,
                            _0x4bd269[_0xd13049 - 0x1],
                            "h",
                            0x8
                          )
                        : _0x3a21d0[_0x434fae(0x39a)]["candleChart"]["series"][
                            _0xe6b8c7
                          ]["data"]["push"](fixNumber(_0xf081e4["m"], 0x8));
                  } else {
                    if (
                      _0x3a21d0[_0x434fae(0x39a)][_0x434fae(0x196)][
                        _0x434fae(0x38e)
                      ][_0xe6b8c7][_0x434fae(0x22d)][_0x434fae(0x169)](
                        _0x434fae(0x382)
                      )
                    )
                      _0x3a21d0[_0x434fae(0x39a)][_0x434fae(0x196)][
                        _0x434fae(0x38e)
                      ][_0xe6b8c7][_0x434fae(0x2ac)]["push"](
                        fixNumber(_0xf081e4["u"], 0x8)
                      );
                    else {
                      if (
                        _0x3a21d0[_0x434fae(0x39a)]["candleChart"][
                          _0x434fae(0x38e)
                        ][_0xe6b8c7][_0x434fae(0x22d)][_0x434fae(0x169)](
                          _0x434fae(0x341)
                        )
                      )
                        _0x3a21d0[_0x434fae(0x39a)][_0x434fae(0x196)]["series"][
                          _0xe6b8c7
                        ][_0x434fae(0x2ac)][_0x434fae(0x266)](
                          fixNumber(_0xf081e4["l"], 0x8)
                        );
                      else {
                        if (
                          _0x3a21d0["chartData"]["candleChart"][
                            _0x434fae(0x38e)
                          ][_0xe6b8c7]["name"][_0x434fae(0x169)](
                            "Upper-Keltner"
                          )
                        )
                          _0x3a21d0["chartData"]["candleChart"][
                            _0x434fae(0x38e)
                          ][_0xe6b8c7][_0x434fae(0x2ac)][_0x434fae(0x266)](
                            fixNumber(_0xf081e4["u"], 0x8)
                          );
                        else {
                          if (
                            _0x3a21d0[_0x434fae(0x39a)][_0x434fae(0x196)][
                              _0x434fae(0x38e)
                            ][_0xe6b8c7]["name"][_0x434fae(0x169)](
                              "Lower-Keltner"
                            )
                          )
                            _0x3a21d0[_0x434fae(0x39a)][_0x434fae(0x196)][
                              _0x434fae(0x38e)
                            ][_0xe6b8c7][_0x434fae(0x2ac)][_0x434fae(0x266)](
                              fixNumber(_0xf081e4["l"], 0x8)
                            );
                          else {
                            if (
                              _0x3a21d0[_0x434fae(0x39a)][_0x434fae(0x196)][
                                _0x434fae(0x38e)
                              ][_0xe6b8c7]["name"][_0x434fae(0x169)](
                                "Upper-BB-MomentumSqueeze"
                              )
                            )
                              _0x3a21d0[_0x434fae(0x39a)][_0x434fae(0x196)][
                                "series"
                              ][_0xe6b8c7][_0x434fae(0x2ac)]["push"](
                                fixNumber(_0xf081e4["bU"], 0x8)
                              );
                            else {
                              if (
                                _0x3a21d0[_0x434fae(0x39a)]["candleChart"][
                                  _0x434fae(0x38e)
                                ][_0xe6b8c7][_0x434fae(0x22d)]["startsWith"](
                                  _0x434fae(0x252)
                                )
                              )
                                _0x3a21d0[_0x434fae(0x39a)][_0x434fae(0x196)][
                                  "series"
                                ][_0xe6b8c7][_0x434fae(0x2ac)][
                                  _0x434fae(0x266)
                                ](fixNumber(_0xf081e4["bL"], 0x8));
                              else {
                                if (
                                  _0x3a21d0[_0x434fae(0x39a)][_0x434fae(0x196)][
                                    "series"
                                  ][_0xe6b8c7][_0x434fae(0x22d)][
                                    _0x434fae(0x169)
                                  ]("Upper-KC-MomentumSqueeze")
                                )
                                  _0x3a21d0[_0x434fae(0x39a)]["candleChart"][
                                    _0x434fae(0x38e)
                                  ][_0xe6b8c7]["data"][_0x434fae(0x266)](
                                    fixNumber(_0xf081e4["kU"], 0x8)
                                  );
                                else {
                                  if (
                                    _0x3a21d0[_0x434fae(0x39a)][
                                      _0x434fae(0x196)
                                    ][_0x434fae(0x38e)][_0xe6b8c7]["name"][
                                      _0x434fae(0x169)
                                    ](_0x434fae(0x347))
                                  )
                                    _0x3a21d0["chartData"][_0x434fae(0x196)][
                                      _0x434fae(0x38e)
                                    ][_0xe6b8c7][_0x434fae(0x2ac)]["push"](
                                      fixNumber(_0xf081e4["kL"], 0x8)
                                    );
                                  else {
                                    if (
                                      _0x3a21d0[_0x434fae(0x39a)][
                                        _0x434fae(0x196)
                                      ]["series"][_0xe6b8c7][_0x434fae(0x22d)][
                                        "startsWith"
                                      ](_0x434fae(0x2ed))
                                    )
                                      _0xf081e4["s"]
                                        ? _0x3a21d0[_0x434fae(0x39a)][
                                            _0x434fae(0x196)
                                          ]["series"][_0xe6b8c7][
                                            _0x434fae(0x2ac)
                                          ][_0x434fae(0x266)]({
                                            value: 0x0,
                                            valueOrg: _0xf081e4["s"],
                                            itemStyle: {
                                              color: _0x434fae(0x17e),
                                              color0: _0x434fae(0x17e),
                                              borderColor: _0x434fae(0x17e),
                                              borderColor0: null,
                                            },
                                          })
                                        : _0x3a21d0[_0x434fae(0x39a)][
                                            _0x434fae(0x196)
                                          ]["series"][_0xe6b8c7][
                                            _0x434fae(0x2ac)
                                          ][_0x434fae(0x266)]({
                                            value: 0x0,
                                            valueOrg: _0xf081e4["s"],
                                            itemStyle: {
                                              color: "#fff",
                                              color0: _0x434fae(0x315),
                                              borderColor: "#343a40",
                                              borderColor0: null,
                                            },
                                          });
                                    else
                                      _0x3a21d0[_0x434fae(0x39a)][
                                        _0x434fae(0x196)
                                      ][_0x434fae(0x38e)][_0xe6b8c7]["name"][
                                        _0x434fae(0x169)
                                      ](_0x434fae(0x16c)) &&
                                        (_0x4bd269[_0xd13049 - 0x1] !==
                                          undefined &&
                                        _0x4bd269[_0xd13049 - 0x1] !== null &&
                                        _0x4bd269[_0xd13049 - 0x1] !== ""
                                          ? pushHistogramChartValue(
                                              _0x3a21d0[_0x434fae(0x39a)][
                                                _0x434fae(0x196)
                                              ]["series"][_0xe6b8c7][
                                                _0x434fae(0x2ac)
                                              ],
                                              _0xf081e4,
                                              _0x4bd269[_0xd13049 - 0x1],
                                              "h",
                                              0x8
                                            )
                                          : _0x3a21d0[_0x434fae(0x39a)][
                                              "candleChart"
                                            ][_0x434fae(0x38e)][_0xe6b8c7][
                                              _0x434fae(0x2ac)
                                            ][_0x434fae(0x266)](null));
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } else
          _0x3a21d0["chartData"][_0x434fae(0x196)][_0x434fae(0x38e)][_0xe6b8c7][
            _0x434fae(0x2ac)
          ][_0x434fae(0x266)](null);
      }
    });
  let _0x382861 = 0x0;
  (_0x3a21d0[_0x443da1(0x39a)][_0x443da1(0x196)]["tradesDates"] = []),
    _0x3a21d0["trades"][_0x443da1(0x172)]((_0x5a858e) => {
      const _0x49b253 = _0x443da1;
      let _0x4e0c02 = [
        _0x49b253(0x258),
        _0x49b253(0x27f),
        "FILLED",
        _0x49b253(0x1c5),
      ];
      if (!_0x4e0c02[_0x49b253(0x1c4)](_0x5a858e[_0x49b253(0x3aa)])) return;
      let _0x424345 = new Date(_0x5a858e["od"]),
        _0x4342d1 =
          _0x4e0c02[_0x49b253(0x1c4)](_0x5a858e["closeStatus"]) &&
          _0x5a858e["cd"]
            ? new Date(_0x5a858e["cd"])
            : null,
        _0xda5f49 = null,
        _0x2d9664 = null;
      for (
        let _0xf14a4f = _0x382861;
        _0xf14a4f < _0x5e3b3e[_0x49b253(0x349)];
        _0xf14a4f++
      ) {
        let _0x55458e =
            typeof _0x5e3b3e[_0xf14a4f]["d"] === _0x49b253(0x2d2)
              ? new Date(_0x5e3b3e[_0xf14a4f]["d"])
              : _0x5e3b3e[_0xf14a4f]["d"],
          _0x12016c =
            typeof _0x5e3b3e[_0xf14a4f]["cd"] === _0x49b253(0x2d2)
              ? new Date(_0x5e3b3e[_0xf14a4f]["cd"])
              : _0x5e3b3e[_0xf14a4f]["cd"];
        if (!_0xda5f49 && _0x55458e <= _0x424345 && _0x424345 <= _0x12016c) {
          (_0xda5f49 = formatDateTime(_0x55458e)), (_0x382861 = _0xf14a4f);
          if (!_0x4342d1) break;
        }
        if (_0x4342d1 && _0x55458e <= _0x4342d1 && _0x4342d1 <= _0x12016c) {
          (_0x2d9664 = formatDateTime(_0x55458e)), (_0x382861 = _0xf14a4f);
          break;
        }
      }
      _0x3a21d0[_0x49b253(0x39a)][_0x49b253(0x196)][_0x49b253(0x257)][
        _0x49b253(0x266)
      ]({ o: _0x424345, c: _0x4342d1 ? _0x4342d1 : _0x424345 }),
        _0x3a21d0["chartData"][_0x49b253(0x196)]["series"][0x0][
          _0x49b253(0x18b)
        ][_0x49b253(0x2ac)][_0x49b253(0x266)]({
          trade: _0x5a858e,
          name: "Opened",
          coord: [_0xda5f49, _0x5a858e["o"]],
          value: _0x49b253(0x270),
          itemStyle: {
            color: _0x49b253(0x315),
            borderColor: _0x49b253(0x2b0),
            borderWidth: 0x1,
          },
          label: { color: _0x49b253(0x1f5), fontSize: 0xa },
        }),
        _0x5a858e[_0x49b253(0x1ff)] != null &&
          _0x5a858e[_0x49b253(0x1ff)] !== undefined &&
          (_0x3a21d0["chartData"][_0x49b253(0x196)][_0x49b253(0x38e)][0x0][
            _0x49b253(0x18b)
          ]["data"][_0x49b253(0x266)]({
            trade: _0x5a858e,
            name: _0x49b253(0x186),
            coord: [_0x2d9664, _0x5a858e["c"]],
            value: _0x5a858e[_0x49b253(0x1ff)][_0x49b253(0x23b)](0x2) + "%",
            itemStyle: {
              color: _0x49b253(0x2b0),
              borderColor: _0x49b253(0x2b0),
              borderWidth: 0x1,
            },
            label: { color: _0x49b253(0x315), fontSize: 0x9 },
          }),
          _0x3a21d0[_0x49b253(0x39a)][_0x49b253(0x196)][_0x49b253(0x38e)][0x0][
            _0x49b253(0x3a7)
          ]["data"]["push"]([
            { name: "Opened", coord: [_0xda5f49, _0x5a858e["o"]] },
            { name: _0x49b253(0x186), coord: [_0x2d9664, _0x5a858e["c"]] },
          ]));
    });
}
function pushHistogramChartValue(
  _0x14ce81,
  _0x22de72,
  _0x108ef8,
  _0x580b00,
  _0xbe5576
) {
  const _0x3a2f0d = a0_0xd18718;
  !_0x108ef8 && (_0x108ef8 = _0x22de72);
  let _0x422317 = { borderColor: null, borderColor0: null };
  if (_0x22de72[_0x580b00] > 0x0)
    _0x22de72[_0x580b00] < _0x108ef8[_0x580b00]
      ? ((_0x422317[_0x3a2f0d(0x3a8)] = _0x3a2f0d(0x1f6)),
        (_0x422317[_0x3a2f0d(0x356)] = "#59ac59"))
      : ((_0x422317[_0x3a2f0d(0x3a8)] = "#59ff59"),
        (_0x422317[_0x3a2f0d(0x356)] = "#59ff59"));
  else
    _0x22de72[_0x580b00] < 0x0
      ? _0x22de72[_0x580b00] > _0x108ef8[_0x580b00]
        ? ((_0x422317["color"] = _0x3a2f0d(0x1fb)),
          (_0x422317[_0x3a2f0d(0x356)] = _0x3a2f0d(0x1fb)))
        : ((_0x422317[_0x3a2f0d(0x3a8)] = "#ff5959"),
          (_0x422317[_0x3a2f0d(0x356)] = _0x3a2f0d(0x299)))
      : ((_0x422317[_0x3a2f0d(0x3a8)] = _0x3a2f0d(0x3b9)),
        (_0x422317[_0x3a2f0d(0x356)] = _0x3a2f0d(0x3b9)));
  _0x14ce81[_0x3a2f0d(0x266)]({
    value: fixNumber(_0x22de72[_0x580b00], _0xbe5576),
    itemStyle: _0x422317,
  });
}
function createCandleChart(_0x54a840, _0x3be363) {
  const _0x20a686 = a0_0xd18718;
  currentColorIndex = 0x0;
  let _0xc3b634 = {
    dates: [],
    series: [
      {
        name: _0x54a840,
        type: "candlestick",
        data: [],
        itemStyle: {
          color: "#28a745",
          borderColor: _0x20a686(0x324),
          color0: _0x20a686(0x3bf),
          borderColor0: _0x20a686(0x3bf),
        },
        markPoint: { itemStyle: { opacity: 0.7 }, data: [] },
        markLine: {
          symbol: [_0x20a686(0x162), _0x20a686(0x162)],
          symbolSize: 0x4,
          lineStyle: {
            type: _0x20a686(0x311),
            width: 0x1,
            color: _0x20a686(0x315),
            opacity: 0.7,
          },
          label: { show: ![] },
          emphasis: { label: { show: ![] } },
          data: [],
        },
      },
    ],
    axisCount: 0x0,
  };
  return (
    Object[_0x20a686(0x301)](_0x3be363)[_0x20a686(0x172)]((_0x1151b3) => {
      const _0x29080e = _0x20a686;
      if (_0x1151b3["startsWith"]("RSI")) {
        _0xc3b634[_0x29080e(0x3a5)]++;
        let _0xc59fb1 = createChartSerie(
          _0x1151b3,
          _0x1151b3,
          _0x3be363[_0x1151b3][_0x29080e(0x2f1)],
          _0xc3b634[_0x29080e(0x3a5)]
        );
        _0xc3b634["series"][_0x29080e(0x266)](_0xc59fb1);
      } else {
        if (_0x1151b3[_0x29080e(0x169)]("MACD")) {
          _0xc3b634[_0x29080e(0x3a5)]++;
          let _0x398a49 = createChartSerie(
              _0x1151b3,
              _0x1151b3,
              _0x3be363[_0x1151b3]["timeframe"],
              _0xc3b634[_0x29080e(0x3a5)]
            ),
            _0x273216 = createChartSerie(
              _0x1151b3 + "-Signal",
              _0x1151b3,
              _0x3be363[_0x1151b3][_0x29080e(0x2f1)],
              _0xc3b634[_0x29080e(0x3a5)]
            ),
            _0x4a4494 = createChartSerie(
              _0x1151b3 + _0x29080e(0x287),
              _0x1151b3,
              _0x3be363[_0x1151b3]["timeframe"],
              _0xc3b634["axisCount"],
              _0x29080e(0x338)
            );
          _0xc3b634[_0x29080e(0x38e)][_0x29080e(0x266)](_0x398a49),
            _0xc3b634[_0x29080e(0x38e)][_0x29080e(0x266)](_0x273216),
            _0xc3b634[_0x29080e(0x38e)]["push"](_0x4a4494);
        } else {
          if (_0x1151b3[_0x29080e(0x169)](_0x29080e(0x219))) {
            _0xc3b634["axisCount"]++;
            let _0x354ed4 = createChartSerie(
                _0x29080e(0x323) + _0x1151b3,
                _0x1151b3,
                _0x3be363[_0x1151b3]["timeframe"],
                _0xc3b634["axisCount"]
              ),
              _0x331562 = createChartSerie(
                _0x29080e(0x284) + _0x1151b3,
                _0x1151b3,
                _0x3be363[_0x1151b3][_0x29080e(0x2f1)],
                _0xc3b634[_0x29080e(0x3a5)]
              );
            _0xc3b634[_0x29080e(0x38e)][_0x29080e(0x266)](_0x354ed4),
              _0xc3b634[_0x29080e(0x38e)][_0x29080e(0x266)](_0x331562);
          } else {
            if (_0x1151b3[_0x29080e(0x169)](_0x29080e(0x362))) {
              _0xc3b634[_0x29080e(0x3a5)]++;
              let _0x2c5c6d = createChartSerie(
                  _0x1151b3,
                  _0x1151b3,
                  _0x3be363[_0x1151b3][_0x29080e(0x2f1)],
                  _0xc3b634["axisCount"],
                  _0x29080e(0x338)
                ),
                _0xf58665 = _0x1151b3[_0x29080e(0x3ab)](
                  "MomentumSqueeze",
                  _0x29080e(0x2ed)
                ),
                _0x596fe7 = createChartSerie(
                  _0xf58665,
                  _0x1151b3,
                  _0x3be363[_0x1151b3]["timeframe"],
                  _0xc3b634["axisCount"],
                  _0x29080e(0x167),
                  !![]
                );
              _0xc3b634["series"][_0x29080e(0x266)](_0x2c5c6d),
                _0xc3b634[_0x29080e(0x38e)][_0x29080e(0x266)](_0x596fe7);
            } else {
              if (_0x1151b3[_0x29080e(0x169)](_0x29080e(0x16c))) {
                _0xc3b634["axisCount"]++;
                let _0x12fbc8 = createChartSerie(
                  _0x1151b3,
                  _0x1151b3,
                  _0x3be363[_0x1151b3][_0x29080e(0x2f1)],
                  _0xc3b634[_0x29080e(0x3a5)],
                  "bar"
                );
                _0xc3b634[_0x29080e(0x38e)][_0x29080e(0x266)](_0x12fbc8);
              } else {
                if (_0x1151b3[_0x29080e(0x169)]("Bollinger")) {
                  let _0x279451 = getNextColorIndex(),
                    _0x26ee51 = createChartSerie(
                      _0x29080e(0x3a9) + _0x1151b3,
                      _0x1151b3,
                      _0x3be363[_0x1151b3][_0x29080e(0x2f1)],
                      0x0,
                      _0x29080e(0x167),
                      ![],
                      _0x279451
                    ),
                    _0x34001b = createChartSerie(
                      "Lower-" + _0x1151b3,
                      _0x1151b3,
                      _0x3be363[_0x1151b3]["timeframe"],
                      0x0,
                      "line",
                      ![],
                      _0x279451
                    );
                  _0xc3b634[_0x29080e(0x38e)][_0x29080e(0x266)](_0x26ee51),
                    _0xc3b634["series"][_0x29080e(0x266)](_0x34001b);
                } else {
                  if (_0x1151b3[_0x29080e(0x169)](_0x29080e(0x37d))) {
                    let _0x3f4529 = getNextColorIndex(),
                      _0x11132a = createChartSerie(
                        _0x29080e(0x3a9) + _0x1151b3,
                        _0x1151b3,
                        _0x3be363[_0x1151b3][_0x29080e(0x2f1)],
                        0x0,
                        _0x29080e(0x167),
                        ![],
                        _0x3f4529
                      ),
                      _0x5aa644 = createChartSerie(
                        _0x29080e(0x1b8) + _0x1151b3,
                        _0x1151b3,
                        _0x3be363[_0x1151b3][_0x29080e(0x2f1)],
                        0x0,
                        _0x29080e(0x167),
                        ![],
                        _0x3f4529
                      );
                    _0xc3b634[_0x29080e(0x38e)][_0x29080e(0x266)](_0x11132a),
                      _0xc3b634[_0x29080e(0x38e)][_0x29080e(0x266)](_0x5aa644);
                  } else {
                    let _0x1fc738 = createChartSerie(
                      _0x1151b3,
                      _0x1151b3,
                      _0x3be363[_0x1151b3][_0x29080e(0x2f1)],
                      0x0
                    );
                    _0xc3b634[_0x29080e(0x38e)][_0x29080e(0x266)](_0x1fc738);
                  }
                }
              }
            }
          }
        }
      }
    }),
    _0xc3b634
  );
}
const colors = [
  a0_0xd18718(0x1d5),
  a0_0xd18718(0x348),
  a0_0xd18718(0x307),
  a0_0xd18718(0x2b3),
  a0_0xd18718(0x31e),
  a0_0xd18718(0x334),
  a0_0xd18718(0x361),
  "#00ffa1",
];
let currentColorIndex = 0x0;
function getNextColorIndex() {
  const _0x430af6 = a0_0xd18718;
  let _0x22154b = currentColorIndex;
  return (
    (currentColorIndex =
      colors[_0x430af6(0x349)] - 0x1 === currentColorIndex
        ? 0x0
        : currentColorIndex + 0x1),
    _0x22154b
  );
}
function createChartSerie(
  _0x2335ad,
  _0xa463dd,
  _0xc576e3,
  _0x42fa4e,
  _0x1762ef = "line",
  _0xb99f79 = ![],
  _0x12f4e3 = null
) {
  const _0x13acd5 = a0_0xd18718;
  _0x12f4e3 === null && (_0x12f4e3 = getNextColorIndex());
  let _0x162c01 = {
    indIndex: 0x0,
    name: _0x2335ad[_0x13acd5(0x3ab)](_0x13acd5(0x1ef), "")
      [_0x13acd5(0x3ab)](_0x13acd5(0x332), "")
      [_0x13acd5(0x3ab)](/minutes/g, "m")
      [_0x13acd5(0x3ab)](/minute/g, "m")
      [_0x13acd5(0x3ab)](/hours/g, "h")
      [_0x13acd5(0x3ab)](/hour/g, "h")
      [_0x13acd5(0x3ab)](/day/g, "d"),
    indDataId: _0xa463dd,
    timeframe: _0xc576e3,
    type: _0x1762ef,
    data: [],
    smooth: ![],
    connectNulls: !![],
    symbolSize: 0x1,
    color: colors[_0x12f4e3],
    lineStyle: { opacity: 0.9, width: 0x1 },
    xAxisIndex: _0x42fa4e,
    yAxisIndex: _0x42fa4e,
  };
  return (
    _0xb99f79 &&
      ((_0x162c01[_0x13acd5(0x376)] = ![]),
      (_0x162c01[_0x13acd5(0x3a8)] = _0x13acd5(0x315)),
      (_0x162c01[_0x13acd5(0x39b)] = 0x4),
      (_0x162c01["showAllSymbol"] = !![]),
      (_0x162c01["symbol"] = _0x13acd5(0x162)),
      (_0x162c01["lineStyle"][_0x13acd5(0x158)] = 0x1),
      (_0x162c01[_0x13acd5(0x250)][_0x13acd5(0x1a2)] = 0x0)),
    _0x162c01
  );
}
async function sendStrategyStatus(_0x44d993, _0x117c5a, _0x52521b) {
  const _0x46ac0a = a0_0xd18718;
  if (_0x44d993 === _0x46ac0a(0x37e)) return;
  let _0x595458 =
      _0x117c5a === _0x46ac0a(0x26a) ? _0x46ac0a(0x2e0) : _0x46ac0a(0x177),
    _0x5141b8 = {
      type: _0x117c5a === _0x46ac0a(0x26a) ? _0x46ac0a(0x2ad) : "live",
      statuses: [
        {
          id: _0x52521b["id"],
          editDate: _0x52521b[_0x46ac0a(0x3a4)],
          status: _0x52521b,
        },
      ],
    };
  return fetchWithRetry(
    _0x46ac0a(0x29b),
    _0x46ac0a(0x1fe),
    _0x5141b8,
    (_0x9bc54b) => {
      const _0xc22e2 = _0x46ac0a;
      _0x9bc54b &&
        _0x9bc54b[_0xc22e2(0x24c)] &&
        _0x9bc54b[_0xc22e2(0x24c)][_0xc22e2(0x349)] > 0x0 &&
        _0x9bc54b[_0xc22e2(0x24c)][_0xc22e2(0x172)]((_0x4b672d) =>
          addItemToArrayInLocalStore(_0x595458, _0x4b672d)
        );
    },
    () => {
      addItemToArrayInLocalStore(_0x595458, _0x52521b["id"]);
    },
    { Authorization: _0x46ac0a(0x1e6) + _0x44d993 }
  );
}
async function sendStrategyStatusesNotSynced(_0x53b875, _0x464142, _0x3347fc) {
  const _0x5391c8 = a0_0xd18718;
  if (_0x53b875 === "demo") return;
  let _0x320fb9 =
    _0x3347fc === "simulation" ? _0x5391c8(0x2e0) : _0x5391c8(0x177);
  try {
    await autoSyncMutex[_0x5391c8(0x39d)]();
    let _0x4d2ded = getArrayFromLocalStore(_0x320fb9);
    localStorage["removeItem"](_0x320fb9);
    if (_0x4d2ded["length"] === 0x0) return;
    let _0x463b6a = [];
    for (let _0xe1ae15 of _0x4d2ded) {
      let _0x5bab01 =
        _0x3347fc === _0x5391c8(0x26a)
          ? await getEcbDatabase()["getSimStrategyStatus"](_0xe1ae15)
          : await getEcbDatabase()[_0x5391c8(0x328)](_0xe1ae15);
      _0x5bab01 && _0x463b6a["push"](_0x5bab01);
    }
    if (_0x463b6a["length"] > 0x0) {
      const _0x53440d = _0x5391c8(0x29b);
      let _0x4a8b74 = {
        type: _0x3347fc === _0x5391c8(0x26a) ? "sim" : _0x5391c8(0x184),
        statuses: _0x463b6a[_0x5391c8(0x164)]((_0x310c2f) => ({
          id: _0x310c2f["id"],
          editDate: _0x310c2f[_0x5391c8(0x3a4)],
          status: _0x310c2f,
        })),
      };
      await fetchWithRetry(
        _0x53440d,
        _0x5391c8(0x1fe),
        _0x4a8b74,
        (_0x48e214) => {
          const _0x364e41 = _0x5391c8;
          _0x48e214 &&
            _0x48e214["failedIds"] &&
            _0x48e214[_0x364e41(0x24c)]["length"] > 0x0 &&
            _0x48e214[_0x364e41(0x24c)][_0x364e41(0x172)]((_0x125406) =>
              addItemToArrayInLocalStore(_0x320fb9, _0x125406)
            );
        },
        () => {
          const _0x4445ab = _0x5391c8;
          _0x463b6a[_0x4445ab(0x172)]((_0x44ab2c) =>
            addItemToArrayInLocalStore(_0x320fb9, _0x44ab2c["id"])
          );
        },
        { Authorization: "Bearer\x20" + _0x53b875 }
      );
    }
  } finally {
    autoSyncMutex["release"]();
  }
}
async function sendRemoveStrategyStatusesNotSynced(_0x212973, _0x7efa0) {
  const _0x4ea062 = a0_0xd18718;
  if (_0x212973 === _0x4ea062(0x37e)) return;
  const _0x54337d = _0x4ea062(0x29b);
  let _0x5e3977 =
    _0x7efa0 === _0x4ea062(0x26a) ? _0x4ea062(0x30f) : "removedLiveStatusesIds";
  try {
    await autoSyncMutex[_0x4ea062(0x39d)]();
    let _0x5ef23b = getArrayFromLocalStore(_0x5e3977);
    _0x5ef23b &&
      _0x5ef23b[_0x4ea062(0x349)] > 0x0 &&
      (localStorage["removeItem"](_0x5e3977),
      await fetchWithRetry(
        _0x54337d,
        _0x4ea062(0x33c),
        _0x5ef23b,
        (_0x340cf1) => {
          const _0x1fe74c = _0x4ea062;
          _0x340cf1[_0x1fe74c(0x24c)] &&
            _0x340cf1["failedIds"]["forEach"]((_0x356ad5) => {
              addItemToArrayInLocalStore(_0x5e3977, _0x356ad5);
            });
        },
        () => {
          const _0x22e380 = _0x4ea062;
          _0x5ef23b[_0x22e380(0x172)]((_0xc1f373) => {
            addItemToArrayInLocalStore(_0x5e3977, _0xc1f373);
          });
        },
        { Authorization: _0x4ea062(0x1e6) + _0x212973 }
      ));
  } finally {
    autoSyncMutex[_0x4ea062(0x333)]();
  }
}
async function sendRemoveStrategyStatus(_0x16759a, _0x352ca4, _0x22967b) {
  const _0x29bd87 = a0_0xd18718;
  if (_0x16759a === _0x29bd87(0x37e)) return;
  let _0x14f26d = {
      type: _0x352ca4 === _0x29bd87(0x26a) ? "sim" : _0x29bd87(0x184),
      ids: [_0x22967b],
    },
    _0x52d497 =
      _0x352ca4 === _0x29bd87(0x26a) ? _0x29bd87(0x30f) : _0x29bd87(0x163);
  return (
    removeItemFromArrayInLocalStore(_0x52d497, _0x22967b),
    fetchWithRetry(
      _0x29bd87(0x29b),
      _0x29bd87(0x33c),
      _0x14f26d,
      (_0x52566e) => {
        const _0x4488b0 = _0x29bd87;
        _0x52566e[_0x4488b0(0x24c)] &&
          _0x52566e[_0x4488b0(0x24c)][_0x4488b0(0x172)]((_0x2fcdf0) => {
            addItemToArrayInLocalStore(_0x52d497, _0x2fcdf0);
          });
      },
      () => {
        addItemToArrayInLocalStore(_0x52d497, _0x22967b);
      },
      { Authorization: _0x29bd87(0x1e6) + _0x16759a }
    )
  );
}
async function sendStrategyDetails(_0x57092f, _0x55deba, _0x16ac06) {
  const _0x3ef008 = a0_0xd18718;
  if (_0x57092f === _0x3ef008(0x37e)) return;
  let _0x400a4c =
      _0x55deba === _0x3ef008(0x26a) ? _0x3ef008(0x1bb) : _0x3ef008(0x265),
    _0x5512ff = {
      type: _0x55deba === "simulation" ? _0x3ef008(0x2ad) : _0x3ef008(0x184),
      details: [
        {
          id: _0x16ac06["id"],
          editDate: _0x16ac06[_0x3ef008(0x3a4)],
          details: _0x16ac06,
        },
      ],
    };
  return fetchWithRetry(
    _0x3ef008(0x2e1),
    _0x3ef008(0x1fe),
    _0x5512ff,
    (_0x24658b) => {
      const _0x525cd9 = _0x3ef008;
      _0x24658b &&
        _0x24658b[_0x525cd9(0x24c)] &&
        _0x24658b[_0x525cd9(0x24c)][_0x525cd9(0x349)] > 0x0 &&
        _0x24658b["failedIds"]["forEach"]((_0x3c2f03) =>
          addItemToArrayInLocalStore(_0x400a4c, _0x3c2f03)
        );
    },
    () => {
      addItemToArrayInLocalStore(_0x400a4c, _0x16ac06["id"]);
    },
    { Authorization: "Bearer\x20" + _0x57092f }
  );
}
async function sendStrategyDetailsNotSynced(_0x1ea485, _0x2b7e9f, _0x5cc82d) {
  const _0x1513e9 = a0_0xd18718;
  if (_0x1ea485 === _0x1513e9(0x37e)) return;
  let _0x4ce06b =
    _0x5cc82d === _0x1513e9(0x26a) ? _0x1513e9(0x1bb) : _0x1513e9(0x265);
  try {
    await autoSyncMutex["lock"]();
    let _0x43f070 = getArrayFromLocalStore(_0x4ce06b);
    localStorage[_0x1513e9(0x19f)](_0x4ce06b);
    if (_0x43f070[_0x1513e9(0x349)] === 0x0) return;
    let _0x4d2166 = [];
    for (let _0x2ab81b of _0x43f070) {
      let _0x4413de =
        _0x5cc82d === _0x1513e9(0x26a)
          ? await getEcbDatabase()["getSimStrategyDetails"](_0x2ab81b)
          : await getEcbDatabase()["getLiveStrategyDetails"](_0x2ab81b);
      _0x4413de && _0x4d2166[_0x1513e9(0x266)](_0x4413de);
    }
    if (_0x4d2166[_0x1513e9(0x349)] > 0x0) {
      const _0x344333 = _0x1513e9(0x2e1);
      let _0x4541e4 = {
        type: _0x5cc82d === _0x1513e9(0x26a) ? _0x1513e9(0x2ad) : "live",
        details: _0x4d2166[_0x1513e9(0x164)]((_0x1d1a23) => ({
          id: _0x1d1a23["id"],
          editDate: _0x4d2166["editDate"],
          details: _0x1d1a23,
        })),
      };
      await fetchWithRetry(
        _0x344333,
        _0x1513e9(0x1fe),
        _0x4541e4,
        (_0x142719) => {
          const _0x31e689 = _0x1513e9;
          _0x142719 &&
            _0x142719[_0x31e689(0x24c)] &&
            _0x142719[_0x31e689(0x24c)]["length"] > 0x0 &&
            _0x142719["failedIds"][_0x31e689(0x172)]((_0x1fb25d) =>
              addItemToArrayInLocalStore(_0x4ce06b, _0x1fb25d)
            );
        },
        () => {
          _0x4d2166["forEach"]((_0x1af181) =>
            addItemToArrayInLocalStore(_0x4ce06b, _0x1af181["id"])
          );
        },
        { Authorization: "Bearer\x20" + _0x1ea485 }
      );
    }
  } finally {
    autoSyncMutex[_0x1513e9(0x333)]();
  }
}
async function sendRemoveStrategyDetailsNotSynced(_0x47a074, _0x24ad93) {
  const _0x126147 = a0_0xd18718;
  if (_0x47a074 === _0x126147(0x37e)) return;
  const _0xb05e60 = _0x126147(0x2e1);
  let _0xf40975 =
    _0x24ad93 === _0x126147(0x26a) ? _0x126147(0x3b7) : _0x126147(0x2d9);
  try {
    await autoSyncMutex[_0x126147(0x39d)]();
    let _0x5b88e7 = getArrayFromLocalStore(_0xf40975);
    _0x5b88e7 &&
      _0x5b88e7["length"] > 0x0 &&
      (localStorage[_0x126147(0x19f)](_0xf40975),
      await fetchWithRetry(
        _0xb05e60,
        _0x126147(0x33c),
        _0x5b88e7,
        (_0x4e2ccc) => {
          const _0x4725c2 = _0x126147;
          _0x4e2ccc[_0x4725c2(0x24c)] &&
            _0x4e2ccc["failedIds"][_0x4725c2(0x172)]((_0x516e4e) => {
              addItemToArrayInLocalStore(_0xf40975, _0x516e4e);
            });
        },
        () => {
          const _0x1ee3fa = _0x126147;
          _0x5b88e7[_0x1ee3fa(0x172)]((_0x5b6db8) => {
            addItemToArrayInLocalStore(_0xf40975, _0x5b6db8);
          });
        },
        { Authorization: _0x126147(0x1e6) + _0x47a074 }
      ));
  } finally {
    autoSyncMutex[_0x126147(0x333)]();
  }
}
async function sendRemoveStrategyDetails(_0x43812d, _0x129614, _0x285da1) {
  const _0x32852f = a0_0xd18718;
  if (_0x43812d === "demo") return;
  let _0x170e98 = {
      type: _0x129614 === "simulation" ? _0x32852f(0x2ad) : _0x32852f(0x184),
      ids: [_0x285da1],
    },
    _0x469e0 =
      _0x129614 === "simulation" ? "removedSimDetailsIds" : _0x32852f(0x2d9);
  return (
    removeItemFromArrayInLocalStore(_0x469e0, _0x285da1),
    fetchWithRetry(
      _0x32852f(0x2e1),
      _0x32852f(0x33c),
      _0x170e98,
      (_0x362a90) => {
        const _0x316517 = _0x32852f;
        _0x362a90[_0x316517(0x24c)] &&
          _0x362a90[_0x316517(0x24c)][_0x316517(0x172)]((_0x31a492) => {
            addItemToArrayInLocalStore(_0x469e0, _0x31a492);
          });
      },
      () => {
        addItemToArrayInLocalStore(_0x469e0, _0x285da1);
      },
      { Authorization: _0x32852f(0x1e6) + _0x43812d }
    )
  );
}
async function saveStrategyEvent(_0x5bf1a4, _0x3f4030, _0x3ebc91) {
  const _0x50041a = a0_0xd18718;
  return (
    _0x5bf1a4 === _0x50041a(0x26a)
      ? getEcbDatabase()["storeSimStrategyEvent"](_0x3f4030)
      : getEcbDatabase()["storeLiveStrategyEvent"](_0x3f4030),
    sendEvent(_0x3ebc91, _0x5bf1a4, _0x3f4030)
  );
}
async function saveStrategTrade(_0x16aac8, _0x51db4a, _0x5ed2e9) {
  const _0x4c6e3f = a0_0xd18718;
  return (
    _0x16aac8 === _0x4c6e3f(0x26a)
      ? await getEcbDatabase()[_0x4c6e3f(0x1a7)](_0x51db4a)
      : await getEcbDatabase()["storeLiveStrategyTrade"](_0x51db4a),
    sendTrade(_0x5ed2e9, _0x16aac8, _0x51db4a)
  );
}
async function removeTrades(_0x4c4ee3, _0x59223c) {
  const _0x5b40c1 = a0_0xd18718;
  let _0x37708a = [];
  return (
    _0x4c4ee3 === "simulation"
      ? ((_0x37708a = await getEcbDatabase()[_0x5b40c1(0x1bc)](_0x59223c)),
        await getEcbDatabase()[_0x5b40c1(0x31c)](_0x59223c),
        _0x37708a[_0x5b40c1(0x172)]((_0x2229c8) => {
          const _0x3632b0 = _0x5b40c1;
          removeItemFromArrayInLocalStore(_0x3632b0(0x2c6), _0x2229c8["id"]);
        }))
      : ((_0x37708a = await getEcbDatabase()[_0x5b40c1(0x33d)](_0x59223c)),
        await getEcbDatabase()["deleteLiveStrategyTrades"](_0x59223c),
        _0x37708a[_0x5b40c1(0x172)]((_0x5495d4) => {
          const _0x36ed81 = _0x5b40c1;
          removeItemFromArrayInLocalStore(_0x36ed81(0x1a5), _0x5495d4["id"]);
        })),
    _0x37708a
  );
}
async function removeEvents(_0x3314c1, _0x3fb3f6) {
  const _0x586396 = a0_0xd18718;
  let _0x5818be = [];
  return (
    _0x3314c1 === "simulation"
      ? ((_0x5818be = await getEcbDatabase()["getSimStrategyEvents"](
          _0x3fb3f6
        )),
        await getEcbDatabase()[_0x586396(0x32b)](_0x3fb3f6))
      : ((_0x5818be = await getEcbDatabase()[_0x586396(0x370)](_0x3fb3f6)),
        await getEcbDatabase()[_0x586396(0x391)](_0x3fb3f6)),
    _0x5818be
  );
}
async function sendRemoveTrades(_0x4962a6, _0x293e47, _0x35aec1) {
  const _0x15226e = a0_0xd18718;
  if (_0x4962a6 === "demo") return;
  let _0x3a283b =
      _0x293e47 === _0x15226e(0x26a)
        ? _0x15226e(0x22a)
        : "removedTradesLiveIds",
    _0x42881e = {
      type: _0x293e47 === "simulation" ? "sim" : "live",
      ids: [_0x35aec1],
    };
  fetchWithRetry(
    _0x15226e(0x21c),
    "delete",
    _0x42881e,
    (_0x502fcd) => {
      const _0x3078a7 = _0x15226e;
      _0x502fcd["failedIds"] &&
        _0x502fcd[_0x3078a7(0x24c)]["forEach"]((_0x550c67) => {
          addItemToArrayInLocalStore(_0x3a283b, _0x550c67);
        });
    },
    () => {
      addItemToArrayInLocalStore(_0x3a283b, _0x35aec1);
    },
    { Authorization: _0x15226e(0x1e6) + _0x4962a6 }
  );
}
async function sendRemoveEvents(_0xe7124f, _0x218c3b, _0x2454de) {
  const _0x59042d = a0_0xd18718;
  if (_0xe7124f === "demo") return;
  let _0x298c8b = {
    type: _0x218c3b === _0x59042d(0x26a) ? _0x59042d(0x2ad) : _0x59042d(0x184),
    ids: [_0x2454de],
  };
  fetchWithRetry(_0x59042d(0x19a), _0x59042d(0x33c), _0x298c8b, null, null, {
    Authorization: "Bearer\x20" + _0xe7124f,
  });
}
function getStartDateForSingleBinanceCall(_0x5d57c1, _0x11ff11) {
  const _0x58c36c = a0_0xd18718;
  let _0x1655fd = new Date(_0x5d57c1[_0x58c36c(0x168)]());
  switch (_0x11ff11) {
    case _0x58c36c(0x212):
      _0x1655fd[_0x58c36c(0x1f0)](_0x1655fd[_0x58c36c(0x3b8)]() - 0x3b6);
      break;
    case "5\x20minutes":
      _0x1655fd[_0x58c36c(0x1f0)](_0x1655fd[_0x58c36c(0x3b8)]() - 0x3b6 * 0x5);
      break;
    case _0x58c36c(0x2ca):
      _0x1655fd[_0x58c36c(0x1f0)](_0x1655fd[_0x58c36c(0x3b8)]() - 0x3b6 * 0xf);
      break;
    case _0x58c36c(0x21d):
      _0x1655fd[_0x58c36c(0x1f0)](_0x1655fd[_0x58c36c(0x3b8)]() - 0x3b6 * 0x23);
      break;
    case _0x58c36c(0x378):
      _0x1655fd["setHours"](_0x1655fd[_0x58c36c(0x36f)]() - 0x3b6);
      break;
    case _0x58c36c(0x2a7):
      _0x1655fd[_0x58c36c(0x1c3)](_0x1655fd[_0x58c36c(0x36f)]() - 0x3b6 * 0x4);
      break;
    case _0x58c36c(0x2f8):
      _0x1655fd[_0x58c36c(0x1c3)](_0x1655fd[_0x58c36c(0x36f)]() - 0x3b6 * 0xc);
      break;
    case "1\x20day":
      _0x1655fd[_0x58c36c(0x1c3)](_0x1655fd["getHours"]() - 0x3b6 * 0x18);
      break;
    case "1\x20week":
      _0x1655fd["setHours"](_0x1655fd[_0x58c36c(0x36f)]() - 0x3b6 * 0x18 * 0x7);
      break;
    case _0x58c36c(0x274):
      _0x1655fd[_0x58c36c(0x1c3)](
        _0x1655fd[_0x58c36c(0x36f)]() - 0x3b6 * 0x18 * 0x1e
      );
      break;
    default:
      _0x1655fd["setHours"](_0x1655fd["getHours"]() - 0x5);
  }
  return _0x1655fd;
}
async function getContests(_0xb9c649) {
  const _0x3c5d57 = a0_0xd18718,
    _0x25d8ea = _0x3c5d57(0x293);
  return new Promise(async (_0x50d9c9) => {
    const _0x429e02 = _0x3c5d57;
    await fetchWithRetry(
      _0x25d8ea,
      _0x429e02(0x308),
      null,
      async (_0x4788a4) => {
        try {
          _0x4788a4 && _0xb9c649({ type: "contests", payload: _0x4788a4 }),
            _0x50d9c9();
        } catch (_0x37c03f) {
          _0x50d9c9();
        }
      },
      () => {
        _0x50d9c9();
      }
    );
  });
}
async function loadContestRanking(_0x47a438, _0x28696b, _0x130b5d) {
  const _0x44dba3 = a0_0xd18718,
    _0xbac7c3 =
      "https://api2.easycryptobot.com/contest-executions.php?m=" +
      _0x47a438 +
      _0x44dba3(0x159) +
      _0x28696b;
  return new Promise(async (_0x3b041a) => {
    const _0xe28fa5 = _0x44dba3;
    await fetchWithRetry(
      _0xbac7c3,
      _0xe28fa5(0x308),
      null,
      async (_0x272a77) => {
        const _0x5535d9 = _0xe28fa5;
        try {
          _0x272a77 &&
            _0x272a77[_0x5535d9(0x1f1)] &&
            ((_0x272a77[_0x5535d9(0x1f1)] = _0x272a77[_0x5535d9(0x1f1)]["map"](
              (_0x3cb7c9) =>
                convertOldStrategyContest(
                  _0x3cb7c9[_0x5535d9(0x1d3)],
                  _0x3cb7c9
                )
            )),
            _0x130b5d({ type: _0x5535d9(0x224), payload: _0x272a77 })),
            _0x3b041a();
        } catch (_0x47a5a6) {
          _0x3b041a();
        }
      },
      () => {
        _0x3b041a();
      }
    );
  });
}
async function getContestWinners(_0x15b331, _0x381516) {
  const _0x3f4e06 = a0_0xd18718;
  let _0x3885fa = [];
  for (let _0x380859 = 0x0; _0x380859 < 0x3; _0x380859++) {
    const _0x12113a =
      _0x3f4e06(0x180) + _0x15b331 + _0x3f4e06(0x159) + _0x380859;
    let _0x1736f9 = new Promise(async (_0xb3a38f) => {
        const _0x394c87 = _0x3f4e06;
        await fetchWithRetry(
          _0x12113a,
          _0x394c87(0x308),
          null,
          async (_0x1a386c) => {
            const _0x261deb = _0x394c87;
            try {
              if (_0x1a386c && _0x1a386c["executions"]) {
                (_0x1a386c[_0x261deb(0x1f1)] = _0x1a386c[_0x261deb(0x1f1)][
                  _0x261deb(0x164)
                ]((_0x18ef32) =>
                  convertOldStrategyContest(
                    _0x18ef32[_0x261deb(0x1d3)],
                    _0x18ef32
                  )
                )),
                  _0xb3a38f(_0x1a386c[_0x261deb(0x1f1)]);
                return;
              }
              _0xb3a38f();
            } catch (_0x149a8) {
              _0xb3a38f();
            }
          },
          () => {
            _0xb3a38f();
          }
        );
      }),
      _0x4fe2d8 = await _0x1736f9;
    _0x4fe2d8 &&
      _0x4fe2d8[_0x3f4e06(0x172)]((_0x2cfbd3) => {
        const _0x47ffb1 = _0x3f4e06;
        if (
          _0x3885fa[_0x47ffb1(0x349)] === 0xa ||
          _0x2cfbd3[_0x47ffb1(0x1ff)] < 0x0
        )
          return;
        let _0x4eb026 = _0x3885fa[_0x47ffb1(0x20c)]((_0x347ab) => {
          const _0xc6edee = _0x47ffb1;
          return _0x2cfbd3["strategy"][_0xc6edee(0x33f)]
            ? _0x347ab["strategy"][_0xc6edee(0x33f)] ===
                _0x2cfbd3["strategy"][_0xc6edee(0x33f)]
            : _0x347ab["strategy"]["nickname"] ===
                _0x2cfbd3["strategy"][_0xc6edee(0x27d)];
        });
        !_0x4eb026 && _0x3885fa[_0x47ffb1(0x266)](_0x2cfbd3);
      });
    if (_0x3885fa["length"] === 0xa) break;
  }
  _0x381516({ type: _0x3f4e06(0x339), payload: _0x3885fa });
}
function getUsdToAssetValue(_0x3915e9, _0x15db16, _0x1e428f) {
  const _0x5059e9 = a0_0xd18718;
  if (_0x3915e9 === 0x0) return _0x3915e9;
  if (
    _0x15db16 === _0x5059e9(0x33e) ||
    _0x15db16 === _0x5059e9(0x3b6) ||
    _0x15db16 === "BUSD"
  )
    return _0x3915e9;
  if (_0x1e428f[_0x15db16 + "USDT"])
    return _0x3915e9 / _0x1e428f[_0x15db16 + _0x5059e9(0x33e)];
  if (_0x1e428f[_0x15db16 + _0x5059e9(0x3b6)])
    return _0x3915e9 / _0x1e428f[_0x15db16 + _0x5059e9(0x3b6)];
  if (_0x1e428f[_0x15db16 + "BNB"])
    return (
      _0x3915e9 /
      _0x1e428f[_0x15db16 + _0x5059e9(0x3bb)] /
      _0x1e428f[_0x5059e9(0x1cb)]
    );
  if (_0x1e428f[_0x15db16 + "ETH"])
    return (
      _0x3915e9 /
      _0x1e428f[_0x15db16 + _0x5059e9(0x255)] /
      _0x1e428f[_0x5059e9(0x251)]
    );
  if (_0x1e428f[_0x15db16 + _0x5059e9(0x1af)])
    return (
      _0x3915e9 /
      _0x1e428f[_0x15db16 + _0x5059e9(0x1af)] /
      _0x1e428f[_0x5059e9(0x353)]
    );
  return 0x0;
}
function getQuotedValue(_0x2f1783, _0x3524c8, _0x315284, _0x2508ea) {
  const _0x33f5c6 = a0_0xd18718;
  if (_0x2f1783 === 0x0) return _0x2f1783;
  if (
    _0x3524c8 === _0x315284 ||
    (_0x3524c8 === _0x33f5c6(0x33e) && _0x315284 === _0x33f5c6(0x3b6)) ||
    (_0x3524c8 === _0x33f5c6(0x3b6) && _0x315284 === _0x33f5c6(0x33e))
  )
    return _0x2f1783;
  if (_0x2508ea["" + _0x3524c8 + _0x315284])
    return _0x2f1783 * _0x2508ea["" + _0x3524c8 + _0x315284];
  let _0x1e36c8 = getUsdValue(_0x2f1783, _0x3524c8, _0x2508ea);
  return getUsdToAssetValue(_0x1e36c8, _0x315284, _0x2508ea);
}
function getHoursFromTimeframes(_0x322263, _0x4dda82) {
  const _0x189bf5 = a0_0xd18718;
  let _0x13bd86 = 0x0,
    _0x253169 = 0x0;
  switch (_0x322263) {
    case "1\x20minute":
      (_0x13bd86 = Math["floor"](_0x4dda82 / 0x3c)),
        (_0x253169 = _0x4dda82 % 0x3c);
      break;
    case _0x189bf5(0x3c1):
      (_0x13bd86 = Math[_0x189bf5(0x211)](_0x4dda82 / 0xc)),
        (_0x253169 = (_0x4dda82 % 0xc) * 0x5);
      break;
    case _0x189bf5(0x2ca):
      (_0x13bd86 = Math[_0x189bf5(0x211)](_0x4dda82 / 0x4)),
        (_0x253169 = (_0x4dda82 % 0x4) * 0xf);
      break;
    case _0x189bf5(0x21d):
      (_0x13bd86 = Math[_0x189bf5(0x211)](_0x4dda82 / 0x2)),
        (_0x253169 = (_0x4dda82 % 0x2) * 0x1e);
      break;
    case _0x189bf5(0x378):
      _0x13bd86 = _0x4dda82;
      break;
    case "4\x20hours":
      _0x13bd86 = Math[_0x189bf5(0x211)](_0x4dda82 * 0x4);
      break;
    case "12\x20hours":
      _0x13bd86 = Math[_0x189bf5(0x211)](_0x4dda82 * 0xc);
      break;
    case "1\x20day":
      _0x13bd86 = Math[_0x189bf5(0x211)](_0x4dda82 * 0x18);
      break;
    default:
      return "";
  }
  return (
    "" +
    (_0x13bd86 > 0x0
      ? _0x13bd86 +
        "\x20" +
        (_0x13bd86 === 0x1 ? _0x189bf5(0x216) : _0x189bf5(0x306)) +
        (_0x253169 > 0x0 ? _0x189bf5(0x34c) : "")
      : "") +
    (_0x253169 > 0x0
      ? _0x253169 + "\x20" + (_0x253169 === 0x1 ? "min" : _0x189bf5(0x39f))
      : "")
  );
}
async function updatePLValues(_0x4f46de, _0x2b2a02) {
  const _0x2aa064 = a0_0xd18718;
  if (_0x4f46de === _0x2aa064(0x37e)) return;
  let _0x59a11f = await getEcbDatabase()[_0x2aa064(0x187)](_0x2b2a02),
    _0x313922 = await getEcbDatabase()[_0x2aa064(0x33b)](_0x2b2a02),
    _0x1b159a = await getEcbDatabase()[_0x2aa064(0x364)](_0x2b2a02),
    _0x20ef71 = await getEcbDatabase()[_0x2aa064(0x199)](_0x2b2a02),
    _0x1c8efb = await getEcbDatabase()[_0x2aa064(0x19d)](_0x2b2a02),
    _0x2c7e99 = await getEcbDatabase()["getStrategies"](_0x2b2a02);
  (_0x59a11f = _0x59a11f ? _0x59a11f : { id: _0x2b2a02 }),
    (_0x313922 = _0x313922 ? _0x313922 : { id: _0x2b2a02 }),
    _0x1c8efb["push"]({
      id: _0x2aa064(0x2d8),
      strategies: _0x2c7e99["map"]((_0x504acc) => _0x504acc["id"]),
    });
  let _0x447b98 = new Date();
  _0x447b98["setDate"](_0x447b98[_0x2aa064(0x22f)]() - 0x1);
  let _0x48e2de = _0x447b98["getTime"]();
  const _0x17f975 = { d: _0x48e2de },
    _0x178fe6 = { d: _0x48e2de };
  let _0x39783a = ![],
    _0x955a40 = ![];
  for (let _0x415232 of _0x1c8efb) {
    let _0xaee5e1 = ![],
      _0x5314d9 = ![],
      _0x3dfd7c = ![],
      _0x4983a0 = ![];
    (_0x415232[_0x2aa064(0x36a)] = 0x0),
      (_0x415232[_0x2aa064(0x174)] = 0x0),
      (_0x415232["allSim"] = 0x0),
      (_0x415232[_0x2aa064(0x209)] = 0x0);
    for (let _0x46b05f of _0x415232[_0x2aa064(0x23f)]) {
      const _0x156c85 = _0x2c7e99[_0x2aa064(0x20c)](
        (_0x4e5b03) => _0x4e5b03["id"] === _0x46b05f
      );
      if (!_0x156c85) continue;
      const _0x537adc = _0x1b159a[_0x2aa064(0x20c)](
          (_0x1efe4c) => _0x1efe4c["id"] === _0x46b05f
        ),
        _0x1267c9 = _0x20ef71["find"](
          (_0x543bdd) => _0x543bdd["id"] === _0x46b05f
        ),
        _0x1c1793 = _0x537adc ? _0x537adc["resultInQuoted"] : "",
        _0x3c4b86 = _0x1267c9 ? _0x1267c9[_0x2aa064(0x2c4)] : "",
        _0x4de609 = getExchange(_0x156c85[_0x2aa064(0x2d6)])[
          _0x2aa064(0x2fa)
        ]();
      if (
        _0x4de609 &&
        Object[_0x2aa064(0x301)](_0x4de609)[_0x2aa064(0x349)] > 0x0
      ) {
        if (_0x1c1793) {
          const _0x11086c = getUsdValue(
            _0x1c1793,
            _0x156c85[_0x2aa064(0x32f)][_0x2aa064(0x201)],
            _0x4de609
          );
          _0x11086c !== "" &&
            ((_0xaee5e1 = !![]),
            (_0x415232[_0x2aa064(0x36a)] += _0x11086c),
            _0x537adc &&
              _0x537adc[_0x2aa064(0x2e9)] === "Running" &&
              ((_0x415232["runLive"] += _0x11086c), (_0x3dfd7c = !![])));
        }
        if (_0x3c4b86) {
          const _0x4ae69b = getUsdValue(
            _0x3c4b86,
            _0x156c85[_0x2aa064(0x32f)]["quoteAsset"],
            _0x4de609
          );
          _0x4ae69b !== "" &&
            ((_0x415232[_0x2aa064(0x37b)] += _0x4ae69b),
            (_0x5314d9 = !![]),
            _0x1267c9 &&
              _0x1267c9["status"] === _0x2aa064(0x2a9) &&
              ((_0x415232[_0x2aa064(0x209)] += _0x4ae69b), (_0x4983a0 = !![])));
        }
      }
    }
    _0xaee5e1 &&
      ((_0x178fe6[_0x415232["id"]] = { all: _0x415232[_0x2aa064(0x36a)] }),
      _0x3dfd7c &&
        (_0x178fe6[_0x415232["id"]][_0x2aa064(0x2fe)] = _0x415232["runLive"]),
      (_0x39783a = !![])),
      _0x5314d9 &&
        ((_0x17f975[_0x415232["id"]] = { all: _0x415232[_0x2aa064(0x37b)] }),
        _0x4983a0 &&
          (_0x17f975[_0x415232["id"]][_0x2aa064(0x2fe)] =
            _0x415232[_0x2aa064(0x209)]),
        (_0x955a40 = !![]));
  }
  let _0x375e3f = {};
  _0x955a40 &&
    ((_0x375e3f[_0x2aa064(0x2ad)] = _0x17f975),
    _0x59a11f[_0x2aa064(0x262)][_0x2aa064(0x15d)](
      (_0x2a0e62, _0x1196de) => _0x2a0e62["d"] - _0x1196de["d"]
    ),
    _0x59a11f[_0x2aa064(0x262)][_0x2aa064(0x349)] === 0x1e &&
      _0x59a11f[_0x2aa064(0x262)]["shift"](),
    _0x59a11f[_0x2aa064(0x262)][_0x2aa064(0x266)](_0x17f975),
    await getEcbDatabase()[_0x2aa064(0x29c)](_0x59a11f)),
    _0x39783a &&
      ((_0x375e3f["live"] = _0x178fe6),
      _0x313922[_0x2aa064(0x262)]["sort"](
        (_0x31b514, _0xe60004) => _0x31b514["d"] - _0xe60004["d"]
      ),
      _0x313922[_0x2aa064(0x262)][_0x2aa064(0x349)] === 0x1e &&
        _0x313922["pls"][_0x2aa064(0x389)](),
      _0x313922[_0x2aa064(0x262)][_0x2aa064(0x266)](_0x178fe6),
      await getEcbDatabase()[_0x2aa064(0x2e7)](_0x313922)),
    (_0x955a40 || _0x39783a) &&
      (await fetchWithRetry(
        _0x2aa064(0x298),
        _0x2aa064(0x1fe),
        _0x375e3f,
        () => {},
        () => {},
        { Authorization: _0x2aa064(0x1e6) + _0x4f46de }
      ));
}
async function updatePLValuesFromServer(_0x2c3af3, _0x50e43a) {
  const _0x4985fd = a0_0xd18718;
  let _0x1bd919 = await getEcbDatabase()[_0x4985fd(0x187)](_0x50e43a),
    _0x47235a = await getEcbDatabase()[_0x4985fd(0x33b)](_0x50e43a),
    _0x555b34 = [],
    _0x43110a = [];
  await fetchWithRetry(
    "https://api2.easycryptobot.com/daily-pls.php",
    _0x4985fd(0x308),
    null,
    (_0x26cc16) => {
      const _0x59f467 = _0x4985fd;
      _0x26cc16 &&
        (_0x26cc16["sim"] && (_0x555b34 = _0x26cc16[_0x59f467(0x2ad)]),
        _0x26cc16["sim"] && (_0x43110a = _0x26cc16[_0x59f467(0x184)]));
    },
    () => {},
    { Authorization: _0x4985fd(0x1e6) + _0x2c3af3 }
  );
  let _0x5bb1f9 = ![],
    _0x3d8e91 = ![];
  if (_0x1bd919 && _0x555b34 && _0x555b34[_0x4985fd(0x349)] > 0x0)
    _0x555b34[_0x4985fd(0x172)]((_0x49dcd1) => {
      const _0x5e680e = _0x4985fd;
      _0x1bd919[_0x5e680e(0x262)]["findIndex"](
        (_0x4a76d6) => _0x4a76d6["d"] === _0x49dcd1["d"]
      ) === -0x1 &&
        (_0x1bd919[_0x5e680e(0x262)][_0x5e680e(0x266)](_0x49dcd1),
        (_0x5bb1f9 = !![]));
    });
  else
    _0x555b34 &&
      _0x555b34[_0x4985fd(0x349)] > 0x0 &&
      ((_0x1bd919 = { id: _0x50e43a, pls: _0x555b34 }), (_0x5bb1f9 = !![]));
  if (_0x47235a && _0x43110a && _0x43110a[_0x4985fd(0x349)] > 0x0)
    _0x43110a["forEach"]((_0x219179) => {
      const _0x53ffea = _0x4985fd;
      _0x47235a[_0x53ffea(0x262)][_0x53ffea(0x3b3)](
        (_0x5aff7a) => _0x5aff7a["d"] === _0x219179["d"]
      ) === -0x1 &&
        (_0x47235a[_0x53ffea(0x262)][_0x53ffea(0x266)](_0x219179),
        (_0x3d8e91 = !![]));
    });
  else
    _0x43110a &&
      _0x43110a[_0x4985fd(0x349)] > 0x0 &&
      ((_0x47235a = { id: _0x50e43a, pls: _0x43110a }), (_0x3d8e91 = !![]));
  _0x5bb1f9 &&
    (_0x1bd919[_0x4985fd(0x262)]["sort"](
      (_0x45fb6a, _0x4fa3fd) => _0x45fb6a["d"] - _0x4fa3fd["d"]
    ),
    _0x1bd919[_0x4985fd(0x262)][_0x4985fd(0x349)] > 0x1e &&
      _0x1bd919[_0x4985fd(0x262)][_0x4985fd(0x27c)](
        0x0,
        _0x1bd919[_0x4985fd(0x262)][_0x4985fd(0x349)] - 0x1e
      ),
    await getEcbDatabase()[_0x4985fd(0x29c)](_0x1bd919)),
    _0x3d8e91 &&
      (_0x47235a[_0x4985fd(0x262)][_0x4985fd(0x15d)](
        (_0x150bb9, _0x534148) => _0x150bb9["d"] - _0x534148["d"]
      ),
      _0x47235a["pls"][_0x4985fd(0x349)] > 0x1e &&
        _0x47235a[_0x4985fd(0x262)]["splice"](
          0x0,
          _0x47235a[_0x4985fd(0x262)]["length"] - 0x1e
        ),
      await getEcbDatabase()[_0x4985fd(0x2e7)](_0x47235a));
}
async function checkPLUpdateNeeded(_0xd7b67c, _0x5b001c) {
  const _0x218b4c = a0_0xd18718;
  updatePLValuesFromServer(_0xd7b67c, _0x5b001c);
  while (!![]) {
    try {
      let _0x306f2f = new Date();
      while (_0x306f2f[_0x218b4c(0x22f)]() === new Date()[_0x218b4c(0x22f)]()) {
        await sleep(0x3e8 * 0x3c * 0xa);
      }
      await updatePLValues(_0xd7b67c, _0x5b001c);
    } catch {
      console["log"]("error\x20storing\x20PL\x20values");
    }
  }
}
export {
  generateUniqueId,
  verifyUser,
  showInfoDialog,
  showConfirmDialog,
  getTimestamp,
  formatDateTime,
  formatDate,
  getDatesDiff,
  showTime,
  sleep,
  getStartDate,
  fixNumber,
  rr,
  rrOld,
  ss,
  deleteApiKey,
  encrypt,
  decrypt,
  roundQuotedValue,
  roundBaseValue,
  getUsdValue,
  fetchWithRetry,
  autoSync,
  autoUpdateExecutionStatus,
  updateExecutionStatus,
  getIndicatorSetup,
  resetIndicatorSetup,
  checkStrategyParams,
  checkRuleParams,
  checkForNewVersion,
  getRoutes,
  getUserData,
  addItemToArrayInLocalStore,
  removeItemFromArrayInLocalStore,
  stringToDate,
  addError,
  isVisibleById,
  formatDateTime2,
  formatDateTime3,
  stringToDate2,
  fixStrategyVersion,
  getInterval,
  getTimeframeFromInterval,
  sendTrade,
  sendEvent,
  sendStrategy,
  getUsers,
  initializeWorker,
  autoSyncHomePage,
  getStrategyGroups,
  saveStrategyGroup,
  deleteStrategyGroup,
  saveWatchlist,
  deleteWatchlist,
  getWatchlists,
  getMultiCharts,
  isOnMobile,
  getFromLocalStorage,
  pricesUpdateDelay,
  binanceMinPairs,
  binanceUSMinPairs,
  formatDate2,
  autoSyncStrategyGroups,
  sendRemoveStrategiesNotSynced,
  checkInvalidIndicatorSetting,
  checkInvalidIndicatorSettingsAll,
  getIndicatorSettings,
  saveIndicators,
  getIndicators,
  attachCumulativesData,
  attachDistributionsData,
  attachTradesData,
  attachChartData,
  deleteMultiChart,
  saveMultiChart,
  removeTrades,
  sendStrategyStatus,
  sendRemoveStrategyStatus,
  saveStrategyEvent,
  saveStrategTrade,
  removeEvents,
  sendRemoveTrades,
  sendRemoveEvents,
  getStrategyTrades,
  sendRemoveStrategyDetails,
  autoAppSync,
  sendRemoteStrategyStop,
  sendRemoteStrategySell,
  getStartDateForSingleBinanceCall,
  sendTrades,
  getUserTrades,
  getUserEvents,
  getContestWinners,
  loadContestRanking,
  getContests,
  getLimits,
  sendNewOptimization,
  convertOldStrategyDemo,
  getQuotedValue,
  getHoursFromTimeframes,
  getStrategiesTraficSaver,
  pairsDetailsAutoSync,
  comparePairDetails,
  getStrategiesDetail,
  formatDateTime4,
  formatDateTime5,
  checkPLUpdateNeeded,
};
