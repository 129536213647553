import React, { useReducer } from "react";
import { globalContext, globalContextReducer, initialState } from "./Context.js";
import { decrypt } from "./utils.js";

const GlobalContextProvider = (props) => {
  let [state, dispatch] = useReducer(globalContextReducer, initialState);
  let value = { state, dispatch };

  let ecbToken = localStorage.getItem("ecbToken");
  if (state.user.id !== "demo" && ecbToken) {
    try {
      state.user.token = decrypt(ecbToken);

      let lockedState = localStorage.getItem("lockedState");
      if (lockedState) {
        try {
          lockedState = decrypt(lockedState, state.user.token);
        } catch (e) {}
      }
      state.locked = lockedState !== "unlocked";
    } catch (e) {}
  }

  let tradeInfoSound = localStorage.getItem("tradeInfoSound");
  if (tradeInfoSound) {
    state.tradeInfoSound = tradeInfoSound;
  }

  let manualTradeConfirm = localStorage.getItem("manualTradeConfirm");
  if (manualTradeConfirm !== null) {
    state.manualTradeConfirm = manualTradeConfirm;
  }

  let commissionRate = localStorage.getItem("commissionRate");
  if (commissionRate) {
    try {
      state.commissionRate = Number.parseFloat(commissionRate);
    } catch (e) {}
  }

  return <globalContext.Provider value={value}>{props.children}</globalContext.Provider>;
};

let GlobalContextConsumer = globalContext.Consumer;

export { GlobalContextConsumer, GlobalContextProvider };
