import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import loading from "./assets/images/loading-reverced.gif";
import layoutPanels1 from "./assets/images/layoutPanels1.png";
import layoutPanels2 from "./assets/images/layoutPanels2.png";
import layoutPanels22 from "./assets/images/layoutPanels22.png";
import layoutPanels3 from "./assets/images/layoutPanels3.png";
import { globalContext } from "./Context.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, DropdownWithSearch } from "./Dropdown.js";
import { ModalConfirm } from "./Modal.js";
import {
  faPlus,
  faCog,
  faEdit,
  faExclamationTriangle,
  faPuzzlePiece,
  faCogs,
  faSearchPlus,
  faHistory,
  faLayerGroup,
  faCompressAlt,
  faExpandArrowsAlt,
  faChartLine,
  faAngleRight,
  faAngleLeft,
  faRandom,
  faSearch,
  faPlusCircle,
  faTimesCircle,
  faArrowAltCircleRight,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";

import { exchanges } from "./constants/consts.json";
import { generateUniqueId, showInfoDialog, getFromLocalStorage, saveStrategyGroup, sleep } from "./utils.js";
import $ from "jquery";
import {
  subscribeForBacktests,
  unsubscribeForBacktests,
  getBacktests,
  getOptimizations,
  subscribeForOptimizations,
  unsubscribeForOptimizations,
} from "./Bot.js";
import { BacktestSettings, BacktestResult } from "./Backtest.js";
import { OptimizationSettings, OptimizationResult } from "./Optimizations.js";
import { StrategyToEdit, InspectStrategy, StrategyGroups } from "./Strategies.js";
import { ExecutionsTable, ExecutionsCharts, TradingSetup, TradingResult } from "./Trading";
import getEcbDatabase from "./EcbDatabase";
import ResizablePanels from "./ResizablePanels";
import { NavLink } from "react-router-dom";
import { RadioButtons } from "./RadioButtons.js";

const strategyVersion = 4;
const statusVersion = 2;

const StrategyActions = (props) => {
  const { state, dispatch } = useContext(globalContext);
  const [backtests, setBacktests] = useState(getBacktests());
  const [backtestResult, setBacktestResult] = useState();
  const [optimizations, setOptimizations] = useState(getOptimizations());
  const [optimizationResult, setOptimizationResult] = useState();
  const [strategyTabs] = useState(
    props.freeBacktest
      ? [{ label: "Backtest", icon: faHistory }]
      : [
          { label: "Details", icon: faSearchPlus },
          { label: "Backtest", icon: faHistory },
          { label: "Optimization", icon: faLayerGroup },
          { label: "Simulation", icon: faRandom },
          { label: "Trade Live", icon: faChartLine },
        ]
  );
  const [strategyTabsWidth] = useState(530);
  const [dropdownHeightSubtract] = useState(118);
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.selectedStrategyId) {
      setBacktestResult(null);
      setOptimizationResult(null);
      let selectedStrategyIdTmp = props.selectedStrategyIdRef.current;
      getEcbDatabase()
        .getBacktests(props.selectedStrategyId)
        .then((backtests) => {
          if (props.selectedStrategyIdRef.current !== selectedStrategyIdTmp) {
            return;
          }
          if (componentIsMounted.current && backtests.length > 0) {
            setBacktestResult(backtests[0].backtest);
          }
        });
      getEcbDatabase()
        .getOptimizations(props.selectedStrategyId)
        .then((optimizations) => {
          if (props.selectedStrategyIdRef.current !== selectedStrategyIdTmp) {
            return;
          }
          if (componentIsMounted.current && optimizations.length > 0) {
            setOptimizationResult(optimizations[0].optimization);
          }
        });
    } else {
      setBacktestResult(null);
      setOptimizationResult(null);
    }
  }, [props.selectedStrategyId, state.strategies]);

  useEffect(() => {
    subscribeForBacktests("strategies", (backtests) => {
      if (componentIsMounted.current) {
        setBacktests(backtests);
      }
    });
    subscribeForOptimizations("strategies", (optimizations) => {
      if (componentIsMounted.current) {
        setOptimizations(optimizations);
      }
    });
    return () => {
      unsubscribeForBacktests("strategies");
      unsubscribeForOptimizations("strategies");
    };
  }, []);

  return (
    <>
      {(props.smallWidthDevice || props.singlePage) && (
        <div className="text-nowrap">
          <FontAwesomeIcon className="text-info mr-2" icon={faSearch} />
          <DropdownWithSearch
            classesInput={`search-bar text-left h-33 pb-2 px-2 ${props.freeBacktest ? "mb-2" : ""}`}
            dropMenuClasses={`bg-new-dark border border-secondary rounded w-350 small left-0 ${
              props.panelHeight ? "" : "max-h-250"
            }`}
            dropMenuItemsClasses="text-light items-info text-wrap"
            menuStyle={
              props.panelHeight ? { maxHeight: Math.max(100, props.panelHeight - dropdownHeightSubtract) } : {}
            }
            placeholder="Strategy"
            selected=""
            setSelected={(strategy) => {
              if (!props.selectedStrategy || props.selectedStrategy.id !== strategy.id) {
                props.setSelectedStrategyIdCallback(strategy.id);
              }
            }}
            deleteSearchText={true}
            closeOnEsc={true}
            showOnFocus={true}
            onBlur={() => {}}
            items={state.strategies.map((el) => ({
              id: el.id,
              name: el.name,
            }))}
          />
        </div>
      )}

      <div
        className={`d-flex overflow-hidden user-select-none ${
          props.freeBacktest ? "" : "hover-white active-border mb-3"
        }`}
      >
        {props.singlePage && !props.freeBacktest && (
          <a
            href="#/"
            className={`text-info pt-1 pb-2 mr-2 ${props.panelWidth > strategyTabsWidth ? "pr-2 " : "pr-3"}`}
            title="Create Strategy"
            onClick={(e) => {
              e.preventDefault();
              document.activeElement.blur();

              props.setSaveFromOptimization(false);
              props.setEditType(
                <>
                  <FontAwesomeIcon icon={faPuzzlePiece} /> New Strategy
                </>
              );
              props.setStrategyToEdit({
                id: generateUniqueId(state.strategies, state.user.id, "id"),
                editDate: new Date().getTime(),
                version: strategyVersion,
                name: "",
                buyRules: [],
                sellRules: [],
                tBuy: "",
                tBuyWait: "",
                stoploss: "",
                target: "",
                trailingTarget: "",
                timeClose: "",
                exchange: state.demo || props.freeBacktest ? exchanges[1] : exchanges[0],
                pair: state.demo ? "BTCUSDT" : "",
                pairDetails: state.demo
                  ? {
                      baseMinQty: 0.000001,
                      baseMaxQty: 9000,
                      baseStepSize: 0.000001,
                      minPrice: 0.01,
                      maxPrice: 1000000,
                      tickSize: 0.01,
                      baseDecimal: 6,
                      quotedDecimal: 2,
                      baseAsset: "BTC",
                      quoteAsset: "USDT",
                    }
                  : null,
                userId: state.user.id,
                sim: {
                  maxDrawdown: "",
                  maxLoss: "",
                  maxNegTrades: "",
                  commissionRate: "",
                  orderDetails: { type: "market", value: "" },
                  tradeSize: { type: "base", value: "" },
                },
                live: {
                  maxDrawdown: "",
                  maxLoss: "",
                  maxNegTrades: "",
                  commissionRate: "",
                  orderDetails: { type: "market", value: "" },
                  tradeSize: { type: "base", value: "" },
                },
              });
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="" />
          </a>
        )}
        {props.selectedStrategy && !props.freeBacktest && (
          <a
            href="#/"
            className={`text-info pt-1 pb-2 mr-2 ${
              props.panelWidth > strategyTabsWidth
                ? props.singlePage
                  ? "px-2"
                  : "pr-2 "
                : props.singlePage
                ? "px-3"
                : "pr-3"
            }`}
            title="Edit"
            onClick={(e) => {
              e.preventDefault();
              document.activeElement.blur();
              if (
                !state.app &&
                !state.demo &&
                (state.simStatuses[props.selectedStrategy.id] || state.liveStatuses[props.selectedStrategy.id])
              ) {
                showInfoDialog(
                  dispatch,
                  <span className="text-danger">
                    <FontAwesomeIcon icon={faExclamationTriangle} /> Cannot edit in the browser
                  </span>,
                  <>
                    Cannot edit in the browser strategies used in live or simulated trading.
                    <br />
                    Edit the strategy from the running app.
                  </>
                );
                return;
              }

              props.setStrategyToEdit(props.selectedStrategy);
              props.setSaveFromOptimization(false);
              props.setEditType(
                <>
                  <FontAwesomeIcon icon={faEdit} /> Edit Strategy
                </>
              );
            }}
          >
            <FontAwesomeIcon icon={faEdit} className="" />
          </a>
        )}
        {(!props.freeBacktest || !props.singlePage) &&
          strategyTabs.map((tab) => (
            <a
              className={`text-info text-nowrap mr-2 pt-1 pb-2 ${
                props.strategyActiveSubTab === tab.label ? "active" : ""
              } ${props.panelWidth > strategyTabsWidth ? "px-2 " : "px-3"}`}
              key={tab.label}
              href="/#"
              title={props.panelWidth > strategyTabsWidth ? "" : tab.label}
              onClick={(e) => {
                e.preventDefault();
                document.activeElement.blur();
                props.setStrategyActiveSubTabCallback(tab.label);
              }}
            >
              {props.panelWidth > strategyTabsWidth || props.freeBacktest ? (
                tab.label
              ) : (
                <FontAwesomeIcon icon={tab.icon} className="" />
              )}
            </a>
          ))}
        {props.setPanelExpanded && (
          <a
            className={`text-light-green text-nowrap ml-auto pt-1 pb-2 ${
              props.panelWidth > strategyTabsWidth ? "pl-2 " : "pl-3"
            }`}
            href="/#"
            title={props.panelExpanded ? "Collapse" : "Expand"}
            onClick={(e) => {
              e.preventDefault();
              document.activeElement.blur();
              props.setPanelExpanded(!props.panelExpanded);
            }}
          >
            {props.panelExpanded ? (
              <FontAwesomeIcon className="small fade-in" icon={faCompressAlt} />
            ) : (
              <FontAwesomeIcon className="small fade-in" icon={faExpandArrowsAlt} />
            )}
          </a>
        )}
      </div>

      {props.selectedStrategy ? (
        <div className="">
          {props.strategyActiveSubTab !== "Details" && (
            <>
              <ul className="nav nav-tabs flex-nowrap hover-white user-select-none small">
                <li className="nav-item">
                  <div
                    className={`nav-link text-info hover-light cursor-pointer ${
                      props.strategyActiveSubTabResult ? " hover-noborder" : "active"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      props.setStrategyActiveSubTabResult(false);
                    }}
                  >
                    Setup
                  </div>
                </li>
                <li className="nav-item">
                  <div
                    className={`nav-link text-info hover-light cursor-pointer  ${
                      props.strategyActiveSubTabResult ? "active" : " hover-noborder"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      props.setStrategyActiveSubTabResult(true);
                    }}
                  >
                    Results
                  </div>
                </li>
                <li
                  className={`h5 text-truncate mr-1 mt-1 ${props.panelWidth > 500 ? "pl-3" : "pl-2"}`}
                  style={{ width: props.panelWidth - 195 }}
                >
                  {props.selectedStrategy.name}
                </li>
                <li className="ml-auto mt-1">
                  {props.panelWidth > 600 && (
                    <div className="cursor-help tooltip-parent">
                      <div className="h6 text-info">
                        <FontAwesomeIcon icon={faSearchPlus} />
                      </div>

                      <div className="tooltip-on-hover right-0 top-3 border border-info rounded bg-new-dark min-w-hover-strategy p-3">
                        <div className="pb-2">
                          <InspectStrategy
                            labelsColor="text-light-green"
                            strategy={props.selectedStrategy}
                            componentIsMounted={componentIsMounted.current}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </li>
              </ul>
              {!props.strategyActiveSubTabResult &&
                (props.strategyActiveSubTab === "Simulation" || props.strategyActiveSubTab === "Trade Live") && (
                  <div className="pt-1 pb-1">
                    {state.setupHidden ? (
                      <a
                        href="#/"
                        className="text-light-green small"
                        title="Expand setup"
                        onClick={(e) => {
                          e.preventDefault();
                          document.activeElement.blur();
                          dispatch({ type: "setupHidden", payload: false });
                        }}
                      >
                        <FontAwesomeIcon icon={faCog} /> <FontAwesomeIcon icon={faAngleRight} />
                      </a>
                    ) : (
                      <a
                        href="#/"
                        className="text-light-green small"
                        title="Collapse setup"
                        onClick={(e) => {
                          e.preventDefault();
                          document.activeElement.blur();
                          dispatch({ type: "setupHidden", payload: true });
                        }}
                      >
                        <FontAwesomeIcon icon={faAngleLeft} /> <FontAwesomeIcon icon={faCog} />
                      </a>
                    )}
                  </div>
                )}

              <div
                className={`overflow-auto ${
                  !props.strategyActiveSubTabResult &&
                  (props.strategyActiveSubTab === "Simulation" || props.strategyActiveSubTab === "Trade Live")
                    ? ""
                    : "mt-4"
                }`}
                style={props.panelHeight ? { maxHeight: Math.max(100, props.panelHeight - 131) } : {}}
              >
                {!props.strategyActiveSubTabResult
                  ? (props.strategyActiveSubTab === "Backtest" && (
                      <BacktestSettings
                        strategy={props.selectedStrategy}
                        backtests={backtests}
                        runningBacktest={backtests[props.selectedStrategyId]}
                        currentBacktestResult={backtestResult}
                        setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
                        setResult={setBacktestResult}
                        rightPanelWidth={props.panelWidth}
                      />
                    )) ||
                    (props.strategyActiveSubTab === "Optimization" && (
                      <OptimizationSettings
                        strategy={props.selectedStrategy}
                        optimizations={optimizations}
                        runningOptimization={optimizations[props.selectedStrategyId]}
                        setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
                        setResult={setOptimizationResult}
                        currentOptimizationResult={optimizationResult}
                        rightPanelWidth={props.panelWidth}
                      />
                    )) ||
                    (props.strategyActiveSubTab === "Simulation" && (
                      <TradingSetup
                        id={`${props.selectedStrategy.id}_simSetup`}
                        strategy={props.selectedStrategy}
                        type="simulation"
                        rightPanelWidth={props.panelWidth}
                        topPanelHeight={props.panelHeight ? props.panelHeight : 600}
                        setupHidden={state.setupHidden}
                      />
                    )) ||
                    (props.strategyActiveSubTab === "Trade Live" && (
                      <TradingSetup
                        id={`${props.selectedStrategy.id}_tradeSetup`}
                        strategy={props.selectedStrategy}
                        type="trading"
                        rightPanelWidth={props.panelWidth}
                        topPanelHeight={props.panelHeight ? props.panelHeight : 600}
                        setupHidden={state.setupHidden}
                      />
                    ))
                  : (props.strategyActiveSubTab === "Backtest" && (
                      <BacktestResult
                        backtest={backtestResult}
                        backtests={backtests}
                        strategy={props.selectedStrategy}
                        runningBacktest={backtests[props.selectedStrategyId]}
                        setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
                        setResult={setBacktestResult}
                        topPanelHeight={props.panelHeight ? props.panelHeight : 600}
                      />
                    )) ||
                    (props.strategyActiveSubTab === "Optimization" && (
                      <OptimizationResult
                        optimization={optimizationResult}
                        optimizations={optimizations}
                        strategy={props.selectedStrategy}
                        runningOptimization={optimizations[props.selectedStrategyId]}
                        setResult={setOptimizationResult}
                        setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
                        saveStrategy={(strategy) => {
                          props.setStrategyToEdit(strategy);
                          props.setSaveFromOptimization(true);
                          props.setEditType(
                            <>
                              <FontAwesomeIcon icon={faCogs} /> Save Optimization
                            </>
                          );
                        }}
                        rightPanelWidth={props.panelWidth}
                        topPanelHeight={props.panelHeight ? props.panelHeight : 600}
                      />
                    )) ||
                    (props.strategyActiveSubTab === "Simulation" && (
                      <TradingResult
                        strategy={props.selectedStrategy}
                        type="simulation"
                        setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
                        rightPanelWidth={props.panelWidth}
                        topPanelHeight={props.panelHeight ? props.panelHeight : 600}
                        smallWidthDevice={props.smallWidthDevice}
                      />
                    )) ||
                    (props.strategyActiveSubTab === "Trade Live" && (
                      <TradingResult
                        strategy={props.selectedStrategy}
                        type="trading"
                        setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
                        rightPanelWidth={props.panelWidth}
                        topPanelHeight={props.panelHeight ? props.panelHeight : 600}
                        smallWidthDevice={props.smallWidthDevice}
                      />
                    ))}
              </div>
            </>
          )}
          {props.strategyActiveSubTab === "Details" && (
            <div
              className="overflow-auto  fade-in mt-2 "
              style={props.panelHeight ? { maxHeight: Math.max(100, props.panelHeight - 70) } : {}}
            >
              <InspectStrategy
                showName={true}
                labelsColor="text-light-green"
                strategy={props.selectedStrategy}
                componentIsMounted={componentIsMounted.current}
                setStrategyToEdit={props.setStrategyToEdit}
                setEditType={props.setEditType}
                setSaveFromOptimization={props.setSaveFromOptimization}
              />
            </div>
          )}
        </div>
      ) : (
        <h5 className="text-secondary text-center mt-5">
          {state.strategies.length > 0
            ? props.freeBacktest
              ? "Select strategy to backtest"
              : "Select strategy from the group"
            : "Create a strategy to get started"}
        </h5>
      )}

      {!state.strategiesInitialized && (
        <div className="loading-img cursor-help my-5" title="Loading..">
          <img src={loading} alt="" />
        </div>
      )}
    </>
  );
};

const StrategiesTradingTable = (props) => {
  const { state, dispatch } = useContext(globalContext);
  const [dropdownHeightSubtract] = useState(118);
  return (
    <>
      {props.showGroupsDropdown && (
        <Dropdown
          btnClasses="d-flex text-light hover-border-bottom info pl-0 pb-2 mb-2"
          arrow
          arrowClasses="mt-1"
          selectedClasses="text-truncate max-w-270 mr-1"
          menuClasses={`bg-new-dark border border-secondary rounded small w-250 py-1 ${
            props.panelHeight ? "" : "max-h-250"
          }`}
          menuItemsClasses="text-light hover-info px-3 py-1"
          menuStyle={props.panelHeight ? { maxHeight: Math.max(100, props.panelHeight - dropdownHeightSubtract) } : {}}
          selected={props.activeGroup ? props.activeGroup.name : state.strategyGroups[0].name}
          setSelected={(group) => {
            props.setActiveGroupIdCallback(group.id);
          }}
          items={state.strategyGroups.filter((gr) => !gr.isDefault)}
          additionalOptions={[
            {
              label: (
                <div className="d-flex">
                  Create Group
                  <FontAwesomeIcon className="ml-auto mt-1" icon={faPlus} />
                </div>
              ),
              classes: "text-info border-bottom border-secondary px-3 pt-1 pb-2",
              fn: () => {
                if (!$("#setGroup").is(":visible")) {
                  $("#setGroup").modal("toggle");
                }
              },
            },
            {
              label: state.strategyGroups[0].name,
              classes: "text-light hover-info px-3 pt-2 pb-1",
              fn: () => {
                props.setActiveGroupIdCallback(state.strategyGroups[0].id);
              },
            },
            {
              label: state.strategyGroups[1].name,
              classes: "text-light hover-info px-3 pt-1 pb-2",
              fn: () => {
                props.setActiveGroupIdCallback(state.strategyGroups[1].id);
              },
            },
            {
              label: state.strategyGroups[2].name,
              classes: "text-light hover-info px-3 pt-1 pb-2",
              fn: () => {
                props.setActiveGroupIdCallback(state.strategyGroups[2].id);
              },
            },
            {
              label: state.strategyGroups[3].name,
              classes: `text-light hover-info px-3 pt-1 pb-2 ${
                state.strategyGroups.length > 4 ? "border-bottom border-secondary mb-1" : ""
              }`,
              fn: () => {
                props.setActiveGroupIdCallback(state.strategyGroups[3].id);
              },
            },
          ]}
        />
      )}

      {state.strategies.length > 0 && (
        <>
          <ul className="nav nav-tabs hover-white user-select-none mb-3" role="tablist">
            <li className="nav-item">
              <div
                className={`nav-link text-info hover-light cursor-pointer ${
                  props.tradingActiveSubTab === "simulation" ? "active" : "hover-noborder"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  props.setTradingActiveSubTabCallback("simulation");
                }}
              >
                Simulations
              </div>
            </li>
            <li className="nav-item">
              <div
                className={`nav-link text-info hover-light cursor-pointer ${
                  props.tradingActiveSubTab === "trading" ? "active" : "hover-noborder"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  props.setTradingActiveSubTabCallback("trading");
                }}
              >
                Trade Live
              </div>
            </li>
            {props.setPanelExpanded && (
              <li className="ml-auto">
                <a
                  className="text-light-green"
                  href="/#"
                  title={props.panelExpanded ? "Collapse" : "Expand"}
                  onClick={(e) => {
                    e.preventDefault();
                    document.activeElement.blur();
                    props.setPanelExpanded(!props.panelExpanded);
                  }}
                >
                  {props.panelExpanded ? (
                    <FontAwesomeIcon className="small fade-in" icon={faCompressAlt} />
                  ) : (
                    <FontAwesomeIcon className="small fade-in" icon={faExpandArrowsAlt} />
                  )}
                </a>
              </li>
            )}
          </ul>
          <div className="">
            {props.tradingActiveSubTab === "simulation" ? (
              <ExecutionsTable
                selectedStrategyId={props.selectedStrategyId}
                setSelectedStrategyId={props.setSelectedStrategyIdCallback}
                setStrategyActiveSubTab={props.setStrategyActiveSubTabCallback}
                setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
                activeGroup={props.activeGroup}
                setActiveGroupIdCallback={props.setActiveGroupIdCallback}
                type={"simulation"}
                panelHeight={props.panelHeight}
                smallWidthDevice={props.smallWidthDevice}
              />
            ) : (
              <ExecutionsTable
                selectedStrategyId={props.selectedStrategyId}
                setSelectedStrategyId={props.setSelectedStrategyIdCallback}
                setStrategyActiveSubTab={props.setStrategyActiveSubTabCallback}
                setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
                activeGroup={props.activeGroup}
                setActiveGroupIdCallback={props.setActiveGroupIdCallback}
                type={"trading"}
                panelHeight={props.panelHeight}
                smallWidthDevice={props.smallWidthDevice}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

const StrategiesTradingChart = (props) => {
  const { state, dispatch } = useContext(globalContext);
  const [dropdownHeightSubtract] = useState(118);
  return (
    <>
      {props.showGroupsDropdown && (
        <Dropdown
          btnClasses="d-flex text-light hover-border-bottom info pl-0 pb-2 mb-2"
          arrow
          arrowClasses="mt-1"
          selectedClasses="text-truncate max-w-270 mr-1"
          menuClasses={`bg-new-dark border border-secondary rounded small w-250 py-1 ${
            props.panelHeight ? "" : "max-h-250"
          }`}
          menuItemsClasses="text-light hover-info px-3 py-1"
          menuStyle={props.panelHeight ? { maxHeight: Math.max(100, props.panelHeight - dropdownHeightSubtract) } : {}}
          selected={props.activeGroup ? props.activeGroup.name : state.strategyGroups[0].name}
          setSelected={(group) => {
            props.setActiveGroupIdCallback(group.id);
          }}
          items={state.strategyGroups.filter((gr) => !gr.isDefault)}
          additionalOptions={[
            {
              label: (
                <div className="d-flex">
                  Create Group
                  <FontAwesomeIcon className="ml-auto mt-1" icon={faPlus} />
                </div>
              ),
              classes: "text-info border-bottom border-secondary px-3 pt-1 pb-2",
              fn: () => {
                if (!$("#setGroup").is(":visible")) {
                  $("#setGroup").modal("toggle");
                }
              },
            },
            {
              label: state.strategyGroups[0].name,
              classes: "text-light hover-info px-3 pt-2 pb-1",
              fn: () => {
                props.setActiveGroupIdCallback(state.strategyGroups[0].id);
              },
            },
            {
              label: state.strategyGroups[1].name,
              classes: "text-light hover-info px-3 pt-1 pb-2",
              fn: () => {
                props.setActiveGroupIdCallback(state.strategyGroups[1].id);
              },
            },
            {
              label: state.strategyGroups[2].name,
              classes: "text-light hover-info px-3 pt-1 pb-2",
              fn: () => {
                props.setActiveGroupIdCallback(state.strategyGroups[2].id);
              },
            },
            {
              label: state.strategyGroups[3].name,
              classes: `text-light hover-info px-3 pt-1 pb-2 ${
                state.strategyGroups.length > 4 ? "border-bottom border-secondary mb-1" : ""
              }`,
              fn: () => {
                props.setActiveGroupIdCallback(state.strategyGroups[3].id);
              },
            },
          ]}
        />
      )}

      {state.strategies.length > 0 && (
        <>
          <ul className="nav nav-tabs hover-white user-select-none mb-3" role="tablist">
            <li className="nav-item">
              <div
                className={`nav-link text-info hover-light cursor-pointer ${
                  props.tradingActiveSubTab === "simulation" ? "active" : "hover-noborder"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  props.setTradingActiveSubTabCallback("simulation");
                }}
              >
                Simulations
              </div>
            </li>
            <li className="nav-item">
              <div
                className={`nav-link text-info hover-light cursor-pointer ${
                  props.tradingActiveSubTab === "trading" ? "active" : "hover-noborder"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  props.setTradingActiveSubTabCallback("trading");
                }}
              >
                Trade Live
              </div>
            </li>
            {props.setPanelExpanded && (
              <li className="ml-auto">
                <a
                  className="text-light-green"
                  href="/#"
                  title={props.panelExpanded ? "Collapse" : "Expand"}
                  onClick={(e) => {
                    e.preventDefault();
                    document.activeElement.blur();
                    props.setPanelExpanded(!props.panelExpanded);
                  }}
                >
                  {props.panelExpanded ? (
                    <FontAwesomeIcon className="small fade-in" icon={faCompressAlt} />
                  ) : (
                    <FontAwesomeIcon className="small fade-in" icon={faExpandArrowsAlt} />
                  )}
                </a>
              </li>
            )}
          </ul>
          <div className="">
            {props.tradingActiveSubTab === "simulation" ? (
              <ExecutionsCharts
                selectedStrategyId={props.selectedStrategyId}
                setSelectedStrategyId={props.setSelectedStrategyIdCallback}
                setStrategyActiveSubTab={props.setStrategyActiveSubTabCallback}
                setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
                activeGroup={props.activeGroup}
                setActiveGroupIdCallback={props.setActiveGroupIdCallback}
                type={"simulation"}
                panelHeight={props.panelHeight}
                smallWidthDevice={props.smallWidthDevice}
              />
            ) : (
              <ExecutionsCharts
                selectedStrategyId={props.selectedStrategyId}
                setSelectedStrategyId={props.setSelectedStrategyIdCallback}
                setStrategyActiveSubTab={props.setStrategyActiveSubTabCallback}
                setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
                activeGroup={props.activeGroup}
                setActiveGroupIdCallback={props.setActiveGroupIdCallback}
                type={"trading"}
                panelHeight={props.panelHeight}
                smallWidthDevice={props.smallWidthDevice}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

const layoutType2 = ["multi", "single"];
const panelsOptions = ["", "Strategies", "Actions", "Trading", "Charts"];

function getBotDefaultLayout() {
  return {
    type: layoutType2[0],
    panels: [
      { name: "Main", type: 3, p1: panelsOptions[1], p2: panelsOptions[2], p3: panelsOptions[3] },
      { name: "Charts", type: 1, p1: panelsOptions[4], p2: panelsOptions[1], p3: panelsOptions[2] },
    ],
  };
}

async function getBotTradingLayout(userId) {
  let layout = await getEcbDatabase().getBotTradingView(userId);
  if (!layout) {
    layout = getBotDefaultLayout();
  }
  return layout;
}

const BotTradingLayout = (props) => {
  return (
    <>
      {props.panelContent === panelsOptions[1] && (
        <StrategyGroups
          activeGroup={props.activeGroup}
          setSaveFromOptimization={props.setSaveFromOptimization}
          setStrategyToEdit={props.setStrategyToEdit}
          setEditType={props.setEditType}
          selectedStrategy={props.selectedStrategy}
          selectedStrategyId={props.selectedStrategyId}
          setSelectedStrategy={props.setSelectedStrategy}
          setActiveGroupIdCallback={props.setActiveGroupIdCallback}
          setActiveGroup={props.setActiveGroup}
          setSelectedStrategyIdCallback={props.setSelectedStrategyIdCallback}
          selectedStrategyIdRef={props.selectedStrategyIdRef}
          panelHeight={props.panelHeight}
          panelExpanded={props.panelExpanded}
          setPanelExpanded={props.setPanelExpanded}
          tradingActiveSubTab={props.tradingActiveSubTab}
          setTradingActiveSubTabCallback={props.setTradingActiveSubTabCallback}
        />
      )}
      {props.panelContent === panelsOptions[2] && (
        <StrategyActions
          activeGroup={props.activeGroup}
          selectedStrategy={props.selectedStrategy}
          selectedStrategyId={props.selectedStrategyId}
          selectedStrategyIdRef={props.selectedStrategyIdRef}
          setStrategyToEdit={props.setStrategyToEdit}
          setEditType={props.setEditType}
          setSaveFromOptimization={props.setSaveFromOptimization}
          strategyActiveSubTab={props.strategyActiveSubTab}
          setStrategyActiveSubTabCallback={props.setStrategyActiveSubTabCallback}
          strategyActiveSubTabResult={props.strategyActiveSubTabResult}
          setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
          setSelectedStrategyIdCallback={props.setSelectedStrategyIdCallback}
          panelWidth={props.panelWidth}
          panelHeight={props.panelHeight ? props.panelHeight : 600}
          panelExpanded={props.panelExpanded}
          setPanelExpanded={props.setPanelExpanded}
          smallWidthDevice={props.smallWidthDevice}
          singlePage={props.panelExpanded}
        />
      )}
      {props.panelContent === panelsOptions[3] && (
        <StrategiesTradingTable
          tradingActiveSubTab={props.tradingActiveSubTab}
          setTradingActiveSubTabCallback={props.setTradingActiveSubTabCallback}
          setStrategyActiveSubTabCallback={props.setStrategyActiveSubTabCallback}
          selectedStrategyId={props.selectedStrategyId}
          setSelectedStrategyIdCallback={props.setSelectedStrategyIdCallback}
          setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
          activeGroup={props.activeGroup}
          setActiveGroupIdCallback={props.setActiveGroupIdCallback}
          panelWidth={props.panelWidth}
          panelHeight={props.panelHeight}
          panelExpanded={props.panelExpanded}
          setPanelExpanded={props.setPanelExpanded}
        />
      )}
      {props.panelContent === panelsOptions[4] && (
        <StrategiesTradingChart
          tradingActiveSubTab={props.tradingActiveSubTab}
          setTradingActiveSubTabCallback={props.setTradingActiveSubTabCallback}
          setStrategyActiveSubTabCallback={props.setStrategyActiveSubTabCallback}
          selectedStrategyId={props.selectedStrategyId}
          setSelectedStrategyIdCallback={props.setSelectedStrategyIdCallback}
          setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
          activeGroup={props.activeGroup}
          setActiveGroupIdCallback={props.setActiveGroupIdCallback}
          panelWidth={props.panelWidth}
          panelHeight={props.panelHeight}
          panelExpanded={props.panelExpanded}
          setPanelExpanded={props.setPanelExpanded}
        />
      )}
    </>
  );
};

const BotTrading = (props) => {
  const { state, dispatch } = useContext(globalContext);
  const [activeGroupId, setActiveGroupId] = useState(getFromLocalStorage("activeGroupId"), "0_00");
  const [activeGroup, setActiveGroup] = useState(null);
  const [selectedStrategyId, setSelectedStrategyId] = useState();
  const [selectedStrategy, setSelectedStrategy] = useState(null);
  const selectedStrategyIdRef = useRef(null);
  const [editType, setEditType] = useState("New Strategy");
  const [strategyToEdit, setStrategyToEdit] = useState(null);
  const [saveFromOptimization, setSaveFromOptimization] = useState(false);
  const [strategyActiveSubTab, setStrategyActiveSubTab] = useState(getFromLocalStorage("strategySubTab", "Details"));
  const [tradingActiveSubTab, setTradingActiveSubTab] = useState(getFromLocalStorage("tradingSubTab", "trading"));
  const [strategyActiveSubTabResult, setStrategyActiveSubTabResult] = useState(false);
  const [botTradingPageWidth, setBotTradingPageWidth] = useState(400);
  const [layout, setLayout] = useState(null);
  const [layoutEdit, setLayoutEdit] = useState(getBotDefaultLayout());
  const [smallWidthDevice, setSmallWidthDevice] = useState(false);

  const [activeSubPage, setActiveSubPage] = useState(getFromLocalStorage("botTradingPage", panelsOptions[1]));
  const [activePanel, setActivePanel] = useState(getFromLocalStorage("botTradingPagePanel", "0", "number"));

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getBotTradingLayout(state.user.id).then((layout) => {
      if (componentIsMounted.current) {
        setLayout(layout);
        setLayoutEdit(layout);
      }
    });
  }, [state.user.id]);

  useEffect(() => {
    if (state.strategyGroupsInitialized) {
      let gr = state.strategyGroups.find((gr) => gr.id === activeGroupId);
      if (gr) {
        setActiveGroup({ ...gr });
      } else {
        setActiveGroup({ ...state.strategyGroups[0] });
      }
    }
  }, [activeGroupId, state.strategyGroups, state.strategyGroupsInitialized]);

  useEffect(() => {
    if (activeGroup) {
      let selectedStrategyIdLocalStorage = localStorage.getItem("selectedStrategyId");
      if (selectedStrategyIdLocalStorage) {
        if (activeGroup.strategies.find((el) => el === selectedStrategyIdLocalStorage)) {
          setSelectedStrategyId(selectedStrategyIdLocalStorage);
          selectedStrategyIdRef.current = selectedStrategyIdLocalStorage;
        } else {
          if (
            smallWidthDevice ||
            (layout && layout.type === "single") ||
            (layout &&
              layout.type === "multi" &&
              layout.panels &&
              layout.panels[activePanel] &&
              layout.panels[activePanel].type === 1 &&
              activeSubPage === panelsOptions[2])
          ) {
            return;
          }
          setSelectedStrategyId(null);
          selectedStrategyIdRef.current = null;
        }
      } else {
        setSelectedStrategyId(activeGroup.strategies[0]);
        selectedStrategyIdRef.current = activeGroup.strategies[0];
      }
    }
  }, [activeGroup]);

  useEffect(() => {
    if (selectedStrategyId) {
      setSelectedStrategy(state.strategies.find((el) => el.id === selectedStrategyId));
    } else {
      setSelectedStrategy(null);
    }
  }, [selectedStrategyId, state.strategies]);

  const setActiveGroupIdCallback = useCallback(
    (newId) => {
      let gr = state.strategyGroups.find((gr) => gr.id === newId);
      setActiveGroupId(newId);
      setActiveGroup({ ...gr });
      localStorage.setItem("activeGroupId", newId);
    },
    [state.strategyGroups]
  );

  const setStrategyActiveSubTabCallback = useCallback((page) => {
    localStorage.setItem("strategySubTab", page);
    setStrategyActiveSubTab(page);
  }, []);

  const setTradingActiveSubTabCallback = useCallback((page) => {
    localStorage.setItem("tradingSubTab", page);
    setTradingActiveSubTab(page);
  }, []);

  const setSelectedStrategyIdCallback = useCallback(
    (id) => {
      if (!id) {
        id = activeGroup.strategies[0];
      }
      setSelectedStrategyId(id);
      selectedStrategyIdRef.current = id;
      //To navigate to the actions page when clicked on the strategies table row
      /*if (layout && layout.type === "single" && activeSubPage === panelsOptions[1]) {
        setActiveSubPageCallback(panelsOptions[2]);
      }*/

      localStorage.setItem("selectedStrategyId", id);
    },
    [activeGroup, layout, activeSubPage]
  );

  const setActiveSubPageCallback = useCallback((page) => {
    localStorage.setItem("botTradingPage", page);
    setActiveSubPage(page);
  }, []);

  const setActivePanelCallback = useCallback((page) => {
    localStorage.setItem("botTradingPagePanel", page);
    setActivePanel(page);
  }, []);

  useEffect(() => {
    if ((layout && layout.type === "single") || smallWidthDevice) {
      if (
        activeSubPage !== panelsOptions[1] &&
        activeSubPage !== panelsOptions[2] &&
        activeSubPage !== panelsOptions[3] &&
        activeSubPage !== panelsOptions[4]
      ) {
        setActiveSubPage(panelsOptions[1]);
      }

      (async () => {
        let maxWait = new Date();
        maxWait.setSeconds(maxWait.getSeconds() + 5);
        while (maxWait > new Date()) {
          let botTradingPage = document.getElementById("botTradingPage");
          if (botTradingPage) {
            if (componentIsMounted.current) {
              setBotTradingPageWidth(+botTradingPage.clientWidth);
            }
            break;
          }
          await sleep(100);
        }
      })();
    } else {
      if (layout && layout.panels.length - 1 < activePanel) {
        setActivePanelCallback(0);
      }
    }

    const sendSizeChangeDiv = (e) => {
      let root = document.getElementById("root");
      if (root) {
        if (smallWidthDevice && +root.clientWidth >= 768) {
          setSmallWidthDevice(false);
        }
        if (!smallWidthDevice && +root.clientWidth < 768) {
          setSmallWidthDevice(true);
        }
        let botTradingPage = document.getElementById("botTradingPage");
        if (botTradingPage) {
          setBotTradingPageWidth(+botTradingPage.clientWidth);
        }
      }
    };
    sendSizeChangeDiv();

    window.addEventListener("resize", sendSizeChangeDiv);
    return () => {
      window.removeEventListener("resize", sendSizeChangeDiv);
    };
  }, [layout, smallWidthDevice, activePanel]);

  const setLayoutCallBack = useCallback(
    (layout) => {
      getEcbDatabase().storeBotTradingView({ ...layout, id: state.user.id });
      setLayout(layout);
    },
    [state.user.id]
  );

  return (
    <div className="page position-relative text-left fade-in pt-4 px-3 px-md-5 ">
      <div id="botTradingPage">
        {layout ? (
          <>
            {!smallWidthDevice && (
              <a
                className="position-absolute text-light-green text-nowrap small top-5-px right-0 mr-5"
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();

                  getBotTradingLayout(state.user.id).then((layout) => {
                    if (componentIsMounted.current) {
                      setLayoutEdit(layout);
                      if (!$("#layoutSetup").is(":visible")) {
                        $("#layoutSetup").modal("toggle");
                      }
                    }
                  });
                }}
              >
                Layout <FontAwesomeIcon className="fade-in" icon={faCog} />
              </a>
            )}

            {state.strategiesInitialized && state.strategyGroupsInitialized ? (
              <>
                {layout.type === "multi" &&
                  !smallWidthDevice &&
                  (layout.panels.length > 1 ? (
                    <>
                      <div className="text-center user-select-none">
                        <div className="d-inline-flex  active-border hover-white mb-3">
                          {layout.panels.map((panel, index) => (
                            <a
                              className={`text-info px-2 pb-2 mr-2 ${+activePanel === index ? "active" : ""}`}
                              key={index}
                              href="#/"
                              onClick={(e) => {
                                e.preventDefault();
                                document.activeElement.blur();
                                setActivePanelCallback(index);
                              }}
                            >
                              {panel.name}
                            </a>
                          ))}
                        </div>
                      </div>
                      {layout.panels[activePanel] && (
                        <Panel
                          panel={layout.panels[activePanel]}
                          activeSubPage={activeSubPage}
                          activeGroup={activeGroup}
                          setSaveFromOptimization={setSaveFromOptimization}
                          setStrategyToEdit={setStrategyToEdit}
                          setEditType={setEditType}
                          selectedStrategy={selectedStrategy}
                          selectedStrategyId={selectedStrategyId}
                          setSelectedStrategy={setSelectedStrategy}
                          setActiveGroupIdCallback={setActiveGroupIdCallback}
                          setActiveGroup={setActiveGroup}
                          setSelectedStrategyIdCallback={setSelectedStrategyIdCallback}
                          selectedStrategyIdRef={selectedStrategyIdRef}
                          tradingActiveSubTab={tradingActiveSubTab}
                          setTradingActiveSubTabCallback={setTradingActiveSubTabCallback}
                          strategyActiveSubTab={strategyActiveSubTab}
                          setStrategyActiveSubTabCallback={setStrategyActiveSubTabCallback}
                          strategyActiveSubTabResult={strategyActiveSubTabResult}
                          setStrategyActiveSubTabResult={setStrategyActiveSubTabResult}
                          panelWidth={botTradingPageWidth}
                          layout={layout}
                          setLayout={setLayoutCallBack}
                          smallWidthDevice={smallWidthDevice}
                        />
                      )}
                    </>
                  ) : (
                    <div className="mt-3">
                      <Panel
                        panel={layout.panels[0]}
                        activeSubPage={activeSubPage}
                        activeGroup={activeGroup}
                        setSaveFromOptimization={setSaveFromOptimization}
                        setStrategyToEdit={setStrategyToEdit}
                        setEditType={setEditType}
                        selectedStrategy={selectedStrategy}
                        selectedStrategyId={selectedStrategyId}
                        setSelectedStrategy={setSelectedStrategy}
                        setActiveGroupIdCallback={setActiveGroupIdCallback}
                        setActiveGroup={setActiveGroup}
                        setSelectedStrategyIdCallback={setSelectedStrategyIdCallback}
                        selectedStrategyIdRef={selectedStrategyIdRef}
                        tradingActiveSubTab={tradingActiveSubTab}
                        setTradingActiveSubTabCallback={setTradingActiveSubTabCallback}
                        strategyActiveSubTab={strategyActiveSubTab}
                        setStrategyActiveSubTabCallback={setStrategyActiveSubTabCallback}
                        strategyActiveSubTabResult={strategyActiveSubTabResult}
                        setStrategyActiveSubTabResult={setStrategyActiveSubTabResult}
                        panelWidth={botTradingPageWidth}
                        layout={layout}
                        setLayout={setLayoutCallBack}
                        smallWidthDevice={smallWidthDevice}
                        setActiveSubPageCallback={setActiveSubPageCallback}
                      />
                    </div>
                  ))}

                {(layout.type === "single" || smallWidthDevice) && (
                  <>
                    <div id="" className="">
                      <div className="text-center user-select-none">
                        <div className="d-inline-flex  active-border hover-white mb-3">
                          <a
                            className={`text-info px-2 pb-2 mr-2 ${activeSubPage === panelsOptions[1] ? "active" : ""}`}
                            href="#/"
                            onClick={(e) => {
                              e.preventDefault();
                              document.activeElement.blur();
                              setActiveSubPageCallback(panelsOptions[1]);
                            }}
                          >
                            {panelsOptions[1]}
                          </a>
                          <a
                            className={`text-info px-2 pb-2 mr-2 ${activeSubPage === panelsOptions[2] ? "active" : ""}`}
                            href="#/"
                            onClick={(e) => {
                              e.preventDefault();
                              document.activeElement.blur();
                              setActiveSubPageCallback(panelsOptions[2]);
                            }}
                          >
                            {panelsOptions[2]}
                          </a>
                          <a
                            className={`text-info px-2 pb-2 ${activeSubPage === panelsOptions[3] ? "active" : ""}`}
                            href="#/"
                            onClick={(e) => {
                              e.preventDefault();
                              document.activeElement.blur();
                              setActiveSubPageCallback(panelsOptions[3]);
                            }}
                          >
                            {panelsOptions[3]}
                          </a>
                          <a
                            className={`text-info px-2 pb-2 ${activeSubPage === panelsOptions[4] ? "active" : ""}`}
                            href="#/"
                            onClick={(e) => {
                              e.preventDefault();
                              document.activeElement.blur();
                              setActiveSubPageCallback(panelsOptions[4]);
                            }}
                          >
                            {panelsOptions[4]}
                          </a>
                        </div>
                      </div>
                      <MenuItems
                        activeSubPage={activeSubPage}
                        activeGroup={activeGroup}
                        setSaveFromOptimization={setSaveFromOptimization}
                        setStrategyToEdit={setStrategyToEdit}
                        setEditType={setEditType}
                        selectedStrategy={selectedStrategy}
                        selectedStrategyId={selectedStrategyId}
                        setSelectedStrategy={setSelectedStrategy}
                        setActiveGroupIdCallback={setActiveGroupIdCallback}
                        setActiveGroup={setActiveGroup}
                        setSelectedStrategyIdCallback={setSelectedStrategyIdCallback}
                        selectedStrategyIdRef={selectedStrategyIdRef}
                        tradingActiveSubTab={tradingActiveSubTab}
                        setTradingActiveSubTabCallback={setTradingActiveSubTabCallback}
                        strategyActiveSubTab={strategyActiveSubTab}
                        setStrategyActiveSubTabCallback={setStrategyActiveSubTabCallback}
                        strategyActiveSubTabResult={strategyActiveSubTabResult}
                        setStrategyActiveSubTabResult={setStrategyActiveSubTabResult}
                        panelWidth={botTradingPageWidth}
                        smallWidthDevice={smallWidthDevice}
                        setActiveSubPageCallback={setActiveSubPageCallback}
                      />
                    </div>
                  </>
                )}
              </>
            ) : state.user.isAdmin ? (
              <div className="text-center mx-auto mt-5">
                <h2 className="text-secondary text-center mb-4">No user to show</h2>
                <NavLink
                  className="text-info mt-5"
                  as={NavLink}
                  to="/"
                  exact
                  activeClassName="active"
                  onClick={() => {
                    if ($("#infoDialog").is(":visible")) {
                      $("#infoDialog").modal("toggle");
                    }
                  }}
                >
                  SELECT NOW
                </NavLink>
              </div>
            ) : (
              <div className="text-center mx-auto mt-5">
                <div className="loading-img cursor-help my-5" title="Loading..">
                  <img src={loading} alt="" />
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="text-center mx-auto mt-5">
            <div className="loading-img cursor-help my-5" title="Loading..">
              <img src={loading} alt="" />
            </div>
          </div>
        )}
      </div>
      {strategyToEdit && (
        <StrategyToEdit
          setStrategyToEdit={(strategy) => {
            if (componentIsMounted.current) {
              setStrategyToEdit(strategy);
            }
          }}
          strategy={strategyToEdit}
          editType={editType}
          demo={state.demo}
          freeBacktest={props.freeBacktest}
          saveFromOptimization={saveFromOptimization}
          addStrategyToActiveGroup={(newStrategy) => {
            if (!activeGroup.strategies.includes(newStrategy.id) && !activeGroup.isDefault) {
              activeGroup.strategies.push(newStrategy.id);
              activeGroup.editDate = new Date().getTime();
              saveStrategyGroup(activeGroup, state.user.token, dispatch);
              setActiveGroup({ ...activeGroup });
            }
          }}
          setSelectedStrategyId={setSelectedStrategyIdCallback}
        />
      )}

      <ModalConfirm
        id="layoutSetup"
        okDisabled={layoutEdit.type === "multi" && layoutEdit.panels.length === 0}
        header={
          <div className="text-info">
            <FontAwesomeIcon icon={faCog} /> Layout Setup
          </div>
        }
        okText="SAVE"
        content={
          <div className="">
            <div
              onClick={(e) => {
                e.preventDefault();
                setLayoutEdit({ ...layoutEdit, type: "multi" });
              }}
            >
              {layoutEdit.type === "multi" ? (
                <FontAwesomeIcon className="text-info mr-2" icon={faArrowAltCircleRight} />
              ) : (
                <FontAwesomeIcon className="text-white border border-info rounded-circle mr-2" icon={faCircle} />
              )}
              Multi Panels View
            </div>
            {layoutEdit.type === "multi" && (
              <div className="ml-4 mb-3 fade-in-fast">
                <div className="mt-1 mb-2">
                  <a
                    href="#/"
                    className="text-info"
                    title="Add View"
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      layoutEdit.panels.push({
                        name: "",
                        type: 3,
                        p1: panelsOptions[1],
                        p2: panelsOptions[2],
                        p3: panelsOptions[3],
                      });
                      setLayoutEdit({ ...layoutEdit });
                    }}
                  >
                    <FontAwesomeIcon className="" icon={faPlusCircle} />
                    <span className="border-bottom border-info d-inline-block w-100-px text-center px-2 ml-2">
                      Add View
                    </span>
                  </a>
                </div>
                {layoutEdit.panels.map((panel, index) => {
                  return (
                    <div key={index} className="d-flex mb-2 custom-hr alt">
                      <a
                        href="#/"
                        className="text-danger"
                        title="Remove Panel"
                        onClick={(e) => {
                          e.preventDefault();
                          document.activeElement.blur();
                          layoutEdit.panels.splice(index, 1);
                          setLayoutEdit({ ...layoutEdit });
                        }}
                      >
                        <FontAwesomeIcon className="" icon={faTimesCircle} />
                      </a>
                      <input
                        type="text"
                        className="btn-bottom-outline-info w-100-px ml-2"
                        placeholder="View Name"
                        value={panel.name}
                        onChange={(e) => {
                          panel.name = e.target.value;
                          setLayoutEdit({ ...layoutEdit });
                        }}
                      />
                      <div className="ml-2">
                        <div className="text-center mb-2">Type:</div>
                        <div className="d-flex flex-column">
                          <div className="d-flex mb-2">
                            <RadioButtons
                              options={[
                                {
                                  id: 1,
                                  optionClasses: "text-dark",
                                  checkedColor: "text-info",
                                  uncheckedColor: "text-white border border-info rounded-circle",
                                  checked: panel.type === 1,
                                  check: () => {
                                    panel.type = 1;
                                    setLayoutEdit({ ...layoutEdit });
                                  },
                                  text: (
                                    <div className="d-inline-block w-55">
                                      <img src={layoutPanels1} alt="" />
                                    </div>
                                  ),
                                },
                                {
                                  id: 2,
                                  optionClasses: "text-dark",
                                  checkedColor: "text-info",
                                  uncheckedColor: "text-white border border-info rounded-circle",
                                  checked: panel.type === 2,
                                  check: () => {
                                    panel.type = 2;
                                    setLayoutEdit({ ...layoutEdit });
                                  },
                                  text: (
                                    <div className="d-inline-block w-55">
                                      <img src={layoutPanels2} alt="" />
                                    </div>
                                  ),
                                },
                              ]}
                            />
                          </div>
                          <div className="d-flex">
                            <RadioButtons
                              options={[
                                {
                                  id: 22,
                                  optionClasses: "text-dark",
                                  checkedColor: "text-info",
                                  uncheckedColor: "text-white border border-info rounded-circle",
                                  checked: panel.type === 22,
                                  check: () => {
                                    panel.type = 22;
                                    setLayoutEdit({ ...layoutEdit });
                                  },
                                  text: (
                                    <div className="d-inline-block w-55">
                                      <img src={layoutPanels22} alt="" />
                                    </div>
                                  ),
                                },
                                {
                                  id: 3,
                                  optionClasses: "text-dark",
                                  checkedColor: "text-info",
                                  uncheckedColor: "text-white border border-info rounded-circle",
                                  checked: panel.type === 3,
                                  check: () => {
                                    panel.type = 3;
                                    setLayoutEdit({ ...layoutEdit });
                                  },
                                  text: (
                                    <div className="d-inline-block w-55">
                                      <img src={layoutPanels3} alt="" />
                                    </div>
                                  ),
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="ml-2">
                        Content:
                        <div>
                          1:
                          <Dropdown
                            btnClasses="text-info ml-2"
                            placeholder="Select"
                            selectedClasses="text-info"
                            arrow
                            arrowClasses="mt-1"
                            menuClasses="bg-white white border border-info rounded small min-w-150 text-nowrap py-1"
                            menuItemsClasses="text-info px-3 py-1"
                            selected={panel.p1}
                            setSelected={(selected) => {
                              if (panel.p2 === selected) {
                                panel.p2 = panel.p1;
                              } else if (panel.p3 === selected) {
                                panel.p3 = panel.p1;
                              }
                              panel.p1 = selected;
                              setLayoutEdit({ ...layoutEdit });
                            }}
                            items={panelsOptions}
                          />
                        </div>
                        {panel.type !== 1 && (
                          <div>
                            2:
                            <Dropdown
                              btnClasses="text-info ml-2"
                              placeholder="Select"
                              selectedClasses="text-info"
                              arrow
                              arrowClasses="mt-1"
                              menuClasses="bg-white white border border-info rounded small min-w-150 text-nowrap py-1"
                              menuItemsClasses="text-info px-3 py-1"
                              selected={panel.p2}
                              setSelected={(selected) => {
                                if (panel.p1 === selected) {
                                  panel.p1 = panel.p2;
                                } else if (panel.p3 === selected) {
                                  panel.p3 = panel.p2;
                                }
                                panel.p2 = selected;
                                setLayoutEdit({ ...layoutEdit });
                              }}
                              items={panelsOptions}
                            />
                          </div>
                        )}
                        {panel.type === 3 && (
                          <div>
                            3:
                            <Dropdown
                              btnClasses="text-info ml-2"
                              placeholder="Select"
                              selectedClasses="text-info"
                              arrow
                              arrowClasses="mt-1"
                              menuClasses="bg-white white border border-info rounded small min-w-150 text-nowrap py-1"
                              menuItemsClasses="text-info px-3 py-1"
                              selected={panel.p3}
                              setSelected={(selected) => {
                                if (panel.p1 === selected) {
                                  panel.p1 = panel.p3;
                                } else if (panel.p2 === selected) {
                                  panel.p2 = panel.p3;
                                }
                                panel.p3 = selected;
                                setLayoutEdit({ ...layoutEdit });
                              }}
                              items={panelsOptions}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div
              onClick={(e) => {
                e.preventDefault();
                setLayoutEdit({ ...layoutEdit, type: "single" });
              }}
            >
              {layoutEdit.type === "single" ? (
                <FontAwesomeIcon className="text-info mr-2" icon={faArrowAltCircleRight} />
              ) : (
                <FontAwesomeIcon className="text-white border border-info rounded-circle mr-2" icon={faCircle} />
              )}
              Single Panel View
            </div>
            {layoutEdit.type === "single" && (
              <div className="ml-4 mt-2">
                <div className="d-inline-block w-55">
                  <img src={layoutPanels1} alt="" />
                </div>
              </div>
            )}
          </div>
        }
        func={() => {
          layoutEdit.panels.forEach((panel) => {
            if (!panel.name) {
              panel.name = "Panel";
              delete panel.expanded;
            }
          });

          setLayoutCallBack(layoutEdit);
        }}
        closeFn={() => {}}
      />
    </div>
  );
};

const MenuItems = (props) => {
  return (
    <div>
      {props.activeSubPage === panelsOptions[1] && (
        <StrategyGroups
          activeGroup={props.activeGroup}
          setSaveFromOptimization={props.setSaveFromOptimization}
          setStrategyToEdit={props.setStrategyToEdit}
          setEditType={props.setEditType}
          selectedStrategy={props.selectedStrategy}
          selectedStrategyId={props.selectedStrategyId}
          setSelectedStrategy={props.setSelectedStrategy}
          setActiveGroupIdCallback={props.setActiveGroupIdCallback}
          setActiveGroup={props.setActiveGroup}
          setSelectedStrategyIdCallback={props.setSelectedStrategyIdCallback}
          selectedStrategyIdRef={props.selectedStrategyIdRef}
          tradingActiveSubTab={props.tradingActiveSubTab}
          setTradingActiveSubTabCallback={props.setTradingActiveSubTabCallback}
          setActiveSubPageCallback={props.setActiveSubPageCallback}
        />
      )}
      {props.activeSubPage === panelsOptions[2] && (
        <StrategyActions
          activeGroup={props.activeGroup}
          selectedStrategy={props.selectedStrategy}
          selectedStrategyId={props.selectedStrategyId}
          selectedStrategyIdRef={props.selectedStrategyIdRef}
          setStrategyToEdit={props.setStrategyToEdit}
          setEditType={props.setEditType}
          setSaveFromOptimization={props.setSaveFromOptimization}
          strategyActiveSubTab={props.strategyActiveSubTab}
          setStrategyActiveSubTabCallback={props.setStrategyActiveSubTabCallback}
          setSelectedStrategyIdCallback={props.setSelectedStrategyIdCallback}
          strategyActiveSubTabResult={props.strategyActiveSubTabResult}
          setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
          panelWidth={props.panelWidth}
          smallWidthDevice={props.smallWidthDevice}
          singlePage
        />
      )}
      {props.activeSubPage === panelsOptions[3] && (
        <StrategiesTradingTable
          tradingActiveSubTab={props.tradingActiveSubTab}
          setTradingActiveSubTabCallback={props.setTradingActiveSubTabCallback}
          setStrategyActiveSubTabCallback={props.setStrategyActiveSubTabCallback}
          selectedStrategyId={props.selectedStrategyId}
          setSelectedStrategyIdCallback={props.setSelectedStrategyIdCallback}
          setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
          activeGroup={props.activeGroup}
          setActiveGroupIdCallback={props.setActiveGroupIdCallback}
          smallWidthDevice={props.smallWidthDevice}
          showGroupsDropdown
        />
      )}
      {props.activeSubPage === panelsOptions[4] && (
        <StrategiesTradingChart
          tradingActiveSubTab={props.tradingActiveSubTab}
          setTradingActiveSubTabCallback={props.setTradingActiveSubTabCallback}
          setStrategyActiveSubTabCallback={props.setStrategyActiveSubTabCallback}
          selectedStrategyId={props.selectedStrategyId}
          setSelectedStrategyIdCallback={props.setSelectedStrategyIdCallback}
          setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
          activeGroup={props.activeGroup}
          setActiveGroupIdCallback={props.setActiveGroupIdCallback}
          smallWidthDevice={props.smallWidthDevice}
          showGroupsDropdown
        />
      )}
    </div>
  );
};

const Panel = (props) => {
  const [topPanelHeight, setTopPanelHeight] = useState(395);
  const [rightPanelWidth, setRightPanelWidth] = useState(400);
  const [leftPanelWidth, setLeftPanelWidth] = useState(400);
  const [bottomPanelWidth, setBottomPanelWidth] = useState(400);

  return props.panel.type === 1 ? (
    <MenuItems
      activeSubPage={props.panel.p1}
      activeGroup={props.activeGroup}
      setSaveFromOptimization={props.setSaveFromOptimization}
      setStrategyToEdit={props.setStrategyToEdit}
      setEditType={props.setEditType}
      selectedStrategy={props.selectedStrategy}
      selectedStrategyId={props.selectedStrategyId}
      setSelectedStrategy={props.setSelectedStrategy}
      setActiveGroupIdCallback={props.setActiveGroupIdCallback}
      setActiveGroup={props.setActiveGroup}
      setSelectedStrategyIdCallback={props.setSelectedStrategyIdCallback}
      selectedStrategyIdRef={props.selectedStrategyIdRef}
      tradingActiveSubTab={props.tradingActiveSubTab}
      setTradingActiveSubTabCallback={props.setTradingActiveSubTabCallback}
      strategyActiveSubTab={props.strategyActiveSubTab}
      setStrategyActiveSubTabCallback={props.setStrategyActiveSubTabCallback}
      strategyActiveSubTabResult={props.strategyActiveSubTabResult}
      setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
      panelWidth={props.panelWidth}
      smallWidthDevice={props.smallWidthDevice}
    />
  ) : (
    <>
      <div id="panelTop" className={` d-flex ${props.panel.expanded === 3 ? "" : "pb-2"}`}>
        <div id="panelLeft" className={props.panel.type === 22 ? "" : "pr-3"}>
          <BotTradingLayout
            activeGroup={props.activeGroup}
            setSaveFromOptimization={props.setSaveFromOptimization}
            setStrategyToEdit={props.setStrategyToEdit}
            setEditType={props.setEditType}
            selectedStrategy={props.selectedStrategy}
            selectedStrategyId={props.selectedStrategyId}
            setSelectedStrategy={props.setSelectedStrategy}
            setActiveGroupIdCallback={props.setActiveGroupIdCallback}
            saveStrategyGroup={saveStrategyGroup}
            setActiveGroup={props.setActiveGroup}
            setSelectedStrategyIdCallback={props.setSelectedStrategyIdCallback}
            selectedStrategyIdRef={props.selectedStrategyIdRef}
            strategyActiveSubTab={props.strategyActiveSubTab}
            setStrategyActiveSubTabCallback={props.setStrategyActiveSubTabCallback}
            strategyActiveSubTabResult={props.strategyActiveSubTabResult}
            setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
            tradingActiveSubTab={props.tradingActiveSubTab}
            setTradingActiveSubTabCallback={props.setTradingActiveSubTabCallback}
            panelExpanded={props.panel.expanded === 1}
            setPanelExpanded={() => {
              if (props.panel.expanded) {
                delete props.panel.expanded;
              } else {
                props.panel.expanded = 1;
              }
              props.setLayout({ ...props.layout, panels: props.layout.panels.map((p) => ({ ...p })) });
            }}
            panelWidth={leftPanelWidth}
            panelHeight={topPanelHeight}
            panelContent={props.panel.p1}
            smallWidthDevice={props.smallWidthDevice}
          />
        </div>
        <div id="dragX"></div>
        <div id="panelRight" className={props.panel.type !== 22 ? "pl-3" : ""}>
          {props.panel.type !== 22 && (
            <BotTradingLayout
              activeGroup={props.activeGroup}
              setSaveFromOptimization={props.setSaveFromOptimization}
              setStrategyToEdit={props.setStrategyToEdit}
              setEditType={props.setEditType}
              selectedStrategy={props.selectedStrategy}
              selectedStrategyId={props.selectedStrategyId}
              setSelectedStrategy={props.setSelectedStrategy}
              setActiveGroupIdCallback={props.setActiveGroupIdCallback}
              saveStrategyGroup={saveStrategyGroup}
              setActiveGroup={props.setActiveGroup}
              setSelectedStrategyIdCallback={props.setSelectedStrategyIdCallback}
              selectedStrategyIdRef={props.selectedStrategyIdRef}
              strategyActiveSubTab={props.strategyActiveSubTab}
              setStrategyActiveSubTabCallback={props.setStrategyActiveSubTabCallback}
              strategyActiveSubTabResult={props.strategyActiveSubTabResult}
              setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
              tradingActiveSubTab={props.tradingActiveSubTab}
              setTradingActiveSubTabCallback={props.setTradingActiveSubTabCallback}
              panelExpanded={props.panel.expanded === 2}
              setPanelExpanded={() => {
                if (props.panel.expanded) {
                  delete props.panel.expanded;
                } else {
                  props.panel.expanded = 2;
                }
                props.setLayout({ ...props.layout, panels: props.layout.panels.map((p) => ({ ...p })) });
              }}
              panelWidth={rightPanelWidth}
              panelHeight={topPanelHeight}
              panelContent={props.panel.p2}
              smallWidthDevice={props.smallWidthDevice}
            />
          )}
        </div>
      </div>
      <div id="dragY"></div>
      <div id="panelBottom" className="fade-in py-3">
        {props.panel.type !== 2 && (
          <BotTradingLayout
            activeGroup={props.activeGroup}
            setSaveFromOptimization={props.setSaveFromOptimization}
            setStrategyToEdit={props.setStrategyToEdit}
            setEditType={props.setEditType}
            selectedStrategy={props.selectedStrategy}
            selectedStrategyId={props.selectedStrategyId}
            setSelectedStrategy={props.setSelectedStrategy}
            setActiveGroupIdCallback={props.setActiveGroupIdCallback}
            saveStrategyGroup={saveStrategyGroup}
            setActiveGroup={props.setActiveGroup}
            setSelectedStrategyIdCallback={props.setSelectedStrategyIdCallback}
            selectedStrategyIdRef={props.selectedStrategyIdRef}
            strategyActiveSubTab={props.strategyActiveSubTab}
            setStrategyActiveSubTabCallback={props.setStrategyActiveSubTabCallback}
            strategyActiveSubTabResult={props.strategyActiveSubTabResult}
            setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
            tradingActiveSubTab={props.tradingActiveSubTab}
            setTradingActiveSubTabCallback={props.setTradingActiveSubTabCallback}
            panelExpanded={props.panel.expanded === 3}
            setPanelExpanded={() => {
              if (props.panel.expanded) {
                delete props.panel.expanded;
              } else {
                props.panel.expanded = 3;
              }
              props.setLayout({ ...props.layout, panels: props.layout.panels.map((p) => ({ ...p })) });
            }}
            panelWidth={bottomPanelWidth}
            panelContent={props.panel.type === 22 ? props.panel.p2 : props.panel.p3}
            smallWidthDevice={props.smallWidthDevice}
          />
        )}
      </div>
      <ResizablePanels
        leftPanelSizeDefault={485}
        topPanelSizeDefault={"60vh"}
        minLeft={415}
        minRight={415}
        minTop={300}
        minBottom={100}
        panelLeftExpanded={props.panel.expanded === 1}
        panelRightExpanded={props.panel.expanded === 2}
        panelBottomExpanded={props.panel.expanded === 3}
        topHeightOnChange={setTopPanelHeight}
        rightWidthOnChange={setRightPanelWidth}
        leftWidthOnChange={setLeftPanelWidth}
        bottomWidthOnChange={setBottomPanelWidth}
        panel={props.panel}
        storePanel={() => props.setLayout({ ...props.layout, panels: props.layout.panels.map((p) => ({ ...p })) })}
      />
    </>
  );
};

export { strategyVersion, statusVersion, StrategyActions, panelsOptions };
export default BotTrading;
