import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "jquery/src/jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "video-react/dist/video-react.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

if (navigator.appVersion && navigator.appVersion.indexOf("easy-crypto-bot/2.0.0") !== -1) {
  console.log("ServiceWorker Not Supported!");
  if ("serviceWorker" in navigator) {
    serviceWorker.unregister();
  }
} else {
  if ("serviceWorker" in navigator) {
    console.log("ServiceWorker is Supported!");
    serviceWorker.register();
  } else {
    console.log("ServiceWorker Not Supported!");
  }
}
