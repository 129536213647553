import React, { useState, useRef, useEffect } from "react";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const Dropdown = (props) => {
  const [id] = useState(`d${new Date().getTime()}${Math.floor(Math.random() * 8999999999 + 1000000000)}`);
  let selectedColor;
  if (props.placeholder && props.selected === props.items[0]) {
    switch (props.placeholder) {
      case "required":
        selectedColor = `text-danger-light font-italic ${
          props.placeholderHover ? props.placeholderHover : "hover-light-green"
        }`;
        break;
      default:
        selectedColor = `text-secondary font-italic ${
          props.placeholderHover ? props.placeholderHover : "hover-light-green"
        }`;
    }
  } else {
    selectedColor = props.selectedClasses;
  }

  useEffect(() => {
    const onBodyClick = (e) => {
      try {
        const ids = [`${id}Btn`, `${id}MenuArrow`];
        if ($(`#${id}Menu`).is(":visible")) {
          if (
            ids.indexOf(e.target.id) !== -1 ||
            ids.indexOf(e.target.parentElement.id) !== -1 ||
            ids.indexOf(e.target.parentElement.parentElement.id) !== -1
          ) {
            return;
          }
          $(`#${id}Menu`).hide();
          $(`#${id}MenuArrow`).removeClass("rotate-180");
          $(`#${id}Menu`).removeClass("overflow-auto");
        }
      } catch (e) {
        //Unknown error
      }
    };
    document.body.addEventListener("click", onBodyClick);
    return () => {
      document.body.removeEventListener("click", onBodyClick);
    };
  }, []);

  return (
    <div className={`d-inline-block position-relative ${props.mainDivClasses}`}>
      <a
        id={`${id}Btn`}
        className={`d-flex ${props.btnClasses}`}
        title={props.title ? props.title : ""}
        href="#/"
        onClick={(e) => {
          e.preventDefault();
          document.activeElement.blur();
          if (props.disabled) {
            return;
          }
          if ($(`#${id}Menu`).is(":visible")) {
            $(`#${id}Menu`).removeClass("overflow-auto");
            $(`#${id}Menu`).slideUp("200", "linear", () => {
              $(`#${id}MenuArrow`).removeClass("rotate-180");
            });
          } else {
            $(`#${id}MenuArrow`).addClass("rotate-180");
            $(`#${id}Menu`).slideDown("200", "linear", () => {
              $(`#${id}Menu`).addClass("overflow-auto");
            });
          }
        }}
      >
        <div className={selectedColor}>{props.selected}</div>
        <FontAwesomeIcon
          id={`${id}MenuArrow`}
          className={props.arrow ? `d-inline-block ml-2 ${props.arrowClasses}` : "d-none"}
          icon={faAngleDown}
        />
      </a>

      <div
        id={`${id}Menu`}
        className={`position-absolute z-index-big ${props.menuClasses}`}
        style={props.menuStyle ? { ...props.menuStyle, display: "none" } : { display: "none" }}
      >
        {props.additionalOptions &&
          props.additionalOptions.map((option, index) => (
            <a
              className={`d-block px-2 ${option.classes}`}
              href="/#"
              key={[`option${index}`]}
              value={option.label}
              onClick={(e) => {
                e.preventDefault();
                document.activeElement.blur();
                option.fn();
              }}
            >
              {option.label}
            </a>
          ))}
        {props.items.map((item, index) => {
          return (
            <a
              className={`px-2 ${props.menuItemsClasses} ${props.placeholder && index === 0 ? "d-none" : "d-block "}`}
              href="/#"
              key={index.toString()}
              value={item.name ? item.name : item}
              onClick={(e) => {
                e.preventDefault();
                document.activeElement.blur();
                props.setSelected(item);
              }}
            >
              {item.name ? item.name : item}
            </a>
          );
        })}
      </div>
    </div>
  );
};

const DropdownWithSearch = (props) => {
  const [id] = useState(`ds${new Date().getTime()}${Math.floor(Math.random() * 8999999999 + 1000000000)}`);
  const [text, setText] = useState(props.selected ? props.selected : "");
  const [preSelected, setPreSelected] = useState(0);
  const [showOptions, setShowOptions] = useState(false);
  const [filteredItems, setFilteredItems] = useState(props.items);
  const optionsDiv = useRef(null);
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setText(props.selected ? props.selected : "");
  }, [props.selected]);

  useEffect(() => {
    let newFfilteredItems = [];
    if (!props.hide && showOptions && props.items) {
      newFfilteredItems =
        text.length === 0
          ? props.items
          : props.items.filter((item) => {
              return item.name
                ? item.name.toLowerCase().indexOf(text.toLowerCase()) !== -1
                : item.toLowerCase().indexOf(text.toLowerCase()) !== -1;
            });
    }
    setFilteredItems(newFfilteredItems);
  }, [text, showOptions]);

  const onBlur = (e) => {
    setTimeout(() => {
      if (`${id}-dropdown` !== document.activeElement.parentElement.id) {
        if (componentIsMounted.current) {
          setShowOptions(false);
          try {
            if (
              filteredItems.length === 1 &&
              ((filteredItems[0].name && filteredItems[0].name === text) || filteredItems[0].toUpperCase() === text)
            ) {
              props.setSelected(filteredItems[0]);
            } else if (props.selected) {
              setText(props.selected);
            } else {
            }
          } catch (e) {}
        }
      }
    }, 1);
  };

  return (
    <div className="drop-down-search position-relative d-inline-block">
      <input
        type="text"
        autoComplete="off"
        id={id}
        className={"" + props.classesInput}
        placeholder={
          props.classesInput && props.classesInput.indexOf("required") > -1 ? "<Required>" : props.placeholder
        }
        value={text}
        onFocus={(e) => {
          setShowOptions(true);
        }}
        onChange={(e) => {
          setShowOptions(true);
          setPreSelected(0);
          setText(e.target.value.toUpperCase());
        }}
        onBlur={onBlur}
        onKeyDown={(e) => {
          let keycode = e.keyCode ? e.keyCode : e.which;
          if (keycode === 13 && filteredItems.length > preSelected) {
            //enter
            setShowOptions(false);
            setText(
              props.deleteSearchText
                ? ""
                : filteredItems[preSelected].name
                ? filteredItems[preSelected].name
                : filteredItems[preSelected]
            );
            props.setSelected(filteredItems[preSelected]);
          } else if (keycode === 40 && filteredItems.length - 1 > preSelected) {
            //arrowdown
            $(optionsDiv.current).scrollTop(0);
            $(optionsDiv.current).scrollTop((preSelected + 1) * 30);

            setPreSelected(preSelected + 1);
          } else if (keycode === 38 && preSelected > 0) {
            //arrowup
            $(optionsDiv.current).scrollTop(0);
            $(optionsDiv.current).scrollTop((preSelected - 1) * 30);

            setPreSelected(preSelected - 1);
          }
          if (props.closeOnEsc) {
            if (keycode === 27) {
              setShowOptions(false);
            }
          }
        }}
      ></input>
      {filteredItems.length > 0 && showOptions && (
        <div
          className={`position-absolute overflow-auto z-index-big rounded text-left ${props.dropMenuClasses} `}
          ref={optionsDiv}
          id={`${id}-dropdown`}
          style={props.menuStyle ? props.menuStyle : {}}
        >
          {filteredItems.map((item, index) => {
            return (
              <a
                key={item.id ? item.id : item}
                className={`d-block ${props.dropMenuItemsClasses} ${index === preSelected ? "active" : ""}`}
                href="#/"
                value={item.name ? item.name : item}
                onClick={(e) => {
                  e.preventDefault();
                  setShowOptions(false);
                  setText(props.deleteSearchText ? "" : item.name ? item.name.toUpperCase() : item.toUpperCase());
                  props.setSelected(item);
                }}
                onBlur={onBlur}
              >
                {item.name ? item.name : item}
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
export { Dropdown, DropdownWithSearch };
