import React, { useState, useContext, useRef, useEffect } from "react";
import { globalContext } from "./Context.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPowerOff,
  faQuestionCircle,
  faTimesCircle,
  faPlus,
  faCoins,
  faExclamationTriangle,
  faInfoCircle,
  faStar,
  faDatabase,
  faSearch,
  faDownload,
  faEdit,
  faUserEdit,
  faSync,
  faCaretRight,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import binanceLogo from "./assets/images/binance-logo.png";
import binanceusLogo from "./assets/images/binanceus-logo.png";
import windows32Logo from "./assets/images/windows32-logo.png";
import windows64Logo from "./assets/images/windows64-logo.png";
import linuxLogo from "./assets/images/linux-logo.png";
import macLogo from "./assets/images/mac-logo.png";
import {
  showConfirmDialog,
  showInfoDialog,
  fetchWithRetry,
  formatDateTime,
  sleep,
  encrypt,
  decrypt,
  fixStrategyVersion,
} from "./utils.js";
import { exchanges } from "./constants/consts.json";
import { Modal, ModalConfirm } from "./Modal.js";
import { Dropdown } from "./Dropdown.js";
import $ from "jquery";
import getExchange from "./Exchange.js";
import loading from "./assets/images/loading.gif";
import { PricingPlans, proPlan, beginnerPlan } from "./Pricing.js";
import favlogo from "./assets/images/favicon.ico";
import Footer, { VersionInfo } from "./Footer.js";
import { NavLink } from "react-router-dom";
import { checkUserExpire } from "./SignIn.js";

function showPaypalModal(
  token,
  userId,
  setOrderId,
  planType,
  price,
  promoCode,
  discount
) {
  $("#paypalBtn").html("");
  if (!$("#paypalButtonsModal").is(":visible")) {
    $("#paypalButtonsModal").modal("toggle");
  }

  let ammount = discount > 0 ? Math.floor(price * (1 - discount / 100)) : price;

  window.paypal
    .Buttons({
      style: {
        shape: "rect",
        color: "gold",
        layout: "vertical",
        label: "pay",
      },
      intent: "CAPTURE",
      createOrder: function (data, actions) {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: ammount,
                currency_code: "USD",
              },
              description: userId,
              custom_id: `${planType}${userId}`,
            },
          ],
          application_context: { shipping_preference: "NO_SHIPPING" },
        });
      },

      onApprove: function (data, actions) {
        if (discount > 0) {
          fetchWithRetry(
            `https://api2.easycryptobot.com/promo-code.php`,
            "post",
            {
              code: promoCode,
            },
            () => {},
            () => {},
            { Authorization: `Bearer ${token}` }
          );
        }
        return actions.order.capture().then(function (details) {
          setOrderId(details.purchase_units[0].payments.captures[0].id);
        });
      },
    })
    .render("#paypalBtn");
}

function startDownload(id, dispatch) {
  showInfoDialog(
    dispatch,
    <span className="text-info">
      <FontAwesomeIcon icon={faInfoCircle} /> Info
    </span>,
    "The download will start shortly.."
  );
  $(id).addClass("disabled");
  window.setTimeout(function () {
    $(id).removeClass("disabled");
  }, 5000);
}

async function changeEmail(token, password, newEmail, dispatch, showChanging) {
  if (newEmail.length < 2 || newEmail.indexOf("@") === -1) {
    showInfoDialog(
      dispatch,
      <span className="text-danger">
        <FontAwesomeIcon icon={faExclamationTriangle} /> Email Change Failed
      </span>,
      "Please type valid email."
    );
    showChanging(false);
    return;
  }
  fetchWithRetry(
    `https://api2.easycryptobot.com/email.php`,
    "post",
    {
      password: password,
      newEmail: newEmail,
    },
    (json) => {
      showInfoDialog(
        dispatch,
        <span className="text-info">
          <FontAwesomeIcon icon={faInfoCircle} /> Email Change
        </span>,
        "We send you a confirmation link on your new email. The email change will be applied after you confirm your new email."
      );
      dispatch({
        type: "editEmail",
        payload: true,
      });
      showChanging(false);
      return;
    },
    () => {
      showChanging(false);
      showInfoDialog(
        dispatch,
        <span className="text-danger">
          <FontAwesomeIcon icon={faExclamationTriangle} /> Email Change Failed
        </span>,
        "Wrong email or password!"
      );
    },
    { Authorization: `Bearer ${token}` }
  );
}
async function changeName(token, password, newName, dispatch, showChanging) {
  if (newName.length < 3) {
    showInfoDialog(
      dispatch,
      <span className="text-danger">
        <FontAwesomeIcon icon={faExclamationTriangle} /> Name Change Failed
      </span>,
      "The new name must be at least 3 symbols!"
    );
    showChanging(false);
    return;
  }

  fetchWithRetry(
    `https://api2.easycryptobot.com/name.php`,
    "post",
    {
      password: password,
      newName: newName,
    },
    (json) => {
      showInfoDialog(
        dispatch,
        <span className="text-info">
          <FontAwesomeIcon icon={faInfoCircle} /> Name Change
        </span>,
        "Your name has been changed successfully!"
      );
      showChanging(false);
      fetchWithRetry(
        `https://api2.easycryptobot.com/login.php`,
        "get",
        null,
        (json) => {
          if (json && json.user && json.token) {
            dispatch({
              type: "user",
              payload: {
                token: json.token,
                id: json.user.id,
                name: json.user.name,
                email: json.user.email,
                expireDate: formatDateTime(new Date(json.user.expireDate)),
                isExpired: json.user.isExpired,
                refCode: json.user.refCode,
                subscriptionType: json.user.subscriptionType,
                parent: json.user.parent,
                customPlan: json.user.customPlan,
                dispatch: dispatch,
              },
            });
          }
        },
        null,
        { Authorization: `Bearer ${token}` }
      );

      return;
    },
    () => {
      showChanging(false);
      showInfoDialog(
        dispatch,
        <span className="text-danger">
          <FontAwesomeIcon icon={faExclamationTriangle} /> Name Change Failed
        </span>,
        "Wrong password!"
      );
    },
    { Authorization: `Bearer ${token}` }
  );
}

async function changePassword(
  token,
  password,
  newPassword,
  newPassword2,
  dispatch,
  showChanging
) {
  if (newPassword !== newPassword2) {
    showInfoDialog(
      dispatch,
      <span className="text-danger">
        <FontAwesomeIcon icon={faExclamationTriangle} /> Password Change Failed
      </span>,
      "The retyped password does not match the new password!"
    );
    showChanging(false);
    return;
  }
  if (newPassword.length < 3) {
    showInfoDialog(
      dispatch,
      <span className="text-danger">
        <FontAwesomeIcon icon={faExclamationTriangle} /> Password Change Failed
      </span>,
      "The new password must be at least 3 symbols!"
    );
    showChanging(false);
    return;
  }

  fetchWithRetry(
    `https://api2.easycryptobot.com/password.php`,
    "post",
    {
      password: password,
      newPassword: newPassword,
    },
    (json) => {
      showInfoDialog(
        dispatch,
        <span className="text-info">
          <FontAwesomeIcon icon={faInfoCircle} /> Password Change
        </span>,
        "Your password has been changed successfully!"
      );
      let locked = encrypt("locked", newPassword);
      localStorage.setItem("locked", locked);

      showChanging(false);
      return;
    },
    () => {
      showChanging(false);
      showInfoDialog(
        dispatch,
        <span className="text-danger">
          <FontAwesomeIcon icon={faExclamationTriangle} /> Password Change
          Failed
        </span>,
        "Wrong password!"
      );
    },
    { Authorization: `Bearer ${token}` }
  );
}

async function verifyPayment(
  orderId,
  state,
  dispatch,
  setOrderId,
  retry = 1,
  maxRetries = 20
) {
  fetchWithRetry(
    `https://api2.easycryptobot.com/payment.php?id=${orderId}`,
    "get",
    null,
    (json) => {
      let wasExpired = state.user.isExpired;
      fetchWithRetry(
        `https://api2.easycryptobot.com/login.php`,
        "get",
        null,
        (json) => {
          if (json && json.user && json.token) {
            dispatch({
              type: "user",
              payload: {
                token: json.token,
                id: json.user.id,
                name: json.user.name,
                email: json.user.email,
                expireDate: formatDateTime(new Date(json.user.expireDate)),
                isExpired: json.user.isExpired,
                refCode: json.user.refCode,
                subscriptionType: json.user.subscriptionType,
                parent: json.user.parent,
                customPlan: json.user.customPlan,
                dispatch: dispatch,
              },
            });
            setOrderId(null);
            if (wasExpired) {
              dispatch({
                type: "reload",
                payload: {},
              });
            }
          }
        },
        null,
        { Authorization: `Bearer ${state.user.token}` }
      );
    },
    async () => {
      if (retry > maxRetries) {
        return;
      }
      await sleep(1000 * 30);
      verifyPayment(orderId, state, dispatch, setOrderId, retry + 1);
    },
    { Authorization: `Bearer ${state.user.token}` }
  );
}

const Downloads = (props) => {
  let { dispatch } = useContext(globalContext);
  return (
    <>
      <div className="d-flex flex-wrap flex-md-nowap justify-content-center">
        <div className="col my-4">
          <div className="card rounded text-dark bg-white">
            <div className={props.homePage ? "d-none" : "card-header"}>
              <span className="d-inline-block mr-1">
                <img src={favlogo} alt="" />
              </span>
              EasyCryptoBot for:
            </div>

            <a
              id="ecb-win64"
              href="https://easycryptobot.com/downloads/ecb-win64.zip"
              downlaod="ecb-win64.zip"
              className="btn p-0"
              title="Windows 64"
              onClick={(e) => {
                startDownload("#ecb-win64", dispatch);
              }}
            >
              <img className="rounded w-100" src={windows64Logo} alt="" />
            </a>
          </div>
        </div>
        <div className="col my-4">
          <div className="card rounded text-dark bg-white">
            <div className={props.homePage ? "d-none" : "card-header"}>
              <span className="d-inline-block mr-1">
                <img src={favlogo} alt="" />
              </span>
              EasyCryptoBot for:
            </div>

            <a
              id="ecb-win32"
              href="https://easycryptobot.com/downloads/ecb-win32.zip"
              download="ecb-win32.zip"
              className="btn p-0"
              title="Windows 32"
              onClick={(e) => {
                startDownload("#ecb-win32", dispatch);
              }}
            >
              <img className="rounded w-100" src={windows32Logo} alt="" />
            </a>
          </div>
        </div>
        <div className="col my-4">
          <div className="card rounded text-dark bg-white">
            <div className={props.homePage ? "d-none" : "card-header"}>
              <span className="d-inline-block mr-1">
                <img src={favlogo} alt="" />
              </span>
              EasyCryptoBot for:
            </div>

            <a
              id="ecb-lin"
              href="https://easycryptobot.com/downloads/ecb-linux.zip"
              download="ecb-linux.tar"
              className="btn p-0"
              title="Linux"
              onClick={(e) => {
                startDownload("#ecb-lin", dispatch);
              }}
            >
              <img className="rounded w-100" src={linuxLogo} alt="" />
            </a>
          </div>
        </div>
        <div className={`col my-4 ${props.homePage ? "pr-0" : ""}`}>
          <div className="card rounded text-dark bg-white">
            <div className={props.homePage ? "d-none" : "card-header"}>
              <span className="d-inline-block mr-1">
                <img src={favlogo} alt="" />
              </span>
              EasyCryptoBot for:
            </div>

            <a
              id="ecb-mac"
              href="https://easycryptobot.com/downloads/ecb-mac.zip"
              download="ecb-mac.zip"
              className="btn p-0"
              title="Mac"
              onClick={(e) => {
                startDownload("#ecb-mac", dispatch);
              }}
            >
              <img className="rounded w-100" src={macLogo} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className={props.homePage ? "small" : "text-center pt-2"}>
        <div className="text-secondary mb-2">
          Recommended: minimum 3GB free RAM
        </div>
        {!props.homePage && (
          <div>
            Cloud service is also available on request. It is not included in
            the standard pricing plans.
            <div>
              <NavLink
                className="text-info mr-1"
                as={NavLink}
                to="/contact"
                exact
                activeClassName="active"
              >
                Contact us
              </NavLink>
              for more information.
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const AddExchangeModal = (props) => {
  let { state, dispatch } = useContext(globalContext);
  return (
    <ModalConfirm
      id="addApiKeys"
      okText="OK"
      okDisabled={
        !props.exchange ||
        props.exchange === exchanges[0] ||
        props.apiKey.length === 0 ||
        props.apiSecret.length === 0
      }
      header={
        props.message ? (
          <div className="text-danger">
            <FontAwesomeIcon icon={faExclamationTriangle} /> {props.message}
          </div>
        ) : (
          <div>
            <FontAwesomeIcon icon={faCoins} />{" "}
            {props.editExchange ? "Edit" : "Add"} exchange account
          </div>
        )
      }
      content={
        <>
          {props.editExchange ? (
            <div className="mb-1">
              <div className="d-inline-block min-w-150">Select Exchange:</div>
              <b>{props.exchange}</b>
            </div>
          ) : (
            <div className="mb-1">
              <div className="d-inline-block min-w-150">Select Exchange:</div>
              <Dropdown
                btnClasses={`btn btn-sm btn-outline-info justify-content-center w-165 `}
                selectedClasses={`mx-auto`}
                arrow={true}
                arrowClassses="ml-auto mt-2"
                placeholder="required"
                placeholderHover="mx-auto"
                menuClasses="bg-white border border-info rounded w-165"
                menuItemsClasses="small text-dark hover-bg-info hover-light py-1"
                selected={props.exchange}
                setSelected={(selected) => props.setExchange(selected)}
                items={exchanges}
              />
            </div>
          )}

          <div className="mb-1">
            <div className="d-inline-block min-w-150">API Key:</div>
            <input
              type="text"
              className="btn-outline-info w-165"
              value={props.apiKey}
              autoComplete="off"
              placeholder="<API Key>"
              onChange={(e) => {
                props.setApiKey(e.target.value);
              }}
            />
          </div>
          <div className="mb-1">
            <div className="d-inline-block min-w-150">API Secret:</div>
            <input
              type="text"
              className="btn-outline-info w-165"
              value={props.apiSecret}
              autoComplete="off"
              placeholder="<API Secret>"
              onChange={(e) => {
                props.setApiSecret(e.target.value);
              }}
            />
          </div>
          <div className="mb-1">
            Your API Key and API Secret will be encrypted and stored only on
            your computer.
          </div>
        </>
      }
      func={async () => {
        if (
          props.exchange === exchanges[0] ||
          props.apiKey.length === 0 ||
          props.apiSecret === 0 ||
          state.demo
        ) {
          return;
        }
        if (state.demo) {
          dispatch({
            type: "addExchange",
            payload: {
              exchange: props.exchange,
              data: {
                key: props.apiKey,
                secret: props.apiSecret,
                valid: false,
              },
            },
          });
          return;
        }
        let [isValid, error] = await getExchange(props.exchange).verifyApiKey(
          props.apiKey,
          props.apiSecret
        );
        if (props.fn) {
          props.fn(props.exchange);
        }
        if (error) {
          showInfoDialog(
            dispatch,
            <span className="text-danger">
              <FontAwesomeIcon icon={faExclamationTriangle} /> Invalid API key:
            </span>,
            error
          );
          dispatch({
            type: "addExchange",
            payload: {
              exchange: props.exchange,
              data: {
                key: props.apiKey,
                secret: props.apiSecret,
                valid: isValid != null,
              },
            },
          });
          dispatch({
            type: "assets",
            payload: { id: props.exchange, assets: isValid },
          });
          return;
        }

        if (isValid) {
          getExchange(props.exchange).subscribeToBalanceUpdate(
            props.exchange,
            (assets) => {
              dispatch({
                type: "assets",
                payload: { id: props.exchange, assets: assets },
              });
            }
          );
          dispatch({
            type: "assets",
            payload: { id: props.exchange, assets: isValid },
          });
        } else {
          showInfoDialog(
            dispatch,
            <span className="text-danger">
              <FontAwesomeIcon icon={faExclamationTriangle} /> Invalid account
            </span>,
            `Wrong API key or secret or failed connection to ${props.exchange}.`
          );
        }
        dispatch({
          type: "addExchange",
          payload: {
            exchange: props.exchange,
            data: {
              key: props.apiKey,
              secret: props.apiSecret,
              valid: isValid != null,
            },
          },
        });
      }}
      closeFn={props.closeFn}
    />
  );
};

const AddExchange = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");
  const [exchange, setExchange] = useState(exchanges[0]);
  const [editExchange, setEditExchange] = useState(false);
  return (
    <>
      <AddExchangeModal
        apiKey={apiKey}
        setApiKey={setApiKey}
        apiSecret={apiSecret}
        setApiSecret={setApiSecret}
        exchange={exchange}
        setExchange={setExchange}
        editExchange={editExchange}
      />

      <button
        className={props.buttonClasses}
        onClick={(e) => {
          setExchange(exchanges[0]);
          setEditExchange(false);
          setApiKey("");
          setApiSecret("");
          if (!$("#addApiKeys").is(":visible")) {
            $("#addApiKeys").modal("toggle");
          }
        }}
      >
        Add
        <FontAwesomeIcon className="ml-2" icon={faPlus} />
      </button>
      <div className="d-flex">
        <label className="d-inline-block min-w-130" />
        {Object.keys(state.exchanges).length > 0 &&
          Object.keys(state.exchanges).map((exchange, index) => {
            let logoSrc = "";
            switch (exchange) {
              case "Binance":
                logoSrc = binanceLogo;
                break;
              case "Binance US":
                logoSrc = binanceusLogo;
                break;
              default:
            }

            return (
              <div key={index} className="card-deck my-4 mr-3">
                <div className="card rounded text-center">
                  <img className="card-img-top " src={logoSrc} alt="" />
                  <a
                    href="#/"
                    className="position-absolute right-025 text-danger"
                    title="Remove"
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      showConfirmDialog(
                        dispatch,
                        <span className="text-info">
                          <FontAwesomeIcon icon={faQuestionCircle} /> Remove
                          Exchange
                        </span>,
                        <>
                          Are you sure you want to remove <b>{exchange}</b>{" "}
                          exchange?
                        </>,
                        () => {
                          dispatch({
                            type: "removeExchange",
                            payload: exchange,
                          });
                        }
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="" />
                  </a>
                  <div>
                    <div
                      className={`card-text bold mt-2 mb-1 ${
                        state.exchanges[exchange].valid
                          ? "text-info"
                          : "text-danger"
                      }`}
                    >
                      <b>
                        {state.exchanges[exchange].valid ? "VALID" : "INVALID"}
                      </b>
                    </div>

                    <a
                      href="#/"
                      className="btn btn-small btn-dark small mt-2 mb-3"
                      title="Update API Key"
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                        setEditExchange(true);
                        setExchange(exchange);
                        setApiKey("");
                        setApiSecret("");
                        if (!$("#addApiKeys").is(":visible")) {
                          $("#addApiKeys").modal("toggle");
                        }
                      }}
                    >
                      Update Key
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

const Settings = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const [subTab, setSubTab] = useState("accountSettings");
  const [updateAccountType, setUpdateAccountType] = useState("");
  const [newName, setNewName] = useState("");
  const [changingName, setChangingName] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [changingEmail, setChangingEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [changingPassword, setChangingPassword] = useState(false);

  const [selectedProPlanType, setSelectedProPlanType] = useState("y");
  const [selectedCustomPlanPrice, setSelectedCustomPlanPrice] = useState("");
  const [selectedCustomPlanType, setSelectedCustomPlanType] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeDiscount, setPromoCodeDiscount] = useState("");
  const [checkPromoCode, setCheckPromoCode] = useState(false);
  const [serverDiscountB, setServerDiscountB] = useState(null);
  const [serverDiscountPro, setServerDiscountPro] = useState(null);

  const [backup, setBackup] = useState(null);
  const [allStr, setAllStr] = useState(false);
  const [relatedStrategies, setRelatedStrategies] = useState({});
  const [relatedStrategiesImport, setRelatedStrategiesImport] = useState({});
  const [relatedStrategiesDefault] = useState("");
  const [filter, setFilter] = useState("");
  const [filteredStrategiesIds, setFilteredStrategiesIds] = useState([]);
  const [importedStrategies, setImportedStrategies] = useState([]);
  const [filteredImportedStrategiesIds, setFilteredImportedStrategiesIds] =
    useState([]);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    fetchWithRetry(
      `https://api2.easycryptobot.com/promo-code.php?c=DiscountB`,
      "get",
      null,
      (json) => {
        if (json.discount !== 0) {
          setServerDiscountB(json.discount);
        }
      },
      () => {
        setServerDiscountB(null);
      }
    );
    fetchWithRetry(
      `https://api2.easycryptobot.com/promo-code.php?c=DiscountPro`,
      "get",
      null,
      (json) => {
        if (json.discount !== 0) {
          setServerDiscountPro(json.discount);
        }
      },
      () => {
        setServerDiscountPro(null);
      }
    );
  }, []);

  useEffect(() => {
    setFilteredStrategiesIds(state.strategies.map((el) => el.id));
  }, [state.strategies]);

  useEffect(() => {
    let strategiesIds = Object.keys(relatedStrategies);
    if (strategiesIds.length > 0) {
      let strategies = state.strategies.filter((strategy) =>
        strategiesIds.includes(strategy.id)
      );
      let blob = new Blob(
        [
          encrypt(
            JSON.stringify({
              strategies: strategies,
            })
          ),
        ],
        {
          type: "application/json",
          encoding: "UTF-8",
        }
      );
      setBackup(blob);
    } else {
      setBackup(null);
    }
  }, [relatedStrategies]);

  const markRelatedStrategies = (id, name) => {
    if (!relatedStrategies[id]) {
      relatedStrategies[id] = name;
    } else {
      delete relatedStrategies[id];
    }
    setRelatedStrategies({ ...relatedStrategies });
  };

  const markRelatedStrategiesImport = (id, name) => {
    if (!relatedStrategiesImport[id]) {
      relatedStrategiesImport[id] = name;
    } else {
      delete relatedStrategiesImport[id];
    }
    setRelatedStrategiesImport({ ...relatedStrategiesImport });
  };

  const processPayment = (orderId) => {
    if ($("#paypalButtonsModal").is(":visible")) {
      $("#paypalButtonsModal").modal("toggle");
    }
    dispatch({ type: "orderId", payload: orderId });
    verifyPayment(orderId, state, dispatch, () =>
      dispatch({ type: "orderId", payload: null })
    );
  };
  return (
    <>
      <div className="page-alt bg-white text-dark pb-5 px-sm-5">
        <ModalConfirm
          id="accountUpdate"
          okText="Change"
          header={
            <div>
              <FontAwesomeIcon icon={faUserEdit} /> Change {updateAccountType}
            </div>
          }
          content={
            <>
              <div className="row text-center">
                <form
                  className="form-signin mx-auto"
                  id="signInForm"
                  onSubmit={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                >
                  {updateAccountType === "name" && (
                    <div className="form-label-group mb-3">
                      <input
                        type="text"
                        className="btn-outline-gray w-400"
                        placeholder="New Name"
                        required
                        autoComplete="off"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                      />
                    </div>
                  )}
                  {updateAccountType === "email" && (
                    <div className="form-label-group mb-3">
                      <input
                        type="email"
                        className="btn-outline-gray w-400"
                        placeholder="New Email"
                        required
                        autoComplete="off"
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                      />
                    </div>
                  )}
                  <div className="form-label-group mb-3">
                    <input
                      type="password"
                      className="btn-outline-gray w-400"
                      placeholder={
                        updateAccountType === "password"
                          ? "Old Password"
                          : "Password"
                      }
                      required
                      autoComplete="off"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  {updateAccountType === "password" && (
                    <>
                      <div className="form-label-group mb-3">
                        <input
                          type="password"
                          className="btn-outline-gray w-400"
                          placeholder="New Password"
                          required
                          autoComplete="off"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </div>

                      <div className="form-label-group mb-3">
                        <input
                          type="password"
                          id="signUpPassword"
                          className="btn-outline-gray w-400"
                          placeholder="Retype New Password"
                          autoComplete="off"
                          required
                          value={newPassword2}
                          onChange={(e) => setNewPassword2(e.target.value)}
                        />
                      </div>
                    </>
                  )}
                </form>
              </div>
            </>
          }
          func={async () => {
            switch (updateAccountType) {
              case "name":
                setChangingName(true);
                changeName(
                  state.user.token,
                  password,
                  newName,
                  dispatch,
                  (value) => {
                    if (componentIsMounted.current) {
                      setChangingName(value);
                    }
                  }
                );
                break;
              case "email":
                setChangingEmail(true);
                changeEmail(
                  state.user.token,
                  password,
                  newEmail,
                  dispatch,
                  (value) => {
                    if (componentIsMounted.current) {
                      setChangingEmail(value);
                    }
                  }
                );
                break;
              case "password":
                setChangingPassword(true);
                changePassword(
                  state.user.token,
                  password,
                  newPassword,
                  newPassword2,
                  dispatch,
                  (value) => {
                    if (componentIsMounted.current) {
                      setChangingPassword(value);
                    }
                  }
                );
                break;
              default:
            }
            setPassword("");
          }}
        />

        <Modal
          id="paypalButtonsModal"
          okText="Close"
          header={
            <span className="text-info">
              <FontAwesomeIcon icon={faStar} /> Buy subscription
            </span>
          }
          content={
            <>
              <div id="paypalBtn"></div>
              <div className="small">
                *For payments in Crypto currencies please contact us at{" "}
                <span className="text-info">sales@easycryptobot.com</span>
              </div>
            </>
          }
        />
        <ModalConfirm
          id={"backupStrategiesModal"}
          header={
            <>
              <FontAwesomeIcon icon={faDatabase} /> Export
            </>
          }
          okText="EXPORT"
          okDisabled={Object.keys(relatedStrategies).length === 0}
          content={
            <div>
              {state.strategies.length > 0 && (
                <>
                  <div className="mr-2">Export strategies:</div>
                  <div className="my-2 text-left">
                    <FontAwesomeIcon className="text-info" icon={faSearch} />
                    &nbsp;
                    <div className="d-inline-block input-group-sm">
                      <input
                        type="text"
                        className="btn-bottom-outline-info w-400"
                        value={filter}
                        placeholder="Search"
                        onChange={(e) => {
                          setFilter(e.target.value);
                          setFilteredStrategiesIds(
                            state.strategies
                              .filter(
                                (el) =>
                                  el.name
                                    .toLowerCase()
                                    .indexOf(e.target.value.toLowerCase()) !==
                                  -1
                              )
                              .map((el) => el.id)
                          );
                        }}
                      ></input>
                    </div>
                  </div>
                  <div className="custom-control custom-checkbox" key="All">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="allStr"
                      checked={allStr}
                      onChange={(e) => {
                        if (!allStr) {
                          let relatedStrategies = {};
                          state.strategies.forEach((strategy) => {
                            relatedStrategies[strategy.id] = strategy.name;
                          });
                          setRelatedStrategies(relatedStrategies);
                        } else {
                          setRelatedStrategies({});
                        }
                        setAllStr(!allStr);
                      }}
                    />
                    <label
                      type="text"
                      className="custom-control-label font-weight-light font-italic"
                      htmlFor="allStr"
                    >
                      Select all
                    </label>
                  </div>
                  <div className="overflow-auto white my-1 export-strategies">
                    {filteredStrategiesIds
                      .map((id) => state.strategies.find((el) => el.id === id))
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((strategy) => {
                        return (
                          <div
                            className="custom-control custom-checkbox"
                            key={strategy.id}
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`c${strategy.id}`}
                              checked={
                                relatedStrategies[strategy.id]
                                  ? relatedStrategies[strategy.id]
                                  : relatedStrategiesDefault
                              }
                              onChange={(e) => {
                                markRelatedStrategies(
                                  strategy.id,
                                  strategy.name
                                );
                              }}
                            />
                            <label
                              type="text"
                              className="custom-control-label"
                              htmlFor={`c${strategy.id}`}
                            >
                              {strategy.name}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
            </div>
          }
          func={async () => {
            let currentTry = 0;
            while (currentTry < 10) {
              if (backup) {
                document.getElementById("createBackup").click();
                break;
              } else {
                await sleep(1000);
              }
              currentTry++;
            }
          }}
        />

        <ModalConfirm
          id={"importStrategiesModal"}
          header={
            <>
              <FontAwesomeIcon icon={faDownload} /> Import Strategies
            </>
          }
          okText="IMPORT"
          okDisabled={Object.keys(relatedStrategiesImport).length === 0}
          content={
            <div>
              {importedStrategies.length > 0 && (
                <>
                  <div className="mr-2">Select strategies to import:</div>
                  <div className="my-2 text-left">
                    <FontAwesomeIcon className="text-info" icon={faSearch} />
                    &nbsp;
                    <div className="d-inline-block input-group-sm">
                      <input
                        type="text"
                        className="btn-bottom-outline-info w-400"
                        value={filter}
                        placeholder="Search"
                        onChange={(e) => {
                          setFilter(e.target.value);
                          setFilteredImportedStrategiesIds(
                            importedStrategies
                              .filter(
                                (el) =>
                                  el.name
                                    .toLowerCase()
                                    .indexOf(e.target.value.toLowerCase()) !==
                                  -1
                              )
                              .map((el) => el.id)
                          );
                        }}
                      ></input>
                    </div>
                  </div>
                  <div className="custom-control custom-checkbox" key="All">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="allStrImport"
                      checked={allStr}
                      onChange={(e) => {
                        if (!allStr) {
                          let relatedStrategiesImport = {};
                          importedStrategies.forEach((strategy) => {
                            relatedStrategiesImport[strategy.id] =
                              strategy.name;
                          });
                          setRelatedStrategiesImport(relatedStrategiesImport);
                        } else {
                          setRelatedStrategiesImport({});
                        }
                        setAllStr(!allStr);
                      }}
                    />
                    <label
                      type="text"
                      className="custom-control-label font-weight-light font-italic"
                      htmlFor="allStrImport"
                    >
                      Select all
                    </label>
                  </div>
                  <div className="overflow-auto white my-1 export-strategies">
                    {filteredImportedStrategiesIds
                      .map((id) =>
                        importedStrategies.find((el) => el.id === id)
                      )
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((strategy) => {
                        return (
                          <div
                            className="custom-control custom-checkbox"
                            key={strategy.id}
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`ci${strategy.id}`}
                              checked={
                                relatedStrategiesImport[strategy.id]
                                  ? relatedStrategiesImport[strategy.id]
                                  : relatedStrategiesDefault
                              }
                              onChange={(e) => {
                                markRelatedStrategiesImport(
                                  strategy.id,
                                  strategy.name
                                );
                              }}
                            />
                            <label
                              type="text"
                              className="custom-control-label"
                              htmlFor={`ci${strategy.id}`}
                            >
                              {strategy.name}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
            </div>
          }
          func={async () => {
            let restoredStrategies = 0;
            Object.keys(relatedStrategiesImport).forEach((id) => {
              let strategy = importedStrategies.find((el) => el.id === id);
              fixStrategyVersion(strategy);
              restoredStrategies++;
              dispatch({
                type: "importStrategy",
                payload: strategy,
              });
            });

            document.getElementById("inputFileRestore").value = "";
            showInfoDialog(
              dispatch,
              <span className="text-info">
                <FontAwesomeIcon icon={faDownload} /> Import completed
              </span>,
              <div>
                <div>
                  Imported:{" "}
                  <span className="text-info">{restoredStrategies}</span>{" "}
                  strategies!
                </div>
              </div>
            );
          }}
        />

        <div className="pt-3"></div>
        <div className="card user-select-none">
          <div className="card-body">
            <div className="card-title mb-4">
              <div className="d-flex justify-content-between">
                <div className="userData text-left">
                  <h2>{state.user.name}</h2>
                </div>
                <div className="userData ml-3">
                  <button
                    className="btn btn-dark btn-block text-uppercase mt-1"
                    onClick={() => {
                      showConfirmDialog(
                        dispatch,
                        <span className="text-info">
                          <FontAwesomeIcon icon={faQuestionCircle} /> Sign out
                        </span>,
                        <div>
                          {state.app
                            ? "All running strategies will be stopped."
                            : "Are you sure you want to sign out?"}
                        </div>,
                        () => {
                          state.logout();
                        }
                      );
                    }}
                  >
                    <span className="text-nowrap">Sign Out</span>{" "}
                    <FontAwesomeIcon icon={faPowerOff} />
                  </button>
                </div>
              </div>
            </div>

            <ul className="nav nav-tabs info font-weight-bold cursor-pointer mb-4">
              <li className="nav-item">
                <span
                  className={`nav-link text-secondary p-2 mr-2 ${
                    subTab === "accountSettings" ? "active" : "hover-noborder"
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    document.activeElement.blur();
                    setSubTab("accountSettings");
                  }}
                >
                  Account
                </span>
              </li>
              {!state.user.isSubAcc &&
                !state.demo &&
                state.user.subscriptionType !== "lifetime" && (
                  <li className="nav-item">
                    <span
                      className={`nav-link text-secondary p-2 mr-2 ${
                        subTab === "subscription" ? "active" : "hover-noborder"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                        setSubTab("subscription");
                      }}
                    >
                      {state.user.isExpired && (
                        <FontAwesomeIcon
                          icon={faExclamationCircle}
                          className="text-danger blink-slow mr-1"
                        />
                      )}
                      Subscriptions
                    </span>
                  </li>
                )}
              {!state.app && !state.demo && (
                <li className="nav-item">
                  <span
                    className={`nav-link text-secondary p-2 mr-2 ${
                      subTab === "downloads" ? "active" : "hover-noborder"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      setSubTab("downloads");
                    }}
                  >
                    Downloads
                  </span>
                </li>
              )}
            </ul>
            <div className="ml-1">
              {subTab === "accountSettings" && (
                <div className="fade-in" id="accountSettings">
                  {state.user.isExpired && !state.orderId && (
                    <>
                      <h3 className="text-danger">
                        {state.user.subscriptionType === "trial"
                          ? "The trial period has expired!"
                          : `Your ${state.user.subscriptionType} plan has expired!`}
                      </h3>
                      <div className="text-muted small mb-4">
                        Your account is now limited to the Free plan
                      </div>
                    </>
                  )}

                  <div className="text-left">
                    <label className="d-inline-block min-w-130">Name:</label>
                    {changingName ? (
                      <div
                        className="loading-img-sm cursor-help"
                        title="Loading.."
                      >
                        <img src={loading} alt="" />
                      </div>
                    ) : (
                      <>
                        <span className="text-info font-weight-bold mr-3">
                          {state.user.name}
                        </span>
                        {!state.demo && !state.user.isSubAcc && (
                          <a
                            href="#/"
                            className="text-info"
                            title="Change Name"
                            onClick={(e) => {
                              e.preventDefault();
                              document.activeElement.blur();
                              setUpdateAccountType("name");
                              if (!$("#accountUpdate").is(":visible")) {
                                $("#accountUpdate").modal("toggle");
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </a>
                        )}
                      </>
                    )}
                  </div>
                  <hr />

                  <div className="text-left">
                    <label className="d-inline-block min-w-130">E-mail:</label>
                    {changingEmail ? (
                      <div
                        className="loading-img-sm cursor-help"
                        title="Loading.."
                      >
                        <img src={loading} alt="" />
                      </div>
                    ) : (
                      <>
                        <span className="text-info font-weight-bold mr-3">
                          {state.user.email}
                        </span>
                        {!state.demo && !state.user.isSubAcc && (
                          <>
                            <a
                              href="#/"
                              className="text-info"
                              title="Change Email"
                              onClick={(e) => {
                                e.preventDefault();
                                document.activeElement.blur();
                                setUpdateAccountType("email");
                                if (!$("#accountUpdate").is(":visible")) {
                                  $("#accountUpdate").modal("toggle");
                                }
                              }}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </a>
                            {state.editEmail && (
                              <a
                                href="#/"
                                className="text-info ml-3"
                                title="Refresh Email"
                                onClick={(e) => {
                                  e.preventDefault();
                                  document.activeElement.blur();

                                  fetchWithRetry(
                                    `https://api2.easycryptobot.com/login.php`,
                                    "get",
                                    null,
                                    (json) => {
                                      if (
                                        json &&
                                        json.user &&
                                        json.token &&
                                        json.user.email !== state.user.email
                                      ) {
                                        dispatch({
                                          type: "user",
                                          payload: {
                                            token: json.token,
                                            id: json.user.id,
                                            name: json.user.name,
                                            email: json.user.email,
                                            expireDate: formatDateTime(
                                              new Date(json.user.expireDate)
                                            ),
                                            isExpired: json.user.isExpired,
                                            refCode: json.user.refCode,
                                            subscriptionType:
                                              json.user.subscriptionType,
                                            parent: json.user.parent,
                                            customPlan: json.user.customPlan,
                                            dispatch: dispatch,
                                          },
                                        });
                                        dispatch({
                                          type: "editEmail",
                                          payload: false,
                                        });
                                      }
                                    },
                                    null,
                                    {
                                      Authorization: `Bearer ${state.user.token}`,
                                    }
                                  );
                                }}
                              >
                                <FontAwesomeIcon icon={faSync} />
                              </a>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <hr />
                  {!state.demo && !state.user.isSubAcc && (
                    <>
                      <div className="text-left">
                        <label className="d-inline-block min-w-130">
                          Password:
                        </label>
                        {changingPassword ? (
                          <div
                            className="loading-img-sm cursor-help"
                            title="Loading.."
                          >
                            <img src={loading} alt="" />
                          </div>
                        ) : (
                          <a
                            href="#/"
                            className="btn btn-sm btn-info"
                            title="Change Password"
                            onClick={(e) => {
                              e.preventDefault();
                              document.activeElement.blur();
                              setUpdateAccountType("password");
                              if (!$("#accountUpdate").is(":visible")) {
                                $("#accountUpdate").modal("toggle");
                              }
                            }}
                          >
                            Change
                          </a>
                        )}
                      </div>
                      <hr />
                    </>
                  )}

                  <div className="text-left">
                    <label className="d-inline-block min-w-130">
                      Subscription:
                    </label>
                    <a
                      href="/"
                      className={
                        state.user.isExpired
                          ? "text-danger font-weight-bold"
                          : "text-info font-weight-bold"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                        if (
                          !state.demo &&
                          state.user.subscriptionType !== "lifetime"
                        ) {
                          setSubTab("subscription");
                        }
                      }}
                    >
                      {state.user.isExpired ? "Expired " : ""}
                      {state.user.subscriptionType === "trial" && "Trial"}
                      {state.user.subscriptionType === "beginner" &&
                        "Beginner plan"}
                      {state.user.subscriptionType.includes("Custom") &&
                        `${state.user.subscriptionType} plan`}
                      {(state.user.subscriptionType === "monthly" ||
                        state.user.subscriptionType === "annually" ||
                        state.user.subscriptionType === "lifetime") &&
                        `Pro ${state.user.subscriptionType} plan`}
                      {!state.demo &&
                        state.user.subscriptionType !== "lifetime" && (
                          <FontAwesomeIcon
                            icon={faCaretRight}
                            className="ml-2 blink-slow"
                          />
                        )}
                    </a>
                    <div className="text-muted small">
                      <label className="d-inline-block min-w-130" />
                      Exp:{" "}
                      {state.user.subscriptionType !== "lifetime"
                        ? state.user.expireDate
                        : "Never"}
                    </div>
                  </div>
                  <hr />
                  <div className="text-left">
                    <label className="d-inline-block min-w-130">
                      Referral Code:
                    </label>
                    <span className="text-info font-weight-bold one-click-select">
                      {state.user.refCode}
                    </span>
                    <a
                      href="#/"
                      className="text-info"
                      title="Info"
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                        showInfoDialog(
                          dispatch,
                          <span className="text-info">
                            <FontAwesomeIcon icon={faInfoCircle} /> Referral
                            Code
                          </span>,
                          <div>
                            Give this code to your friends. They will receive
                            one additional month when they subscribe for the
                            first time.
                            {state.user.expireDate < "2100-01-01 00:00:00" && (
                              <div className="mt-2">
                                You will receive one month of subscription for
                                every new user that uses the code.
                              </div>
                            )}
                          </div>
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                    </a>
                  </div>
                  {state.app && (
                    <>
                      <hr />
                      <div className="text-left">
                        <label className="d-inline-block min-w-130">
                          Exchanges acc:
                        </label>
                        <div className="d-inline font-weight-normal">
                          {state.exchangesInitialized ? (
                            <AddExchange buttonClasses="btn btn-sm btn-info" />
                          ) : (
                            <div
                              className="loading-img-sm cursor-help "
                              title="Loading.."
                            >
                              <img src={loading} alt="" />
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {state.app && !state.demo && (
                    <>
                      <hr />
                      <div className="text-left">
                        <label className="d-inline-block text-wrap w-130">
                          Import/Export Strategies:
                        </label>
                        <div className="d-inline align-top">
                          <input
                            id="inputFileRestore"
                            type="file"
                            name="name"
                            className="d-none"
                            onChange={() => {
                              try {
                                let file =
                                  document.getElementById("inputFileRestore")
                                    .files[0];
                                let fileReader = new FileReader();
                                fileReader.onload = (event) => {
                                  try {
                                    let json = {};
                                    try {
                                      json = JSON.parse(
                                        decrypt(event.target.result)
                                      );
                                    } catch (e) {
                                      //json = JSON.parse(event.target.result);
                                    }

                                    let importedStrategies = [];
                                    if (json.strategies) {
                                      importedStrategies = json.strategies; //json.strategies.filter((el) => el.userId === state.user.id);
                                      setImportedStrategies(importedStrategies);
                                      setFilteredImportedStrategiesIds(
                                        importedStrategies.map((el) => el.id)
                                      );
                                      let relatedStrategies = {};
                                      importedStrategies.forEach(
                                        (el) =>
                                          (relatedStrategies[el.id] = el.name)
                                      );
                                      setRelatedStrategiesImport(
                                        relatedStrategies
                                      );
                                    }

                                    if (importedStrategies.length > 0) {
                                      if (
                                        !$("#importStrategiesModal").is(
                                          ":visible"
                                        )
                                      ) {
                                        $("#importStrategiesModal").modal(
                                          "toggle"
                                        );
                                      }
                                    } else {
                                      showInfoDialog(
                                        dispatch,
                                        <span className="text-danger">
                                          <FontAwesomeIcon
                                            icon={faExclamationTriangle}
                                          />{" "}
                                          Nothing to Import
                                        </span>,
                                        <>
                                          No strategies were found in the file.
                                        </>
                                      );
                                    }
                                  } catch (e) {
                                    showInfoDialog(
                                      dispatch,
                                      <span className="text-danger">
                                        <FontAwesomeIcon
                                          icon={faExclamationTriangle}
                                        />{" "}
                                        Import failed
                                      </span>,
                                      <>Invalid or corrupted file</>
                                    );
                                  }
                                };
                                fileReader.readAsText(file, "UTF-8");
                              } catch (e) {
                                //No file was selected
                              }
                            }}
                          />
                          {backup && (
                            <a
                              className="d-none"
                              download="ecb-export.json"
                              id="createBackup"
                              href={window.URL.createObjectURL(backup)}
                            >
                              Export
                            </a>
                          )}
                          <a
                            className="btn btn-sm btn-info mr-3"
                            id="restoreBackup"
                            href="#/"
                            onClick={(e) => {
                              e.preventDefault();
                              document.getElementById(
                                "inputFileRestore"
                              ).value = "";
                              document
                                .getElementById("inputFileRestore")
                                .click();
                              setFilter("");
                              setAllStr(true);
                              setFilteredImportedStrategiesIds([]);
                              setRelatedStrategiesImport({});
                              setRelatedStrategies({});
                              setImportedStrategies([]);
                            }}
                          >
                            Import
                          </a>
                          <a
                            className="btn btn-sm btn-info"
                            id=""
                            href="#/"
                            onClick={(e) => {
                              e.preventDefault();
                              setFilter("");
                              setAllStr(false);
                              setFilteredStrategiesIds(
                                state.strategies.map((el) => el.id)
                              );
                              setRelatedStrategies({});
                              if (state.strategies.length > 0) {
                                if (
                                  !$("#backupStrategiesModal").is(":visible")
                                ) {
                                  $("#backupStrategiesModal").modal("toggle");
                                }
                              } else {
                                showInfoDialog(
                                  dispatch,
                                  <span className="text-danger">
                                    <FontAwesomeIcon
                                      icon={faExclamationTriangle}
                                    />{" "}
                                    Nothing to export
                                  </span>,
                                  <>There are no strategies.</>
                                );
                              }
                            }}
                          >
                            Export
                          </a>
                        </div>
                      </div>
                    </>
                  )}
                  <hr />
                  <div className="text-left">
                    <label className="d-inline-block text-wrap w-130">
                      ECB Version:
                    </label>
                    <span className="text-info">{state.version}</span>{" "}
                    <VersionInfo
                      text={<FontAwesomeIcon icon={faInfoCircle} />}
                    />
                  </div>
                </div>
              )}
              {subTab === "subscription1" && (
                <div className="fade-in" id="subscription">
                  {state.orderId ? (
                    <div className="h-35-vh my-5">
                      <div
                        className="loading-img cursor-help mb-3"
                        title="Loading.."
                      >
                        <img src={loading} alt="" />
                      </div>
                      <h3 className="loading">
                        Your payment is being processed
                      </h3>
                    </div>
                  ) : (
                    <>
                      {state.user.isExpired && !state.orderId && (
                        <>
                          <h3 className="text-danger">
                            {state.user.subscriptionType === "trial"
                              ? "The trial period has expired!"
                              : `Your ${state.user.subscriptionType} plan has expired!`}
                          </h3>
                          <div className="text-muted small mb-4">
                            Your account is now limited to the Free plan
                          </div>
                        </>
                      )}
                      <div className="">
                        {true && (
                          <>
                            {!checkPromoCode ? (
                              <div className="my-2">
                                <div className="d-inline-block">
                                  Promo code:
                                </div>
                                <div className="d-inline-block w-130 px-2">
                                  <input
                                    type="text"
                                    className="form-control w-100-p h-32"
                                    value={promoCode}
                                    placeholder=""
                                    onChange={(e) => {
                                      setPromoCode(
                                        e.target.value.toUpperCase()
                                      );
                                    }}
                                  />
                                </div>
                                <button
                                  className="btn btn-sm btn-info text-uppercase mb-1"
                                  onClick={async () => {
                                    if (promoCode) {
                                      setCheckPromoCode(true);
                                      //await sleep(2000);

                                      fetchWithRetry(
                                        `https://api2.easycryptobot.com/promo-code.php?c=${promoCode}`,
                                        "get",
                                        null,
                                        (json) => {
                                          setCheckPromoCode(false);
                                          setPromoCodeDiscount(json.discount);
                                        },
                                        () => {
                                          setCheckPromoCode(false);
                                          setPromoCodeDiscount(null);
                                        }
                                      );
                                    }
                                  }}
                                >
                                  Apply
                                </button>
                              </div>
                            ) : (
                              <div
                                className="loading-img cursor-help mb-3"
                                title="Checking referral code.."
                              >
                                <img src={loading} alt="" />
                              </div>
                            )}{" "}
                          </>
                        )}
                        {promoCodeDiscount &&
                          (promoCodeDiscount > 0 ? (
                            <>
                              <span className="text-info my-4 font-weight-bold">
                                Applied{" "}
                                <b className="text-danger">
                                  -{promoCodeDiscount}%
                                </b>{" "}
                                discount!
                              </span>
                              <a
                                href="#/"
                                className="text-danger ml-2"
                                title="Remove discount"
                                onClick={(e) => {
                                  e.preventDefault();
                                  document.activeElement.blur();
                                  setPromoCode("");
                                  setPromoCodeDiscount(null);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faTimesCircle}
                                  className=""
                                />
                              </a>
                            </>
                          ) : (
                            <div className="text-danger my-4 font-weight-bold">
                              Invalid code!
                            </div>
                          ))}
                        <PricingPlans
                          discount={{
                            b: promoCodeDiscount
                              ? promoCodeDiscount
                              : serverDiscountB,
                            pro: promoCodeDiscount
                              ? promoCodeDiscount
                              : serverDiscountPro,
                          }}
                          selectedProPlanType={selectedProPlanType}
                          setSelectedProPlanType={setSelectedProPlanType}
                          setSelectedCustomPlanPrice={
                            setSelectedCustomPlanPrice
                          }
                          selectedCustomPlanType={selectedCustomPlanType}
                          setSelectedCustomPlanType={setSelectedCustomPlanType}
                          btnLabel="Buy"
                          btnFn={{
                            b: () => {
                              if (state.demo) {
                                return;
                              }
                              showPaypalModal(
                                state.user.token,
                                state.user.id,
                                processPayment,
                                "b",
                                beginnerPlan,
                                promoCode,
                                promoCodeDiscount
                                  ? promoCodeDiscount
                                  : serverDiscountB
                              );
                            },
                            pro: () => {
                              if (state.demo) {
                                return;
                              }
                              showPaypalModal(
                                state.user.token,
                                state.user.id,
                                processPayment,
                                selectedProPlanType,
                                proPlan[selectedProPlanType],
                                promoCode,
                                promoCodeDiscount
                                  ? promoCodeDiscount
                                  : serverDiscountPro
                              );
                            },
                            custom: () => {
                              if (state.demo) {
                                return;
                              }
                              if (
                                !selectedCustomPlanPrice ||
                                !selectedCustomPlanType
                              ) {
                                return;
                              }
                              showPaypalModal(
                                state.user.token,
                                state.user.id,
                                processPayment,
                                selectedCustomPlanType,
                                selectedCustomPlanPrice
                              );
                            },
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
              {subTab === "subscription" && (
                  <div className="fade-in" id="subscription">
                    <h3 className="text-danger">
                      Currently we do not accept new clients. 
                    </h3>
                </div>
              )}
              {subTab === "downloads" && (
                <div className="fade-in" id="downloads">
                  <Downloads />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export { Downloads, AddExchange, AddExchangeModal };
export default Settings;
