import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faCog,
  faQuestionCircle,
  faHandPointRight,
  faExclamationTriangle,
  faTimes,
  faArrowCircleUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  resetIndicatorSetup,
  getIndicatorSetup,
  getIndicatorSettings,
  checkInvalidIndicatorSetting,
  getHoursFromTimeframes,
} from "./utils.js";
import Dropdown from "./Dropdown";
import {
  timeframes,
  indicators,
  directions,
  crossDirections,
  maIndicators,
  bbDirections,
  greaterLessDirections,
  candlePatterns,
  conditionDirections,
  aboveBelowDirections,
  highestLowestDirections,
  priceIndicators,
  upDownTrend,
  risingFalling,
  histogramDirections,
  histogramDirections2,
} from "./constants/consts.json";
import $ from "jquery";
import { Modal } from "./Modal.js";
import { RadioButtons } from "./RadioButtons.js";

const ruleDefaults = {
  bbValue: 4,
  kcValue: 2,
  maValue: 4,
  maValue2: 1,
  rsiValue: 20,
  rsiValue2: 4,
  stoValue: 20,
  stoValue2: 4,
};

const IndicatorSettingsTable = (props) => {
  const [indexes] = useState([1, 2, 3, 4, 5]);
  return (
    <table className="table table-sm table-transparent table-borderless rounded ">
      <tbody>
        {indexes.map((index) =>
          getIndicatorSettings(props.rule.indicator, index) ? (
            <tr key={index}>
              <td className="text-left text-nowrap text-light">
                {getIndicatorSettings(props.rule.indicator, index).label}:
              </td>
              <td className="text-left text-nowrap w-100">
                {index !== 5 ? (
                  <>
                    <input
                      type="number"
                      className={`strategy text-center required w-60 px-2 mr-2 ${
                        checkInvalidIndicatorSetting(
                          props.rule.indicator,
                          index,
                          props.rule[`period${index > 1 ? index : ""}`]
                        )
                          ? "invalid"
                          : ""
                      }`}
                      placeholder="<Required>"
                      value={props.rule[`period${index > 1 ? index : ""}`]}
                      onChange={(e) => {
                        let settings = getIndicatorSettings(props.rule.indicator, index);
                        let value = Math.abs(settings.decimal ? parseFloat(e.target.value) : parseInt(e.target.value));
                        props.rule[`period${index > 1 ? index : ""}`] = isNaN(value) ? "" : value;
                        props.saveSettings({ ...props.rule });
                        //TODO setIndicatorsEditList([...indicatorsEditList]);
                      }}
                    ></input>
                    <span className="text-info font-italic small">
                      {checkInvalidIndicatorSetting(
                        props.rule.indicator,
                        index,
                        props.rule[`period${index > 1 ? index : ""}`]
                      )}
                    </span>
                  </>
                ) : (
                  <RadioButtons
                    options={[
                      {
                        optionClasses: "text-white",
                        checkedColor: "text-light-green",
                        uncheckedColor: "text-white",
                        checked: props.rule.formula === "standard",
                        check: () => {
                          props.rule.formula = "standard";
                          props.saveSettings({ ...props.rule });
                        },
                        text: "standard",
                      },
                      {
                        optionClasses: "text-white",
                        checkedColor: "text-light-green",
                        uncheckedColor: "text-white",
                        checked: props.rule.formula === "smoothed",
                        check: () => {
                          props.rule.formula = "smoothed";
                          props.saveSettings({ ...props.rule });
                        },
                        text: "smoothed",
                      },
                    ]}
                  />
                )}
              </td>
              {props.hide && (
                <td className="text-nowrap text-right">
                  {index === 1 && (
                    <a
                      href="#/"
                      className="text-light-green"
                      title="Settings"
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                        props.hide();
                      }}
                    >
                      <FontAwesomeIcon icon={faArrowCircleUp} />
                    </a>
                  )}
                </td>
              )}
            </tr>
          ) : null
        )}
      </tbody>
    </table>
  );
};

const RuleIndicatorSettingPopup = (props) => {
  return (
    <Modal
      id={props.indicatorSettingsId}
      closeOnEnter={true}
      center={true}
      dark={true}
      header={
        <span>
          <FontAwesomeIcon className="mr-2" icon={faCog} />
          {props.rule.indicator} Setup
        </span>
      }
      content={
        <div className="px-3">
          <IndicatorSettingsTable
            rule={props.rule}
            saveSettings={() => {
              props.saveRule({ ...props.rule });
            }}
          />
        </div>
      }
      closeFn={() => {
        props.setShowOverlay(false);
        $("body").addClass("modal-open");
      }}
    />
  );
};

const RemoveConditionBtn = (props) => {
  return (
    <div className="col-rule-btn align-top mr-2">
      <a
        href="#/"
        className="text-danger d-inline-block"
        title="Remove condition"
        onClick={(e) => {
          e.preventDefault();
          document.activeElement.blur();
          let ruleTmp = { ...props.rule };
          delete ruleTmp[props.condition];
          ruleTmp.conditionsOrder.splice(ruleTmp.conditionsOrder.indexOf(props.condition), 1);
          props.saveRule(ruleTmp);
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </a>
    </div>
  );
};

function checkExcludingConditions(excludingConditions, addedConditions) {
  if (excludingConditions && excludingConditions.find((condition) => addedConditions.indexOf(condition) !== -1)) {
    return false;
  }
  return true;
}

const AddConditionBtn = (props) => {
  return !props.rule[props.condition] &&
    checkExcludingConditions(props.excludingConditions, props.rule.conditionsOrder) ? (
    <li className="p-0 text-nowrap">
      <a
        href="#/"
        className="p-1 text-gray hover-bg-light-green rounded d-inline-block border-bottom-transparent"
        title="Add condition"
        onClick={(e) => {
          e.preventDefault();
          document.activeElement.blur();
          let ruleTmp = { ...props.rule };
          ruleTmp[props.condition] = {};
          ruleTmp.conditionsOrder.push(props.condition);
          props.saveRule(ruleTmp);
        }}
      >
        <div className="col-rule-btn align-top mr-2">
          <FontAwesomeIcon className="text-light-green d-inline-block small" icon={faPlus} />
        </div>
        <div className="col-rule text-justify text-wrap">{props.content}</div>
      </a>
    </li>
  ) : (
    ""
  );
};

const RuleValue = (props) => {
  let value = props.rule[props.condition][props.valueKey ? props.valueKey : "value"];
  return (
    <>
      <input
        type="number"
        className={`strategy ${props.classes} ${props.defaultValue ? "default" : ""} ${
          value && !props.skipWidthChange ? "w-50" : ""
        }`}
        value={value !== undefined ? value : ""}
        placeholder={
          props.defaultValue ? `<Default: ${props.defaultValue}>` : props.isPercent ? "<Percent>" : "<Number>"
        }
        onChange={(e) => {
          let ruleTmp = { ...props.rule };
          if (props.defaultValue && e.target.value === "") {
            $(e.target).removeClass("is-invalid");
            delete ruleTmp[props.condition][props.valueKey ? props.valueKey : "value"];
          } else {
            let value = props.isInt ? parseInt(e.target.value) : parseFloat(e.target.value);
            value = props.isPositive ? Math.abs(value) : value;
            if (props.inputIsValidFunc(value)) {
              //$(e.target).removeClass("is-invalid");
            } else {
              //$(e.target).addClass("is-invalid");
            }
            ruleTmp[props.condition][props.valueKey ? props.valueKey : "value"] = isNaN(value) ? "" : value;
          }
          props.saveRule(ruleTmp);
        }}
      ></input>
      <div className="invalid-feedback invalid-feedback-absolute">{props.invalidMsg}</div>
    </>
  );
};

const ThePrice = (props) => {
  const [indicatorSetup, setIndicatorSetup] = useState("");

  useEffect(() => {
    if (props.rule.condition2) {
      setIndicatorSetup(getIndicatorSetup(props.rule));
    } else {
      setIndicatorSetup("");
    }
  }, [props.rule]);

  return (
    <>
      <ul className="list-unstyled text-left mb-0">
        {props.rule.conditionsOrder.map((condition) => {
          switch (condition) {
            case "condition1":
              return (
                <li key="condition1" className={`p-1 rounded ${props.rule.condition1.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition1" />
                  <div className="col-rule text-justify text-wrap">
                    is{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={
                        props.rule.condition1.direction ? props.rule.condition1.direction : conditionDirections[0]
                      }
                      setSelected={(direction) => {
                        let ruleTmp = { ...props.rule };
                        ruleTmp.condition1.direction = direction;
                        props.saveRule(ruleTmp);
                      }}
                      items={conditionDirections}
                    />{" "}
                    <RuleValue
                      classes="mr-1"
                      rule={props.rule}
                      condition="condition1"
                      saveRule={props.saveRule}
                      inputIsValidFunc={(value) => value >= 0 && value <= 100}
                      invalidMsg="Must be between 0 and 100"
                      isPositive={true}
                      isPercent={true}
                    />
                    {props.rule.condition1.direction && (
                      <>
                        % from its {props.rule.condition1.direction === "up" ? "lowest" : "highest"} point since the
                        last trade or the strategy start
                      </>
                    )}
                  </div>
                </li>
              );
            case "condition2":
              return (
                <li key="condition2" className={`p-1 rounded ${props.rule.condition2.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition2" />
                  <div className="col-rule text-justify text-wrap">
                    is{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={props.rule.condition2.direction ? props.rule.condition2.direction : directions[0]}
                      setSelected={(direction) => {
                        let ruleTmp = { ...props.rule };
                        if (direction !== "crossing") {
                          delete ruleTmp.condition2.crossDirection;
                        }
                        ruleTmp.condition2.direction = direction;
                        props.saveRule(ruleTmp);
                      }}
                      items={directions}
                    />{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={props.rule.condition2.indicator ? props.rule.condition2.indicator : priceIndicators[0]}
                      setSelected={(indicator) => {
                        let ruleTmp = { ...props.rule };
                        let ma = ["SMA", "EMA"];
                        if (indicator === "Bollinger Bands") {
                          ruleTmp.condition2.period = 20;
                          ruleTmp.condition2.period2 = 2;
                        }
                        if (indicator === "Keltner Channel") {
                          ruleTmp.condition2.period = 20;
                          ruleTmp.condition2.period2 = 1;
                        } else if (ma.includes(indicator) && !ma.includes(props.rule.condition2.indicator)) {
                          ruleTmp.condition2.period = 20;
                          delete ruleTmp.condition2.period2;
                          delete ruleTmp.condition2.band;
                        }
                        ruleTmp.condition2.indicator = indicator;

                        props.saveRule(ruleTmp);
                      }}
                      items={priceIndicators}
                    />
                    {props.rule.condition2.indicator && (
                      <>
                        <a
                          className={`px-1 ml-1 d-inline-block border-bottom border-light font-italic ${
                            indicatorSetup === "" ? "text-danger" : "text-light-green"
                          }`}
                          data-toggle="modal"
                          data-target={"#" + props.rule.id}
                          href="#/"
                          onClick={(e) => {
                            e.preventDefault();
                            document.activeElement.blur();
                            props.setShowOverlay(true);
                          }}
                        >
                          {indicatorSetup} <FontAwesomeIcon icon={faCog} />
                        </a>{" "}
                        {(props.rule.condition2.indicator === "Bollinger Bands" ||
                          props.rule.condition2.indicator === "Keltner Channel") && (
                          <>
                            <Dropdown
                              btnClasses="border-bottom border-light px-2"
                              placeholder="select"
                              selectedClasses="text-light-green hover-light-green"
                              menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                              menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                              selected={props.rule.condition2.band ? props.rule.condition2.band : bbDirections[0]}
                              setSelected={(band) => {
                                let ruleTmp = { ...props.rule };
                                ruleTmp.condition2.band = band;
                                props.saveRule(ruleTmp);
                              }}
                              items={bbDirections}
                            />
                          </>
                        )}
                        {props.rule.condition2.direction === "crossing" && (
                          <>
                            {" "}
                            from{" "}
                            <Dropdown
                              btnClasses="border-bottom border-light px-2"
                              placeholder="select"
                              selectedClasses="text-light-green hover-light-green"
                              menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                              menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                              selected={
                                props.rule.condition2.crossDirection
                                  ? props.rule.condition2.crossDirection
                                  : crossDirections[0]
                              }
                              setSelected={(direction) => {
                                let ruleTmp = { ...props.rule };
                                ruleTmp.condition2.crossDirection = direction;
                                props.saveRule(ruleTmp);
                              }}
                              items={crossDirections}
                            />
                          </>
                        )}
                      </>
                    )}
                    {(props.rule.condition2.indicator === "SMA" || props.rule.condition2.indicator === "EMA") && (
                      <RuleIndicatorSettingPopup
                        setShowOverlay={props.setShowOverlay}
                        indicatorSettingsId={props.rule.id}
                        rule={{ indicator: props.rule.condition2.indicator, period: props.rule.condition2.period }}
                        saveRule={(rule) => {
                          let ruleTmp = { ...props.rule };
                          ruleTmp.condition2.period = rule.period;
                          props.saveRule(ruleTmp);
                        }}
                      />
                    )}
                    {(props.rule.condition2.indicator === "Bollinger Bands" ||
                      props.rule.condition2.indicator === "Keltner Channel") && (
                      <RuleIndicatorSettingPopup
                        setShowOverlay={props.setShowOverlay}
                        indicatorSettingsId={props.rule.id}
                        rule={{
                          indicator: props.rule.condition2.indicator,
                          period: props.rule.condition2.period,
                          period2: props.rule.condition2.period2,
                        }}
                        saveRule={(rule) => {
                          let ruleTmp = { ...props.rule };
                          ruleTmp.condition2.period = rule.period;
                          ruleTmp.condition2.period2 = rule.period2;
                          props.saveRule(ruleTmp);
                        }}
                      />
                    )}
                  </div>
                </li>
              );
            case "condition3":
              return (
                <li key="condition3" className={`p-1 rounded ${props.rule.condition3.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition3" />
                  <div className="col-rule text-justify text-wrap">
                    is{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={
                        props.rule.condition3.direction ? props.rule.condition3.direction : aboveBelowDirections[0]
                      }
                      setSelected={(direction) => {
                        let ruleTmp = { ...props.rule };
                        ruleTmp.condition3.direction = direction;
                        props.saveRule(ruleTmp);
                      }}
                      items={aboveBelowDirections}
                    />{" "}
                    it's
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={
                        props.rule.condition3.direction2 ? props.rule.condition3.direction2 : highestLowestDirections[0]
                      }
                      setSelected={(direction) => {
                        let ruleTmp = { ...props.rule };
                        ruleTmp.condition3.direction2 = direction;
                        props.saveRule(ruleTmp);
                      }}
                      items={highestLowestDirections}
                    />{" "}
                    point, observed in the past
                    <RuleValue
                      classes="mr-1"
                      rule={props.rule}
                      condition="condition3"
                      saveRule={props.saveRule}
                      inputIsValidFunc={(value) => value >= 0 && value <= 100}
                      invalidMsg="Must be between 0 and 100"
                      isPositive={true}
                      isInt={true}
                    />{" "}
                    candles.
                    {props.rule.timeframe !== timeframes[0] && props.rule.condition3.value > 0 && (
                      <span className="font-italic text-secondary">
                        {" "}
                        Past {getHoursFromTimeframes(props.rule.timeframe, props.rule.condition3.value)}
                      </span>
                    )}
                  </div>
                </li>
              );
            case "condition4":
              return (
                <li key="condition4" className={`p-1 rounded ${props.rule.condition4.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition4" />
                  <div className="col-rule text-justify text-wrap">
                    is between{" "}
                    <RuleValue
                      classes="mr-1"
                      rule={props.rule}
                      condition="condition4"
                      saveRule={props.saveRule}
                      inputIsValidFunc={(value) => value > 0}
                      invalidMsg="Must be greater than 0"
                      isPositive={true}
                      skipWidthChange
                    />{" "}
                    and{" "}
                    <RuleValue
                      classes="mr-1"
                      rule={props.rule}
                      valueKey="value2"
                      condition="condition4"
                      saveRule={props.saveRule}
                      inputIsValidFunc={(value) => value > 0}
                      invalidMsg="Must be greater than 0"
                      isPositive={true}
                      skipWidthChange
                    />
                  </div>
                </li>
              );
              case "condition5":
              return (
                <li key="condition5" className={`p-1 rounded ${props.rule.condition5.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition5" />
                  <div className="col-rule text-justify text-wrap">
                    is{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={props.rule.condition5.direction ? props.rule.condition5.direction : directions[0]}
                      setSelected={(direction) => {
                        let ruleTmp = { ...props.rule };
                        if (direction !== "crossing") {
                          delete ruleTmp.condition5.crossDirection;
                        }
                        ruleTmp.condition5.direction = direction;
                        props.saveRule(ruleTmp);
                      }}
                      items={directions}
                    />{" "}price level{" "}
                     <RuleValue
                      classes="mr-1"
                      rule={props.rule}
                      condition="condition5"
                      saveRule={props.saveRule}
                      inputIsValidFunc={(value) => value > 0}
                      invalidMsg="Must be greater than 0"
                      isPositive={true}
                      skipWidthChange
                    />
                     {props.rule.condition5.direction === "crossing" && (
                          <>
                            {" "}
                            from{" "}
                            <Dropdown
                              btnClasses="border-bottom border-light px-2"
                              placeholder="select"
                              selectedClasses="text-light-green hover-light-green"
                              menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                              menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                              selected={
                                props.rule.condition5.crossDirection
                                  ? props.rule.condition5.crossDirection
                                  : crossDirections[0]
                              }
                              setSelected={(direction) => {
                                let ruleTmp = { ...props.rule };
                                ruleTmp.condition5.crossDirection = direction;
                                props.saveRule(ruleTmp);
                              }}
                              items={crossDirections}
                            />
                          </>
                        )}
                  </div>
                </li>
              );
            default:
              return "";
          }
        })}
      </ul>

      <ul className="list-unstyled text-left mb-0">
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition1"
          content={
            <>
              is <span className="text-lighter-green">up</span> / <span className="text-lighter-green">down</span>{" "}
              <span className="text-lighter-green">N</span> percents
            </>
          }
        />

        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition2"
          content={
            <>
              is <span className="text-lighter-green">above</span> / <span className="text-lighter-green">below</span> /{" "}
              <span className="text-lighter-green">crossing</span> an indicator (
              <span className="text-lighter-green">SMA</span> / <span className="text-lighter-green">EMA</span> /{" "}
              <span className="text-lighter-green">Bollinger Bands</span> /{" "}
              <span className="text-lighter-green">Keltner Channel</span>)
            </>
          }
        />

        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition3"
          content={
            <>
              is <span className="text-lighter-green">above</span> / <span className="text-lighter-green">below</span>{" "}
              it's <span className="text-lighter-green">highest</span> /{" "}
              <span className="text-lighter-green">lowest</span> point, observed in the past{" "}
              <span className="text-lighter-green">N</span> candles
            </>
          }
        />

        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition4"
          content={
            <>
              is between <span className="text-lighter-green">two numbers</span>
            </>
          }
        />

        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition5"
          content={
            <>
              is <span className="text-lighter-green">above</span> / <span className="text-lighter-green">below</span> /{" "}
              <span className="text-lighter-green">crossing</span> a level
            </>
          }
        />
      </ul>
    </>
  );
};

const CandleMove = (props) => {
  return (
    props.rule[props.condition] && (
      <li className={`p-1 rounded ${props.rule[props.condition].saved ? "" : "bg-change"}`}>
        <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition={props.condition} />
        <div className="col-rule text-justify text-wrap">
          {props.text}{" "}
          <Dropdown
            btnClasses="border-bottom border-light px-2"
            placeholder="select"
            selectedClasses="text-light-green hover-light-green"
            menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
            menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
            selected={
              props.rule[props.condition].direction ? props.rule[props.condition].direction : greaterLessDirections[0]
            }
            setSelected={(direction) => {
              let ruleTmp = { ...props.rule };
              ruleTmp[props.condition].direction = direction;
              props.saveRule(ruleTmp);
            }}
            items={greaterLessDirections}
          />{" "}
          <RuleValue
            rule={props.rule}
            condition={props.condition}
            saveRule={props.saveRule}
            inputIsValidFunc={(value) => value >= props.minValue && value <= 100}
            invalidMsg={`Must be between ${props.minValue} and 100`}
            isPositive={props.minValue >= 0}
            isPercent={true}
          />
          %
          {props.rule[props.condition].direction === "between" && (
            <>
              {" "}
              and{" "}
              <RuleValue
                rule={props.rule}
                valueKey="value2"
                condition={props.condition}
                pre="and"
                saveRule={props.saveRule}
                inputIsValidFunc={(value) => value >= props.minValue && value <= 100}
                invalidMsg={`Must be between ${props.minValue} and 100`}
                isPositive={props.minValue >= 0}
                isPercent={true}
              />
              %
            </>
          )}
        </div>
      </li>
    )
  );
};

const CandleStick = (props) => {
  return (
    <>
      <ul className="list-unstyled text-left mb-0">
        {props.rule.conditionsOrder.map((condition) => {
          switch (condition) {
            case "condition1":
              return (
                <li key="condition1" className={`p-1 rounded ${props.rule.condition1.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition1" />
                  <div className="col-rule text-justify text-wrap">
                    pattern is{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={props.rule.condition1.pattern ? props.rule.condition1.pattern : candlePatterns[0]}
                      setSelected={(pattern) => {
                        let ruleTmp = { ...props.rule };
                        ruleTmp.condition1.pattern = pattern;
                        props.saveRule(ruleTmp);
                      }}
                      items={candlePatterns}
                    />
                  </div>
                </li>
              );
            case "condition2":
              return (
                <CandleMove
                  key="condition2"
                  rule={props.rule}
                  saveRule={props.saveRule}
                  condition="condition2"
                  strategy={props.strategy}
                  text={(props.rule.mode === "close" ? "open to close " : "open to current price ") + " move is "}
                  minValue={-100}
                />
              );
            case "condition3":
              return (
                <CandleMove
                  key="condition3"
                  rule={props.rule}
                  saveRule={props.saveRule}
                  condition="condition3"
                  strategy={props.strategy}
                  text="low to high move is "
                  minValue={0}
                />
              );
            case "condition4":
              return (
                <CandleMove
                  key="condition4"
                  rule={props.rule}
                  saveRule={props.saveRule}
                  condition="condition4"
                  strategy={props.strategy}
                  text="upper shadow is "
                  minValue={0}
                />
              );
            case "condition5":
              return (
                <CandleMove
                  key="condition5"
                  rule={props.rule}
                  saveRule={props.saveRule}
                  condition="condition5"
                  strategy={props.strategy}
                  text="lower shadow is "
                  minValue={0}
                />
              );
            default:
              return "";
          }
        })}
      </ul>

      <ul className="list-unstyled text-left mb-0">
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition1"
          content={
            <>
              pattern is <span className="text-lighter-green">doji</span> /{" "}
              <span className="text-lighter-green">hammer</span> / <span className="text-lighter-green">engulfing</span>{" "}
              / <span className="text-lighter-green">harami</span> / <span className="text-lighter-green">stars</span>{" "}
              and more...
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition2"
          content={
            <>
              {props.rule.mode === "close" ? "open to close" : "open to current price"} move is{" "}
              <span className="text-lighter-green">greater</span> / <span className="text-lighter-green">less</span> /{" "}
              <span className="text-lighter-green">between</span>
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition3"
          content={
            <>
              low to high move is <span className="text-lighter-green">greater</span> /{" "}
              <span className="text-lighter-green">less</span> / <span className="text-lighter-green">between</span>
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition4"
          content={
            <>
              upper shadow is <span className="text-lighter-green">greater</span> /{" "}
              <span className="text-lighter-green">less</span> / <span className="text-lighter-green">between</span>
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition5"
          content={
            <>
              lower shadow is <span className="text-lighter-green">greater</span> /{" "}
              <span className="text-lighter-green">less</span> / <span className="text-lighter-green">between</span>
            </>
          }
        />
      </ul>
    </>
  );
};

const MovingAverage = (props) => {
  return (
    <>
      <RuleIndicatorSettingPopup
        setShowOverlay={props.setShowOverlay}
        indicatorSettingsId={props.rule.id}
        rule={props.rule}
        saveRule={props.saveRule}
      />

      <ul className="list-unstyled text-left mb-0">
        {props.rule.conditionsOrder.map((condition) => {
          switch (condition) {
            case "condition1":
              return (
                <li key="condition1" className={`p-1 rounded ${props.rule.condition1.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition1" />
                  <div className="col-rule text-justify text-wrap">
                    is crossing{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={props.rule.condition1.indicator ? props.rule.condition1.indicator : maIndicators[0]}
                      setSelected={(indicator) => {
                        if (indicator !== props.rule.condition1.indicator) {
                          let ruleTmp = { ...props.rule };
                          ruleTmp.condition1.indicator = indicator;
                          if (!ruleTmp.condition1.period) {
                            ruleTmp.condition1.period = 20;
                          }
                          props.saveRule(ruleTmp);
                        }
                      }}
                      items={maIndicators}
                    />
                    {props.rule.condition1.indicator && (
                      <>
                        <a
                          className="px-1 mx-1 d-inline-block border-bottom border-light text-light-green font-italic"
                          data-toggle="modal"
                          data-target={`#${props.rule.id}-2`}
                          href="#/"
                          onClick={(e) => {
                            e.preventDefault();
                            document.activeElement.blur();
                            props.setShowOverlay(true);
                          }}
                        >
                          ({props.rule.condition1.period}) <FontAwesomeIcon icon={faCog} />
                        </a>{" "}
                        from{" "}
                        <Dropdown
                          btnClasses="border-bottom border-light px-2"
                          placeholder="select"
                          selectedClasses="text-light-green hover-light-green"
                          menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                          menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                          selected={
                            props.rule.condition1.crossDirection
                              ? props.rule.condition1.crossDirection
                              : crossDirections[0]
                          }
                          setSelected={(crossDirection) => {
                            let ruleTmp = { ...props.rule };
                            ruleTmp.condition1.crossDirection = crossDirection;
                            props.saveRule(ruleTmp);
                          }}
                          items={crossDirections}
                        />
                      </>
                    )}
                    <RuleIndicatorSettingPopup
                      setShowOverlay={props.setShowOverlay}
                      indicatorSettingsId={`${props.rule.id}-2`}
                      rule={{ indicator: props.rule.condition1.indicator, period: props.rule.condition1.period }}
                      saveRule={(rule) => {
                        let ruleTmp = { ...props.rule };
                        ruleTmp.condition1.period = rule.period;
                        props.saveRule(ruleTmp);
                      }}
                    />
                  </div>
                </li>
              );

            case "condition2":
              return (
                <li key="condition2" className={`p-1 rounded ${props.rule.condition2.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition2" />
                  <div className="col-rule text-justify text-wrap">
                    is in{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={props.rule.condition2.direction ? props.rule.condition2.direction : upDownTrend[0]}
                      setSelected={(direction) => {
                        let ruleTmp = { ...props.rule };
                        ruleTmp.condition2.direction = direction;
                        props.saveRule(ruleTmp);
                      }}
                      items={upDownTrend}
                    />
                    {props.rule.condition2.direction && (
                      <>
                        . The {props.rule.indicator} has{" "}
                        {props.rule.condition2.direction === "uptrend" ? "gained" : "lost"} at least{" "}
                        <RuleValue
                          rule={props.rule}
                          condition="condition2"
                          saveRule={props.saveRule}
                          defaultValue={ruleDefaults.maValue}
                          inputIsValidFunc={(value) => value > 0 && value <= 100}
                          invalidMsg={`Must be between 0 and 100`}
                          isPositive={true}
                        />
                        % in the {props.rule.condition2.direction}
                      </>
                    )}
                  </div>
                </li>
              );

            case "condition3":
              return (
                <li key="condition3" className={`p-1 rounded ${props.rule.condition3.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition3" />
                  <div className="col-rule text-justify text-wrap">
                    is reversing its{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={props.rule.condition3.direction ? props.rule.condition3.direction : upDownTrend[0]}
                      setSelected={(direction) => {
                        let ruleTmp = { ...props.rule };
                        ruleTmp.condition3.direction = direction;
                        props.saveRule(ruleTmp);
                      }}
                      items={upDownTrend}
                    />
                    {props.rule.condition3.direction && (
                      <>
                        . The {props.rule.indicator} {props.rule.condition3.direction === "uptrend" ? "gained" : "lost"}{" "}
                        at least{" "}
                        <RuleValue
                          rule={props.rule}
                          condition="condition3"
                          saveRule={props.saveRule}
                          defaultValue={ruleDefaults.maValue}
                          inputIsValidFunc={(value) => value > 0 && value <= 100}
                          invalidMsg={`Must be between 0 and 100`}
                          isPositive={true}
                        />
                        % in the {props.rule.condition3.direction}. The {props.rule.indicator} is{" "}
                        {props.rule.condition3.direction === "uptrend" ? "losing" : "gaining"} at least{" "}
                        <RuleValue
                          rule={props.rule}
                          valueKey="value2"
                          condition="condition3"
                          saveRule={props.saveRule}
                          defaultValue={ruleDefaults.maValue2}
                          inputIsValidFunc={(value) => value > 0 && value <= 100}
                          invalidMsg={`Must be between 0 and 100`}
                          isPositive={true}
                        />
                        % in the new {props.rule.condition3.direction === "uptrend" ? "downtrend" : "uptrend"}
                      </>
                    )}
                  </div>
                </li>
              );
            case "condition4":
              return (
                <li key="condition4" className={`p-1 rounded ${props.rule.condition4.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition4" />
                  <div className="col-rule text-justify text-wrap">
                    has at least{" "}
                    <RuleValue
                      rule={props.rule}
                      condition="condition4"
                      saveRule={props.saveRule}
                      inputIsValidFunc={(value) => value > 1 && value <= 100}
                      invalidMsg={`Must be between 2 and 100`}
                      isPositive={true}
                      isInt={true}
                    />{" "}
                    consecutive
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={props.rule.condition4.direction ? props.rule.condition4.direction : risingFalling[0]}
                      setSelected={(direction) => {
                        let ruleTmp = { ...props.rule };
                        ruleTmp.condition4.direction = direction;
                        props.saveRule(ruleTmp);
                      }}
                      items={risingFalling}
                    />{" "}
                    values
                  </div>
                </li>
              );

            default:
              return "";
          }
        })}
      </ul>

      <ul className="list-unstyled text-left mb-0">
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition1"
          content={
            <>
              is crossing <span className="text-lighter-green">SMA</span> /{" "}
              <span className="text-lighter-green">EMA</span>
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition2"
          content={
            <>
              is in <span className="text-lighter-green">uptrend</span> /{" "}
              <span className="text-lighter-green">downtrend</span>
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition3"
          content={
            <>
              is reversing its <span className="text-lighter-green">trend</span>
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition4"
          content={
            <>
              has a <span className="text-lighter-green">number</span> of consecutive{" "}
              <span className="text-lighter-green">rising</span> / <span className="text-lighter-green">falling</span>{" "}
              values
            </>
          }
        />
      </ul>
    </>
  );
};

const RSI = (props) => {
  return (
    <>
      <RuleIndicatorSettingPopup
        setShowOverlay={props.setShowOverlay}
        indicatorSettingsId={props.rule.id}
        rule={props.rule}
        saveRule={props.saveRule}
      />

      <ul className="list-unstyled text-left mb-0">
        {props.rule.conditionsOrder.map((condition, index) => {
          switch (condition) {
            case "condition1":
              return (
                <li key="condition1" className={`p-1 rounded ${props.rule.condition1.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition1" />
                  <div className="col-rule text-justify text-wrap">
                    is{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={props.rule.condition1.direction ? props.rule.condition1.direction : directions[0]}
                      setSelected={(direction) => {
                        let ruleTmp = { ...props.rule };
                        ruleTmp.condition1.direction = direction;
                        if (direction !== "crossing") {
                          delete ruleTmp.condition1.crossDirection;
                        }
                        props.saveRule(ruleTmp);
                      }}
                      items={directions}
                    />{" "}
                    <RuleValue
                      condition="condition1"
                      rule={props.rule}
                      saveRule={props.saveRule}
                      inputIsValidFunc={(value) => value >= 0 && value <= 100}
                      invalidMsg="Must be between 0 and 100"
                      isInt={true}
                      isPositive={true}
                    />
                    {props.rule.condition1.direction === "crossing" && (
                      <>
                        {" "}
                        from{" "}
                        <Dropdown
                          btnClasses="border-bottom border-light px-2"
                          placeholder="select"
                          selectedClasses="text-light-green hover-light-green"
                          menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                          menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                          selected={
                            props.rule.condition1.crossDirection
                              ? props.rule.condition1.crossDirection
                              : crossDirections[0]
                          }
                          setSelected={(crossDirection) => {
                            let ruleTmp = { ...props.rule };
                            ruleTmp.condition1.crossDirection = crossDirection;
                            props.saveRule(ruleTmp);
                          }}
                          items={crossDirections}
                        />
                      </>
                    )}
                  </div>
                </li>
              );

            case "condition2":
              return (
                <li key="condition2" className={`p-1 rounded ${props.rule.condition2.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition2" />
                  <div className="col-rule text-justify text-wrap">
                    is reversing its{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={props.rule.condition2.direction ? props.rule.condition2.direction : upDownTrend[0]}
                      setSelected={(direction) => {
                        let ruleTmp = { ...props.rule };
                        ruleTmp.condition2.direction = direction;
                        props.saveRule(ruleTmp);
                      }}
                      items={upDownTrend}
                    />
                    {props.rule.condition2.direction && (
                      <>
                        . The {props.rule.indicator} {props.rule.condition2.direction === "uptrend" ? "gained" : "lost"}{" "}
                        at least{" "}
                        <RuleValue
                          rule={props.rule}
                          condition="condition2"
                          saveRule={props.saveRule}
                          defaultValue={ruleDefaults.rsiValue}
                          inputIsValidFunc={(value) => value > 0 && value <= 100}
                          invalidMsg={`Must be between 0 and 100`}
                          isPositive={true}
                        />{" "}
                        points in the {props.rule.condition2.direction}. The {props.rule.indicator} is{" "}
                        {props.rule.condition2.direction === "uptrend" ? "losing" : "gaining"} at least{" "}
                        <RuleValue
                          rule={props.rule}
                          valueKey="value2"
                          condition="condition2"
                          saveRule={props.saveRule}
                          defaultValue={ruleDefaults.rsiValue2}
                          inputIsValidFunc={(value) => value > 0 && value <= 100}
                          invalidMsg={`Must be between 0 and 100`}
                          isPositive={true}
                        />{" "}
                        points in the new {props.rule.condition2.direction === "uptrend" ? "downtrend" : "uptrend"}
                      </>
                    )}
                  </div>
                </li>
              );

            default:
              return "";
          }
        })}
      </ul>

      <ul className="list-unstyled text-left mb-0">
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition1"
          content={
            <>
              is <span className="text-lighter-green">above</span> / <span className="text-lighter-green">below</span> /{" "}
              <span className="text-lighter-green">crossing a number</span>
            </>
          }
        />

        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition2"
          content={
            <>
              is reversing its <span className="text-lighter-green">direction</span>
            </>
          }
        />
      </ul>
    </>
  );
};

const MACD = (props) => {
  return (
    <>
      <RuleIndicatorSettingPopup
        setShowOverlay={props.setShowOverlay}
        indicatorSettingsId={props.rule.id}
        rule={props.rule}
        saveRule={props.saveRule}
      />

      <ul className="list-unstyled text-left mb-0">
        {props.rule.conditionsOrder.map((condition) => {
          switch (condition) {
            case "condition1":
              return (
                <li key="condition1" className={`p-1 rounded ${props.rule.condition1.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition1" />
                  <div className="col-rule text-justify text-wrap">
                    is{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={props.rule.condition1.direction ? props.rule.condition1.direction : directions[0]}
                      setSelected={(direction) => {
                        let ruleTmp = { ...props.rule };
                        ruleTmp.condition1.direction = direction;
                        if (direction !== "crossing") {
                          delete ruleTmp.condition1.crossDirection;
                        }
                        props.saveRule(ruleTmp);
                      }}
                      items={directions}
                    />{" "}
                    the signal line
                    {props.rule.condition1.direction === "crossing" && (
                      <>
                        {" "}
                        from{" "}
                        <Dropdown
                          btnClasses="border-bottom border-light px-2"
                          placeholder="select"
                          selectedClasses="text-light-green hover-light-green"
                          menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                          menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                          selected={
                            props.rule.condition1.crossDirection
                              ? props.rule.condition1.crossDirection
                              : crossDirections[0]
                          }
                          setSelected={(crossDirection) => {
                            let ruleTmp = { ...props.rule };
                            ruleTmp.condition1.crossDirection = crossDirection;
                            props.saveRule(ruleTmp);
                          }}
                          items={crossDirections}
                        />
                      </>
                    )}
                  </div>
                </li>
              );
            case "condition2":
              return (
                <li key="condition2" className={`p-1 rounded ${props.rule.condition2.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition2" />
                  <div className="col-rule text-justify text-wrap">
                    is{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={props.rule.condition2.direction ? props.rule.condition2.direction : directions[0]}
                      setSelected={(direction) => {
                        let ruleTmp = { ...props.rule };
                        ruleTmp.condition2.direction = direction;
                        if (direction !== "crossing") {
                          delete ruleTmp.condition2.crossDirection;
                        }
                        props.saveRule(ruleTmp);
                      }}
                      items={directions}
                    />{" "}
                    zero
                    {props.rule.condition2.direction === "crossing" && (
                      <>
                        {" "}
                        from{" "}
                        <Dropdown
                          btnClasses="border-bottom border-light px-2"
                          placeholder="select"
                          selectedClasses="text-light-green hover-light-green"
                          menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                          menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                          selected={
                            props.rule.condition2.crossDirection
                              ? props.rule.condition2.crossDirection
                              : crossDirections[0]
                          }
                          setSelected={(crossDirection) => {
                            let ruleTmp = { ...props.rule };
                            ruleTmp.condition2.crossDirection = crossDirection;
                            props.saveRule(ruleTmp);
                          }}
                          items={crossDirections}
                        />
                      </>
                    )}
                  </div>
                </li>
              );
            case "condition3":
              return (
                <li key="condition3" className={`p-1 rounded ${props.rule.condition3.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition3" />
                  <div className="col-rule text-justify text-wrap">
                    histogram is{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={
                        props.rule.condition3.direction ? props.rule.condition3.direction : histogramDirections[0]
                      }
                      setSelected={(direction) => {
                        let ruleTmp = { ...props.rule };
                        ruleTmp.condition3.direction = direction;
                        props.saveRule(ruleTmp);
                      }}
                      items={histogramDirections}
                    />
                  </div>
                </li>
              );
            case "condition4":
              return (
                <li key="condition4" className={`p-1 rounded ${props.rule.condition4.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition4" />
                  <div className="col-rule text-justify text-wrap">
                    histogram makes a{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={
                        props.rule.condition4.direction ? props.rule.condition4.direction : histogramDirections2[0]
                      }
                      setSelected={(direction) => {
                        let ruleTmp = { ...props.rule };
                        ruleTmp.condition4.direction = direction;
                        props.saveRule(ruleTmp);
                      }}
                      items={histogramDirections2}
                    />
                  </div>
                </li>
              );
            default:
              return "";
          }
        })}
      </ul>

      <ul className="list-unstyled text-left mb-0">
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition1"
          content={
            <>
              is <span className="text-lighter-green">above</span> / <span className="text-lighter-green">below</span> /{" "}
              <span className="text-lighter-green">crossing</span> the signal line
            </>
          }
        />

        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition2"
          content={
            <>
              is <span className="text-lighter-green">above</span> / <span className="text-lighter-green">below</span> /{" "}
              <span className="text-lighter-green">crossing</span> zero
            </>
          }
        />

        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition3"
          content={
            <>
              histogram is <span className="text-lighter-green">positive</span> /{" "}
              <span className="text-lighter-green">negative</span>
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition4"
          content={
            <>
              histogram makes a <span className="text-lighter-green">peak</span> /{" "}
              <span className="text-lighter-green">trough</span>
            </>
          }
        />
      </ul>
    </>
  );
};

const BandsCondition = (props) => {
  return props.rule[props.condition] ? (
    <li className={`p-1 rounded ${props.rule[props.condition].saved ? "" : "bg-change"}`}>
      <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition={props.condition} />
      <div className="col-rule text-justify text-wrap">
        {props.text}{" "}
        <RuleValue
          rule={props.rule}
          condition={props.condition}
          defaultValue={props.defValue}
          saveRule={props.saveRule}
          inputIsValidFunc={(value) => {
            return value > 0 && value <= 100;
          }}
          invalidMsg="Must be between 0 and 100"
          isPositive={true}
        />
        %
      </div>
    </li>
  ) : (
    ""
  );
};

const BollingerBands = (props) => {
  return (
    <>
      <RuleIndicatorSettingPopup
        setShowOverlay={props.setShowOverlay}
        indicatorSettingsId={props.rule.id}
        rule={props.rule}
        saveRule={props.saveRule}
      />

      <ul className="list-unstyled text-left mb-0">
        {props.rule.conditionsOrder.map((condition) => {
          switch (condition) {
            case "condition1":
              return (
                <BandsCondition
                  key="condition1"
                  defValue={ruleDefaults.bbValue}
                  rule={props.rule}
                  saveRule={props.saveRule}
                  condition="condition1"
                  text="bands enter in a squeeze. Bands width is going below"
                />
              );
            case "condition2":
              return (
                <BandsCondition
                  key="condition2"
                  defValue={ruleDefaults.bbValue}
                  rule={props.rule}
                  saveRule={props.saveRule}
                  condition="condition2"
                  text="bands are in a squeeze. Bands width is below"
                />
              );
            case "condition3":
              return (
                <BandsCondition
                  key="condition3"
                  defValue={ruleDefaults.bbValue}
                  rule={props.rule}
                  saveRule={props.saveRule}
                  condition="condition3"
                  text="bands break from a squeeze. Bands width is going above"
                />
              );
            case "condition4":
              return (
                <BandsCondition
                  key="condition4"
                  defValue={ruleDefaults.bbValue}
                  rule={props.rule}
                  saveRule={props.saveRule}
                  condition="condition4"
                  text="bands are not in a squeeze. Bands width is above"
                />
              );
            default:
              return "";
          }
        })}
      </ul>

      <ul className="list-unstyled text-left mb-0">
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition1"
          excludingConditions={["condition2", "condition3", "condition4"]}
          content={
            <>
              bands <span className="text-lighter-green">enter in</span> a squeeze
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition2"
          excludingConditions={["condition1", "condition3", "condition4"]}
          content={
            <>
              bands <span className="text-lighter-green">are in</span> a squeeze
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition3"
          excludingConditions={["condition1", "condition2", "condition4"]}
          content={
            <>
              bands <span className="text-lighter-green">break from</span> a squeeze
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition4"
          excludingConditions={["condition1", "condition2", "condition3"]}
          content={
            <>
              bands <span className="text-lighter-green">are not in</span> a squeeze
            </>
          }
        />
      </ul>
    </>
  );
};
const KeltnerChannel = (props) => {
  return (
    <>
      <RuleIndicatorSettingPopup
        setShowOverlay={props.setShowOverlay}
        indicatorSettingsId={props.rule.id}
        rule={props.rule}
        saveRule={props.saveRule}
      />

      <ul className="list-unstyled text-left mb-0">
        {props.rule.conditionsOrder.map((condition) => {
          switch (condition) {
            case "condition1":
              return (
                <BandsCondition
                  key="condition1"
                  defValue={ruleDefaults.kcValue}
                  rule={props.rule}
                  saveRule={props.saveRule}
                  condition="condition1"
                  text="bands enter in a squeeze. Bands width is going below"
                />
              );
            case "condition2":
              return (
                <BandsCondition
                  key="condition2"
                  defValue={ruleDefaults.kcValue}
                  rule={props.rule}
                  saveRule={props.saveRule}
                  condition="condition2"
                  text="bands are in a squeeze. Bands width is below"
                />
              );
            case "condition3":
              return (
                <BandsCondition
                  key="condition3"
                  defValue={ruleDefaults.kcValue}
                  rule={props.rule}
                  saveRule={props.saveRule}
                  condition="condition3"
                  text="bands break from a squeeze. Bands width is going above"
                />
              );
            case "condition4":
              return (
                <BandsCondition
                  key="condition4"
                  defValue={ruleDefaults.kcValue}
                  rule={props.rule}
                  saveRule={props.saveRule}
                  condition="condition4"
                  text="bands are not in a squeeze. Bands width is above"
                />
              );
            default:
              return "";
          }
        })}
      </ul>

      <ul className="list-unstyled text-left mb-0">
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition1"
          excludingConditions={["condition2", "condition3", "condition4"]}
          content={
            <>
              bands <span className="text-lighter-green">enter in</span> a squeeze
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition2"
          excludingConditions={["condition1", "condition3", "condition4"]}
          content={
            <>
              bands <span className="text-lighter-green">are in</span> a squeeze
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition3"
          excludingConditions={["condition1", "condition2", "condition4"]}
          content={
            <>
              bands <span className="text-lighter-green">break from</span> a squeeze
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition4"
          excludingConditions={["condition1", "condition2", "condition3"]}
          content={
            <>
              bands <span className="text-lighter-green">are not in</span> a squeeze
            </>
          }
        />
      </ul>
    </>
  );
};

const Stochastic = (props) => {
  return (
    <>
      <RuleIndicatorSettingPopup
        setShowOverlay={props.setShowOverlay}
        indicatorSettingsId={props.rule.id}
        rule={props.rule}
        saveRule={props.saveRule}
      />

      <ul className="list-unstyled text-left mb-0">
        {props.rule.conditionsOrder.map((condition) => {
          switch (condition) {
            case "condition1":
              return (
                <li key="condition1" className={`p-1 rounded ${props.rule.condition1.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition1" />
                  <div className="col-rule text-justify text-wrap">
                    K% line is{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={props.rule.condition1.direction ? props.rule.condition1.direction : directions[0]}
                      setSelected={(direction) => {
                        let ruleTmp = { ...props.rule };
                        ruleTmp.condition1.direction = direction;
                        if (direction !== "crossing") {
                          delete ruleTmp.condition1.crossDirection;
                        }
                        props.saveRule(ruleTmp);
                      }}
                      items={directions}
                    />{" "}
                    the D% line
                    {props.rule.condition1.direction === "crossing" && (
                      <>
                        {" "}
                        from{" "}
                        <Dropdown
                          btnClasses="border-bottom border-light px-2"
                          placeholder="select"
                          selectedClasses="text-light-green hover-light-green"
                          menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                          menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                          selected={
                            props.rule.condition1.crossDirection
                              ? props.rule.condition1.crossDirection
                              : crossDirections[0]
                          }
                          setSelected={(crossDirection) => {
                            let ruleTmp = { ...props.rule };
                            ruleTmp.condition1.crossDirection = crossDirection;
                            props.saveRule(ruleTmp);
                          }}
                          items={crossDirections}
                        />
                      </>
                    )}
                  </div>
                </li>
              );
            case "condition2":
              return (
                <li key="condition2" className={`p-1 rounded ${props.rule.condition2.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition2" />
                  <div className="col-rule text-justify text-wrap">
                    K% line is{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={props.rule.condition2.direction ? props.rule.condition2.direction : directions[0]}
                      setSelected={(direction) => {
                        let ruleTmp = { ...props.rule };
                        ruleTmp.condition2.direction = direction;
                        if (direction !== "crossing") {
                          delete ruleTmp.condition2.crossDirection;
                        }
                        props.saveRule(ruleTmp);
                      }}
                      items={directions}
                    />{" "}
                    <RuleValue
                      condition="condition2"
                      rule={props.rule}
                      saveRule={props.saveRule}
                      inputIsValidFunc={(value) => value >= 0 && value <= 100}
                      invalidMsg="Must be between 0 and 100"
                      isInt={true}
                      isPositive={true}
                    />
                    {props.rule.condition2.direction === "crossing" && (
                      <>
                        {" "}
                        from{" "}
                        <Dropdown
                          btnClasses="border-bottom border-light px-2"
                          placeholder="select"
                          selectedClasses="text-light-green hover-light-green"
                          menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                          menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                          selected={
                            props.rule.condition2.crossDirection
                              ? props.rule.condition2.crossDirection
                              : crossDirections[0]
                          }
                          setSelected={(crossDirection) => {
                            let ruleTmp = { ...props.rule };
                            ruleTmp.condition2.crossDirection = crossDirection;
                            props.saveRule(ruleTmp);
                          }}
                          items={crossDirections}
                        />
                      </>
                    )}
                  </div>
                </li>
              );
            case "condition3":
              return (
                <li key="condition3" className={`p-1 rounded ${props.rule.condition3.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition3" />
                  <div className="col-rule text-justify text-wrap">
                    K% line is reversing its{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={props.rule.condition3.direction ? props.rule.condition3.direction : upDownTrend[0]}
                      setSelected={(direction) => {
                        let ruleTmp = { ...props.rule };
                        ruleTmp.condition3.direction = direction;
                        props.saveRule(ruleTmp);
                      }}
                      items={upDownTrend}
                    />
                    {props.rule.condition3.direction && (
                      <>
                        . The K% line {props.rule.condition3.direction === "uptrend" ? "gained" : "lost"} at least{" "}
                        <RuleValue
                          rule={props.rule}
                          condition="condition3"
                          saveRule={props.saveRule}
                          defaultValue={ruleDefaults.stoValue}
                          inputIsValidFunc={(value) => value > 0 && value <= 100}
                          invalidMsg={`Must be between 0 and 100`}
                          isPositive={true}
                        />{" "}
                        points in the {props.rule.condition3.direction}. The K% line is{" "}
                        {props.rule.condition3.direction === "uptrend" ? "losing" : "gaining"} at least{" "}
                        <RuleValue
                          rule={props.rule}
                          valueKey="value2"
                          condition="condition3"
                          saveRule={props.saveRule}
                          defaultValue={ruleDefaults.stoValue2}
                          inputIsValidFunc={(value) => value > 0 && value <= 100}
                          invalidMsg={`Must be between 0 and 100`}
                          isPositive={true}
                        />
                        points in the new {props.rule.condition3.direction === "uptrend" ? "downtrend" : "uptrend"}
                      </>
                    )}
                  </div>
                </li>
              );
            default:
              return "";
          }
        })}
      </ul>

      <ul className="list-unstyled text-left mb-0">
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition1"
          content={
            <>
              K% line is <span className="text-lighter-green">above</span> /{" "}
              <span className="text-lighter-green">below</span> / <span className="text-lighter-green">crossing</span>{" "}
              the D% line
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition2"
          content={
            <>
              K% line is <span className="text-lighter-green">above</span> /{" "}
              <span className="text-lighter-green">below</span> /{" "}
              <span className="text-lighter-green">crossing a number</span>
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition3"
          content={
            <>
              K% line is reversing its <span className="text-lighter-green">direction</span>
            </>
          }
        />
      </ul>
    </>
  );
};

const TextCondition = (props) => {
  return props.rule[props.condition] ? (
    <li className={`p-1 rounded ${props.rule[props.condition].saved ? "" : "bg-change"}`}>
      <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition={props.condition} />
      <div className="col-rule text-justify text-wrap">{props.text}</div>
    </li>
  ) : (
    ""
  );
};

const MomentumFormula = (props) => {
  return (
    <div className="">
      <div className="d-inline-block small w-165 ml-2">Momentum Formula:</div>
      <RadioButtons
        options={[
          {
            optionClasses: "text-dark mr-md-4 pr-md-2",
            checkedColor: "text-info",
            uncheckedColor: "text-light border rounded-circle border-info",
            checked: props.rule.formula === "standard",
            check: () => {
              let ruleTmp = { ...props.rule, formula: "standard" };
              props.saveRule(ruleTmp);
            },
            text: "Standard",
          },
          {
            optionClasses: "text-dark ml-md-2",
            checkedColor: "text-info",
            uncheckedColor: "text-light border rounded-circle border-info",
            checked: props.rule.formula === "smoothed",
            check: () => {
              let ruleTmp = { ...props.rule, formula: "smoothed" };
              props.saveRule(ruleTmp);
            },
            text: "Smoothed",
          },
        ]}
      />
    </div>
  );
};
const Momentum = (props) => {
  return (
    <>
      <RuleIndicatorSettingPopup
        setShowOverlay={props.setShowOverlay}
        indicatorSettingsId={props.rule.id}
        rule={props.rule}
        saveRule={props.saveRule}
      />
      <ul className="list-unstyled text-left mb-0">
        {props.rule.conditionsOrder.map((condition) => {
          switch (condition) {
            case "condition1":
              return (
                <li key="condition1" className={`p-1 rounded ${props.rule.condition1.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition1" />
                  <div className="col-rule text-justify text-wrap">
                    momentum is{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={
                        props.rule.condition1.direction ? props.rule.condition1.direction : histogramDirections[0]
                      }
                      setSelected={(direction) => {
                        let ruleTmp = { ...props.rule };
                        ruleTmp.condition1.direction = direction;
                        props.saveRule(ruleTmp);
                      }}
                      items={histogramDirections}
                    />
                  </div>
                </li>
              );
            case "condition2":
              return (
                <li key="condition2" className={`p-1 rounded ${props.rule.condition2.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition2" />
                  <div className="col-rule text-justify text-wrap">
                    histogram makes a{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={
                        props.rule.condition2.direction ? props.rule.condition2.direction : histogramDirections2[0]
                      }
                      setSelected={(direction) => {
                        let ruleTmp = { ...props.rule };
                        ruleTmp.condition2.direction = direction;
                        props.saveRule(ruleTmp);
                      }}
                      items={histogramDirections2}
                    />
                  </div>
                </li>
              );
            default:
              return "";
          }
        })}
      </ul>

      <ul className="list-unstyled text-left mb-0">
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition1"
          content={
            <>
              momentum is <span className="text-lighter-green">positive</span> /{" "}
              <span className="text-lighter-green">negative</span>
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition2"
          content={
            <>
              histogram makes a <span className="text-lighter-green">peak</span> /{" "}
              <span className="text-lighter-green">trough</span>
            </>
          }
        />
      </ul>
    </>
  );
};

const MomentumSqueeze = (props) => {
  return (
    <>
      <RuleIndicatorSettingPopup
        setShowOverlay={props.setShowOverlay}
        indicatorSettingsId={props.rule.id}
        rule={props.rule}
        saveRule={props.saveRule}
      />

      <ul className="list-unstyled text-left mb-0">
        {props.rule.conditionsOrder.map((condition) => {
          switch (condition) {
            case "condition1":
              return (
                <TextCondition
                  key="condition1"
                  rule={props.rule}
                  saveRule={props.saveRule}
                  condition="condition1"
                  text={<>bands enter in a squeeze. Bollinger Bands enter the Keltner Channel</>}
                />
              );
            case "condition2":
              return (
                <TextCondition
                  key="condition2"
                  rule={props.rule}
                  saveRule={props.saveRule}
                  condition="condition2"
                  text={<>bands are in a squeeze. Bollinger Bands are within the Keltner Channel</>}
                />
              );
            case "condition3":
              return (
                <TextCondition
                  key="condition3"
                  rule={props.rule}
                  saveRule={props.saveRule}
                  condition="condition3"
                  text={<>bands break from a squeeze. Bollinger Bands break from the Keltner Channel</>}
                />
              );
            case "condition4":
              return (
                <TextCondition
                  key="condition4"
                  rule={props.rule}
                  saveRule={props.saveRule}
                  condition="condition4"
                  text={<>bands are not in a squeeze. Bollinger Bands are not within the Keltner Channel</>}
                />
              );
            case "condition5":
              return (
                <li key="condition5" className={`p-1 rounded ${props.rule.condition5.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition5" />
                  <div className="col-rule text-justify text-wrap">
                    momentum is{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={
                        props.rule.condition5.direction ? props.rule.condition5.direction : histogramDirections[0]
                      }
                      setSelected={(direction) => {
                        let ruleTmp = { ...props.rule };
                        ruleTmp.condition5.direction = direction;
                        props.saveRule(ruleTmp);
                      }}
                      items={histogramDirections}
                    />
                  </div>
                </li>
              );
            case "condition6":
              return (
                <li key="condition6" className={`p-1 rounded ${props.rule.condition6.saved ? "" : "bg-change"}`}>
                  <RemoveConditionBtn saveRule={props.saveRule} rule={props.rule} condition="condition6" />
                  <div className="col-rule text-justify text-wrap">
                    histogram makes a{" "}
                    <Dropdown
                      btnClasses="border-bottom border-light px-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={
                        props.rule.condition6.direction ? props.rule.condition6.direction : histogramDirections2[0]
                      }
                      setSelected={(direction) => {
                        let ruleTmp = { ...props.rule };
                        ruleTmp.condition6.direction = direction;
                        props.saveRule(ruleTmp);
                      }}
                      items={histogramDirections2}
                    />
                  </div>
                </li>
              );
            default:
              return "";
          }
        })}
      </ul>

      <ul className="list-unstyled text-left mb-0">
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition1"
          excludingConditions={["condition2", "condition3", "condition4"]}
          content={
            <>
              bands <span className="text-lighter-green">enter in</span> a squeeze.{" "}
              <span className="text-lighter-green">Bollinger Bands</span> enter the{" "}
              <span className="text-lighter-green">Keltner Channel</span>
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition2"
          excludingConditions={["condition1", "condition3", "condition4"]}
          content={
            <>
              bands <span className="text-lighter-green">are in</span> a squeeze.{" "}
              <span className="text-lighter-green">Bollinger Bands</span> are within the{" "}
              <span className="text-lighter-green">Keltner Channel</span>
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition3"
          excludingConditions={["condition1", "condition2", "condition4"]}
          content={
            <>
              bands <span className="text-lighter-green">break from</span> a squeeze.{" "}
              <span className="text-lighter-green">Bollinger Bands</span> break from the{" "}
              <span className="text-lighter-green">Keltner Channel</span>
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition4"
          excludingConditions={["condition1", "condition2", "condition3"]}
          content={
            <>
              bands <span className="text-lighter-green">are not in</span> a squeeze.{" "}
              <span className="text-lighter-green">Bollinger Bands</span> are not within the{" "}
              <span className="text-lighter-green">Keltner Channel</span>
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition5"
          content={
            <>
              momentum is <span className="text-lighter-green">positive</span> /{" "}
              <span className="text-lighter-green">negative</span>
            </>
          }
        />
        <AddConditionBtn
          rule={props.rule}
          saveRule={props.saveRule}
          condition="condition6"
          content={
            <>
              histogram makes a <span className="text-lighter-green">peak</span> /{" "}
              <span className="text-lighter-green">trough</span>
            </>
          }
        />
      </ul>
    </>
  );
};
const CandleRuleSaved = (props) => {
  return (
    <li className="mt-2">
      {props.text} <span className={props.labelsColor}>{props.rule[props.condition].direction}</span>{" "}
      <span className={props.labelsColor}>{props.rule[props.condition].value.toFixed(2)}%</span>{" "}
      {props.rule[props.condition].direction === "between" && (
        <>
          and <span className={props.labelsColor}>{props.rule[props.condition].value2.toFixed(2)}%</span>
        </>
      )}
    </li>
  );
};
const BollingerBandsRuleSaved = (props) => {
  return (
    <li className="mt-2">
      {props.text} <span className={props.labelsColor}>{props.rule[props.condition].value.toFixed(2)}%</span>
    </li>
  );
};
const RuleSaved = (props) => {
  if (props.rule.indicator === "Manual order") {
    return (
      <div className="d-inline-block text-justify">
        <span className={props.labelsColor}>{props.ruleType === "buy" ? "Manual buy" : "Manual sell"}</span>
      </div>
    );
  }
  let setup = getIndicatorSetup(props.rule);
  let content = " ";
  switch (props.rule.indicator) {
    case "the price":
      content = (
        <ul className="text-justify pl-4 mb-0">
          {props.rule.conditionsOrder.map((condition) => {
            switch (condition) {
              case "condition1":
                return (
                  <li key="condition1" className="mt-2">
                    is <span className={props.labelsColor}>{props.rule.condition1.direction}</span>{" "}
                    <span className={props.labelsColor}>{props.rule.condition1.value.toFixed(2)}%</span> from its{" "}
                    {props.rule.condition1.direction === "up" ? "lowest" : "highest"} point since the last trade or the
                    strategy start
                  </li>
                );
              case "condition2":
                return (
                  <li key="condition2" className="mt-2">
                    is {props.rule.condition2.direction}{" "}
                    <span className={props.labelsColor}>
                      {props.rule.condition2.indicator + getIndicatorSetup(props.rule)}
                    </span>{" "}
                    {(props.rule.condition2.indicator === "Bollinger Bands" ||
                      props.rule.condition2.indicator === "Keltner Channel") && (
                      <span className={props.labelsColor}>{props.rule.condition2.band}</span>
                    )}{" "}
                    {props.rule.condition2.direction === "crossing" && (
                      <>
                        from <span className={props.labelsColor}>{props.rule.condition2.crossDirection}</span>
                      </>
                    )}
                  </li>
                );
              case "condition3":
                return (
                  <li key="condition3" className="mt-2">
                    is <span className={props.labelsColor}>{props.rule.condition3.direction}</span> it's{" "}
                    <span className={props.labelsColor}>{props.rule.condition3.direction2}</span> point, observed in the
                    past <span className={props.labelsColor}>{props.rule.condition3.value}</span> candles.{" "}
                    <span className="font-italic text-secondary">
                      Past {getHoursFromTimeframes(props.rule.timeframe, props.rule.condition3.value)}
                    </span>
                  </li>
                );
              case "condition4":
                return (
                  <li key="condition4" className="mt-2">
                    is between <span className={props.labelsColor}>{props.rule.condition4.value}</span> and{" "}
                    <span className={props.labelsColor}>{props.rule.condition4.value2}</span>.
                  </li>
                );
              case "condition5":
                return (
                  <li key="condition5" className="mt-2">
                    is {props.rule.condition5.direction}{" "}price level{" "}
                    <span className={props.labelsColor}>
                      {props.rule.condition5.value}
                    </span>{" "}
                    {props.rule.condition5.direction === "crossing" && (
                      <>
                        from <span className={props.labelsColor}>{props.rule.condition5.crossDirection}</span>
                      </>
                    )}
                  </li>
                );
              default:
                return "";
            }
          })}
        </ul>
      );
      break;
    case "the candlestick":
      content = (
        <ul className="text-justify pl-4 mb-0">
          {props.rule.conditionsOrder.map((condition) => {
            switch (condition) {
              case "condition1":
                return (
                  <li key="condition1" className="mt-2">
                    pattern is <span className={props.labelsColor}>{props.rule.condition1.pattern}</span>
                  </li>
                );
              case "condition2":
                return (
                  <CandleRuleSaved
                    labelsColor={props.labelsColor}
                    key="condition2"
                    rule={props.rule}
                    condition="condition2"
                    text={(props.rule.mode === "close" ? "open to close" : "open to current price") + " move is"}
                  />
                );
              case "condition3":
                return (
                  <CandleRuleSaved
                    labelsColor={props.labelsColor}
                    key="condition3"
                    rule={props.rule}
                    condition="condition3"
                    text="low to high move is"
                  />
                );
              case "condition4":
                return (
                  <CandleRuleSaved
                    labelsColor={props.labelsColor}
                    key="condition4"
                    rule={props.rule}
                    condition="condition4"
                    text="upper shadow is"
                  />
                );
              case "condition5":
                return (
                  <CandleRuleSaved
                    labelsColor={props.labelsColor}
                    key="condition5"
                    rule={props.rule}
                    condition="condition5"
                    text="lower shadow is"
                  />
                );
              default:
                return "";
            }
          })}
        </ul>
      );
      break;

    case "SMA":
    case "EMA":
      content = (
        <ul className="text-justify pl-4 mb-0">
          {props.rule.conditionsOrder.map((condition) => {
            switch (condition) {
              case "condition1":
                return (
                  <li key="condition1" className="mt-2">
                    is crossing{" "}
                    <span
                      className={props.labelsColor}
                    >{`${props.rule.condition1.indicator}(${props.rule.condition1.period})`}</span>{" "}
                    from <span className={props.labelsColor}>{props.rule.condition1.crossDirection}</span>
                  </li>
                );
              case "condition2":
                return (
                  <li key="condition2" className="mt-2">
                    is in <span className={props.labelsColor}>{props.rule.condition2.direction}</span>. The{" "}
                    {props.rule.indicator} has {props.rule.condition2.direction === "uptrend" ? "gained" : "lost"} at
                    least <span className={props.labelsColor}>{props.rule.condition2.value.toFixed(2)}%</span> in the{" "}
                    {props.rule.condition2.direction}
                  </li>
                );
              case "condition3":
                return (
                  <li key="condition3" className="mt-2">
                    is reversing its <span className={props.labelsColor}>{props.rule.condition3.direction}</span>. The{" "}
                    {props.rule.indicator} {props.rule.condition3.direction === "uptrend" ? "gained" : "lost"} at least{" "}
                    <span className={props.labelsColor}>{props.rule.condition3.value.toFixed(2)}%</span> in the{" "}
                    {props.rule.condition3.direction} The {props.rule.indicator} is{" "}
                    {props.rule.condition3.direction === "uptrend" ? "losing" : "gaining"} at least{" "}
                    <span className={props.labelsColor}>{props.rule.condition3.value2.toFixed(2)}%</span> in the new{" "}
                    {props.rule.condition3.direction === "uptrend" ? "downtrend" : "uptrend"}
                  </li>
                );
              case "condition4":
                return (
                  <li key="condition4" className="mt-2">
                    has at least <span className={props.labelsColor}>{props.rule.condition4.value}</span> consecutive{" "}
                    <span className={props.labelsColor}>{props.rule.condition4.direction}</span> values
                  </li>
                );
              default:
                return "";
            }
          })}
        </ul>
      );
      break;
    case "RSI":
      content = (
        <ul className="text-justify pl-4 mb-0">
          {props.rule.conditionsOrder.map((condition) => {
            switch (condition) {
              case "condition1":
                return (
                  <li key="condition1" className="mt-2">
                    is <span className={props.labelsColor}>{props.rule.condition1.direction}</span>{" "}
                    <span className={props.labelsColor}>{props.rule.condition1.value}</span>
                    {props.rule.condition1.direction === "crossing" && (
                      <>
                        {" "}
                        from <span className={props.labelsColor}>{props.rule.condition1.crossDirection}</span>
                      </>
                    )}
                  </li>
                );
              case "condition2":
                return (
                  <li key="condition2" className="mt-2">
                    is reversing its <span className={props.labelsColor}>{props.rule.condition2.direction}</span>. The{" "}
                    {props.rule.indicator} {props.rule.condition2.direction === "uptrend" ? "gained" : "lost"} at least{" "}
                    <span className={props.labelsColor}>{props.rule.condition2.value.toFixed(0)}</span> points in the{" "}
                    {props.rule.condition2.direction} The {props.rule.indicator} is{" "}
                    {props.rule.condition2.direction === "uptrend" ? "losing" : "gaining"} at least{" "}
                    <span className={props.labelsColor}>{props.rule.condition2.value2.toFixed(0)}</span> points in the
                    new {props.rule.condition2.direction === "uptrend" ? "downtrend" : "uptrend"}
                  </li>
                );
              default:
                return "";
            }
          })}
        </ul>
      );
      break;
    case "MACD":
      content = (
        <ul className="text-justify pl-4 mb-0">
          {props.rule.conditionsOrder.map((condition) => {
            switch (condition) {
              case "condition1":
                return (
                  <li key="condition1" className="mt-2">
                    is <span className={props.labelsColor}>{props.rule.condition1.direction}</span> the signal line
                    {props.rule.condition1.direction === "crossing" && (
                      <>
                        {" "}
                        from <span className={props.labelsColor}>{props.rule.condition1.crossDirection}</span>
                      </>
                    )}
                  </li>
                );
              case "condition2":
                return (
                  <li key="condition2" className="mt-2">
                    is <span className={props.labelsColor}>{props.rule.condition2.direction}</span> zero
                    {props.rule.condition2.direction === "crossing" && (
                      <>
                        {" "}
                        from <span className={props.labelsColor}>{props.rule.condition2.crossDirection}</span>
                      </>
                    )}
                  </li>
                );
              case "condition3":
                return (
                  <li key="condition3" className="mt-2">
                    histogram is <span className={props.labelsColor}>{props.rule.condition3.direction}</span>
                  </li>
                );
              case "condition4":
                return (
                  <li key="condition4" className="mt-2">
                    histogram makes a <span className={props.labelsColor}>{props.rule.condition4.direction}</span>
                  </li>
                );
              default:
                return "";
            }
          })}
        </ul>
      );
      break;
    case "Momentum":
      content = (
        <ul className="text-justify pl-4 mb-0">
          {props.rule.conditionsOrder.map((condition) => {
            switch (condition) {
              case "condition1":
                return (
                  <li key="condition5" className="mt-2">
                    momentum is <span className={props.labelsColor}>{props.rule.condition1.direction}</span>
                  </li>
                );
              case "condition2":
                return (
                  <li key="condition2" className="mt-2">
                    histogram makes a <span className={props.labelsColor}>{props.rule.condition2.direction}</span>
                  </li>
                );
              default:
                return "";
            }
          })}
        </ul>
      );
      break;
    case "Bollinger Bands":
    case "Keltner Channel":
      content = (
        <ul className="text-justify pl-4 mb-0">
          {props.rule.conditionsOrder.map((condition) => {
            switch (condition) {
              case "condition1":
                return (
                  <BollingerBandsRuleSaved
                    labelsColor={props.labelsColor}
                    key="condition1"
                    rule={props.rule}
                    condition="condition1"
                    text="bands enter in a squeeze. Bands width is going below"
                  />
                );
              case "condition2":
                return (
                  <BollingerBandsRuleSaved
                    labelsColor={props.labelsColor}
                    key="condition2"
                    rule={props.rule}
                    condition="condition2"
                    text="bands are in a squeeze. Bands width is below"
                  />
                );
              case "condition3":
                return (
                  <BollingerBandsRuleSaved
                    labelsColor={props.labelsColor}
                    key="condition3"
                    rule={props.rule}
                    condition="condition3"
                    text="bands break from a squeeze. Bands width is going above"
                  />
                );
              case "condition4":
                return (
                  <BollingerBandsRuleSaved
                    labelsColor={props.labelsColor}
                    key="condition4"
                    rule={props.rule}
                    condition="condition4"
                    text="bands are not in a squeeze. Bands width is above"
                  />
                );
              default:
                return "";
            }
          })}
        </ul>
      );
      break;
    case "Stochastic":
    case "Stochastic RSI":
      content = (
        <ul className="text-justify pl-4 mb-0">
          {props.rule.conditionsOrder.map((condition) => {
            switch (condition) {
              case "condition1":
                return (
                  <li key="condition1" className="mt-2">
                    K% line is <span className={props.labelsColor}>{props.rule.condition1.direction}</span> the D% line
                    {props.rule.condition1.direction === "crossing" && (
                      <>
                        {" "}
                        from <span className={props.labelsColor}>{props.rule.condition1.crossDirection}</span>
                      </>
                    )}
                  </li>
                );
              case "condition2":
                return (
                  <li key="condition2" className="mt-2">
                    K% line is <span className={props.labelsColor}>{props.rule.condition2.direction}</span>{" "}
                    <span className={props.labelsColor}>{props.rule.condition2.value}</span>
                    {props.rule.condition2.direction === "crossing" && (
                      <>
                        {" "}
                        from <span className={props.labelsColor}>{props.rule.condition2.crossDirection}</span>
                      </>
                    )}
                  </li>
                );
              case "condition3":
                return (
                  <li key="condition3" className="mt-2">
                    K% line is reversing its{" "}
                    <span className={props.labelsColor}>{props.rule.condition3.direction}</span>. The K% line{" "}
                    {props.rule.condition3.direction === "uptrend" ? "gained" : "lost"} at least{" "}
                    <span className={props.labelsColor}>{props.rule.condition3.value.toFixed(0)}</span> points in the{" "}
                    {props.rule.condition3.direction}. The K% line is{" "}
                    {props.rule.condition3.direction === "uptrend" ? "losing" : "gaining"} at least{" "}
                    <span className={props.labelsColor}>{props.rule.condition3.value2.toFixed(0)}</span> points in the
                    new {props.rule.condition3.direction === "uptrend" ? "downtrend" : "uptrend"}
                  </li>
                );
              default:
                return "";
            }
          })}
        </ul>
      );
      break;
    case "Momentum Squeeze":
      content = (
        <ul className="text-justify pl-4 mb-0">
          {props.rule.conditionsOrder.map((condition) => {
            switch (condition) {
              case "condition1":
                return (
                  <li key="condition1" className="mt-2">
                    bands <span className={props.labelsColor}>enter in</span> a squeeze.{" "}
                    <span className={props.labelsColor}>Bollinger Bands</span> enter the{" "}
                    <span className={props.labelsColor}>Keltner Channel</span>
                  </li>
                );
              case "condition2":
                return (
                  <li key="condition2" className="mt-2">
                    bands <span className={props.labelsColor}>are in</span> a squeeze.{" "}
                    <span className={props.labelsColor}>Bollinger Bands</span> are within the{" "}
                    <span className={props.labelsColor}>Keltner Channel</span>
                  </li>
                );
              case "condition3":
                return (
                  <li key="condition3" className="mt-2">
                    bands <span className={props.labelsColor}>break from</span> a squeeze.{" "}
                    <span className={props.labelsColor}>Bollinger Bands</span> break from the{" "}
                    <span className={props.labelsColor}>Keltner Channel</span>
                  </li>
                );
              case "condition4":
                return (
                  <li key="condition4" className="mt-2">
                    bands <span className={props.labelsColor}>are not in</span> a squeeze.{" "}
                    <span className={props.labelsColor}>Bollinger Bands</span> are not within the{" "}
                    <span className={props.labelsColor}>Keltner Channel</span>
                  </li>
                );
              case "condition5":
                return (
                  <li key="condition5" className="mt-2">
                    momentum is <span className={props.labelsColor}>{props.rule.condition5.direction}</span>
                  </li>
                );
              case "condition6":
                return (
                  <li key="condition6" className="mt-2">
                    histogram makes a <span className={props.labelsColor}>{props.rule.condition6.direction}</span>
                  </li>
                );
              default:
                return "";
            }
          })}
        </ul>
      );
      break;
    default:
  }
  return (
    <div className="d-inline-block text-justify">
      On {props.rule.timeframe} chart{" "}
      <span className={props.labelsColor}>
        {props.rule.indicator + (props.rule.indicator === "the price" ? "" : setup)}
      </span>
      :{content}
    </div>
  );
};

const Rule = (props) => {
  const [indicatorSetup, setIndicatorSetup] = useState("");

  useEffect(() => {
    setIndicatorSetup(getIndicatorSetup(props.rule));
  }, [props.secondIndicator, props.rule]);

  return (
    <tr key={props.rule.id}>
      <td className="text-left pt-0 pb-2">
        <a
          href="#/"
          className="text-danger mr-3"
          onClick={(e) => {
            e.preventDefault();
            document.activeElement.blur();
            props.removeRule(props.rule);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </a>
        <FontAwesomeIcon className="text-light-green" icon={faHandPointRight} />
      </td>
      <td className="text-left py-0 pb-2">
        <div className={`ml-neg1 ${props.rule.saved ? "" : "bg-change"} rounded`}>
          {props.rule.indicator === "Manual order" ? (
            <div
              className={`pl-1 text-justify text-light-green text-wrap rounded ${props.rule.saved ? "" : "bg-change"}`}
            >
              {props.ruleType === "buy" ? "Manual buy" : "Manual sell"}
            </div>
          ) : (
            <div className={`text-justify text-wrap rounded ${props.rule.saved ? "" : "bg-change"}`}>
              <div className="d-inline-block px-1 mb-2">
                On{" "}
                <Dropdown
                  btnClasses="border-bottom border-light px-2"
                  placeholder="select"
                  selectedClasses="text-light-green hover-light-green"
                  menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                  menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                  selected={props.rule.timeframe}
                  setSelected={(timeframe) => {
                    if (props.demo) {
                      props.showErrorMsg(
                        <span className="text-info">
                          <FontAwesomeIcon icon={faExclamationTriangle} /> Demo mode
                        </span>,
                        "Cannot be changed in DEMO mode!"
                      );
                      return;
                    }

                    let ruleTmp = { ...props.rule, timeframe: timeframe };
                    props.lastTimeframeRef.current = timeframe;
                    props.saveRule(ruleTmp);
                  }}
                  items={timeframes}
                />{" "}
                chart{" "}
                <Dropdown
                  btnClasses="border-bottom border-light px-2"
                  placeholder="select"
                  selectedClasses="text-light-green hover-light-green"
                  menuClasses="bg-white white border border-light-green rounded text-nowrap min-w-80 max-h-250"
                  menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                  selected={props.rule.indicator}
                  setSelected={(indicator) => {
                    if (indicator !== props.rule.indicator) {
                      let ruleTmp;
                      if (
                        (indicator === "SMA" || indicator === "EMA") &&
                        (props.rule.indicator === "SMA" || props.rule.indicator === "EMA")
                      ) {
                        ruleTmp = { ...props.rule, indicator: indicator };
                      } else {
                        ruleTmp = resetIndicatorSetup(props.rule, indicator);
                      }
                      props.saveRule(ruleTmp);
                    }
                  }}
                  items={indicators}
                />
                {props.rule.indicator !== indicators[0] &&
                  props.rule.indicator !== "the candlestick" &&
                  props.rule.indicator !== "the price" &&
                  props.rule.indicator !== "Manual order" && (
                    <a
                      className={`px-1 ml-1 d-inline-block border-bottom border-light font-italic ${
                        indicatorSetup === "" ? "text-danger" : "text-light-green"
                      }`}
                      data-toggle="modal"
                      data-target={"#" + props.rule.id}
                      href="#/"
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                        props.setShowOverlay(true);
                      }}
                    >
                      {indicatorSetup} <FontAwesomeIcon icon={faCog} />
                    </a>
                  )}
                :
              </div>
              {props.rule.indicator === "the price" && (
                <ThePrice rule={props.rule} saveRule={props.saveRule} setShowOverlay={props.setShowOverlay} />
              )}
              {props.rule.indicator === "the candlestick" && (
                <CandleStick strategy={props.strategy} rule={props.rule} saveRule={props.saveRule} />
              )}
              {(props.rule.indicator === "SMA" || props.rule.indicator === "EMA") && (
                <MovingAverage rule={props.rule} saveRule={props.saveRule} setShowOverlay={props.setShowOverlay} />
              )}
              {props.rule.indicator === "RSI" && (
                <RSI rule={props.rule} saveRule={props.saveRule} setShowOverlay={props.setShowOverlay} />
              )}
              {props.rule.indicator === "MACD" && (
                <MACD rule={props.rule} saveRule={props.saveRule} setShowOverlay={props.setShowOverlay} />
              )}
              {props.rule.indicator === "Bollinger Bands" && (
                <BollingerBands rule={props.rule} saveRule={props.saveRule} setShowOverlay={props.setShowOverlay} />
              )}
              {props.rule.indicator === "Keltner Channel" && (
                <KeltnerChannel rule={props.rule} saveRule={props.saveRule} setShowOverlay={props.setShowOverlay} />
              )}
              {(props.rule.indicator === "Stochastic" || props.rule.indicator === "Stochastic RSI") && (
                <Stochastic rule={props.rule} saveRule={props.saveRule} setShowOverlay={props.setShowOverlay} />
              )}
              {props.rule.indicator === "Momentum" && (
                <Momentum rule={props.rule} saveRule={props.saveRule} setShowOverlay={props.setShowOverlay} />
              )}
              {props.rule.indicator === "Momentum Squeeze" && (
                <MomentumSqueeze rule={props.rule} saveRule={props.saveRule} setShowOverlay={props.setShowOverlay} />
              )}
            </div>
          )}
        </div>
      </td>
      <td className="text-left py-0 pb-2 pr-0 pr-md-3">
        {props.rule.indicator !== "Manual order" && (
          <RadioButtons
            options={[
              {
                optionClasses: "text-white",
                checkedColor: "text-light-green",
                uncheckedColor: "text-white",
                checked: props.rule.mode === "close",
                check: () => {
                  let ruleTmp = { ...props.rule, mode: "close" };
                  props.lastModeRef.current = "close";
                  props.saveRule(ruleTmp);
                },
                text: "candle close",
              },
              {
                optionClasses: "text-white",
                checkedColor: "text-light-green",
                uncheckedColor: "text-white",
                checked: props.rule.mode === "current",
                check: () => {
                  let ruleTmp = { ...props.rule, mode: "current" };
                  props.lastModeRef.current = "current";
                  props.saveRule(ruleTmp);
                },
                text: "current price",
              },
            ]}
          />
        )}
      </td>
    </tr>
  );
};

const Rules = (props) => {
  return props.rules.map((rule) => (
    <Rule
      strategy={props.strategy}
      demo={props.demo}
      showErrorMsg={props.showErrorMsg}
      key={rule.id}
      rule={rule}
      ruleType={props.ruleType}
      setShowOverlay={props.setShowOverlay}
      removeRule={(rule) => {
        props.showConfirmMsg(
          <span className="text-info">
            <FontAwesomeIcon icon={faQuestionCircle} /> Remove Rule
          </span>,
          <>
            Are you sure you want to remove the{" "}
            {rule.indicator === indicators[0] ? (
              "rule"
            ) : (
              <>
                <b>{rule.indicator}</b> rule
              </>
            )}
            ?
          </>,
          () => {
            props.saveRules(props.rules.filter((el) => el.id !== rule.id));
          }
        );
      }}
      saveRule={(rule) => {
        let newRules = [];
        for (let ruleTmp of props.rules) {
          if (rule.id === ruleTmp.id) {
            newRules.push(rule);
            continue;
          }
          newRules.push(ruleTmp);
        }
        props.saveRules(newRules);
      }}
      lastTimeframeRef={props.lastTimeframeRef}
      lastModeRef={props.lastModeRef}
    />
  ));
};

export { Rules, RuleSaved, IndicatorSettingsTable, ruleDefaults };
