import React, { useContext } from "react";
import "./App.css";
import { showInfoDialog, fetchWithRetry, showConfirmDialog } from "./utils.js";
import { showUpdateNowMessage } from "./Navigation.js";
import { globalContext } from "./Context.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAt,
  faCloudDownloadAlt,
  faEnvelope,
  faHandPointRight,
  faInfoCircle,
  faQuestionCircle,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";
import $ from "jquery";

function showTermsAndConditions(dispatch) {
  import("./terms-and-conditions.js").then((component) => {
    showInfoDialog(dispatch, <span className="text-info">Terms &amp; Conditions</span>, component.default, true);
  });
}

function showPrivacyPolicy(dispatch) {
  import("./privacy-policy.js").then((component) => {
    showInfoDialog(dispatch, <span className="text-info">Privacy Policy</span>, component.default, true);
  });
}
const Footer = (props) => {
  let { state, dispatch } = useContext(globalContext);
  return (
    <footer
      className={`position-relative footer bg-new-darker mt-auto py-2 user-select-none ${
        props.classes ? props.classes : ""
      }`}
    >
      <div className="container text-secondary">
        <div>
          Follow us{" "}
          <a
            href="https://www.facebook.com/EasyCryptoBot"
            rel="noopener noreferrer"
            target="_blank"
            className="text-info d-inline-block px-2"
            onClick={(e) => {
              document.activeElement.blur();
            }}
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a
            href="https://twitter.com/EasyCryptoBot"
            rel="noopener noreferrer"
            target="_blank"
            className="text-info d-inline-block pl-1"
            onClick={(e) => {
              document.activeElement.blur();
            }}
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
        </div>
        <div className="user-select-none">
          <FontAwesomeIcon icon={faEnvelope} /> info
          <FontAwesomeIcon icon={faAt} />
          easycryptobot.com
        </div>
        <div>
          <a
            href="#/"
            className="text-info text-nowrap d-inline-block mx-2"
            onClick={(e) => {
              e.preventDefault();
              document.activeElement.blur();
              showTermsAndConditions(dispatch);
            }}
          >
            Terms &amp; Conditions
          </a>
          <a
            href="#/"
            className="text-info text-nowrap d-inline-block mx-2"
            onClick={(e) => {
              e.preventDefault();
              document.activeElement.blur();
              showPrivacyPolicy(dispatch);
            }}
          >
            Privacy Policy
          </a>

          <VersionInfo text="Version" />
        </div>
        <div>Insentico Ltd. &copy; 2018-{new Date().getFullYear()} All rights reserved.</div>
        {state.app && (
          <div className="d-none d-sm-block position-absolute small font-italic right-1 bottom-1">
            Build: {state.build}
          </div>
        )}
      </div>
    </footer>
  );
};

const VersionInfo = (props) => {
  let { state, dispatch } = useContext(globalContext);
  return (
    <a
      href="#/"
      className="text-info d-inline-block mx-2"
      title="Update available"
      onClick={(e) => {
        e.preventDefault();
        document.activeElement.blur();
        showInfoDialog(
          dispatch,
          <div className="text-info">
            <FontAwesomeIcon icon={faInfoCircle} /> Version Info
          </div>,
          <div>
            <div className="text-center">
              <a
                href="#/"
                className="btn btn-sm btn-info mx-2"
                title="Update available"
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  fetchWithRetry(
                    `https://api2.easycryptobot.com/version.php?v=${state.version}&t=${new Date().getTime()}`,
                    "get",
                    null,
                    (json) => {
                      if (json && json.version) {
                        dispatch({ type: "serverVersion", payload: json });

                        if (json.version.version > state.version) {
                          if ($("#infoDialog").is(":visible")) {
                            $("#infoDialog").modal("toggle");
                          }

                          state.serverVersion = json.version;
                          showUpdateNowMessage(dispatch, state);
                        } else {
                          showInfoDialog(
                            dispatch,
                            <div className="text-info">
                              <FontAwesomeIcon icon={faInfoCircle} /> No Update
                            </div>,
                            <div>You have the latest version of EasyCryptoBot</div>
                          );
                        }
                      }
                    }
                  );
                }}
              >
                <FontAwesomeIcon icon={faCloudDownloadAlt} /> Check for Update
              </a>

              <a
                href="#/"
                className="btn btn-sm btn-info mx-2"
                title="Restart"
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  if ($("#infoDialog").is(":visible")) {
                    $("#infoDialog").modal("toggle");
                  }
                  showConfirmDialog(
                    dispatch,
                    <div className="text-info">
                      <FontAwesomeIcon icon={faQuestionCircle} /> Restart Now
                    </div>,
                    <div>The application will restart.</div>,
                    () => {
                      if (navigator.serviceWorker) {
                        navigator.serviceWorker.getRegistrations().then((registrations) => {
                          for (let registration of registrations) {
                            registration.unregister();
                          }
                          window.location.reload();
                        });
                      } else {
                        window.location.reload();
                      }
                    }
                  );
                }}
              >
                <FontAwesomeIcon icon={faSync} /> Restart Now
              </a>
            </div>
            <div>
              Version: <span className="text-info">{state.version}</span>{" "}
            </div>
            <div>
              Build: <span className="text-info">{state.build}</span>{" "}
            </div>
            {state.serverVersion && state.serverVersion.info && (
              <div>
                Version history:
                <div className="pl-3">
                  {Object.keys(state.serverVersion.info)
                    .filter((el) => el <= state.version)
                    .map((el, index) => (
                      <div key={`${el}-${index}`}>
                        <span className="text-info">{el}:</span>
                        {state.serverVersion.info[el].map((subEl, index) => (
                          <div className="pl-2" key={`sub-${el}-${index}`}>
                            <FontAwesomeIcon className="text-info" icon={faHandPointRight} /> {subEl}
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>,
          () => {
            if (navigator.serviceWorker) {
              navigator.serviceWorker.getRegistrations().then((registrations) => {
                for (let registration of registrations) {
                  registration.unregister();
                }
                window.location.reload();
              });
            } else {
              window.location.reload();
            }
          }
        );
      }}
    >
      {props.text}
    </a>
  );
};
export { VersionInfo };
export default Footer;
