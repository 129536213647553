import Mutex from "./Mutex.js";
import getExchange from "./Exchange.js";
import {
  addError,
  sleep,
  showInfoDialog,
  initializeWorker,
  saveStrategyEvent,
  saveStrategTrade,
  addItemToArrayInLocalStore,
} from "./utils.js";
import { conditionDirections } from "./constants/consts.json";
import CryptoJS from "crypto-js";
import { getTimeframes } from "./tatools.js";
import getEcbDatabase from "./EcbDatabase";
/* eslint-disable no-unused-expressions */
/* eslint-disable no-func-assign */
/* eslint-disable no-sequences */
(function (_0xebc1d0, _0x5a5b18) {
  const _0x9898ec = a0_0x2727,
    _0x399100 = _0xebc1d0();
  while (!![]) {
    try {
      const _0x8759f6 =
        (parseInt(_0x9898ec(0x94)) / 0x1) * (parseInt(_0x9898ec(0xfc)) / 0x2) +
        parseInt(_0x9898ec(0x82)) / 0x3 +
        (parseInt(_0x9898ec(0xb0)) / 0x4) * (-parseInt(_0x9898ec(0x80)) / 0x5) +
        (-parseInt(_0x9898ec(0x96)) / 0x6) * (parseInt(_0x9898ec(0xf6)) / 0x7) +
        (-parseInt(_0x9898ec(0xf5)) / 0x8) * (parseInt(_0x9898ec(0x87)) / 0x9) +
        (-parseInt(_0x9898ec(0xac)) / 0xa) * (parseInt(_0x9898ec(0xdf)) / 0xb) +
        (parseInt(_0x9898ec(0xa2)) / 0xc) * (parseInt(_0x9898ec(0x92)) / 0xd);
      if (_0x8759f6 === _0x5a5b18) break;
      else _0x399100["push"](_0x399100["shift"]());
    } catch (_0x1ce333) {
      _0x399100["push"](_0x399100["shift"]());
    }
  }
})(a0_0xf11b, 0xb4111);
let add2 = 0x2,
  add3 = [
    0xc, 0x21, 0x2c, 0x2, 0x5, 0x11, 0x6, 0xc, 0x39, 0xc, 0x21, 0x7, 0x1e, 0x7,
    0x5, 0xc, 0x8, 0x17, 0x5, 0x1, 0x2d, 0x1, 0x1,
  ];
const tradings = {},
  tradingMutex = new Mutex(),
  tradingWorkers = {};
function updateExchangeApiKeys(_0x4c68ef, _0x225581, _0x1ee9ac, _0x58ae87) {
  const _0x249888 = a0_0x2727;
  tradingWorkers[_0x4c68ef] &&
    tradingWorkers[_0x4c68ef][_0x249888(0x109)]({
      cmd: "UPDATE_API_KEY",
      apiKey: _0x225581,
      apiSecret: _0x1ee9ac,
      apiKeyValid: _0x58ae87,
    });
}
function stopTradingWorkers() {
  const _0x282720 = a0_0x2727;
  Object[_0x282720(0x11f)](tradingWorkers)[_0x282720(0xbd)]((_0x24cfeb) => {
    const _0x316fcc = _0x282720;
    try {
      let _0x22beaa = tradingWorkers[_0x24cfeb];
      _0x22beaa[_0x316fcc(0x103)]();
    } catch (_0x77df4d) {}
  });
}
async function initializeTradingWorkers(
  _0x5afbee,
  _0x12ddd7,
  _0x4b02f6,
  _0x439869,
  _0x366145,
  _0x5a5f75
) {
  const _0x14943d = a0_0x2727;
  let _0x53b9b3 = await initializeWorker(
      tradingWorkers,
      _0x14943d(0x10f),
      _0x14943d(0x9f)
    ),
    _0x1b4bd4 = await initializeWorker(
      tradingWorkers,
      "Binance\x20US",
      _0x14943d(0x9f)
    );
  if (!_0x53b9b3 || !_0x1b4bd4) {
    showInfoDialog(_0x5afbee, _0x14943d(0xe8), _0x14943d(0xa5));
    return;
  }
  Object[_0x14943d(0x11f)](tradingWorkers)[_0x14943d(0xbd)]((_0x59473f) => {
    const _0x142df3 = _0x14943d;
    let _0x509847 = tradingWorkers[_0x59473f];
    (_0x509847[_0x142df3(0xcb)] = async (_0x5e46e) => {
      const _0x22e281 = _0x142df3;
      try {
        if (_0x5e46e["data"]["cmd"] === _0x22e281(0xfb)) {
          let _0x4244a5 = tradings[_0x5e46e[_0x22e281(0x112)]["id"]];
          if (!_0x4244a5) return;
          (_0x4244a5[_0x22e281(0x105)][_0x22e281(0x105)] = _0x22e281(0xef)),
            delete _0x4244a5["status"]["starting"],
            (_0x4244a5[_0x22e281(0x105)]["error"] = null),
            (_0x4244a5[_0x22e281(0x105)][_0x22e281(0x98)] = null),
            (_0x4244a5[_0x22e281(0x105)][_0x22e281(0x10d)] = new Date()[
              _0x22e281(0x88)
            ]()),
            _0x5afbee({
              type:
                _0x4244a5[_0x22e281(0x106)] === _0x22e281(0xe1)
                  ? _0x22e281(0xf1)
                  : "liveStatus",
              payload: { status: _0x4244a5[_0x22e281(0x105)] },
            });
          return;
        } else {
          if (
            _0x5e46e[_0x22e281(0x112)][_0x22e281(0xc9)] === _0x22e281(0x10c)
          ) {
            let _0x544490 = tradings[_0x5e46e[_0x22e281(0x112)]["id"]];
            if (!_0x544490) return;
            (_0x544490[_0x22e281(0x105)]["status"] = "Stopped"),
              (_0x544490["status"][_0x22e281(0x84)] = null),
              (_0x544490[_0x22e281(0x105)]["errorLevel"] = null),
              (_0x544490[_0x22e281(0x105)][_0x22e281(0x10d)] = new Date()[
                "getTime"
              ]()),
              _0x5afbee({
                type:
                  _0x544490["type"] === _0x22e281(0xe1)
                    ? _0x22e281(0xf1)
                    : _0x22e281(0xd7),
                payload: { status: _0x544490["status"] },
              });
            return;
          }
        }
        await tradingMutex[_0x22e281(0xb8)]();
        if (_0x5e46e["data"][_0x22e281(0xc9)] === "ERROR") {
          addError({
            ..._0x5e46e[_0x22e281(0x112)][_0x22e281(0x84)],
            user: _0x439869,
            id: _0x439869 + "_" + new Date()[_0x22e281(0x88)](),
          });
          return;
        }
        let _0x5b1227 = tradings[_0x5e46e[_0x22e281(0x112)]["id"]];
        if (!_0x5b1227) return;
        switch (_0x5e46e["data"][_0x22e281(0xc9)]) {
          case _0x22e281(0xa0):
            let _0x3e1081 = CryptoJS[_0x22e281(0xe3)](
              _0x5e46e["data"]["data"],
              _0x5e46e["data"]["key"]
            );
            _0x509847[_0x22e281(0x109)]({
              cmd: _0x22e281(0xe5),
              id: _0x5e46e[_0x22e281(0x112)]["id"],
              signed: _0x3e1081[_0x22e281(0xca)](),
            });
            break;
          case _0x22e281(0xcd):
            getExchange(_0x5b1227[_0x22e281(0xbc)][_0x22e281(0xc7)])[
              _0x22e281(0xae)
            ](_0x5e46e[_0x22e281(0x112)][_0x22e281(0xd8)], (_0x115a3e) => {
              const _0x2f5c14 = _0x22e281;
              _0x509847["postMessage"]({
                cmd: _0x2f5c14(0xd1),
                id: _0x5e46e["data"]["id"],
                order: _0x115a3e,
              });
            });
            break;
          case "UNSUBSCRIBE_ORDER_STATUS":
            getExchange(_0x5b1227[_0x22e281(0xbc)][_0x22e281(0xc7)])[
              _0x22e281(0x91)
            ](_0x5e46e["data"]["orderId"]);
            break;
          case _0x22e281(0xc5):
            _0x5e46e[_0x22e281(0x112)][_0x22e281(0xec)][_0x22e281(0x10d)] =
              new Date()[_0x22e281(0x88)]();
            _0x5b1227[_0x22e281(0x106)] === _0x22e281(0xe1)
              ? (getEcbDatabase()[_0x22e281(0x117)](
                  _0x5e46e[_0x22e281(0x112)]["details"]
                ),
                addItemToArrayInLocalStore(
                  "strategySimDetailsNotSync",
                  _0x5e46e[_0x22e281(0x112)][_0x22e281(0xec)]["id"]
                ))
              : (getEcbDatabase()[_0x22e281(0x123)](
                  _0x5e46e[_0x22e281(0x112)]["details"]
                ),
                addItemToArrayInLocalStore(
                  _0x22e281(0x111),
                  _0x5e46e[_0x22e281(0x112)][_0x22e281(0xec)]["id"]
                ));
            break;
          case _0x22e281(0x107):
            (_0x5b1227[_0x22e281(0x105)][_0x22e281(0xec)] =
              _0x5e46e["data"][_0x22e281(0xec)]),
              _0x5afbee({
                type:
                  _0x5b1227[_0x22e281(0x106)] === "simulation"
                    ? _0x22e281(0xf1)
                    : _0x22e281(0xd7),
                payload: { status: _0x5b1227[_0x22e281(0x105)] },
              });
            break;
          case _0x22e281(0xc6):
            (_0x5b1227[_0x22e281(0x105)][_0x22e281(0x120)] =
              _0x5e46e[_0x22e281(0x112)]["status"][_0x22e281(0x120)]),
              (_0x5b1227[_0x22e281(0x105)][_0x22e281(0x86)] =
                _0x5e46e["data"][_0x22e281(0x105)][_0x22e281(0x86)]),
              (_0x5b1227["status"][_0x22e281(0x10d)] = new Date()["getTime"]()),
              _0x5afbee({
                type:
                  _0x5b1227[_0x22e281(0x106)] === _0x22e281(0xe1)
                    ? _0x22e281(0xf1)
                    : _0x22e281(0xd7),
                payload: { status: _0x5b1227[_0x22e281(0x105)] },
              });
            break;
          case _0x22e281(0xed):
            break;
          case _0x22e281(0xdb):
            (!_0x5b1227[_0x22e281(0x105)][_0x22e281(0x122)] ||
              (_0x5b1227[_0x22e281(0x105)]["lastTrade"] &&
                _0x5b1227["status"][_0x22e281(0x122)]["id"] !==
                  _0x5e46e[_0x22e281(0x112)]["trade"]["id"])) &&
              _0x5b1227["status"][_0x22e281(0x8d)]++;
            (_0x5e46e[_0x22e281(0x112)][_0x22e281(0xa7)][_0x22e281(0x10d)] =
              new Date()[_0x22e281(0x88)]()),
              (_0x5b1227[_0x22e281(0x105)][_0x22e281(0x122)] =
                _0x5e46e[_0x22e281(0x112)][_0x22e281(0xa7)]),
              (_0x5b1227[_0x22e281(0x105)][_0x22e281(0x86)] = !![]),
              (_0x5b1227[_0x22e281(0x105)][_0x22e281(0x120)] =
                _0x5e46e["data"]["nextTrade"]),
              saveStrategTrade(
                _0x5b1227["type"],
                _0x5e46e[_0x22e281(0x112)][_0x22e281(0xa7)],
                _0x366145
              ),
              delete _0x5b1227[_0x22e281(0x105)]["manualBuyAt"],
              delete _0x5b1227["status"][_0x22e281(0xb7)],
              (_0x5b1227[_0x22e281(0x105)][_0x22e281(0x10d)] = new Date()[
                _0x22e281(0x88)
              ]()),
              _0x5afbee({
                type:
                  _0x5b1227[_0x22e281(0x106)] === _0x22e281(0xe1)
                    ? "simStatus"
                    : "liveStatus",
                payload: { status: _0x5b1227["status"], trade: !![] },
              });
            break;
          case _0x22e281(0xeb):
            _0x5b1227[_0x22e281(0x105)]["tradesCount"]--,
              (_0x5b1227[_0x22e281(0x105)][_0x22e281(0x86)] = ![]),
              (_0x5b1227["status"][_0x22e281(0x120)] = _0x22e281(0x115)),
              delete _0x5b1227[_0x22e281(0x105)][_0x22e281(0x122)],
              (_0x5b1227[_0x22e281(0x105)][_0x22e281(0x10d)] = new Date()[
                "getTime"
              ]()),
              _0x5afbee({
                type:
                  _0x5b1227[_0x22e281(0x106)] === _0x22e281(0xe1)
                    ? _0x22e281(0xf1)
                    : _0x22e281(0xd7),
                payload: { status: _0x5b1227[_0x22e281(0x105)], trade: !![] },
              }),
              (_0x5e46e["data"][_0x22e281(0xa7)]["editDate"] = new Date()[
                _0x22e281(0x88)
              ]()),
              saveStrategTrade(
                _0x5b1227[_0x22e281(0x106)],
                _0x5e46e[_0x22e281(0x112)][_0x22e281(0xa7)],
                _0x366145
              );
            break;
          case _0x22e281(0xf0):
            (_0x5e46e[_0x22e281(0x112)][_0x22e281(0xa7)][_0x22e281(0x10d)] =
              new Date()[_0x22e281(0x88)]()),
              await saveStrategTrade(
                _0x5b1227[_0x22e281(0x106)],
                _0x5e46e[_0x22e281(0x112)][_0x22e281(0xa7)],
                _0x366145
              );
            let _0x1542db = ["NEW", _0x22e281(0x90), _0x22e281(0xd4)];
            _0x1542db[_0x22e281(0xf2)](
              _0x5e46e[_0x22e281(0x112)][_0x22e281(0xa7)][_0x22e281(0xce)]
            )
              ? ((_0x5b1227["status"]["nextTrade"] = "sell"),
                (_0x5b1227[_0x22e281(0x105)][_0x22e281(0x86)] = !![]),
                (_0x5b1227["status"][_0x22e281(0x122)] =
                  _0x5e46e[_0x22e281(0x112)][_0x22e281(0xa7)]))
              : ((_0x5b1227[_0x22e281(0x105)][_0x22e281(0x120)] =
                  _0x22e281(0x115)),
                (_0x5b1227[_0x22e281(0x105)][_0x22e281(0x86)] = ![]),
                delete _0x5b1227[_0x22e281(0x105)][_0x22e281(0x122)]);
            if (
              _0x5e46e["data"][_0x22e281(0xa7)][_0x22e281(0xce)] ===
                _0x22e281(0x114) ||
              _0x5e46e[_0x22e281(0x112)][_0x22e281(0xa7)][_0x22e281(0xce)] ===
                _0x22e281(0x7f)
            ) {
              let _0x2f5ac1 =
                  _0x5b1227[_0x22e281(0x106)] === _0x22e281(0xe1)
                    ? await getEcbDatabase()[_0x22e281(0xda)](
                        _0x5b1227[_0x22e281(0xbc)]["id"]
                      )
                    : await getEcbDatabase()[_0x22e281(0xd0)](
                        _0x5b1227["strategy"]["id"]
                      ),
                _0x18c622 = 0x0,
                _0x34a724 = 0x0;
              _0x2f5ac1[_0x22e281(0xbd)]((_0x4ac812) => {
                const _0x31f104 = _0x22e281;
                let _0x379360 = ["FILLED", _0x31f104(0x7f)];
                _0x379360[_0x31f104(0xf2)](_0x4ac812[_0x31f104(0xce)]) &&
                  (_0x4ac812["result"] && (_0x18c622 += _0x4ac812["result"]),
                  _0x4ac812[_0x31f104(0xe6)] &&
                    (_0x34a724 += _0x4ac812[_0x31f104(0xe6)]));
              }),
                (_0x5b1227[_0x22e281(0x105)][_0x22e281(0xc1)] = _0x18c622),
                (_0x5b1227[_0x22e281(0x105)][_0x22e281(0xe6)] = _0x34a724);
            }
            (_0x5b1227[_0x22e281(0x105)][_0x22e281(0x10d)] = new Date()[
              _0x22e281(0x88)
            ]()),
              _0x5afbee({
                type:
                  _0x5b1227[_0x22e281(0x106)] === "simulation"
                    ? _0x22e281(0xf1)
                    : _0x22e281(0xd7),
                payload: { status: _0x5b1227[_0x22e281(0x105)], trade: !![] },
              });
            break;
          case "CLEAR_MANUAL":
            (_0x5b1227[_0x22e281(0x105)][_0x22e281(0x8c)] ||
              _0x5b1227[_0x22e281(0x105)][_0x22e281(0xb7)]) &&
              (delete _0x5b1227[_0x22e281(0x105)][_0x22e281(0x8c)],
              delete _0x5b1227[_0x22e281(0x105)][_0x22e281(0xb7)],
              (_0x5b1227[_0x22e281(0x105)]["editDate"] = new Date()[
                "getTime"
              ]()),
              _0x5afbee({
                type:
                  _0x5b1227[_0x22e281(0x106)] === _0x22e281(0xe1)
                    ? "simStatus"
                    : _0x22e281(0xd7),
                payload: { status: _0x5b1227[_0x22e281(0x105)] },
              }));
            break;
          case "GET_QUOTED_BALANCE":
            _0x509847[_0x22e281(0x109)]({
              cmd: _0x22e281(0xa3),
              id: _0x5e46e[_0x22e281(0x112)]["id"],
              quotedBalance: getBalance(
                _0x5b1227[_0x22e281(0xbc)]["pairDetails"][_0x22e281(0x93)],
                _0x5b1227[_0x22e281(0xbc)][_0x22e281(0xc7)]
              ),
            });
            break;
          case "EVENT":
            _0x5e46e[_0x22e281(0x112)][_0x22e281(0x102)]["errorLevel"] &&
              ((_0x5b1227[_0x22e281(0x105)]["error"] =
                _0x5e46e["data"][_0x22e281(0x102)][_0x22e281(0x11c)]),
              (_0x5b1227[_0x22e281(0x105)][_0x22e281(0x98)] =
                _0x5e46e[_0x22e281(0x112)][_0x22e281(0x102)][_0x22e281(0x98)]),
              _0x5afbee({
                type:
                  _0x5b1227[_0x22e281(0x106)] === _0x22e281(0xe1)
                    ? _0x22e281(0xf1)
                    : _0x22e281(0xd7),
                payload: { status: _0x5b1227[_0x22e281(0x105)] },
              }));
            _0x5e46e["data"][_0x22e281(0x102)]["showPopup"] &&
              showInfoDialog(
                _0x5afbee,
                _0x5e46e[_0x22e281(0x112)][_0x22e281(0x102)][_0x22e281(0x98)][
                  _0x22e281(0xad)
                ](),
                _0x5e46e[_0x22e281(0x112)][_0x22e281(0x102)][_0x22e281(0x11c)]
              );
            saveStrategyEvent(
              _0x5b1227[_0x22e281(0x106)],
              _0x5e46e["data"][_0x22e281(0x102)],
              _0x366145
            );
            break;
          case _0x22e281(0xd9):
            (_0x5b1227[_0x22e281(0x105)]["error"] = null),
              (_0x5b1227[_0x22e281(0x105)][_0x22e281(0x98)] = null),
              (_0x5b1227["status"][_0x22e281(0x10d)] = new Date()["getTime"]()),
              _0x5afbee({
                type:
                  _0x5b1227[_0x22e281(0x106)] === "simulation"
                    ? _0x22e281(0xf1)
                    : _0x22e281(0xd7),
                payload: { status: _0x5b1227[_0x22e281(0x105)] },
              });
            break;
          case _0x22e281(0x100):
            let _0x5caf79 = await getExchange(
              _0x5b1227["strategy"][_0x22e281(0xc7)]
            )[_0x22e281(0xc0)]();
            _0x509847["postMessage"]({
              cmd: "UPDATE_PRICES",
              id: _0x5e46e["data"]["id"],
              prices: _0x5caf79,
            });
            break;
          default:
        }
      } finally {
        tradingMutex[_0x22e281(0xe0)]();
      }
    }),
      _0x509847["postMessage"]({
        cmd: _0x142df3(0x116),
        exchange: _0x59473f,
        apiKeys: _0x12ddd7[_0x59473f],
      });
  });
  for (let _0x12f3ac of _0x4b02f6[_0x14943d(0xc3)]) {
    let _0x4e79aa = _0x4b02f6[_0x14943d(0xd3)][_0x12f3ac["id"]];
    if (_0x4e79aa) {
      let _0x235e29 = ![];
      (_0x4e79aa[_0x14943d(0x105)] === _0x14943d(0xef) ||
        _0x4e79aa[_0x14943d(0xb4)]) &&
        ((_0x4e79aa["starting"] = !![]),
        (_0x4e79aa[_0x14943d(0x105)] = _0x14943d(0xb6)),
        (_0x4e79aa["editDate"] = new Date()["getTime"]()),
        await getEcbDatabase()[_0x14943d(0xf9)](_0x4e79aa),
        _0x5afbee({ type: _0x14943d(0xf1), payload: { status: _0x4e79aa } }),
        (_0x235e29 = !![])),
        addTrading(_0x12f3ac, _0x4e79aa, "simulation", _0x235e29);
    }
    let _0x31cfe0 = _0x4b02f6[_0x14943d(0xcc)][_0x12f3ac["id"]];
    if (_0x31cfe0) {
      let _0x4d2c4d = ![];
      _0x5a5f75
        ? (_0x31cfe0[_0x14943d(0x105)] === _0x14943d(0xef) ||
            _0x31cfe0["starting"]) &&
          (delete _0x31cfe0[_0x14943d(0xb4)],
          (_0x31cfe0["status"] = "Stopped"),
          (_0x31cfe0[_0x14943d(0x10d)] = new Date()[_0x14943d(0x88)]()),
          await getEcbDatabase()[_0x14943d(0x81)](_0x31cfe0),
          _0x5afbee({ type: _0x14943d(0xd7), payload: { status: _0x31cfe0 } }))
        : (_0x31cfe0["status"] === _0x14943d(0xef) ||
            _0x31cfe0[_0x14943d(0xb4)]) &&
          ((_0x31cfe0[_0x14943d(0xb4)] = !![]),
          (_0x31cfe0[_0x14943d(0x105)] = _0x14943d(0xb6)),
          (_0x31cfe0[_0x14943d(0x10d)] = new Date()[_0x14943d(0x88)]()),
          await getEcbDatabase()[_0x14943d(0x81)](_0x31cfe0),
          _0x5afbee({ type: "liveStatus", payload: { status: _0x31cfe0 } }),
          (_0x4d2c4d = !![])),
        addTrading(_0x12f3ac, _0x31cfe0, _0x14943d(0x11a), _0x4d2c4d);
    }
  }
}
async function executionReportServiceRestart(_0x49ebb7) {
  const _0x113452 = a0_0x2727;
  Object[_0x113452(0x11f)](tradingWorkers)[_0x113452(0xbd)]((_0x37bb0e) => {
    const _0x344ca4 = _0x113452;
    _0x49ebb7 === _0x37bb0e &&
      tradingWorkers[_0x37bb0e] &&
      tradingWorkers[_0x37bb0e][_0x344ca4(0x109)]({
        cmd: "EXECUTION_REPORT_SERVICE_RESET",
      });
  });
}
async function updateStrategy(_0x30a741) {
  const _0x419ea0 = a0_0x2727;
  try {
    await tradingMutex[_0x419ea0(0xb8)](),
      tradings[_0x30a741["id"]] &&
        (tradings[_0x30a741["id"]][_0x419ea0(0xbc)] = _0x30a741),
      Object[_0x419ea0(0x11f)](tradingWorkers)[_0x419ea0(0xbd)]((_0x37d37d) => {
        const _0x2c4e61 = _0x419ea0;
        _0x30a741[_0x2c4e61(0xc7)] === _0x37d37d &&
          tradingWorkers[_0x37d37d][_0x2c4e61(0x109)]({
            cmd: "UPDATE_STRATEGY",
            strategy: _0x30a741,
          });
      });
  } finally {
    tradingMutex[_0x419ea0(0xe0)]();
  }
}
function getTradingId(_0x543de3, _0x5dd231) {
  return _0x543de3["id"] + "-" + _0x5dd231;
}
function a0_0xf11b() {
  const _0x23647f = [
    "Loading",
    "manualSellAt",
    "lock",
    "additionalSellRulesRange",
    "addRecentBacktest",
    "tradeSize",
    "strategy",
    "forEach",
    "push",
    "completed",
    "getPrices",
    "result",
    "COMPLETED",
    "strategies",
    "openStatus",
    "DETAILS",
    "NEXT_TRADE",
    "exchange",
    "ERROR",
    "cmd",
    "toString",
    "onmessage",
    "liveStatuses",
    "SUBSCRIBE_ORDER_STATUS",
    "closeStatus",
    "origQtyInQuoted",
    "getLiveStrategyTrades",
    "ORDER_STATUS_UPDATE",
    "Backtest\x20Temporarily\x20Unavailable",
    "simStatuses",
    "CANCELED",
    "getAssetBalance",
    "No\x20historical\x20data",
    "liveStatus",
    "orderId",
    "CLEAR_ERROR",
    "getSimStrategyTrades",
    "OPEN_TRADE",
    "optimization.worker.js",
    "MANUAL_SELL",
    "addRecentOptimization",
    "7375929mRwGfu",
    "release",
    "simulation",
    "sellSizeInQuoted",
    "HmacSHA256",
    "START_TRADING",
    "SIGNED",
    "resultInQuoted",
    "getSimStrategyDetails",
    "Trading\x20Temporarily\x20Unavailable",
    "contestMonth",
    "filter",
    "CANCEL_UNFILLED_ORDER",
    "details",
    "COMMISSION_RATE",
    "dateFrom",
    "Running",
    "CLOSE_TRADE",
    "simStatus",
    "includes",
    "conditionPair",
    "toDelete",
    "75992glQQOC",
    "1351xkPAVP",
    "then",
    "\x20could\x20not\x20be\x20performed\x20as\x20",
    "storeSimStrategyStatus",
    "cancelGetHistoricalData",
    "TRADING_STARTED",
    "26830dvrduA",
    "removeRecentOptimization",
    "deleteBacktest",
    "eta",
    "GET_PRICES",
    "Backtest\x20of\x20",
    "event",
    "terminate",
    "deleteOptimization",
    "status",
    "type",
    "STATUS_DETAILS",
    "\x20didn\x27t\x20return\x20the\x20historical\x20data\x20for\x20the\x20choosen\x20period.",
    "postMessage",
    "STOP_TRADING",
    "getSeconds",
    "TRADING_STOPPED",
    "editDate",
    "conditionValue",
    "Binance",
    "name",
    "strategyLiveDetailsNotSync",
    "data",
    "dateTo",
    "FILLED",
    "buy",
    "INITIALIZE",
    "storeSimStrategyDetails",
    "UPDATE_COMPLETED",
    "downloaded",
    "trading",
    "commissionRate",
    "content",
    "max",
    "removeLiveStatus",
    "keys",
    "nextTrade",
    "free",
    "lastTrade",
    "storeLiveStrategyDetails",
    "FILLED_WITH_LEFTOVER",
    "427630jUFnwB",
    "storeLiveStrategyStatus",
    "3739824GZADNc",
    "conditionDirection",
    "error",
    "removeSimStatus",
    "activeTrade",
    "909CgLFaV",
    "getTime",
    "1\x20minute",
    "min",
    "resultId",
    "manualBuyAt",
    "tradesCount",
    "pairDetails",
    "getHistoricalData",
    "PARTIALLY_FILLED",
    "unsubscribeToOrderUpdate",
    "2291315zLxzWW",
    "quoteAsset",
    "29oGSWTe",
    "Optimization\x20of\x20",
    "45822UmEUJY",
    "Optimization\x20Temporarily\x20Unavailable",
    "errorLevel",
    "NEW",
    "Stopped",
    "worker",
    "floor",
    "setStatusLoading",
    "totalReturn",
    "binance.worker.js",
    "SIGN",
    "bt-",
    "156IFyyIu",
    "UPDATE_QUOTED_BALANCE",
    "random",
    "Restart\x20the\x20app\x20or\x20try\x20again\x20later.",
    "pair",
    "trade",
    "setSeconds",
    "addOptimizationLimit",
    "opt-",
    "backtest.worker.js",
    "10XFHCqR",
    "toUpperCase",
    "subscribeToOrderUpdate",
    "length",
    "4LGTKrG",
    "ADD_EXECUTION",
    "skipedRules",
    "\x20didn\x27t\x20return\x20the\x20historical\x20data\x20for\x20the\x20choosen\x20period.\x20Please\x20try\x20again\x20later.",
    "starting",
    "\x20could\x20not\x20be\x20performed.\x20Please\x20try\x20later.",
  ];
  a0_0xf11b = function () {
    return _0x23647f;
  };
  return a0_0xf11b();
}
async function addTrading(_0x2d7960, _0x31376c, _0x3a8f7b, _0x1bdb2d) {
  const _0x5f34d3 = a0_0x2727;
  try {
    if (!_0x2d7960 || !_0x31376c) return;
    await tradingMutex["lock"]();
    let _0x3ad5db =
        _0x3a8f7b === _0x5f34d3(0xe1)
          ? await getEcbDatabase()[_0x5f34d3(0xda)](_0x2d7960["id"])
          : await getEcbDatabase()[_0x5f34d3(0xd0)](_0x2d7960["id"]),
      _0x1643ea =
        _0x3a8f7b === _0x5f34d3(0xe1)
          ? await getEcbDatabase()[_0x5f34d3(0xe7)](_0x2d7960["id"])
          : await getEcbDatabase()["getLiveStrategyDetails"](_0x2d7960["id"]);
    !_0x1643ea && (_0x1643ea = { id: _0x2d7960["id"] }),
      (tradings[getTradingId(_0x2d7960, _0x3a8f7b)] = {
        strategy: _0x2d7960,
        status: _0x31376c,
        details: _0x1643ea,
        type: _0x3a8f7b,
      }),
      tradingWorkers[_0x2d7960[_0x5f34d3(0xc7)]]["postMessage"]({
        cmd: _0x5f34d3(0xb1),
        id: getTradingId(_0x2d7960, _0x3a8f7b),
        strategy: _0x2d7960,
        status: _0x31376c,
        trades: _0x3ad5db,
        details: _0x1643ea,
        type: _0x3a8f7b,
      }),
      _0x1bdb2d &&
        tradingWorkers[_0x2d7960[_0x5f34d3(0xc7)]][_0x5f34d3(0x109)]({
          cmd: "START_TRADING",
          id: getTradingId(_0x2d7960, _0x3a8f7b),
        });
  } finally {
    tradingMutex[_0x5f34d3(0xe0)]();
  }
}
async function startTrading(
  _0x1c7b8e,
  _0x311d46,
  _0x1f7b95,
  _0xe3514a,
  _0x2545c0
) {
  const _0x81aa5 = a0_0x2727;
  try {
    if (!_0x1c7b8e) return;
    if (_0x2545c0) {
      (_0x311d46[_0x81aa5(0x105)] = _0x81aa5(0xef)),
        _0xe3514a({
          type: _0x1f7b95 === _0x81aa5(0xe1) ? _0x81aa5(0xf1) : _0x81aa5(0xd7),
          payload: { status: _0x311d46 },
        });
      return;
    }
    await tradingMutex[_0x81aa5(0xb8)](),
      _0xe3514a &&
        (_0xe3514a({
          type: _0x81aa5(0x9d),
          payload: { strategy: _0x1c7b8e, type: _0x1f7b95 },
        }),
        _0xe3514a({
          type: _0x81aa5(0x9d),
          payload: { strategy: _0x1c7b8e, type: _0x1f7b95 },
        })),
      tradings[getTradingId(_0x1c7b8e, _0x1f7b95)]
        ? tradingWorkers[_0x1c7b8e["exchange"]][_0x81aa5(0x109)]({
            cmd: _0x81aa5(0xe4),
            id: getTradingId(_0x1c7b8e, _0x1f7b95),
          })
        : addTrading(_0x1c7b8e, _0x311d46, _0x1f7b95, !![]);
  } finally {
    tradingMutex[_0x81aa5(0xe0)]();
  }
}
async function stopTrading(
  _0x308571,
  _0x3765ce,
  _0x396354,
  _0x1cb788,
  _0x1740e4
) {
  const _0x21df88 = a0_0x2727;
  try {
    if (_0x1cb788) {
      _0x1740e4 &&
        ((_0x1740e4[_0x21df88(0x105)] = _0x21df88(0x9a)),
        _0x396354({
          type: _0x3765ce === _0x21df88(0xe1) ? "simStatus" : _0x21df88(0xd7),
          payload: { status: _0x1740e4 },
        }));
      return;
    }
    await tradingMutex[_0x21df88(0xb8)](),
      tradings[getTradingId(_0x308571, _0x3765ce)] &&
        (_0x396354 &&
          _0x396354({
            type: _0x21df88(0x9d),
            payload: { strategy: _0x308571, type: _0x3765ce },
          }),
        tradingWorkers[_0x308571[_0x21df88(0xc7)]][_0x21df88(0x109)]({
          cmd: _0x21df88(0x10a),
          id: getTradingId(_0x308571, _0x3765ce),
        }));
  } finally {
    tradingMutex[_0x21df88(0xe0)]();
  }
}
async function removeTrading(
  _0x3b33ee,
  _0xc6083a,
  _0x377371,
  _0x2ad0bb,
  _0xe8c89e
) {
  const _0x415856 = a0_0x2727;
  if (_0xe8c89e) {
    _0x2ad0bb({
      type: _0x377371 === _0x415856(0xe1) ? _0x415856(0x85) : _0x415856(0x11e),
      payload: _0x3b33ee["id"],
    });
    return;
  }
  try {
    if (!_0xc6083a) return;
    await tradingMutex[_0x415856(0xb8)]();
    let _0x239ea3 = getTradingId(_0x3b33ee, _0x377371);
    if (tradings[_0x239ea3]) {
      let _0x280870 = tradings[_0x239ea3]["strategy"][_0x415856(0xc7)];
      delete tradings[_0x239ea3],
        tradingWorkers[_0x280870][_0x415856(0x109)]({
          cmd: "REMOVE_EXECUTION",
          id: _0x239ea3,
        });
    }
    _0x2ad0bb({
      type: _0x377371 === _0x415856(0xe1) ? _0x415856(0x85) : _0x415856(0x11e),
      payload: _0x3b33ee["id"],
    });
  } finally {
    tradingMutex[_0x415856(0xe0)]();
  }
}
async function manualSell(_0x293919, _0x4025d9, _0x5d8aa2, _0x46a522) {
  const _0x3ad76a = a0_0x2727;
  if (_0x46a522) return;
  try {
    await tradingMutex[_0x3ad76a(0xb8)](),
      tradings[getTradingId(_0x293919, _0x4025d9)] &&
        ((tradings[getTradingId(_0x293919, _0x4025d9)][_0x3ad76a(0x105)][
          _0x3ad76a(0xb7)
        ] = _0x5d8aa2),
        tradingWorkers[_0x293919[_0x3ad76a(0xc7)]][_0x3ad76a(0x109)]({
          cmd: _0x3ad76a(0xdd),
          id: getTradingId(_0x293919, _0x4025d9),
        }));
  } finally {
    tradingMutex[_0x3ad76a(0xe0)]();
  }
}
async function manualBuy(
  _0x18d2d5,
  _0x309617,
  _0x3f4d0b,
  _0xfbf90d,
  _0x565155,
  _0x4cc18f
) {
  const _0x7b1c84 = a0_0x2727;
  if (_0x4cc18f) return;
  try {
    await tradingMutex[_0x7b1c84(0xb8)]();
    if (tradings[getTradingId(_0x18d2d5, _0x309617)]) {
      tradings[getTradingId(_0x18d2d5, _0x309617)][_0x7b1c84(0x105)][
        _0x7b1c84(0x8c)
      ] = _0x565155;
      if (!_0xfbf90d) {
        let _0x20d5e3 = await getExchange(_0x18d2d5[_0x7b1c84(0xc7)])[
          _0x7b1c84(0xc0)
        ]();
        _0xfbf90d = _0x20d5e3[_0x18d2d5[_0x7b1c84(0xa6)]];
      }
      tradingWorkers[_0x18d2d5["exchange"]][_0x7b1c84(0x109)]({
        cmd: "MANUAL_BUY",
        id: getTradingId(_0x18d2d5, _0x309617),
        type: _0x3f4d0b,
        price: _0xfbf90d,
      });
    }
  } finally {
    tradingMutex["release"]();
  }
}
const backtests = {},
  backtestSubscribers = {},
  backtestMutex = new Mutex();
async function startBacktest(_0x2e577c, _0x4d48fe, _0x3919ee, _0x2cc2b1) {
  const _0x11a6e8 = a0_0x2727;
  await stopBacktest(_0x2e577c["id"], _0x3919ee),
    (backtests[_0x2e577c["id"]] = {
      strategy: _0x2e577c,
      params: _0x4d48fe,
      completed: 0x0,
      downloaded: 0x0,
      result: {},
    }),
    _0x3919ee({ type: _0x11a6e8(0xba), payload: _0x2e577c["id"] }),
    await updateBacktestsSubscibers();
  let _0x3022a6 = await initializeWorker(
    backtests[_0x2e577c["id"]],
    _0x11a6e8(0x9b),
    _0x11a6e8(0xab)
  );
  if (!_0x3022a6) {
    stopBacktest(_0x2e577c["id"], _0x3919ee),
      showInfoDialog(
        _0x3919ee,
        _0x11a6e8(0xd2),
        _0x11a6e8(0x101) + _0x2e577c[_0x11a6e8(0x110)] + _0x11a6e8(0xb5)
      );
    return;
  }
  let _0x231aa7 = getTimeframes(_0x2e577c);
  !_0x231aa7["includes"](_0x11a6e8(0x89)) &&
    _0x231aa7[_0x11a6e8(0xbe)](_0x11a6e8(0x89));
  let _0x5f3a68 = new Date(),
    _0x4486af = Math[_0x11a6e8(0xa4)]() * 0xa + 0x3c,
    _0x2ec34b = await getExchange(_0x2e577c["exchange"])[_0x11a6e8(0x8f)](
      _0x11a6e8(0xa1) + _0x2e577c["id"],
      _0x2e577c["pair"],
      _0x231aa7,
      _0x4d48fe[_0x11a6e8(0xee)],
      _0x4d48fe[_0x11a6e8(0x113)],
      async (_0x3e84ed) => {
        const _0x21fa78 = _0x11a6e8;
        try {
          if (_0x3e84ed === 0x0) return;
          await backtestMutex[_0x21fa78(0xb8)]();
          if (!backtests[_0x2e577c["id"]]) return;
          backtests[_0x2e577c["id"]][_0x21fa78(0x119)] =
            _0x2e577c["conditionPair"] &&
            _0x2e577c[_0x21fa78(0xf3)] !== _0x2e577c[_0x21fa78(0xa6)]
              ? _0x4486af * (Math[_0x21fa78(0x8a)](0x64, _0x3e84ed) / 0x64)
              : Math[_0x21fa78(0x8a)](0x64, _0x3e84ed);
          let _0x1514a8 = new Date();
          _0x1514a8[_0x21fa78(0xa8)](_0x1514a8["getSeconds"]() - 0x1),
            _0x1514a8 > _0x5f3a68 &&
              ((_0x5f3a68 = new Date()), updateBacktestsSubscibers());
        } finally {
          backtestMutex["release"]();
        }
      },
      ![],
      _0x2cc2b1
    );
  if (!backtests[_0x2e577c["id"]]) return;
  let _0x1eb37d = ![];
  _0x2ec34b
    ? Object[_0x11a6e8(0x11f)](_0x2ec34b)[_0x11a6e8(0xbd)]((_0x31c8d1) => {
        (!_0x2ec34b[_0x31c8d1] || _0x2ec34b[_0x31c8d1]["length"] === 0x0) &&
          (_0x1eb37d = !![]);
      })
    : (_0x1eb37d = !![]);
  if (_0x1eb37d) {
    _0x3919ee &&
      showInfoDialog(
        _0x3919ee,
        _0x11a6e8(0xd6),
        _0x11a6e8(0x101) +
          _0x2e577c[_0x11a6e8(0x110)] +
          "\x20could\x20not\x20be\x20performed\x20as\x20" +
          _0x2e577c[_0x11a6e8(0xc7)] +
          _0x11a6e8(0x108)
      );
    stopBacktest(_0x2e577c["id"], _0x3919ee);
    return;
  }
  let _0x4e8efb = null;
  if (
    _0x2e577c[_0x11a6e8(0xf3)] &&
    _0x2e577c[_0x11a6e8(0xf3)] !== _0x2e577c["pair"]
  ) {
    let _0x35c75b = ["1\x20minute"];
    (_0x5f3a68 = new Date()),
      (_0x4e8efb = await getExchange(_0x2e577c[_0x11a6e8(0xc7)])[
        _0x11a6e8(0x8f)
      ](
        "bt-" + _0x2e577c["id"],
        _0x2e577c[_0x11a6e8(0xf3)],
        _0x35c75b,
        _0x4d48fe[_0x11a6e8(0xee)],
        _0x4d48fe[_0x11a6e8(0x113)],
        async (_0x24d19a) => {
          const _0x384604 = _0x11a6e8;
          try {
            if (_0x24d19a === 0x0) return;
            await backtestMutex["lock"]();
            if (!backtests[_0x2e577c["id"]]) return;
            backtests[_0x2e577c["id"]][_0x384604(0x119)] = Math[
              _0x384604(0x8a)
            ](
              0x64,
              _0x4486af +
                (0x64 - _0x4486af) *
                  (Math[_0x384604(0x8a)](0x64, _0x24d19a) / 0x64)
            );
            let _0x701ad0 = new Date();
            _0x701ad0[_0x384604(0xa8)](_0x701ad0["getSeconds"]() - 0x1),
              _0x701ad0 > _0x5f3a68 &&
                ((_0x5f3a68 = new Date()), updateBacktestsSubscibers());
          } finally {
            backtestMutex[_0x384604(0xe0)]();
          }
        },
        ![],
        _0x2cc2b1
      ));
    if (!backtests[_0x2e577c["id"]]) return;
    if (
      !_0x4e8efb ||
      !_0x4e8efb[_0x35c75b] ||
      _0x4e8efb[_0x35c75b]["length"] === 0x0
    ) {
      _0x3919ee &&
        showInfoDialog(
          _0x3919ee,
          _0x11a6e8(0xd6),
          _0x11a6e8(0x101) +
            _0x2e577c[_0x11a6e8(0x110)] +
            "\x20could\x20not\x20be\x20performed\x20as\x20" +
            _0x2e577c["exchange"] +
            _0x11a6e8(0x108)
        );
      stopBacktest(_0x2e577c["id"], _0x3919ee);
      return;
    } else _0x4e8efb = _0x4e8efb[_0x35c75b];
  } else
    _0x4e8efb = _0x2ec34b[_0x11a6e8(0x89)]["map"]((_0x18a4c7) => ({
      ..._0x18a4c7,
    }));
  try {
    await backtestMutex[_0x11a6e8(0xb8)]();
    if (!backtests[_0x2e577c["id"]] || !backtests[_0x2e577c["id"]]["worker"])
      return;
    let _0x109e20 = 0x0;
    (backtests[_0x2e577c["id"]][_0x11a6e8(0x9b)]["onmessage"] = async (
      _0x391082
    ) => {
      const _0x45f8df = _0x11a6e8;
      try {
        await backtestMutex[_0x45f8df(0xb8)]();
        if (
          !backtests[_0x2e577c["id"]] ||
          !backtests[_0x2e577c["id"]][_0x45f8df(0x9b)]
        )
          return;
        switch (_0x391082[_0x45f8df(0x112)][_0x45f8df(0xc9)]) {
          case _0x45f8df(0x118):
            if (_0x109e20 < _0x391082["data"]["completed"]) {
              (_0x109e20 = _0x391082[_0x45f8df(0x112)]["completed"]),
                (backtests[_0x2e577c["id"]]["completed"] =
                  Math[_0x45f8df(0x9c)](_0x109e20));
              let _0x749427 = new Date();
              _0x749427[_0x45f8df(0xa8)](_0x749427[_0x45f8df(0x10b)]() - 0x1),
                _0x749427 > _0x5f3a68 &&
                  ((_0x5f3a68 = new Date()), updateBacktestsSubscibers());
            }
            break;
          case "COMPLETED":
            (backtests[_0x2e577c["id"]][_0x45f8df(0xbf)] = Math[
              _0x45f8df(0x11d)
            ](backtests[_0x2e577c["id"]][_0x45f8df(0xbf)], 0x63)),
              (backtests[_0x2e577c["id"]][_0x45f8df(0x8b)] =
                _0x391082[_0x45f8df(0x112)][_0x45f8df(0x8b)]),
              updateBacktestsSubscibers(),
              (backtests[_0x2e577c["id"]][_0x45f8df(0xbf)] = 0x64),
              updateBacktestsSubscibers(),
              backtests[_0x2e577c["id"]][_0x45f8df(0x9b)][_0x45f8df(0x103)](),
              delete backtests[_0x2e577c["id"]]["worker"],
              (backtests[_0x2e577c["id"]]["toDelete"] = !![]),
              sleep(0x2710)[_0x45f8df(0xf7)](() => {
                const _0x543f30 = _0x45f8df;
                backtests[_0x2e577c["id"]] &&
                  backtests[_0x2e577c["id"]][_0x543f30(0xf4)] &&
                  (delete backtests[_0x2e577c["id"]],
                  updateBacktestsSubscibers());
              });
            break;
          case "ERROR":
            stopBacktest(_0x2e577c["id"], _0x3919ee);
            break;
          default:
        }
      } finally {
        backtestMutex[_0x45f8df(0xe0)]();
      }
    }),
      (backtests[_0x2e577c["id"]]["downloaded"] = 0x64),
      updateBacktestsSubscibers(),
      (_0x5f3a68 = new Date()),
      backtests[_0x2e577c["id"]][_0x11a6e8(0x9b)]["postMessage"]({
        cmd: "START",
        strategy: _0x2e577c,
        historicalData: _0x2ec34b,
        conditionHistoricalData: _0x4e8efb,
        dateFrom: _0x4d48fe["dateFrom"],
        dateTo: _0x4d48fe[_0x11a6e8(0x113)],
        commissionRate: _0x4d48fe[_0x11a6e8(0x11b)],
        addChartData: !![],
        tradeSize: _0x4d48fe[_0x11a6e8(0xbb)],
        params: _0x4d48fe,
      });
  } finally {
    backtestMutex[_0x11a6e8(0xe0)]();
  }
}
async function updateBacktestsSubscibers() {
  const _0x6484c1 = a0_0x2727;
  try {
    await backtestMutex[_0x6484c1(0xb8)](),
      Object[_0x6484c1(0x11f)](backtestSubscribers)[_0x6484c1(0xbd)](
        (_0xc03794) => backtestSubscribers[_0xc03794]({ ...backtests })
      );
  } finally {
    backtestMutex[_0x6484c1(0xe0)]();
  }
}
async function stopBacktest(_0x379b57, _0x57c424) {
  const _0x2208f0 = a0_0x2727;
  try {
    await backtestMutex["lock"](),
      backtests[_0x379b57] &&
        (_0x57c424 &&
          _0x57c424({
            type: "removeRecentBacktest",
            payload: backtests[_0x379b57][_0x2208f0(0xbc)]["id"],
          }),
        getEcbDatabase()[_0x2208f0(0xfe)](_0x379b57),
        getExchange(backtests[_0x379b57][_0x2208f0(0xbc)][_0x2208f0(0xc7)])[
          _0x2208f0(0xfa)
        ](_0x2208f0(0xa1) + backtests[_0x379b57][_0x2208f0(0xbc)]["id"]),
        backtests[_0x379b57][_0x2208f0(0x9b)] &&
          (backtests[_0x379b57][_0x2208f0(0x9b)][_0x2208f0(0x103)](),
          delete backtests[_0x379b57][_0x2208f0(0x9b)]),
        delete backtests[_0x379b57],
        updateBacktestsSubscibers());
  } finally {
    backtestMutex[_0x2208f0(0xe0)]();
  }
}
function a0_0x2727(_0x538d8f, _0x325c9a) {
  const _0xf11bf = a0_0xf11b();
  return (
    (a0_0x2727 = function (_0x272785, _0x4384b2) {
      _0x272785 = _0x272785 - 0x7f;
      let _0x5cb96a = _0xf11bf[_0x272785];
      return _0x5cb96a;
    }),
    a0_0x2727(_0x538d8f, _0x325c9a)
  );
}
function subscribeForBacktests(_0xae8ac9, _0x482ca9) {
  backtestSubscribers[_0xae8ac9] = _0x482ca9;
}
function unsubscribeForBacktests(_0x23dd9f) {
  delete backtestSubscribers[_0x23dd9f];
}
function getBacktests() {
  return backtests;
}
const optimizations = {},
  optimizationsIndexStatus = {},
  optimizationSubscribers = {},
  optimizationMutex = new Mutex();
let runningOptimizationId = null,
  optimizationsQueue = [];
async function startOptimization(
  _0x1223a8,
  _0xc8d85f,
  _0x12e8ed,
  _0x5af27b,
  _0x7eaed1,
  _0x37d7eb,
  _0x5781ef,
  _0x3b4d00,
  _0x29f89f,
  _0x3f34f5,
  _0x14b9e2
) {
  const _0x4e4188 = a0_0x2727;
  try {
    await stopOptimization(_0x1223a8["id"], _0x3f34f5),
      await optimizationMutex["lock"]();
    if (optimizations[_0x1223a8["id"]]) return;
    _0x3f34f5({ type: _0x4e4188(0xde), payload: _0x1223a8["id"] }),
      (optimizations[_0x1223a8["id"]] = {
        strategy: _0x1223a8,
        dateFrom: _0xc8d85f,
        dateTo: _0x12e8ed,
        commissionRate: _0x5af27b,
        additionalSellRules: _0x5781ef,
        additionalSellRulesRange: _0x3b4d00,
        skipedRules: _0x29f89f,
        minTrades: _0x7eaed1,
        tradeSize: _0x37d7eb,
        completed: -0x1,
        downloaded: 0x0,
        eta: "",
        iteration: 0x1,
        strategyQueue: [],
        results: { maxReturn: [], riskReward: [], consistentReturn: [] },
        contestMonth: _0x14b9e2,
      });
    optimizationsIndexStatus[_0x1223a8["id"]]
      ? optimizationsIndexStatus[_0x1223a8["id"]]++
      : (optimizationsIndexStatus[_0x1223a8["id"]] = 0x1);
    updateOptimizationSubscibers();
    let _0x3e0962 = await initializeWorker(
      optimizations[_0x1223a8["id"]],
      _0x4e4188(0x9b),
      _0x4e4188(0xdc)
    );
    if (!_0x3e0962) {
      stopOptimization(_0x1223a8["id"], _0x3f34f5),
        showInfoDialog(
          _0x3f34f5,
          _0x4e4188(0x97),
          _0x4e4188(0x95) + _0x1223a8["name"] + _0x4e4188(0xb5)
        );
      return;
    }
    executeOptimization(_0x1223a8["id"], _0x3f34f5);
  } finally {
    optimizationMutex[_0x4e4188(0xe0)]();
  }
}
async function stopOptimization(_0x4e9dee, _0x265731) {
  const _0x4916bb = a0_0x2727;
  try {
    optimizations[_0x4e9dee] &&
      (_0x265731({
        type: _0x4916bb(0xfd),
        payload: optimizations[_0x4e9dee][_0x4916bb(0xbc)]["id"],
      }),
      getExchange(optimizations[_0x4e9dee][_0x4916bb(0xbc)][_0x4916bb(0xc7)])[
        _0x4916bb(0xfa)
      ](_0x4916bb(0xaa) + optimizations[_0x4e9dee]["strategy"]["id"])),
      await optimizationMutex[_0x4916bb(0xb8)](),
      optimizations[_0x4e9dee] &&
        (getEcbDatabase()[_0x4916bb(0x104)](_0x4e9dee),
        getExchange(optimizations[_0x4e9dee][_0x4916bb(0xbc)][_0x4916bb(0xc7)])[
          "cancelGetHistoricalData"
        ](_0x4916bb(0xaa) + optimizations[_0x4e9dee]["strategy"]["id"]),
        optimizations[_0x4e9dee]["worker"] &&
          (optimizations[_0x4e9dee][_0x4916bb(0x9b)][_0x4916bb(0x103)](),
          (optimizations[_0x4e9dee][_0x4916bb(0x9b)] = null)),
        (optimizationsQueue = optimizationsQueue[_0x4916bb(0xea)](
          (_0x32d7a3) => _0x32d7a3 !== _0x4e9dee
        )),
        delete optimizations[_0x4e9dee],
        updateOptimizationSubscibers(),
        runningOptimizationId === _0x4e9dee &&
          optimizationFinishes(_0x4e9dee, _0x265731));
  } catch (_0x536a89) {
  } finally {
    optimizationMutex[_0x4916bb(0xe0)]();
  }
}
async function optimizationFinishes(_0x332ff1, _0x48d283) {
  const _0x22861f = a0_0x2727;
  try {
    await optimizationMutex[_0x22861f(0xb8)]();
    if (runningOptimizationId === _0x332ff1) {
      runningOptimizationId = null;
      if (optimizationsQueue["length"] > 0x0) {
        let _0x112aa4 = optimizationsQueue["splice"](0x0, 0x1)[0x0];
        executeOptimization(_0x112aa4, _0x48d283);
      }
    }
  } finally {
    optimizationMutex["release"]();
  }
}
async function executeOptimization(_0x4fed11, _0x2e6a02) {
  const _0x2eed6a = a0_0x2727;
  let _0x566cdf = null;
  try {
    await optimizationMutex[_0x2eed6a(0xb8)]();
    if (runningOptimizationId) {
      optimizationsQueue[_0x2eed6a(0xbe)](_0x4fed11);
      return;
    }
    _0x566cdf = optimizationsIndexStatus[_0x4fed11];
    if (!optimizations[_0x4fed11]) return;
    (runningOptimizationId = _0x4fed11),
      (optimizations[_0x4fed11][_0x2eed6a(0xbf)] = 0x0),
      updateOptimizationSubscibers();
  } finally {
    optimizationMutex[_0x2eed6a(0xe0)]();
  }
  let _0x514fef =
      optimizations[_0x4fed11][_0x2eed6a(0xbc)]["conditionPair"] &&
      optimizations[_0x4fed11][_0x2eed6a(0xbc)][_0x2eed6a(0x10e)] > 0x0 &&
      optimizations[_0x4fed11]["strategy"][_0x2eed6a(0x83)] !==
        conditionDirections[0x0],
    _0x6a69f2 = Math["random"]() * 0xa + 0x50,
    _0x2f561d = getTimeframes(optimizations[_0x4fed11][_0x2eed6a(0xbc)]);
  !_0x2f561d[_0x2eed6a(0xf2)](_0x2eed6a(0x89)) &&
    _0x2f561d[_0x2eed6a(0xbe)]("1\x20minute");
  let _0x381d71 = new Date(),
    _0x3d84af = await getExchange(
      optimizations[_0x4fed11]["strategy"]["exchange"]
    )[_0x2eed6a(0x8f)](
      "opt-" + optimizations[_0x4fed11][_0x2eed6a(0xbc)]["id"],
      optimizations[_0x4fed11][_0x2eed6a(0xbc)]["pair"],
      _0x2f561d,
      optimizations[_0x4fed11]["dateFrom"],
      optimizations[_0x4fed11][_0x2eed6a(0x113)],
      async (_0x3b5342) => {
        const _0x14730a = _0x2eed6a;
        try {
          if (!optimizations[_0x4fed11]) return;
          optimizations[_0x4fed11]["downloaded"] =
            _0x514fef &&
            optimizations[_0x4fed11][_0x14730a(0xbc)]["conditionPair"] !==
              optimizations[_0x4fed11]["strategy"][_0x14730a(0xa6)]
              ? _0x6a69f2 * (Math[_0x14730a(0x8a)](0x64, _0x3b5342) / 0x64)
              : Math[_0x14730a(0x8a)](0x64, _0x3b5342);
          let _0x1b0f36 = new Date();
          _0x1b0f36[_0x14730a(0xa8)](_0x1b0f36[_0x14730a(0x10b)]() - 0x1),
            _0x1b0f36 > _0x381d71 &&
              ((_0x381d71 = new Date()), updateOptimizationSubscibers());
        } catch (_0x21dada) {}
      },
      ![],
      optimizations[_0x4fed11][_0x2eed6a(0xe9)]
    );
  if (_0x566cdf !== optimizationsIndexStatus[_0x4fed11]) return;
  let _0x27bdea = ![];
  _0x3d84af
    ? Object[_0x2eed6a(0x11f)](_0x3d84af)["forEach"]((_0x19a460) => {
        const _0x523ad6 = _0x2eed6a;
        (!_0x3d84af[_0x19a460] ||
          _0x3d84af[_0x19a460][_0x523ad6(0xaf)] === 0x0) &&
          (_0x27bdea = !![]);
      })
    : (_0x27bdea = !![]);
  let _0x4237e9 = null;
  if (!_0x27bdea) {
    if (
      _0x514fef &&
      optimizations[_0x4fed11][_0x2eed6a(0xbc)][_0x2eed6a(0xf3)] !==
        optimizations[_0x4fed11][_0x2eed6a(0xbc)]["pair"]
    ) {
      let _0x51e98e = "1\x20minute";
      _0x4237e9 = await getExchange(
        optimizations[_0x4fed11][_0x2eed6a(0xbc)]["exchange"]
      )[_0x2eed6a(0x8f)](
        _0x2eed6a(0xaa) + optimizations[_0x4fed11]["strategy"]["id"],
        optimizations[_0x4fed11][_0x2eed6a(0xbc)]["conditionPair"],
        [_0x51e98e],
        optimizations[_0x4fed11][_0x2eed6a(0xee)],
        optimizations[_0x4fed11][_0x2eed6a(0x113)],
        async (_0x20bd90) => {
          const _0x14f880 = _0x2eed6a;
          try {
            if (_0x20bd90 === 0x0) return;
            if (!optimizations[_0x4fed11]) return;
            optimizations[_0x4fed11][_0x14f880(0x119)] = Math[_0x14f880(0x8a)](
              0x64,
              _0x6a69f2 +
                (0x64 - _0x6a69f2) * (Math["min"](0x64, _0x20bd90) / 0x64)
            );
            let _0x4ec7d6 = new Date();
            _0x4ec7d6["setSeconds"](_0x4ec7d6[_0x14f880(0x10b)]() - 0x1),
              _0x4ec7d6 > _0x381d71 &&
                ((_0x381d71 = new Date()), updateOptimizationSubscibers());
          } catch (_0x451a26) {}
        },
        ![],
        optimizations[_0x4fed11][_0x2eed6a(0xe9)]
      );
      if (_0x566cdf !== optimizationsIndexStatus[_0x4fed11]) return;
      !_0x4237e9 ||
      !_0x4237e9[_0x51e98e] ||
      _0x4237e9[_0x51e98e]["length"] === 0x0
        ? (_0x27bdea = !![])
        : (_0x4237e9 = _0x4237e9[_0x51e98e]);
    } else
      _0x4237e9 = _0x3d84af["1\x20minute"]["map"]((_0x5228e3) => ({
        ..._0x5228e3,
      }));
  }
  if (_0x27bdea) {
    optimizations[_0x4fed11]
      ? (_0x2e6a02 &&
          showInfoDialog(
            _0x2e6a02,
            _0x2eed6a(0xd6),
            _0x2eed6a(0x95) +
              optimizations[_0x4fed11]["strategy"][_0x2eed6a(0x110)] +
              _0x2eed6a(0xf8) +
              optimizations[_0x4fed11][_0x2eed6a(0xbc)][_0x2eed6a(0xc7)] +
              _0x2eed6a(0xb3)
          ),
        stopOptimization(_0x4fed11, _0x2e6a02))
      : optimizationFinishes(_0x4fed11, _0x2e6a02);
    return;
  }
  try {
    optimizations[_0x4fed11] &&
    _0x566cdf === optimizationsIndexStatus[_0x4fed11]
      ? ((optimizations[_0x4fed11][_0x2eed6a(0x9b)][_0x2eed6a(0xcb)] = async (
          _0x28a07d
        ) => {
          const _0x3cf213 = _0x2eed6a;
          if (!optimizations[_0x4fed11]) return;
          try {
            await optimizationMutex[_0x3cf213(0xb8)]();
            switch (_0x28a07d["data"][_0x3cf213(0xc9)]) {
              case _0x3cf213(0x118):
                (optimizations[_0x4fed11][_0x3cf213(0xbf)] = Math[
                  _0x3cf213(0x8a)
                ](
                  0x63,
                  Math[_0x3cf213(0x9c)](
                    _0x28a07d[_0x3cf213(0x112)][_0x3cf213(0xbf)]
                  )
                )),
                  (optimizations[_0x4fed11]["eta"] =
                    _0x28a07d[_0x3cf213(0x112)]["eta"]);
                let _0x40e34f = new Date();
                _0x40e34f[_0x3cf213(0xa8)](_0x40e34f[_0x3cf213(0x10b)]() - 0x1);
                _0x40e34f > _0x381d71 &&
                  ((_0x381d71 = new Date()), updateOptimizationSubscibers());
                break;
              case _0x3cf213(0xc8):
                stopOptimization(_0x4fed11, _0x2e6a02);
                break;
              case _0x3cf213(0xc2):
                optimizations[_0x4fed11][_0x3cf213(0x9b)][_0x3cf213(0x103)](),
                  delete optimizations[_0x4fed11][_0x3cf213(0x9b)],
                  (optimizations[_0x4fed11][_0x3cf213(0xbf)] = Math[
                    _0x3cf213(0x11d)
                  ](optimizations[_0x4fed11][_0x3cf213(0xbf)], 0x63)),
                  updateOptimizationSubscibers(),
                  (optimizations[_0x4fed11][_0x3cf213(0xbf)] = 0x64),
                  (optimizations[_0x4fed11][_0x3cf213(0xff)] = ""),
                  (optimizations[_0x4fed11][_0x3cf213(0x8b)] =
                    _0x28a07d[_0x3cf213(0x112)][_0x3cf213(0x8b)]);
                _0x28a07d[_0x3cf213(0x112)][_0x3cf213(0x9e)] > 0x0 &&
                  _0x2e6a02({ type: _0x3cf213(0xa9), payload: "" });
                updateOptimizationSubscibers(),
                  optimizationFinishes(_0x4fed11, _0x2e6a02),
                  (optimizations[_0x4fed11]["toDelete"] = !![]),
                  sleep(0x2710)[_0x3cf213(0xf7)](() => {
                    const _0x2f9163 = _0x3cf213;
                    optimizations[_0x4fed11] &&
                      optimizations[_0x4fed11][_0x2f9163(0xf4)] &&
                      (delete optimizations[_0x4fed11],
                      updateOptimizationSubscibers());
                  });
                break;
              default:
            }
          } finally {
            optimizationMutex[_0x3cf213(0xe0)]();
          }
        }),
        (optimizations[_0x4fed11][_0x2eed6a(0x119)] = 0x64),
        updateOptimizationSubscibers(),
        optimizations[_0x4fed11][_0x2eed6a(0x9b)][_0x2eed6a(0x109)]({
          cmd: "START",
          additionalSellRules: optimizations[_0x4fed11]["additionalSellRules"],
          additionalSellRulesRange: optimizations[_0x4fed11][_0x2eed6a(0xb9)],
          historicalData: _0x3d84af,
          conditionHistoricalData: _0x4237e9,
          dateFrom: optimizations[_0x4fed11]["dateFrom"],
          dateTo: optimizations[_0x4fed11][_0x2eed6a(0x113)],
          commissionRate: optimizations[_0x4fed11]["commissionRate"],
          minTrades: optimizations[_0x4fed11]["minTrades"],
          tradeSize: optimizations[_0x4fed11][_0x2eed6a(0xbb)],
          strategy: optimizations[_0x4fed11][_0x2eed6a(0xbc)],
          skipedRules: optimizations[_0x4fed11][_0x2eed6a(0xb2)],
        }))
      : optimizationFinishes(_0x4fed11, _0x2e6a02);
  } finally {
    optimizationMutex["release"]();
  }
}
function subscribeForOptimizations(_0x48f4f0, _0x222aef) {
  optimizationSubscribers[_0x48f4f0] = _0x222aef;
}
function unsubscribeForOptimizations(_0x2a2fa2) {
  delete optimizationSubscribers[_0x2a2fa2];
}
async function updateOptimizationSubscibers() {
  const _0x39131a = a0_0x2727;
  Object[_0x39131a(0x11f)](optimizationSubscribers)[_0x39131a(0xbd)](
    (_0x1182bb) => optimizationSubscribers[_0x1182bb]({ ...optimizations })
  );
}
function getOptimizations() {
  return optimizations;
}
function getBalance(_0x470f52, _0x936eda) {
  const _0x261b8a = a0_0x2727;
  let _0x200678 = [_0x261b8a(0x99), _0x261b8a(0x90), _0x261b8a(0x114)],
    _0x8db55b = [_0x261b8a(0x99), _0x261b8a(0x90), null, undefined],
    _0x3c450e = getExchange(_0x936eda)[_0x261b8a(0xd5)](_0x470f52),
    _0x2f7df0 = Object[_0x261b8a(0x11f)](tradings)["reduce"](
      (_0x123d0a, _0x50cff9) => {
        const _0x64b099 = _0x261b8a;
        let _0x30633b = tradings[_0x50cff9],
          _0x5c23d3 = 0x0;
        if (_0x30633b[_0x64b099(0x106)] === _0x64b099(0x11a)) {
          if (
            _0x30633b["strategy"][_0x64b099(0xc7)] === _0x936eda &&
            _0x30633b[_0x64b099(0xbc)][_0x64b099(0x8e)]["quoteAsset"] ===
              _0x470f52
          ) {
            let _0x49e6b4 = _0x30633b[_0x64b099(0x105)][_0x64b099(0x122)];
            _0x49e6b4 &&
              _0x8db55b["includes"](_0x49e6b4[_0x64b099(0xce)]) &&
              _0x200678[_0x64b099(0xf2)](_0x49e6b4[_0x64b099(0xc4)]) &&
              (_0x5c23d3 +=
                _0x49e6b4[_0x64b099(0xcf)] -
                (_0x49e6b4[_0x64b099(0xe2)]
                  ? _0x49e6b4["sellSizeInQuoted"]
                  : 0x0));
          }
        }
        return _0x123d0a + _0x5c23d3;
      },
      0x0
    );
  return _0x3c450e && _0x3c450e[_0x261b8a(0x121)] !== undefined
    ? _0x3c450e[_0x261b8a(0x121)] + _0x2f7df0
    : 0x0;
}
export {
  startBacktest,
  stopBacktest,
  subscribeForBacktests,
  unsubscribeForBacktests,
  getBacktests,
  startOptimization,
  stopOptimization,
  getOptimizations,
  subscribeForOptimizations,
  unsubscribeForOptimizations,
  startTrading,
  stopTrading,
  removeTrading,
  initializeTradingWorkers,
  stopTradingWorkers,
  updateExchangeApiKeys,
  updateStrategy,
  manualSell,
  manualBuy,
  getBalance,
  addTrading,
  executionReportServiceRestart,
  add2,
  add3,
};
