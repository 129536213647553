import Mutex from "./Mutex.js";
/* eslint-disable no-unused-expressions */
/* eslint-disable no-func-assign */
/* eslint-disable no-sequences */
const a0_0xfd150d = a0_0x5016;
(function (_0x5b2874, _0x3e766a) {
  const _0x5dad6d = a0_0x5016,
    _0x5b7045 = _0x5b2874();
  while (!![]) {
    try {
      const _0x59055c =
        -parseInt(_0x5dad6d(0x228)) / 0x1 +
        -parseInt(_0x5dad6d(0x1fc)) / 0x2 +
        (parseInt(_0x5dad6d(0x182)) / 0x3) *
          (parseInt(_0x5dad6d(0x212)) / 0x4) +
        -parseInt(_0x5dad6d(0x1e9)) / 0x5 +
        -parseInt(_0x5dad6d(0x1e1)) / 0x6 +
        (parseInt(_0x5dad6d(0x1ba)) / 0x7) *
          (-parseInt(_0x5dad6d(0x1f9)) / 0x8) +
        (-parseInt(_0x5dad6d(0x1c9)) / 0x9) *
          (-parseInt(_0x5dad6d(0x1be)) / 0xa);
      if (_0x59055c === _0x3e766a) break;
      else _0x5b7045["push"](_0x5b7045["shift"]());
    } catch (_0x313abd) {
      _0x5b7045["push"](_0x5b7045["shift"]());
    }
  }
})(a0_0x1f5e, 0x67487);
function a0_0x5016(_0x53099f, _0x16baba) {
  const _0x1f5eba = a0_0x1f5e();
  return (
    (a0_0x5016 = function (_0x50166f, _0x22f0aa) {
      _0x50166f = _0x50166f - 0x15e;
      let _0xc9f51c = _0x1f5eba[_0x50166f];
      return _0xc9f51c;
    }),
    a0_0x5016(_0x53099f, _0x16baba)
  );
}
class EcbDatabase {
  constructor() {
    const _0x1a566c = a0_0x5016;
    try {
      (this["mutexHistorical"] = new Mutex()),
        (this[_0x1a566c(0x17f)] = new Mutex()),
        (this["mutexErrors"] = new Mutex()),
        (this[_0x1a566c(0x192)] = new Mutex()),
        (this[_0x1a566c(0x163)] = new Mutex()),
        (this[_0x1a566c(0x1c3)] = new Mutex()),
        (this[_0x1a566c(0x1a9)] = new Mutex()),
        (this["mutexIndicators"] = new Mutex()),
        (this[_0x1a566c(0x1d9)] = new Mutex()),
        (this[_0x1a566c(0x1c4)] = new Mutex()),
        (this[_0x1a566c(0x1e0)] = new Mutex()),
        (this[_0x1a566c(0x1e8)] = new Mutex()),
        (this[_0x1a566c(0x18d)] = new Mutex()),
        (this[_0x1a566c(0x21a)] = new Mutex()),
        (this[_0x1a566c(0x211)] = _0x1a566c(0x17c)),
        (this["hitoricalDataStoreIndex"] = _0x1a566c(0x177)),
        (this[_0x1a566c(0x180)] = _0x1a566c(0x1c5)),
        (this["strategiesContestStore"] = "strategiesContestStore"),
        (this[_0x1a566c(0x1d8)] = _0x1a566c(0x187)),
        (this["backtests"] = "backtests"),
        (this[_0x1a566c(0x190)] = _0x1a566c(0x190)),
        (this[_0x1a566c(0x181)] = _0x1a566c(0x181)),
        (this["optimizationsSlim"] = _0x1a566c(0x19f)),
        (this[_0x1a566c(0x17d)] = _0x1a566c(0x17d)),
        (this["keys"] = _0x1a566c(0x21e)),
        (this["errorsStore"] = _0x1a566c(0x1c0)),
        (this[_0x1a566c(0x16c)] = _0x1a566c(0x201)),
        (this[_0x1a566c(0x1c7)] = _0x1a566c(0x1c7)),
        (this[_0x1a566c(0x1b1)] = _0x1a566c(0x1b1)),
        (this[_0x1a566c(0x1bd)] = _0x1a566c(0x1bd)),
        (this["multiCharts"] = _0x1a566c(0x197)),
        (this["strategyStore"] = _0x1a566c(0x1cb)),
        (this[_0x1a566c(0x226)] = _0x1a566c(0x226)),
        (this[_0x1a566c(0x20b)] = _0x1a566c(0x20b)),
        (this[_0x1a566c(0x1dd)] = _0x1a566c(0x1dd)),
        (this[_0x1a566c(0x21f)] = _0x1a566c(0x21f)),
        (this[_0x1a566c(0x1cd)] = _0x1a566c(0x1cd)),
        (this[_0x1a566c(0x183)] = _0x1a566c(0x183)),
        (this[_0x1a566c(0x1eb)] = _0x1a566c(0x1eb)),
        (this[_0x1a566c(0x21d)] = _0x1a566c(0x21d)),
        (this["strategySimEvent"] = _0x1a566c(0x1a1)),
        (this["strategySimEventIndex"] = _0x1a566c(0x15f)),
        (this[_0x1a566c(0x1f0)] = "strategySimStatus"),
        (this[_0x1a566c(0x16a)] = "strategySimDetails"),
        (this["botTradingView"] = _0x1a566c(0x1d5)),
        (this[_0x1a566c(0x205)] = _0x1a566c(0x205)),
        (this[_0x1a566c(0x1f8)] = "manualCloseTrades"),
        (this[_0x1a566c(0x1ff)] = _0x1a566c(0x1ff)),
        (this[_0x1a566c(0x1ef)] = "dailyPlsSimStore"),
        (this[_0x1a566c(0x1a8)] = 0xf);
      let _0x19ac5d = indexedDB[_0x1a566c(0x198)](
        _0x1a566c(0x1ca),
        this["version"]
      );
      (_0x19ac5d[_0x1a566c(0x1af)] = () => {
        const _0x604dd8 = _0x1a566c;
        this["db"] = _0x19ac5d["result"];
        !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](
          this[_0x604dd8(0x1ff)]
        ) &&
          this["db"][_0x604dd8(0x1ee)](this[_0x604dd8(0x1ff)], {
            keyPath: "id",
          });
        !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](
          this["dailyPlsSimStore"]
        ) &&
          this["db"][_0x604dd8(0x1ee)](this[_0x604dd8(0x1ef)], {
            keyPath: "id",
          });
        !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](
          this[_0x604dd8(0x205)]
        ) &&
          this["db"][_0x604dd8(0x1ee)](this["manualTrades"], { keyPath: "id" });
        !this["db"]["objectStoreNames"][_0x604dd8(0x1cc)](
          this[_0x604dd8(0x1f8)]
        ) &&
          this["db"]["createObjectStore"](this[_0x604dd8(0x1f8)], {
            keyPath: "id",
          });
        !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](
          this[_0x604dd8(0x1d5)]
        ) &&
          this["db"]["createObjectStore"](this[_0x604dd8(0x1d5)], {
            keyPath: "id",
          });
        !this["db"]["objectStoreNames"]["contains"](this["strategyStore"]) &&
          this["db"]["createObjectStore"](this[_0x604dd8(0x1d6)], {
            keyPath: "id",
          });
        if (
          !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](
            this[_0x604dd8(0x226)]
          )
        ) {
          let _0x2db12d = this["db"][_0x604dd8(0x1ee)](
            this["strategyLiveTrade"],
            { keyPath: "id" }
          );
          _0x2db12d[_0x604dd8(0x1f6)](this[_0x604dd8(0x20b)], "sId", {
            unique: ![],
          });
        }
        if (
          !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](
            this["strategyLiveEvent"]
          )
        ) {
          let _0x479a8b = this["db"][_0x604dd8(0x1ee)](this[_0x604dd8(0x1dd)], {
            keyPath: "id",
          });
          _0x479a8b[_0x604dd8(0x1f6)](
            this[_0x604dd8(0x21f)],
            _0x604dd8(0x17e),
            { unique: ![] }
          );
        }
        !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](
          this[_0x604dd8(0x1cd)]
        ) &&
          this["db"][_0x604dd8(0x1ee)](this[_0x604dd8(0x1cd)], {
            keyPath: "id",
          });
        !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](
          this["strategyLiveDetails"]
        ) &&
          this["db"][_0x604dd8(0x1ee)](this[_0x604dd8(0x183)], {
            keyPath: "id",
          });
        if (
          !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](
            this[_0x604dd8(0x1eb)]
          )
        ) {
          let _0x410bec = this["db"]["createObjectStore"](
            this[_0x604dd8(0x1eb)],
            { keyPath: "id" }
          );
          _0x410bec[_0x604dd8(0x1f6)](
            this[_0x604dd8(0x21d)],
            _0x604dd8(0x17e),
            { unique: ![] }
          );
        }
        if (!this["db"][_0x604dd8(0x224)]["contains"](this[_0x604dd8(0x1a1)])) {
          let _0x51fc8b = this["db"][_0x604dd8(0x1ee)](this[_0x604dd8(0x1a1)], {
            keyPath: "id",
          });
          _0x51fc8b["createIndex"](
            this["strategySimEventIndex"],
            _0x604dd8(0x17e),
            { unique: ![] }
          );
        }
        !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](
          this[_0x604dd8(0x1f0)]
        ) &&
          this["db"][_0x604dd8(0x1ee)](this[_0x604dd8(0x1f0)], {
            keyPath: "id",
          });
        !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](
          this[_0x604dd8(0x16a)]
        ) &&
          this["db"][_0x604dd8(0x1ee)](this[_0x604dd8(0x16a)], {
            keyPath: "id",
          });
        !this["db"][_0x604dd8(0x224)]["contains"](this[_0x604dd8(0x1bd)]) &&
          this["db"][_0x604dd8(0x1ee)](this["indicators"], { keyPath: "id" });
        !this["db"]["objectStoreNames"][_0x604dd8(0x1cc)](this["watchlists"]) &&
          this["db"][_0x604dd8(0x1ee)](this[_0x604dd8(0x1b1)], {
            keyPath: "id",
          });
        !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](this["multiCharts"]) &&
          this["db"]["createObjectStore"](this[_0x604dd8(0x197)], {
            keyPath: "id",
          });
        !this["db"]["objectStoreNames"][_0x604dd8(0x1cc)](
          this[_0x604dd8(0x1c7)]
        ) &&
          this["db"]["createObjectStore"](this[_0x604dd8(0x1c7)], {
            keyPath: "id",
          });
        !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](
          this[_0x604dd8(0x16c)]
        ) &&
          this["db"]["createObjectStore"](this[_0x604dd8(0x16c)], {
            keyPath: [
              _0x604dd8(0x172),
              _0x604dd8(0x1aa),
              _0x604dd8(0x20f),
              "tf",
            ],
          });
        if (
          !this["db"][_0x604dd8(0x224)]["contains"](this["hitoricalDataStore"])
        ) {
          let _0x123950 = this["db"]["createObjectStore"](
            this[_0x604dd8(0x211)],
            { keyPath: ["ex", "p", "tf", "d"] }
          );
          _0x123950[_0x604dd8(0x1f6)](
            this[_0x604dd8(0x185)],
            ["ex", "p", "tf"],
            { unique: ![] }
          );
        }
        !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](
          this[_0x604dd8(0x180)]
        ) &&
          this["db"][_0x604dd8(0x1ee)](this[_0x604dd8(0x180)], {
            keyPath: "id",
          }),
          !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](
            this["strategiesContestStore"]
          ) &&
            this["db"][_0x604dd8(0x1ee)](this["strategiesContestStore"], {
              keyPath: "id",
            }),
          !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](
            this[_0x604dd8(0x1d8)]
          ) &&
            this["db"][_0x604dd8(0x1ee)](this[_0x604dd8(0x1d8)], {
              keyPath: "id",
            }),
          !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](this["keys"]) &&
            this["db"][_0x604dd8(0x1ee)](this[_0x604dd8(0x21e)], {
              keyPath: "id",
            }),
          !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](this["backtests"]) &&
            this["db"]["createObjectStore"](this[_0x604dd8(0x18c)], {
              keyPath: "id",
            }),
          !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](
            this[_0x604dd8(0x190)]
          ) &&
            this["db"][_0x604dd8(0x1ee)](this["backtestsSlim"], {
              keyPath: "id",
            }),
          !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](
            this[_0x604dd8(0x181)]
          ) &&
            this["db"][_0x604dd8(0x1ee)](this[_0x604dd8(0x181)], {
              keyPath: "id",
            }),
          !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](
            this[_0x604dd8(0x19f)]
          ) &&
            this["db"]["createObjectStore"](this[_0x604dd8(0x19f)], {
              keyPath: "id",
            }),
          !this["db"][_0x604dd8(0x224)]["contains"](this[_0x604dd8(0x17d)]) &&
            this["db"][_0x604dd8(0x1ee)](this["optimizationIndicators"], {
              keyPath: "id",
            }),
          !this["db"][_0x604dd8(0x224)][_0x604dd8(0x1cc)](
            this[_0x604dd8(0x165)]
          ) &&
            this["db"]["createObjectStore"](this[_0x604dd8(0x165)], {
              keyPath: "id",
            });
      }),
        (_0x19ac5d[_0x1a566c(0x20d)] = () => {
          const _0x4688f0 = _0x1a566c;
          this["db"] = _0x19ac5d[_0x4688f0(0x207)];
        }),
        (_0x19ac5d[_0x1a566c(0x19a)] = () => {});
    } catch (_0x5d02ba) {
      console[_0x1a566c(0x19c)](_0x5d02ba);
    }
  }
  async [a0_0xfd150d(0x21b)](
    _0x588c56,
    _0x2b9fa2,
    _0x445a90,
    _0x502a1f,
    _0x3c184a,
    _0xf9a4f5,
    _0x1c2d14
  ) {
    const _0x1e6ef7 = a0_0xfd150d;
    try {
      await this[_0x1e6ef7(0x220)][_0x1e6ef7(0x22b)]();
      let _0x3b4926 = indexedDB[_0x1e6ef7(0x198)](
        _0x1e6ef7(0x1ca),
        this["version"]
      );
      (_0x3b4926[_0x1e6ef7(0x20d)] = () => {
        const _0x2f6ed5 = _0x1e6ef7;
        try {
          let _0x36e53e = _0x3b4926[_0x2f6ed5(0x207)],
            _0x22a2e0 = _0x36e53e[_0x2f6ed5(0x1a3)](
              this["hitoricalDataChunksStore"],
              "readwrite"
            ),
            _0x2d9608 = _0x22a2e0[_0x2f6ed5(0x196)](this[_0x2f6ed5(0x16c)]),
            _0x17c200 = _0x2d9608[_0x2f6ed5(0x175)]({
              exchange: _0x588c56,
              pair: _0x2b9fa2,
              tf: _0x445a90,
              month: _0x502a1f,
              data: _0x3c184a,
              lastDownload: _0xf9a4f5,
              isFull: _0x1c2d14,
            });
          (_0x17c200["onsuccess"] = () =>
            this[_0x2f6ed5(0x220)][_0x2f6ed5(0x174)]()),
            (_0x17c200[_0x2f6ed5(0x19a)] = (_0x41bff7) => {
              const _0x33bee2 = _0x2f6ed5;
              _0x17c200[_0x33bee2(0x179)][_0x33bee2(0x1b8)] ===
                _0x33bee2(0x193) && _0x41bff7["preventDefault"](),
                this["mutexHistorical"][_0x33bee2(0x174)]();
            });
        } catch (_0x53cd66) {
          console[_0x2f6ed5(0x19c)](_0x2f6ed5(0x1c2) + _0x53cd66),
            this[_0x2f6ed5(0x220)][_0x2f6ed5(0x174)]();
        }
      }),
        (_0x3b4926[_0x1e6ef7(0x19a)] = () =>
          this[_0x1e6ef7(0x220)][_0x1e6ef7(0x174)]());
    } catch (_0x39bd46) {
      this[_0x1e6ef7(0x220)][_0x1e6ef7(0x174)]();
    }
  }
  [a0_0xfd150d(0x221)](_0x3d2883, _0x228ef6, _0x15b103, _0x2a6a69, _0x6d398d) {
    return new Promise((_0x50eac5) => {
      const _0x4355fd = a0_0x5016;
      try {
        let _0x35aab3 = _0x3d2883[_0x4355fd(0x162)](
          IDBKeyRange[_0x4355fd(0x168)]([
            _0x228ef6,
            _0x15b103,
            _0x2a6a69,
            _0x6d398d,
          ])
        );
        (_0x35aab3[_0x4355fd(0x20d)] = (_0x3c96b5) => {
          const _0x1003bc = _0x4355fd;
          try {
            let _0x33bff5 = _0x3c96b5[_0x1003bc(0x176)][_0x1003bc(0x207)];
            _0x33bff5
              ? _0x50eac5(_0x33bff5[_0x1003bc(0x227)])
              : _0x50eac5(null);
          } catch (_0x5cd9f1) {
            _0x50eac5(null);
          }
        }),
          (_0x35aab3[_0x4355fd(0x19a)] = (_0x41545d) => {
            _0x50eac5(null);
          });
      } catch (_0x5d82af) {
        _0x50eac5(null);
      }
    });
  }
  [a0_0xfd150d(0x1bb)](_0x1b6ded, _0x2e68e5, _0x526042, _0x44c87f) {
    const _0x555864 = a0_0xfd150d;
    if (!_0x1b6ded || !_0x2e68e5 || !_0x526042)
      return (
        console[_0x555864(0x19c)](
          _0x555864(0x194) +
            _0x1b6ded +
            _0x555864(0x213) +
            _0x2e68e5 +
            _0x555864(0x1f3) +
            _0x526042
        ),
        []
      );
    return new Promise(async (_0xf1cf2c) => {
      const _0x1c8b00 = _0x555864;
      try {
        await this[_0x1c8b00(0x220)]["lock"]();
        let _0x2516e2 = indexedDB[_0x1c8b00(0x198)](
          _0x1c8b00(0x1ca),
          this[_0x1c8b00(0x1a8)]
        );
        (_0x2516e2[_0x1c8b00(0x20d)] = async () => {
          const _0x5101ec = _0x1c8b00;
          try {
            let _0x535f9c = _0x2516e2["result"],
              _0x21ee95 = _0x535f9c[_0x5101ec(0x1a3)](
                this["hitoricalDataChunksStore"],
                _0x5101ec(0x20a)
              ),
              _0x436889 = _0x21ee95[_0x5101ec(0x196)](this[_0x5101ec(0x16c)]),
              _0x13c4aa = [];
            for (let _0x2596e8 of _0x44c87f) {
              let _0x116740 = await this["getSingleHistoricalDataChunk"](
                _0x436889,
                _0x2596e8,
                _0x1b6ded,
                _0x2e68e5,
                _0x526042
              );
              _0x116740 && _0x13c4aa[_0x5101ec(0x19b)](_0x116740);
            }
            _0x13c4aa[_0x5101ec(0x1ce)]((_0x31df07, _0x4d6fbf) => {
              const _0x4b14d5 = _0x5101ec;
              return _0x31df07[_0x4b14d5(0x172)] > _0x4d6fbf[_0x4b14d5(0x172)]
                ? 0x1
                : -0x1;
            }),
              this[_0x5101ec(0x220)]["release"](),
              _0xf1cf2c(_0x13c4aa);
          } catch (_0x3b35de) {
            this[_0x5101ec(0x220)][_0x5101ec(0x174)](), _0xf1cf2c([]);
          }
        }),
          (_0x2516e2[_0x1c8b00(0x19a)] = () => {
            const _0x47e51d = _0x1c8b00;
            this["mutexHistorical"][_0x47e51d(0x174)](), _0xf1cf2c([]);
          });
      } catch (_0x4ecf35) {
        this[_0x1c8b00(0x220)][_0x1c8b00(0x174)](), _0xf1cf2c([]);
      }
    });
  }
  async [a0_0xfd150d(0x1fd)](_0xd8efa7) {
    const _0x2e3ae9 = a0_0xfd150d;
    try {
      await this[_0x2e3ae9(0x220)]["lock"]();
      let _0x1f299a = indexedDB[_0x2e3ae9(0x198)](
        _0x2e3ae9(0x1ca),
        this["version"]
      );
      (_0x1f299a[_0x2e3ae9(0x20d)] = () => {
        const _0xed71cd = _0x2e3ae9;
        try {
          let _0xc398c2 = _0x1f299a[_0xed71cd(0x207)],
            _0x197a4c = _0xc398c2["transaction"](
              this[_0xed71cd(0x211)],
              "readwrite"
            ),
            _0x26338f = _0x197a4c[_0xed71cd(0x196)](this["hitoricalDataStore"]);
          _0xd8efa7[_0xed71cd(0x1c8)]((_0x383d14) => {
            let _0x310fc4 = _0x26338f["put"](_0x383d14);
            _0x310fc4["onerror"] = (_0x36c2fc) => {
              const _0x31f66b = a0_0x5016;
              _0x310fc4[_0x31f66b(0x179)]["name"] === _0x31f66b(0x193) &&
                _0x36c2fc["preventDefault"]();
            };
          }),
            this[_0xed71cd(0x220)][_0xed71cd(0x174)]();
        } catch (_0x48d31a) {
          console[_0xed71cd(0x19c)](
            "Could\x20not\x20store\x20data\x20in\x20DB!"
          ),
            this[_0xed71cd(0x220)][_0xed71cd(0x174)]();
        }
      }),
        (_0x1f299a[_0x2e3ae9(0x19a)] = () =>
          this[_0x2e3ae9(0x220)][_0x2e3ae9(0x174)]());
    } catch (_0x247fb4) {
      this["mutexHistorical"][_0x2e3ae9(0x174)]();
    }
  }
  [a0_0xfd150d(0x1bc)](_0x5e0e6e, _0x3b1405, _0x4affb5) {
    const _0x12806c = a0_0xfd150d;
    if (!_0x5e0e6e || !_0x3b1405 || !_0x4affb5)
      return (
        console["log"](
          _0x12806c(0x189) +
            _0x5e0e6e +
            _0x12806c(0x213) +
            _0x3b1405 +
            _0x12806c(0x1f3) +
            _0x4affb5
        ),
        []
      );
    return new Promise(async (_0x273568) => {
      const _0x28f040 = _0x12806c;
      try {
        await this["mutexHistorical"][_0x28f040(0x22b)]();
        let _0x90afeb = indexedDB[_0x28f040(0x198)](
          _0x28f040(0x1ca),
          this["version"]
        );
        (_0x90afeb[_0x28f040(0x20d)] = () => {
          const _0x1fe43a = _0x28f040;
          try {
            let _0x49d287 = _0x90afeb["result"],
              _0xbdbd38 = _0x49d287[_0x1fe43a(0x1a3)](
                this[_0x1fe43a(0x211)],
                _0x1fe43a(0x20a)
              ),
              _0x2d7749 = _0xbdbd38[_0x1fe43a(0x196)](this[_0x1fe43a(0x211)]),
              _0x40fe5d = _0x2d7749["index"](this[_0x1fe43a(0x185)]),
              _0x5c2ca9 = _0x40fe5d[_0x1fe43a(0x162)](
                IDBKeyRange[_0x1fe43a(0x168)]([_0x5e0e6e, _0x3b1405, _0x4affb5])
              ),
              _0x28d312 = [];
            (_0x5c2ca9[_0x1fe43a(0x20d)] = (_0xffe302) => {
              const _0x35d388 = _0x1fe43a;
              try {
                let _0x1e019b = _0xffe302["target"][_0x35d388(0x207)];
                _0x1e019b
                  ? (_0x28d312["push"](_0x1e019b["value"]),
                    _0x1e019b["continue"]())
                  : (this[_0x35d388(0x220)][_0x35d388(0x174)](),
                    _0x28d312[_0x35d388(0x1ce)]((_0x426caa, _0x56f66b) => {
                      return _0x426caa["d"] > _0x56f66b["d"] ? 0x1 : -0x1;
                    }),
                    _0x273568(_0x28d312));
              } catch (_0x3a88de) {
                this[_0x35d388(0x220)][_0x35d388(0x174)](), _0x273568([]);
              }
            }),
              (_0x5c2ca9["onerror"] = (_0x2d65f2) => {
                const _0x4e6906 = _0x1fe43a;
                this["mutexHistorical"][_0x4e6906(0x174)](), _0x273568([]);
              });
          } catch (_0x14c12e) {
            this[_0x1fe43a(0x220)][_0x1fe43a(0x174)](), _0x273568([]);
          }
        }),
          (_0x90afeb[_0x28f040(0x19a)] = () => {
            const _0x242b2a = _0x28f040;
            this[_0x242b2a(0x220)][_0x242b2a(0x174)](), _0x273568([]);
          });
      } catch (_0x146245) {
        this[_0x28f040(0x220)][_0x28f040(0x174)](), _0x273568([]);
      }
    });
  }
  [a0_0xfd150d(0x217)](_0x18a909, _0x48471b, _0x4aa36e) {
    const _0x1a9ffc = a0_0xfd150d;
    if (!_0x18a909 || !_0x48471b || !_0x4aa36e) {
      console[_0x1a9ffc(0x19c)](
        _0x1a9ffc(0x16b) +
          _0x18a909 +
          _0x1a9ffc(0x213) +
          _0x48471b +
          _0x1a9ffc(0x1f3) +
          _0x4aa36e
      );
      return;
    }
    return new Promise(async (_0x3baf0c) => {
      const _0x2e62da = _0x1a9ffc;
      try {
        await this[_0x2e62da(0x220)][_0x2e62da(0x22b)]();
        let _0x498b40 = indexedDB[_0x2e62da(0x198)](
          _0x2e62da(0x1ca),
          this["version"]
        );
        (_0x498b40[_0x2e62da(0x20d)] = () => {
          const _0x3fa7ef = _0x2e62da;
          try {
            let _0x2c41a0 = _0x498b40[_0x3fa7ef(0x207)],
              _0x3f4c54 = _0x2c41a0["transaction"](
                this[_0x3fa7ef(0x211)],
                _0x3fa7ef(0x18f)
              ),
              _0x24f7ff = _0x3f4c54[_0x3fa7ef(0x196)](this[_0x3fa7ef(0x211)]),
              _0x25ed14 = _0x24f7ff["index"](this["hitoricalDataStoreIndex"]),
              _0x26432e = _0x25ed14[_0x3fa7ef(0x18a)](
                IDBKeyRange[_0x3fa7ef(0x168)]([_0x18a909, _0x48471b, _0x4aa36e])
              );
            (_0x26432e[_0x3fa7ef(0x20d)] = () => {
              const _0x5c61df = _0x3fa7ef;
              try {
                let _0x42380a = _0x26432e[_0x5c61df(0x207)];
                _0x42380a &&
                  (_0x24f7ff["delete"](_0x42380a[_0x5c61df(0x204)]),
                  _0x42380a["continue"]()),
                  this[_0x5c61df(0x220)]["release"](),
                  _0x3baf0c();
              } catch (_0x247745) {
                this["mutexHistorical"][_0x5c61df(0x174)]();
              }
            }),
              (_0x26432e[_0x3fa7ef(0x19a)] = (_0x1ab147) => {
                const _0x15f615 = _0x3fa7ef;
                this[_0x15f615(0x220)][_0x15f615(0x174)](), _0x3baf0c();
              });
          } catch (_0x367fba) {
            this["mutexHistorical"][_0x3fa7ef(0x174)](), _0x3baf0c();
          }
        }),
          (_0x498b40[_0x2e62da(0x19a)] = () => {
            const _0x4b0ea9 = _0x2e62da;
            this[_0x4b0ea9(0x220)][_0x4b0ea9(0x174)](), _0x3baf0c();
          });
      } catch (_0x48a306) {
        this["mutexHistorical"]["release"](), _0x3baf0c();
      }
    });
  }
  ["storeOne"](_0x4e0424, _0x3470a2, _0xbf9718) {
    return new Promise(async (_0x3e3f48) => {
      const _0x5496fe = a0_0x5016;
      try {
        await _0xbf9718["lock"]();
        let _0x3a3b83 = indexedDB[_0x5496fe(0x198)](
          _0x5496fe(0x1ca),
          this["version"]
        );
        (_0x3a3b83["onsuccess"] = () => {
          const _0x4020c4 = _0x5496fe;
          try {
            let _0x1ff2fb = _0x3a3b83[_0x4020c4(0x207)],
              _0x1ae3e4 = _0x1ff2fb[_0x4020c4(0x1a3)](
                _0x3470a2,
                _0x4020c4(0x18f)
              ),
              _0x5b4c5d = _0x1ae3e4["objectStore"](_0x3470a2),
              _0x5cbe1d = _0x5b4c5d["put"](_0x4e0424);
            (_0x5cbe1d[_0x4020c4(0x20d)] = () => {
              const _0x121ae4 = _0x4020c4;
              _0xbf9718[_0x121ae4(0x174)](), _0x3e3f48();
            }),
              (_0x5cbe1d[_0x4020c4(0x19a)] = (_0x3f00ff) => {
                const _0x515387 = _0x4020c4;
                _0x5cbe1d[_0x515387(0x179)][_0x515387(0x1b8)] ===
                  _0x515387(0x193) && _0x3f00ff[_0x515387(0x200)](),
                  _0xbf9718["release"](),
                  _0x3e3f48();
              });
          } catch (_0x572d50) {
            console["log"](_0x4020c4(0x167) + _0x3470a2 + ".\x20" + _0x572d50),
              _0xbf9718["release"](),
              _0x3e3f48();
          }
        }),
          (_0x3a3b83[_0x5496fe(0x19a)] = () => {
            _0xbf9718["release"](), _0x3e3f48();
          });
      } catch (_0x54e934) {
        _0xbf9718[_0x5496fe(0x174)](), _0x3e3f48();
      }
    });
  }
  [a0_0xfd150d(0x19e)](_0x5b20f0, _0x51bb91, _0xec5694) {
    return new Promise(async (_0x1f0600) => {
      const _0x5659c1 = a0_0x5016;
      try {
        await _0xec5694[_0x5659c1(0x22b)]();
        let _0x33d64f = indexedDB["open"](
          _0x5659c1(0x1ca),
          this[_0x5659c1(0x1a8)]
        );
        (_0x33d64f[_0x5659c1(0x20d)] = () => {
          const _0x79e98d = _0x5659c1;
          try {
            let _0x1053ec = _0x33d64f[_0x79e98d(0x207)],
              _0x2caf78 = _0x1053ec[_0x79e98d(0x1a3)](
                _0x51bb91,
                _0x79e98d(0x18f)
              ),
              _0x273981 = _0x2caf78[_0x79e98d(0x196)](_0x51bb91);
            _0x5b20f0[_0x79e98d(0x1c8)]((_0xf27049) => {
              const _0x171383 = _0x79e98d;
              let _0x2f4bff = _0x273981[_0x171383(0x175)](_0xf27049);
              _0x2f4bff[_0x171383(0x19a)] = (_0x234e6c) => {
                const _0x5a427d = _0x171383;
                _0x2f4bff[_0x5a427d(0x179)][_0x5a427d(0x1b8)] ===
                  _0x5a427d(0x193) && _0x234e6c[_0x5a427d(0x200)]();
              };
            }),
              _0xec5694[_0x79e98d(0x174)](),
              _0x1f0600();
          } catch (_0x161629) {
            console["log"](_0x79e98d(0x16d), _0x51bb91),
              _0xec5694[_0x79e98d(0x174)](),
              _0x1f0600();
          }
        }),
          (_0x33d64f[_0x5659c1(0x19a)] = () => {
            const _0x47db57 = _0x5659c1;
            _0xec5694[_0x47db57(0x174)](), _0x1f0600();
          });
      } catch (_0x1afd2b) {
        _0xec5694[_0x5659c1(0x174)](), _0x1f0600();
      }
    });
  }
  [a0_0xfd150d(0x215)](_0x26ee16, _0x1e40a1, _0x53924c) {
    return new Promise(async (_0x2ed8f7) => {
      const _0x539533 = a0_0x5016;
      try {
        await _0x53924c[_0x539533(0x22b)]();
        let _0x513c29 = indexedDB[_0x539533(0x198)](
          _0x539533(0x1ca),
          this[_0x539533(0x1a8)]
        );
        (_0x513c29["onsuccess"] = () => {
          const _0x2d63f7 = _0x539533;
          try {
            let _0x139e6c = _0x513c29["result"],
              _0x1b83aa = _0x139e6c[_0x2d63f7(0x1a3)](_0x1e40a1, "readwrite"),
              _0xd0690 = _0x1b83aa[_0x2d63f7(0x196)](_0x1e40a1),
              _0x34cb9d = _0xd0690[_0x2d63f7(0x1e2)](_0x26ee16);
            (_0x34cb9d[_0x2d63f7(0x20d)] = (_0x142d68) => {
              const _0x3263e2 = _0x2d63f7;
              _0x53924c[_0x3263e2(0x174)](), _0x2ed8f7();
            }),
              (_0x34cb9d[_0x2d63f7(0x19a)] = (_0x22b9a1) => {
                const _0x2054ac = _0x2d63f7;
                _0x53924c[_0x2054ac(0x174)](), _0x2ed8f7();
              });
          } catch (_0x5b51b0) {
            console[_0x2d63f7(0x19c)](_0x2d63f7(0x1ed) + _0x5b51b0),
              _0x53924c[_0x2d63f7(0x174)](),
              _0x2ed8f7();
          }
        }),
          (_0x513c29["onerror"] = () => {
            const _0x3c52cd = _0x539533;
            _0x53924c[_0x3c52cd(0x174)](), _0x2ed8f7();
          });
      } catch (_0x37819a) {
        _0x53924c[_0x539533(0x174)](), _0x2ed8f7();
      }
    });
  }
  [a0_0xfd150d(0x1b0)](_0x235f3a, _0x575259) {
    return new Promise(async (_0xe19936) => {
      const _0x1cc104 = a0_0x5016;
      try {
        await _0x575259["lock"]();
        let _0x5a095e = indexedDB["open"](
          _0x1cc104(0x1ca),
          this[_0x1cc104(0x1a8)]
        );
        (_0x5a095e[_0x1cc104(0x20d)] = () => {
          const _0x268766 = _0x1cc104;
          try {
            let _0x30aabb = _0x5a095e["result"],
              _0x3b0bb2 = _0x30aabb["transaction"](_0x235f3a, "readwrite"),
              _0x5805db = _0x3b0bb2[_0x268766(0x196)](_0x235f3a),
              _0x21a138 = _0x5805db["clear"]();
            (_0x21a138[_0x268766(0x20d)] = (_0x1b0427) => {
              const _0x29c324 = _0x268766;
              _0x575259[_0x29c324(0x174)](), _0xe19936();
            }),
              (_0x21a138[_0x268766(0x19a)] = (_0x1791f1) => {
                const _0x24c2f8 = _0x268766;
                _0x575259[_0x24c2f8(0x174)](), _0xe19936();
              });
          } catch (_0x57e823) {
            console[_0x268766(0x19c)](
              "Could\x20not\x20delete\x20backtests\x20" + _0x57e823
            ),
              _0x575259[_0x268766(0x174)](),
              _0xe19936();
          }
        }),
          (_0x5a095e[_0x1cc104(0x19a)] = () => {
            const _0x492526 = _0x1cc104;
            _0x575259[_0x492526(0x174)](), _0xe19936();
          });
      } catch (_0x5372e8) {
        console[_0x1cc104(0x19c)](_0x1cc104(0x223) + _0x5372e8),
          _0x575259[_0x1cc104(0x174)](),
          _0xe19936();
      }
    });
  }
  [a0_0xfd150d(0x1ec)](_0x11136c, _0x573c65, _0x23c474) {
    return new Promise(async (_0x4c823d) => {
      const _0x48da14 = a0_0x5016;
      try {
        await _0x23c474[_0x48da14(0x22b)]();
        let _0x2d02f5 = indexedDB["open"](_0x48da14(0x1ca), this["version"]);
        (_0x2d02f5[_0x48da14(0x20d)] = () => {
          const _0x270f87 = _0x48da14;
          try {
            let _0x336d7c = _0x2d02f5[_0x270f87(0x207)],
              _0x27ee48 = _0x336d7c["transaction"](_0x573c65, _0x270f87(0x20a)),
              _0x17d55a = _0x27ee48[_0x270f87(0x196)](_0x573c65),
              _0x155cb8 = _0x17d55a[_0x270f87(0x1d2)](_0x11136c);
            (_0x155cb8[_0x270f87(0x20d)] = (_0x12eb58) => {
              const _0x5bebc0 = _0x270f87;
              _0x12eb58[_0x5bebc0(0x176)][_0x5bebc0(0x207)]
                ? (_0x23c474[_0x5bebc0(0x174)](),
                  _0x4c823d(_0x12eb58["target"][_0x5bebc0(0x207)]))
                : (_0x23c474["release"](), _0x4c823d(null));
            }),
              (_0x155cb8["onerror"] = (_0x176f9f) => {
                _0x23c474["release"](), _0x4c823d(null);
              });
          } catch (_0x2f8601) {
            _0x23c474[_0x270f87(0x174)](), _0x4c823d(null);
          }
        }),
          (_0x2d02f5["onerror"] = () => {
            const _0x53882e = _0x48da14;
            _0x23c474[_0x53882e(0x174)](), _0x4c823d(null);
          });
      } catch (_0x565de1) {
        _0x23c474["release"](), _0x4c823d(null);
      }
    });
  }
  [a0_0xfd150d(0x18e)](_0x199339, _0x218f45, _0x4f4526, _0x20867c) {
    return new Promise(async (_0x592654) => {
      const _0x4d7400 = a0_0x5016;
      try {
        await _0x4f4526[_0x4d7400(0x22b)]();
        let _0x41cce2 = indexedDB[_0x4d7400(0x198)](
          "ecbDb",
          this[_0x4d7400(0x1a8)]
        );
        (_0x41cce2[_0x4d7400(0x20d)] = () => {
          const _0x166e63 = _0x4d7400;
          try {
            let _0x45eb1b = _0x41cce2[_0x166e63(0x207)],
              _0x4a92ce = _0x45eb1b[_0x166e63(0x1a3)](
                _0x218f45,
                _0x166e63(0x20a)
              ),
              _0x1e3a05 = _0x4a92ce[_0x166e63(0x196)](_0x218f45),
              _0x155857 = _0x1e3a05["openCursor"](),
              _0x1e2f10 = [];
            (_0x155857[_0x166e63(0x20d)] = (_0x3061b3) => {
              const _0x54238a = _0x166e63;
              try {
                let _0x4e9709 = _0x3061b3[_0x54238a(0x176)][_0x54238a(0x207)];
                _0x4e9709
                  ? (_0x1e2f10[_0x54238a(0x19b)](_0x4e9709[_0x54238a(0x227)]),
                    _0x4e9709[_0x54238a(0x1f7)]())
                  : (_0x4f4526[_0x54238a(0x174)](),
                    _0x592654(
                      _0x1e2f10["filter"](
                        (_0x28dc62) =>
                          _0x28dc62[_0x54238a(0x199)] == _0x199339 ||
                          _0x28dc62[_0x54238a(0x222)] == _0x199339 ||
                          (_0x20867c && _0x28dc62[_0x20867c] == _0x199339)
                      )
                    ));
              } catch (_0x34db62) {
                _0x4f4526["release"](), _0x592654([]);
              }
            }),
              (_0x155857["onerror"] = (_0x4cdb40) => {
                const _0x2160ae = _0x166e63;
                _0x4f4526[_0x2160ae(0x174)](), _0x592654([]);
              });
          } catch (_0x4fc844) {
            _0x4f4526["release"](), _0x592654([]);
          }
        }),
          (_0x41cce2[_0x4d7400(0x19a)] = () => {
            const _0x317632 = _0x4d7400;
            _0x4f4526[_0x317632(0x174)](), _0x592654([]);
          });
      } catch (_0x32b8b5) {
        _0x4f4526[_0x4d7400(0x174)](), _0x592654([]);
      }
    });
  }
  [a0_0xfd150d(0x1df)](_0x157cc2, _0x254978, _0x5baf48, _0x24585e) {
    return new Promise(async (_0x49cc1f) => {
      const _0x70e7c = a0_0x5016;
      try {
        await _0x24585e[_0x70e7c(0x22b)]();
        let _0x2aae58 = indexedDB[_0x70e7c(0x198)](
          _0x70e7c(0x1ca),
          this[_0x70e7c(0x1a8)]
        );
        (_0x2aae58[_0x70e7c(0x20d)] = () => {
          const _0x90d1b5 = _0x70e7c;
          try {
            let _0x5889a2 = _0x2aae58[_0x90d1b5(0x207)],
              _0x102500 = _0x5889a2[_0x90d1b5(0x1a3)](
                _0x254978,
                _0x90d1b5(0x20a)
              ),
              _0x34488d = _0x102500[_0x90d1b5(0x196)](_0x254978),
              _0x944869 = _0x34488d[_0x90d1b5(0x191)](_0x5baf48),
              _0x39ecc7 = _0x944869[_0x90d1b5(0x162)](
                IDBKeyRange[_0x90d1b5(0x168)](_0x157cc2)
              ),
              _0x161fd7 = [];
            (_0x39ecc7[_0x90d1b5(0x20d)] = (_0x63ea02) => {
              const _0x373a20 = _0x90d1b5;
              try {
                let _0x407908 = _0x63ea02[_0x373a20(0x176)][_0x373a20(0x207)];
                _0x407908
                  ? (_0x161fd7[_0x373a20(0x19b)](_0x407908["value"]),
                    _0x407908["continue"]())
                  : (_0x24585e[_0x373a20(0x174)](), _0x49cc1f(_0x161fd7));
              } catch (_0x5101f0) {
                _0x24585e[_0x373a20(0x174)](), _0x49cc1f([]);
              }
            }),
              (_0x39ecc7[_0x90d1b5(0x19a)] = (_0xaf0b35) => {
                _0x24585e["release"](), _0x49cc1f([]);
              });
          } catch (_0x39ac38) {
            _0x24585e[_0x90d1b5(0x174)](), _0x49cc1f([]);
          }
        }),
          (_0x2aae58[_0x70e7c(0x19a)] = () => {
            _0x24585e["release"](), _0x49cc1f([]);
          });
      } catch (_0x4e8380) {
        _0x24585e["release"](), _0x49cc1f([]);
      }
    });
  }
  [a0_0xfd150d(0x1d4)](_0x8eea46, _0x4561e5, _0x55b1bf, _0x39569f) {
    return new Promise(async (_0x4f7bc8) => {
      const _0x108322 = a0_0x5016;
      try {
        await _0x39569f[_0x108322(0x22b)]();
        let _0x2acdca = indexedDB[_0x108322(0x198)](
          _0x108322(0x1ca),
          this["version"]
        );
        (_0x2acdca[_0x108322(0x20d)] = () => {
          const _0x58205b = _0x108322;
          try {
            let _0x54a112 = _0x2acdca["result"],
              _0x1c6e8d = _0x54a112[_0x58205b(0x1a3)](
                _0x4561e5,
                _0x58205b(0x18f)
              ),
              _0x319354 = _0x1c6e8d[_0x58205b(0x196)](_0x4561e5),
              _0x227260 = _0x319354[_0x58205b(0x191)](_0x55b1bf),
              _0x572d8d = _0x227260[_0x58205b(0x18a)](
                IDBKeyRange["only"](_0x8eea46)
              );
            (_0x572d8d[_0x58205b(0x20d)] = () => {
              const _0x11caa5 = _0x58205b;
              try {
                let _0x304735 = _0x572d8d[_0x11caa5(0x207)];
                _0x304735 &&
                  (_0x319354["delete"](_0x304735[_0x11caa5(0x204)]),
                  _0x304735[_0x11caa5(0x1f7)]()),
                  _0x39569f[_0x11caa5(0x174)](),
                  _0x4f7bc8();
              } catch (_0x5f5a57) {
                _0x39569f[_0x11caa5(0x174)]();
              }
            }),
              (_0x572d8d[_0x58205b(0x19a)] = (_0xdbb212) => {
                const _0x2d2867 = _0x58205b;
                _0x39569f[_0x2d2867(0x174)](), _0x4f7bc8();
              });
          } catch (_0x22bf4e) {
            _0x39569f[_0x58205b(0x174)](), _0x4f7bc8();
          }
        }),
          (_0x2acdca["onerror"] = () => {
            const _0x44f1e1 = _0x108322;
            _0x39569f[_0x44f1e1(0x174)](), _0x4f7bc8();
          });
      } catch (_0x4debd1) {
        _0x39569f[_0x108322(0x174)](), _0x4f7bc8();
      }
    });
  }
  ["getOldStrategies"](_0x4e23fe) {
    const _0x4086fa = a0_0xfd150d;
    return this[_0x4086fa(0x18e)](
      _0x4e23fe,
      this["strategiesStoreOld"],
      this[_0x4086fa(0x17f)]
    );
  }
  [a0_0xfd150d(0x1d0)](_0x1465a8) {
    const _0x55c368 = a0_0xfd150d;
    return this[_0x55c368(0x166)](
      _0x1465a8,
      this[_0x55c368(0x180)],
      this["mutexStrategies"]
    );
  }
  [a0_0xfd150d(0x18b)](_0x131094) {
    const _0x257037 = a0_0xfd150d;
    return this[_0x257037(0x19e)](
      _0x131094,
      this[_0x257037(0x180)],
      this[_0x257037(0x17f)]
    );
  }
  [a0_0xfd150d(0x1b9)](_0x403dde) {
    const _0xe1ab04 = a0_0xfd150d;
    return this[_0xe1ab04(0x215)](
      _0x403dde,
      this[_0xe1ab04(0x180)],
      this[_0xe1ab04(0x17f)]
    );
  }
  [a0_0xfd150d(0x214)](_0x7b40cd) {
    const _0x5d644e = a0_0xfd150d;
    return this[_0x5d644e(0x18e)](
      _0x7b40cd,
      this[_0x5d644e(0x1d6)],
      this[_0x5d644e(0x17f)]
    );
  }
  [a0_0xfd150d(0x1fa)](_0x43d62b) {
    return this["storeOne"](
      _0x43d62b,
      this["strategyStore"],
      this["mutexStrategies"]
    );
  }
  [a0_0xfd150d(0x1ac)](_0x3569f7) {
    const _0x47eb96 = a0_0xfd150d;
    return this[_0x47eb96(0x1ec)](
      _0x3569f7,
      this["strategyStore"],
      this[_0x47eb96(0x17f)]
    );
  }
  [a0_0xfd150d(0x203)](_0x2fd670) {
    const _0x243d56 = a0_0xfd150d;
    return this[_0x243d56(0x19e)](
      _0x2fd670,
      this[_0x243d56(0x1d6)],
      this[_0x243d56(0x17f)]
    );
  }
  async [a0_0xfd150d(0x208)](_0x353d5b) {
    const _0x4720a5 = a0_0xfd150d;
    return (
      await this[_0x4720a5(0x1a5)](_0x353d5b),
      await this[_0x4720a5(0x173)](_0x353d5b),
      await this[_0x4720a5(0x1f4)](_0x353d5b),
      await this["deleteLiveStrategyDetails"](_0x353d5b),
      await this[_0x4720a5(0x216)](_0x353d5b),
      await this["deleteSimStrategyEvents"](_0x353d5b),
      await this[_0x4720a5(0x202)](_0x353d5b),
      await this[_0x4720a5(0x1f5)](_0x353d5b),
      this["deleteOne"](
        _0x353d5b,
        this[_0x4720a5(0x1d6)],
        this[_0x4720a5(0x17f)]
      )
    );
  }
  ["getLiveStrategyTrades"](_0x3f34a0) {
    const _0x545f8b = a0_0xfd150d;
    return this[_0x545f8b(0x1df)](
      _0x3f34a0,
      this[_0x545f8b(0x226)],
      this[_0x545f8b(0x20b)],
      this["mutexStrategies"]
    );
  }
  [a0_0xfd150d(0x1c6)](_0x4d78bd) {
    const _0x4aea44 = a0_0xfd150d;
    return this[_0x4aea44(0x1ec)](
      _0x4d78bd,
      this[_0x4aea44(0x226)],
      this[_0x4aea44(0x17f)]
    );
  }
  ["deleteLiveStrategyTrades"](_0xc13965) {
    const _0x304ca6 = a0_0xfd150d;
    return this[_0x304ca6(0x1d4)](
      _0xc13965,
      this[_0x304ca6(0x226)],
      this[_0x304ca6(0x20b)],
      this[_0x304ca6(0x17f)]
    );
  }
  [a0_0xfd150d(0x16e)](_0x1d7ac6) {
    const _0x519fd8 = a0_0xfd150d;
    return this[_0x519fd8(0x166)](
      _0x1d7ac6,
      this[_0x519fd8(0x226)],
      this["mutexStrategies"]
    );
  }
  [a0_0xfd150d(0x1e4)](_0x354ba6) {
    const _0x521531 = a0_0xfd150d;
    return this[_0x521531(0x19e)](
      _0x354ba6,
      this[_0x521531(0x226)],
      this[_0x521531(0x17f)]
    );
  }
  [a0_0xfd150d(0x1de)](_0x37358c) {
    const _0x227bfa = a0_0xfd150d;
    return this["getAllByIndex"](
      _0x37358c,
      this[_0x227bfa(0x1dd)],
      this[_0x227bfa(0x21f)],
      this[_0x227bfa(0x17f)]
    );
  }
  [a0_0xfd150d(0x173)](_0x428999) {
    const _0x2bb640 = a0_0xfd150d;
    return this[_0x2bb640(0x1d4)](
      _0x428999,
      this[_0x2bb640(0x1dd)],
      this[_0x2bb640(0x21f)],
      this["mutexStrategies"]
    );
  }
  [a0_0xfd150d(0x20e)](_0x4beb0d) {
    const _0x22a562 = a0_0xfd150d;
    return this[_0x22a562(0x166)](
      _0x4beb0d,
      this[_0x22a562(0x1dd)],
      this["mutexStrategies"]
    );
  }
  [a0_0xfd150d(0x1f2)](_0x56cb91) {
    const _0x4b4a77 = a0_0xfd150d;
    return this[_0x4b4a77(0x19e)](
      _0x56cb91,
      this[_0x4b4a77(0x1dd)],
      this[_0x4b4a77(0x17f)]
    );
  }
  ["getLiveStrategyStatuses"](_0x1d4e7d) {
    const _0x3d7384 = a0_0xfd150d;
    return this["getAll"](
      _0x1d4e7d,
      this[_0x3d7384(0x1cd)],
      this[_0x3d7384(0x17f)]
    );
  }
  [a0_0xfd150d(0x22d)](_0x2f775b) {
    const _0x5bd2e9 = a0_0xfd150d;
    return this[_0x5bd2e9(0x1ec)](
      _0x2f775b,
      this[_0x5bd2e9(0x1cd)],
      this[_0x5bd2e9(0x17f)]
    );
  }
  [a0_0xfd150d(0x1dc)](_0x4e511a) {
    const _0x548b43 = a0_0xfd150d;
    return this[_0x548b43(0x166)](
      _0x4e511a,
      this[_0x548b43(0x1cd)],
      this[_0x548b43(0x17f)]
    );
  }
  ["deleteLiveStrategyStatus"](_0x4ea331) {
    const _0x466640 = a0_0xfd150d;
    return this[_0x466640(0x215)](
      _0x4ea331,
      this["strategyLiveStatus"],
      this[_0x466640(0x17f)]
    );
  }
  [a0_0xfd150d(0x1f1)](_0x31d7eb) {
    const _0x3c04e6 = a0_0xfd150d;
    return this[_0x3c04e6(0x1ec)](
      _0x31d7eb,
      this[_0x3c04e6(0x183)],
      this["mutexStrategies"]
    );
  }
  [a0_0xfd150d(0x1a6)](_0x36ccbe) {
    const _0x1ed56e = a0_0xfd150d;
    return this[_0x1ed56e(0x18e)](
      _0x36ccbe,
      this[_0x1ed56e(0x183)],
      this[_0x1ed56e(0x17f)]
    );
  }
  ["storeLiveStrategyDetails"](_0x3e2ff5) {
    const _0x583feb = a0_0xfd150d;
    return this["storeOne"](
      _0x3e2ff5,
      this["strategyLiveDetails"],
      this[_0x583feb(0x17f)]
    );
  }
  [a0_0xfd150d(0x16f)](_0x5b7b57) {
    const _0x51a7f6 = a0_0xfd150d;
    return this[_0x51a7f6(0x215)](
      _0x5b7b57,
      this[_0x51a7f6(0x183)],
      this[_0x51a7f6(0x17f)]
    );
  }
  [a0_0xfd150d(0x1ad)](_0xe81dd0) {
    const _0x4ee3d2 = a0_0xfd150d;
    return this[_0x4ee3d2(0x1df)](
      _0xe81dd0,
      this[_0x4ee3d2(0x1eb)],
      this[_0x4ee3d2(0x21d)],
      this[_0x4ee3d2(0x17f)]
    );
  }
  [a0_0xfd150d(0x22a)](_0x54957c) {
    const _0x1d015b = a0_0xfd150d;
    return this[_0x1d015b(0x1ec)](
      _0x54957c,
      this[_0x1d015b(0x1eb)],
      this[_0x1d015b(0x17f)]
    );
  }
  [a0_0xfd150d(0x216)](_0x164795) {
    const _0x3512c5 = a0_0xfd150d;
    return this[_0x3512c5(0x1d4)](
      _0x164795,
      this[_0x3512c5(0x1eb)],
      this[_0x3512c5(0x21d)],
      this[_0x3512c5(0x17f)]
    );
  }
  [a0_0xfd150d(0x178)](_0x4dddd9) {
    const _0x11f858 = a0_0xfd150d;
    return this[_0x11f858(0x166)](
      _0x4dddd9,
      this["strategySimTrade"],
      this[_0x11f858(0x17f)]
    );
  }
  [a0_0xfd150d(0x1bf)](_0x33df0a) {
    const _0x16c35f = a0_0xfd150d;
    return this["storeMany"](
      _0x33df0a,
      this[_0x16c35f(0x1eb)],
      this[_0x16c35f(0x17f)]
    );
  }
  ["getSimStrategyEvents"](_0x4e9944) {
    const _0x5db5c2 = a0_0xfd150d;
    return this[_0x5db5c2(0x1df)](
      _0x4e9944,
      this[_0x5db5c2(0x1a1)],
      this[_0x5db5c2(0x15f)],
      this[_0x5db5c2(0x17f)]
    );
  }
  ["deleteSimStrategyEvents"](_0x435906) {
    const _0x2510b7 = a0_0xfd150d;
    return this[_0x2510b7(0x1d4)](
      _0x435906,
      this[_0x2510b7(0x1a1)],
      this[_0x2510b7(0x15f)],
      this[_0x2510b7(0x17f)]
    );
  }
  ["storeSimStrategyEvent"](_0x3bb26c) {
    const _0x448fd4 = a0_0xfd150d;
    return this[_0x448fd4(0x166)](
      _0x3bb26c,
      this[_0x448fd4(0x1a1)],
      this[_0x448fd4(0x17f)]
    );
  }
  [a0_0xfd150d(0x225)](_0x5d4241) {
    const _0x50dae = a0_0xfd150d;
    return this["storeMany"](
      _0x5d4241,
      this[_0x50dae(0x1a1)],
      this[_0x50dae(0x17f)]
    );
  }
  ["getSimStrategyStatuses"](_0x436aa2) {
    const _0x17a13a = a0_0xfd150d;
    return this["getAll"](
      _0x436aa2,
      this[_0x17a13a(0x1f0)],
      this[_0x17a13a(0x17f)]
    );
  }
  [a0_0xfd150d(0x206)](_0x21f609) {
    const _0x41f63e = a0_0xfd150d;
    return this["getOne"](
      _0x21f609,
      this[_0x41f63e(0x1f0)],
      this["mutexStrategies"]
    );
  }
  ["storeSimStrategyStatus"](_0xf64782) {
    const _0x2a6a6b = a0_0xfd150d;
    return this[_0x2a6a6b(0x166)](
      _0xf64782,
      this[_0x2a6a6b(0x1f0)],
      this[_0x2a6a6b(0x17f)]
    );
  }
  [a0_0xfd150d(0x202)](_0xfada19) {
    const _0x3a8fdb = a0_0xfd150d;
    return this[_0x3a8fdb(0x215)](
      _0xfada19,
      this["strategySimStatus"],
      this[_0x3a8fdb(0x17f)]
    );
  }
  [a0_0xfd150d(0x1e7)](_0x302f4b) {
    const _0x52e114 = a0_0xfd150d;
    return this[_0x52e114(0x1ec)](
      _0x302f4b,
      this[_0x52e114(0x16a)],
      this[_0x52e114(0x17f)]
    );
  }
  [a0_0xfd150d(0x1e5)](_0x4c93c8) {
    const _0x82ef26 = a0_0xfd150d;
    return this[_0x82ef26(0x18e)](
      _0x4c93c8,
      this[_0x82ef26(0x16a)],
      this[_0x82ef26(0x17f)]
    );
  }
  [a0_0xfd150d(0x169)](_0x393e84) {
    const _0xadbe67 = a0_0xfd150d;
    return this[_0xadbe67(0x166)](
      _0x393e84,
      this[_0xadbe67(0x16a)],
      this[_0xadbe67(0x17f)]
    );
  }
  [a0_0xfd150d(0x1f5)](_0x1dc5c9) {
    const _0x2224b7 = a0_0xfd150d;
    return this[_0x2224b7(0x215)](
      _0x1dc5c9,
      this[_0x2224b7(0x16a)],
      this[_0x2224b7(0x17f)]
    );
  }
  [a0_0xfd150d(0x21c)](_0x1f7916) {
    const _0x1aa622 = a0_0xfd150d;
    return this[_0x1aa622(0x166)](
      _0x1f7916,
      this[_0x1aa622(0x1da)],
      this[_0x1aa622(0x17f)]
    );
  }
  [a0_0xfd150d(0x195)](_0x1f5406) {
    const _0x34425a = a0_0xfd150d;
    return this["storeMany"](
      _0x1f5406,
      this[_0x34425a(0x1da)],
      this[_0x34425a(0x17f)]
    );
  }
  ["deleteContestStrategy"](_0x11e0df) {
    const _0x108708 = a0_0xfd150d;
    return this[_0x108708(0x215)](
      _0x11e0df,
      this[_0x108708(0x1da)],
      this[_0x108708(0x17f)]
    );
  }
  [a0_0xfd150d(0x1d7)](_0xe69969) {
    const _0x11cee6 = a0_0xfd150d;
    return this[_0x11cee6(0x18e)](
      _0xe69969,
      this[_0x11cee6(0x1da)],
      this["mutexStrategies"]
    );
  }
  ["storeError"](_0x5c989f) {
    const _0x5e9538 = a0_0xfd150d;
    return this["storeOne"](
      _0x5c989f,
      this["errorsStore"],
      this[_0x5e9538(0x19d)]
    );
  }
  ["getErrors"](_0x9a883f) {
    const _0x187e11 = a0_0xfd150d;
    return this[_0x187e11(0x18e)](
      _0x9a883f,
      this[_0x187e11(0x165)],
      this[_0x187e11(0x19d)]
    );
  }
  [a0_0xfd150d(0x1d3)](_0x36b78a) {
    const _0x1c3f96 = a0_0xfd150d;
    return this[_0x1c3f96(0x215)](
      _0x36b78a,
      this[_0x1c3f96(0x165)],
      this["mutexErrors"]
    );
  }
  [a0_0xfd150d(0x15e)](_0x37c3ec) {
    const _0x1dff73 = a0_0xfd150d;
    return this["storeOne"](
      { ..._0x37c3ec, strategy: null },
      this[_0x1dff73(0x1d8)],
      this["mutexExecutions"]
    );
  }
  [a0_0xfd150d(0x1b3)](_0x4b6178) {
    const _0xf6106 = a0_0xfd150d;
    return this[_0xf6106(0x19e)](
      _0x4b6178["map"]((_0x6a6b06) => ({ ..._0x6a6b06, strategy: null })),
      this["executionsStore"],
      this[_0xf6106(0x192)]
    );
  }
  [a0_0xfd150d(0x219)](_0x5cd0e2) {
    const _0x2a2aa2 = a0_0xfd150d;
    return this[_0x2a2aa2(0x215)](
      _0x5cd0e2,
      this[_0x2a2aa2(0x1d8)],
      this[_0x2a2aa2(0x192)]
    );
  }
  ["getExecution"](_0x172f3e) {
    const _0x31a4e0 = a0_0xfd150d;
    return this[_0x31a4e0(0x1ec)](
      _0x172f3e,
      this[_0x31a4e0(0x1d8)],
      this[_0x31a4e0(0x192)]
    );
  }
  [a0_0xfd150d(0x1fe)](_0x496d97) {
    const _0x5dd22e = a0_0xfd150d;
    return this["getAll"](
      _0x496d97,
      this[_0x5dd22e(0x1d8)],
      this[_0x5dd22e(0x192)]
    );
  }
  [a0_0xfd150d(0x1a2)](_0x289d30) {
    const _0x27e720 = a0_0xfd150d;
    return this[_0x27e720(0x166)](
      _0x289d30,
      this[_0x27e720(0x21e)],
      this["mutexKeys"]
    );
  }
  ["deleteKey"](_0x2f5ecf) {
    const _0x2e7eaa = a0_0xfd150d;
    return this[_0x2e7eaa(0x215)](
      _0x2f5ecf,
      this[_0x2e7eaa(0x21e)],
      this[_0x2e7eaa(0x163)]
    );
  }
  [a0_0xfd150d(0x161)](_0x1076f8) {
    const _0x462b3f = a0_0xfd150d;
    return this["getAll"](
      _0x1076f8,
      this[_0x462b3f(0x21e)],
      this[_0x462b3f(0x163)]
    );
  }
  async ["deleteBacktest"](_0x36c8e1) {
    const _0x4b4634 = a0_0xfd150d;
    await this[_0x4b4634(0x215)](
      _0x36c8e1,
      this["backtestsSlim"],
      this[_0x4b4634(0x1c4)]
    ),
      await this[_0x4b4634(0x215)](
        _0x36c8e1,
        this[_0x4b4634(0x18c)],
        this[_0x4b4634(0x1c4)]
      );
  }
  async [a0_0xfd150d(0x184)](_0x17c2d4) {
    const _0xcde3d8 = a0_0xfd150d;
    let _0x6436e0 = await this[_0xcde3d8(0x1e3)](_0x17c2d4);
    for (let _0xce2638 of _0x6436e0) {
      await this[_0xcde3d8(0x218)](_0xce2638["id"]);
    }
  }
  async [a0_0xfd150d(0x1fb)](_0xd8e6dc) {
    const _0xec90c2 = a0_0xfd150d;
    let _0x48c658 = await this[_0xec90c2(0x1ec)](
      _0xd8e6dc,
      this["backtests"],
      this[_0xec90c2(0x1c4)]
    );
    return _0x48c658 ? _0x48c658[_0xec90c2(0x171)] : null;
  }
  async ["getBacktests"](_0x5b5907) {
    const _0x160d37 = a0_0xfd150d;
    let _0xc1681f = await this[_0x160d37(0x18e)](
      _0x5b5907,
      this[_0x160d37(0x190)],
      this["mutexBacktests"],
      "strategyId"
    );
    _0xc1681f["sort"](
      (_0x59774e, _0x168666) =>
        _0x168666[_0x160d37(0x171)][_0x160d37(0x1ae)] -
        _0x59774e[_0x160d37(0x171)][_0x160d37(0x1ae)]
    );
    const _0x34ae8e = 0xa;
    if (_0xc1681f[_0x160d37(0x1b2)] > _0x34ae8e) {
      let _0x33319c = _0xc1681f[_0x160d37(0x17a)](
        _0x34ae8e,
        _0xc1681f["length"] - _0x34ae8e
      );
      for (let _0x8b5faf of _0x33319c) {
        await this[_0x160d37(0x218)](_0x8b5faf["id"]);
      }
    }
    return _0xc1681f;
  }
  async ["deleteOptimization"](_0x5e8ed9) {
    const _0x314e0f = a0_0xfd150d;
    await this[_0x314e0f(0x215)](
      _0x5e8ed9,
      this["optimizationsSlim"],
      this[_0x314e0f(0x1e0)]
    ),
      await this[_0x314e0f(0x215)](
        _0x5e8ed9,
        this["optimizations"],
        this[_0x314e0f(0x1e0)]
      );
  }
  async [a0_0xfd150d(0x209)](_0x595f5e) {
    const _0x3d082d = a0_0xfd150d;
    let _0x536d08 = await this[_0x3d082d(0x186)](_0x595f5e);
    for (let _0x10e9c4 of _0x536d08) {
      await this[_0x3d082d(0x1a7)](_0x10e9c4["id"]);
    }
  }
  async ["getOptimization"](_0x5abac5) {
    const _0x493e17 = a0_0xfd150d;
    let _0x13dbd3 = await this["getOne"](
      _0x5abac5,
      this[_0x493e17(0x181)],
      this[_0x493e17(0x1e0)]
    );
    return _0x13dbd3 ? _0x13dbd3[_0x493e17(0x1b4)] : null;
  }
  async [a0_0xfd150d(0x186)](_0x48b660) {
    const _0x87415f = a0_0xfd150d;
    let _0x44e1a2 = await this["getAll"](
      _0x48b660,
      this["optimizationsSlim"],
      this[_0x87415f(0x1e0)],
      _0x87415f(0x1b6)
    );
    _0x44e1a2["sort"](
      (_0x7bc420, _0x56594a) =>
        _0x56594a["optimization"]["creationDate"] -
        _0x7bc420[_0x87415f(0x1b4)][_0x87415f(0x1ae)]
    );
    const _0x10fe55 = 0xa;
    if (_0x44e1a2[_0x87415f(0x1b2)] > _0x10fe55) {
      let _0xaf7212 = _0x44e1a2[_0x87415f(0x17a)](
        _0x10fe55,
        _0x44e1a2[_0x87415f(0x1b2)] - _0x10fe55
      );
      for (let _0x1eb37c of _0xaf7212) {
        await this[_0x87415f(0x1a7)](_0x1eb37c["id"]);
      }
    }
    return _0x44e1a2;
  }
  [a0_0xfd150d(0x1a0)]() {
    const _0x42b2ed = a0_0xfd150d;
    return this[_0x42b2ed(0x1b0)](
      this[_0x42b2ed(0x17d)],
      this[_0x42b2ed(0x1e0)]
    );
  }
  ["storeStrategyGroup"](_0x4d826f) {
    const _0x8a0d2a = a0_0xfd150d;
    return this["storeOne"](
      _0x4d826f,
      this[_0x8a0d2a(0x1c7)],
      this["mutexStrategyGroups"]
    );
  }
  [a0_0xfd150d(0x1a4)](_0x648db5) {
    const _0x5d7c4d = a0_0xfd150d;
    return this[_0x5d7c4d(0x215)](
      _0x648db5,
      this[_0x5d7c4d(0x1c7)],
      this[_0x5d7c4d(0x1c3)]
    );
  }
  [a0_0xfd150d(0x160)]() {
    const _0x1c638e = a0_0xfd150d;
    return this[_0x1c638e(0x1b0)](
      this[_0x1c638e(0x1c7)],
      this[_0x1c638e(0x1c3)]
    );
  }
  [a0_0xfd150d(0x170)](_0x32da7e) {
    const _0x476145 = a0_0xfd150d;
    return this["getAll"](
      _0x32da7e,
      this[_0x476145(0x1c7)],
      this[_0x476145(0x1c3)]
    );
  }
  [a0_0xfd150d(0x229)](_0x3b97b0) {
    const _0x217ffc = a0_0xfd150d;
    return this[_0x217ffc(0x166)](
      _0x3b97b0,
      this[_0x217ffc(0x1b1)],
      this[_0x217ffc(0x1a9)]
    );
  }
  [a0_0xfd150d(0x1b7)](_0x341af6) {
    const _0x3c10f9 = a0_0xfd150d;
    return this[_0x3c10f9(0x215)](
      _0x341af6,
      this[_0x3c10f9(0x1b1)],
      this[_0x3c10f9(0x1a9)]
    );
  }
  [a0_0xfd150d(0x164)]() {
    const _0x4514bd = a0_0xfd150d;
    return this[_0x4514bd(0x1b0)](this["watchlists"], this[_0x4514bd(0x1a9)]);
  }
  ["getWatchlists"](_0x34652c) {
    const _0x53eccd = a0_0xfd150d;
    return this["getAll"](
      _0x34652c,
      this[_0x53eccd(0x1b1)],
      this[_0x53eccd(0x1a9)]
    );
  }
  [a0_0xfd150d(0x20c)](_0x80ea80) {
    const _0x17c860 = a0_0xfd150d;
    return this["storeOne"](
      _0x80ea80,
      this[_0x17c860(0x197)],
      this[_0x17c860(0x1d9)]
    );
  }
  ["deleteMultiChart"](_0x113164) {
    const _0x2ab934 = a0_0xfd150d;
    return this[_0x2ab934(0x215)](
      _0x113164,
      this["multiCharts"],
      this[_0x2ab934(0x1d9)]
    );
  }
  [a0_0xfd150d(0x22c)]() {
    const _0x32a241 = a0_0xfd150d;
    return this[_0x32a241(0x1b0)](
      this[_0x32a241(0x197)],
      this["mutexmultiCharts"]
    );
  }
  ["getMultiCharts"](_0xc6f7fe) {
    return this["getAll"](
      _0xc6f7fe,
      this["multiCharts"],
      this["mutexmultiCharts"]
    );
  }
  ["storeIndicators"](_0x12d88f) {
    const _0x31388f = a0_0xfd150d;
    return this[_0x31388f(0x166)](
      _0x12d88f,
      this[_0x31388f(0x1bd)],
      this[_0x31388f(0x1d1)]
    );
  }
  ["getIndicators"](_0x239736) {
    const _0x5810fb = a0_0xfd150d;
    return this["getOne"](
      _0x239736,
      this[_0x5810fb(0x1bd)],
      this[_0x5810fb(0x1d1)]
    );
  }
  [a0_0xfd150d(0x1c1)](_0x2b3915) {
    const _0x21f779 = a0_0xfd150d;
    return this["storeOne"](
      _0x2b3915,
      this[_0x21f779(0x1d5)],
      this["mutexBotTradingView"]
    );
  }
  [a0_0xfd150d(0x1ab)](_0x3901ba) {
    const _0x1ccc13 = a0_0xfd150d;
    return this[_0x1ccc13(0x1ec)](
      _0x3901ba,
      this[_0x1ccc13(0x1d5)],
      this[_0x1ccc13(0x1e8)]
    );
  }
  ["storeManualTrade"](_0x4d8430) {
    const _0x3d87c2 = a0_0xfd150d;
    return this[_0x3d87c2(0x166)](
      _0x4d8430,
      this["manualTrades"],
      this[_0x3d87c2(0x18d)]
    );
  }
  [a0_0xfd150d(0x1e6)](_0x58fc75) {
    const _0x3184bc = a0_0xfd150d;
    return this["getAll"](
      _0x58fc75,
      this[_0x3184bc(0x205)],
      this["mutexManualTrades"]
    );
  }
  [a0_0xfd150d(0x210)](_0x37ee4c) {
    const _0x8c7e04 = a0_0xfd150d;
    return this[_0x8c7e04(0x215)](
      _0x37ee4c,
      this[_0x8c7e04(0x205)],
      this["mutexManualTrades"]
    );
  }
  [a0_0xfd150d(0x1db)](_0x4efb59) {
    return this["storeOne"](
      _0x4efb59,
      this["manualCloseTrades"],
      this["mutexManualTrades"]
    );
  }
  [a0_0xfd150d(0x1cf)](_0xd86c89) {
    const _0x3b8a3f = a0_0xfd150d;
    return this[_0x3b8a3f(0x18e)](
      _0xd86c89,
      this[_0x3b8a3f(0x1f8)],
      this["mutexManualTrades"]
    );
  }
  [a0_0xfd150d(0x1ea)](_0x1a1ff8) {
    const _0x1447ef = a0_0xfd150d;
    return this[_0x1447ef(0x215)](
      _0x1a1ff8,
      this[_0x1447ef(0x1f8)],
      this["mutexManualTrades"]
    );
  }
  ["storeDailyPLSims"](_0x46dfe9) {
    const _0x3f8204 = a0_0xfd150d;
    return this["storeOne"](
      _0x46dfe9,
      this[_0x3f8204(0x1ef)],
      this["mutexDailyPls"]
    );
  }
  [a0_0xfd150d(0x1b5)](_0x2f5953) {
    const _0x293e5a = a0_0xfd150d;
    return this["getOne"](
      _0x2f5953,
      this[_0x293e5a(0x1ef)],
      this[_0x293e5a(0x21a)]
    );
  }
  [a0_0xfd150d(0x188)](_0xb31bd6) {
    const _0x5f1414 = a0_0xfd150d;
    return this[_0x5f1414(0x166)](
      _0xb31bd6,
      this[_0x5f1414(0x1ff)],
      this[_0x5f1414(0x21a)]
    );
  }
  [a0_0xfd150d(0x17b)](_0x173e76) {
    const _0x28efac = a0_0xfd150d;
    return this[_0x28efac(0x1ec)](
      _0x173e76,
      this["dailyPlsLiveStore"],
      this["mutexDailyPls"]
    );
  }
}
function a0_0x1f5e() {
  const _0x3868ad = [
    "createObjectStore",
    "dailyPlsSimStore",
    "strategySimStatus",
    "getLiveStrategyDetails",
    "storeLiveStrategyEvents",
    "\x20t:",
    "deleteLiveStrategyStatus",
    "deleteSimStrategyDetails",
    "createIndex",
    "continue",
    "manualCloseTrades",
    "6253256SVQaQP",
    "storeStrategy",
    "getBacktest",
    "1243866qaWcqJ",
    "storeHistoricalData",
    "getExecutions",
    "dailyPlsLiveStore",
    "preventDefault",
    "hitoricalDataChunks",
    "deleteSimStrategyStatus",
    "storeStrategies",
    "primaryKey",
    "manualTrades",
    "getSimStrategyStatus",
    "result",
    "deleteStrategy",
    "deleteOptimizations",
    "readonly",
    "strategyLiveTradeIndex",
    "storeMultiChart",
    "onsuccess",
    "storeLiveStrategyEvent",
    "pair",
    "deleteManualTrade",
    "hitoricalDataStore",
    "10400mAOZjt",
    "\x20p:\x20",
    "getStrategies",
    "deleteOne",
    "deleteSimStrategyTrades",
    "deleteHistoricalData",
    "deleteBacktest",
    "deleteExecution",
    "mutexDailyPls",
    "storeHistoricalDataChunks",
    "storeContestStrategy",
    "strategySimTradeIndex",
    "keys",
    "strategyLiveEventIndex",
    "mutexHistorical",
    "getSingleHistoricalDataChunk",
    "user",
    "Could\x20not\x20delete\x20backtests\x20",
    "objectStoreNames",
    "storeSimStrategyEvents",
    "strategyLiveTrade",
    "value",
    "826413zxgKHx",
    "storeWatchlist",
    "getSimStrategyTrade",
    "lock",
    "deleteMultiCharts",
    "getLiveStrategyStatus",
    "storeExecution",
    "strategySimEventIndex",
    "deleteOldStrategyGroups",
    "getKeys",
    "openCursor",
    "mutexKeys",
    "deleteWatchlists",
    "errorsStore",
    "storeOne",
    "Could\x20not\x20store\x20in\x20DB!\x20Store:\x20",
    "only",
    "storeSimStrategyDetails",
    "strategySimDetails",
    "INVALID\x20DATA\x20IN\x20deleteHistoricalData:\x20e:\x20",
    "hitoricalDataChunksStore",
    "Could\x20not\x20storeMany\x20in\x20DB!",
    "storeLiveStrategyTrade",
    "deleteLiveStrategyDetails",
    "getStrategyGroups",
    "backtest",
    "month",
    "deleteLiveStrategyEvents",
    "release",
    "put",
    "target",
    "hitoricalDataIndex",
    "storeSimStrategyTrade",
    "error",
    "splice",
    "getDailyPLLive",
    "hitoricalData",
    "optimizationIndicators",
    "sId",
    "mutexStrategies",
    "strategiesStoreOld",
    "optimizations",
    "537HxbUbh",
    "strategyLiveDetails",
    "deleteBacktests",
    "hitoricalDataStoreIndex",
    "getOptimizations",
    "executions",
    "storeDailyPLLive",
    "INVALID\x20DATA\x20IN\x20getHistoricalData:\x20e:\x20",
    "openKeyCursor",
    "storeOldStrategies",
    "backtests",
    "mutexManualTrades",
    "getAll",
    "readwrite",
    "backtestsSlim",
    "index",
    "mutexExecutions",
    "ConstraintError",
    "INVALID\x20DATA\x20IN\x20getHistoricalDataChunks:\x20e:\x20",
    "storeContestStrategies",
    "objectStore",
    "multiCharts",
    "open",
    "userId",
    "onerror",
    "push",
    "log",
    "mutexErrors",
    "storeMany",
    "optimizationsSlim",
    "deleteOptimizationIndicators",
    "strategySimEvent",
    "storeKey",
    "transaction",
    "deleteStrategyGroup",
    "deleteLiveStrategyTrades",
    "getLiveStrategyDetailses",
    "deleteOptimization",
    "version",
    "mutexWatchlists",
    "exchange",
    "getBotTradingView",
    "getStrategy",
    "getSimStrategyTrades",
    "creationDate",
    "onupgradeneeded",
    "deleteAll",
    "watchlists",
    "length",
    "storeExecutions",
    "optimization",
    "getDailyPLSims",
    "strategyId",
    "deleteWatchlist",
    "name",
    "deleteOldStrategy",
    "7yPepDX",
    "getHistoricalDataChunks",
    "getHistoricalData",
    "indicators",
    "116010ImDZWU",
    "storeSimStrategyTrades",
    "errors",
    "storeBotTradingView",
    "Could\x20not\x20store\x20data\x20in\x20DB!\x20",
    "mutexStrategyGroups",
    "mutexBacktests",
    "strategies",
    "getLiveStrategyTrade",
    "strategyGroups",
    "forEach",
    "2421rBWkNf",
    "ecbDb",
    "strategy",
    "contains",
    "strategyLiveStatus",
    "sort",
    "getManualCloseTrades",
    "storeOldStrategy",
    "mutexIndicators",
    "get",
    "deleteError",
    "deleteAllByIndex",
    "botTradingView",
    "strategyStore",
    "getContestStrategies",
    "executionsStore",
    "mutexmultiCharts",
    "strategiesContestStore",
    "storeManualCloseTrade",
    "storeLiveStrategyStatus",
    "strategyLiveEvent",
    "getLiveStrategyEvents",
    "getAllByIndex",
    "mutexOptimizations",
    "4460130nbNQWg",
    "delete",
    "getBacktests",
    "storeLiveStrategyTrades",
    "getSimStrategyDetailses",
    "getManualTrades",
    "getSimStrategyDetails",
    "mutexBotTradingView",
    "948320iAcHqo",
    "deleteManualCloseTrade",
    "strategySimTrade",
    "getOne",
    "Could\x20not\x20deleteOne\x20",
  ];
  a0_0x1f5e = function () {
    return _0x3868ad;
  };
  return a0_0x1f5e();
}
let ecbDb = new EcbDatabase();
export default function getEcbDatabase() {
  return ecbDb;
}
