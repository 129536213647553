import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import loadingGif from "./assets/images/loading-reverced.gif";
import { globalContext } from "./Context.js";

const TraficSaver = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const [loading, setLoading] = useState(false);
  return (
    //<CommingSoon title="Portfolio" />
    <div className="page mx-4">
      {loading ? (
        <div className="loading-img cursor-help mt-5 mb-5" title="Loading..">
          <img src={loadingGif} alt="" />
        </div>
      ) : (
        <>
          <div className="py-4">Mobile Internet Trafic:</div>
          <a
            className="btn btn-sm btn-info btn-block font-weight-bold w-75 py-3 mb-4 mx-auto"
            href="#/"
            onClick={(e) => {
              e.preventDefault();
              setLoading(true);
              dispatch({ type: "trafic", payload: "normal" });
            }}
          >
            Normal
            <FontAwesomeIcon icon={faCaretRight} className="ml-2 blink-slow" />
          </a>
          <a
            className="btn btn-sm btn-info btn-block font-weight-bold w-75 py-3 mx-auto"
            href="#/"
            onClick={(e) => {
              e.preventDefault();
              setLoading(true);
              dispatch({ type: "trafic", payload: "saver" });
            }}
          >
            Data Saver
            <FontAwesomeIcon icon={faCaretRight} className="ml-2 blink-slow" />
          </a>
        </>
      )}
    </div>
  );
};

export default TraficSaver;
