/* eslint-disable no-unused-expressions */
/* eslint-disable no-func-assign */
/* eslint-disable no-sequences */
const a0_0x29779a = a0_0x3fc6;
(function (_0x4dc825, _0x15e85f) {
  const _0x281405 = a0_0x3fc6,
    _0x505e7d = _0x4dc825();
  while (!![]) {
    try {
      const _0x9fd086 =
        (-parseInt(_0x281405(0x220)) / 0x1) *
          (-parseInt(_0x281405(0x1f5)) / 0x2) +
        parseInt(_0x281405(0x20a)) / 0x3 +
        -parseInt(_0x281405(0x1fa)) / 0x4 +
        (-parseInt(_0x281405(0x208)) / 0x5) *
          (parseInt(_0x281405(0x1f3)) / 0x6) +
        -parseInt(_0x281405(0x265)) / 0x7 +
        parseInt(_0x281405(0x1eb)) / 0x8 +
        parseInt(_0x281405(0x1fe)) / 0x9;
      if (_0x9fd086 === _0x15e85f) break;
      else _0x505e7d["push"](_0x505e7d["shift"]());
    } catch (_0x467303) {
      _0x505e7d["push"](_0x505e7d["shift"]());
    }
  }
})(a0_0x36d0, 0x1b9eb);
function fixNumber(_0x330ad6, _0x535e99 = 0x8) {
  const _0x12212 = a0_0x3fc6;
  return Number["parseFloat"](_0x330ad6[_0x12212(0x23a)](_0x535e99));
}
function calculateStdDev(_0xabb0d0) {
  const _0x394cf6 = a0_0x3fc6;
  if (_0xabb0d0[_0x394cf6(0x222)] > 0x0) {
    let _0x59e79e =
      _0xabb0d0["reduce"](
        (_0x128ae0, _0x1ec77e) => _0x128ae0 + _0x1ec77e,
        0x0
      ) / _0xabb0d0["length"];
    return Math[_0x394cf6(0x201)](
      _0xabb0d0["reduce"](
        (_0x5b278b, _0x13b343) =>
          _0x5b278b[_0x394cf6(0x212)]((_0x13b343 - _0x59e79e) ** 0x2),
        []
      )[_0x394cf6(0x26d)](
        (_0x58f2b7, _0xfdcacf) => _0x58f2b7 + _0xfdcacf,
        0x0
      ) / _0xabb0d0["length"]
    );
  } else return 0x0;
}
function fillPeriodData(_0x56af29, _0x564188, _0x33a818) {
  const _0x50571d = a0_0x3fc6;
  _0x56af29[_0x50571d(0x222)] < _0x33a818
    ? _0x56af29[_0x50571d(0x207)](_0x564188)
    : (_0x56af29["splice"](0x0, 0x1), _0x56af29[_0x50571d(0x207)](_0x564188));
}
function calculateSma(_0x4fc60b, _0x9ba684, _0x1f95b) {
  const _0x59b685 = a0_0x3fc6;
  let _0x50fc22 = [],
    _0xd2a48e = _0x4fc60b[_0x59b685(0x253)]((_0x3e38a2) => {
      const _0x32af81 = _0x59b685;
      fillPeriodData(_0x50fc22, _0x3e38a2["c"], _0x9ba684);
      if (_0x50fc22[_0x32af81(0x222)] === _0x9ba684) {
        let _0x404ea5 = {
          d: _0x3e38a2["d"],
          cd: _0x3e38a2["cd"],
          ma:
            _0x50fc22["reduce"](
              (_0x22a5f2, _0x354d82) => _0x22a5f2 + _0x354d82,
              0x0
            ) / _0x9ba684,
        };
        if (_0x1f95b === _0x32af81(0x24f) && _0x3e38a2[_0x32af81(0x20d)]) {
          let _0x80be5c = [..._0x50fc22];
          _0x404ea5[_0x32af81(0x20d)] = _0x3e38a2["subData"]["map"](
            (_0x50d06e) => {
              const _0x21c38b = _0x32af81;
              _0x80be5c["splice"](
                _0x80be5c[_0x21c38b(0x222)] - 0x1,
                0x1,
                _0x50d06e["c"]
              );
              let _0x4cf064 =
                _0x80be5c[_0x21c38b(0x26d)](
                  (_0x2ebf06, _0x46a36b) => _0x2ebf06 + _0x46a36b,
                  0x0
                ) / _0x9ba684;
              return { d: _0x50d06e["d"], cd: _0x50d06e["cd"], ma: _0x4cf064 };
            }
          );
        }
        return _0x404ea5;
      } else return null;
    });
  return _0xd2a48e;
}
function calculateEma(_0x2a551e, _0xef7302, _0x35f076) {
  const _0x5e0a5d = a0_0x3fc6;
  let _0x717f8a = 0x2 / (_0xef7302 + 0x1),
    _0x20514b = _0x2a551e[0x0]["c"],
    _0x5d6bad = _0x2a551e[_0x5e0a5d(0x253)]((_0x3f5df0) => {
      const _0x1a76d7 = _0x5e0a5d;
      let _0x1a0b2e = {
        d: _0x3f5df0["d"],
        cd: _0x3f5df0["cd"],
        ma: fixNumber(
          (_0x3f5df0["c"] - _0x20514b) * _0x717f8a + _0x20514b,
          0x8
        ),
      };
      return (
        _0x35f076 === _0x1a76d7(0x24f) &&
          _0x3f5df0[_0x1a76d7(0x20d)] &&
          (_0x1a0b2e[_0x1a76d7(0x20d)] = _0x3f5df0[_0x1a76d7(0x20d)]["map"](
            (_0x2e24b5) => {
              return {
                d: _0x2e24b5["d"],
                cd: _0x2e24b5["cd"],
                ma: fixNumber(
                  (_0x2e24b5["c"] - _0x20514b) * _0x717f8a + _0x20514b,
                  0x8
                ),
              };
            }
          )),
        (_0x20514b = _0x1a0b2e["ma"]),
        _0x1a0b2e
      );
    });
  return _0x5d6bad;
}
function calculateRsi(_0x4309f2, _0x215531, _0x1a5d38) {
  let _0x36939a = null,
    _0x31cb70 = null,
    _0x393c26 = null,
    _0x39ae0b = _0x4309f2["map"]((_0x3c791a, _0x531bee, _0x2f7d94) => {
      const _0x37594d = a0_0x3fc6;
      if (_0x215531 > _0x531bee) return null;
      if (_0x36939a === null) {
        (_0x36939a = 0x0), (_0x31cb70 = 0x0);
        for (
          let _0x5853f3 = _0x531bee - (_0x215531 - 0x1) - 0x1;
          _0x5853f3 < _0x531bee;
          _0x5853f3++
        ) {
          let _0x4fdffa =
            _0x2f7d94[_0x5853f3 + 0x1]["c"] - _0x2f7d94[_0x5853f3]["c"];
          _0x4fdffa > 0x0
            ? (_0x36939a += _0x4fdffa)
            : (_0x31cb70 += Math["abs"](_0x4fdffa));
        }
        (_0x36939a /= _0x215531), (_0x31cb70 /= _0x215531);
      } else {
        let _0x4d09d1 =
            _0x2f7d94[_0x531bee]["c"] - _0x2f7d94[_0x531bee - 0x1]["c"],
          _0x34b703 = 0x0,
          _0x557a8c = 0x0;
        _0x4d09d1 > 0x0
          ? (_0x34b703 = _0x4d09d1)
          : (_0x557a8c = Math[_0x37594d(0x260)](_0x4d09d1)),
          _0x1a5d38 === _0x37594d(0x24f) &&
            _0x3c791a[_0x37594d(0x20d)] &&
            (_0x393c26 = _0x3c791a[_0x37594d(0x20d)][_0x37594d(0x253)](
              (_0x4281a0) => {
                let _0x1045a7 =
                    _0x4281a0["c"] - _0x2f7d94[_0x531bee - 0x1]["c"],
                  _0x30fa0f = 0x0,
                  _0x18feb0 = 0x0;
                _0x1045a7 > 0x0
                  ? (_0x30fa0f = _0x1045a7)
                  : (_0x18feb0 = Math["abs"](_0x1045a7));
                let _0x48f9ed =
                    (_0x36939a * (_0x215531 - 0x1) + _0x30fa0f) / _0x215531,
                  _0x21dc0c =
                    (_0x31cb70 * (_0x215531 - 0x1) + _0x18feb0) / _0x215531;
                return {
                  d: _0x4281a0["d"],
                  cd: _0x4281a0["cd"],
                  r:
                    _0x21dc0c === 0x0
                      ? 0x64
                      : fixNumber(
                          0x64 - 0x64 / (0x1 + _0x48f9ed / _0x21dc0c),
                          0x2
                        ),
                };
              }
            )),
          (_0x36939a = (_0x36939a * (_0x215531 - 0x1) + _0x34b703) / _0x215531),
          (_0x31cb70 = (_0x31cb70 * (_0x215531 - 0x1) + _0x557a8c) / _0x215531);
      }
      let _0x5e0dc6 = {
        d: _0x3c791a["d"],
        cd: _0x3c791a["cd"],
        r:
          _0x31cb70 === 0x0
            ? 0x64
            : fixNumber(0x64 - 0x64 / (0x1 + _0x36939a / _0x31cb70), 0x2),
      };
      return _0x393c26 && (_0x5e0dc6[_0x37594d(0x20d)] = _0x393c26), _0x5e0dc6;
    });
  return _0x39ae0b;
}
function calculateBB(
  _0x4f435c,
  _0x401642,
  _0x3b3062,
  _0x1d0018,
  _0x40c478,
  _0x444d87
) {
  const _0x96227d = a0_0x3fc6;
  let _0x188bfb = getIndicatorId({
      indicator: _0x96227d(0x21b),
      period: _0x401642,
      timeframe: _0x1d0018,
      mode: _0x40c478,
    }),
    _0x3f22a5 = _0x444d87[_0x188bfb];
  !_0x3f22a5 &&
    ((_0x3f22a5 = calculateSma(_0x4f435c, _0x401642, _0x40c478)),
    (_0x444d87[_0x188bfb] = _0x3f22a5));
  let _0x232d93 = [],
    _0x308658 = _0x4f435c[_0x96227d(0x253)]((_0x500fd2) => {
      const _0x386647 = _0x96227d;
      fillPeriodData(_0x232d93, _0x500fd2["c"], _0x401642);
      if (_0x232d93[_0x386647(0x222)] === _0x401642) {
        let _0x48217d = { std: fixNumber(calculateStdDev(_0x232d93), 0x8) };
        if (_0x40c478 === _0x386647(0x24f) && _0x500fd2[_0x386647(0x20d)]) {
          let _0x3528af = [..._0x232d93];
          _0x48217d[_0x386647(0x20d)] = _0x500fd2[_0x386647(0x20d)][
            _0x386647(0x253)
          ]((_0x1d3045) => {
            const _0xec9427 = _0x386647;
            return (
              _0x3528af[_0xec9427(0x23b)](
                _0x3528af["length"] - 0x1,
                0x1,
                _0x1d3045["c"]
              ),
              { std: fixNumber(calculateStdDev(_0x3528af), 0x8) }
            );
          });
        }
        return _0x48217d;
      } else return null;
    }),
    _0x399b55 = [];
  for (
    let _0x30311e = 0x0;
    _0x30311e < _0x308658[_0x96227d(0x222)];
    _0x30311e++
  ) {
    if (!_0x308658[_0x30311e] && !_0x3f22a5[_0x30311e]) {
      _0x399b55["push"](null);
      continue;
    }
    let _0x395596 =
        _0x3f22a5[_0x30311e]["ma"] +
        _0x308658[_0x30311e][_0x96227d(0x241)] * _0x3b3062,
      _0x36a1ef =
        _0x3f22a5[_0x30311e]["ma"] -
        _0x308658[_0x30311e][_0x96227d(0x241)] * _0x3b3062,
      _0x48c564 = ((_0x395596 - _0x36a1ef) / _0x3f22a5[_0x30311e]["ma"]) * 0x64,
      _0x565f71 = {
        d: _0x3f22a5[_0x30311e]["d"],
        cd: _0x3f22a5[_0x30311e]["cd"],
        w: fixNumber(_0x48c564, 0x4),
        u: fixNumber(_0x395596, 0x8),
        l: fixNumber(_0x36a1ef, 0x8),
      };
    if (
      _0x40c478 === _0x96227d(0x24f) &&
      _0x3f22a5[_0x30311e][_0x96227d(0x20d)]
    ) {
      _0x565f71["subData"] = [];
      if (
        _0x3f22a5[_0x30311e][_0x96227d(0x20d)] &&
        _0x308658[_0x30311e][_0x96227d(0x20d)]
      )
        for (
          let _0x5b3c68 = 0x0;
          _0x5b3c68 < _0x3f22a5[_0x30311e][_0x96227d(0x20d)][_0x96227d(0x222)];
          _0x5b3c68++
        ) {
          let _0x5672b8 =
              _0x3f22a5[_0x30311e][_0x96227d(0x20d)][_0x5b3c68]["ma"] +
              _0x308658[_0x30311e][_0x96227d(0x20d)][_0x5b3c68][
                _0x96227d(0x241)
              ] *
                _0x3b3062,
            _0x2b1c8c =
              _0x3f22a5[_0x30311e][_0x96227d(0x20d)][_0x5b3c68]["ma"] -
              _0x308658[_0x30311e][_0x96227d(0x20d)][_0x5b3c68]["std"] *
                _0x3b3062,
            _0x102ad1 =
              ((_0x5672b8 - _0x2b1c8c) /
                _0x3f22a5[_0x30311e][_0x96227d(0x20d)][_0x5b3c68]["ma"]) *
              0x64;
          _0x565f71["subData"][_0x96227d(0x207)]({
            d: _0x3f22a5[_0x30311e][_0x96227d(0x20d)][_0x5b3c68]["d"],
            cd: _0x3f22a5[_0x30311e][_0x96227d(0x20d)][_0x5b3c68]["cd"],
            w: fixNumber(_0x102ad1, 0x4),
            u: fixNumber(_0x5672b8, 0x8),
            l: fixNumber(_0x2b1c8c, 0x8),
          });
        }
    }
    _0x399b55["push"](_0x565f71);
  }
  return _0x399b55;
}
function calculateKC(
  _0x5bbc78,
  _0x3215c5,
  _0x4c8954,
  _0x35fb12,
  _0x5aa5d2,
  _0x442229
) {
  const _0x25b313 = a0_0x3fc6;
  let _0xdc6805 = _0x3215c5,
    _0x4338f8 = getIndicatorId({
      indicator: "EMA",
      period: _0x3215c5,
      timeframe: _0x35fb12,
      mode: _0x5aa5d2,
    }),
    _0x2399d6 = _0x442229[_0x4338f8];
  !_0x2399d6 &&
    ((_0x2399d6 = calculateEma(_0x5bbc78, _0x3215c5, _0x5aa5d2)),
    (_0x442229[_0x4338f8] = _0x2399d6));
  let _0x1cfb39 = getIndicatorId({
      indicator: _0x25b313(0x266),
      period: _0x3215c5,
      timeframe: _0x35fb12,
      mode: _0x5aa5d2,
    }),
    _0x49b8f8 = _0x442229[_0x1cfb39];
  !_0x49b8f8 &&
    ((_0x49b8f8 = calculateAtr(_0x5bbc78, _0xdc6805, _0x5aa5d2)),
    (_0x442229[_0x1cfb39] = _0x49b8f8));
  let _0x351413 = _0x2399d6[_0x25b313(0x253)]((_0x574572, _0x4c3d4b) => {
    const _0x202345 = _0x25b313;
    if (
      !_0x574572 ||
      !_0x574572["ma"] ||
      !_0x49b8f8[_0x4c3d4b] ||
      !_0x49b8f8[_0x4c3d4b][_0x202345(0x273)]
    )
      return null;
    let _0x29e347 =
        _0x574572["ma"] + _0x4c8954 * _0x49b8f8[_0x4c3d4b][_0x202345(0x273)],
      _0x3b9547 =
        _0x574572["ma"] - _0x4c8954 * _0x49b8f8[_0x4c3d4b][_0x202345(0x273)],
      _0x3e47b5 = ((_0x29e347 - _0x3b9547) / _0x574572["ma"]) * 0x64,
      _0x64e91a = {
        d: _0x574572["d"],
        cd: _0x574572["cd"],
        u: fixNumber(_0x29e347, 0x8),
        l: fixNumber(_0x3b9547, 0x8),
        w: fixNumber(_0x3e47b5, 0x4),
      };
    return (
      _0x5aa5d2 === _0x202345(0x24f) &&
        _0x574572[_0x202345(0x20d)] &&
        (_0x64e91a[_0x202345(0x20d)] = _0x574572[_0x202345(0x20d)][
          _0x202345(0x253)
        ]((_0x4aa77f, _0x48274d) => {
          const _0x3b628d = _0x202345;
          let _0x3d8f7a =
              _0x4aa77f["ma"] +
              _0x4c8954 *
                _0x49b8f8[_0x4c3d4b][_0x3b628d(0x20d)][_0x48274d]["atr"],
            _0x436f32 =
              _0x4aa77f["ma"] -
              _0x4c8954 *
                _0x49b8f8[_0x4c3d4b][_0x3b628d(0x20d)][_0x48274d][
                  _0x3b628d(0x273)
                ],
            _0x551ab2 = ((_0x3d8f7a - _0x436f32) / _0x4aa77f["ma"]) * 0x64,
            _0x5af79c = {
              d: _0x4aa77f["d"],
              cd: _0x4aa77f["cd"],
              u: fixNumber(_0x3d8f7a, 0x8),
              l: fixNumber(_0x436f32, 0x8),
              w: fixNumber(_0x551ab2, 0x4),
            };
          return _0x5af79c;
        })),
      _0x64e91a
    );
  });
  return _0x351413;
}
function calculateAtr(_0x2794af, _0x34cbd2, _0x2c52a7) {
  const _0x3e5163 = a0_0x3fc6;
  let _0x425a9d = [];
  for (let _0xb9188c = 0x0; _0xb9188c < _0x2794af["length"]; _0xb9188c++) {
    if (_0xb9188c === 0x0) {
      let _0x1755d0 = {
        d: _0x2794af[_0xb9188c]["d"],
        cd: _0x2794af[_0xb9188c]["cd"],
        tr: fixNumber(_0x2794af[_0xb9188c]["h"] - _0x2794af[_0xb9188c]["l"]),
      };
      _0x2c52a7 === "current" &&
        _0x2794af[_0xb9188c][_0x3e5163(0x20d)] &&
        ((_0x1755d0[_0x3e5163(0x20d)] = []),
        _0x2794af[_0xb9188c][_0x3e5163(0x20d)][_0x3e5163(0x1f8)](
          (_0x3d4690) => {
            const _0x2e4d5e = _0x3e5163;
            _0x1755d0["subData"][_0x2e4d5e(0x207)]({
              d: _0x3d4690["d"],
              cd: _0x3d4690["cd"],
              tr: fixNumber(_0x3d4690["h"] - _0x3d4690["l"]),
            });
          }
        )),
        _0x425a9d[_0x3e5163(0x207)](_0x1755d0);
    } else {
      let _0x1844f5 = {
        d: _0x2794af[_0xb9188c]["d"],
        cd: _0x2794af[_0xb9188c]["cd"],
        tr: Math[_0x3e5163(0x255)](
          fixNumber(_0x2794af[_0xb9188c]["h"] - _0x2794af[_0xb9188c]["l"], 0x2),
          fixNumber(
            Math[_0x3e5163(0x260)](
              _0x2794af[_0xb9188c]["h"] - _0x2794af[_0xb9188c - 0x1]["c"]
            )
          ),
          fixNumber(
            Math[_0x3e5163(0x260)](
              _0x2794af[_0xb9188c]["l"] - _0x2794af[_0xb9188c - 0x1]["c"]
            )
          )
        ),
      };
      _0x2c52a7 === _0x3e5163(0x24f) &&
        _0x2794af[_0xb9188c][_0x3e5163(0x20d)] &&
        ((_0x1844f5["subData"] = []),
        _0x2794af[_0xb9188c][_0x3e5163(0x20d)]["forEach"]((_0x1168f5) => {
          const _0x267c4a = _0x3e5163;
          _0x1844f5[_0x267c4a(0x20d)][_0x267c4a(0x207)]({
            d: _0x1168f5["d"],
            cd: _0x1168f5["cd"],
            tr: Math["max"](
              fixNumber(_0x1168f5["h"] - _0x1168f5["l"], 0x2),
              fixNumber(
                Math[_0x267c4a(0x260)](_0x1168f5["h"] - _0x1168f5["c"])
              ),
              fixNumber(Math[_0x267c4a(0x260)](_0x1168f5["l"] - _0x1168f5["c"]))
            ),
          });
        })),
        _0x425a9d["push"](_0x1844f5);
    }
  }
  let _0x2dd640 = [],
    _0xcc5265 = 0x0;
  for (
    let _0x55efd0 = 0x0;
    _0x55efd0 < _0x425a9d[_0x3e5163(0x222)];
    _0x55efd0++
  ) {
    _0x55efd0 <= _0x34cbd2 - 0x1 && (_0xcc5265 += _0x425a9d[_0x55efd0]["tr"]);
    if (_0x55efd0 < _0x34cbd2 - 0x1) {
      let _0x54b685 = {
        d: _0x425a9d[_0x55efd0]["d"],
        cd: _0x425a9d[_0x55efd0]["cd"],
        atr: null,
      };
      _0x2c52a7 === "current" &&
        _0x425a9d[_0x55efd0][_0x3e5163(0x20d)] &&
        (_0x54b685[_0x3e5163(0x20d)] = []),
        _0x2dd640["push"](_0x54b685);
    }
    if (_0x55efd0 === _0x34cbd2 - 0x1) {
      _0xcc5265 /= _0x34cbd2;
      let _0x2f8464 = {
        d: _0x425a9d[_0x55efd0]["d"],
        cd: _0x425a9d[_0x55efd0]["cd"],
        atr: fixNumber(_0xcc5265),
      };
      _0x2c52a7 === _0x3e5163(0x24f) &&
        _0x425a9d[_0x55efd0][_0x3e5163(0x20d)] &&
        ((_0x2f8464["subData"] = []),
        _0x425a9d[_0x55efd0]["subData"][_0x3e5163(0x1f8)]((_0x1d9d8a) =>
          _0x2f8464[_0x3e5163(0x20d)]["push"]({
            d: _0x1d9d8a["d"],
            cd: _0x1d9d8a["cd"],
            atr: fixNumber(_0xcc5265),
          })
        )),
        _0x2dd640[_0x3e5163(0x207)](_0x2f8464);
    }
    if (_0x55efd0 >= _0x34cbd2) {
      let _0x3be625 = {
        d: _0x425a9d[_0x55efd0]["d"],
        cd: _0x425a9d[_0x55efd0]["cd"],
        atr: fixNumber(
          (_0x2dd640[_0x55efd0 - 0x1][_0x3e5163(0x273)] * (_0x34cbd2 - 0x1) +
            _0x425a9d[_0x55efd0]["tr"]) /
            _0x34cbd2
        ),
      };
      _0x2c52a7 === _0x3e5163(0x24f) &&
        _0x425a9d[_0x55efd0][_0x3e5163(0x20d)] &&
        ((_0x3be625[_0x3e5163(0x20d)] = []),
        _0x425a9d[_0x55efd0][_0x3e5163(0x20d)]["forEach"]((_0x265340) =>
          _0x3be625["subData"]["push"]({
            d: _0x265340["d"],
            cd: _0x265340["cd"],
            atr: fixNumber(
              (_0x2dd640[_0x55efd0 - 0x1][_0x3e5163(0x273)] *
                (_0x34cbd2 - 0x1) +
                _0x265340["tr"]) /
                _0x34cbd2
            ),
          })
        )),
        _0x2dd640["push"](_0x3be625);
    }
  }
  return _0x2dd640;
}
function linearRegression(_0x2b5c99, _0x41985c) {
  const _0x4d3f07 = a0_0x3fc6;
  let _0x452fa9 = {},
    _0x10a319 = _0x2b5c99[_0x4d3f07(0x222)],
    _0x20b200 = 0x0,
    _0x209050 = 0x0,
    _0xba23dd = 0x0,
    _0x4d0172 = 0x0;
  for (
    let _0x19e321 = 0x0;
    _0x19e321 < _0x2b5c99[_0x4d3f07(0x222)];
    _0x19e321++
  ) {
    (_0x20b200 += _0x41985c[_0x19e321]),
      (_0x209050 += _0x2b5c99[_0x19e321]),
      (_0xba23dd += _0x41985c[_0x19e321] * _0x2b5c99[_0x19e321]),
      (_0x4d0172 += _0x41985c[_0x19e321] * _0x41985c[_0x19e321]);
  }
  return (
    (_0x452fa9[_0x4d3f07(0x256)] =
      (_0x10a319 * _0xba23dd - _0x20b200 * _0x209050) /
      (_0x10a319 * _0x4d0172 - _0x20b200 * _0x20b200)),
    (_0x452fa9[_0x4d3f07(0x23c)] =
      (_0x209050 - _0x452fa9[_0x4d3f07(0x256)] * _0x20b200) / _0x10a319),
    _0x452fa9
  );
}
function calculateMomentum(
  _0x5379c0,
  _0x27f70f,
  _0x34469c,
  _0x839b0d,
  _0x317532,
  _0x14e3e5
) {
  const _0x19ef27 = a0_0x3fc6;
  if (_0x34469c === _0x19ef27(0x25b)) {
    let _0x6562a3 = getIndicatorId({
        indicator: _0x19ef27(0x21b),
        period: _0x27f70f,
        timeframe: _0x839b0d,
        mode: _0x317532,
      }),
      _0x45db10 = _0x14e3e5[_0x6562a3];
    !_0x45db10 &&
      ((_0x45db10 = calculateSma(_0x5379c0, _0x27f70f, _0x317532)),
      (_0x14e3e5[_0x6562a3] = _0x45db10));
    let _0x4b1332 = _0x45db10[_0x19ef27(0x253)]((_0x2afdcc, _0x358065) => {
        const _0x53190b = _0x19ef27;
        if (!_0x2afdcc) return null;
        let _0x415459 = _0x5379c0[_0x358065],
          _0x18bf75 = _0x415459["h"],
          _0x24efa6 = _0x415459["l"];
        for (
          let _0xc6c4eb = _0x358065 - 0x1;
          _0xc6c4eb > Math["max"](0x0, _0x358065 - _0x27f70f);
          _0xc6c4eb--
        ) {
          _0x24efa6 > _0x5379c0[_0xc6c4eb]["l"] &&
            (_0x24efa6 = _0x5379c0[_0xc6c4eb]["l"]),
            _0x18bf75 < _0x5379c0[_0xc6c4eb]["h"] &&
              (_0x18bf75 = _0x5379c0[_0xc6c4eb]["h"]);
        }
        let _0x4266cb =
            _0x415459["c"] -
            ((_0x18bf75 + _0x24efa6) / 0x2 + _0x2afdcc["ma"]) / 0x2,
          _0x45300c = { d: _0x2afdcc["d"], cd: _0x2afdcc["cd"], ht: _0x4266cb };
        return (
          _0x317532 === "current" &&
            _0x2afdcc[_0x53190b(0x20d)] &&
            (_0x45300c[_0x53190b(0x20d)] = _0x2afdcc["subData"][
              _0x53190b(0x253)
            ]((_0x55f4f2, _0x4a49de) => {
              const _0x5bc32 = _0x53190b;
              let _0x198bb2 = _0x415459[_0x5bc32(0x20d)][_0x4a49de],
                _0x12ea81 = _0x198bb2["h"],
                _0x23e1f3 = _0x198bb2["l"];
              for (
                let _0x3e8e39 = _0x358065 - 0x1;
                _0x3e8e39 > Math[_0x5bc32(0x255)](0x0, _0x358065 - _0x27f70f);
                _0x3e8e39--
              ) {
                _0x23e1f3 > _0x5379c0[_0x3e8e39]["l"] &&
                  (_0x23e1f3 = _0x5379c0[_0x3e8e39]["l"]),
                  _0x12ea81 < _0x5379c0[_0x3e8e39]["h"] &&
                    (_0x12ea81 = _0x5379c0[_0x3e8e39]["h"]);
              }
              let _0x40f5a7 =
                _0x198bb2["c"] -
                ((_0x12ea81 + _0x23e1f3) / 0x2 + _0x55f4f2["ma"]) / 0x2;
              return { d: _0x55f4f2["d"], cd: _0x55f4f2["cd"], ht: _0x40f5a7 };
            })),
          _0x45300c
        );
      }),
      _0x2600dc = [];
    return (
      _0x4b1332[_0x19ef27(0x1f8)]((_0x5d6824, _0x3bafe3) => {
        const _0x3c846b = _0x19ef27;
        if (!_0x5d6824) return;
        let _0x508ada = [],
          _0x752226 = [],
          _0x401fc1 = 0x0,
          _0x28e560 = 0x0;
        for (
          let _0x554e05 = _0x3bafe3;
          _0x554e05 >= _0x3bafe3 - _0x27f70f;
          _0x554e05--
        ) {
          if (!_0x4b1332[_0x554e05]) {
            _0x2600dc[_0x3c846b(0x207)](_0x3bafe3);
            return;
          }
          _0x508ada[_0x3c846b(0x207)](_0x4b1332[_0x554e05]["ht"]),
            _0x752226[_0x3c846b(0x207)](_0x401fc1),
            _0x401fc1++,
            (_0x28e560 += _0x4b1332[_0x554e05]["ht"]);
        }
        let _0x19f0a7 = linearRegression(_0x508ada, _0x752226);
        (_0x5d6824["h"] = fixNumber(
          _0x19f0a7[_0x3c846b(0x23c)] +
            _0x19f0a7[_0x3c846b(0x256)] * (_0x28e560 / _0x401fc1),
          0x8
        )),
          _0x317532 === "current" &&
            _0x5d6824[_0x3c846b(0x20d)] &&
            _0x5d6824["subData"][_0x3c846b(0x1f8)]((_0x469a1e) => {
              const _0xe35630 = _0x3c846b;
              let _0x7f4f56 = [_0x469a1e["ht"]],
                _0x5f3063 = [0x0],
                _0x3863db = 0x1,
                _0x57ce33 = _0x469a1e["ht"];
              for (
                let _0x33461d = _0x3bafe3 - 0x1;
                _0x33461d >= _0x3bafe3 - _0x27f70f;
                _0x33461d--
              ) {
                _0x7f4f56[_0xe35630(0x207)](_0x4b1332[_0x33461d]["ht"]),
                  _0x5f3063[_0xe35630(0x207)](_0x3863db),
                  _0x3863db++,
                  (_0x57ce33 += _0x4b1332[_0x33461d]["ht"]);
              }
              let _0x57f535 = linearRegression(_0x7f4f56, _0x5f3063);
              _0x469a1e["h"] = fixNumber(
                _0x57f535[_0xe35630(0x23c)] +
                  _0x57f535[_0xe35630(0x256)] * (_0x57ce33 / _0x3863db),
                0x8
              );
            });
      }),
      _0x2600dc[_0x19ef27(0x1f8)]((_0xeee368) => {
        _0x4b1332[_0xeee368] = null;
      }),
      _0x4b1332[_0x19ef27(0x1f8)]((_0x4c7830) => {
        if (!_0x4c7830) return;
        delete _0x4c7830["ht"],
          _0x317532 === "current" &&
            _0x4c7830["subData"] &&
            _0x4c7830["subData"]["forEach"](
              (_0x17a9d8) => delete _0x17a9d8["ht"]
            );
      }),
      _0x4b1332
    );
  } else {
    let _0x515830 = _0x5379c0[_0x19ef27(0x253)]((_0x21891c, _0x2413ee) => {
      const _0x593112 = _0x19ef27;
      if (_0x2413ee < _0x27f70f) return null;
      let _0x477302 = _0x21891c["c"] - _0x5379c0[_0x2413ee - _0x27f70f]["c"],
        _0x3ab447 = { d: _0x21891c["d"], cd: _0x21891c["cd"], h: _0x477302 };
      return (
        _0x317532 === _0x593112(0x24f) &&
          _0x21891c[_0x593112(0x20d)] &&
          (_0x3ab447["subData"] = _0x21891c[_0x593112(0x20d)][_0x593112(0x253)](
            (_0x2a71e4) => {
              let _0x494476 =
                _0x2a71e4["c"] - _0x5379c0[_0x2413ee - _0x27f70f]["c"];
              return { d: _0x2a71e4["d"], cd: _0x2a71e4["cd"], h: _0x494476 };
            }
          )),
        _0x3ab447
      );
    });
    return _0x515830;
  }
}
function calculateMomentumSqueeze(
  _0x44df2d,
  _0x21a8a8,
  _0x136d44,
  _0x30be22,
  _0x368ef0,
  _0x35706a,
  _0x54f758,
  _0x361ab6,
  _0x2c8df0
) {
  const _0x46e43f = a0_0x3fc6;
  let _0x51ccb5 = getIndicatorId({
      indicator: _0x46e43f(0x24c),
      period: _0x21a8a8,
      period2: _0x136d44,
      timeframe: _0x54f758,
      mode: _0x361ab6,
    }),
    _0x3a9551 = _0x2c8df0[_0x51ccb5];
  !_0x3a9551 &&
    ((_0x3a9551 = calculateBB(
      _0x44df2d,
      _0x21a8a8,
      _0x136d44,
      _0x54f758,
      _0x361ab6,
      _0x2c8df0
    )),
    (_0x2c8df0[_0x51ccb5] = _0x3a9551));
  let _0x505665 = getIndicatorId({
      indicator: _0x46e43f(0x21f),
      period: _0x30be22,
      period2: _0x368ef0,
      timeframe: _0x54f758,
      mode: _0x361ab6,
    }),
    _0x5b0f32 = _0x2c8df0[_0x505665];
  !_0x5b0f32 &&
    ((_0x5b0f32 = calculateKC(
      _0x44df2d,
      _0x30be22,
      _0x368ef0,
      _0x54f758,
      _0x361ab6,
      _0x2c8df0
    )),
    (_0x2c8df0[_0x505665] = _0x5b0f32));
  let _0x2d2c12 = getIndicatorId({
      indicator: _0x46e43f(0x26f),
      period: _0x30be22,
      formula: _0x35706a,
      timeframe: _0x54f758,
      mode: _0x361ab6,
    }),
    _0x2aec7f = _0x2c8df0[_0x2d2c12];
  !_0x2aec7f &&
    ((_0x2aec7f = calculateMomentum(
      _0x44df2d,
      _0x30be22,
      _0x35706a,
      _0x54f758,
      _0x361ab6,
      _0x2c8df0
    )),
    (_0x2c8df0[_0x2d2c12] = _0x2aec7f));
  let _0x548020 = _0x3a9551["map"]((_0x1225a7, _0x42b85f) => {
    const _0x11e8cc = _0x46e43f;
    if (!_0x1225a7) return null;
    let _0x2e6e5b = _0x5b0f32[_0x42b85f];
    if (!_0x2e6e5b) return null;
    let _0x396060 = _0x2aec7f[_0x42b85f];
    if (!_0x396060) return null;
    let _0x162001 =
        _0x1225a7["l"] > _0x2e6e5b["l"] && _0x1225a7["u"] < _0x2e6e5b["u"],
      _0x236d30 = {
        d: _0x1225a7["d"],
        cd: _0x1225a7["cd"],
        h: _0x396060["h"],
        s: _0x162001,
      };
    return (
      _0x361ab6 === _0x11e8cc(0x24f) &&
        _0x1225a7["subData"] &&
        (_0x236d30[_0x11e8cc(0x20d)] = _0x1225a7["subData"][_0x11e8cc(0x253)](
          (_0x2fc830, _0x18ae5d) => {
            const _0x53835e = _0x11e8cc;
            let _0x1004a7 = _0x2e6e5b[_0x53835e(0x20d)][_0x18ae5d],
              _0x534855 = _0x396060["subData"][_0x18ae5d],
              _0x296be5 =
                _0x2fc830["l"] > _0x1004a7["l"] &&
                _0x2fc830["u"] < _0x1004a7["u"],
              _0x311f40 = {
                d: _0x2fc830["d"],
                cd: _0x2fc830["cd"],
                h: _0x534855["h"],
                s: _0x296be5,
              };
            return _0x311f40;
          }
        )),
      _0x236d30
    );
  });
  return _0x548020;
}
function calculateStochastic(
  _0x3693e9,
  _0x1e23d9,
  _0x5a625f,
  _0x1726c5,
  _0x5e1ed7
) {
  const _0x30326c = a0_0x3fc6;
  let _0xe9bd26 = [],
    _0x5ecfcd = [],
    _0x5bf598 = _0x3693e9[_0x30326c(0x253)]((_0x245333) => {
      const _0x2fa428 = _0x30326c;
      if (_0x245333 === null) return null;
      fillPeriodData(_0xe9bd26, _0x245333["l"], _0x1e23d9),
        fillPeriodData(_0x5ecfcd, _0x245333["h"], _0x1e23d9);
      let _0xf70dbd = null,
        _0x28555f = null;
      if (_0x5ecfcd["length"] === _0x1e23d9)
        (_0xf70dbd = Math[_0x2fa428(0x255)](..._0x5ecfcd)),
          (_0x28555f = Math[_0x2fa428(0x281)](..._0xe9bd26));
      else return null;
      let _0x302426 = {
        d: _0x245333["d"],
        cd: _0x245333["cd"],
        c: _0x245333["c"],
        hh: fixNumber(_0xf70dbd, 0x8),
        ll: fixNumber(_0x28555f, 0x8),
      };
      if (_0x5e1ed7 === _0x2fa428(0x24f) && _0x245333["subData"]) {
        let _0x4df825 = [..._0xe9bd26],
          _0x5e530c = [..._0x5ecfcd];
        _0x302426[_0x2fa428(0x20d)] = _0x245333[_0x2fa428(0x20d)][
          _0x2fa428(0x253)
        ]((_0x15abbd) => {
          const _0x2e7134 = _0x2fa428;
          return (
            _0x4df825[_0x2e7134(0x23b)](
              _0x4df825[_0x2e7134(0x222)] - 0x1,
              0x1,
              _0x15abbd["l"]
            ),
            _0x5e530c["splice"](
              _0x5e530c[_0x2e7134(0x222)] - 0x1,
              0x1,
              _0x15abbd["h"]
            ),
            {
              d: _0x15abbd["d"],
              cd: _0x15abbd["cd"],
              c: _0x15abbd["c"],
              hh: fixNumber(Math[_0x2e7134(0x255)](..._0x5e530c), 0x8),
              ll: fixNumber(Math[_0x2e7134(0x281)](..._0x4df825), 0x8),
            }
          );
        });
      }
      return _0x302426;
    }),
    _0x62079f = [],
    _0x13e2be = [],
    _0x391c58 = _0x5bf598[_0x30326c(0x253)]((_0x417517) => {
      const _0x1f0900 = _0x30326c;
      if (_0x417517 === null) return null;
      let _0x2c2d34 =
        _0x417517["hh"] - _0x417517["ll"] !== 0x0
          ? ((_0x417517["c"] - _0x417517["ll"]) /
              (_0x417517["hh"] - _0x417517["ll"])) *
            0x64
          : 0x0;
      fillPeriodData(_0x62079f, _0x2c2d34, _0x1726c5);
      let _0x356711 = null;
      if (_0x62079f[_0x1f0900(0x222)] === _0x1726c5)
        _0x356711 =
          _0x62079f["reduce"](
            (_0x431bb8, _0x2e8228) => _0x431bb8 + _0x2e8228,
            0x0
          ) / _0x1726c5;
      else return null;
      fillPeriodData(_0x13e2be, _0x356711, _0x5a625f);
      let _0x53292f = null;
      if (_0x13e2be[_0x1f0900(0x222)] === _0x5a625f)
        _0x53292f =
          _0x13e2be[_0x1f0900(0x26d)](
            (_0x78b504, _0x31c68c) => _0x78b504 + _0x31c68c,
            0x0
          ) / _0x5a625f;
      else return null;
      let _0x19022e = {
        d: _0x417517["d"],
        cd: _0x417517["cd"],
        k: fixNumber(_0x356711, 0x2),
        dl: fixNumber(_0x53292f, 0x2),
      };
      if (_0x5e1ed7 === "current" && _0x417517[_0x1f0900(0x20d)]) {
        let _0xbb539d = [..._0x62079f],
          _0x345d6d = [..._0x13e2be];
        _0x19022e[_0x1f0900(0x20d)] = _0x417517[_0x1f0900(0x20d)]["map"](
          (_0x485e39) => {
            const _0x1a5925 = _0x1f0900;
            let _0x38c796 =
              _0x485e39["hh"] - _0x485e39["ll"] !== 0x0
                ? ((_0x485e39["c"] - _0x485e39["ll"]) /
                    (_0x485e39["hh"] - _0x485e39["ll"])) *
                  0x64
                : 0x0;
            _0xbb539d[_0x1a5925(0x23b)](
              _0xbb539d[_0x1a5925(0x222)] - 0x1,
              0x1,
              _0x38c796
            );
            let _0x12edb3 =
              _0xbb539d[_0x1a5925(0x26d)](
                (_0x32a217, _0xfa9aa4) => _0x32a217 + _0xfa9aa4,
                0x0
              ) / _0x1726c5;
            return (
              _0x345d6d[_0x1a5925(0x23b)](
                _0x345d6d[_0x1a5925(0x222)] - 0x1,
                0x1,
                _0x12edb3
              ),
              (_0x53292f =
                _0x345d6d[_0x1a5925(0x26d)](
                  (_0x3db2ba, _0x544d3f) => _0x3db2ba + _0x544d3f,
                  0x0
                ) / _0x5a625f),
              {
                d: _0x485e39["d"],
                cd: _0x485e39["cd"],
                k: fixNumber(_0x12edb3, 0x2),
                dl: fixNumber(_0x53292f, 0x2),
              }
            );
          }
        );
      }
      return _0x19022e;
    });
  return _0x391c58;
}
function calculateStochRsi(
  _0x4d58ef,
  _0x472faa,
  _0x3de820,
  _0x31b9f5,
  _0x487b83,
  _0x4751cd,
  _0x27e293,
  _0x51f96f
) {
  let _0x429dea = getIndicatorId({
      indicator: "RSI",
      period: _0x487b83,
      timeframe: _0x4751cd,
      mode: _0x27e293,
    }),
    _0x3bff66 = _0x51f96f[_0x429dea];
  return (
    !_0x3bff66 &&
      ((_0x3bff66 = calculateRsi(_0x4d58ef, _0x487b83, _0x27e293)),
      (_0x51f96f[_0x429dea] = _0x3bff66)),
    (_0x3bff66 = _0x3bff66["map"]((_0x307ab0) => {
      const _0x210613 = a0_0x3fc6;
      if (_0x307ab0 === null) return null;
      return {
        d: _0x307ab0["d"],
        cd: _0x307ab0["cd"],
        c: _0x307ab0["r"],
        l: _0x307ab0["r"],
        h: _0x307ab0["r"],
        subData: _0x307ab0[_0x210613(0x20d)]
          ? _0x307ab0["subData"][_0x210613(0x253)]((_0x226c2d) => ({
              d: _0x226c2d["d"],
              cd: _0x226c2d["cd"],
              c: _0x226c2d["r"],
              l: _0x226c2d["r"],
              h: _0x226c2d["r"],
            }))
          : null,
      };
    })),
    calculateStochastic(_0x3bff66, _0x472faa, _0x3de820, _0x31b9f5, _0x27e293)
  );
}
function calculateMacd(
  _0x1074db,
  _0x6c5970,
  _0x47255b,
  _0x343943,
  _0x4839b2,
  _0x5addef,
  _0x3db28e
) {
  const _0x5215 = a0_0x3fc6;
  let _0x3ff391 = getIndicatorId({
      indicator: _0x5215(0x251),
      period: _0x6c5970,
      timeframe: _0x4839b2,
      mode: _0x5addef,
    }),
    _0x10fa37 = _0x3db28e[_0x3ff391];
  !_0x10fa37 &&
    ((_0x10fa37 = calculateEma(_0x1074db, _0x6c5970, _0x5addef)),
    (_0x3db28e[_0x3ff391] = _0x10fa37));
  let _0x99d345 = getIndicatorId({
      indicator: "EMA",
      period: _0x47255b,
      timeframe: _0x4839b2,
      mode: _0x5addef,
    }),
    _0x15d65a = _0x3db28e[_0x99d345];
  !_0x15d65a &&
    ((_0x15d65a = calculateEma(_0x1074db, _0x47255b, _0x5addef)),
    (_0x3db28e[_0x99d345] = _0x15d65a));
  let _0x358e52 = [];
  for (
    let _0x23e97e = 0x0;
    _0x23e97e < _0x10fa37[_0x5215(0x222)];
    _0x23e97e++
  ) {
    let _0x3458d4 = {
      d: _0x10fa37[_0x23e97e]["d"],
      cd: _0x10fa37[_0x23e97e]["cd"],
      c: fixNumber(
        _0x10fa37[_0x23e97e]["ma"] - _0x15d65a[_0x23e97e]["ma"],
        0x8
      ),
    };
    if (_0x5addef === "current" && _0x10fa37[_0x23e97e][_0x5215(0x20d)]) {
      let _0x12d158 = [];
      for (
        let _0x49df2b = 0x0;
        _0x49df2b < _0x10fa37[_0x23e97e][_0x5215(0x20d)][_0x5215(0x222)];
        _0x49df2b++
      ) {
        _0x12d158["push"]({
          d: _0x10fa37[_0x23e97e][_0x5215(0x20d)][_0x49df2b]["d"],
          cd: _0x10fa37[_0x23e97e][_0x5215(0x20d)][_0x49df2b]["cd"],
          c: fixNumber(
            _0x10fa37[_0x23e97e]["subData"][_0x49df2b]["ma"] -
              _0x15d65a[_0x23e97e][_0x5215(0x20d)][_0x49df2b]["ma"],
            0x8
          ),
        });
      }
      _0x3458d4[_0x5215(0x20d)] = _0x12d158;
    }
    _0x358e52[_0x5215(0x207)](_0x3458d4);
  }
  let _0x5bf4bc = calculateEma(_0x358e52, _0x343943, _0x5addef),
    _0x140b31 = [];
  for (let _0x56e021 = 0x0; _0x56e021 < _0x358e52["length"]; _0x56e021++) {
    let _0x358d32 = {
      d: _0x358e52[_0x56e021]["d"],
      cd: _0x358e52[_0x56e021]["cd"],
      m: fixNumber(_0x358e52[_0x56e021]["c"], 0x8),
      s: fixNumber(_0x5bf4bc[_0x56e021]["ma"], 0x8),
      h: fixNumber(_0x358e52[_0x56e021]["c"] - _0x5bf4bc[_0x56e021]["ma"], 0x8),
    };
    if (_0x5addef === "current" && _0x10fa37[_0x56e021]["subData"]) {
      let _0x5f4b0e = [];
      for (
        let _0x2ebdfe = 0x0;
        _0x2ebdfe < _0x358e52[_0x56e021]["subData"][_0x5215(0x222)];
        _0x2ebdfe++
      ) {
        _0x5f4b0e["push"]({
          d: _0x358e52[_0x56e021][_0x5215(0x20d)][_0x2ebdfe]["d"],
          cd: _0x358e52[_0x56e021][_0x5215(0x20d)][_0x2ebdfe]["cd"],
          m: fixNumber(
            _0x358e52[_0x56e021][_0x5215(0x20d)][_0x2ebdfe]["c"],
            0x8
          ),
          s: fixNumber(
            _0x5bf4bc[_0x56e021][_0x5215(0x20d)][_0x2ebdfe]["ma"],
            0x8
          ),
          h: fixNumber(
            _0x358e52[_0x56e021]["subData"][_0x2ebdfe]["c"] -
              _0x5bf4bc[_0x56e021][_0x5215(0x20d)][_0x2ebdfe]["ma"],
            0x8
          ),
        });
      }
      _0x358d32["subData"] = _0x5f4b0e;
    }
    _0x140b31[_0x5215(0x207)](_0x358d32);
  }
  return _0x140b31;
}
const timeframes = [
  a0_0x29779a(0x22d),
  a0_0x29779a(0x234),
  a0_0x29779a(0x23f),
  a0_0x29779a(0x1ea),
  a0_0x29779a(0x230),
  a0_0x29779a(0x290),
  a0_0x29779a(0x1ee),
  a0_0x29779a(0x262),
  a0_0x29779a(0x21c),
];
function getTimeframes(_0xde4504, _0x4243f4 = ![]) {
  const _0x2b51b9 = a0_0x29779a;
  let _0x3c9c00 = [];
  const _0x311140 = (_0xd86c8c) => {
    const _0x47bfe9 = a0_0x3fc6;
    if (_0x4243f4 && _0xd86c8c[_0x47bfe9(0x23d)] === _0x47bfe9(0x27f)) return;
    _0xd86c8c[_0x47bfe9(0x20f)] &&
      _0x3c9c00[_0x47bfe9(0x288)](_0xd86c8c[_0x47bfe9(0x20f)]) === -0x1 &&
      _0x3c9c00[_0x47bfe9(0x207)](_0xd86c8c[_0x47bfe9(0x20f)]);
  };
  return (
    _0xde4504["buyRules"][_0x2b51b9(0x1f8)](_0x311140),
    _0xde4504[_0x2b51b9(0x22f)][_0x2b51b9(0x1f8)](_0x311140),
    _0x3c9c00[_0x2b51b9(0x222)] === 0x0 &&
      _0x3c9c00[_0x2b51b9(0x207)](_0x2b51b9(0x290)),
    _0x3c9c00["sort"]((_0x1b8fcb, _0xf504a2) => {
      const _0x4ce262 = _0x2b51b9;
      return (
        timeframes[_0x4ce262(0x288)](_0x1b8fcb) -
        timeframes[_0x4ce262(0x288)](_0xf504a2)
      );
    })
  );
}
function ensureDate(_0x4ea86c) {
  const _0x3b65b5 = a0_0x29779a;
  return typeof _0x4ea86c === _0x3b65b5(0x21a) ||
    typeof _0x4ea86c === _0x3b65b5(0x25f)
    ? new Date(_0x4ea86c)
    : _0x4ea86c;
}
function getIndicatorId(_0x10e683) {
  const _0x58c9c2 = a0_0x29779a;
  switch (_0x10e683[_0x58c9c2(0x23d)]) {
    case "the\x20candlestick":
      break;
    case _0x58c9c2(0x225):
      if (_0x10e683[_0x58c9c2(0x249)])
        switch (_0x10e683[_0x58c9c2(0x249)][_0x58c9c2(0x23d)]) {
          case "SMA":
          case _0x58c9c2(0x251):
            return (
              _0x10e683[_0x58c9c2(0x249)]["indicator"] +
              "(" +
              _0x10e683[_0x58c9c2(0x216)] +
              ")-" +
              _0x10e683[_0x58c9c2(0x20f)][_0x58c9c2(0x1f2)](/ /g, "") +
              "-" +
              _0x10e683[_0x58c9c2(0x240)]
            );
          case _0x58c9c2(0x24c):
          case _0x58c9c2(0x21f):
            return (
              _0x10e683[_0x58c9c2(0x249)]["indicator"][_0x58c9c2(0x1f2)](
                / /g,
                ""
              ) +
              "(" +
              _0x10e683[_0x58c9c2(0x249)][_0x58c9c2(0x216)] +
              "," +
              _0x10e683[_0x58c9c2(0x249)]["period2"] +
              ")-" +
              _0x10e683[_0x58c9c2(0x20f)][_0x58c9c2(0x1f2)](/ /g, "") +
              "-" +
              _0x10e683[_0x58c9c2(0x240)]
            );
          default:
        }
      break;
    case _0x58c9c2(0x21b):
    case _0x58c9c2(0x251):
    case _0x58c9c2(0x215):
      return (
        _0x10e683[_0x58c9c2(0x23d)]["replace"](/ /g, "") +
        "(" +
        _0x10e683[_0x58c9c2(0x216)] +
        ")-" +
        _0x10e683[_0x58c9c2(0x20f)][_0x58c9c2(0x1f2)](/ /g, "") +
        "-" +
        _0x10e683[_0x58c9c2(0x240)]
      );
    case _0x58c9c2(0x276):
    case "Stochastic":
      return (
        _0x10e683[_0x58c9c2(0x23d)][_0x58c9c2(0x1f2)](/ /g, "") +
        "(" +
        _0x10e683[_0x58c9c2(0x216)] +
        "," +
        _0x10e683[_0x58c9c2(0x27a)] +
        "," +
        _0x10e683[_0x58c9c2(0x223)] +
        ")-" +
        _0x10e683["timeframe"]["replace"](/ /g, "") +
        "-" +
        _0x10e683[_0x58c9c2(0x240)]
      );
    case _0x58c9c2(0x24c):
    case _0x58c9c2(0x21f):
      return (
        _0x10e683["indicator"][_0x58c9c2(0x1f2)](/ /g, "") +
        "(" +
        _0x10e683["period"] +
        "," +
        _0x10e683[_0x58c9c2(0x27a)] +
        ")-" +
        _0x10e683[_0x58c9c2(0x20f)][_0x58c9c2(0x1f2)](/ /g, "") +
        "-" +
        _0x10e683[_0x58c9c2(0x240)]
      );
    case "Stochastic\x20RSI":
      return (
        _0x10e683[_0x58c9c2(0x23d)][_0x58c9c2(0x1f2)](/ /g, "") +
        "(" +
        _0x10e683["period"] +
        "," +
        _0x10e683[_0x58c9c2(0x27a)] +
        "," +
        _0x10e683["period3"] +
        "," +
        _0x10e683["period4"] +
        ")-" +
        _0x10e683[_0x58c9c2(0x20f)]["replace"](/ /g, "") +
        "-" +
        _0x10e683[_0x58c9c2(0x240)]
      );
    case _0x58c9c2(0x26f):
      return (
        _0x10e683["indicator"][_0x58c9c2(0x1f2)](/ /g, "") +
        "(" +
        _0x10e683["period"] +
        "," +
        _0x10e683[_0x58c9c2(0x25d)] +
        ")-" +
        _0x10e683[_0x58c9c2(0x20f)][_0x58c9c2(0x1f2)](/ /g, "") +
        "-" +
        _0x10e683["mode"]
      );
    case "Momentum\x20Squeeze":
      return (
        _0x10e683[_0x58c9c2(0x23d)][_0x58c9c2(0x1f2)](/ /g, "") +
        "(" +
        _0x10e683[_0x58c9c2(0x216)] +
        "," +
        _0x10e683[_0x58c9c2(0x27a)] +
        "," +
        _0x10e683[_0x58c9c2(0x223)] +
        "," +
        _0x10e683["period4"] +
        "," +
        _0x10e683[_0x58c9c2(0x25d)] +
        ")-" +
        _0x10e683[_0x58c9c2(0x20f)][_0x58c9c2(0x1f2)](/ /g, "") +
        "-" +
        _0x10e683[_0x58c9c2(0x240)]
      );
    case "ATR":
      return (
        _0x10e683["indicator"][_0x58c9c2(0x1f2)](/ /g, "") +
        "(" +
        _0x10e683[_0x58c9c2(0x216)] +
        ")-" +
        _0x10e683["timeframe"][_0x58c9c2(0x1f2)](/ /g, "") +
        "-" +
        _0x10e683[_0x58c9c2(0x240)]
      );
    default:
  }
  return "DEF";
}
function addIndicatorsData(_0x27cd63, _0x7e8bad, _0x466326) {
  const _0x44bfec = a0_0x29779a;
  return (
    _0x7e8bad[_0x44bfec(0x239)][_0x44bfec(0x1f8)]((_0x424c99) => {
      fillRuleIndicatorData(_0x27cd63, _0x424c99, _0x466326);
    }),
    _0x7e8bad[_0x44bfec(0x22f)][_0x44bfec(0x1f8)]((_0x1c2a06) => {
      fillRuleIndicatorData(_0x27cd63, _0x1c2a06, _0x466326);
    }),
    _0x27cd63
  );
}
function fillRuleIndicatorData(_0x5cdd76, _0x5bc80e, _0x24ac4e) {
  const _0x175c41 = a0_0x29779a;
  let _0x1bf41a = getIndicatorId(_0x5bc80e);
  if (
    _0x5bc80e[_0x175c41(0x23d)] === _0x175c41(0x225) &&
    _0x5bc80e[_0x175c41(0x249)]
  ) {
    if (!_0x5cdd76[_0x1bf41a])
      switch (_0x5bc80e[_0x175c41(0x249)][_0x175c41(0x23d)]) {
        case _0x175c41(0x21b):
          _0x5cdd76[_0x1bf41a] = calculateSma(
            _0x24ac4e[_0x5bc80e[_0x175c41(0x20f)]],
            _0x5bc80e[_0x175c41(0x249)][_0x175c41(0x216)],
            _0x5bc80e[_0x175c41(0x240)]
          );
          break;
        case _0x175c41(0x251):
          _0x5cdd76[_0x1bf41a] = calculateEma(
            _0x24ac4e[_0x5bc80e["timeframe"]],
            _0x5bc80e["condition2"][_0x175c41(0x216)],
            _0x5bc80e[_0x175c41(0x240)]
          );
          break;
        case _0x175c41(0x24c):
          _0x5cdd76[_0x1bf41a] = calculateBB(
            _0x24ac4e[_0x5bc80e[_0x175c41(0x20f)]],
            _0x5bc80e["condition2"][_0x175c41(0x216)],
            _0x5bc80e[_0x175c41(0x249)]["period2"],
            _0x5bc80e[_0x175c41(0x20f)],
            _0x5bc80e[_0x175c41(0x240)],
            _0x5cdd76
          );
          break;
        case _0x175c41(0x21f):
          _0x5cdd76[_0x1bf41a] = calculateKC(
            _0x24ac4e[_0x5bc80e[_0x175c41(0x20f)]],
            _0x5bc80e["condition2"][_0x175c41(0x216)],
            _0x5bc80e[_0x175c41(0x249)][_0x175c41(0x27a)],
            _0x5bc80e["timeframe"],
            _0x5bc80e[_0x175c41(0x240)],
            _0x5cdd76
          );
          break;
        default:
      }
  } else {
    if (
      _0x5bc80e[_0x175c41(0x23d)] !== _0x175c41(0x20e) &&
      _0x5bc80e["indicator"] !== _0x175c41(0x225) &&
      _0x5bc80e[_0x175c41(0x23d)] !== _0x175c41(0x27f)
    ) {
      if (!_0x5cdd76[_0x1bf41a]) {
        switch (_0x5bc80e["indicator"]) {
          case _0x175c41(0x21b):
            _0x5cdd76[_0x1bf41a] = calculateSma(
              _0x24ac4e[_0x5bc80e["timeframe"]],
              _0x5bc80e[_0x175c41(0x216)],
              _0x5bc80e[_0x175c41(0x240)]
            );
            break;
          case "EMA":
            _0x5cdd76[_0x1bf41a] = calculateEma(
              _0x24ac4e[_0x5bc80e[_0x175c41(0x20f)]],
              _0x5bc80e[_0x175c41(0x216)],
              _0x5bc80e[_0x175c41(0x240)]
            );
            break;
          case _0x175c41(0x215):
            _0x5cdd76[_0x1bf41a] = calculateRsi(
              _0x24ac4e[_0x5bc80e[_0x175c41(0x20f)]],
              _0x5bc80e[_0x175c41(0x216)],
              _0x5bc80e[_0x175c41(0x240)]
            );
            break;
          case _0x175c41(0x276):
            _0x5cdd76[_0x1bf41a] = calculateMacd(
              _0x24ac4e[_0x5bc80e[_0x175c41(0x20f)]],
              _0x5bc80e["period"],
              _0x5bc80e[_0x175c41(0x27a)],
              _0x5bc80e[_0x175c41(0x223)],
              _0x5bc80e[_0x175c41(0x20f)],
              _0x5bc80e[_0x175c41(0x240)],
              _0x5cdd76
            );
            break;
          case "Bollinger\x20Bands":
            _0x5cdd76[_0x1bf41a] = calculateBB(
              _0x24ac4e[_0x5bc80e[_0x175c41(0x20f)]],
              _0x5bc80e["period"],
              _0x5bc80e[_0x175c41(0x27a)],
              _0x5bc80e[_0x175c41(0x20f)],
              _0x5bc80e[_0x175c41(0x240)],
              _0x5cdd76
            );
            break;
          case _0x175c41(0x21f):
            _0x5cdd76[_0x1bf41a] = calculateKC(
              _0x24ac4e[_0x5bc80e[_0x175c41(0x20f)]],
              _0x5bc80e[_0x175c41(0x216)],
              _0x5bc80e[_0x175c41(0x27a)],
              _0x5bc80e["timeframe"],
              _0x5bc80e[_0x175c41(0x240)],
              _0x5cdd76
            );
            break;
          case _0x175c41(0x236):
            _0x5cdd76[_0x1bf41a] = calculateStochastic(
              _0x24ac4e[_0x5bc80e["timeframe"]],
              _0x5bc80e[_0x175c41(0x216)],
              _0x5bc80e[_0x175c41(0x27a)],
              _0x5bc80e[_0x175c41(0x223)],
              _0x5bc80e["mode"]
            );
            break;
          case _0x175c41(0x272):
            _0x5cdd76[_0x1bf41a] = calculateStochRsi(
              _0x24ac4e[_0x5bc80e[_0x175c41(0x20f)]],
              _0x5bc80e[_0x175c41(0x216)],
              _0x5bc80e[_0x175c41(0x27a)],
              _0x5bc80e[_0x175c41(0x223)],
              _0x5bc80e[_0x175c41(0x291)],
              _0x5bc80e[_0x175c41(0x20f)],
              _0x5bc80e[_0x175c41(0x240)],
              _0x5cdd76
            );
            break;
          case _0x175c41(0x26f):
            _0x5cdd76[_0x1bf41a] = calculateMomentum(
              _0x24ac4e[_0x5bc80e[_0x175c41(0x20f)]],
              _0x5bc80e[_0x175c41(0x216)],
              _0x5bc80e[_0x175c41(0x25d)],
              _0x5bc80e["timeframe"],
              _0x5bc80e[_0x175c41(0x240)],
              _0x5cdd76
            );
            break;
          case "Momentum\x20Squeeze":
            _0x5cdd76[_0x1bf41a] = calculateMomentumSqueeze(
              _0x24ac4e[_0x5bc80e[_0x175c41(0x20f)]],
              _0x5bc80e["period"],
              _0x5bc80e[_0x175c41(0x27a)],
              _0x5bc80e[_0x175c41(0x223)],
              _0x5bc80e[_0x175c41(0x291)],
              _0x5bc80e["formula"],
              _0x5bc80e[_0x175c41(0x20f)],
              _0x5bc80e[_0x175c41(0x240)],
              _0x5cdd76
            );
            break;
          default:
        }
        _0x5cdd76[_0x1bf41a] &&
          (_0x5cdd76[_0x1bf41a][_0x175c41(0x20f)] =
            _0x5bc80e[_0x175c41(0x20f)]);
      }
      (_0x5bc80e["indicator"] === "SMA" || _0x5bc80e["indicator"] === "EMA") &&
        _0x5bc80e[_0x175c41(0x1ef)] &&
        fillRuleIndicatorData(
          _0x5cdd76,
          {
            indicator: _0x5bc80e[_0x175c41(0x1ef)][_0x175c41(0x23d)],
            period: _0x5bc80e[_0x175c41(0x1ef)][_0x175c41(0x216)],
            timeframe: _0x5bc80e[_0x175c41(0x20f)],
            mode: _0x5bc80e[_0x175c41(0x240)],
          },
          _0x24ac4e
        );
    }
  }
}
function checkRuleLogic(_0x226ce6) {
  const _0x4aa54b = a0_0x29779a;
  switch (_0x226ce6[_0x4aa54b(0x23e)]) {
    case _0x4aa54b(0x24d):
      if (_0x226ce6["checkValueCur"] <= _0x226ce6[_0x4aa54b(0x269)]) return ![];
      break;
    case "below":
      if (_0x226ce6[_0x4aa54b(0x278)] >= _0x226ce6[_0x4aa54b(0x269)])
        return ![];
      break;
    case _0x4aa54b(0x21d):
      switch (_0x226ce6["crossDirection"]) {
        case _0x4aa54b(0x214):
          if (
            _0x226ce6["checkValuePrev"] < _0x226ce6[_0x4aa54b(0x28d)] ||
            _0x226ce6["checkValueCur"] >= _0x226ce6[_0x4aa54b(0x269)]
          )
            return ![];
          break;
        case _0x4aa54b(0x26b):
          if (
            _0x226ce6[_0x4aa54b(0x209)] > _0x226ce6["confirmValuePrev"] ||
            _0x226ce6[_0x4aa54b(0x278)] <= _0x226ce6[_0x4aa54b(0x269)]
          )
            return ![];
          break;
        default:
      }
      break;
    case _0x4aa54b(0x264):
      if (
        Math[_0x4aa54b(0x255)](..._0x226ce6["checkValueCur"]) <=
        _0x226ce6["confirmValue"]
      )
        return ![];
      break;
    case _0x4aa54b(0x289):
      if (
        Math[_0x4aa54b(0x281)](..._0x226ce6[_0x4aa54b(0x278)]) >=
        _0x226ce6[_0x4aa54b(0x269)]
      )
        return ![];
      break;
    case _0x4aa54b(0x271):
      if (
        Math["min"](..._0x226ce6[_0x4aa54b(0x278)]) >=
          _0x226ce6["confirmValue2"] ||
        Math[_0x4aa54b(0x255)](..._0x226ce6[_0x4aa54b(0x278)]) <=
          _0x226ce6[_0x4aa54b(0x269)]
      )
        return ![];
      break;
    default:
      return ![];
  }
  return !![];
}
function checkTrend(
  _0x448101,
  _0x2844e7,
  _0x52c877,
  _0xff8a44,
  _0x3c4bb7,
  _0x4aa8cc,
  _0x461b4b,
  _0xe1931d
) {
  const _0x175c26 = a0_0x29779a;
  if (!_0x52c877[_0xff8a44 - 0x1] || !_0x52c877[_0xff8a44 - 0x2]) return ![];
  if (_0xe1931d) {
    if (_0x448101 === _0x175c26(0x20c)) {
      if (
        _0x2844e7[_0x3c4bb7] <= _0x52c877[_0xff8a44 - 0x1][_0x3c4bb7] ||
        _0x52c877[_0xff8a44 - 0x1][_0x3c4bb7] <=
          _0x52c877[_0xff8a44 - 0x2][_0x3c4bb7]
      )
        return ![];
    } else {
      if (
        _0x2844e7[_0x3c4bb7] >= _0x52c877[_0xff8a44 - 0x1][_0x3c4bb7] ||
        _0x52c877[_0xff8a44 - 0x1][_0x3c4bb7] >=
          _0x52c877[_0xff8a44 - 0x2][_0x3c4bb7]
      )
        return ![];
    }
  }
  let _0x33b395 = _0x2844e7,
    _0x12ddef = [{}];
  for (let _0x470eee = _0xff8a44 - 0x1; _0x470eee > 0x0; _0x470eee--) {
    let _0x1f9516 = _0x52c877[_0x470eee];
    if (!_0x1f9516) return ![];
    if (_0x448101 === _0x175c26(0x20c)) {
      let _0x170efc = _0x12ddef[_0x12ddef[_0x175c26(0x222)] - 0x1];
      _0x33b395[_0x3c4bb7] <= _0x1f9516[_0x3c4bb7]
        ? !_0x170efc["l"]
          ? ((_0x170efc["l"] = _0x33b395[_0x3c4bb7]),
            (_0x170efc["h"] = _0x1f9516[_0x3c4bb7]))
          : ((_0x170efc["h"] = _0x1f9516[_0x3c4bb7]),
            (_0x170efc["dh"] = _0x1f9516["d"]))
        : _0x170efc["l"] && _0x12ddef["push"]({});
      let _0x2019e3 = _0x12ddef[0x0];
      for (
        let _0x3d89b9 = 0x1;
        _0x3d89b9 < _0x12ddef[_0x175c26(0x222)];
        _0x3d89b9++
      ) {
        let _0x3d64a7 = _0x12ddef[_0x3d89b9];
        if (!_0x3d64a7["l"]) break;
        if (_0x2019e3["l"] < _0x3d64a7["l"] || _0x2019e3["h"] < _0x3d64a7["h"])
          return ![];
        _0x2019e3 = _0x3d64a7;
      }
      let _0x2cd31c = _0x461b4b
        ? ((_0x2844e7[_0x3c4bb7] - _0x1f9516[_0x3c4bb7]) /
            _0x1f9516[_0x3c4bb7]) *
          0x64
        : _0x2844e7[_0x3c4bb7] - _0x1f9516[_0x3c4bb7];
      if (_0x4aa8cc <= _0x2cd31c) return _0x470eee;
    } else {
      let _0x44178d = _0x12ddef[_0x12ddef[_0x175c26(0x222)] - 0x1];
      _0x33b395[_0x3c4bb7] >= _0x1f9516[_0x3c4bb7]
        ? !_0x44178d["l"]
          ? ((_0x44178d["l"] = _0x33b395[_0x3c4bb7]),
            (_0x44178d["h"] = _0x1f9516[_0x3c4bb7]))
          : ((_0x44178d["h"] = _0x1f9516[_0x3c4bb7]),
            (_0x44178d["dh"] = _0x1f9516["d"]))
        : _0x44178d["l"] && _0x12ddef[_0x175c26(0x207)]({});
      let _0x4dbb77 = _0x12ddef[0x0];
      for (
        let _0x254044 = 0x1;
        _0x254044 < _0x12ddef[_0x175c26(0x222)];
        _0x254044++
      ) {
        let _0x315483 = _0x12ddef[_0x254044];
        if (!_0x315483["l"]) break;
        if (_0x4dbb77["l"] > _0x315483["l"] || _0x4dbb77["h"] > _0x315483["h"])
          return ![];
        _0x4dbb77 = _0x315483;
      }
      let _0x260dcb = _0x461b4b
        ? ((_0x1f9516[_0x3c4bb7] - _0x2844e7[_0x3c4bb7]) /
            _0x1f9516[_0x3c4bb7]) *
          0x64
        : _0x1f9516[_0x3c4bb7] - _0x2844e7[_0x3c4bb7];
      if (_0x4aa8cc <= _0x260dcb) return _0x470eee;
    }
    _0x33b395 = _0x1f9516;
  }
  return ![];
}
function checkTradingRules(
  _0x3b0cb4,
  _0x309f7a,
  _0x55b0fb,
  _0x18ff10,
  _0x3549ad
) {
  const _0x100e90 = a0_0x29779a;
  if (_0x3b0cb4[_0x100e90(0x222)] === 0x0) return ![];
  for (let _0x3b0cea of _0x3b0cb4) {
    let _0x1dae41 = _0x309f7a[_0x3b0cea[_0x100e90(0x20f)]],
      _0x2183c4 = getCandleData(_0x1dae41, _0x55b0fb, _0x18ff10, _0x3b0cea);
    if (!_0x2183c4) return ![];
    let [
      _0x35f281,
      _0x21d4cf,
      _0x543c8b,
      _0x1960db,
      _0x21904e,
      _0x4a888b,
      _0x223f75,
    ] = _0x2183c4;
    switch (_0x3b0cea[_0x100e90(0x23d)]) {
      case _0x100e90(0x225):
        if (_0x3b0cea[_0x100e90(0x1ef)]) {
          if (!_0x18ff10["pa"] || !_0x18ff10["pa"][_0x100e90(0x246)])
            return ![];
          switch (_0x3b0cea[_0x100e90(0x1ef)][_0x100e90(0x23e)]) {
            case "up":
              let _0x436b5e = 0x0;
              _0x3b0cea[_0x100e90(0x240)] === _0x100e90(0x24f) &&
              _0x35f281["h1"]
                ? (_0x436b5e =
                    ((_0x35f281["h1"] - _0x18ff10["pa"][_0x100e90(0x246)]) /
                      _0x18ff10["pa"][_0x100e90(0x246)]) *
                    0x64)
                : (_0x436b5e =
                    ((_0x35f281["c"] - _0x18ff10["pa"][_0x100e90(0x246)]) /
                      _0x18ff10["pa"][_0x100e90(0x246)]) *
                    0x64);
              if (_0x436b5e < _0x3b0cea[_0x100e90(0x1ef)][_0x100e90(0x1e9)])
                return ![];
              if (_0x3549ad) {
                let _0x266a67 =
                  _0x18ff10["pa"]["low"] *
                  (0x1 + _0x3b0cea[_0x100e90(0x1ef)][_0x100e90(0x1e9)] / 0x64);
                _0x35f281["o1"] && _0x35f281["o1"] > _0x266a67
                  ? _0x3549ad["push"](_0x35f281["o1"])
                  : _0x3549ad[_0x100e90(0x207)](_0x266a67);
              }
              break;
            case _0x100e90(0x26c):
              let _0x5c61f2 = 0x0;
              _0x3b0cea[_0x100e90(0x240)] === _0x100e90(0x24f) &&
              _0x35f281["l1"]
                ? (_0x5c61f2 =
                    ((_0x18ff10["pa"]["high"] - _0x35f281["l1"]) /
                      _0x18ff10["pa"]["high"]) *
                    0x64)
                : (_0x5c61f2 =
                    ((_0x18ff10["pa"][_0x100e90(0x26e)] - _0x35f281["c"]) /
                      _0x18ff10["pa"][_0x100e90(0x26e)]) *
                    0x64);
              if (_0x5c61f2 < _0x3b0cea["condition1"]["value"]) return ![];
              if (_0x3549ad) {
                let _0x126c59 =
                  _0x18ff10["pa"]["high"] *
                  (0x1 - _0x3b0cea[_0x100e90(0x1ef)][_0x100e90(0x1e9)] / 0x64);
                _0x35f281["o1"] && _0x35f281["o1"] < _0x126c59
                  ? _0x3549ad["push"](_0x35f281["o1"])
                  : _0x3549ad[_0x100e90(0x207)](_0x126c59);
              }
              break;
            default:
              return ![];
          }
        }
        if (_0x3b0cea[_0x100e90(0x249)]) {
          let _0x4cb070 = {
            checkValueCur: _0x35f281["c"],
            checkValuePrev: _0x21d4cf["c"],
            direction: _0x3b0cea[_0x100e90(0x249)][_0x100e90(0x23e)],
            crossDirection: _0x3b0cea["condition2"]["crossDirection"],
          };
          switch (_0x3b0cea["condition2"][_0x100e90(0x23d)]) {
            case _0x100e90(0x21b):
            case _0x100e90(0x251):
              (_0x4cb070[_0x100e90(0x269)] = _0x1960db["ma"]),
                (_0x4cb070[_0x100e90(0x28d)] = _0x21904e["ma"]);
              break;
            case _0x100e90(0x24c):
            case _0x100e90(0x21f):
              _0x3b0cea[_0x100e90(0x249)][_0x100e90(0x1ff)] === _0x100e90(0x1ed)
                ? ((_0x4cb070[_0x100e90(0x269)] = _0x1960db["u"]),
                  (_0x4cb070["confirmValuePrev"] = _0x21904e["u"]))
                : ((_0x4cb070[_0x100e90(0x269)] = _0x1960db["l"]),
                  (_0x4cb070[_0x100e90(0x28d)] = _0x21904e["l"]));
              break;
            default:
              return ![];
          }
          if (!checkRuleLogic(_0x4cb070)) return ![];
        }
        if (_0x3b0cea[_0x100e90(0x1e7)]) {
          let _0x59c968 =
            _0x3b0cea[_0x100e90(0x240)] === _0x100e90(0x24f) ? 0x1 : 0x2;
          if (
            _0x1dae41[_0x100e90(0x1f0)] - _0x59c968 <
            _0x3b0cea[_0x100e90(0x1e7)]["value"]
          )
            return ![];
          let _0x4ebe85 =
              _0x1dae41[_0x100e90(0x287)][_0x1dae41["index"] - _0x59c968]["l"],
            _0x32b765 = _0x1dae41["data"][_0x1dae41["index"] - _0x59c968]["h"];
          for (
            let _0x4aaa08 = _0x1dae41[_0x100e90(0x1f0)] - _0x59c968 - 0x1;
            _0x4aaa08 >=
            _0x1dae41[_0x100e90(0x1f0)] -
              _0x3b0cea[_0x100e90(0x1e7)]["value"] -
              (_0x3b0cea["mode"] === _0x100e90(0x24f) ? 0x0 : 0x1);
            _0x4aaa08--
          ) {
            _0x4ebe85 > _0x1dae41["data"][_0x4aaa08]["l"] &&
              (_0x4ebe85 = _0x1dae41[_0x100e90(0x287)][_0x4aaa08]["l"]),
              _0x32b765 < _0x1dae41["data"][_0x4aaa08]["h"] &&
                (_0x32b765 = _0x1dae41[_0x100e90(0x287)][_0x4aaa08]["h"]);
          }
          switch (_0x3b0cea[_0x100e90(0x1e7)][_0x100e90(0x1f1)]) {
            case _0x100e90(0x243):
              let _0x502b22 = _0x35f281["c"];
              _0x3b0cea["mode"] === _0x100e90(0x24f) &&
                _0x35f281["h1"] &&
                (_0x502b22 = _0x35f281["h1"]);
              if (
                _0x3b0cea[_0x100e90(0x1e7)]["direction"] === _0x100e90(0x24d)
              ) {
                if (_0x32b765 > _0x502b22) return ![];
                if (_0x3549ad) {
                  if (_0x35f281["o1"] && _0x35f281["o1"] > _0x32b765)
                    _0x3549ad[_0x100e90(0x207)](_0x35f281["o1"]);
                  else
                    _0x35f281["o"] > _0x32b765
                      ? _0x3549ad[_0x100e90(0x207)](_0x35f281["o"])
                      : _0x3549ad["push"](_0x32b765);
                }
              } else {
                if (_0x32b765 < _0x502b22) return ![];
                if (_0x3549ad) {
                  if (_0x35f281["o1"] && _0x35f281["o1"] < _0x32b765)
                    _0x3549ad[_0x100e90(0x207)](_0x35f281["o1"]);
                  else
                    _0x35f281["o"] < _0x32b765
                      ? _0x3549ad[_0x100e90(0x207)](_0x35f281["o"])
                      : _0x3549ad["push"](_0x32b765);
                }
              }
              break;
            case _0x100e90(0x28c):
              let _0x4cd38f = _0x35f281["c"];
              _0x3b0cea[_0x100e90(0x240)] === "current" &&
                _0x35f281["l1"] &&
                (_0x4cd38f = _0x35f281["l1"]);
              if (
                _0x3b0cea[_0x100e90(0x1e7)][_0x100e90(0x23e)] ===
                _0x100e90(0x204)
              ) {
                if (_0x4ebe85 < _0x4cd38f) return ![];
                if (_0x3549ad) {
                  if (_0x35f281["o1"] && _0x35f281["o1"] < _0x4ebe85)
                    _0x3549ad[_0x100e90(0x207)](_0x35f281["o1"]);
                  else
                    _0x35f281["o"] < _0x4ebe85
                      ? _0x3549ad[_0x100e90(0x207)](_0x35f281["o"])
                      : _0x3549ad[_0x100e90(0x207)](_0x4ebe85);
                }
              } else {
                if (_0x4ebe85 > _0x4cd38f) return ![];
                if (_0x3549ad) {
                  if (_0x35f281["o1"] && _0x35f281["o1"] > _0x4ebe85)
                    _0x3549ad["push"](_0x35f281["o1"]);
                  else
                    _0x35f281["o"] > _0x4ebe85
                      ? _0x3549ad[_0x100e90(0x207)](_0x35f281["o"])
                      : _0x3549ad[_0x100e90(0x207)](_0x4ebe85);
                }
              }
              break;
            default:
              return ![];
          }
        }
        if (_0x3b0cea["condition4"]) {
          let _0x2d714b = _0x35f281["c"],
            _0x34ceaa = _0x35f281["c"];
          _0x3b0cea[_0x100e90(0x240)] === _0x100e90(0x24f) &&
            (_0x35f281["l1"] && (_0x2d714b = _0x35f281["l1"]),
            _0x35f281["h1"] && (_0x34ceaa = _0x35f281["h1"]));
          if (_0x34ceaa < _0x3b0cea["condition4"][_0x100e90(0x1e9)]) return ![];
          if (_0x2d714b > _0x3b0cea[_0x100e90(0x232)][_0x100e90(0x237)])
            return ![];
          if (_0x3549ad) {
            if (_0x3b0cea[_0x100e90(0x240)] === _0x100e90(0x24f)) {
              if (
                _0x35f281["o1"] &&
                _0x35f281["o1"] >= _0x3b0cea[_0x100e90(0x232)]["value"] &&
                _0x35f281["o1"] <= _0x3b0cea["condition4"][_0x100e90(0x237)]
              )
                _0x3549ad[_0x100e90(0x207)](_0x35f281["o1"]);
              else {
                if (
                  _0x35f281["o1"] &&
                  _0x35f281["o1"] < _0x3b0cea["condition4"][_0x100e90(0x1e9)]
                )
                  _0x3549ad["push"](
                    _0x3b0cea[_0x100e90(0x232)][_0x100e90(0x1e9)]
                  );
                else
                  _0x35f281["o1"] &&
                    _0x35f281["o1"] >
                      _0x3b0cea["condition4"][_0x100e90(0x237)] &&
                    _0x3549ad[_0x100e90(0x207)](
                      _0x3b0cea[_0x100e90(0x232)]["value2"]
                    );
              }
            }
          }
        }
        if (_0x3b0cea[_0x100e90(0x24b)]) {
          let _0x5960c0 = _0x35f281["c"],
            _0x45f97b = _0x35f281["c"];
          if (_0x3b0cea[_0x100e90(0x240)] === "current")
            switch (_0x3b0cea["condition5"][_0x100e90(0x23e)]) {
              case _0x100e90(0x24d):
                _0x35f281["h1"] &&
                  ((_0x5960c0 = _0x35f281["h1"]),
                  (_0x45f97b =
                    _0x35f281["o1"] >
                    _0x3b0cea[_0x100e90(0x24b)][_0x100e90(0x1e9)]
                      ? _0x35f281["o1"]
                      : _0x3b0cea["condition5"]["value"]));
                break;
              case _0x100e90(0x204):
                _0x35f281["l1"] &&
                  ((_0x5960c0 = _0x35f281["l1"]),
                  (_0x45f97b =
                    _0x35f281["o1"] <
                    _0x3b0cea[_0x100e90(0x24b)][_0x100e90(0x1e9)]
                      ? _0x35f281["o1"]
                      : _0x3b0cea["condition5"][_0x100e90(0x1e9)]));
                break;
              case _0x100e90(0x21d):
                _0x3b0cea[_0x100e90(0x24b)][_0x100e90(0x263)] ===
                "top\x20to\x20bottom"
                  ? _0x35f281["l1"] &&
                    ((_0x5960c0 = _0x35f281["l1"]),
                    (_0x45f97b =
                      _0x35f281["o1"] < _0x3b0cea[_0x100e90(0x24b)]["value"]
                        ? _0x35f281["o1"]
                        : _0x3b0cea["condition5"][_0x100e90(0x1e9)]))
                  : _0x35f281["h1"] &&
                    ((_0x5960c0 = _0x35f281["h1"]),
                    (_0x45f97b =
                      _0x35f281["o1"] >
                      _0x3b0cea[_0x100e90(0x24b)][_0x100e90(0x1e9)]
                        ? _0x35f281["o1"]
                        : _0x3b0cea[_0x100e90(0x24b)]["value"]));
                break;
              default:
            }
          let _0x1f1153 = {
            checkValueCur: _0x5960c0,
            checkValuePrev: _0x21d4cf["c"],
            direction: _0x3b0cea[_0x100e90(0x24b)][_0x100e90(0x23e)],
            crossDirection: _0x3b0cea[_0x100e90(0x24b)][_0x100e90(0x263)],
            confirmValue: _0x3b0cea["condition5"]["value"],
            confirmValuePrev: _0x3b0cea[_0x100e90(0x24b)]["value"],
          };
          if (!checkRuleLogic(_0x1f1153)) return ![];
          _0x3549ad &&
            _0x3b0cea[_0x100e90(0x240)] === _0x100e90(0x24f) &&
            _0x3549ad[_0x100e90(0x207)](_0x45f97b);
        }
        break;
      case _0x100e90(0x20e):
        if (_0x3b0cea[_0x100e90(0x1ef)]) {
          let _0x5b109 =
              ((_0x35f281["c"] - _0x35f281["o"]) / _0x35f281["o"]) * 0x64,
            _0x56024a = Math["max"](_0x35f281["o"], _0x35f281["c"]),
            _0x3e3624 = ((_0x35f281["h"] - _0x56024a) / _0x56024a) * 0x64,
            _0x30ed2f = Math[_0x100e90(0x281)](_0x35f281["o"], _0x35f281["c"]),
            _0x6bb240 = Math[_0x100e90(0x260)](
              ((_0x35f281["l"] - _0x30ed2f) / _0x30ed2f) * 0x64
            );
          const _0x5d8974 = 0x1;
          switch (_0x3b0cea[_0x100e90(0x1ef)]["pattern"]) {
            case _0x100e90(0x252):
              if (
                !(-0.01 < _0x5b109 && _0x5b109 < 0.01) ||
                0.1 > _0x3e3624 ||
                0.1 > _0x6bb240
              )
                return ![];
              break;
            case _0x100e90(0x217):
              if (
                0x0 === _0x5b109 ||
                Math["abs"](_0x5b109) * 0.2 < _0x3e3624 ||
                Math[_0x100e90(0x260)](_0x5b109) * 0x2 > _0x6bb240
              )
                return ![];
              if (
                !checkTrend(
                  "downtrend",
                  _0x35f281,
                  _0x1dae41[_0x100e90(0x287)],
                  _0x1dae41[_0x100e90(0x1f0)],
                  "c",
                  _0x5d8974,
                  !![],
                  !![]
                )
              )
                return ![];
              break;
            case "inverted\x20hammer":
              if (
                0x0 === _0x5b109 ||
                Math[_0x100e90(0x260)](_0x5b109) * 0.2 < _0x6bb240 ||
                Math[_0x100e90(0x260)](_0x5b109) * 0x2 > _0x3e3624
              )
                return ![];
              if (
                !checkTrend(
                  _0x100e90(0x286),
                  _0x35f281,
                  _0x1dae41[_0x100e90(0x287)],
                  _0x1dae41[_0x100e90(0x1f0)],
                  "c",
                  _0x5d8974,
                  !![],
                  !![]
                )
              )
                return ![];
              break;
            case _0x100e90(0x213):
              if (
                0x0 === _0x5b109 ||
                Math[_0x100e90(0x260)](_0x5b109) * 0.2 < _0x3e3624 ||
                Math[_0x100e90(0x260)](_0x5b109) * 0x2 > _0x6bb240
              )
                return ![];
              if (
                !checkTrend(
                  _0x100e90(0x20c),
                  _0x35f281,
                  _0x1dae41[_0x100e90(0x287)],
                  _0x1dae41[_0x100e90(0x1f0)],
                  "c",
                  _0x5d8974,
                  !![],
                  !![]
                )
              )
                return ![];
              break;
            case _0x100e90(0x20b):
              if (
                0x0 === _0x5b109 ||
                Math["abs"](_0x5b109) * 0.2 < _0x6bb240 ||
                Math[_0x100e90(0x260)](_0x5b109) * 0x2 > _0x3e3624
              )
                return ![];
              if (
                !checkTrend(
                  "uptrend",
                  _0x35f281,
                  _0x1dae41["data"],
                  _0x1dae41[_0x100e90(0x1f0)],
                  "c",
                  _0x5d8974,
                  !![],
                  !![]
                )
              )
                return ![];
              break;
            case _0x100e90(0x218):
              if (
                _0x35f281["o"] >= _0x21d4cf["c"] ||
                _0x35f281["c"] <= _0x21d4cf["o"]
              )
                return ![];
              let _0x2f851e =
                ((_0x21d4cf["c"] - _0x21d4cf["o"]) / _0x21d4cf["o"]) * 0x64;
              if (
                _0x2f851e > 0x0 ||
                _0x5b109 * 0.9 < Math[_0x100e90(0x260)](_0x2f851e)
              )
                return ![];
              if (
                !checkTrend(
                  "downtrend",
                  _0x35f281,
                  _0x1dae41[_0x100e90(0x287)],
                  _0x1dae41[_0x100e90(0x1f0)],
                  "c",
                  _0x5d8974,
                  !![],
                  !![]
                )
              )
                return ![];
              break;
            case _0x100e90(0x1fd):
              if (
                _0x21d4cf["o"] <= _0x35f281["c"] ||
                _0x21d4cf["c"] >= _0x35f281["o"]
              )
                return ![];
              let _0x4b9626 =
                ((_0x21d4cf["c"] - _0x21d4cf["o"]) / _0x21d4cf["o"]) * 0x64;
              if (
                _0x4b9626 < 0x0 ||
                Math[_0x100e90(0x260)](_0x5b109) * 0.9 < _0x4b9626
              )
                return ![];
              if (
                !checkTrend(
                  _0x100e90(0x20c),
                  _0x35f281,
                  _0x1dae41["data"],
                  _0x1dae41[_0x100e90(0x1f0)],
                  "c",
                  _0x5d8974,
                  !![],
                  !![]
                )
              )
                return ![];
              break;
            case "bullish\x20harami":
              if (
                _0x35f281["o"] <= _0x21d4cf["c"] ||
                _0x35f281["c"] >= _0x21d4cf["o"]
              )
                return ![];
              let _0x79b7e1 =
                ((_0x21d4cf["c"] - _0x21d4cf["o"]) / _0x21d4cf["o"]) * 0x64;
              if (
                _0x79b7e1 > 0x0 ||
                _0x5b109 < 0x0 ||
                Math[_0x100e90(0x260)](_0x79b7e1) * 0.9 < _0x5b109
              )
                return ![];
              if (
                !checkTrend(
                  _0x100e90(0x286),
                  _0x35f281,
                  _0x1dae41[_0x100e90(0x287)],
                  _0x1dae41["index"],
                  "c",
                  _0x5d8974,
                  !![],
                  !![]
                )
              )
                return ![];
              break;
            case _0x100e90(0x224):
              if (
                _0x35f281["o"] >= _0x21d4cf["c"] ||
                _0x35f281["c"] <= _0x21d4cf["o"]
              )
                return ![];
              let _0xfa926b =
                ((_0x21d4cf["c"] - _0x21d4cf["o"]) / _0x21d4cf["o"]) * 0x64;
              if (
                _0xfa926b < 0x0 ||
                _0x5b109 > 0x0 ||
                _0xfa926b * 0.9 < Math[_0x100e90(0x260)](_0x5b109)
              )
                return ![];
              if (
                !checkTrend(
                  _0x100e90(0x20c),
                  _0x35f281,
                  _0x1dae41[_0x100e90(0x287)],
                  _0x1dae41[_0x100e90(0x1f0)],
                  "c",
                  _0x5d8974,
                  !![],
                  !![]
                )
              )
                return ![];
              break;
            case _0x100e90(0x270):
              if (_0x5b109 < 0.1) return ![];
              let _0x46261b =
                _0x1dae41["data"][_0x1dae41[_0x100e90(0x1f0)] - 0x2];
              if (!_0x46261b) return ![];
              if (
                _0x35f281["c"] > _0x46261b["o"] ||
                _0x35f281["c"] <
                  _0x46261b["c"] + 0.2 * (_0x46261b["o"] - _0x46261b["c"]) ||
                _0x21d4cf["c"] > _0x46261b["c"] ||
                _0x21d4cf["o"] > _0x46261b["c"]
              )
                return ![];
              let _0x4efdb3 =
                ((_0x46261b["c"] - _0x46261b["o"]) / _0x46261b["o"]) * 0x64;
              if (_0x4efdb3 > -0.3) return ![];
              let _0x464c31 =
                ((_0x21d4cf["c"] - _0x21d4cf["o"]) / _0x21d4cf["o"]) * 0x64;
              if (
                Math[_0x100e90(0x260)](_0x464c31) > 0.3 ||
                Math[_0x100e90(0x260)](_0x464c31) * 1.5 >
                  Math[_0x100e90(0x260)](_0x4efdb3)
              )
                return ![];
              if (
                !checkTrend(
                  _0x100e90(0x286),
                  _0x21d4cf,
                  _0x1dae41[_0x100e90(0x287)],
                  _0x1dae41["index"] - 0x1,
                  "c",
                  _0x5d8974,
                  !![],
                  !![]
                )
              )
                return ![];
              break;
            case "evening\x20star":
              if (_0x5b109 > -0.1) return ![];
              let _0xd5ac40 =
                _0x1dae41[_0x100e90(0x287)][_0x1dae41[_0x100e90(0x1f0)] - 0x2];
              if (!_0xd5ac40) return ![];
              if (
                _0x35f281["c"] < _0xd5ac40["o"] ||
                _0x35f281["c"] >
                  _0xd5ac40["c"] - 0.2 * (_0xd5ac40["c"] - _0xd5ac40["o"]) ||
                _0x21d4cf["c"] < _0xd5ac40["c"] ||
                _0x21d4cf["o"] < _0xd5ac40["c"]
              )
                return ![];
              let _0x5e55ae =
                ((_0xd5ac40["c"] - _0xd5ac40["o"]) / _0xd5ac40["o"]) * 0x64;
              if (_0x5e55ae < 0.3) return ![];
              let _0x33949f =
                ((_0x21d4cf["c"] - _0x21d4cf["o"]) / _0x21d4cf["o"]) * 0x64;
              if (
                Math[_0x100e90(0x260)](_0x33949f) > 0.3 ||
                Math[_0x100e90(0x260)](_0x33949f) * 1.5 > Math["abs"](_0x5e55ae)
              )
                return ![];
              if (
                !checkTrend(
                  _0x100e90(0x20c),
                  _0x21d4cf,
                  _0x1dae41["data"],
                  _0x1dae41[_0x100e90(0x1f0)] - 0x1,
                  "c",
                  _0x5d8974,
                  !![],
                  !![]
                )
              )
                return ![];
              break;
            default:
              return ![];
          }
        }
        if (_0x3b0cea[_0x100e90(0x249)]) {
          let _0x1cfe65 = [];
          _0x1cfe65["push"](
            ((_0x35f281["c"] - _0x35f281["o"]) / _0x35f281["o"]) * 0x64
          );
          _0x3b0cea["mode"] === _0x100e90(0x24f) &&
            _0x3b0cb4[_0x100e90(0x222)] === 0x1 &&
            _0x35f281["h1"] &&
            _0x35f281["l1"] &&
            (_0x1cfe65[_0x100e90(0x207)](
              ((_0x35f281["h1"] - _0x35f281["o"]) / _0x35f281["o"]) * 0x64
            ),
            _0x1cfe65[_0x100e90(0x207)](
              ((_0x35f281["l1"] - _0x35f281["o"]) / _0x35f281["o"]) * 0x64
            ));
          let _0x3f757f = {
            direction: _0x3b0cea["condition2"][_0x100e90(0x23e)],
            checkValueCur: _0x1cfe65,
            confirmValue: _0x3b0cea["condition2"][_0x100e90(0x1e9)],
            confirmValue2: _0x3b0cea["condition2"]["value2"],
          };
          if (!checkRuleLogic(_0x3f757f)) return ![];
        }
        if (_0x3b0cea["condition3"]) {
          let _0x5d3451 = [];
          _0x5d3451[_0x100e90(0x207)](
            ((_0x35f281["h"] - _0x35f281["l"]) / _0x35f281["l"]) * 0x64
          );
          let _0x1a37b9 = {
            direction: _0x3b0cea[_0x100e90(0x1e7)][_0x100e90(0x23e)],
            checkValueCur: _0x5d3451,
            confirmValue: _0x3b0cea[_0x100e90(0x1e7)]["value"],
            confirmValue2: _0x3b0cea[_0x100e90(0x1e7)][_0x100e90(0x237)],
          };
          if (!checkRuleLogic(_0x1a37b9)) return ![];
        }
        if (_0x3b0cea["condition4"]) {
          let _0x573971 = [],
            _0x1f4396 = Math[_0x100e90(0x255)](_0x35f281["o"], _0x35f281["c"]);
          _0x573971[_0x100e90(0x207)](
            ((_0x35f281["h"] - _0x1f4396) / _0x1f4396) * 0x64
          );
          let _0x1980dd = {
            direction: _0x3b0cea[_0x100e90(0x232)][_0x100e90(0x23e)],
            checkValueCur: _0x573971,
            confirmValue: _0x3b0cea[_0x100e90(0x232)]["value"],
            confirmValue2: _0x3b0cea[_0x100e90(0x232)][_0x100e90(0x237)],
          };
          if (!checkRuleLogic(_0x1980dd)) return ![];
        }
        if (_0x3b0cea[_0x100e90(0x24b)]) {
          let _0x1806d6 = [],
            _0x203c95 = Math[_0x100e90(0x281)](_0x35f281["o"], _0x35f281["c"]);
          _0x1806d6[_0x100e90(0x207)](
            Math["abs"](((_0x35f281["l"] - _0x203c95) / _0x203c95) * 0x64)
          );
          let _0x4d12ba = {
            direction: _0x3b0cea[_0x100e90(0x24b)][_0x100e90(0x23e)],
            checkValueCur: _0x1806d6,
            confirmValue: _0x3b0cea[_0x100e90(0x24b)][_0x100e90(0x1e9)],
            confirmValue2: _0x3b0cea[_0x100e90(0x24b)][_0x100e90(0x237)],
          };
          if (!checkRuleLogic(_0x4d12ba)) return ![];
        }
        break;
      case _0x100e90(0x21b):
      case _0x100e90(0x251):
        if (_0x3b0cea[_0x100e90(0x1ef)]) {
          let _0xee5ce9 = getIndicatorId({
              indicator: _0x3b0cea[_0x100e90(0x1ef)][_0x100e90(0x23d)],
              period: _0x3b0cea["condition1"]["period"],
              timeframe: _0x3b0cea[_0x100e90(0x20f)],
              mode: _0x3b0cea[_0x100e90(0x240)],
            }),
            _0x56f093 = _0x55b0fb[_0xee5ce9],
            _0x23cd00 = _0x18ff10[_0xee5ce9],
            _0xc89c98 = null,
            _0x448284 = null;
          while (_0x23cd00[_0x100e90(0x1f0)] < _0x56f093[_0x100e90(0x222)]) {
            let _0x3851b2 = _0x56f093[_0x23cd00[_0x100e90(0x1f0)]];
            if (
              _0x3851b2 &&
              ensureDate(_0x543c8b["d"]) >= ensureDate(_0x3851b2["d"]) &&
              ensureDate(_0x543c8b["cd"]) <= ensureDate(_0x3851b2["cd"])
            ) {
              if (
                _0x3b0cea[_0x100e90(0x240)] === "current" &&
                _0x3851b2[_0x100e90(0x20d)]
              )
                while (
                  _0x23cd00[_0x100e90(0x22c)] <
                  _0x3851b2["subData"][_0x100e90(0x222)]
                ) {
                  let _0x41a957 =
                    _0x3851b2["subData"][_0x23cd00[_0x100e90(0x22c)]];
                  if (
                    ensureDate(_0x35f281["d"]) >= ensureDate(_0x41a957["d"]) &&
                    ensureDate(_0x35f281["cd"]) <= ensureDate(_0x41a957["cd"])
                  ) {
                    _0xc89c98 = _0x41a957;
                    break;
                  }
                  _0x23cd00["subIndex"]++;
                }
              else _0xc89c98 = _0x3851b2;
              break;
            }
            _0x23cd00[_0x100e90(0x1f0)]++, (_0x23cd00[_0x100e90(0x22c)] = 0x0);
          }
          _0x23cd00["index"] > 0x0 &&
            (_0x448284 = _0x56f093[_0x23cd00[_0x100e90(0x1f0)] - 0x1]);
          if (!_0xc89c98 || !_0x448284) return ![];
          if (
            _0x3b0cea[_0x100e90(0x1ef)][_0x100e90(0x263)] ===
            "top\x20to\x20bottom"
          ) {
            if (
              _0x21904e["ma"] < _0x448284["ma"] ||
              _0x1960db["ma"] >= _0xc89c98["ma"]
            )
              return ![];
          } else {
            if (
              _0x21904e["ma"] > _0x448284["ma"] ||
              _0x1960db["ma"] <= _0xc89c98["ma"]
            )
              return ![];
          }
        }
        if (_0x3b0cea["condition2"]) {
          if (
            !checkTrend(
              _0x3b0cea[_0x100e90(0x249)][_0x100e90(0x23e)],
              _0x1960db,
              _0x4a888b,
              _0x223f75["index"],
              "ma",
              _0x3b0cea[_0x100e90(0x249)]["value"],
              !![],
              !![]
            )
          )
            return ![];
        }
        if (_0x3b0cea[_0x100e90(0x1e7)])
          switch (_0x3b0cea[_0x100e90(0x1e7)][_0x100e90(0x23e)]) {
            case "uptrend":
              let _0x1b49c9 = checkTrend(
                _0x100e90(0x286),
                _0x1960db,
                _0x4a888b,
                _0x223f75[_0x100e90(0x1f0)],
                "ma",
                _0x3b0cea["condition3"]["value2"],
                !![],
                !![]
              );
              if (!_0x1b49c9) return ![];
              if (
                !checkTrend(
                  _0x3b0cea[_0x100e90(0x1e7)][_0x100e90(0x23e)],
                  _0x4a888b[_0x1b49c9],
                  _0x4a888b,
                  _0x1b49c9,
                  "ma",
                  _0x3b0cea[_0x100e90(0x1e7)][_0x100e90(0x1e9)],
                  !![],
                  ![]
                )
              )
                return ![];
              break;
            case _0x100e90(0x286):
              let _0x3ba613 = checkTrend(
                _0x100e90(0x20c),
                _0x1960db,
                _0x4a888b,
                _0x223f75[_0x100e90(0x1f0)],
                "ma",
                _0x3b0cea[_0x100e90(0x1e7)][_0x100e90(0x237)],
                !![],
                !![]
              );
              if (!_0x3ba613) return ![];
              if (
                !checkTrend(
                  _0x3b0cea[_0x100e90(0x1e7)][_0x100e90(0x23e)],
                  _0x4a888b[_0x3ba613],
                  _0x4a888b,
                  _0x3ba613,
                  "ma",
                  _0x3b0cea["condition3"][_0x100e90(0x1e9)],
                  !![],
                  ![]
                )
              )
                return ![];
              break;
            default:
              return ![];
          }
        if (_0x3b0cea[_0x100e90(0x232)]) {
          if (_0x223f75["index"] - _0x3b0cea[_0x100e90(0x232)]["value"] < 0x1)
            return ![];
          let _0x15d222 = _0x1960db;
          for (
            let _0x17af61 = _0x223f75["index"] - 0x1;
            _0x17af61 >=
            _0x223f75[_0x100e90(0x1f0)] -
              _0x3b0cea[_0x100e90(0x232)][_0x100e90(0x1e9)];
            _0x17af61--
          ) {
            let _0x2d36f7 = _0x4a888b[_0x17af61];
            switch (_0x3b0cea["condition4"][_0x100e90(0x23e)]) {
              case _0x100e90(0x1f7):
                if (_0x2d36f7["ma"] >= _0x15d222["ma"]) return ![];
                break;
              case _0x100e90(0x1f9):
                if (_0x2d36f7["ma"] <= _0x15d222["ma"]) return ![];
                break;
              default:
                return ![];
            }
            _0x15d222 = _0x2d36f7;
          }
        }
        break;
      case _0x100e90(0x215):
        if (_0x3b0cea["condition1"]) {
          let _0x58f168 = {
            checkValueCur: _0x1960db["r"],
            checkValuePrev: _0x21904e["r"],
            confirmValue: _0x3b0cea[_0x100e90(0x1ef)][_0x100e90(0x1e9)],
            confirmValuePrev: _0x3b0cea[_0x100e90(0x1ef)][_0x100e90(0x1e9)],
            direction: _0x3b0cea["condition1"][_0x100e90(0x23e)],
            crossDirection: _0x3b0cea[_0x100e90(0x1ef)][_0x100e90(0x263)],
          };
          if (!checkRuleLogic(_0x58f168)) return ![];
        }
        if (_0x3b0cea[_0x100e90(0x249)])
          switch (_0x3b0cea[_0x100e90(0x249)][_0x100e90(0x23e)]) {
            case "uptrend":
              let _0x45d8ef = checkTrend(
                "downtrend",
                _0x1960db,
                _0x4a888b,
                _0x223f75[_0x100e90(0x1f0)],
                "r",
                _0x3b0cea[_0x100e90(0x249)][_0x100e90(0x237)],
                ![],
                !![]
              );
              if (!_0x45d8ef) return ![];
              if (
                !checkTrend(
                  _0x3b0cea[_0x100e90(0x249)]["direction"],
                  _0x4a888b[_0x45d8ef],
                  _0x4a888b,
                  _0x45d8ef,
                  "r",
                  _0x3b0cea[_0x100e90(0x249)][_0x100e90(0x1e9)],
                  ![],
                  ![]
                )
              )
                return ![];
              break;
            case "downtrend":
              let _0xb7cc51 = checkTrend(
                _0x100e90(0x20c),
                _0x1960db,
                _0x4a888b,
                _0x223f75["index"],
                "r",
                _0x3b0cea["condition2"]["value2"],
                ![],
                !![]
              );
              if (!_0xb7cc51) return ![];
              if (
                !checkTrend(
                  _0x3b0cea[_0x100e90(0x249)]["direction"],
                  _0x4a888b[_0xb7cc51],
                  _0x4a888b,
                  _0xb7cc51,
                  "r",
                  _0x3b0cea[_0x100e90(0x249)]["value"],
                  ![],
                  ![]
                )
              )
                return ![];
              break;
            default:
              return ![];
          }
        break;
      case _0x100e90(0x276):
        if (_0x3b0cea[_0x100e90(0x1ef)]) {
          let _0x31d535 = {
            checkValueCur: _0x1960db["m"],
            checkValuePrev: _0x21904e["m"],
            confirmValue: _0x1960db["s"],
            confirmValuePrev: _0x21904e["s"],
            direction: _0x3b0cea[_0x100e90(0x1ef)][_0x100e90(0x23e)],
            crossDirection: _0x3b0cea["condition1"][_0x100e90(0x263)],
          };
          if (!checkRuleLogic(_0x31d535)) return ![];
        }
        if (_0x3b0cea[_0x100e90(0x249)]) {
          let _0x597957 = {
            checkValueCur: _0x1960db["m"],
            checkValuePrev: _0x21904e["m"],
            confirmValue: 0x0,
            confirmValuePrev: 0x0,
            direction: _0x3b0cea[_0x100e90(0x249)][_0x100e90(0x23e)],
            crossDirection: _0x3b0cea[_0x100e90(0x249)]["crossDirection"],
          };
          if (!checkRuleLogic(_0x597957)) return ![];
        }
        if (_0x3b0cea["condition3"])
          switch (_0x3b0cea["condition3"][_0x100e90(0x23e)]) {
            case _0x100e90(0x268):
              if (_0x1960db["h"] <= 0x0) return ![];
              break;
            case _0x100e90(0x238):
              if (_0x1960db["h"] >= 0x0) return ![];
              break;
            default:
              return ![];
          }
        if (_0x3b0cea["condition4"])
          switch (_0x3b0cea["condition4"][_0x100e90(0x23e)]) {
            case _0x100e90(0x235):
              if (_0x1960db["h"] <= 0x0) return ![];
              if (!_0x21904e || _0x21904e["h"] <= _0x1960db["h"]) return ![];
              if (
                !_0x4a888b[_0x223f75[_0x100e90(0x1f0)] - 0x2] ||
                _0x21904e["h"] <
                  _0x4a888b[_0x223f75[_0x100e90(0x1f0)] - 0x2]["h"]
              )
                return ![];
              break;
            case "trough":
              if (_0x1960db["h"] >= 0x0) return ![];
              if (!_0x21904e || _0x21904e["h"] >= _0x1960db["h"]) return ![];
              if (
                !_0x4a888b[_0x223f75[_0x100e90(0x1f0)] - 0x2] ||
                _0x21904e["h"] >
                  _0x4a888b[_0x223f75[_0x100e90(0x1f0)] - 0x2]["h"]
              )
                return ![];
              break;
            default:
              return ![];
          }
        break;
      case "Bollinger\x20Bands":
      case _0x100e90(0x21f):
        if (_0x3b0cea[_0x100e90(0x1ef)]) {
          if (
            _0x1960db["w"] >= _0x3b0cea[_0x100e90(0x1ef)][_0x100e90(0x1e9)] ||
            _0x21904e["w"] < _0x3b0cea[_0x100e90(0x1ef)][_0x100e90(0x1e9)]
          )
            return ![];
        }
        if (_0x3b0cea[_0x100e90(0x249)]) {
          if (
            _0x1960db["w"] >= _0x3b0cea["condition2"][_0x100e90(0x1e9)] ||
            _0x21904e["w"] >= _0x3b0cea[_0x100e90(0x249)][_0x100e90(0x1e9)]
          )
            return ![];
        }
        if (_0x3b0cea[_0x100e90(0x1e7)]) {
          if (
            _0x1960db["w"] <= _0x3b0cea["condition3"]["value"] ||
            _0x21904e["w"] > _0x3b0cea[_0x100e90(0x1e7)][_0x100e90(0x1e9)]
          )
            return ![];
        }
        if (_0x3b0cea[_0x100e90(0x232)]) {
          if (
            _0x1960db["w"] <= _0x3b0cea[_0x100e90(0x232)][_0x100e90(0x1e9)] ||
            _0x21904e["w"] <= _0x3b0cea[_0x100e90(0x232)][_0x100e90(0x1e9)]
          )
            return ![];
        }
        break;
      case _0x100e90(0x236):
      case _0x100e90(0x272):
        if (_0x3b0cea[_0x100e90(0x1ef)]) {
          let _0x25fa6e = {
            checkValueCur: _0x1960db["k"],
            checkValuePrev: _0x21904e["k"],
            confirmValue: _0x1960db["dl"],
            confirmValuePrev: _0x21904e["dl"],
            direction: _0x3b0cea[_0x100e90(0x1ef)][_0x100e90(0x23e)],
            crossDirection: _0x3b0cea[_0x100e90(0x1ef)]["crossDirection"],
          };
          if (!checkRuleLogic(_0x25fa6e)) return ![];
        }
        if (_0x3b0cea[_0x100e90(0x249)]) {
          let _0x1167b3 = {
            checkValueCur: _0x1960db["k"],
            checkValuePrev: _0x21904e["k"],
            confirmValue: _0x3b0cea[_0x100e90(0x249)][_0x100e90(0x1e9)],
            confirmValuePrev: _0x3b0cea[_0x100e90(0x249)][_0x100e90(0x1e9)],
            direction: _0x3b0cea["condition2"]["direction"],
            crossDirection: _0x3b0cea[_0x100e90(0x249)][_0x100e90(0x263)],
          };
          if (!checkRuleLogic(_0x1167b3)) return ![];
        }
        if (_0x3b0cea[_0x100e90(0x1e7)])
          switch (_0x3b0cea[_0x100e90(0x1e7)][_0x100e90(0x23e)]) {
            case _0x100e90(0x20c):
              let _0x3c94f4 = checkTrend(
                _0x100e90(0x286),
                _0x1960db,
                _0x4a888b,
                _0x223f75[_0x100e90(0x1f0)],
                "k",
                _0x3b0cea[_0x100e90(0x1e7)][_0x100e90(0x237)],
                ![],
                !![]
              );
              if (!_0x3c94f4) return ![];
              if (
                !checkTrend(
                  _0x3b0cea[_0x100e90(0x1e7)][_0x100e90(0x23e)],
                  _0x4a888b[_0x3c94f4],
                  _0x4a888b,
                  _0x3c94f4,
                  "k",
                  _0x3b0cea[_0x100e90(0x1e7)][_0x100e90(0x1e9)],
                  ![],
                  ![]
                )
              )
                return ![];
              break;
            case _0x100e90(0x286):
              let _0x16799c = checkTrend(
                "uptrend",
                _0x1960db,
                _0x4a888b,
                _0x223f75[_0x100e90(0x1f0)],
                "k",
                _0x3b0cea[_0x100e90(0x1e7)]["value2"],
                ![],
                !![]
              );
              if (!_0x16799c) return ![];
              if (
                !checkTrend(
                  _0x3b0cea[_0x100e90(0x1e7)][_0x100e90(0x23e)],
                  _0x4a888b[_0x16799c],
                  _0x4a888b,
                  _0x16799c,
                  "k",
                  _0x3b0cea[_0x100e90(0x1e7)][_0x100e90(0x1e9)],
                  ![],
                  ![]
                )
              )
                return ![];
              break;
            default:
              return ![];
          }
        break;
      case _0x100e90(0x26f):
        if (_0x3b0cea[_0x100e90(0x1ef)])
          switch (_0x3b0cea["condition1"][_0x100e90(0x23e)]) {
            case _0x100e90(0x268):
              if (_0x1960db["h"] <= 0x0) return ![];
              break;
            case _0x100e90(0x238):
              if (_0x1960db["h"] >= 0x0) return ![];
              break;
            default:
              return ![];
          }
        if (_0x3b0cea[_0x100e90(0x249)])
          switch (_0x3b0cea[_0x100e90(0x249)][_0x100e90(0x23e)]) {
            case _0x100e90(0x235):
              if (_0x1960db["h"] <= 0x0) return ![];
              if (!_0x21904e || _0x21904e["h"] <= _0x1960db["h"]) return ![];
              if (
                !_0x4a888b[_0x223f75[_0x100e90(0x1f0)] - 0x2] ||
                _0x21904e["h"] <
                  _0x4a888b[_0x223f75[_0x100e90(0x1f0)] - 0x2]["h"]
              )
                return ![];
              break;
            case "trough":
              if (_0x1960db["h"] >= 0x0) return ![];
              if (!_0x21904e || _0x21904e["h"] >= _0x1960db["h"]) return ![];
              if (
                !_0x4a888b[_0x223f75["index"] - 0x2] ||
                _0x21904e["h"] >
                  _0x4a888b[_0x223f75[_0x100e90(0x1f0)] - 0x2]["h"]
              )
                return ![];
              break;
            default:
              return ![];
          }
        break;
      case "Momentum\x20Squeeze":
        if (_0x3b0cea[_0x100e90(0x1ef)]) {
          if (!_0x1960db["s"]) return ![];
          if (_0x21904e["s"]) return ![];
        }
        if (_0x3b0cea[_0x100e90(0x249)]) {
          if (!_0x1960db["s"]) return ![];
        }
        if (_0x3b0cea[_0x100e90(0x1e7)]) {
          if (_0x1960db["s"]) return ![];
          if (!_0x21904e["s"]) return ![];
        }
        if (_0x3b0cea["condition4"]) {
          if (_0x1960db["s"]) return ![];
        }
        if (_0x3b0cea["condition5"])
          switch (_0x3b0cea[_0x100e90(0x24b)][_0x100e90(0x23e)]) {
            case _0x100e90(0x268):
              if (_0x1960db["h"] <= 0x0) return ![];
              break;
            case _0x100e90(0x238):
              if (_0x1960db["h"] >= 0x0) return ![];
              break;
            default:
              return ![];
          }
        if (_0x3b0cea[_0x100e90(0x28e)])
          switch (_0x3b0cea["condition6"][_0x100e90(0x23e)]) {
            case _0x100e90(0x235):
              if (_0x1960db["h"] <= 0x0) return ![];
              if (!_0x21904e || _0x21904e["h"] <= _0x1960db["h"]) return ![];
              if (
                !_0x4a888b[_0x223f75["index"] - 0x2] ||
                _0x21904e["h"] < _0x4a888b[_0x223f75["index"] - 0x2]["h"]
              )
                return ![];
              break;
            case _0x100e90(0x24e):
              if (_0x1960db["h"] >= 0x0) return ![];
              if (!_0x21904e || _0x21904e["h"] >= _0x1960db["h"]) return ![];
              if (
                !_0x4a888b[_0x223f75["index"] - 0x2] ||
                _0x21904e["h"] >
                  _0x4a888b[_0x223f75[_0x100e90(0x1f0)] - 0x2]["h"]
              )
                return ![];
              break;
            default:
              return ![];
          }
        break;
      default:
        return ![];
    }
  }
  return !![];
}
function roundQuotedValue(_0x22042f, _0x451e9d) {
  const _0x4aad9e = a0_0x29779a;
  if (typeof _0x22042f !== _0x4aad9e(0x25f)) return "";
  return _0x451e9d
    ? +_0x22042f[_0x4aad9e(0x23a)](_0x451e9d[_0x4aad9e(0x267)])
    : fixNumber(_0x22042f, 0x8);
}
function a0_0x36d0() {
  const _0x1dd62f = [
    "value2",
    "negative",
    "buyRules",
    "toFixed",
    "splice",
    "intercept",
    "indicator",
    "direction",
    "5\x20minutes",
    "mode",
    "std",
    "sellSizeInBase",
    "highest",
    "getMinutes",
    "winLossRatio",
    "low",
    "tBuyLow",
    "closeStatus",
    "condition2",
    "commQuoted",
    "condition5",
    "Bollinger\x20Bands",
    "above",
    "trough",
    "current",
    "stoploss",
    "EMA",
    "doji",
    "map",
    "avPosTrade",
    "max",
    "slope",
    "avTrade",
    "trailingTarget",
    "pairDetails",
    "lostTrades",
    "smoothed",
    "tradesCount",
    "formula",
    "closeId",
    "number",
    "abs",
    "setMinutes",
    "12\x20hours",
    "crossDirection",
    "greater\x20than",
    "1137493YDwszC",
    "ATR",
    "quotedDecimal",
    "positive",
    "confirmValue",
    "slCooldown",
    "bottom\x20to\x20top",
    "down",
    "reduce",
    "high",
    "Momentum",
    "morning\x20star",
    "between",
    "Stochastic\x20RSI",
    "atr",
    "sellSizeInQuoted",
    "slTimer",
    "MACD",
    "backtest\x20error:\x20",
    "checkValueCur",
    "comm",
    "period2",
    "winRate",
    "sizeInBase",
    "stoplossWaiting",
    "getTime",
    "Manual\x20order",
    "timestop",
    "min",
    "getSeconds",
    "totalReturnStdDev2",
    "avTradeDuration",
    "buy",
    "downtrend",
    "data",
    "indexOf",
    "less\x20than",
    "winTrades",
    "tBuy",
    "lowest",
    "confirmValuePrev",
    "condition6",
    "quoted",
    "1\x20hour",
    "period4",
    "condition3",
    "target",
    "value",
    "15\x20minutes",
    "23424AusUkg",
    "stack",
    "upper\x20band",
    "4\x20hours",
    "condition1",
    "index",
    "direction2",
    "replace",
    "114360YdNxhV",
    "sell",
    "2vMRXzv",
    "candleAction",
    "rising",
    "forEach",
    "falling",
    "233052EVDySo",
    "timeClose",
    "getHours",
    "bearish\x20engulfing",
    "2789163AdBRej",
    "band",
    "resultInQuoted",
    "sqrt",
    "minPrice",
    "trades",
    "below",
    "nextTrade",
    "candle1m",
    "push",
    "55flAEsU",
    "checkValuePrev",
    "147315kYDcJa",
    "shooting\x20star",
    "uptrend",
    "subData",
    "the\x20candlestick",
    "timeframe",
    "setHours",
    "totalWin",
    "concat",
    "hanging\x20man",
    "top\x20to\x20bottom",
    "RSI",
    "period",
    "hammer",
    "bullish\x20engulfing",
    "totalReturn",
    "string",
    "SMA",
    "1\x20day",
    "crossing",
    "keys",
    "Keltner\x20Channel",
    "181613wCczht",
    "details",
    "length",
    "period3",
    "bearish\x20harami",
    "the\x20price",
    "sizeInQuoted",
    "type",
    "wCooldown",
    "trailingTargetPriceUsed",
    "tradeStdDev",
    "totalLost",
    "subIndex",
    "<Select>",
    "tbd",
    "sellRules",
    "30\x20minutes",
    "result",
    "condition4",
    "base",
    "1\x20minute",
    "peak",
    "Stochastic",
  ];
  a0_0x36d0 = function () {
    return _0x1dd62f;
  };
  return a0_0x36d0();
}
function getCandleData(_0x2f1f7a, _0x5203f8, _0x4ebb45, _0x54a4b8) {
  const _0x29ad96 = a0_0x29779a;
  let _0x141194 =
      _0x54a4b8[_0x29ad96(0x240)] === _0x29ad96(0x24f)
        ? _0x2f1f7a[_0x29ad96(0x206)]
        : _0x2f1f7a[_0x29ad96(0x287)][_0x2f1f7a[_0x29ad96(0x1f0)] - 0x1],
    _0x45eef2 =
      _0x54a4b8[_0x29ad96(0x240)] === _0x29ad96(0x24f)
        ? _0x2f1f7a[_0x29ad96(0x287)][_0x2f1f7a[_0x29ad96(0x1f0)]]
        : _0x141194,
    _0x2dfd17 =
      _0x2f1f7a[_0x29ad96(0x287)][
        _0x2f1f7a[_0x29ad96(0x1f0)] -
          (_0x54a4b8[_0x29ad96(0x240)] === _0x29ad96(0x24f) ? 0x1 : 0x2)
      ];
  if (!_0x141194 || !_0x2dfd17) return null;
  let _0x2dd02e = null,
    _0x205c7e = null,
    _0xdf38d8 = null,
    _0x543d13 = null;
  if (
    (_0x54a4b8[_0x29ad96(0x23d)] !== _0x29ad96(0x20e) &&
      _0x54a4b8["indicator"] !== _0x29ad96(0x27f) &&
      _0x54a4b8[_0x29ad96(0x23d)] !== _0x29ad96(0x225)) ||
    (_0x54a4b8[_0x29ad96(0x23d)] === _0x29ad96(0x225) &&
      _0x54a4b8["condition2"])
  ) {
    let _0x24d50a = getIndicatorId(_0x54a4b8);
    (_0x2dd02e = _0x5203f8[_0x24d50a]), (_0x543d13 = _0x4ebb45[_0x24d50a]);
    while (_0x543d13[_0x29ad96(0x1f0)] < _0x2dd02e[_0x29ad96(0x222)]) {
      let _0x4e474d = _0x2dd02e[_0x543d13[_0x29ad96(0x1f0)]];
      if (
        _0x4e474d &&
        ensureDate(_0x45eef2["d"]) >= ensureDate(_0x4e474d["d"]) &&
        ensureDate(_0x45eef2["cd"]) <= ensureDate(_0x4e474d["cd"])
      ) {
        if (_0x54a4b8[_0x29ad96(0x240)] === _0x29ad96(0x24f))
          while (
            _0x4e474d[_0x29ad96(0x20d)] &&
            _0x543d13[_0x29ad96(0x22c)] <
              _0x4e474d[_0x29ad96(0x20d)][_0x29ad96(0x222)]
          ) {
            let _0x1b40d1 = _0x4e474d["subData"][_0x543d13[_0x29ad96(0x22c)]];
            if (
              ensureDate(_0x141194["d"]) >= ensureDate(_0x1b40d1["d"]) &&
              ensureDate(_0x141194["cd"]) <= ensureDate(_0x1b40d1["cd"])
            ) {
              _0x205c7e = _0x1b40d1;
              break;
            }
            _0x543d13[_0x29ad96(0x22c)]++;
          }
        else _0x205c7e = _0x4e474d;
        break;
      }
      if (_0x4e474d && ensureDate(_0x45eef2["d"]) < ensureDate(_0x4e474d["d"]))
        break;
      _0x543d13[_0x29ad96(0x1f0)]++, (_0x543d13[_0x29ad96(0x22c)] = 0x0);
    }
    _0x543d13["index"] > 0x0 &&
      (_0xdf38d8 = _0x2dd02e[_0x543d13["index"] - 0x1]);
    if (!_0x205c7e || !_0xdf38d8) return null;
  }
  return [
    _0x141194,
    _0x2dfd17,
    _0x45eef2,
    _0x205c7e,
    _0xdf38d8,
    _0x2dd02e,
    _0x543d13,
  ];
}
function calculateVolumeSpread(_0x1d223b, _0x5c1249, _0x2cff44) {
  let _0x292052 = _0x1d223b - _0x5c1249,
    _0x47d6a5 = _0x2cff44;
  while (_0x292052 > 0x0) {
    (_0x292052 -= _0x5c1249), (_0x47d6a5 += _0x2cff44);
  }
  return Math["min"](_0x47d6a5, 0.3);
}
function a0_0x3fc6(_0x17aa5a, _0x8726c3) {
  const _0x36d01c = a0_0x36d0();
  return (
    (a0_0x3fc6 = function (_0x3fc6c5, _0xce88fb) {
      _0x3fc6c5 = _0x3fc6c5 - 0x1e7;
      let _0x323a23 = _0x36d01c[_0x3fc6c5];
      return _0x323a23;
    }),
    a0_0x3fc6(_0x17aa5a, _0x8726c3)
  );
}
function checkStoplossAndTarget(_0x11ed7f, _0x4c6382, _0x5ec17e) {
  const _0x2ce019 = a0_0x29779a;
  if (_0x11ed7f["stoploss"] && _0x11ed7f[_0x2ce019(0x250)] >= _0x4c6382["l1"]) {
    if (_0x5ec17e[_0x2ce019(0x275)]) {
      if (_0x11ed7f[_0x2ce019(0x27d)]) {
        if (_0x11ed7f["stoplossWaiting"] <= _0x4c6382["cd"])
          return (
            delete _0x11ed7f[_0x2ce019(0x27d)],
            [
              {
                d: _0x4c6382["d"],
                p:
                  _0x4c6382["o1"] < _0x11ed7f[_0x2ce019(0x250)]
                    ? _0x4c6382["o1"]
                    : _0x11ed7f[_0x2ce019(0x250)],
              },
              _0x2ce019(0x250),
            ]
          );
      } else
        (_0x11ed7f["stoplossWaiting"] = new Date(_0x4c6382["cd"]["getTime"]())),
          _0x11ed7f[_0x2ce019(0x27d)][_0x2ce019(0x261)](
            _0x11ed7f["stoplossWaiting"][_0x2ce019(0x244)]() +
              _0x5ec17e[_0x2ce019(0x275)]
          );
    } else
      return [
        {
          d: _0x4c6382["d"],
          p:
            _0x4c6382["o1"] < _0x11ed7f[_0x2ce019(0x250)]
              ? _0x4c6382["o1"]
              : _0x11ed7f[_0x2ce019(0x250)],
        },
        _0x2ce019(0x250),
      ];
  } else delete _0x11ed7f[_0x2ce019(0x27d)];
  if (
    _0x11ed7f[_0x2ce019(0x258)] &&
    _0x11ed7f[_0x2ce019(0x258)] >= _0x4c6382["l1"]
  )
    return [
      {
        d: _0x4c6382["d"],
        p:
          _0x4c6382["o1"] < _0x11ed7f[_0x2ce019(0x258)]
            ? _0x4c6382["o1"]
            : _0x11ed7f[_0x2ce019(0x258)],
      },
      _0x2ce019(0x258),
    ];
  if (
    _0x5ec17e[_0x2ce019(0x1e8)] &&
    !_0x5ec17e["trailingTarget"] &&
    _0x11ed7f[_0x2ce019(0x1e8)] <= _0x4c6382["h1"]
  )
    return [
      {
        d: _0x4c6382["d"],
        p:
          _0x4c6382["o1"] > _0x11ed7f[_0x2ce019(0x1e8)]
            ? _0x4c6382["o1"]
            : _0x11ed7f[_0x2ce019(0x1e8)],
      },
      _0x2ce019(0x1e8),
    ];
  if (
    _0x11ed7f[_0x2ce019(0x1fb)] &&
    ensureDate(_0x11ed7f[_0x2ce019(0x1fb)]) < ensureDate(_0x4c6382["d"])
  )
    return [{ d: _0x4c6382["d"], p: _0x4c6382["o1"] }, _0x2ce019(0x280)];
  if (
    _0x11ed7f[_0x2ce019(0x1fb)] &&
    ensureDate(_0x11ed7f[_0x2ce019(0x1fb)]) < ensureDate(_0x4c6382["cd"])
  )
    return [{ d: _0x4c6382["cd"], p: _0x4c6382["c"] }, _0x2ce019(0x280)];
  if (_0x5ec17e[_0x2ce019(0x258)]) {
    if (_0x11ed7f["trailingTargetPriceUsed"])
      _0x11ed7f[_0x2ce019(0x229)] < _0x4c6382["h1"] &&
        ((_0x11ed7f[_0x2ce019(0x229)] = _0x4c6382["h1"]),
        (_0x11ed7f[_0x2ce019(0x258)] = roundQuotedValue(
          _0x4c6382["h1"] * (0x1 - _0x5ec17e[_0x2ce019(0x258)] / 0x64),
          _0x5ec17e[_0x2ce019(0x259)]
        )));
    else
      _0x11ed7f[_0x2ce019(0x1e8)] <= _0x4c6382["h1"] &&
        ((_0x11ed7f[_0x2ce019(0x229)] = _0x4c6382["h1"]),
        (_0x11ed7f[_0x2ce019(0x258)] = roundQuotedValue(
          _0x4c6382["h1"] * (0x1 - _0x5ec17e[_0x2ce019(0x258)] / 0x64),
          _0x5ec17e[_0x2ce019(0x259)]
        )));
    if (
      _0x11ed7f[_0x2ce019(0x258)] &&
      _0x11ed7f["trailingTarget"] >= _0x4c6382["c"]
    )
      return [
        { d: _0x4c6382["d"], p: _0x11ed7f[_0x2ce019(0x258)] },
        _0x2ce019(0x258),
      ];
  }
  return [![]];
}
function updatePriceAction(_0x2e4379, _0x4ccbc3) {
  const _0x47e300 = a0_0x29779a;
  if (!_0x2e4379["pa"])
    _0x2e4379["pa"] = { low: _0x4ccbc3["c"], high: _0x4ccbc3["c"] };
  else {
    let _0x35e8ca = _0x4ccbc3["l1"] ? _0x4ccbc3["l1"] : _0x4ccbc3["l"],
      _0x54db72 = _0x4ccbc3["h1"] ? _0x4ccbc3["h1"] : _0x4ccbc3["h"];
    _0x2e4379["pa"][_0x47e300(0x246)] > _0x35e8ca &&
      (_0x2e4379["pa"][_0x47e300(0x246)] = _0x35e8ca),
      _0x2e4379["pa"]["high"] < _0x54db72 &&
        (_0x2e4379["pa"]["high"] = _0x54db72);
  }
}
function parseBacktestResult(_0x21eb4b) {
  const _0x27128f = a0_0x29779a;
  let _0x89ba84 = {
      trades: _0x21eb4b,
      totalReturnStdDev2: 0x0,
      totalReturn: 0x0,
      marketReturn: 0x0,
      maxDrawdown: 0x0,
      winRate: 0x0,
      resultInQuoted: 0x0,
      tradesCount: 0x0,
      winTrades: 0x0,
      lostTrades: 0x0,
      avPosTrade: 0x0,
      avNegTrade: 0x0,
      avTradeDuration: 0x0,
      totalWin: 0x0,
      totalLost: 0x0,
      cumulativeDates: [],
      cumulativeSeries: [],
      distributionSeries: [],
      distributionXaxis: [],
    },
    _0x2bcc86 = [0x0],
    _0x23a19b = ![],
    _0x24289a = [],
    _0x58c81b = 0x0;
  _0x21eb4b[_0x27128f(0x1f8)]((_0x47ed59) => {
    const _0x1fe0dd = _0x27128f;
    if (
      _0x47ed59[_0x1fe0dd(0x248)] &&
      _0x47ed59[_0x1fe0dd(0x231)] !== undefined &&
      _0x47ed59[_0x1fe0dd(0x231)] !== null
    ) {
      (_0x58c81b += Math[_0x1fe0dd(0x260)](_0x47ed59["od"] - _0x47ed59["cd"])),
        _0x89ba84["tradesCount"]++,
        (_0x89ba84[_0x1fe0dd(0x219)] += _0x47ed59[_0x1fe0dd(0x231)]),
        (_0x89ba84[_0x1fe0dd(0x200)] += _0x47ed59[_0x1fe0dd(0x200)]),
        _0x24289a[_0x1fe0dd(0x207)](_0x47ed59[_0x1fe0dd(0x231)]);
      if (_0x47ed59[_0x1fe0dd(0x231)] > 0x0)
        (_0x89ba84[_0x1fe0dd(0x211)] += _0x47ed59[_0x1fe0dd(0x231)]),
          _0x89ba84["winTrades"]++,
          (_0x23a19b = ![]);
      else
        _0x47ed59[_0x1fe0dd(0x231)] < 0x0
          ? ((_0x89ba84[_0x1fe0dd(0x22b)] += _0x47ed59["result"]),
            _0x89ba84[_0x1fe0dd(0x25a)]++,
            _0x23a19b
              ? (_0x2bcc86[_0x2bcc86["length"] - 0x1] +=
                  _0x47ed59[_0x1fe0dd(0x231)])
              : (_0x2bcc86[_0x1fe0dd(0x207)](_0x47ed59[_0x1fe0dd(0x231)]),
                (_0x23a19b = !![])))
          : (_0x23a19b = ![]);
    }
  }),
    (_0x89ba84[_0x27128f(0x284)] =
      _0x89ba84["tradesCount"] > 0x0
        ? _0x58c81b / _0x89ba84[_0x27128f(0x25c)]
        : 0x0),
    (_0x89ba84["tradeStdDev"] = calculateStdDev(_0x24289a)),
    (_0x89ba84[_0x27128f(0x257)] =
      _0x89ba84[_0x27128f(0x25c)] > 0x0
        ? _0x89ba84[_0x27128f(0x219)] / _0x89ba84[_0x27128f(0x25c)]
        : 0x0);
  let _0x4fb417 =
      _0x89ba84[_0x27128f(0x257)] + _0x89ba84[_0x27128f(0x22a)] * 0x2,
    _0x464347 = _0x89ba84[_0x27128f(0x257)] - _0x89ba84[_0x27128f(0x22a)] * 0x2;
  return (
    (_0x89ba84[_0x27128f(0x283)] = _0x24289a[_0x27128f(0x26d)](
      (_0x476340, _0x407adc) => {
        if (_0x407adc >= _0x464347 && _0x407adc <= _0x4fb417)
          return _0x476340 + _0x407adc;
        return _0x476340;
      },
      0x0
    )),
    (_0x89ba84["maxDrawdown"] = _0x2bcc86[_0x27128f(0x26d)](
      (_0x36cfc3, _0x1e43d2) => (_0x1e43d2 < _0x36cfc3 ? _0x1e43d2 : _0x36cfc3),
      0x0
    )),
    (_0x89ba84[_0x27128f(0x245)] =
      _0x89ba84[_0x27128f(0x25a)] > 0x0
        ? _0x89ba84[_0x27128f(0x28a)] / _0x89ba84[_0x27128f(0x25a)]
        : 0x1),
    (_0x89ba84[_0x27128f(0x27b)] =
      _0x89ba84[_0x27128f(0x25c)] > 0x0
        ? (_0x89ba84[_0x27128f(0x28a)] / _0x89ba84[_0x27128f(0x25c)]) * 0x64
        : 0x0),
    (_0x89ba84[_0x27128f(0x254)] =
      _0x89ba84[_0x27128f(0x28a)] > 0x0
        ? _0x89ba84["totalWin"] / _0x89ba84[_0x27128f(0x28a)]
        : 0x0),
    (_0x89ba84["avNegTrade"] =
      _0x89ba84[_0x27128f(0x25a)] > 0x0
        ? _0x89ba84[_0x27128f(0x22b)] / _0x89ba84["lostTrades"]
        : 0x0),
    _0x89ba84
  );
}
function buyOrder(
  _0x5eee43,
  _0x168949,
  _0x5825ce,
  _0x530779,
  _0x59e1e1,
  _0x5567b6,
  _0x1b84d2,
  _0x419bc5,
  _0x8a6fb7,
  _0x54cef3
) {
  const _0xb635aa = a0_0x29779a;
  let _0x4235ba = roundQuotedValue(_0x168949 * (0x1 + _0x1b84d2), _0x54cef3),
    _0x5ed2a3 = _0x530779[_0xb635aa(0x1e9)]
      ? _0x530779[_0xb635aa(0x227)] === _0xb635aa(0x233)
        ? _0x530779[_0xb635aa(0x1e9)]
        : _0x530779[_0xb635aa(0x1e9)] / _0x4235ba
      : null;
  if (_0x5ed2a3) {
    if (_0x8a6fb7["v1"] < _0x5ed2a3) {
      let _0x72e55a = calculateVolumeSpread(_0x5ed2a3, _0x419bc5, _0x1b84d2);
      (_0x4235ba = roundQuotedValue(_0x168949 * (0x1 + _0x72e55a), _0x54cef3)),
        (_0x5ed2a3 = _0x530779[_0xb635aa(0x1e9)]
          ? _0x530779[_0xb635aa(0x227)] === "base"
            ? _0x530779["value"]
            : _0x530779[_0xb635aa(0x1e9)] / _0x4235ba
          : null);
    }
  }
  _0x4235ba - _0x168949 < _0x54cef3[_0xb635aa(0x202)] &&
    (_0x4235ba = roundQuotedValue(
      _0x168949 + _0x54cef3["minPrice"],
      _0x54cef3
    ));
  let _0x224d73 = {
    openId: new Date()[_0xb635aa(0x27e)](),
    od: ensureDate(_0x5825ce)[_0xb635aa(0x27e)](),
    o: _0x4235ba,
    openStatus: "FILLED",
    sizeInBase: _0x5ed2a3,
    sizeInQuoted: _0x530779[_0xb635aa(0x1e9)]
      ? _0x530779[_0xb635aa(0x227)] === _0xb635aa(0x28f)
        ? _0x530779[_0xb635aa(0x1e9)]
        : _0x530779["value"] * _0x4235ba
      : null,
  };
  (_0x224d73[_0xb635aa(0x279)] = {
    bnb: 0x0,
    base:
      (_0x59e1e1 / 0x64) * _0x224d73[_0xb635aa(0x27c)]
        ? _0x224d73[_0xb635aa(0x27c)]
        : 0x0,
  }),
    (_0x5eee43[_0xb635aa(0x205)] = "sell"),
    (_0x5eee43[_0xb635aa(0x1f6)] = null),
    delete _0x5eee43["tBuyLow"],
    delete _0x5eee43["tbd"],
    _0x5567b6[_0xb635aa(0x250)] &&
      (_0x5eee43["stoploss"] = roundQuotedValue(
        _0x4235ba *
          (0x1 - (_0x5567b6[_0xb635aa(0x250)] - _0x59e1e1 * 0x2) / 0x64),
        _0x54cef3
      )),
    _0x5567b6["target"] &&
      (_0x5eee43["target"] = roundQuotedValue(
        _0x4235ba *
          (0x1 + (_0x5567b6[_0xb635aa(0x1e8)] + _0x59e1e1 * 0x2) / 0x64),
        _0x54cef3
      )),
    _0x5567b6[_0xb635aa(0x1fb)] &&
      ((_0x5eee43[_0xb635aa(0x1fb)] = new Date(
        ensureDate(_0x5825ce)[_0xb635aa(0x27e)]()
      )),
      _0x5eee43[_0xb635aa(0x1fb)][_0xb635aa(0x210)](
        _0x5eee43["timeClose"][_0xb635aa(0x1fc)]() + _0x5567b6["timeClose"]
      )),
    _0x5eee43[_0xb635aa(0x203)]["push"](_0x224d73);
}
function sellOrder(
  _0x3ec8d6,
  _0x5aac70,
  _0x3adb34,
  _0x2c416a,
  _0x1f2ccc,
  _0x12dbe4,
  _0x1ebca6,
  _0x3f4906,
  _0x5a8c84,
  _0x4d474f
) {
  const _0x1ff4cf = a0_0x29779a;
  delete _0x1f2ccc["pa"];
  let _0x2c6251 =
      _0x3ec8d6[_0x1ff4cf(0x203)][_0x3ec8d6["trades"]["length"] - 0x1],
    _0x4f9536 = roundQuotedValue(_0x5aac70 * (0x1 - _0x12dbe4), _0x5a8c84);
  if (_0x2c6251[_0x1ff4cf(0x27c)]) {
    if (_0x3f4906["v1"] < _0x2c6251["sizeInBase"]) {
      let _0x571d41 = calculateVolumeSpread(
        _0x2c6251["sizeInBase"],
        _0x1ebca6,
        _0x12dbe4
      );
      _0x4f9536 = roundQuotedValue(_0x5aac70 * (0x1 - _0x571d41), _0x5a8c84);
    }
  }
  _0x5aac70 - _0x4f9536 < _0x5a8c84[_0x1ff4cf(0x202)] &&
    (_0x4f9536 = roundQuotedValue(
      _0x5aac70 - _0x5a8c84[_0x1ff4cf(0x202)],
      _0x5a8c84
    )),
    (_0x2c6251[_0x1ff4cf(0x221)] = {
      s: _0x3ec8d6[_0x1ff4cf(0x250)],
      t: _0x3ec8d6[_0x1ff4cf(0x1e8)],
      tt: _0x3ec8d6[_0x1ff4cf(0x258)],
      hp: _0x3ec8d6[_0x1ff4cf(0x229)],
      tc: _0x3ec8d6["timeClose"],
      ct: _0x4d474f,
      cwp: _0x5aac70,
    }),
    (_0x3ec8d6[_0x1ff4cf(0x205)] = _0x1ff4cf(0x285)),
    (_0x3ec8d6[_0x1ff4cf(0x1f6)] = null),
    (_0x3ec8d6[_0x1ff4cf(0x250)] = null),
    (_0x3ec8d6["target"] = null),
    (_0x3ec8d6[_0x1ff4cf(0x258)] = null),
    (_0x3ec8d6[_0x1ff4cf(0x229)] = null),
    (_0x3ec8d6[_0x1ff4cf(0x1fb)] = null),
    (_0x2c6251[_0x1ff4cf(0x25e)] = new Date()[_0x1ff4cf(0x27e)]()),
    (_0x2c6251["cd"] = ensureDate(_0x3adb34)[_0x1ff4cf(0x27e)]()),
    (_0x2c6251["c"] = _0x4f9536),
    (_0x2c6251[_0x1ff4cf(0x248)] = "FILLED"),
    (_0x2c6251[_0x1ff4cf(0x242)] = _0x2c6251[_0x1ff4cf(0x27c)]),
    (_0x2c6251[_0x1ff4cf(0x274)] = _0x2c6251[_0x1ff4cf(0x27c)]
      ? _0x2c6251["sizeInBase"] * _0x4f9536
      : null),
    (_0x2c6251["sellComm"] = {
      bnb: 0x0,
      base:
        (_0x2c416a / 0x64) * _0x2c6251[_0x1ff4cf(0x274)]
          ? _0x2c6251[_0x1ff4cf(0x274)]
          : 0x0,
    }),
    (_0x2c6251[_0x1ff4cf(0x231)] =
      ((_0x2c6251["c"] - _0x2c6251["o"]) / _0x2c6251["o"] -
        (_0x2c416a / 0x64) * 0x2) *
      0x64),
    (_0x2c6251[_0x1ff4cf(0x24a)] = _0x2c6251[_0x1ff4cf(0x226)]
      ? _0x2c6251[_0x1ff4cf(0x226)] * (_0x2c416a / 0x64) +
        _0x2c6251[_0x1ff4cf(0x274)] * (_0x2c416a / 0x64)
      : null),
    (_0x2c6251[_0x1ff4cf(0x200)] = _0x2c6251[_0x1ff4cf(0x226)]
      ? _0x2c6251[_0x1ff4cf(0x226)] * (_0x2c6251[_0x1ff4cf(0x231)] / 0x64)
      : null);
}
function calculateBidAskSpread(_0x2338ab) {
  const _0x1614d4 = a0_0x29779a;
  if (_0x2338ab["length"] === 0x0) return 0x0;
  let _0x8846a8 = _0x2338ab[0x0][_0x1614d4(0x20d)][0x0],
    _0x6ecba3 = 0x0,
    _0x507314 = 0x0,
    _0x67a7dc = 0x0,
    _0x590245 = 0x0;
  for (
    let _0x596fc6 = 0x0;
    _0x596fc6 < _0x2338ab[_0x1614d4(0x222)];
    _0x596fc6++
  ) {
    for (
      let _0x37b521 = 0x0;
      _0x37b521 < _0x2338ab[_0x596fc6][_0x1614d4(0x20d)][_0x1614d4(0x222)];
      _0x37b521++
    ) {
      let _0x24a6d7 = _0x2338ab[_0x596fc6][_0x1614d4(0x20d)][_0x37b521];
      _0x24a6d7["v1"] && ((_0x67a7dc += _0x24a6d7["v1"]), _0x590245++),
        _0x8846a8 &&
          _0x24a6d7 &&
          (_0x8846a8["c"] > _0x24a6d7["o1"] ||
            _0x8846a8["c"] < _0x24a6d7["o1"]) &&
          ((_0x6ecba3 +=
            Math[_0x1614d4(0x260)](_0x8846a8["c"] - _0x24a6d7["o1"]) /
            _0x24a6d7["o1"]),
          _0x507314++),
        (_0x8846a8 = _0x24a6d7);
    }
  }
  let _0x59a9f8 = _0x507314 > 0x0 ? _0x6ecba3 / _0x507314 / 0x2 : 0x0;
  _0x59a9f8 = Math[_0x1614d4(0x281)](
    Math[_0x1614d4(0x255)](_0x59a9f8, 0.00005),
    0.0005
  );
  let _0x4681e3 = _0x590245 > 0x0 ? _0x67a7dc / _0x590245 : 0x0;
  return [_0x59a9f8, _0x4681e3];
}
async function backtest(
  _0x59b2a9,
  _0x35516e,
  _0x298241,
  _0x18f451,
  _0x1fbedf,
  _0x27f059,
  _0x4c06e7,
  _0x29da73,
  _0x3a05ae,
  _0x3b21a3
) {
  const _0x436820 = a0_0x29779a;
  try {
    const _0x196f9f = {
        nextTrade: _0x436820(0x285),
        candleAction: null,
        stoploss: null,
        target: null,
        trailingTarget: null,
        trailingTargetPriceUsed: null,
        timeClose: null,
        trades: [],
      },
      _0x376ede = getTimeframes(_0x59b2a9),
      _0x36b7a9 = _0x376ede[0x0],
      _0x52c200 = _0x376ede[_0x436820(0x222)] > 0x1 ? _0x376ede[0x1] : null,
      _0x124857 = _0x35516e[_0x36b7a9],
      _0x4fab6c = _0x52c200 ? _0x35516e[_0x52c200] : null;
    let _0x20dbdf = 0x0,
      _0x1f4fe1 = null,
      _0x53e32f = {};
    Object[_0x436820(0x21e)](_0x18f451)["forEach"]((_0x317634) => {
      _0x53e32f[_0x317634] = { index: 0x0, subIndex: 0x0 };
    });
    let _0x29b5fe = 0x0,
      _0x196487 = 0x0,
      _0x50229d = 0x0,
      _0x3dc2ab = _0x59b2a9[_0x436820(0x239)][_0x436820(0x26d)](
        (_0x34db93, _0x1f9bd1) => {
          const _0x1de562 = _0x436820;
          return _0x34db93 || _0x1f9bd1[_0x1de562(0x240)] === _0x1de562(0x24f);
        },
        ![]
      ),
      _0x37ba72 = _0x59b2a9[_0x436820(0x22f)][_0x436820(0x26d)](
        (_0x585061, _0x2180ae) => {
          const _0x3c8991 = _0x436820;
          return _0x585061 || _0x2180ae["mode"] === _0x3c8991(0x24f);
        },
        ![]
      );
    return (
      _0x124857["forEach"]((_0x4e2645, _0xd2b367) => {
        const _0x43aa83 = _0x436820;
        if (ensureDate(_0x1fbedf) > ensureDate(_0x4e2645["cd"])) return;
        if (_0x3b21a3) {
          let _0x153115 =
            (_0xd2b367 - _0x29b5fe) / (_0x124857[_0x43aa83(0x222)] - _0x29b5fe);
          _0x196487 + 0.01 < _0x153115 &&
            ((_0x196487 = _0x153115), _0x3b21a3(0x64 * _0x196487));
        }
        if (_0x4fab6c) {
          if (
            !(
              _0x1f4fe1 &&
              ensureDate(_0x1f4fe1["d"]) <= ensureDate(_0x4e2645["d"]) &&
              ensureDate(_0x1f4fe1["cd"]) >= ensureDate(_0x4e2645["cd"])
            )
          )
            for (
              let _0x5e3b37 = _0x20dbdf;
              _0x5e3b37 < _0x4fab6c[_0x43aa83(0x222)];
              _0x5e3b37++
            ) {
              const _0x20577d = _0x4fab6c[_0x5e3b37];
              if (
                ensureDate(_0x20577d["d"]) <= ensureDate(_0x4e2645["d"]) &&
                ensureDate(_0x20577d["cd"]) >= ensureDate(_0x4e2645["cd"])
              ) {
                (_0x20dbdf = _0x5e3b37),
                  (_0x1f4fe1 = _0x20577d),
                  (_0x50229d = 0x0);
                break;
              }
              _0x1f4fe1 = null;
              if (ensureDate(_0x20577d["d"]) > ensureDate(_0x4e2645["d"]))
                break;
            }
          if (!_0x1f4fe1) return;
        }
        for (
          let _0x301705 = 0x0;
          _0x301705 < _0x4e2645["subData"]["length"];
          _0x301705++
        ) {
          let _0xf9a4a4 = _0x301705 === 0x0,
            _0x3a9c0c = _0x4e2645["subData"][_0x301705];
          if (ensureDate(_0x1fbedf) > ensureDate(_0x3a9c0c["d"])) continue;
          updatePriceAction(_0x53e32f, _0x3a9c0c);
          let _0x39c7b0 = {};
          _0x39c7b0[_0x36b7a9] = {
            candle1m: _0x3a9c0c,
            data: _0x124857,
            index: _0xd2b367,
          };
          if (_0x1f4fe1) {
            let _0x1a62a1 = null;
            for (
              let _0x9c9df0 = _0x50229d;
              _0x9c9df0 < _0x1f4fe1["subData"]["length"];
              _0x9c9df0++
            ) {
              (_0x1a62a1 = _0x1f4fe1[_0x43aa83(0x20d)][_0x9c9df0]),
                (_0x50229d = _0x9c9df0 + 0x1);
              if (ensureDate(_0x1a62a1["d"]) > ensureDate(_0x3a9c0c["d"])) {
                _0x1a62a1 = null;
                break;
              }
              if (
                ensureDate(_0x1a62a1["d"])[_0x43aa83(0x27e)]() ===
                ensureDate(_0x3a9c0c["d"])[_0x43aa83(0x27e)]()
              )
                break;
              _0x1a62a1 = null;
            }
            _0x39c7b0[_0x52c200] = {
              candle1m: _0x1a62a1,
              data: _0x4fab6c,
              index: _0x20dbdf,
            };
          }
          if (_0x196f9f[_0x43aa83(0x205)] === _0x43aa83(0x1f4)) {
            let [_0x121181, _0x5c0d7a] = checkStoplossAndTarget(
                _0x196f9f,
                _0x3a9c0c,
                _0x59b2a9
              ),
              _0x19ebf5 = [];
            if (_0x121181) {
              sellOrder(
                _0x196f9f,
                _0x121181["p"],
                _0x121181["d"],
                _0x27f059,
                _0x53e32f,
                _0x29da73,
                _0x3a05ae,
                _0x3a9c0c,
                _0x59b2a9[_0x43aa83(0x259)],
                _0x5c0d7a
              );
              continue;
            } else {
              if (
                (_0x37ba72 || _0xf9a4a4) &&
                checkTradingRules(
                  _0x59b2a9["sellRules"],
                  _0x39c7b0,
                  _0x18f451,
                  _0x53e32f,
                  _0x19ebf5
                )
              ) {
                let _0x32817b = _0x37ba72
                  ? _0x19ebf5[_0x43aa83(0x222)] > 0x0
                    ? _0x19ebf5[_0x43aa83(0x26d)](
                        (_0x566ef7, _0x5c16c5) => _0x566ef7 + _0x5c16c5,
                        0x0
                      ) / _0x19ebf5[_0x43aa83(0x222)]
                    : _0x3a9c0c["c"]
                  : _0x3a9c0c["o"];
                sellOrder(
                  _0x196f9f,
                  _0x32817b,
                  _0x3a9c0c["d"],
                  _0x27f059,
                  _0x53e32f,
                  _0x29da73,
                  _0x3a05ae,
                  _0x3a9c0c,
                  _0x59b2a9[_0x43aa83(0x259)],
                  "rule"
                );
                continue;
              }
            }
          }
          if (_0x196f9f[_0x43aa83(0x205)] === "buy") {
            let _0x197cbc =
              _0x196f9f["trades"][_0x43aa83(0x222)] > 0x0
                ? _0x196f9f["trades"][
                    _0x196f9f["trades"][_0x43aa83(0x222)] - 0x1
                  ]
                : null;
            if (_0x197cbc && _0x197cbc["cd"]) {
              let _0x21db54 = new Date(_0x197cbc["cd"]);
              if (
                _0x21db54 >= ensureDate(_0x4e2645["d"]) &&
                _0x21db54 < ensureDate(_0x4e2645["cd"])
              )
                continue;
            }
            let _0x131c96 = [];
            if (_0x59b2a9[_0x43aa83(0x26a)]) {
              let _0x291ed1 =
                _0x196f9f[_0x43aa83(0x203)][_0x43aa83(0x222)] > 0x0
                  ? _0x196f9f[_0x43aa83(0x203)][
                      _0x196f9f[_0x43aa83(0x203)][_0x43aa83(0x222)] - 0x1
                    ]
                  : null;
              if (_0x291ed1 && _0x291ed1[_0x43aa83(0x231)] < 0x0) {
                let _0x1799a4 = new Date(_0x291ed1["cd"]);
                _0x1799a4[_0x43aa83(0x261)](
                  _0x1799a4[_0x43aa83(0x244)]() + _0x59b2a9[_0x43aa83(0x26a)]
                );
                if (_0x1799a4 >= ensureDate(_0x3a9c0c["cd"])) continue;
              }
            }
            if (_0x59b2a9[_0x43aa83(0x228)]) {
              let _0x1a2a46 =
                _0x196f9f[_0x43aa83(0x203)][_0x43aa83(0x222)] > 0x0
                  ? _0x196f9f[_0x43aa83(0x203)][
                      _0x196f9f[_0x43aa83(0x203)][_0x43aa83(0x222)] - 0x1
                    ]
                  : null;
              if (_0x1a2a46 && _0x1a2a46[_0x43aa83(0x231)] > 0x0) {
                let _0x1bd2b9 = new Date(_0x1a2a46["cd"]);
                _0x1bd2b9["setMinutes"](
                  _0x1bd2b9[_0x43aa83(0x244)]() + _0x59b2a9[_0x43aa83(0x228)]
                );
                if (_0x1bd2b9 >= ensureDate(_0x3a9c0c["cd"])) continue;
              }
            }
            if (
              (_0x3dc2ab || _0xf9a4a4) &&
              checkTradingRules(
                _0x59b2a9[_0x43aa83(0x239)],
                _0x39c7b0,
                _0x18f451,
                _0x53e32f,
                _0x131c96
              )
            ) {
              let _0x8f326 = _0x3dc2ab
                ? _0x131c96[_0x43aa83(0x222)] > 0x0
                  ? _0x131c96[_0x43aa83(0x26d)](
                      (_0x1b7756, _0x433b46) => _0x1b7756 + _0x433b46,
                      0x0
                    ) / _0x131c96[_0x43aa83(0x222)]
                  : _0x3a9c0c["c"]
                : _0x3a9c0c["o"];
              if (_0x59b2a9[_0x43aa83(0x28b)]) {
                (_0x196f9f[_0x43aa83(0x205)] = _0x43aa83(0x28b)),
                  (_0x196f9f[_0x43aa83(0x247)] = _0x8f326),
                  (_0x196f9f[_0x43aa83(0x22e)] = new Date(
                    ensureDate(_0x3a9c0c["cd"])["getTime"]()
                  )),
                  _0x196f9f["tbd"][_0x43aa83(0x261)](
                    _0x196f9f["tbd"]["getMinutes"]() + _0x59b2a9["tBuyWait"],
                    _0x196f9f[_0x43aa83(0x22e)][_0x43aa83(0x282)]()
                  );
                let _0x3f8374 = roundQuotedValue(
                  _0x196f9f[_0x43aa83(0x247)] *
                    (0x1 + _0x59b2a9[_0x43aa83(0x28b)] / 0x64),
                  _0x59b2a9["pairDetails"]
                );
                _0x3f8374 <= _0x3a9c0c["c"] &&
                  buyOrder(
                    _0x196f9f,
                    _0x3f8374,
                    _0x3a9c0c["d"],
                    _0x4c06e7,
                    _0x27f059,
                    _0x59b2a9,
                    _0x29da73,
                    _0x3a05ae,
                    _0x3a9c0c,
                    _0x59b2a9[_0x43aa83(0x259)]
                  );
                continue;
              } else {
                buyOrder(
                  _0x196f9f,
                  _0x8f326,
                  _0x3a9c0c["d"],
                  _0x4c06e7,
                  _0x27f059,
                  _0x59b2a9,
                  _0x29da73,
                  _0x3a05ae,
                  _0x3a9c0c,
                  _0x59b2a9[_0x43aa83(0x259)]
                );
                continue;
              }
            }
          }
          if (_0x196f9f[_0x43aa83(0x205)] === _0x43aa83(0x28b)) {
            if (_0x196f9f["tbd"] > ensureDate(_0x3a9c0c["d"])) {
              let _0x1ff2b0 = ![];
              _0x196f9f[_0x43aa83(0x247)] > _0x3a9c0c["o1"] &&
                (_0x196f9f[_0x43aa83(0x247)] = _0x3a9c0c["o1"]);
              let _0x37f41b = roundQuotedValue(
                _0x196f9f[_0x43aa83(0x247)] *
                  (0x1 + _0x59b2a9[_0x43aa83(0x28b)] / 0x64),
                _0x59b2a9[_0x43aa83(0x259)]
              );
              _0x37f41b <= _0x3a9c0c["h1"]
                ? (_0x37f41b < _0x3a9c0c["o1"] && (_0x37f41b = _0x3a9c0c["o1"]),
                  (_0x1ff2b0 = !![]))
                : _0x196f9f[_0x43aa83(0x247)] > _0x3a9c0c["l1"] &&
                  ((_0x196f9f[_0x43aa83(0x247)] = _0x3a9c0c["l1"]),
                  (_0x37f41b = roundQuotedValue(
                    _0x196f9f[_0x43aa83(0x247)] *
                      (0x1 + _0x59b2a9[_0x43aa83(0x28b)] / 0x64),
                    _0x59b2a9[_0x43aa83(0x259)]
                  )),
                  _0x37f41b <= _0x3a9c0c["c"] && (_0x1ff2b0 = !![]));
              if (_0x1ff2b0) {
                buyOrder(
                  _0x196f9f,
                  _0x37f41b,
                  _0x3a9c0c["d"],
                  _0x4c06e7,
                  _0x27f059,
                  _0x59b2a9,
                  _0x29da73,
                  _0x3a05ae,
                  _0x3a9c0c,
                  _0x59b2a9[_0x43aa83(0x259)]
                );
                let [_0xd95e8a, _0x4e26e1] = checkStoplossAndTarget(
                  _0x196f9f,
                  {
                    d: _0x3a9c0c["cd"],
                    cd: _0x3a9c0c["cd"],
                    o: _0x37f41b,
                    o1: _0x37f41b,
                    h: _0x3a9c0c["c"],
                    h1: _0x3a9c0c["c"],
                    l: _0x3a9c0c["c"],
                    l1: _0x3a9c0c["c"],
                    c: _0x3a9c0c["c"],
                  },
                  _0x59b2a9
                );
                _0xd95e8a &&
                  sellOrder(
                    _0x196f9f,
                    _0xd95e8a["p"],
                    _0xd95e8a["d"],
                    _0x27f059,
                    _0x53e32f,
                    _0x29da73,
                    _0x3a05ae,
                    _0x3a9c0c,
                    _0x59b2a9[_0x43aa83(0x259)],
                    _0x4e26e1
                  );
              }
            } else
              (_0x196f9f[_0x43aa83(0x205)] = _0x43aa83(0x285)),
                delete _0x196f9f[_0x43aa83(0x247)],
                delete _0x196f9f[_0x43aa83(0x22e)];
          }
        }
      }),
      _0x196f9f[_0x436820(0x203)]
    );
  } catch (_0x3f4b85) {
    return (
      console["log"](
        _0x436820(0x277) + _0x3f4b85 + "\x20" + _0x3f4b85[_0x436820(0x1ec)]
      ),
      []
    );
  }
}
function marketReturn(_0x2c58ac, _0x48b393) {
  const _0x5b40d8 = a0_0x29779a;
  let _0x9fcec0 = _0x48b393["find"](
      (_0x3fed0f) => ensureDate(_0x3fed0f["cd"]) >= ensureDate(_0x2c58ac)
    ),
    _0x1376d1 = _0x48b393[_0x48b393[_0x5b40d8(0x222)] - 0x1];
  if (!_0x9fcec0 || !_0x1376d1) return 0x0;
  return ((_0x1376d1["c"] - _0x9fcec0["o"]) / _0x9fcec0["o"]) * 0x64;
}
function attachOneMinuteSubData(_0x4eb34e) {
  const _0x46cd79 = a0_0x29779a;
  let _0x1d99cd = _0x4eb34e[_0x46cd79(0x234)];
  if (!_0x1d99cd) return;
  Object[_0x46cd79(0x21e)](_0x4eb34e)["forEach"]((_0x23f40a, _0x17546d) => {
    const _0x2ea4c8 = _0x46cd79;
    if (_0x23f40a === _0x2ea4c8(0x234) && _0x17546d > 0x0) return;
    let _0x5c9bbc = _0x4eb34e[_0x23f40a],
      _0x38f1f1 = 0x0;
    _0x5c9bbc[_0x2ea4c8(0x1f8)]((_0x5e3e4a) => {
      const _0x388555 = _0x2ea4c8;
      if (_0x23f40a === _0x388555(0x234) && _0x17546d === 0x0) {
        _0x5e3e4a[_0x388555(0x20d)] = [
          {
            d: _0x5e3e4a["d"],
            o: _0x5e3e4a["o"],
            h: _0x5e3e4a["h"],
            l: _0x5e3e4a["l"],
            o1: _0x5e3e4a["o"],
            h1: _0x5e3e4a["h"],
            l1: _0x5e3e4a["l"],
            v1: _0x5e3e4a["v"],
            c: _0x5e3e4a["c"],
            cd: _0x5e3e4a["cd"],
            v: _0x5e3e4a["v"],
            tn: _0x5e3e4a["tn"],
          },
        ];
        return;
      }
      let _0x20de47 = [];
      for (
        let _0x1b7343 = _0x38f1f1;
        _0x1b7343 < _0x1d99cd["length"];
        _0x1b7343++
      ) {
        let _0x432545 = _0x1d99cd[_0x1b7343];
        if (ensureDate(_0x432545["d"]) < ensureDate(_0x5e3e4a["d"])) {
          _0x38f1f1 = _0x1b7343;
          continue;
        }
        if (
          ensureDate(_0x432545["d"])[_0x388555(0x27e)]() ===
          ensureDate(_0x5e3e4a["d"])[_0x388555(0x27e)]()
        ) {
          _0x20de47[_0x388555(0x207)]({
            ..._0x432545,
            o1: _0x432545["o"],
            h1: _0x432545["h"],
            l1: _0x432545["l"],
            v1: _0x432545["v"],
          }),
            (_0x38f1f1 = _0x1b7343);
          continue;
        }
        let _0x50ec4c =
          _0x20de47["length"] > 0x0
            ? _0x20de47[_0x20de47[_0x388555(0x222)] - 0x1]
            : null;
        if (!_0x50ec4c) break;
        _0x38f1f1 = _0x1b7343;
        if (ensureDate(_0x432545["cd"]) <= ensureDate(_0x5e3e4a["cd"]))
          _0x20de47[_0x388555(0x207)]({
            d: _0x432545["d"],
            o: _0x50ec4c["o"],
            h:
              _0x50ec4c["h"] > _0x432545["h"] ? _0x50ec4c["h"] : _0x432545["h"],
            l:
              _0x50ec4c["l"] < _0x432545["l"] ? _0x50ec4c["l"] : _0x432545["l"],
            o1: _0x432545["o"],
            h1: _0x432545["h"],
            l1: _0x432545["l"],
            v1: _0x432545["v"],
            c: _0x432545["c"],
            cd: _0x432545["cd"],
            v: _0x50ec4c["v"] + _0x432545["v"],
            tn: _0x50ec4c["tn"] + _0x432545["tn"],
          });
        else break;
      }
      _0x5e3e4a[_0x388555(0x20d)] = _0x20de47;
    });
  });
}
export {
  parseBacktestResult,
  marketReturn,
  addIndicatorsData,
  ensureDate,
  getTimeframes,
  getIndicatorId,
};
