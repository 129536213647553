import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import loading from "./assets/images/loading-reverced.gif";
import { globalContext } from "./Context.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "./Dropdown.js";
import { faPlus, faCompressAlt, faExpandArrowsAlt } from "@fortawesome/free-solid-svg-icons";

import { getUserData, getFromLocalStorage } from "./utils.js";
import $ from "jquery";
import { ExecutionsTable, ExecutionsCharts, TradingSetup, TradingResult } from "./Trading";

const StrategiesTradingTable = (props) => {
  const { state, dispatch } = useContext(globalContext);
  const [dropdownHeightSubtract] = useState(118);
  return (
    <>
      {props.showGroupsDropdown && (
        <Dropdown
          btnClasses="d-flex text-light hover-border-bottom info pl-0 pb-2 mb-2 w-250"
          arrow
          arrowClasses="mt-1"
          selectedClasses="text-truncate max-w-450 mr-1"
          menuClasses={`bg-new-dark border border-secondary rounded small w-250 py-1 ${
            props.panelHeight ? "" : "max-h-250"
          }`}
          menuItemsClasses="text-light hover-info px-3 py-1"
          menuStyle={props.panelHeight ? { maxHeight: Math.max(100, props.panelHeight - dropdownHeightSubtract) } : {}}
          selected={props.activeGroup ? props.activeGroup.name : state.strategyGroups[0].name}
          setSelected={(group) => {
            props.setActiveGroupIdCallback(group.id);
          }}
          items={state.strategyGroups.filter((gr) => !gr.isDefault)}
          additionalOptions={[
            {
              label: state.strategyGroups[1].name,
              classes: `text-light hover-info px-3 pt-1 pb-2 ${
                state.strategyGroups.length > 4 ? "border-bottom border-secondary mb-1" : ""
              }`,
              fn: () => {
                props.setActiveGroupIdCallback(state.strategyGroups[1].id);
              },
            },
          ]}
        />
      )}

      {state.strategies.length > 0 && (
        <>
          <ul className="nav nav-tabs hover-white user-select-none mb-3" role="tablist">
            <li className="nav-item">
              <div
                className={`nav-link text-info hover-light cursor-pointer ${
                  props.tradingActiveSubTab === "simulation" ? "active" : "hover-noborder"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  props.setTradingActiveSubTabCallback("simulation");
                }}
              >
                Simulations
              </div>
            </li>
            <li className="nav-item">
              <div
                className={`nav-link text-info hover-light cursor-pointer ${
                  props.tradingActiveSubTab === "trading" ? "active" : "hover-noborder"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  props.setTradingActiveSubTabCallback("trading");
                }}
              >
                Trade Live
              </div>
            </li>
            {props.setPanelExpanded && (
              <li className="ml-auto">
                <a
                  className="text-light-green"
                  href="/#"
                  title={props.panelExpanded ? "Collapse" : "Expand"}
                  onClick={(e) => {
                    e.preventDefault();
                    document.activeElement.blur();
                    props.setPanelExpanded(!props.panelExpanded);
                  }}
                >
                  {props.panelExpanded ? (
                    <FontAwesomeIcon className="small fade-in" icon={faCompressAlt} />
                  ) : (
                    <FontAwesomeIcon className="small fade-in" icon={faExpandArrowsAlt} />
                  )}
                </a>
              </li>
            )}
          </ul>
          <div className="">
            {props.tradingActiveSubTab === "simulation" ? (
              <ExecutionsTable
                selectedStrategyId={props.selectedStrategyId}
                setSelectedStrategyId={props.setSelectedStrategyIdCallback}
                setStrategyActiveSubTab={props.setStrategyActiveSubTabCallback}
                setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
                activeGroup={props.activeGroup}
                setActiveGroupIdCallback={props.setActiveGroupIdCallback}
                type={"simulation"}
                panelHeight={props.panelHeight}
                smallWidthDevice={props.smallWidthDevice}
                resultInQuote={true}
              />
            ) : (
              <ExecutionsTable
                selectedStrategyId={props.selectedStrategyId}
                setSelectedStrategyId={props.setSelectedStrategyIdCallback}
                setStrategyActiveSubTab={props.setStrategyActiveSubTabCallback}
                setStrategyActiveSubTabResult={props.setStrategyActiveSubTabResult}
                activeGroup={props.activeGroup}
                setActiveGroupIdCallback={props.setActiveGroupIdCallback}
                type={"trading"}
                panelHeight={props.panelHeight}
                smallWidthDevice={props.smallWidthDevice}
                resultInQuote={true}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

const BotTradingSaver = (props) => {
  const { state, dispatch } = useContext(globalContext);
  const [activeGroupId, setActiveGroupId] = useState(getFromLocalStorage("activeGroupId"), "0_00");
  const [activeGroup, setActiveGroup] = useState(null);
  const [tradingActiveSubTab, setTradingActiveSubTab] = useState(getFromLocalStorage("tradingSubTab", "trading"));
  const [initialized, setInitialized] = useState(false);
  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    //getUserData(state.user, dispatch, state.user.token, state.app);
  }, []);

  useEffect(() => {
    if (state.strategyGroupsInitialized) {
      let gr = state.strategyGroups.find((gr) => gr.id === activeGroupId);
      if (gr) {
        setActiveGroup({ ...gr });
      } else {
        setActiveGroup({ ...state.strategyGroups[0] });
      }
    }
  }, [activeGroupId, state.strategyGroups, state.strategyGroupsInitialized]);

  const setTradingActiveSubTabCallback = useCallback((page) => {
    localStorage.setItem("tradingSubTab", page);
    setTradingActiveSubTab(page);
  }, []);

  const emptyFn = useCallback(() => {}, []);

  const setActiveGroupIdCallback = useCallback(
    (newId) => {
      let gr = state.strategyGroups.find((gr) => gr.id === newId);
      setActiveGroupId(newId);
      setActiveGroup({ ...gr });
      localStorage.setItem("activeGroupId", newId);
    },
    [state.strategyGroups]
  );

  return (
    <div className="page position-relative text-left fade-in pt-4 px-3 px-md-5 ">
      <div id="botTradingPage">
        {initialized ? (
          <StrategiesTradingTable
            tradingActiveSubTab={tradingActiveSubTab}
            setTradingActiveSubTabCallback={setTradingActiveSubTabCallback}
            setStrategyActiveSubTabCallback={emptyFn}
            selectedStrategyId={null}
            setSelectedStrategyIdCallback={emptyFn}
            setStrategyActiveSubTabResult={emptyFn}
            activeGroup={activeGroup}
            setActiveGroupIdCallback={setActiveGroupIdCallback}
            smallWidthDevice={true}
            showGroupsDropdown
          />
        ) : (
          <div className="text-center mx-auto mt-5">
            <div className="loading-img cursor-help my-5" title="Loading..">
              <img src={loading} alt="" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BotTradingSaver;
