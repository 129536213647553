import { faChartLine, faCheck, faRandom, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useContext, useState, useRef } from "react";
import { globalContext } from "./Context.js";
import $ from "jquery";
import loading from "./assets/images/loading-reverced.gif";
import { formatDateTime, getContestWinners, loadContestRanking } from "./utils.js";
import getExchange from "./Exchange.js";
import { Modal } from "./Modal.js";
import { TradingResult } from "./Trading.js";

// const ContestRankingTab = (props) => {
//   return "";
// };

const ExecutionResults = (props) => {
  let { state } = useContext(globalContext);
  const [status, setStatus] = useState(null);
  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.execution) {
      setStatus(props.execution.status);
    } else {
      setStatus(null);
    }
  }, [props.execution]);

  useEffect(() => {
    if (status) {
      if (!$("#executionContestResultModal").is(":visible")) {
        $("#executionContestResultModal").modal("toggle");
      }
    } else {
      if ($("#executionContestResultModal").is(":visible")) {
        $("#executionContestResultModal").modal("toggle");
      }
    }
  }, [status]);

  return (
    <Modal
      id="executionContestResultModal"
      okText="Close"
      closeFn={() => {
        props.closeFn();
      }}
      header={
        <>
          <FontAwesomeIcon icon={faRandom} /> Contest Result
          <div>{props.execution.strategy.name}</div>
        </>
      }
      content={
        status && (
          <div className="px-3">
            <TradingResult
              contestMonth={state.selectedContest.month}
              bugReport
              strategy={props.execution.strategy}
              type={"simulation"}
              setStrategyActiveSubTabResult={() => {}}
              topPanelHeight={700}
              inspectStrategy={props.execution.strategy.showStrategy}
              status={props.execution.status}
              trades={props.execution.trades}
              smallWidthDevice={props.smallWidthDevice}
            />
          </div>
        )
      }
      large={true}
      dark={true}
    />
  );
};
const ContestRankingTab = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const [pages, setPages] = useState([1]);
  const [strategyToShow, setStrategyToShow] = useState(null);
  const [pricesBinance, setPricesBinance] = useState({});
  const [smallWidthDevice, setSmallWidthDevice] = useState(false);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const sendSizeChangeDiv = (e) => {
      let root = document.getElementById("root");
      if (root) {
        if (smallWidthDevice && +root.clientWidth >= 768) {
          setSmallWidthDevice(false);
        }
        if (!smallWidthDevice && +root.clientWidth < 768) {
          setSmallWidthDevice(true);
        }
      }
    };
    sendSizeChangeDiv();

    window.addEventListener("resize", sendSizeChangeDiv);
    return () => {
      window.removeEventListener("resize", sendSizeChangeDiv);
    };
  }, [smallWidthDevice]);

  useEffect(() => {
    let pages = [];
    for (let i = 0; i < Math.ceil(state.selectedContest.total / 10); i++) {
      pages.push(i);
    }
    setPages(pages);
  }, [state.selectedContest.total]);

  useEffect(() => {
    if (state.selectedContest.ongoing) {
      getExchange("Binance", true, state.app, state.demo).subscribeToPricesUpdate("CONTEST", (prices, pricesStats) => {
        if (componentIsMounted.current) {
          setPricesBinance({
            prices: prices,
            pricesStats: pricesStats,
          });
        }
      });
      return () => {
        getExchange("Binance").unsubscribeToPricesUpdate("CONTEST");
      };
    }
  }, []);

  useEffect(() => {
    if (state.selectedContest.month) {
      loadContestRanking(state.selectedContest.month, state.selectedContest.page, dispatch);
    }
  }, [state.selectedContest.month, state.selectedContest.page]);

  useEffect(() => {
    if (state.selectedContest.month && !state.selectedContest.ongoing && !state.selectedContest.winners) {
      getContestWinners(state.selectedContest.month, dispatch);
    }
  }, [state.selectedContest.month]);

  return (
    <div className="my-4 mx-4">
      {strategyToShow && (
        <ExecutionResults
          execution={strategyToShow}
          smallWidthDevice={smallWidthDevice}
          type={props.type}
          closeFn={() => {
            setStrategyToShow(null);
          }}
        />
      )}
      {state.contests.length > 0 ? (
        <div className="d-block d-lg-flex">
          {!state.selectedContest.ongoing && (
            <div className="col-12 col-lg-4 col-xxl-3 border-right-lg border-info">
              <h2 className="h3 mb-2">Winners:</h2>
              <h3 className="h5 mb-4">{state.selectedContest.month}</h3>
              <div className="table-responsive border border-dark rounded mb-5 mb-lg-0">
                {state.selectedContest.winners ? (
                  <table className="table table-light table-hover small user-select-none">
                    <thead className="bg-light-green">
                      <tr className="">
                        <th className="text-left border-top-0">#</th>
                        <th className="text-left border-top-0">User</th>
                        {/* <th className="text-left d-none d-md-table-cell">
                          Strategy
                        </th> */}
                        <th className="text-left text-nowrap border-top-0">Result %</th>
                        <th className="text-center border-top-0 pr-3"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.selectedContest.winners.map((execution, index) => {
                        return (
                          <tr key={execution.strategy.id}>
                            <td className="text-left">{index + 1 + state.selectedContest.page * 10}</td>
                            <td className="text-left">{execution.strategy.nickname}</td>
                            {/* <td className="text-left d-none d-md-table-cell">
                              {execution.strategy.name}
                            </td> */}
                            <td
                              className={`text-left ${
                                execution.status.result > 0
                                  ? "text-success"
                                  : execution.status.result < 0
                                  ? "text-danger"
                                  : ""
                              }`}
                            >
                              {execution.status.result.toFixed(2) + "%"}
                            </td>
                            <td className="text-center pr-3">
                              <a
                                href="#/"
                                className="text-info pr-2 "
                                title="Show Result"
                                onClick={(e) => {
                                  e.preventDefault();
                                  document.activeElement.blur();
                                  setStrategyToShow(execution);
                                }}
                              >
                                <FontAwesomeIcon icon={faChartLine} />
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div className="text-center mx-auto">
                    <div className="loading-img cursor-help my-5" title="Loading..">
                      <img src={loading} alt="" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="col flex-grow-1">
            <h2 className="h3 mb-2">
              {!state.selectedContest.ongoing ? "Final ranking " : "Ranking "}
              {state.selectedContest.start} - {state.selectedContest.end}{" "}
              {state.selectedContest.month.replace("-", " ")}:
            </h2>
            <h3 className="h5 mb-4">Pair: {state.selectedContest.pair}</h3>
            <div className="table-responsive border border-dark rounded mb-2">
              {state.selectedContest.executions ? (
                <table
                  className={`table table-light table-hover user-select-none ${
                    state.selectedContest.ongoing ? "table-striped" : "small"
                  } ${state.selectedContest.loading ? "opacity-6" : ""}`}
                >
                  <thead className="bg-info">
                    <tr>
                      <th className="text-left border-top-0">#</th>
                      <th className="text-left border-top-0">Strategy</th>
                      <th className="text-left d-none d-md-table-cell border-top-0">User</th>
                      {state.selectedContest.ongoing && (
                        <th className="text-center text-nowrap d-none d-md-table-cell border-top-0">Open Trade</th>
                      )}
                      <th className="text-center text-nowrap d-none d-md-table-cell border-top-0">Trades</th>
                      <th className="text-left text-nowrap border-top-0">Result %</th>
                      <th className="text-center pr-3 border-top-0">Details</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {state.selectedContest.executions.map((execution, index) => {
                      let openTrade = "";
                      if (execution.status.activeTrade && execution.trades.length > 0) {
                        if (
                          pricesBinance &&
                          pricesBinance.pricesStats &&
                          pricesBinance.pricesStats[execution.strategy.pair]
                        ) {
                          let percent =
                            ((pricesBinance.pricesStats[execution.strategy.pair].bid -
                              execution.trades[execution.trades.length - 1].o) /
                              execution.trades[execution.trades.length - 1].o) *
                              100 -
                            (execution.commissionRate ? execution.commissionRate * 2 : 0);
                          openTrade = percent && !isNaN(percent) && (
                            <>
                              <span className={percent > 0 ? "text-success" : percent < 0 ? "text-danger" : ""}>
                                &nbsp;{percent.toFixed(2)}%
                              </span>
                            </>
                          );
                        }
                      }
                      return (
                        <tr key={execution.strategy.id}>
                          <td className="text-left">{index + 1 + state.selectedContest.page * 10}</td>
                          <td className="text-left">{execution.strategy.name}</td>
                          <td className="text-left d-none d-md-table-cell">{execution.strategy.nickname}</td>
                          {state.selectedContest.ongoing && (
                            <td className="text-center d-none d-md-table-cell">
                              {execution.status.activeTrade && openTrade === "" ? (
                                <FontAwesomeIcon className="text-info" icon={faCheck} />
                              ) : (
                                ""
                              )}
                              {openTrade}
                            </td>
                          )}
                          <td className="text-center d-none d-md-table-cell">{execution.status.tradesCount}</td>
                          <td
                            className={`text-left ${
                              execution.status.result > 0
                                ? "text-success"
                                : execution.status.result < 0
                                ? "text-danger"
                                : ""
                            }`}
                          >
                            {execution.status.result.toFixed(2) + "%"}
                          </td>
                          <td className="text-center pr-3">
                            <a
                              href="#/"
                              className="text-info pr-2 "
                              title="Show Result"
                              onClick={(e) => {
                                e.preventDefault();
                                document.activeElement.blur();
                                setStrategyToShow(execution);
                              }}
                            >
                              <FontAwesomeIcon icon={faChartLine} />
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div className="text-center mx-auto">
                  <div className="loading-img cursor-help my-5" title="Loading..">
                    <img src={loading} alt="" />
                  </div>
                </div>
              )}
            </div>
            <div className="overflow-auto">
              <nav aria-label="page navigation">
                <ul className="pagination mb-1">
                  <li className="page-item" key="prev">
                    <a
                      className="page-link small px-2 py-1"
                      aria-label="Previous"
                      href="#/"
                      onClick={(e) => {
                        e.preventDefault();
                        if (state.selectedContest.page > 0) {
                          dispatch({
                            type: "selectedContestRankingPage",
                            payload: state.selectedContest.page - 1,
                          });
                        }
                      }}
                    >
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </a>
                  </li>
                  {pages.map((el) => {
                    return (
                      <li className={"page-item " + (state.selectedContest.page === el ? "active" : "")} key={el}>
                        <a
                          className="page-link small px-2 py-1"
                          href="#/"
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch({
                              type: "selectedContestRankingPage",
                              payload: el,
                            });
                          }}
                        >
                          {el + 1}
                        </a>
                      </li>
                    );
                  })}

                  <li className="page-item" key="next">
                    <a
                      className="page-link small px-2 py-1"
                      href="#/"
                      aria-label="Next"
                      onClick={(e) => {
                        e.preventDefault();
                        if (state.selectedContest.page < pages.length - 1) {
                          dispatch({
                            type: "selectedContestRankingPage",
                            payload: state.selectedContest.page + 1,
                          });
                        }
                      }}
                    >
                      <span aria-hidden="true">&raquo;</span>
                      <span className="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

            {state.selectedContest.ongoing && state.selectedContest.lastUpdated && (
              <div className="text-center small text-info">
                <a
                  href="#/"
                  className="text-info"
                  title="Update Now"
                  onClick={(e) => {
                    e.preventDefault();
                    document.activeElement.blur();
                    loadContestRanking(state.selectedContest.month, state.selectedContest.page, dispatch);
                  }}
                >
                  Last updated on {formatDateTime(state.selectedContest.lastUpdated)}
                  <FontAwesomeIcon icon={faSync} className="ml-2" />
                </a>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="text-center mx-auto">
          <div className="loading-img cursor-help my-5" title="Loading..">
            <img src={loading} alt="" />
          </div>
        </div>
      )}
    </div>
  );
};

// const ContestRanking = (props) => {
//   return (
//     <div className="page">
//       <div className="pt-1">
//         <h1 className="major m-4 pb-4 user-select-none">
//           <span className="logo">
//             <img src={logo} alt="logo" />
//           </span>
//           <div>Trading Strategy Contest</div>
//         </h1>
//         <ContestRankingTab />
//       </div>
//     </div>
//   );
// };
export default ContestRankingTab;
