import React, { useContext, useEffect } from "react";
import logo from "./assets/images/logo.png";
import { globalContext } from "./Context.js";
import getExchange, { initializeExchanges } from "./Exchange.js";
import loading from "./assets/images/loading.gif";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRunning } from "@fortawesome/free-solid-svg-icons";
import Footer from "./Footer";
import { convertOldStrategyDemo } from "./utils.js";

const Demo = (props) => {
  let { state, dispatch } = useContext(globalContext);

  const startDemo = () => {
    initializeExchanges(state.app, true);
    import("./demo-content.js").then((data) => {
      Object.keys(data.demoHistoricalData).forEach((tf) => {
        data.demoHistoricalData[tf].forEach((el) => {
          if (typeof el.d === "string") {
            el.d = new Date(el.d);
          }
        });
      });

      let assets = {
        BTC: { total: 0.05, free: 0.05, locked: 0 },
        USDT: { total: 300, free: 300, locked: 0 },
        ETH: { total: 2, free: 2, locked: 0 },
        LTC: { total: 5, free: 5, locked: 0 },
        BNB: { total: 10, free: 10, locked: 0 },
        XMR: { total: 5, free: 5, locked: 0 },
        SRM: { total: 100, free: 100, locked: 0 },
        SXP: { total: 100, free: 50, locked: 50 },
        ZIL: { total: 20, free: 20, locked: 0 },
        EOS: { total: 0.2, free: 0.2, locked: 0 },
        HOT: { total: 5, free: 5, locked: 0 },
      };
      let snapshots = [
        { d: new Date("2021-07-02T23:59:59.000Z"), btc: 0.01926676, usdt: 1667.9618071187999 },
        { d: new Date("2021-07-03T23:59:59.000Z"), btc: 0.01919391, usdt: 1677.2860971541 },
        { d: new Date("2021-07-04T23:59:59.000Z"), btc: 0.01947708, usdt: 1656.1855519912 },
        { d: new Date("2021-07-05T23:59:59.000Z"), btc: 0.01938846, usdt: 1663.4732950846001 },
        { d: new Date("2021-07-06T23:59:59.000Z"), btc: 0.01955601, usdt: 1662.2079573412 },
        { d: new Date("2021-07-07T23:59:59.000Z"), btc: 0.019603, usdt: 1844.46254313 },
        { d: new Date("2021-07-08T23:59:59.000Z"), btc: 0.0189705, usdt: 1741.502823605 },
        { d: new Date("2021-07-09T23:59:59.000Z"), btc: 0.01891234, usdt: 1633.6176684158 },
        { d: new Date("2021-07-10T23:59:59.000Z"), btc: 0.01859439, usdt: 1937.0250210661 },
        { d: new Date("2021-07-11T23:59:59.000Z"), btc: 0.01902021, usdt: 2229.3146507923 },
        { d: new Date("2021-07-12T23:59:59.000Z"), btc: 0.01887173, usdt: 2417.6673824021 },
        { d: new Date("2021-07-13T23:59:59.000Z"), btc: 0.01852971, usdt: 2108.1454527942 },
        { d: new Date("2021-07-14T23:59:59.000Z"), btc: 0.01795187, usdt: 2272.3056156 },
        { d: new Date("2021-07-15T23:59:59.000Z"), btc: 0.0183463, usdt: 2375.777894181 },
        { d: new Date("2021-07-16T23:59:59.000Z"), btc: 0.01819263, usdt: 2373.4329710841 },
        { d: new Date("2021-07-17T23:59:59.000Z"), btc: 0.01768321, usdt: 2361.9469499776001 },
        { d: new Date("2021-07-18T23:59:59.000Z"), btc: 0.01779104, usdt: 2148.669446736 },
        { d: new Date("2021-07-19T23:59:59.000Z"), btc: 0.0175373, usdt: 2222.4423050549999 },
        { d: new Date("2021-07-20T23:59:59.000Z"), btc: 0.01690493, usdt: 2343.4006914342999 },
        { d: new Date("2021-07-21T23:59:59.000Z"), btc: 0.0168053, usdt: 2442.606669499 },
        { d: new Date("2021-07-22T23:59:59.000Z"), btc: 0.01566958, usdt: 2727.0320639821999 },
        { d: new Date("2021-07-23T23:59:59.000Z"), btc: 0.01556967, usdt: 2833.3879346138 },
        { d: new Date("2021-07-24T23:59:59.000Z"), btc: 0.01495792, usdt: 2929.2264666783999 },
        { d: new Date("2021-07-25T23:59:59.000Z"), btc: 0.01480835, usdt: 2751.42741396 },
        { d: new Date("2021-07-26T23:59:59.000Z"), btc: 0.01320834, usdt: 3121.1729626358001 },
        { d: new Date("2021-07-27T23:59:59.000Z"), btc: 0.0136638, usdt: 3246.8192639279999 },
        { d: new Date("2021-07-28T23:59:59.000Z"), btc: 0.0126584, usdt: 3306.54461043200007 },
        { d: new Date("2021-07-29T23:59:59.000Z"), btc: 0.01254014, usdt: 3429.2737886918001 },
        { d: new Date("2021-07-30T23:59:59.000Z"), btc: 0.01219832, usdt: 3705.76467012560005 },
        { d: new Date("2021-07-31T23:59:59.000Z"), btc: 0.01212411, usdt: 3905.6241259222 },
      ];
      getExchange("Binance").setDemoAssets(assets);
      getExchange("Binance").setDemoSnapshotsData(snapshots);
      data.demoHistoricalData["1 minute"] = data.demoHistoricalData["1 hour"].map((el) => el);
      getExchange("Binance").setDemoHistoricalData(data.demoHistoricalData);
      dispatch({ type: "demo", payload: true });
      dispatch({ type: "assets", payload: { id: "Binance", assets: assets } });

      let strategies = [];
      let liveStatuses = {};
      let liveDetails = {};
      data.demoStrategies.forEach((oldStrategy) => {
        let oldExecution = data.demoExecutions.find((execution) => execution.strategyId === oldStrategy.id);
        let obj = convertOldStrategyDemo(oldStrategy, oldExecution);
        strategies.push(obj.strategy);
        if (obj.status) {
          obj.status.trades = obj.trades;
          obj.status.lastTrade = obj.trades[obj.trades.length - 1];
          liveStatuses[oldStrategy.id] = obj.status;
          liveDetails[oldStrategy.id] = {};
        }
      });

      dispatch({
        type: "strategies",
        payload: { strategies: strategies, liveStatuses: liveStatuses, liveDetails: liveDetails },
      });
      data.demoExecutions.forEach((execution) => {
        execution.strategy = data.demoStrategies.find((strategy) => strategy.id === execution.strategyId);
      });

      //dispatch({ type: "executions", payload: data.demoExecutions });

      dispatch({ type: "dateFrom", payload: new Date("2020-04-01 00:00:00") });
      dispatch({ type: "dateTo", payload: new Date("2020-05-01 23:59:59") });
    });
  };
  if (window.location.href.indexOf("?s=now") !== -1) {
    startDemo();
  }

  return (
    <>
      <div className="page-alt">
        <div className="pt-1 text-center">
          <h1 className="major alt mt-4 pb-4 w-75 mx-auto left-slide">
            <FontAwesomeIcon icon={faRunning} /> Demo
          </h1>
        </div>
        <div className="container">
          <h2 className="h4 card-title text-center m-4">Try without account:</h2>
          <div className="col-12 col-sm-9 col-md-5 col-lg-4  mx-auto">
            <button className="btn btn-lg btn-info btn-block text-uppercase" type="submit" onClick={() => startDemo()}>
              Start Demo
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const QuitDemo = (props) => {
  let { state } = useContext(globalContext);
  useEffect(() => {
    getExchange("Binance").unsubscribeToPricesUpdate("Binance");
    getExchange("Binance US").unsubscribeToPricesUpdate("Binance US");
    state.logout();
  }, []);

  return (
    <div className="page">
      <div className="loading-img cursor-help mt-5 mb-5" title="Loading..">
        <img src={loading} alt="" />
      </div>
    </div>
  );
};

const DemoHome = (props) => {
  let history = useHistory();
  return (
    <div className="page">
      <span className="logo mt-4">
        <img src={logo} alt="logo" />
      </span>

      <h1 className="major mt-2 pb-4">Easy Crypto Bot Demo</h1>

      <h2 className="h4 text-center mt-4 mx-2">Use the navigation to check-out the app features!</h2>

      <div className="text-center mt-4">
        <button
          type="button"
          className="btn min-w-150 btn-dark mx-2 mx-sm-4 my-2"
          onClick={() => {
            history.push(`/strategies`);
          }}
        >
          Strategies
        </button>

        <button
          type="button"
          className="btn min-w-150 btn-info mx-2 mx-sm-4 my-2"
          onClick={() => {
            history.push(`/trading`);
          }}
        >
          Trading
        </button>
      </div>
    </div>
  );
};

export { Demo, QuitDemo, DemoHome };
