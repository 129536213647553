import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import Dropdown, { DropdownWithSearch } from "./Dropdown";
import { globalContext } from "./Context.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsAltV,
  faBan,
  faEdit,
  faExclamationTriangle,
  faInfoCircle,
  faPlus,
  faQuestionCircle,
  faSearch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { exchanges } from "./constants/consts.json";
import { AddExchangeModal } from "./Settings";
import $ from "jquery";
import getExchange from "./Exchange";
import { RadioButtons } from "./RadioButtons";
import { ModalConfirm } from "./Modal";
import {
  fixNumber,
  formatDateTime3,
  getFromLocalStorage,
  getUsdValue,
  roundBaseValue,
  roundQuotedValue,
  showConfirmDialog,
  showInfoDialog,
  getQuotedValue,
} from "./utils";
import loadingReverced from "./assets/images/loading-reverced.gif";
import getEcbDatabase from "./EcbDatabase";
import { TradeDetails } from "./Trading";

const EditStoploss = (props) => {
  const [useStoploss, setUseStoploss] = useState(false);
  const [stoplossType, setStoplossType] = useState("percent");
  const [stoploss, setStoploss] = useState("");
  const [stoplossP, setStoplossP] = useState("");
  const [okDisabled, setOkDisabled] = useState(true);
  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setUseStoploss(true);
    setStoplossType("percent");
    setStoploss("");
    setStoplossP("");

    if (!props.trade) {
      return;
    }
    if (props.trade.stoplossType) {
      setOkDisabled(true);
      setStoplossType(props.trade.stoplossType);
      setStoploss(props.trade.stoploss);
      setStoplossP(props.trade.stoplossP);
    }
  }, [props.trade]);

  useEffect(() => {
    if (useStoploss && props.trade) {
      if (props.trade.direction === "Buy") {
        if (stoplossType === "percent") {
          setOkDisabled(stoplossP <= 0 || stoplossP >= 100);
        } else {
          setOkDisabled(stoploss <= 0 || stoploss >= (props.trade.o ? props.trade.o : props.trade.price));
        }
      } else {
        if (stoplossType === "percent") {
          setOkDisabled(stoplossP <= 0);
        } else {
          setOkDisabled(stoploss <= (props.trade.o ? props.trade.o : props.trade.price));
        }
      }
    } else {
      setOkDisabled(false);
    }
  }, [useStoploss, stoploss, stoplossP, stoplossType]);

  return (
    <ModalConfirm
      id="manualOrderEditStoploss"
      header={<span className="text-info">{props.trade && props.trade.pair} Stoploss</span>}
      okText="SAVE"
      okDisabled={okDisabled}
      content={
        <div className="font-normal">
          <div className="shadow-dark rounded p-2">
            <div className="d-inline-block min-w-100 align-top">Open Price:</div>
            {props.trade && (
              <span className="text-info">
                {roundQuotedValue(props.trade.o ? props.trade.o : props.trade.price, props.trade.pairDetails)}
              </span>
            )}
          </div>
          {useStoploss ? (
            <div className="text-nowrap shadow-dark rounded p-2 mt-3 mb-2">
              <div className="d-inline-block min-w-100 align-top">Stoploss:</div>
              <div className="d-inline-block">
                <RadioButtons
                  options={[
                    {
                      id: "sPercentEdit",
                      optionClasses: "d-block text-dark",
                      checkedColor: "text-info",
                      uncheckedColor: "text-white border rounded-circle border-info",
                      checked: stoplossType === "percent",
                      check: () => {
                        if (stoplossP) {
                          let price = props.trade.o ? props.trade.o : props.trade.price;
                          if (price && stoplossP < 100) {
                            setStoploss(
                              props.trade.direction === "Buy"
                                ? roundQuotedValue(price * (1 - stoplossP / 100), props.pairDetails)
                                : roundQuotedValue(price * (1 + stoplossP / 100), props.pairDetails)
                            );
                          } else {
                            setStoploss("");
                          }
                        }
                        setStoplossType("percent");
                      },
                      text: (
                        <>
                          <span className="d-inline-block min-w-100">as percent</span>
                          <input
                            type="number"
                            className="d-inline-block btn-bottom-outline-info w-150 "
                            value={stoplossP}
                            placeholder="<Percent>"
                            onChange={(e) => {
                              let value = Math.abs(parseFloat(e.target.value));
                              setStoplossP(!isNaN(value) ? value : "");
                              setStoploss("");
                              let price = props.trade.o ? props.trade.o : props.trade.price;
                              if (price && value < 100) {
                                setStoploss(
                                  props.trade.direction === "Buy"
                                    ? !isNaN(value)
                                      ? roundQuotedValue(price * (1 - value / 100), props.pairDetails)
                                      : ""
                                    : !isNaN(value)
                                    ? roundQuotedValue(price * (1 + value / 100), props.pairDetails)
                                    : ""
                                );
                              } else {
                                setStoploss("");
                              }
                            }}
                          />
                          <a
                            href="#/"
                            className="text-danger ml-5"
                            onClick={(e) => {
                              e.preventDefault();
                              document.activeElement.blur();
                              setUseStoploss(false);
                            }}
                          >
                            <FontAwesomeIcon className="" icon={faTimesCircle} />
                          </a>
                        </>
                      ),
                    },
                    {
                      id: "sPriceEdit",
                      optionClasses: "d-block text-dark mt-2",
                      checkedColor: "text-info",
                      uncheckedColor: "text-white border rounded-circle border-info",
                      checked: stoplossType === "price",
                      check: () => setStoplossType("price"),
                      text: (
                        <>
                          <span className="d-inline-block min-w-100">at price</span>
                          <input
                            type="number"
                            className="d-inline-block btn-bottom-outline-info w-150 "
                            value={stoploss}
                            placeholder="<Price>"
                            onChange={(e) => {
                              let value = Math.abs(parseFloat(e.target.value));
                              setStoploss(!isNaN(value) ? value : "");
                              setStoplossP("");

                              let price = props.trade.o ? props.trade.o : props.trade.price;
                              if (
                                price &&
                                ((props.trade.direction === "Buy" && value < price) ||
                                  (props.trade.direction === "Sell" && value > price))
                              ) {
                                setStoplossP(
                                  props.trade.direction === "Buy"
                                    ? !isNaN(value)
                                      ? fixNumber(((price - value) / price) * 100, 2)
                                      : ""
                                    : !isNaN(value)
                                    ? fixNumber(((value - price) / price) * 100, 2)
                                    : ""
                                );
                              } else {
                                setStoplossP("");
                              }
                            }}
                          />
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          ) : (
            <div className="text-nowrap mt-3">
              <a
                href="#/"
                className="btn btn-sm btn-info w-110"
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  setUseStoploss(true);
                }}
              >
                Stoploss
                <FontAwesomeIcon className="ml-2" icon={faPlus} />
              </a>
            </div>
          )}
        </div>
      }
      func={() => {
        let now = new Date().getTime();
        let manualTrade = { ...props.trade, editDate: now };

        if (useStoploss) {
          manualTrade.stoploss = stoploss;
          manualTrade.stoplossP = stoplossP;
          manualTrade.stoplossType = stoplossType;
        } else {
          delete manualTrade.stoploss;
          delete manualTrade.stoplossP;
          delete manualTrade.stoplossType;
        }

        getExchange(props.trade.exchange).manualTradeEdit(manualTrade);
      }}
    />
  );
};

const EditTarget = (props) => {
  const [useTarget, setUseTarget] = useState(false);
  const [targetType, setTargetType] = useState("percent");
  const [target, setTarget] = useState("");
  const [targetP, setTargetP] = useState("");
  const componentIsMounted = useRef(true);
  const [okDisabled, setOkDisabled] = useState(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setUseTarget(true);
    setTargetType("percent");
    setTarget("");
    setTargetP("");

    if (!props.trade) {
      return;
    }
    if (props.trade.targetType) {
      setOkDisabled(false);
      setTargetType(props.trade.targetType);
      setTarget(props.trade.target);
      setTargetP(props.trade.targetP);
    }
  }, [props.trade]);

  useEffect(() => {
    if (useTarget && props.trade) {
      if (props.trade.direction === "Buy") {
        if (targetType === "percent") {
          setOkDisabled(targetP <= 0);
        } else {
          setOkDisabled(target <= 0 || target <= (props.trade.o ? props.trade.o : props.trade.price));
        }
      } else {
        if (targetType === "percent") {
          setOkDisabled(targetP <= 0 || targetP >= 100);
        } else {
          setOkDisabled(target <= 0 || target >= (props.trade.o ? props.trade.o : props.trade.price));
        }
      }
    } else {
      setOkDisabled(false);
    }
  }, [useTarget, target, targetP, targetType]);

  return (
    <ModalConfirm
      id="manualOrderEditTarget"
      header={<span className="text-info">{props.trade && props.trade.pair} Target</span>}
      okText="SAVE"
      okDisabled={okDisabled}
      content={
        <div className="font-normal">
          <div className="shadow-dark rounded p-2">
            <div className="d-inline-block min-w-100 align-top">Open Price:</div>
            {props.trade && (
              <span className="text-info">
                {roundQuotedValue(props.trade.o ? props.trade.o : props.trade.price, props.trade.pairDetails)}
              </span>
            )}
          </div>
          {useTarget ? (
            <div className="text-nowrap shadow-dark rounded p-2 mt-3 mb-2">
              <div className="d-inline-block min-w-100 align-top">Target:</div>
              <div className="d-inline-block">
                <RadioButtons
                  options={[
                    {
                      id: "tPercentEdit",
                      optionClasses: "d-block text-dark",
                      checkedColor: "text-info",
                      uncheckedColor: "text-white border rounded-circle border-info",
                      checked: targetType === "percent",
                      check: () => {
                        if (targetP) {
                          let price = props.trade.o ? props.trade.o : props.trade.price;
                          if (price) {
                            setTarget(
                              props.trade.direction === "Buy"
                                ? roundQuotedValue(price * (1 + targetP / 100), props.pairDetails)
                                : roundQuotedValue(price * (1 - targetP / 100), props.pairDetails)
                            );
                          } else {
                            setTarget("");
                          }
                        }
                        setTargetType("percent");
                      },
                      text: (
                        <>
                          <span className="d-inline-block min-w-100">as percent</span>
                          <input
                            type="number"
                            className="d-inline-block btn-bottom-outline-info w-150 "
                            value={targetP}
                            placeholder="<Percent>"
                            onChange={(e) => {
                              let value = Math.abs(parseFloat(e.target.value));
                              setTargetP(!isNaN(value) ? value : "");
                              setTarget("");
                              let price = props.trade.o ? props.trade.o : props.trade.price;
                              if (price && value > 0) {
                                setTarget(
                                  props.trade.direction === "Buy"
                                    ? !isNaN(value)
                                      ? roundQuotedValue(price * (1 + value / 100), props.pairDetails)
                                      : ""
                                    : !isNaN(value)
                                    ? roundQuotedValue(price * (1 - value / 100), props.pairDetails)
                                    : ""
                                );
                              } else {
                                setTarget("");
                              }
                            }}
                          />
                          <a
                            href="#/"
                            className="text-danger ml-5"
                            onClick={(e) => {
                              e.preventDefault();
                              document.activeElement.blur();
                              setUseTarget(false);
                            }}
                          >
                            <FontAwesomeIcon className="" icon={faTimesCircle} />
                          </a>
                        </>
                      ),
                    },
                    {
                      id: "tPriceEdit",
                      optionClasses: "d-block text-dark mt-2",
                      checkedColor: "text-info",
                      uncheckedColor: "text-white border rounded-circle border-info",
                      checked: targetType === "price",
                      check: () => setTargetType("price"),
                      text: (
                        <>
                          <span className="d-inline-block min-w-100">at price</span>
                          <input
                            type="number"
                            className="d-inline-block btn-bottom-outline-info w-150 "
                            value={target}
                            placeholder="<Price>"
                            onChange={(e) => {
                              let value = Math.abs(parseFloat(e.target.value));
                              setTarget(!isNaN(value) ? value : "");
                              setTargetP("");

                              let price = props.trade.o ? props.trade.o : props.trade.price;
                              if (
                                price &&
                                ((props.trade.direction === "Buy" && value > price) ||
                                  (props.trade.direction === "Sell" && value < price))
                              ) {
                                setTargetP(
                                  props.trade.direction === "Buy"
                                    ? !isNaN(value)
                                      ? fixNumber(((value - price) / price) * 100, 2)
                                      : ""
                                    : !isNaN(value)
                                    ? fixNumber(((price - value) / price) * 100, 2)
                                    : ""
                                );
                              } else {
                                setTargetP("");
                              }
                            }}
                          />
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          ) : (
            <div className="text-nowrap mt-3">
              <a
                href="#/"
                className="btn btn-sm btn-info w-110"
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  setUseTarget(true);
                }}
              >
                Target
                <FontAwesomeIcon className="ml-2" icon={faPlus} />
              </a>
            </div>
          )}
        </div>
      }
      func={() => {
        let now = new Date().getTime();
        let manualTrade = { ...props.trade, editDate: now };

        if (useTarget) {
          manualTrade.target = target;
          manualTrade.targetP = targetP;
          manualTrade.targetType = targetType;
        } else {
          delete manualTrade.target;
          delete manualTrade.targetP;
          delete manualTrade.targetType;
        }

        getExchange(props.trade.exchange).manualTradeEdit(manualTrade);
      }}
    />
  );
};

const ManualOrder = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const [marketPrice, setMarketPrice] = useState("");
  const [limitPrice, setLimitPrice] = useState("");
  const [orderType, setOrderType] = useState("market");
  const [baseAsset, setBaseAsset] = useState({ total: 0, free: 0 });
  const [quotedAsset, setQuotedAsset] = useState({ total: 0, free: 0 });
  const [baseSize, setBaseSize] = useState("");
  const [quoteSize, setQuoteSize] = useState("");
  const [percent, setPercent] = useState("");
  const [sizeType, setSzeType] = useState("base");
  const [useStoploss, setUseStoploss] = useState(false);
  const [useTarget, setUseTarget] = useState(false);
  const [stoplossType, setStoplossType] = useState("percent");
  const [stoploss, setStoploss] = useState("");
  const [stoplossP, setStoplossP] = useState("");
  const [targetType, setTargetType] = useState("percent");
  const [target, setTarget] = useState("");
  const [targetP, setTargetP] = useState("");
  const componentIsMounted = useRef(true);
  const [prices, setPrices] = useState(null);
  const [okDisabled, setOkDisabled] = useState(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setOrderType("market");
    setBaseAsset({ total: 0, free: 0 });
    setQuotedAsset({ total: 0, free: 0 });
    setSzeType("base");
    setMarketPrice("");
    setLimitPrice("");
    setBaseSize("");
    setQuoteSize("");
    setPercent("");
    setUseStoploss(false);
    setUseTarget(false);
    setStoplossType("percent");
    setStoploss("");
    setStoplossP("");
    setTargetType("percent");
    setTarget("");
    setTargetP("");

    if (!props.exchange || !props.pair || !props.pairDetails) {
      return;
    }

    if (state.assets[props.exchange] && state.assets[props.exchange][props.pairDetails.baseAsset]) {
      setBaseAsset(state.assets[props.exchange][props.pairDetails.baseAsset]);
    }
    if (state.assets[props.exchange] && state.assets[props.exchange][props.pairDetails.quoteAsset]) {
      setQuotedAsset(state.assets[props.exchange][props.pairDetails.quoteAsset]);
    }

    getExchange(props.exchange).subscribeToPricesUpdate("manualTrading", (prices, pricesStats) => {
      if (componentIsMounted.current) {
        setPrices(prices);
        setMarketPrice(prices[props.pair]);
      }
    });

    return () => {
      getExchange(props.exchange).unsubscribeToPricesUpdate("manualTrading");
    };
  }, [props.exchange, props.pair, props.pairDetails, props.direction]);

  useEffect(() => {
    if (!props.exchange || !props.pair || !props.pairDetails) {
      return;
    }
    if (state.assets[props.exchange] && state.assets[props.exchange][props.pairDetails.baseAsset]) {
      setBaseAsset(state.assets[props.exchange][props.pairDetails.baseAsset]);
    }
    if (state.assets[props.exchange] && state.assets[props.exchange][props.pairDetails.quoteAsset]) {
      setQuotedAsset(state.assets[props.exchange][props.pairDetails.quoteAsset]);
    }
  }, [state.assets]);

  useEffect(() => {
    if (!props.exchange || !props.pair || !props.pairDetails) {
      return;
    }

    let price = orderType === "limit" ? limitPrice : marketPrice;
    if (!price) {
      return;
    }

    let assetBalance = getExchange(props.exchange).getAssetBalance(
      props.direction === "Buy" ? props.pairDetails.quoteAsset : props.pairDetails.baseAsset
    );

    if (sizeType === "base") {
      let quoteValue = !isNaN(baseSize) ? roundQuotedValue(baseSize * price, props.pairDetails) : "";
      setQuoteSize(baseSize !== "" && quoteValue);

      if (assetBalance && assetBalance.free && quoteValue) {
        let percent = ((props.direction === "Buy" ? quoteValue : baseSize) / assetBalance.free) * 100;
        if (percent) {
          setPercent(fixNumber(percent, 2));
        } else {
          setPercent("");
        }
      } else {
        setPercent("");
      }
    } else if (sizeType === "quote") {
      let baseSize = quoteSize !== "" && !isNaN(quoteSize) ? roundBaseValue(quoteSize / price, props.pairDetails) : "";
      setBaseSize(baseSize);
      if (assetBalance && assetBalance.free) {
        let percent = ((props.direction === "Buy" ? quoteSize : baseSize) / assetBalance.free) * 100;
        if (percent) {
          setPercent(fixNumber(percent, 2));
        } else {
          setPercent("");
        }
      } else {
        setPercent("");
      }
    } else {
    }

    if (useStoploss) {
      if (stoplossType === "percent") {
        setStoploss(
          props.direction === "Buy"
            ? !isNaN(stoplossP) && stoplossP > 0
              ? roundQuotedValue(price * (1 - stoplossP / 100), props.pairDetails)
              : ""
            : !isNaN(stoplossP) && stoplossP > 0
            ? roundQuotedValue(price * (1 + stoplossP / 100), props.pairDetails)
            : ""
        );
      } else {
        setStoplossP(
          props.direction === "Buy"
            ? !isNaN(stoploss)
              ? fixNumber(((price - stoploss) / price) * 100, 2)
              : ""
            : !isNaN(stoploss)
            ? fixNumber(((stoploss - price) / price) * 100, 2)
            : ""
        );
      }
    }

    if (useTarget) {
      if (targetType === "percent") {
        setTarget(
          props.direction === "Buy"
            ? !isNaN(targetP) && targetP > 0
              ? roundQuotedValue(price * (1 + targetP / 100), props.pairDetails)
              : ""
            : !isNaN(targetP) && targetP > 0
            ? roundQuotedValue(price * (1 - targetP / 100), props.pairDetails)
            : ""
        );
      } else {
        setTargetP(
          props.direction === "Buy"
            ? !isNaN(target)
              ? fixNumber(((target - price) / price) * 100, 2)
              : ""
            : !isNaN(target)
            ? fixNumber(((price - target) / price) * 100, 2)
            : ""
        );
      }
    }
  }, [limitPrice, orderType]);

  useEffect(() => {
    let isDisabled = false;
    if (props.direction === "Buy") {
      if (useStoploss) {
        if (stoplossType === "percent") {
          isDisabled = stoplossP <= 0 || stoplossP >= 100;
        } else {
          isDisabled = stoploss <= 0 || stoploss >= (orderType === "limit" ? limitPrice : marketPrice);
        }
      }
      if (!isDisabled && useTarget) {
        if (targetType === "percent") {
          isDisabled = targetP <= 0;
        } else {
          isDisabled = target <= 0 || target <= (orderType === "limit" ? limitPrice : marketPrice);
        }
      }
      if (!isDisabled && orderType === "limit" && !limitPrice) {
        isDisabled = true;
      }
      if (!isDisabled) {
        if (sizeType === "base") {
          if (!baseSize) {
            isDisabled = true;
          }
        } else {
          if (!quoteSize) {
            isDisabled = true;
          }
        }
      }
    } else {
      if (useStoploss) {
        if (stoplossType === "percent") {
          isDisabled = stoplossP <= 0;
        } else {
          isDisabled = stoploss <= (orderType === "limit" ? limitPrice : marketPrice);
        }
      }
      if (!isDisabled && useTarget) {
        if (targetType === "percent") {
          isDisabled = targetP <= 0;
        } else {
          isDisabled = target <= 0 || target >= (orderType === "limit" ? limitPrice : marketPrice);
        }
      }
      if (!isDisabled && orderType === "limit" && !limitPrice) {
        isDisabled = true;
      }
      if (!isDisabled) {
        if (sizeType === "base") {
          if (!baseSize) {
            isDisabled = true;
          }
        } else {
          if (!quoteSize) {
            isDisabled = true;
          }
        }
      }
    }
    if (!props.pairDetails) {
      setOkDisabled(true);
    } else {
      setOkDisabled(isDisabled);
    }
  }, [
    useStoploss,
    stoploss,
    stoplossP,
    stoplossType,
    useTarget,
    target,
    targetP,
    targetType,
    orderType,
    limitPrice,
    sizeType,
    baseSize,
    quoteSize,
  ]);

  return (
    <ModalConfirm
      id="manualOrder"
      header={
        <span className="text-info">
          {props.direction} {props.pair ? props.pair : ""}
        </span>
      }
      okText={props.direction.toUpperCase()}
      okDisabled={okDisabled}
      content={
        <div className="font-normal">
          <div className="">
            {props.pairDetails && (
              <div className="shadow-dark rounded p-2">
                <div className="text-left">Current balance:</div>
                <div className="table-responsive border-top border-dark">
                  <table className="table table-sm small table-hover">
                    <thead className="border-dark border-bottom">
                      <tr>
                        <th className="text-left pl-2">Asset</th>
                        <th className="text-left">Total</th>
                        <th className="text-left ">Free</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="pl-2">{props.pairDetails.baseAsset}</td>
                        <td>{baseAsset.total}</td>
                        <td>{baseAsset.free}</td>
                      </tr>
                      <tr>
                        <td className="pl-2">{props.pairDetails.quoteAsset}</td>
                        <td>{quotedAsset.total}</td>
                        <td>{quotedAsset.free}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            <div className="text-nowrap shadow-dark rounded p-2 mt-4">
              <div className="d-inline-block min-w-100 align-top">{props.direction} Size:</div>
              <div className="d-inline-block">
                <RadioButtons
                  options={[
                    {
                      id: "base",
                      optionClasses: "d-block text-dark",
                      checkedColor: "text-info",
                      uncheckedColor: "text-white border rounded-circle border-info",
                      checked: sizeType === "base",
                      check: () => setSzeType("base"),
                      text: (
                        <>
                          <span className="d-inline-block min-w-100">
                            {props.pairDetails && <>in {props.pairDetails.baseAsset}</>}
                          </span>
                          <input
                            type="number"
                            className="d-inline-block btn-bottom-outline-info w-150 "
                            value={baseSize}
                            placeholder={`<${props.pairDetails ? props.pairDetails.baseAsset + " " : ""}Size>`}
                            onChange={(e) => {
                              let value = Math.abs(parseFloat(e.target.value));
                              setBaseSize(!isNaN(value) ? value : "");
                              let price = orderType === "limit" ? limitPrice : marketPrice;
                              if (price) {
                                let quote = !isNaN(value) ? roundQuotedValue(value * price, props.pairDetails) : "";
                                setQuoteSize(quote);

                                let assetBalance = getExchange(props.exchange).getAssetBalance(
                                  props.direction === "Buy" ? props.pairDetails.quoteAsset : props.pairDetails.baseAsset
                                );

                                if (assetBalance && assetBalance.free) {
                                  let percent = ((props.direction === "Buy" ? quote : value) / assetBalance.free) * 100;
                                  if (percent) {
                                    setPercent(fixNumber(percent, 2));
                                  } else {
                                    setPercent("");
                                  }
                                } else {
                                  setPercent("");
                                }
                              } else {
                                setQuoteSize("");
                                setPercent("");
                              }
                            }}
                          />
                          {prices && props.pairDetails && baseSize > 0 && (
                            <div className="d-inline-block text-info small overflow-hidden max-w-100 ml-2">
                              ~ ${getUsdValue(baseSize, props.pairDetails.baseAsset, prices)}
                            </div>
                          )}
                        </>
                      ),
                    },
                    {
                      id: "quote",
                      optionClasses: "d-block text-dark mt-2",
                      checkedColor: "text-info",
                      uncheckedColor: "text-white border rounded-circle border-info",
                      checked: sizeType === "quote",
                      disabled: orderType === "limit",
                      check: () => setSzeType("quote"),
                      text: (
                        <>
                          <span className="d-inline-block min-w-100">
                            {props.pairDetails && <>in {props.pairDetails.quoteAsset}</>}
                          </span>
                          <input
                            type="number"
                            className="d-inline-block btn-bottom-outline-info w-150"
                            value={quoteSize}
                            placeholder={`<${props.pairDetails ? props.pairDetails.quoteAsset + " " : ""}Size>`}
                            onChange={(e) => {
                              let value = Math.abs(parseFloat(e.target.value));
                              setQuoteSize(!isNaN(value) ? value : "");

                              let assetBalance = getExchange(props.exchange).getAssetBalance(
                                props.direction === "Buy" ? props.pairDetails.quoteAsset : props.pairDetails.baseAsset
                              );
                              if (props.direction === "Buy") {
                                if (assetBalance && assetBalance.free && value) {
                                  let percent = (value / assetBalance.free) * 100;
                                  setPercent(fixNumber(percent, 2));
                                } else {
                                  setPercent("");
                                }
                              }
                              let price = orderType === "limit" ? limitPrice : marketPrice;
                              if (price) {
                                let base = !isNaN(value) ? roundBaseValue(value / price, props.pairDetails) : "";
                                setBaseSize(base);
                                if (props.direction === "Sell") {
                                  let percent = (base / assetBalance.free) * 100;
                                  if (percent) {
                                    setPercent(fixNumber(percent, 2));
                                  } else {
                                    setPercent("");
                                  }
                                }
                              } else {
                                setBaseSize("");
                              }
                            }}
                          />
                        </>
                      ),
                    },
                    {
                      id: "percent",
                      optionClasses: "d-block text-dark mt-2",
                      checkedColor: "text-info",
                      uncheckedColor: "text-white border rounded-circle border-info",
                      checked: sizeType === "percent",
                      disabled: orderType === "limit",
                      check: () => setSzeType("percent"),
                      text: (
                        <>
                          <span className="d-inline-block min-w-100">
                            {props.pairDetails && (
                              <>
                                % of{" "}
                                {props.direction === "Buy" ? props.pairDetails.quoteAsset : props.pairDetails.baseAsset}
                              </>
                            )}
                          </span>
                          <input
                            type="number"
                            className="d-inline-block btn-bottom-outline-info w-150"
                            value={percent}
                            placeholder={`<${
                              props.pairDetails
                                ? "% of " +
                                  (props.direction === "Buy"
                                    ? props.pairDetails.quoteAsset
                                    : props.pairDetails.baseAsset) +
                                  " "
                                : ""
                            }>`}
                            onChange={(e) => {
                              let value = Math.abs(parseFloat(e.target.value));
                              if (value > 100) {
                                value = 100;
                              }
                              setPercent(!isNaN(value) ? value : "");
                              if (!value) {
                                setQuoteSize("");
                                setBaseSize("");
                                return;
                              }
                              let assetBalance = getExchange(props.exchange).getAssetBalance(
                                props.direction === "Buy" ? props.pairDetails.quoteAsset : props.pairDetails.baseAsset
                              );
                              if (assetBalance) {
                                if (props.direction === "Buy") {
                                  let quoteValue = assetBalance.free * (value / 100);
                                  setQuoteSize(roundQuotedValue(quoteValue, props.pairDetails));
                                  roundQuotedValue(quoteValue, props.pairDetails);
                                  let price = orderType === "limit" ? limitPrice : marketPrice;
                                  if (price) {
                                    setBaseSize(
                                      !isNaN(quoteValue) ? roundBaseValue(quoteValue / price, props.pairDetails) : ""
                                    );
                                  } else {
                                    setBaseSize("");
                                  }
                                } else {
                                  let baseValue = assetBalance.free * (value / 100);
                                  setBaseSize(roundBaseValue(baseValue, props.pairDetails));
                                  let price = orderType === "limit" ? limitPrice : marketPrice;
                                  if (price) {
                                    setQuoteSize(
                                      !isNaN(baseValue) ? roundQuotedValue(baseValue * price, props.pairDetails) : ""
                                    );
                                  } else {
                                    setQuoteSize("");
                                  }
                                }
                              } else {
                                setQuoteSize("");
                                setBaseSize("");
                              }
                            }}
                          />
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>

            <div className="shadow-dark rounded p-2 mt-4">
              <div className="d-inline-block min-w-100 align-top">Order type:</div>
              <div className="d-inline-block">
                <RadioButtons
                  options={[
                    {
                      id: "market",
                      optionClasses: "d-block text-dark",
                      checkedColor: "text-info",
                      uncheckedColor: "text-white border rounded-circle border-info",
                      checked: orderType === "market",
                      check: () => {
                        setOrderType("market");
                        if (marketPrice) {
                          if (sizeType === "base") {
                            setQuoteSize(
                              baseSize !== "" && !isNaN(baseSize)
                                ? roundQuotedValue(baseSize * marketPrice, props.pairDetails)
                                : ""
                            );
                          } else {
                            setBaseSize(
                              quoteSize !== "" && !isNaN(quoteSize)
                                ? roundBaseValue(quoteSize / marketPrice, props.pairDetails)
                                : ""
                            );
                          }
                        }
                      },
                      text: (
                        <>
                          <span className="d-inline-block min-w-100">market</span>
                          {marketPrice && (
                            <>
                              <span className="text-secondary">Last price:</span>
                              <span className="text-info ml-2">{roundQuotedValue(marketPrice, props.pairDetails)}</span>
                            </>
                          )}
                        </>
                      ),
                    },
                    {
                      id: "limit",
                      optionClasses: "d-block text-dark mt-2",
                      checkedColor: "text-info",
                      uncheckedColor: "text-white border rounded-circle border-info",
                      checked: orderType === "limit",
                      check: () => {
                        if (!limitPrice) {
                          if (marketPrice) {
                            setLimitPrice(marketPrice);
                          }
                        }
                        setSzeType("base");
                        setOrderType("limit");
                      },
                      text: (
                        <>
                          <span className="d-inline-block min-w-100 pb-1">limit</span>
                          {orderType === "limit" && (
                            <input
                              type="number"
                              className="d-inline-block btn-bottom-outline-info w-150"
                              value={limitPrice}
                              placeholder="<Price>"
                              onChange={(e) => {
                                let value = Math.abs(parseFloat(e.target.value));
                                setLimitPrice(!isNaN(value) ? value : "");
                              }}
                            />
                          )}
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
            {useStoploss ? (
              <div className="text-nowrap shadow-dark rounded p-2 mt-4">
                <div className="d-inline-block min-w-100 align-top">Stoploss:</div>
                <div className="d-inline-block">
                  <RadioButtons
                    options={[
                      {
                        id: "sPercent",
                        optionClasses: "d-block text-dark",
                        checkedColor: "text-info",
                        uncheckedColor: "text-white border rounded-circle border-info",
                        checked: stoplossType === "percent",
                        check: () => {
                          if (stoplossP) {
                            let price = orderType === "limit" ? limitPrice : marketPrice;
                            if (price && stoplossP < 100) {
                              setStoploss(
                                props.direction === "Buy"
                                  ? roundQuotedValue(price * (1 - stoplossP / 100), props.pairDetails)
                                  : roundQuotedValue(price * (1 + stoplossP / 100), props.pairDetails)
                              );
                            } else {
                              setStoploss("");
                            }
                          }

                          setStoplossType("percent");
                        },
                        text: (
                          <>
                            <span className="d-inline-block min-w-100">as percent</span>
                            <input
                              type="number"
                              className="d-inline-block btn-bottom-outline-info w-150 "
                              value={stoplossP}
                              placeholder="<Percent>"
                              onChange={(e) => {
                                let value = Math.abs(parseFloat(e.target.value));
                                setStoplossP(!isNaN(value) ? value : "");
                                setStoploss("");
                                let price = orderType === "limit" ? limitPrice : marketPrice;
                                if (price && value < 100) {
                                  setStoploss(
                                    props.direction === "Buy"
                                      ? !isNaN(value)
                                        ? roundQuotedValue(price * (1 - value / 100), props.pairDetails)
                                        : ""
                                      : !isNaN(value)
                                      ? roundQuotedValue(price * (1 + value / 100), props.pairDetails)
                                      : ""
                                  );
                                } else {
                                  setStoploss("");
                                }
                              }}
                            />
                            <a
                              href="#/"
                              className="text-danger ml-5"
                              onClick={(e) => {
                                e.preventDefault();
                                document.activeElement.blur();
                                setUseStoploss(false);
                              }}
                            >
                              <FontAwesomeIcon className="" icon={faTimesCircle} />
                            </a>
                          </>
                        ),
                      },
                      {
                        id: "sPrice",
                        optionClasses: "d-block text-dark mt-2",
                        checkedColor: "text-info",
                        uncheckedColor: "text-white border rounded-circle border-info",
                        checked: stoplossType === "price",
                        check: () => setStoplossType("price"),
                        text: (
                          <>
                            <span className="d-inline-block min-w-100">at price</span>
                            <input
                              type="number"
                              className="d-inline-block btn-bottom-outline-info w-150 "
                              value={stoploss}
                              placeholder="<Price>"
                              onChange={(e) => {
                                let value = Math.abs(parseFloat(e.target.value));
                                setStoploss(!isNaN(value) ? value : "");
                                setStoplossP("");

                                let price = orderType === "limit" ? limitPrice : marketPrice;
                                if (
                                  price &&
                                  ((props.direction === "Buy" && value < price) ||
                                    (props.direction === "Sell" && value > price))
                                ) {
                                  setStoplossP(
                                    props.direction === "Buy"
                                      ? !isNaN(value)
                                        ? fixNumber(((price - value) / price) * 100, 2)
                                        : ""
                                      : !isNaN(value)
                                      ? fixNumber(((value - price) / price) * 100, 2)
                                      : ""
                                  );
                                } else {
                                  setStoplossP("");
                                }
                              }}
                            />
                          </>
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            ) : (
              <div className="text-nowrap mt-4">
                <a
                  href="#/"
                  className="btn btn-sm btn-info w-110"
                  onClick={(e) => {
                    e.preventDefault();
                    document.activeElement.blur();
                    setUseStoploss(true);
                  }}
                >
                  Stoploss
                  <FontAwesomeIcon className="ml-2" icon={faPlus} />
                </a>
              </div>
            )}
            {useTarget ? (
              <div className="text-nowrap shadow-dark rounded p-2 mt-4 mb-3">
                <div className="d-inline-block min-w-100 align-top">Target:</div>
                <div className="d-inline-block">
                  <RadioButtons
                    options={[
                      {
                        id: "tPercent",
                        optionClasses: "d-block text-dark",
                        checkedColor: "text-info",
                        uncheckedColor: "text-white border rounded-circle border-info",
                        checked: targetType === "percent",
                        check: () => {
                          if (targetP) {
                            let price = orderType === "limit" ? limitPrice : marketPrice;
                            if (price) {
                              setTarget(
                                props.direction === "Buy"
                                  ? roundQuotedValue(price * (1 + targetP / 100), props.pairDetails)
                                  : roundQuotedValue(price * (1 - targetP / 100), props.pairDetails)
                              );
                            } else {
                              setTarget("");
                            }
                          }
                          setTargetType("percent");
                        },
                        text: (
                          <>
                            <span className="d-inline-block min-w-100">as percent</span>
                            <input
                              type="number"
                              className="d-inline-block btn-bottom-outline-info w-150 "
                              value={targetP}
                              placeholder="<Percent>"
                              onChange={(e) => {
                                let value = Math.abs(parseFloat(e.target.value));
                                setTargetP(!isNaN(value) ? value : "");
                                setTarget("");
                                let price = orderType === "limit" ? limitPrice : marketPrice;
                                if (price) {
                                  setTarget(
                                    props.direction === "Buy"
                                      ? !isNaN(value)
                                        ? roundQuotedValue(price * (1 + value / 100), props.pairDetails)
                                        : ""
                                      : !isNaN(value)
                                      ? roundQuotedValue(price * (1 - value / 100), props.pairDetails)
                                      : ""
                                  );
                                } else {
                                  setTarget("");
                                }
                              }}
                            />
                            <a
                              href="#/"
                              className="text-danger ml-5"
                              onClick={(e) => {
                                e.preventDefault();
                                document.activeElement.blur();
                                setUseTarget(false);
                              }}
                            >
                              <FontAwesomeIcon className="" icon={faTimesCircle} />
                            </a>
                          </>
                        ),
                      },
                      {
                        id: "tPrice",
                        optionClasses: "d-block text-dark mt-2",
                        checkedColor: "text-info",
                        uncheckedColor: "text-white border rounded-circle border-info",
                        checked: targetType === "price",
                        check: () => setTargetType("price"),
                        text: (
                          <>
                            <span className="d-inline-block min-w-100">at price</span>
                            <input
                              type="number"
                              className="d-inline-block btn-bottom-outline-info w-150 "
                              value={target}
                              placeholder="<Price>"
                              onChange={(e) => {
                                let value = Math.abs(parseFloat(e.target.value));
                                setTarget(!isNaN(value) ? value : "");
                                setTargetP("");
                                let price = orderType === "limit" ? limitPrice : marketPrice;
                                if (
                                  price &&
                                  ((props.direction === "Buy" && value > price) ||
                                    (props.direction === "Sell" && value < price))
                                ) {
                                  setTargetP(
                                    props.direction === "Buy"
                                      ? !isNaN(value)
                                        ? fixNumber(((value - price) / price) * 100, 2)
                                        : ""
                                      : !isNaN(value)
                                      ? fixNumber(((price - value) / price) * 100, 2)
                                      : ""
                                  );
                                } else {
                                  setTargetP("");
                                }
                              }}
                            />
                          </>
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            ) : (
              <div className="text-nowrap mt-4 mb-3">
                <a
                  href="#/"
                  className="btn btn-sm btn-info w-110"
                  onClick={(e) => {
                    e.preventDefault();
                    document.activeElement.blur();
                    setUseTarget(true);
                  }}
                >
                  Target
                  <FontAwesomeIcon className="ml-2" icon={faPlus} />
                </a>
              </div>
            )}
          </div>
        </div>
      }
      func={() => {
        let now = new Date().getTime();
        let manualTrade = {
          id: `${state.user.id}-${now}`,
          userId: state.user.id,
          pair: props.pair,
          pairDetails: props.pairDetails,
          exchange: props.exchange,
          direction: props.direction,
          baseSize: baseSize,
          quoteSize: quoteSize,
          sizeType: sizeType,
          orderType: orderType,
          price: orderType === "market" ? marketPrice : limitPrice,
          status: "NEW",
          exchangeId: "",
          creationDate: now,
          editDate: now,
        };
        if (useStoploss) {
          manualTrade.stoploss = stoploss;
          manualTrade.stoplossP = stoplossP;
          manualTrade.stoplossType = stoplossType;
        }
        if (useTarget) {
          manualTrade.target = target;
          manualTrade.targetP = targetP;
          manualTrade.targetType = targetType;
        }

        getExchange(props.exchange)
          .manualTrade(manualTrade)
          .then((result) => {
            if (result.error) {
              showInfoDialog(
                dispatch,
                <span className="text-danger">
                  <FontAwesomeIcon icon={faExclamationTriangle} /> Trade Failed
                </span>,
                <>{result.error}</>
              );
            } else {
              showInfoDialog(
                dispatch,
                <span className="text-info">
                  <FontAwesomeIcon icon={faInfoCircle} /> Order Completed
                </span>,
                <>
                  You {props.direction === "Buy" ? "bought" : "sold"}{" "}
                  <span className="text-info">{result.sizeInBase}</span> {props.pairDetails.baseAsset} at{" "}
                  <span className="text-info">{result.price ? result.price : manualTrade.price}</span>.
                </>
              );
            }
          });
      }}
    />
  );
};

const TradeResult = (props) => {
  const [tradeResult, setTradeResult] = useState("");
  const [prices, setPrices] = useState({});

  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getExchange(props.trade.exchange).subscribeToPricesUpdate(`res-${props.trade.id}`, (prices, pricesStats) => {
      if (componentIsMounted.current) {
        setPrices({
          prices: prices,
          pricesStats: pricesStats,
        });
      }
    });

    return () => {
      getExchange(props.trade.exchange).unsubscribeToPricesUpdate(`res-${props.trade.id}`);
    };
  }, [props.trade, props.trade.status]);

  useEffect(() => {
    let openTradeResult = "";
    let usdtValue = getUsdValue(props.trade.resultInQuoted, props.trade.pairDetails.quoteAsset, prices);
    if (prices && prices.pricesStats && prices.pricesStats[props.trade.pair]) {
      openTradeResult = (
        <span
          className={
            props.trade.resultInQuoted > 0 ? "text-success" : props.trade.resultInQuoted < 0 ? "text-danger" : ""
          }
        >
          {usdtValue !== "" ? `$${usdtValue}` : ""}
        </span>
      );
    }

    setTradeResult(openTradeResult);
  }, [props.trade, props.trade.status, props.trade.resultInQuoted, prices]);

  return tradeResult;
};

const OpenTrade = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const [openTradeResult, setOpenTradeResult] = useState("");
  const [currentPrice, setCurrentPrice] = useState("");
  const [prices, setPrices] = useState({});
  const [closing, setClosing] = useState(false);

  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getExchange(props.trade.exchange).subscribeToPricesUpdate(props.trade.id, (prices, pricesStats) => {
      if (componentIsMounted.current) {
        setPrices({
          prices: prices,
          pricesStats: pricesStats,
        });
      }
    });
    return () => {
      getExchange(props.trade.exchange).unsubscribeToPricesUpdate(props.trade.id);
    };
  }, [props.trade]);

  useEffect(() => {
    let openTradeResult = "";
    let currentPrice = "";
    if (closing) {
      currentPrice = (
        <div className="loading-white fade-in-fast" title="Loading..">
          <img src={loadingReverced} alt="" />
        </div>
      );
    } else if (
      prices &&
      prices.pricesStats &&
      prices.pricesStats[props.trade.pair] &&
      prices.pricesStats[props.trade.pair].bid &&
      prices.pricesStats[props.trade.pair].ask
    ) {
      currentPrice = (
        <>
          {roundQuotedValue(
            props.trade.direction === "Buy"
              ? prices.pricesStats[props.trade.pair].bid
              : prices.pricesStats[props.trade.pair].ask,
            props.trade.pairDetails
          )}
          <a
            href="#/"
            className="text-danger ml-2"
            title={props.trade.status !== "NEW" ? "Close Trade" : "Cancel Pending Order"}
            onClick={(e) => {
              e.preventDefault();
              document.activeElement.blur();

              showConfirmDialog(
                dispatch,
                <span className="text-info">
                  <FontAwesomeIcon icon={faQuestionCircle} />{" "}
                  {props.trade.status === "NEW" ? `Cancel Penging ${props.trade.direction}` : "Close trade"}
                </span>,
                <div>
                  Are you sure you want to{" "}
                  {props.trade.status === "NEW"
                    ? `cancel the pending ${props.trade.direction} order?`
                    : `close the trade. This will execute a market ${
                        props.trade.direction === "Buy" ? "sell" : "buy"
                      } order.`}
                </div>,
                () => {
                  if (!state.exchanges[props.trade.exchange].valid) {
                    showInfoDialog(
                      dispatch,
                      <span className="text-danger">
                        <FontAwesomeIcon icon={faExclamationTriangle} /> {props.trade.exchange} Error
                      </span>,
                      <>Invalid API-key or your IP address is not in the trusted IPs list on Binance.</>
                    );
                    return;
                  }
                  setClosing(true);
                  getExchange(props.trade.exchange)
                    .closeManualTrade(props.trade)
                    .then((result) => {
                      setClosing(false);

                      if (result.error) {
                        showInfoDialog(
                          dispatch,
                          <span className="text-danger">
                            <FontAwesomeIcon icon={faExclamationTriangle} /> Trade Failed
                          </span>,
                          <>{result.error}</>
                        );
                      } else if (result.canceled) {
                        showInfoDialog(
                          dispatch,
                          <span className="text-info">
                            <FontAwesomeIcon icon={faInfoCircle} /> Cancel Pending Order
                          </span>,
                          <>You canceled the {props.trade.pair} pending order.</>
                        );
                      } else {
                        showInfoDialog(
                          dispatch,
                          <span className="text-info">
                            <FontAwesomeIcon icon={faInfoCircle} /> Order Completed
                          </span>,
                          <>
                            You {props.direction === "Buy" ? "bought" : "sold"} {result.sizeInBase}{" "}
                            {props.trade.pairDetails.baseAsset} at {result.price ? result.price : props.trade.price}.
                            <br />
                            Trade details are available at the history tab.
                          </>
                        );
                      }
                    });
                }
              );
            }}
          >
            <FontAwesomeIcon icon={faBan} className="" />
          </a>
        </>
      );
    }

    if (prices && prices.pricesStats && prices.pricesStats[props.trade.pair]) {
      let tradePrice = props.trade.o ? props.trade.o : props.trade.price;
      let commissionInQuoted = !props.trade.oComm
        ? 0
        : (props.trade.pairDetails.quoteAsset === "BNB"
            ? props.trade.oComm.bnb
            : getQuotedValue(props.trade.oComm.bnb, "BNB", props.trade.pairDetails.quoteAsset, prices.prices)) +
          props.trade.oComm.base * props.trade.o +
          props.trade.oComm.quote;

      let commissionRate = commissionInQuoted ? commissionInQuoted / props.trade.oSizeInQuoted : 0;
      let commissionPercent = commissionRate * 100;
      let percent =
        props.trade.direction === "Buy"
          ? ((prices.pricesStats[props.trade.pair].bid - tradePrice) / tradePrice) * 100 -
            (commissionPercent ? commissionPercent * 2 : 0)
          : ((tradePrice - prices.pricesStats[props.trade.pair].ask) / tradePrice) * 100 -
            (commissionPercent ? commissionPercent * 2 : 0);

      if (props.trade.status !== "NEW" && !isNaN(percent)) {
        openTradeResult = (
          <span className={percent > 0 ? "text-success" : percent < 0 ? "text-danger" : ""}>
            &nbsp;{percent.toFixed(2)}%
          </span>
        );
      }
    }

    setCurrentPrice(currentPrice);
    setOpenTradeResult(openTradeResult);
  }, [props.trade, prices, closing]);

  return (
    <>
      <td className="text-left text-nowrap">{currentPrice}</td>
      <td className="text-left text-nowrap">{openTradeResult}</td>
    </>
  );
};

const ManualTrading = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const [exchange, setExchange] = useState(exchanges[0]);
  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");
  const [pairs, setPairs] = useState([]);
  const [pairsDetails, setPairsDetails] = useState({});
  const [orderDirection, setOrderDirection] = useState("");
  const componentIsMounted = useRef(true);
  const [selectedPair, setSelectedPair] = useState("");
  const [selectedPairDetails, setSelectedPairDetails] = useState("");
  const [openTrades, setOpenTrades] = useState([]);
  const [closeTrades, setCloseTrades] = useState([]);
  const [tradeToEdit, setTradeToEdit] = useState(null);
  const [openTradesOrder, setOpenTradesOrder] = useState(getFromLocalStorage("openTradesOrder", "date"));
  const [closeTradesOrder, setCloseTradesOrder] = useState(getFromLocalStorage("closeTradesOrder", "date"));
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [pageCloseTrades, setPageCloseTrades] = useState(0);
  const [pagesCloseTrades, setPagesCloseTrades] = useState([]);
  const [openTradesOnPage, setOpenTradesOnPage] = useState(getFromLocalStorage("openTradesOnPage", 10, "number", true));
  const [closeTradesOnPage, setCloseTradesOnPage] = useState(
    getFromLocalStorage("closeTradesOnPage", 10, "number", true)
  );
  const [manualTradesTab, setManualTradesTab] = useState(getFromLocalStorage("manualTradesTab", "open"));

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let keys = Object.keys(state.exchanges);
    if (!props.selectedAccount && keys[0]) {
      props.setSelectedAccount(keys[0]);
    }

    if (props.tradingPair) {
      let [exchange, pairTmp] = props.tradingPair.split("-");
      exchange = exchange === "BINANCE" ? "Binance" : "Binance US";
      if (props.selectedAccount === exchange) {
        setSelectedPair(pairTmp);
      }
    }
  }, [state.exchanges, props.tradingPair, props.selectedAccount]);

  useEffect(() => {
    if (props.selectedAccount) {
      getExchange(props.selectedAccount)
        .getPairs()
        .then((pairs) => {
          if (componentIsMounted.current && pairs) {
            setPairs(pairs[0]);
            setPairsDetails(pairs[1]);
          }
        });
    }
  }, [props.selectedAccount]);

  useEffect(() => {
    if (selectedPair && pairsDetails) {
      setSelectedPairDetails(pairsDetails[selectedPair]);
    }
  }, [selectedPair, pairsDetails]);

  useEffect(() => {
    if (props.selectedAccount) {
      getExchange(props.selectedAccount).subscribeToManualTrades("manualTrading", (trades, closeTrade) => {
        if (componentIsMounted.current) {
          let newTrades = [...trades];
          newTrades.sort((a, b) => {
            switch (openTradesOrder) {
              case "date":
                return b.creationDate - a.creationDate;
              case "pair":
                return a.pair.localeCompare(b.pair);
              default:
                return b.creationDate - a.creationDate;
            }
          });
          setOpenTrades(newTrades);

          if (closeTrade && manualTradesTab === "close") {
            getEcbDatabase()
              .getManualCloseTrades(state.user.id)
              .then((trades) => {
                if (componentIsMounted.current) {
                  //setCloseTrades(trades);
                  let newClosedTrades = sortClosedTrades(trades);
                  setCloseTrades(newClosedTrades);
                }
              });
          }
        }
      });
      return () => {
        getExchange(props.selectedAccount).unSubscribeToManualTrades("manualTrading");
      };
    }
  }, [props.selectedAccount]);

  useEffect(() => {
    let newTrades = [...openTrades];
    newTrades.sort((a, b) => {
      switch (openTradesOrder) {
        case "date":
          return b.creationDate - a.creationDate;
        case "dateR":
          return a.creationDate - b.creationDate;
        case "pair":
          return a.pair.localeCompare(b.pair);
        case "pairR":
          return b.pair.localeCompare(a.pair);
        default:
          return b.creationDate - a.creationDate;
      }
    });
    setOpenTrades(newTrades);
  }, [openTradesOrder]);

  useEffect(() => {
    setPage(0);
    let pages = [];
    if (openTradesOnPage) {
      for (let i = 0; i < openTrades.length / openTradesOnPage; i++) {
        pages.push(i);
      }
    }
    setPages(pages);
  }, [openTrades, openTradesOnPage]);

  useEffect(() => {
    if (manualTradesTab === "close") {
      getEcbDatabase()
        .getManualCloseTrades(state.user.id)
        .then((trades) => {
          if (componentIsMounted.current) {
            //setCloseTrades(trades);
            let newClosedTrades = sortClosedTrades(trades);
            setCloseTrades(newClosedTrades);
          }
        });
    }
  }, [manualTradesTab]);

  const sortClosedTrades = useCallback(
    (trades) => {
      let newTrades = [...trades];
      newTrades.sort((a, b) => {
        switch (closeTradesOrder) {
          case "date":
            return b.creationDate - a.creationDate;
          case "dateR":
            return a.creationDate - b.creationDate;
          case "dateC":
            return b.cd - a.cd;
          case "dateCR":
            return a.cd - b.cd;
          case "resP":
            return b.result - a.result;
          case "resPR":
            return a.result - b.result;
          case "pair":
            return a.pair.localeCompare(b.pair);
          case "pairR":
            return b.pair.localeCompare(a.pair);
          default:
            return b.creationDate - a.creationDate;
        }
      });
      return newTrades;
    },
    [closeTradesOrder]
  );
  useEffect(() => {
    let newClosedTrades = sortClosedTrades(closeTrades);
    setCloseTrades(newClosedTrades);
  }, [closeTradesOrder]);

  useEffect(() => {
    setPage(0);
    let pages = [];
    if (closeTradesOnPage) {
      for (let i = 0; i < closeTrades.length / closeTradesOnPage; i++) {
        pages.push(i);
      }
    }
    setPagesCloseTrades(pages);
  }, [closeTrades, closeTradesOnPage]);

  return (
    <div>
      <h4 className="text-secondary mt-3">Manual Trading</h4>
      {!state.app || state.demo ? (
        <h5 className="text-secondary mt-3">Manual trading is available in the desktop app</h5>
      ) : (
        <div className="text-left min-h-220">
          <Dropdown
            btnClasses="text-light hover-border-bottom info pl-0 pb-2 mb-2"
            arrow
            arrowClasses="mt-1"
            selectedClasses="text-truncate max-w-270 mr-1"
            menuClasses="bg-new-dark border border-secondary rounded text-left small max-h-150 w-150 py-1"
            menuItemsClasses="text-light hover-info px-3 py-1"
            selected={props.selectedAccount ? props.selectedAccount : "Add account to trade"}
            setSelected={(exchange) => {
              props.setSelectedAccount(exchange);
            }}
            items={Object.keys(state.exchanges)}
            additionalOptions={[
              {
                label: (
                  <div className="d-flex">
                    Add account
                    <FontAwesomeIcon className="ml-auto mt-1" icon={faPlus} />
                  </div>
                ),
                classes: "text-info border-bottom border-secondary px-3 pt-1 pb-2 mb-1",
                fn: () => {
                  setExchange(exchanges[0]);
                  setApiKey("");
                  setApiSecret("");
                  if (!$("#addApiKeys").is(":visible")) {
                    $("#addApiKeys").modal("toggle");
                  }
                },
              },
            ]}
          />
          {state.exchanges[props.selectedAccount] && !state.exchanges[props.selectedAccount].valid && (
            <div className="text-secondary mb-3">
              Invalid API-key or your IP address is not in the trusted IPs list on Binance
            </div>
          )}
          {props.selectedAccount &&
            state.exchanges[props.selectedAccount] &&
            state.exchanges[props.selectedAccount].valid && (
              <div className="d-flex mb-3">
                <div className="text-nowrap">
                  <FontAwesomeIcon className="text-info mr-2" icon={faSearch} />
                  <DropdownWithSearch
                    classesInput="search-bar text-left h-33 pb-2 px-2 w-150 mr-3"
                    dropMenuClasses={`bg-new-dark text-left border border-secondary rounded max-h-150 w-150 small left-0`}
                    dropMenuItemsClasses="text-light items-info"
                    placeholder="Select pair"
                    selected={selectedPair}
                    setSelected={(pair) => {
                      props.setTradingPair(`${props.selectedAccount.toUpperCase().replace(" ", "")}-${pair}`);
                    }}
                    showOnFocus={true}
                    closeOnEsc={true}
                    items={pairs}
                  />
                </div>

                <div className="text-nowrap">
                  <a
                    href="#/"
                    className="btn btn-sm btn-info w-80 mr-3"
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      if (selectedPair) {
                        setOrderDirection("Buy");
                        if (!$("#manualOrder").is(":visible")) {
                          $("#manualOrder").modal("toggle");
                        }
                      } else {
                        showInfoDialog(
                          dispatch,
                          <div className="text-info">
                            <FontAwesomeIcon icon={faInfoCircle} /> No Pair
                          </div>,
                          <div>Select pair first!</div>
                        );
                      }
                    }}
                  >
                    Buy
                  </a>
                  <a
                    href="#/"
                    className="btn btn-sm btn-info w-80"
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      if (selectedPair) {
                        setOrderDirection("Sell");
                        if (!$("#manualOrder").is(":visible")) {
                          $("#manualOrder").modal("toggle");
                        }
                      } else {
                        showInfoDialog(
                          dispatch,
                          <div className="text-info">
                            <FontAwesomeIcon icon={faInfoCircle} /> No Pair
                          </div>,
                          <div>Select pair first!</div>
                        );
                      }
                    }}
                  >
                    Sell
                  </a>
                </div>
              </div>
            )}
          <ul className="nav nav-tabs hover-white user-select-none mb-3" role="tablist">
            <li className="nav-item">
              <div
                className={`nav-link text-info hover-light cursor-pointer ${
                  manualTradesTab === "open" ? "active" : "hover-noborder"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  localStorage.setItem("manualTradesTab", "open");
                  setManualTradesTab("open");
                }}
              >
                Open Trades
              </div>
            </li>
            <li className="nav-item">
              <div
                className={`nav-link text-info hover-light cursor-pointer ${
                  manualTradesTab === "close" ? "active" : "hover-noborder"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  localStorage.setItem("manualTradesTab", "close");
                  setManualTradesTab("close");
                }}
              >
                History
              </div>
            </li>
            <li className="ml-auto pt-2">
              <span className="small mr-2">Show:</span>
              <div className="d-inline-block input-group-sm">
                <input
                  type="number"
                  className="search-bar text-center small w-35"
                  value={manualTradesTab === "open" ? openTradesOnPage : closeTradesOnPage}
                  placeholder="All"
                  onChange={(e) => {
                    let value = Math.abs(Number.parseInt(e.target.value));
                    let tradesOnPage = !isNaN(value) && value > 0 ? value : "";
                    if (manualTradesTab === "open") {
                      setOpenTradesOnPage(tradesOnPage);
                      localStorage.setItem("openTradesOnPage", tradesOnPage);
                    } else {
                      setCloseTradesOnPage(tradesOnPage);
                      localStorage.setItem("closeTradesOnPage", tradesOnPage);
                    }
                  }}
                ></input>
              </div>
            </li>
          </ul>

          {manualTradesTab === "open" && (
            <>
              {openTrades.length === 0 ? (
                <h5 className="text-secondary mt-3">No trades</h5>
              ) : (
                <>
                  <div className="table-responsive overflow-auto border border-dark rounded user-select-none max-h-fullscreen mb-3">
                    <table className="table table-sm table-new-dark rounded sortable-table small mb-0 ">
                      <thead className="thead-new-dark">
                        <tr className="user-select-none">
                          <th
                            className="text-left text-nowrap pl-3"
                            onClick={() => {
                              let newSort = openTradesOrder !== "date" ? "date" : "dateR";
                              localStorage.setItem("openTradesOrder", newSort);
                              setOpenTradesOrder(newSort);
                            }}
                          >
                            Date
                            {(openTradesOrder === "date" || openTradesOrder === "dateR") && (
                              <FontAwesomeIcon className="ml-2" icon={faArrowsAltV} />
                            )}
                          </th>
                          <th
                            className="text-left"
                            onClick={() => {
                              let newSort = openTradesOrder !== "pair" ? "pair" : "pairR";
                              localStorage.setItem("openTradesOrder", newSort);
                              setOpenTradesOrder(newSort);
                            }}
                          >
                            Pair
                            {(openTradesOrder === "pair" || openTradesOrder === "pairR") && (
                              <FontAwesomeIcon className="ml-2" icon={faArrowsAltV} />
                            )}
                          </th>
                          <th className="text-left">Direction</th>
                          <th className="text-left">Size</th>
                          <th className="text-left">Open</th>
                          <th className="text-left">Price</th>
                          <th className="text-left">P/L</th>
                          <th className="text-left">Stoploss</th>
                          <th className="text-left">Target</th>
                          <th className="text-left">Status</th>
                          <th className="text-center">Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {openTrades
                          .filter((el, index) => {
                            if (openTradesOnPage) {
                              return (
                                index >= page * openTradesOnPage && index < page * openTradesOnPage + openTradesOnPage
                              );
                            } else {
                              return true;
                            }
                          })
                          .map((trade) => {
                            return (
                              <tr key={trade.id}>
                                <td className="text-left pl-3">{formatDateTime3(new Date(trade.creationDate))}</td>
                                <td className="text-left">{trade.pair}</td>
                                <td className="text-left">{trade.direction}</td>
                                <td className="text-left">
                                  {trade.sizeType === "base" ? (
                                    <>
                                      {roundBaseValue(trade.baseSize, trade.pairDetails)} {trade.pairDetails.baseAsset}
                                    </>
                                  ) : (
                                    <>
                                      {roundQuotedValue(trade.quoteSize, trade.pairDetails)}{" "}
                                      {trade.pairDetails.quoteAsset}
                                    </>
                                  )}
                                </td>
                                <td className="text-left">
                                  {roundQuotedValue(trade.o ? trade.o : trade.price, trade.pairDetails)}
                                  <TradeDetails
                                    status={trade.status}
                                    exchId={trade.oExId}
                                    fills={trade.oFills}
                                    pairDetails={trade.pairDetails}
                                    type={trade.direction === "Buy" ? "BUY" : "SELL"}
                                    size={trade.oSizeInBase}
                                    avPrice={trade.o ? trade.o : trade.price}
                                  />
                                </td>
                                <OpenTrade trade={trade} />
                                <td className="text-left text-nowrap">
                                  {trade.stoploss ? (
                                    <>
                                      {roundQuotedValue(trade.stoploss, trade.pairDetails)}
                                      <a
                                        href="#/"
                                        className="text-info ml-2"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          document.activeElement.blur();
                                          setTradeToEdit(trade);
                                          if (!$("#manualOrderEditStoploss").is(":visible")) {
                                            $("#manualOrderEditStoploss").modal("toggle");
                                          }
                                        }}
                                      >
                                        <FontAwesomeIcon className="" icon={faEdit} />
                                      </a>
                                    </>
                                  ) : (
                                    <a
                                      href="#/"
                                      className="text-info"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        document.activeElement.blur();
                                        setTradeToEdit(trade);
                                        if (!$("#manualOrderEditStoploss").is(":visible")) {
                                          $("#manualOrderEditStoploss").modal("toggle");
                                        }
                                      }}
                                    >
                                      <FontAwesomeIcon className="" icon={faPlus} />
                                    </a>
                                  )}
                                </td>
                                <td className="text-left text-nowrap">
                                  {trade.target ? (
                                    <>
                                      {roundQuotedValue(trade.target, trade.pairDetails)}
                                      <a
                                        href="#/"
                                        className="text-info ml-2"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          document.activeElement.blur();
                                          setTradeToEdit(trade);
                                          if (!$("#manualOrderEditTarget").is(":visible")) {
                                            $("#manualOrderEditTarget").modal("toggle");
                                          }
                                        }}
                                      >
                                        <FontAwesomeIcon className="" icon={faEdit} />
                                      </a>
                                    </>
                                  ) : (
                                    <a
                                      href="#/"
                                      className="text-info"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        document.activeElement.blur();
                                        setTradeToEdit(trade);
                                        if (!$("#manualOrderEditTarget").is(":visible")) {
                                          $("#manualOrderEditTarget").modal("toggle");
                                        }
                                      }}
                                    >
                                      <FontAwesomeIcon className="" icon={faPlus} />
                                    </a>
                                  )}
                                </td>
                                <td className="text-left text-nowrap">
                                  {trade.status === "NEW" ? "PENDING LIMIT" : trade.status}
                                  {trade.cError && (
                                    <a
                                      href="#/"
                                      className="text-warning blink ml-2"
                                      title="Error"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        document.activeElement.blur();
                                        showInfoDialog(
                                          dispatch,
                                          <span className="text-warning">
                                            <FontAwesomeIcon icon={faExclamationTriangle} /> Error closing the trade
                                          </span>,
                                          <>{trade.cError}</>
                                        );
                                      }}
                                    >
                                      <FontAwesomeIcon className="" icon={faExclamationTriangle} />
                                    </a>
                                  )}
                                </td>
                                <td className="text-center">
                                  <a
                                    href="#/"
                                    className="text-danger"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      document.activeElement.blur();
                                      showConfirmDialog(
                                        dispatch,
                                        <span className="text-info">
                                          <FontAwesomeIcon icon={faQuestionCircle} /> Remote Trade
                                        </span>,
                                        <>Do you want to remove the {trade.pair} trade?</>,
                                        () => {
                                          getExchange(props.selectedAccount).removeManualTrade(trade.id);
                                        }
                                      );
                                    }}
                                  >
                                    <FontAwesomeIcon className="" icon={faTimesCircle} />
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  {pages.length > 0 && (
                    <div className="overflow-auto">
                      <nav aria-label="page navigation">
                        <ul className="pagination">
                          <li className="page-item" key="prev">
                            <a
                              className="page-link small px-2 py-1"
                              aria-label="Previous"
                              href="#/"
                              onClick={(e) => {
                                e.preventDefault();
                                if (page > 0) {
                                  setPage(page - 1);
                                }
                              }}
                            >
                              <span aria-hidden="true">&laquo;</span>
                              <span className="sr-only">Previous</span>
                            </a>
                          </li>
                          {pages.map((el) => {
                            return (
                              <li className={"page-item " + (page === el ? "active" : "")} key={el}>
                                <a
                                  className="page-link small px-2 py-1"
                                  href="#/"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setPage(el);
                                  }}
                                >
                                  {el + 1}
                                </a>
                              </li>
                            );
                          })}

                          <li className="page-item" key="next">
                            <a
                              className="page-link small px-2 py-1"
                              href="#/"
                              aria-label="Next"
                              onClick={(e) => {
                                e.preventDefault();
                                if (page < Math.floor(openTrades.length / openTradesOnPage)) {
                                  setPage(page + 1);
                                }
                              }}
                            >
                              <span aria-hidden="true">&raquo;</span>
                              <span className="sr-only">Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  )}
                  <div className="text-secondary small text-center">Trading fees are included in the results</div>
                </>
              )}
            </>
          )}
          {manualTradesTab === "close" && (
            <>
              {closeTrades.length === 0 ? (
                <h5 className="text-secondary mt-3">No trades</h5>
              ) : (
                <>
                  <div className="table-responsive overflow-auto border border-dark rounded user-select-none max-h-fullscreen mb-3">
                    <table className="table table-sm table-new-dark rounded sortable-table small mb-0 ">
                      <thead className="thead-new-dark">
                        <tr className="user-select-none">
                          <th
                            className="text-left text-nowrap pl-3"
                            onClick={() => {
                              let newSort = closeTradesOrder !== "date" ? "date" : "dateR";
                              localStorage.setItem("closeTradesOrder", newSort);
                              setCloseTradesOrder(newSort);
                            }}
                          >
                            Open Date
                            {(closeTradesOrder === "date" || closeTradesOrder === "dateR") && (
                              <FontAwesomeIcon className="ml-2" icon={faArrowsAltV} />
                            )}
                          </th>
                          <th
                            className="text-left text-nowrap pl-3"
                            onClick={() => {
                              let newSort = closeTradesOrder !== "dateC" ? "dateC" : "dateCR";
                              localStorage.setItem("closeTradesOrder", newSort);
                              setCloseTradesOrder(newSort);
                            }}
                          >
                            Close Date
                            {(closeTradesOrder === "dateC" || closeTradesOrder === "dateCR") && (
                              <FontAwesomeIcon className="ml-2" icon={faArrowsAltV} />
                            )}
                          </th>
                          <th
                            className="text-left"
                            onClick={() => {
                              let newSort = closeTradesOrder !== "pair" ? "pair" : "pairR";
                              localStorage.setItem("closeTradesOrder", newSort);
                              setCloseTradesOrder(newSort);
                            }}
                          >
                            Pair
                            {(closeTradesOrder === "pair" || closeTradesOrder === "pairR") && (
                              <FontAwesomeIcon className="ml-2" icon={faArrowsAltV} />
                            )}
                          </th>
                          <th className="text-left">Direction</th>
                          <th className="text-left">Size</th>
                          <th className="text-left">Open</th>
                          <th className="text-left">Close</th>
                          <th
                            className="text-left text-nowrap"
                            onClick={() => {
                              let newSort = closeTradesOrder !== "resP" ? "resP" : "resPR";
                              localStorage.setItem("closeTradesOrder", newSort);
                              setCloseTradesOrder(newSort);
                            }}
                          >
                            Result %
                            {(closeTradesOrder === "resP" || closeTradesOrder === "resPR") && (
                              <FontAwesomeIcon className="ml-2" icon={faArrowsAltV} />
                            )}
                          </th>
                          <th className="text-left text-nowrap">Result $</th>
                          <th className="text-left">Status</th>
                          <th className="text-center">Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {closeTrades
                          .filter((el, index) => {
                            if (closeTradesOnPage) {
                              return (
                                index >= pageCloseTrades * closeTradesOnPage &&
                                index < pageCloseTrades * closeTradesOnPage + closeTradesOnPage
                              );
                            } else {
                              return true;
                            }
                          })
                          .map((trade) => {
                            return (
                              <tr key={trade.id}>
                                <td className="text-left pl-3">{formatDateTime3(new Date(trade.creationDate))}</td>
                                <td className="text-left pl-3">{formatDateTime3(new Date(trade.cd))}</td>
                                <td className="text-left">{trade.pair}</td>
                                <td className="text-left">{trade.direction}</td>
                                <td className="text-left">
                                  {trade.sizeType === "base" ? (
                                    <>
                                      {roundBaseValue(trade.baseSize, trade.pairDetails)} {trade.pairDetails.baseAsset}
                                    </>
                                  ) : (
                                    <>
                                      {roundQuotedValue(trade.quoteSize, trade.pairDetails)}{" "}
                                      {trade.pairDetails.quoteAsset}
                                    </>
                                  )}
                                </td>
                                <td className="text-left">
                                  {roundQuotedValue(trade.o ? trade.o : trade.price, trade.pairDetails)}
                                  <TradeDetails
                                    status={trade.status}
                                    exchId={trade.oExId}
                                    fills={trade.oFills}
                                    pairDetails={trade.pairDetails}
                                    type={trade.direction === "Buy" ? "BUY" : "SELL"}
                                    size={trade.oSizeInBase}
                                    avPrice={trade.o}
                                    comm={trade.oComm}
                                  />
                                </td>
                                <td className="text-left text-nowrap">
                                  {trade.c && (
                                    <>
                                      {roundQuotedValue(trade.c, trade.pairDetails)}
                                      <TradeDetails
                                        status={trade.cStatus}
                                        exchId={trade.cExId}
                                        fills={trade.cFills}
                                        pairDetails={trade.pairDetails}
                                        type={trade.direction === "Buy" ? "SELL" : "BUY"}
                                        size={trade.cSizeInBase}
                                        avPrice={trade.c}
                                        tradeDetails={trade.tradeDetails}
                                        comm={trade.cComm}
                                      />
                                    </>
                                  )}
                                </td>
                                <td
                                  className={`text-left text-nowrap ${
                                    trade.result > 0 ? "text-success" : trade.result < 0 ? "text-danger" : ""
                                  }`}
                                >
                                  {trade.result !== undefined && <>{trade.result.toFixed(2)}%</>}
                                </td>
                                <td className="text-left text-nowrap">
                                  <TradeResult trade={trade} />
                                </td>
                                <td className="text-left text-nowrap">{trade.status}</td>
                                <td className="text-center">
                                  <a
                                    href="#/"
                                    className="text-danger"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      document.activeElement.blur();
                                      showConfirmDialog(
                                        dispatch,
                                        <span className="text-info">
                                          <FontAwesomeIcon icon={faQuestionCircle} /> Remote Trade
                                        </span>,
                                        <>Do you want to remove the {trade.pair} trade?</>,
                                        () => {
                                          getEcbDatabase().deleteManualCloseTrade(trade.id);
                                          let newCloseTrades = closeTrades.filter((el) => el.id !== trade.id);
                                          //setCloseTrades(newCloseTrades);
                                          let newClosedTrades = sortClosedTrades(newCloseTrades);
                                          setCloseTrades(newClosedTrades);
                                        }
                                      );
                                    }}
                                  >
                                    <FontAwesomeIcon className="" icon={faTimesCircle} />
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  {pagesCloseTrades.length > 0 && (
                    <div className="overflow-auto">
                      <nav aria-label="page navigation">
                        <ul className="pagination">
                          <li className="page-item" key="prev">
                            <a
                              className="page-link small px-2 py-1"
                              aria-label="Previous"
                              href="#/"
                              onClick={(e) => {
                                e.preventDefault();
                                if (pageCloseTrades > 0) {
                                  setPageCloseTrades(page - 1);
                                }
                              }}
                            >
                              <span aria-hidden="true">&laquo;</span>
                              <span className="sr-only">Previous</span>
                            </a>
                          </li>
                          {pagesCloseTrades.map((el) => {
                            return (
                              <li className={"page-item " + (pageCloseTrades === el ? "active" : "")} key={el}>
                                <a
                                  className="page-link small px-2 py-1"
                                  href="#/"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setPage(el);
                                  }}
                                >
                                  {el + 1}
                                </a>
                              </li>
                            );
                          })}

                          <li className="page-item" key="next">
                            <a
                              className="page-link small px-2 py-1"
                              href="#/"
                              aria-label="Next"
                              onClick={(e) => {
                                e.preventDefault();
                                if (pageCloseTrades < Math.floor(closeTrades.length / closeTradesOnPage)) {
                                  setPageCloseTrades(pageCloseTrades + 1);
                                }
                              }}
                            >
                              <span aria-hidden="true">&raquo;</span>
                              <span className="sr-only">Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  )}
                  <div className="text-secondary small text-center">Trading fees are included in the results</div>
                </>
              )}
            </>
          )}
        </div>
      )}
      <ManualOrder
        exchange={props.selectedAccount}
        pair={selectedPair}
        pairDetails={selectedPairDetails}
        direction={orderDirection}
      />

      <EditStoploss trade={tradeToEdit} />
      <EditTarget trade={tradeToEdit} />

      <AddExchangeModal
        apiKey={apiKey}
        setApiKey={setApiKey}
        apiSecret={apiSecret}
        setApiSecret={setApiSecret}
        exchange={exchange}
        setExchange={setExchange}
        editExchange={false}
        fn={(exchange) => {
          props.setSelectedAccount(exchange);
        }}
      />
    </div>
  );
};

export default ManualTrading;
