import React, { useState, useEffect, useContext } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Redirect, Switch, useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Navigation } from "./Navigation.js";
import { SignInLoading } from "./SignIn.js";
import {
  verifyUser,
  rr,
  pairsDetailsAutoSync,
  autoSync,
  checkForNewVersion,
  getUserData,
  getUsers,
  autoSyncHomePage,
  autoSyncStrategyGroups,
  getWatchlists,
  getMultiCharts,
  getStrategyGroups,
  autoAppSync,
  showInfoDialog,
  decrypt,
  encrypt,
  getStrategiesTraficSaver,
  checkPLUpdateNeeded,
} from "./utils.js";
import { globalContext } from "./Context.js";
import { GlobalContextProvider } from "./ContextProvider.js";
import { Modal, ModalConfirm } from "./Modal.js";
import { initializeExchanges, checkCORSEnabled } from "./Exchange.js";
import { getRoutes, formatDateTime, autoUpdateExecutionStatus } from "./utils.js";
import { initializeTradingWorkers } from "./Bot.js";
import Contest from "./Contest.js";
import $ from "jquery";
import logoReverced from "./assets/images/logo-reverced.png";
import { faExclamationTriangle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getExchange } from "./Exchange";

const Meta = (props) => {
  return "";
};

const ScrollToTop = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.path]);

  return "";
};

function closeModals(history, pathName) {
  let isModalShown = false;
  $(".modal").each(function () {
    if ($(this).is(":visible")) {
      $(this).modal("hide");
      isModalShown = true;
    }
  });

  if (isModalShown) {
    history.push(pathName);
  }
  return isModalShown;
}

const BackButtonListener = (props) => {
  let [pathName, setPathName] = useState(window.location.pathname);
  let history = useHistory();

  useEffect(() => {
    window.addEventListener(
      "popstate",
      (e) => {
        if (closeModals(history, pathName)) {
          history.push(pathName);
        } else {
          setPathName(window.location.pathname);
        }

        $(".modal-backdrop").each(function () {
          $(this).remove();
        });
      },
      false
    );
  }, []);

  return "";
};

async function loginSuccess(state, dispatch, user, setUserIsSignedIn, token) {
  if (!state.app && !state.demo) {
    let useInBrowser = await checkCORSEnabled();
    if (useInBrowser) {
      state.app = true;
      dispatch({
        type: "setIsApp",
        payload: {},
      });
    }
  }
  initializeExchanges(state.app, state.demo, true, user.id);

  if (state.app) {
    let locked = localStorage.getItem("locked");
    let lockedState = localStorage.getItem("lockedState");
    if (!locked || !lockedState) {
      dispatch({ type: "removeToken", payload: null });
      setUserIsSignedIn(0);
      return;
    }
    try {
      lockedState = decrypt(lockedState, state.user.token);
    } catch (e) {
      lockedState = null;
    }
    if (lockedState) lockedState = encrypt(lockedState, token);
    localStorage.setItem("lockedState", lockedState);
  }

  user.isAdmin = user.email === "admin@easycryptobot.com";
  user.isContestRunner = user.email === "contest@easycryptobot.com";

  setUserIsSignedIn(1);

  let userData = { strategies: [], executions: [] };
  if (!state.demo && !user.isAdmin) {
    userData = await getUserData(user, dispatch, token, state.app);
    autoSyncHomePage(token, user.id);
    getWatchlists(token, dispatch, user.id);
    getStrategyGroups(token, dispatch, user.id);
    getMultiCharts(token, dispatch, user.id);
    autoSyncStrategyGroups(token, user.id);
  }

  if (state.app && !state.demo && !user.isAdmin) {
    let appId = localStorage.getItem("appId");
    if (!appId) {
      appId = `${Math.floor(Math.random() * 99 + 100)}${new Date().getTime()}`;
      localStorage.setItem("appId", appId);
    }
    autoAppSync(token, dispatch, appId);

    /*let utilsVersion = localStorage.getItem("uV");
    if (!utilsVersion) {
      localStorage.setItem("uV", 1);
      let apiKeys = await rrOld(dispatch, user.id, user.email);

      for (let exchange in apiKeys) {
        deleteApiKey(exchange, user.id, user.email);
        ss({
          user: user.id,
          exchange: exchange,
          key: apiKeys[exchange].key,
          secret: apiKeys[exchange].secret,
        });
      }
    }*/
    let apiKeys = await rr(dispatch, user.id, user.email);

    pairsDetailsAutoSync(dispatch);
    await initializeTradingWorkers(dispatch, apiKeys, userData, user.id, token, user.isExpired);
    autoSync(token, user);
    checkPLUpdateNeeded(token, user.id)
  }

  if (!state.app && !state.demo && !user.isAdmin) {
    //TODO
    autoUpdateExecutionStatus(token, user.id, dispatch, user.isAdmin);
  }
  if (user.isAdmin) {
    getUsers(token, dispatch);
  }

  checkForNewVersion(dispatch, state.version, token);

  if (navigator.appVersion && navigator.appVersion.indexOf("easy-crypto-bot/2.0.0") !== -1) {
    showInfoDialog(
      dispatch,
      <span className="text-info">
        <FontAwesomeIcon icon={faInfoCircle} /> New Desktop App Update
      </span>,
      <div>
        You can download the new version of the desktop ECB app from{" "}
        <a
          href="https://easycryptobot.com/download-app"
          rel="noopener noreferrer"
          target="_blank"
          className="text-info"
          onClick={(e) => {
            document.activeElement.blur();
          }}
        >
          here
        </a>
        <br />
      </div>
    );
  }
  let oom = localStorage.getItem("oom");
  if (oom) {
    localStorage.removeItem("oom");
    showInfoDialog(
      dispatch,
      <span className="text-danger">
        <FontAwesomeIcon icon={faExclamationTriangle} /> App restarted
      </span>,
      <div>
        Your computer ran out of memory.
        <div className="mt-2">
          Possible reasons:
          <ul>
            <li>running too long-term backtest / optimizations</li>
            <li>too many simultaneously running strategies</li>
            <li>you have too many open applications / browser tabs</li>
          </ul>
        </div>
        To use EasyCryptoBot without interruption, make sure you have at least 4 GB of free RAM
      </div>
    );
  }
}

const MainPage = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const [userIsSignedIn, setUserIsSignedIn] = useState(state.user.token ? 2 : 0);
  const [routes, setRoutes] = useState([]);
  const [smallWidthDevice, setSmallWidthDevice] = useState(null);

  useEffect(() => {
    let urlSearch = window.location.search;
    let oom = "?oom";
    if (urlSearch.indexOf(oom) !== -1) {
      localStorage.setItem("oom", 1);
      let url = window.location.href.replace("?oom", "");
      window.location.assign(url);
    }
  }, []);

  useEffect(() => {
    if (navigator.appVersion && navigator.appVersion.indexOf("easy-crypto-bot/2.0.0") !== -1) {
      showInfoDialog(
        dispatch,
        <span className="text-info">
          <FontAwesomeIcon icon={faInfoCircle} /> New Desktop App Update
        </span>,
        <div>
          You can download the new version of the desktop ECB app from{" "}
          <a
            href="https://easycryptobot.com/download-app"
            rel="noopener noreferrer"
            target="_blank"
            className="text-info"
            onClick={(e) => {
              document.activeElement.blur();
            }}
          >
            here
          </a>
          <br />
        </div>
      );
    }
  }, []);

  useEffect(() => {
    let root = document.getElementById("root");
    if (root) {
      let smallDevice = +root.clientWidth < 768;
      setSmallWidthDevice(smallDevice);
    }
    dispatch({
      type: "login",
      payload: () => {
        setUserIsSignedIn(1);
      },
    });

    if (state.user.token) {
      const noAuthPaths = ["free-backtest", "demo"];
      if (noAuthPaths.find((el) => window.location.href.split("/").slice(-1)[0].indexOf(el) !== -1)) {
        dispatch({ type: "removeToken", payload: null });
        setUserIsSignedIn(0);
        return;
      }
      verifyUser(
        state.user.token,
        async (user, token) => {
          dispatch({
            type: "user",
            payload: {
              token: token,
              id: user.id,
              name: user.name,
              email: user.email,
              expireDate: formatDateTime(new Date(user.expireDate)),
              isExpired: user.isExpired,
              refCode: user.refCode,
              subscriptionType: user.subscriptionType,
              parent: user.parent,
              customPlan: user.customPlan,
              dispatch: dispatch,
            },
          });

          let root = document.getElementById("root");
          if (root) {
            let smallDevice = +root.clientWidth < 768;
            if (smallDevice) {
              setUserIsSignedIn(1);
              return;
            }
          }

          loginSuccess(state, dispatch, user, setUserIsSignedIn, token);
        },
        () => {
          dispatch({ type: "removeToken", payload: null });
          setUserIsSignedIn(0);
        },
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
  if (state.app === null) {
    let isApp = navigator.userAgent.indexOf("easy-crypto-bot") !== -1;
    state.app = isApp;
  }

  let routes = getRoutes(state.app, userIsSignedIn, state.user, state.demo, state.locked);
*/

  useEffect(() => {
    if (smallWidthDevice === null) {
      return;
    }
    if (state.app === null) {
      let isApp = navigator.userAgent.indexOf("easy-crypto-bot") !== -1;
      state.app = isApp;
    }
    if (smallWidthDevice && state.trafic !== "notChoosen") {
      (async () => {
        if (state.trafic === "normal") {
          await loginSuccess(state, dispatch, state.user, setUserIsSignedIn, state.user.token);
        } else {
          //Trafic Saver
          initializeExchanges(state.app, state.demo, true, state.user.id, true);

          let userData = { strategies: [], executions: [] };
          if (!state.demo && !state.user.isAdmin) {
            //userData = await getUserData(state.user, dispatch, state.user.token, state.app);
            //autoSyncHomePage(state.user.token, state.user.id);
            //getWatchlists(state.user.token, dispatch, state.user.id);
            getStrategiesTraficSaver(state.user.token, dispatch);
            getStrategyGroups(state.user.token, dispatch, state.user.id);
            //getMultiCharts(state.user.token, dispatch, state.user.id);
            //autoSyncStrategyGroups(state.user.token, state.user.id);
          }
        }
        let routes = getRoutes(
          state.app,
          userIsSignedIn,
          state.user,
          state.demo,
          state.locked,
          smallWidthDevice,
          state.trafic
        );
        setRoutes(routes);
      })();
    } else {
      let routes = getRoutes(
        state.app,
        userIsSignedIn,
        state.user,
        state.demo,
        state.locked,
        smallWidthDevice,
        state.trafic
      );
      setRoutes(routes);
    }
  }, [state.app, userIsSignedIn, state.user, state.demo, state.locked, smallWidthDevice, state.trafic]);

  if (routes.length === 0) {
    return <div></div>
  }
  return userIsSignedIn !== 2 ? (
    <div>
      <Router>
        <BackButtonListener />
        <header>
          <Navigation routes={routes} />
        </header>
        <main>
          <TransitionGroup>
            <Switch>
              {routes.map(({ name, path, Component }) => (
                <Route key={path} path={path} exact={name !== "Manual"}>
                  {({ match }) => (
                    <>
                      <CSSTransition
                        in={match != null}
                        timeout={{ enter: 250, exit: 0 }}
                        classNames="fade"
                        unmountOnExit
                      >
                        <Component />
                      </CSSTransition>
                      <ScrollToTop path={path} />
                    </>
                  )}
                </Route>
              ))}
              <Route key={"/contest-ranking"} path={"/contest-ranking"} exact={true}>
                {({ match }) => (
                  <>
                    <CSSTransition in={match != null} timeout={{ enter: 250, exit: 0 }} classNames="fade" unmountOnExit>
                      <Contest />
                    </CSSTransition>
                    <ScrollToTop path={"/contest-ranking"} />
                  </>
                )}
              </Route>
              <Redirect to="/"></Redirect>
            </Switch>
          </TransitionGroup>
          <ModalConfirm
            id={"confirmDialog"}
            header={state.confirmDialog.header}
            content={state.confirmDialog.content}
            func={() => {
              if (state.confirmDialog.func()) {
                state.confirmDialog.func();
              }
            }}
            large={state.confirmDialog.large}
            dark={state.confirmDialog.dark}
          />
          <Modal
            id="infoDialog"
            header={state.infoDialog.header}
            content={state.infoDialog.content}
            large={state.infoDialog.large}
            dark={state.infoDialog.dark}
          />
        </main>
      </Router>
    </div>
  ) : (
    <div>
      <header>
        <nav className="navbar navbar-expand-sm bg-new-darker navbar-dark">
          <div className="nav-header bg-new-darker">
            <div className="navbar-brand text-nowrap py-1" to="/">
              <img className="pb-1" src={logoReverced} alt="logo" style={{ width: "24px", height: "auto" }} />
              <span id="topNavLogoText">
                &nbsp;&nbsp;EasyCrypto<span className="text-info">Bot</span>
              </span>
            </div>
          </div>
        </nav>
      </header>
      <main>
        <SignInLoading />
      </main>
    </div>
  );
};

function App() {
  return (
    <GlobalContextProvider>
      <MainPage />
    </GlobalContextProvider>
  );
}

export { Meta };
export default App;
