import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import logo from "./assets/images/logo-white.png";
import { globalContext } from "./Context.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import getExchange from "./Exchange.js";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import { Downloads } from "./Settings.js";
import { isOnMobile, getFromLocalStorage } from "./utils.js";
import Watchlist from "./Watchlist.js";
import MiniCharts from "./MiniCharts.js";
import Accounts from "./Accounts.js";

import ManualTrading from "./ManualTrading";
/*navigator.serviceWorker.addEventListener("message", (event) => {
  //console.log(event.data.msg, event.data.url);
});

const channel = new BroadcastChannel("sw-messages");
channel.addEventListener("message", (event) => {
  //console.log("Received", event.data);
});*/

function getActiveSubPage() {
  let page = getFromLocalStorage("homePage", "watchlist");
  if (page !== "watchlist" && page !== "miniCharts" && page !== "accounts" && page !== "trading") {
    page = "watchlist";
  }
  return page;
}

const HomeApp = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const [pairsAll, setPairsAll] = useState([]);
  const [pairDetailsAll, setPairDetailsAll] = useState({});
  const [isMobile] = useState(isOnMobile());
  const [activeSubPage, setActiveSubPage] = useState(getActiveSubPage());
  const [smallWidthDevice, setSmallWidthDevice] = useState(false);
  const [tradingPair, setTradingPair] = useState(getFromLocalStorage("tradingPair", "BINANCE-BTCUSDT"));
  const [selectedAccount, setSelectedAccount] = useState(null);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    (async () => {
      let pairsDetailsAll = {};
      let pairsAll = [];

      let binance = await getExchange("Binance").getPairs();
      if (binance) {
        let pairsBinance = binance[0].map((pair) => `BINANCE-${pair}`);
        Object.keys(binance[1]).forEach((key) => {
          pairsDetailsAll[`BINANCE-${key}`] = binance[1][key];
        });
        pairsAll = pairsBinance;
      }

      if (binance) {
        let binanceUs = await getExchange("Binance US").getPairs();
        let pairsBinanceUs = binanceUs[0].map((pair) => `BINANCEUS-${pair}`);
        Object.keys(binanceUs[1]).forEach((key) => {
          pairsDetailsAll[`BINANCEUS-${key}`] = binanceUs[1][key];
        });
        pairsAll = pairsAll.concat(pairsBinanceUs);
      }

      if (componentIsMounted.current && pairsAll && pairsAll[0]) {
        setPairsAll(pairsAll);
        setPairDetailsAll(pairsDetailsAll);
      }
    })();
  }, [state.user]);

  useEffect(() => {
    const onResize = (e) => {
      let root = document.getElementById("root");
      if (root) {
        if (smallWidthDevice && +root.clientWidth >= 768) {
          setSmallWidthDevice(false);
        }
        if (!smallWidthDevice && +root.clientWidth < 768) {
          setSmallWidthDevice(true);
        }
      }
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      componentIsMounted.current = false;
      window.removeEventListener("resize", onResize);
    };
  }, [smallWidthDevice]);

  const setActiveSubPageCallback = useCallback(
    (page) => {
      localStorage.setItem("homePage", page);
      setActiveSubPage(page);
    },
    [state.watchlistPanelRightExpanded]
  );

  const setTradingPairCallback = useCallback((pair) => {
    localStorage.setItem("tradingPair", pair);
    setTradingPair(pair);
  }, []);

  return (
    <div className="page px-3 px-md-5 pt-2 pb-md-5">
      {!state.app && !state.demo && !isMobile && (
        <div className="row row-cols-1 row-cols-md-2 custom-hr mb-5">
          <div className="col">
            <h1 className="major alt py-4 strech">
              <div className="logo d-lg-inline-block d-none">
                <img src={logo} alt="" />
              </div>
              <span className="text-nowrap">
                Easy Crypto <span className="text-info">Bot</span>
              </span>
            </h1>
            <h2 className="text-info my-4 h4">Let your trading ideas work for you</h2>
          </div>
          <div className="col text-right">
            <div className="pt-3 pt-md-5 font-italic">
              <NavLink className="h6 text-light" as={NavLink} to="/settings" exact activeClassName="active">
               Trade now in the <span className="text-info">app</span>!
                <FontAwesomeIcon icon={faAngleRight} className="blink ml-2" />
                <FontAwesomeIcon icon={faAngleRight} className=" blink" />
                <FontAwesomeIcon icon={faAngleRight} className=" blink" />
                <small className="d-block font-weight-lighter">
                  Settings <FontAwesomeIcon icon={faAngleRight} /> Downloads
                </small>
              </NavLink>
              <div className="d-none d-lg-inline-block w-xl-80 w-lg-85 w-md-90 ml-auto">
                <Downloads homePage />
              </div>
            </div>

            <div className="h6 font-italic pt-3">
              <NavLink className="text-light" as={NavLink} to="/contest" exact activeClassName="active">
                Interested in the <span className="text-info">contest</span>?
                <FontAwesomeIcon icon={faAngleRight} className="blink ml-3" />
                <FontAwesomeIcon icon={faAngleRight} className=" blink" />
                <FontAwesomeIcon icon={faAngleRight} className=" blink" />
              </NavLink>
            </div>
            <div className="h6 font-italic pt-3">
              <NavLink className="text-light" as={NavLink} to="/contact" exact activeClassName="active">
                Need <span className="text-info">help</span>?
                <FontAwesomeIcon icon={faAngleRight} className="blink ml-3" />
                <FontAwesomeIcon icon={faAngleRight} className=" blink" />
                <FontAwesomeIcon icon={faAngleRight} className=" blink" />
              </NavLink>
            </div>
          </div>
        </div>
      )}

      <div className="">
        <div className="pt-1">
          <div className="d-inline-flex active-border hover-white mb-3">
            <a
              className={`text-info p-2 mr-2 ${activeSubPage === "watchlist" ? "active" : ""}`}
              href="#/"
              onClick={(e) => {
                e.preventDefault();
                document.activeElement.blur();
                setActiveSubPageCallback("watchlist");
              }}
            >
              Watchlists
            </a>
            <a
              className={`text-info p-2 ${state.app || state.demo ? "mr-2" : ""} ${
                activeSubPage === "miniCharts" ? "active" : ""
              }`}
              href="#/"
              onClick={(e) => {
                e.preventDefault();
                document.activeElement.blur();
                setActiveSubPageCallback("miniCharts");
              }}
            >
              Charts
            </a>
            {(state.app || state.demo) && (
              <>
                <a
                  className={`text-info mr-2 p-2 ${activeSubPage === "accounts" ? "active" : ""}`}
                  href="#/"
                  onClick={(e) => {
                    e.preventDefault();
                    document.activeElement.blur();
                    setActiveSubPageCallback("accounts");
                  }}
                >
                  Accounts
                </a>

                <a
                  className={`text-info p-2 ${activeSubPage === "trading" ? "active" : ""}`}
                  href="#/"
                  onClick={(e) => {
                    e.preventDefault();
                    document.activeElement.blur();
                    setActiveSubPageCallback("trading");
                  }}
                >
                  Trading
                </a>
              </>
            )}
          </div>
        </div>

        {activeSubPage === "watchlist" && (
          <Watchlist
            pairsAll={pairsAll}
            pairDetailsAll={pairDetailsAll}
            smallWidthDevice={smallWidthDevice}
            tradingPair={tradingPair}
            setTradingPair={setTradingPairCallback}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
          />
        )}
        {activeSubPage === "miniCharts" && (
          <MiniCharts pairsAll={pairsAll} pairDetailsAll={pairDetailsAll} smallWidthDevice={smallWidthDevice} />
        )}
        {activeSubPage === "accounts" && (
          <Accounts
            smallWidthDevice={smallWidthDevice}
            tradingPair={tradingPair}
            setTradingPair={setTradingPairCallback}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
          />
        )}
        {activeSubPage === "trading" && (
          <ManualTrading
            tradingPair={tradingPair}
            setTradingPair={setTradingPairCallback}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
          />
        )}
      </div>
      {false && (
        <>
          <button
            className="btn btn-info"
            onClick={async (e) => {
              e.preventDefault();
              try {
                // let trade = await getEcbDatabase().getSimStrategyTrade("521395_60202-1616515204294");
                // sendTrades(state.user.token, "simulation", [trade]);
                //console.log(navigator.deviceMemory);
                // while (true) {
                //   console.log(
                //     `totalJSHeapSize: ${performance.memory.totalJSHeapSize / Math.pow(1000, 2)} MB; usedJSHeapSize: ${
                //       performance.memory.usedJSHeapSize / Math.pow(1000, 2)
                //     } MB; jsHeapSizeLimit: ${performance.memory.jsHeapSizeLimit / Math.pow(1000, 2)} MB`
                //   );
                //   await sleep(1000 * 2);
                // }
                // await getEcbDatabase().storeDailyPLSims({id: state.user.id, pls: []}); 
                // await getEcbDatabase().storeDailyPLLive({id: state.user.id, pls: []});
              } catch (e) {
                console.log("ERROR:", e);
              }
            }}
          >
            Test 1
          </button>
          &nbsp; &nbsp; &nbsp;
          <button
            className="btn btn-info"
            onClick={async (e) => {
              e.preventDefault();
              let bt2 = await getExchange("Binance").checkPriceListeningWebsocket();
            }}
          >
            Test 2
          </button>
        </>
      )}
    </div>
  );
};

export default HomeApp;
