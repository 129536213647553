import Mutex from "./Mutex.js";
import getEcbDatabase from "./EcbDatabase.js";
import {
  getStartDate,
  sleep,
  getUsdValue,
  fixNumber,
  fetchWithRetry,
  initializeWorker,
  getTimeframeFromInterval,
  getInterval,
  roundQuotedValue,
  getQuotedValue,
} from "./utils.js";
import { executionReportServiceRestart } from "./Bot.js";
import CryptoJS from "crypto-js";
/* eslint-disable no-unused-expressions */
/* eslint-disable no-func-assign */
/* eslint-disable no-sequences */
const a0_0x14a669 = a0_0x35e4;
(function (_0x4a1ade, _0x377fac) {
  const _0x4a37b3 = a0_0x35e4,
    _0x20e4dd = _0x4a1ade();
  while (!![]) {
    try {
      const _0x1156ae =
        parseInt(_0x4a37b3(0x1f1)) / 0x1 +
        parseInt(_0x4a37b3(0x1cb)) / 0x2 +
        (parseInt(_0x4a37b3(0xe1)) / 0x3) * (-parseInt(_0x4a37b3(0xcf)) / 0x4) +
        parseInt(_0x4a37b3(0x96)) / 0x5 +
        parseInt(_0x4a37b3(0x127)) / 0x6 +
        -parseInt(_0x4a37b3(0xdc)) / 0x7 +
        -parseInt(_0x4a37b3(0x162)) / 0x8;
      if (_0x1156ae === _0x377fac) break;
      else _0x20e4dd["push"](_0x20e4dd["shift"]());
    } catch (_0x1f4fb1) {
      _0x20e4dd["push"](_0x20e4dd["shift"]());
    }
  }
})(a0_0x2b0c, 0x6534f);
class Exchange {
  constructor(_0x29bc6a) {
    const _0x5a6fea = a0_0x35e4;
    this[_0x5a6fea(0xff)] = _0x29bc6a;
  }
  async [a0_0x14a669(0x16a)]() {}
  [a0_0x14a669(0x17d)](
    _0x5d0fd3,
    _0x2d94ce,
    _0x3ad221,
    _0x2644dd,
    _0x32e08c,
    _0x5c2cfc,
    _0x9f47a8,
    _0x3ab3a3
  ) {}
  async ["cancelGetHistoricalData"](_0x4f8ec3) {}
  ["verifyApiKey"](_0x3fd323, _0x1df674) {}
  ["getAssets"]() {}
  [a0_0x14a669(0xfa)]() {}
  [a0_0x14a669(0x179)]() {}
  [a0_0x14a669(0x118)]() {}
  [a0_0x14a669(0x18c)](_0x2b14d5) {}
  [a0_0x14a669(0xa8)](_0x6ea6aa, _0x3fb6b4) {}
  [a0_0x14a669(0x17c)](_0x158cde, _0x27429d) {}
  ["unsubscribeToPricesUpdate"](_0x322ef3) {}
  ["subscribeToChartUpdate"](_0x1d49ce, _0x11e051, _0x197517, _0x16bf72) {}
  [a0_0x14a669(0x1f7)](_0x5acef7) {}
}
class Binance extends Exchange {
  constructor(
    _0x16bbad,
    _0xea2f55,
    _0x398af5,
    _0x596238,
    _0x52e635,
    _0x34c40e,
    _0xb18c26,
    _0x31adb2
  ) {
    const _0x3fc5ae = a0_0x14a669;
    super(_0x52e635),
      (this["app"] = _0x16bbad),
      (this[_0x3fc5ae(0x1ef)] = _0xea2f55),
      (this[_0x3fc5ae(0x165)] = _0x596238),
      (this[_0x3fc5ae(0xcd)] = "https://cors-anywhere.herokuapp.com/"),
      (this["url"] = _0x34c40e),
      (this[_0x3fc5ae(0xa4)] = _0xb18c26),
      (this[_0x3fc5ae(0xcb)] = _0x31adb2),
      (this[_0x3fc5ae(0x166)] = {}),
      (this[_0x3fc5ae(0x19e)] = 0x3e8),
      (this[_0x3fc5ae(0x178)] = null),
      (this["pairDetails"] = {}),
      (this[_0x3fc5ae(0x1d7)] = {}),
      (this["userId"] = _0x398af5),
      (this["assets"] = {}),
      (this[_0x3fc5ae(0x148)] = ![]),
      (this[_0x3fc5ae(0x1da)] = null),
      (this["secret"] = null),
      (this[_0x3fc5ae(0xc4)] = {}),
      (this[_0x3fc5ae(0x191)] = {}),
      (this["getPairsMutex"] = new Mutex()),
      (this[_0x3fc5ae(0xdb)] = new Mutex()),
      (this["historicalDataMutex"] = new Mutex()),
      !_0x596238 && this[_0x3fc5ae(0x16a)](),
      (this["balanceUpdateSubscribers"] = {}),
      (this["totalAssetInUsd"] = ""),
      (this[_0x3fc5ae(0x136)] = 0x0),
      (this[_0x3fc5ae(0x115)] = 0x1),
      (this[_0x3fc5ae(0x10f)] = 0x1),
      (this[_0x3fc5ae(0xb9)] = {}),
      (this[_0x3fc5ae(0x179)] = {}),
      (this[_0x3fc5ae(0x164)] = {}),
      (this["orderUpdatesUnknown"] = []),
      (this[_0x3fc5ae(0x102)] = new Mutex()),
      (this[_0x3fc5ae(0x140)] = {}),
      (this["priceUpdateSubscribersMutex"] = new Mutex()),
      (this[_0x3fc5ae(0x195)] = new Mutex()),
      (this[_0x3fc5ae(0xed)] = new Mutex()),
      (this["balanceSnapshotsSubscribersMutex"] = new Mutex()),
      (this[_0x3fc5ae(0xf1)] = new Mutex()),
      (this[_0x3fc5ae(0x1e7)] = {}),
      (this["manualTrades"] = []),
      (this[_0x3fc5ae(0x12a)] = {}),
      (this[_0x3fc5ae(0xd6)] = new Mutex()),
      (this[_0x3fc5ae(0x11c)] = {}),
      (this[_0x3fc5ae(0xac)] = {}),
      (this[_0x3fc5ae(0x1b7)] = []),
      !_0x596238 &&
        (this[_0x3fc5ae(0xdd)](),
        this[_0x3fc5ae(0x15f)](),
        _0x16bbad && !_0xea2f55 && this[_0x3fc5ae(0xde)]());
  }
  async [a0_0x14a669(0xae)](_0x5dd99e = 0x3, _0x562ce3 = 0x1) {
    const _0x17f4d2 = a0_0x14a669;
    let _0x2d44e2 = ![];
    try {
      const _0x1a9c55 = await fetch(this[_0x17f4d2(0xa4)] + _0x17f4d2(0x99), {
        method: _0x17f4d2(0xbd),
        headers: {
          Accept: _0x17f4d2(0x1d1),
          "Content-Type": "application/json",
        },
      });
      if (_0x1a9c55[_0x17f4d2(0xe9)] === 0xc8) {
        const _0x291c71 = await _0x1a9c55[_0x17f4d2(0x19a)]();
        return _0x291c71[_0x17f4d2(0xee)];
      } else _0x2d44e2 = !![];
    } catch (_0x3c8ea7) {
      _0x2d44e2 = !![];
    }
    if (_0x2d44e2) {
      if (_0x562ce3 <= _0x5dd99e)
        return (
          await sleep(0x7d0),
          await this[_0x17f4d2(0xae)](_0x5dd99e, _0x562ce3 + 0x1)
        );
    }
    return null;
  }
  async ["exchangeOrder"](_0x29e699, _0x1610e4, _0x4dab04) {
    const _0x3faaa0 = a0_0x14a669;
    let _0x586c6b = new Date();
    _0x586c6b[_0x3faaa0(0x16c)](
      _0x586c6b[_0x3faaa0(0x98)]() - 0x5,
      _0x586c6b[_0x3faaa0(0x175)]()
    ),
      (_0x29e699[_0x3faaa0(0xb8)] = 0xe678);
    let _0x4538e0 = await this[_0x3faaa0(0xae)]();
    _0x29e699[_0x3faaa0(0x1a6)] = _0x4538e0
      ? _0x4538e0
      : _0x586c6b["getTime"]();
    let _0x48a653 = new URLSearchParams(_0x29e699),
      _0x31395c = CryptoJS[_0x3faaa0(0x10b)](
        _0x48a653[_0x3faaa0(0xd3)](),
        this[_0x3faaa0(0x1f0)]
      );
    return await this["exchangeOrderWithRetry"](
      _0x48a653[_0x3faaa0(0xd3)](),
      _0x1610e4,
      _0x31395c,
      _0x4dab04
    );
  }
  async [a0_0x14a669(0x107)](
    _0x4fc859,
    _0x21518e,
    _0x15fc6e,
    _0x2bb265,
    _0x49bed7 = 0x5,
    _0x226ea8 = 0x1
  ) {
    const _0x295e1d = a0_0x14a669;
    if (this[_0x295e1d(0x1b1)])
      while (new Date()[_0x295e1d(0xe8)]() < this[_0x295e1d(0x1b1)]) {
        await sleep(0x3e8 * 0x2);
      }
    let _0x5842f2 = ![],
      _0x3f6ce2 = null,
      _0x2f2b2f = null;
    try {
      const _0x5cf33e = await fetch(
        this["apiUrl"] +
          "/api/v3/order?" +
          _0x4fc859 +
          _0x295e1d(0xab) +
          _0x15fc6e,
        {
          method: _0x21518e,
          headers: {
            Accept: _0x295e1d(0x1d1),
            "Content-Type": _0x295e1d(0x1d1),
            "X-MBX-APIKEY": this[_0x295e1d(0x1da)],
          },
        }
      );
      let _0x4239c1 = _0x5cf33e[_0x295e1d(0x13e)][_0x295e1d(0x147)](
          _0x295e1d(0x112)
        ),
        _0x1de6cb = _0x5cf33e["headers"][_0x295e1d(0x147)](_0x295e1d(0x1b5));
      if (
        _0x4239c1 > this[_0x295e1d(0x19e)] ||
        _0x1de6cb > this[_0x295e1d(0x19e)]
      ) {
        console["log"](_0x295e1d(0x181));
        let _0x3c36da = new Date();
        _0x3c36da[_0x295e1d(0x1a9)](
          _0x3c36da[_0x295e1d(0x9e)](),
          _0x3c36da["getSeconds"]() + 0x1e
        ),
          (this[_0x295e1d(0x1b1)] = _0x3c36da["getTime"]());
      }
      if (_0x5cf33e[_0x295e1d(0xe9)] === 0xc8) {
        const _0x388018 = await _0x5cf33e[_0x295e1d(0x19a)]();
        return { order: this[_0x295e1d(0x198)](_0x388018, _0x2bb265) };
      } else {
        if (_0x5cf33e["status"] === 0x1ad) {
          let _0x116007 = new Date();
          _0x116007[_0x295e1d(0x1a9)](
            _0x116007[_0x295e1d(0x9e)]() + 0x1,
            _0x116007[_0x295e1d(0x98)]()
          ),
            (this[_0x295e1d(0x1b1)] = _0x116007["getTime"]()),
            console["log"](_0x295e1d(0x9b));
        } else {
          if (_0x5cf33e[_0x295e1d(0xe9)] === 0x1a2) {
            let _0x4f5eca = new Date();
            _0x4f5eca[_0x295e1d(0x1a9)](
              _0x4f5eca[_0x295e1d(0x9e)]() + 0x3,
              _0x4f5eca[_0x295e1d(0x98)]() + 0x1e
            ),
              (this[_0x295e1d(0x1b1)] = _0x4f5eca[_0x295e1d(0xe8)]()),
              console["log"](_0x295e1d(0x110));
          }
        }
        const _0x5ef976 = await _0x5cf33e[_0x295e1d(0x19a)]();
        _0x5ef976 &&
          ((_0x3f6ce2 = _0x5ef976["code"]),
          (_0x2f2b2f = _0x5ef976[_0x295e1d(0xf8)])),
          !_0x3f6ce2 === -0x7da && !_0x3f6ce2 === -0x3f5 && (_0x5842f2 = !![]);
      }
    } catch (_0x3c629d) {
      _0x5842f2 = !![];
    }
    if (_0x5842f2) {
      if (_0x226ea8 < _0x49bed7)
        return (
          await sleep(0x7d0),
          await this["exchangeOrderWithRetry"](
            _0x4fc859,
            _0x21518e,
            _0x15fc6e,
            _0x2bb265,
            _0x49bed7,
            _0x226ea8 + 0x1
          )
        );
    }
    return { order: null, errorCode: _0x3f6ce2, errorMsg: _0x2f2b2f };
  }
  [a0_0x14a669(0x198)](_0x40be73, _0x2ca733) {
    const _0x13bb1b = a0_0x14a669;
    let _0x14ffec = {};
    (_0x14ffec[_0x13bb1b(0x1f9)] = Number[_0x13bb1b(0x144)](
      _0x40be73[_0x13bb1b(0x19b)]
    )),
      (_0x14ffec[_0x13bb1b(0x134)] = Number[_0x13bb1b(0x144)](
        _0x40be73["cummulativeQuoteQty"]
      ));
    let _0x5f2109 = 0x0,
      _0x1b4cbe = 0x0;
    return (
      (_0x14ffec[_0x13bb1b(0x95)] = []),
      _0x40be73["fills"] &&
        _0x40be73[_0x13bb1b(0x95)][_0x13bb1b(0xc2)] > 0x0 &&
        _0x40be73[_0x13bb1b(0x95)][_0x13bb1b(0xc6)]((_0x510e37) => {
          const _0x171d1b = _0x13bb1b;
          let _0x2e585b = 0x0,
            _0x2b5c60 = 0x0;
          _0x510e37[_0x171d1b(0x1e6)] === _0x171d1b(0xa2)
            ? (_0x2e585b += Number[_0x171d1b(0x144)](
                _0x510e37[_0x171d1b(0xb6)]
              ))
            : (_0x2b5c60 += Number[_0x171d1b(0x144)](
                _0x510e37[_0x171d1b(0xb6)]
              ));
          (_0x5f2109 += _0x2e585b), (_0x1b4cbe += _0x2b5c60);
          let _0x1f0201 = fixNumber(
              Number[_0x171d1b(0x144)](_0x510e37[_0x171d1b(0xad)]),
              0xc
            ),
            _0x59e2ac = roundQuotedValue(
              Number[_0x171d1b(0x144)](_0x510e37[_0x171d1b(0x18a)]),
              _0x2ca733
            );
          _0x14ffec[_0x171d1b(0x95)]["push"]({
            p: _0x59e2ac,
            q: _0x1f0201,
            c: { bnb: _0x2e585b, base: _0x2b5c60 },
          });
        }),
      (_0x14ffec["price"] =
        _0x14ffec[_0x13bb1b(0x1f9)] !== 0x0
          ? roundQuotedValue(
              Number[_0x13bb1b(0x144)](
                _0x14ffec[_0x13bb1b(0x134)] / _0x14ffec["sizeInBase"]
              ),
              _0x2ca733
            )
          : 0x0),
      (_0x14ffec[_0x13bb1b(0xb6)] = { bnb: _0x5f2109, base: _0x1b4cbe }),
      (_0x14ffec[_0x13bb1b(0x103)] = _0x40be73["transactTime"]
        ? _0x40be73[_0x13bb1b(0x1a2)]
        : _0x40be73[_0x13bb1b(0x1cc)]),
      (_0x14ffec["status"] = _0x40be73[_0x13bb1b(0xe9)]),
      (_0x14ffec["id"] = _0x40be73[_0x13bb1b(0x1bc)]),
      (_0x14ffec[_0x13bb1b(0xea)] = _0x40be73["origQty"]),
      _0x14ffec
    );
  }
  [a0_0x14a669(0x14e)](_0x4bb310, _0x362ca0) {
    const _0x3e7cff = a0_0x14a669;
    let _0x132b7b = 0xa ** _0x362ca0[_0x3e7cff(0x9f)],
      _0x47f60f = Math[_0x3e7cff(0x1ed)](_0x4bb310 * _0x132b7b) / _0x132b7b;
    return (
      (_0x47f60f = fixNumber(_0x47f60f, _0x362ca0[_0x3e7cff(0x9f)])), _0x47f60f
    );
  }
  ["fixSizeInQioted"](_0x3c1dd, _0x2df72e) {
    const _0x223742 = a0_0x14a669;
    let _0x4239d9 = 0xa ** _0x2df72e[_0x223742(0x1b2)],
      _0x1bbad5 = Math["floor"](_0x3c1dd * _0x4239d9) / _0x4239d9;
    return (
      (_0x1bbad5 = fixNumber(_0x1bbad5, _0x2df72e[_0x223742(0x1b2)])), _0x1bbad5
    );
  }
  async [a0_0x14a669(0x138)](_0x1d64da) {
    const _0x2f7b99 = a0_0x14a669;
    try {
      await this[_0x2f7b99(0xf1)]["lock"]();
      let _0x27c643 = {
        newClientOrderId:
          "" +
          Math[_0x2f7b99(0x1ed)](Math[_0x2f7b99(0x11e)]() * 0x2327 + 0x3e8) +
          new Date()[_0x2f7b99(0xe8)](),
        symbol: _0x1d64da[_0x2f7b99(0x125)],
        side:
          _0x1d64da[_0x2f7b99(0xf3)] === "Buy"
            ? _0x2f7b99(0x143)
            : _0x2f7b99(0x1dc),
      };
      if (_0x1d64da[_0x2f7b99(0x151)] === _0x2f7b99(0xbe)) {
        _0x27c643[_0x2f7b99(0x1f5)] = _0x2f7b99(0x193);
        if (_0x1d64da[_0x2f7b99(0x1b3)] === "base")
          _0x27c643[_0x2f7b99(0x152)] = this[_0x2f7b99(0x14e)](
            _0x1d64da[_0x2f7b99(0xd9)],
            _0x1d64da["pairDetails"]
          );
        else
          _0x1d64da[_0x2f7b99(0x1b3)] === _0x2f7b99(0x120)
            ? (_0x27c643[_0x2f7b99(0x123)] = this[_0x2f7b99(0xdf)](
                _0x1d64da[_0x2f7b99(0xec)],
                _0x1d64da["pairDetails"]
              ))
            : _0x1d64da[_0x2f7b99(0xf3)] === "Buy"
            ? (_0x27c643[_0x2f7b99(0x123)] = this["fixSizeInQioted"](
                _0x1d64da[_0x2f7b99(0xec)],
                _0x1d64da[_0x2f7b99(0x15d)]
              ))
            : (_0x27c643["quantity"] = this[_0x2f7b99(0x14e)](
                _0x1d64da[_0x2f7b99(0xd9)],
                _0x1d64da[_0x2f7b99(0x15d)]
              ));
      } else
        (_0x27c643["type"] = _0x2f7b99(0x106)),
          (_0x27c643[_0x2f7b99(0x152)] = this[_0x2f7b99(0x14e)](
            _0x1d64da[_0x2f7b99(0xd9)],
            _0x1d64da[_0x2f7b99(0x15d)]
          )),
          (_0x27c643["price"] = this["fixSizeInQioted"](
            _0x1d64da[_0x2f7b99(0x18a)],
            _0x1d64da["pairDetails"]
          )),
          (_0x27c643[_0x2f7b99(0xc0)] = "GTC");
      let _0x46b974 = await this["exchangeOrder"](
        _0x27c643,
        _0x2f7b99(0x1e0),
        _0x1d64da[_0x2f7b99(0x15d)]
      );
      if (_0x46b974 && _0x46b974[_0x2f7b99(0x185)])
        return (
          (_0x1d64da["status"] = _0x46b974[_0x2f7b99(0x185)][_0x2f7b99(0xe9)]),
          (_0x1d64da["o"] = _0x46b974[_0x2f7b99(0x185)][_0x2f7b99(0x18a)]),
          (_0x1d64da["oSizeInBase"] =
            _0x46b974[_0x2f7b99(0x185)]["sizeInBase"]),
          (_0x1d64da[_0x2f7b99(0x16e)] =
            _0x46b974[_0x2f7b99(0x185)][_0x2f7b99(0x134)]),
          (_0x1d64da["oExId"] = _0x46b974["order"]["id"]),
          (_0x1d64da[_0x2f7b99(0x1cd)] = []),
          (_0x1d64da[_0x2f7b99(0x150)] = { bnb: 0x0, base: 0x0, quote: 0x0 }),
          (_0x1d64da["cFills"] = []),
          (_0x1d64da["cComm"] = { bnb: 0x0, base: 0x0, quote: 0x0 }),
          (_0x1d64da[_0x2f7b99(0xd1)] =
            _0x46b974[_0x2f7b99(0x185)][_0x2f7b99(0x103)]),
          (_0x1d64da["editDate"] = new Date()[_0x2f7b99(0xe8)]()),
          getEcbDatabase()[_0x2f7b99(0x190)](_0x1d64da),
          this[_0x2f7b99(0x9c)][_0x2f7b99(0x132)](_0x1d64da),
          Object["keys"](this[_0x2f7b99(0x1e7)])[_0x2f7b99(0xc6)](
            (_0x1158b7) => {
              const _0x25c9d9 = _0x2f7b99;
              this[_0x25c9d9(0x1e7)][_0x1158b7]([...this[_0x25c9d9(0x9c)]]);
            }
          ),
          _0x46b974[_0x2f7b99(0x185)]
        );
      else {
        let _0x485436 =
          _0x2f7b99(0x19d) +
          (_0x1d64da["direction"] === _0x2f7b99(0xbb)
            ? _0x2f7b99(0x12d)
            : "selling") +
          "\x20" +
          _0x1d64da[_0x2f7b99(0x125)] +
          ".\x20";
        if (_0x46b974[_0x2f7b99(0x1ea)] === -0x7da)
          _0x485436 += _0x2f7b99(0x10c) + _0x46b974[_0x2f7b99(0x1e4)];
        else {
          if (_0x46b974[_0x2f7b99(0x1ea)] === -0x3f5)
            _0x485436 += _0x2f7b99(0xb5);
          else {
            if (_0x46b974[_0x2f7b99(0x1ea)] === -0x7df)
              _0x485436 +=
                "Invalid\x20API-key\x20or\x20your\x20IP\x20address\x20is\x20not\x20in\x20the\x20trusted\x20IPs\x20list\x20on\x20Binance.";
            else
              _0x46b974["errorMsg"]
                ? (_0x485436 += _0x2f7b99(0xda) + _0x46b974["errorMsg"])
                : (_0x485436 +=
                    "Connection\x20to\x20" +
                    this[_0x2f7b99(0xff)] +
                    "\x20failed.");
          }
        }
        return { error: _0x485436 };
      }
    } finally {
      this["manualTradesUpdateSubscribersMutex"][_0x2f7b99(0x18e)]();
    }
  }
  async [a0_0x14a669(0x1c8)](_0x165f46) {
    const _0x32674d = a0_0x14a669;
    if (_0x165f46[_0x32674d(0xc2)] === 0x0) return;
    let _0x552098 = new Date();
    _0x552098["setSeconds"](
      _0x552098[_0x32674d(0x98)]() - 0x5,
      _0x552098[_0x32674d(0x175)]()
    );
    let _0xac3b72 = await this["getServerTime"](),
      _0x162913 = {
        asset: _0x165f46,
        recvWindow: 0xe678,
        timestamp: _0xac3b72 ? _0xac3b72 : _0x552098["getTime"](),
      },
      _0x5a00e8 = new URLSearchParams(_0x162913),
      _0xd35303 = CryptoJS[_0x32674d(0x10b)](
        _0x5a00e8["toString"](),
        this[_0x32674d(0x1f0)]
      );
    return await this[_0x32674d(0x177)](
      _0x5a00e8[_0x32674d(0xd3)](),
      _0xd35303
    );
  }
  async ["exchangeDustTransferWithRetry"](
    _0x46b6b8,
    _0xa49bf6,
    _0x3ef4dd = 0x5,
    _0x1a3b0d = 0x1
  ) {
    const _0x447fcc = a0_0x14a669;
    if (this["maxAPIWeithTimeOutDate"])
      while (new Date()[_0x447fcc(0xe8)]() < this["maxAPIWeithTimeOutDate"]) {
        await sleep(0x3e8 * 0x2);
      }
    let _0x17dde6 = ![],
      _0x363bab = null,
      _0x325cd2 = null;
    try {
      const _0x53c8cb = await fetch(
        this[_0x447fcc(0xa4)] +
          "/sapi/v1/asset/dust?" +
          _0x46b6b8 +
          "&signature=" +
          _0xa49bf6,
        {
          method: _0x447fcc(0x1e0),
          headers: {
            Accept: _0x447fcc(0x1d1),
            "Content-Type": "application/json",
            "X-MBX-APIKEY": this[_0x447fcc(0x1da)],
          },
        }
      );
      let _0x815846 = _0x53c8cb[_0x447fcc(0x13e)][_0x447fcc(0x147)](
          _0x447fcc(0x112)
        ),
        _0xa2919b = _0x53c8cb[_0x447fcc(0x13e)][_0x447fcc(0x147)](
          _0x447fcc(0x1b5)
        );
      if (
        _0x815846 > this[_0x447fcc(0x19e)] ||
        _0xa2919b > this[_0x447fcc(0x19e)]
      ) {
        console[_0x447fcc(0x1c3)](_0x447fcc(0x181));
        let _0x1ce6e5 = new Date();
        _0x1ce6e5[_0x447fcc(0x1a9)](
          _0x1ce6e5[_0x447fcc(0x9e)](),
          _0x1ce6e5[_0x447fcc(0x98)]() + 0x1e
        ),
          (this[_0x447fcc(0x1b1)] = _0x1ce6e5[_0x447fcc(0xe8)]());
      }
      if (_0x53c8cb[_0x447fcc(0xe9)] === 0xc8) {
        const _0x27452f = await _0x53c8cb[_0x447fcc(0x19a)]();
        return _0x27452f;
      } else {
        if (_0x53c8cb["status"] === 0x1ad) {
          let _0x458ab1 = new Date();
          _0x458ab1[_0x447fcc(0x1a9)](
            _0x458ab1[_0x447fcc(0x9e)]() + 0x1,
            _0x458ab1[_0x447fcc(0x98)]()
          ),
            (this[_0x447fcc(0x1b1)] = _0x458ab1[_0x447fcc(0xe8)]()),
            console[_0x447fcc(0x1c3)](_0x447fcc(0x9b));
        } else {
          if (_0x53c8cb[_0x447fcc(0xe9)] === 0x1a2) {
            let _0x5096e3 = new Date();
            _0x5096e3["setMinutes"](
              _0x5096e3[_0x447fcc(0x9e)]() + 0x3,
              _0x5096e3[_0x447fcc(0x98)]() + 0x1e
            ),
              (this[_0x447fcc(0x1b1)] = _0x5096e3["getTime"]()),
              console[_0x447fcc(0x1c3)](_0x447fcc(0x110));
          }
        }
        const _0x551d6e = await _0x53c8cb[_0x447fcc(0x19a)]();
        _0x551d6e &&
          ((_0x363bab = _0x551d6e[_0x447fcc(0x16f)]),
          (_0x325cd2 = _0x551d6e[_0x447fcc(0xf8)])),
          !_0x363bab === -0x7da && !_0x363bab === -0x3f5 && (_0x17dde6 = !![]);
      }
    } catch (_0x13da5c) {
      _0x17dde6 = !![];
    }
    if (_0x17dde6) {
      if (_0x1a3b0d < _0x3ef4dd)
        return (
          await sleep(0x7d0),
          await this[_0x447fcc(0x177)](
            _0x46b6b8,
            _0xa49bf6,
            _0x3ef4dd,
            _0x1a3b0d + 0x1
          )
        );
    }
    return { order: null, errorCode: _0x363bab, errorMsg: _0x325cd2 };
  }
  async [a0_0x14a669(0x100)](_0x2ec319, _0x359901, _0x30e334) {
    const _0x32005f = a0_0x14a669;
    let _0x15a949 = { symbol: _0x2ec319, orderId: _0x30e334 },
      _0x1247a7 = await this[_0x32005f(0x184)](
        _0x15a949,
        _0x32005f(0xd0),
        _0x359901
      );
    return _0x1247a7;
  }
  async [a0_0x14a669(0x1f6)](_0x403995, _0x3e400b, _0xe12c71) {
    const _0x38b14f = a0_0x14a669;
    try {
      await this[_0x38b14f(0xf1)]["lock"](), delete _0x403995[_0x38b14f(0x1d2)];
      if (
        _0x403995[_0x38b14f(0xe9)] === _0x38b14f(0x113) ||
        _0x403995[_0x38b14f(0xe9)] === _0x38b14f(0x145)
      ) {
        let _0x2268b7 = await this[_0x38b14f(0x100)](
          _0x403995[_0x38b14f(0x125)],
          _0x403995["pairDetails"],
          _0x403995[_0x38b14f(0x124)]
        );
        if (_0x2268b7 && _0x2268b7[_0x38b14f(0x185)]) {
          if (_0x403995[_0x38b14f(0xe9)] === _0x38b14f(0x113))
            return { canceled: !![] };
        } else {
          let _0x19c967 =
            _0x38b14f(0x1a8) + _0x403995[_0x38b14f(0x125)] + _0x38b14f(0x156);
          if (_0x2268b7[_0x38b14f(0x1ea)] === -0x7da)
            _0x19c967 +=
              "Order\x20Rejected\x20by\x20Binance.\x20Details:\x20" +
              _0x2268b7[_0x38b14f(0x1e4)];
          else {
            if (_0x2268b7["errorCode"] === -0x3f5) _0x19c967 += _0x38b14f(0xb5);
            else {
              if (_0x2268b7["errorCode"] === -0x7df)
                _0x19c967 += _0x38b14f(0x1d0);
              else
                _0x2268b7[_0x38b14f(0x1e4)]
                  ? (_0x19c967 += _0x38b14f(0xda) + _0x2268b7[_0x38b14f(0x1e4)])
                  : (_0x19c967 +=
                      _0x38b14f(0x1a5) +
                      this[_0x38b14f(0xff)] +
                      _0x38b14f(0x109));
            }
          }
          return { error: _0x19c967 };
        }
      }
      let _0x41b884 = {
        newClientOrderId:
          "" +
          Math[_0x38b14f(0x1ed)](Math[_0x38b14f(0x11e)]() * 0x2327 + 0x3e8) +
          new Date()[_0x38b14f(0xe8)](),
        symbol: _0x403995[_0x38b14f(0x125)],
        type: _0x38b14f(0x193),
      };
      _0x403995[_0x38b14f(0xf3)] === _0x38b14f(0xbb)
        ? ((_0x41b884[_0x38b14f(0xba)] = "SELL"),
          (_0x41b884[_0x38b14f(0x152)] = this[_0x38b14f(0x14e)](
            _0x403995[_0x38b14f(0x1b0)] -
              _0x403995[_0x38b14f(0x150)][_0x38b14f(0xd5)],
            _0x403995["pairDetails"]
          )))
        : ((_0x41b884["side"] = _0x38b14f(0x143)),
          (_0x41b884["quoteOrderQty"] = this[_0x38b14f(0xdf)](
            _0x403995[_0x38b14f(0x16e)] - _0x403995["oComm"]["quote"],
            _0x403995["pairDetails"]
          )));
      let _0x3e762b = await this[_0x38b14f(0x184)](
        _0x41b884,
        "post",
        _0x403995[_0x38b14f(0x15d)]
      );
      if (_0x3e762b && _0x3e762b["order"])
        return (
          (_0x403995[_0x38b14f(0x1b4)] = "NEW"),
          (_0x403995["c"] = _0x3e762b["order"]["price"]),
          (_0x403995["cSizeInBase"] = _0x3e762b["order"][_0x38b14f(0x1f9)]),
          (_0x403995[_0x38b14f(0x129)] =
            _0x3e762b[_0x38b14f(0x185)][_0x38b14f(0x134)]),
          (_0x403995["cExId"] = _0x3e762b[_0x38b14f(0x185)]["id"]),
          (_0x403995["cd"] = _0x3e762b[_0x38b14f(0x185)][_0x38b14f(0x103)]),
          (_0x403995["editDate"] = new Date()[_0x38b14f(0xe8)]()),
          _0x3e400b && _0xe12c71
            ? (_0x403995["tradeDetails"] = {
                s: _0x403995[_0x38b14f(0xf9)],
                t: _0x403995["t"],
                cwp: _0x3e400b,
                ct: _0xe12c71,
              })
            : (_0x403995[_0x38b14f(0x114)] = { ct: _0x38b14f(0x17a) }),
          getEcbDatabase()[_0x38b14f(0x190)](_0x403995),
          Object[_0x38b14f(0x119)](this["manualTradesSubscribers"])[
            _0x38b14f(0xc6)
          ]((_0xc6293e) => {
            const _0x582507 = _0x38b14f;
            this[_0x582507(0x1e7)][_0xc6293e]([...this[_0x582507(0x9c)]]);
          }),
          _0x3e762b[_0x38b14f(0x185)]
        );
      else {
        let _0x16b11a =
          _0x38b14f(0x19d) +
          (_0x403995["direction"] === _0x38b14f(0xbb)
            ? "selling"
            : _0x38b14f(0x12d)) +
          "\x20" +
          _0x403995[_0x38b14f(0x125)] +
          ".\x20";
        if (_0x3e762b["errorCode"] === -0x7da)
          _0x16b11a += _0x38b14f(0x10c) + _0x3e762b[_0x38b14f(0x1e4)];
        else {
          if (_0x3e762b[_0x38b14f(0x1ea)] === -0x3f5)
            _0x16b11a += _0x38b14f(0xb5);
          else {
            if (_0x3e762b["errorCode"] === -0x7df)
              _0x16b11a += _0x38b14f(0x1d0);
            else
              _0x3e762b[_0x38b14f(0x1e4)]
                ? (_0x16b11a += _0x38b14f(0xda) + _0x3e762b[_0x38b14f(0x1e4)])
                : (_0x16b11a +=
                    _0x38b14f(0x1a5) + this["exchange"] + "\x20failed.");
          }
        }
        return (
          (_0x403995[_0x38b14f(0x122)] = _0x16b11a),
          getEcbDatabase()["storeManualTrade"](_0x403995),
          Object[_0x38b14f(0x119)](this[_0x38b14f(0x1e7)])[_0x38b14f(0xc6)](
            (_0x2ab46b) => {
              const _0xac7b6d = _0x38b14f;
              this[_0xac7b6d(0x1e7)][_0x2ab46b]([...this[_0xac7b6d(0x9c)]]);
            }
          ),
          { error: _0x16b11a }
        );
      }
    } finally {
      this[_0x38b14f(0xf1)][_0x38b14f(0x18e)]();
    }
  }
  async ["manualTradeEdit"](_0x388106) {
    const _0x1f083d = a0_0x14a669;
    try {
      await this[_0x1f083d(0xf1)][_0x1f083d(0xaa)](),
        getEcbDatabase()[_0x1f083d(0x190)](_0x388106);
      let _0x29a969 = -0x1;
      this[_0x1f083d(0x9c)][_0x1f083d(0xe2)]((_0x294199, _0x451516) => {
        return (_0x29a969 = _0x451516), _0x294199["id"] === _0x388106["id"];
      }),
        this[_0x1f083d(0x9c)]["splice"](_0x29a969, 0x1, _0x388106),
        Object[_0x1f083d(0x119)](this[_0x1f083d(0x1e7)])[_0x1f083d(0xc6)](
          (_0x42b20c) => {
            const _0x4025bb = _0x1f083d;
            this[_0x4025bb(0x1e7)][_0x42b20c]([...this[_0x4025bb(0x9c)]]);
          }
        );
    } finally {
      this[_0x1f083d(0xf1)][_0x1f083d(0x18e)]();
    }
  }
  async [a0_0x14a669(0x1ec)](_0x2637bc, _0x6ff3be) {
    const _0x40d3e5 = a0_0x14a669;
    try {
      await this["manualTradesUpdateSubscribersMutex"][_0x40d3e5(0xaa)](),
        getEcbDatabase()[_0x40d3e5(0x15c)](_0x2637bc),
        (this[_0x40d3e5(0x9c)] = this[_0x40d3e5(0x9c)][_0x40d3e5(0x1cf)](
          (_0x3799ca) => _0x3799ca["id"] !== _0x2637bc
        )),
        Object[_0x40d3e5(0x119)](this[_0x40d3e5(0x1e7)])[_0x40d3e5(0xc6)](
          (_0x3ca384) => {
            const _0x2ba1d5 = _0x40d3e5;
            this[_0x2ba1d5(0x1e7)][_0x3ca384](
              [...this[_0x2ba1d5(0x9c)]],
              _0x6ff3be
            );
          }
        );
    } finally {
      this["manualTradesUpdateSubscribersMutex"]["release"]();
    }
  }
  async [a0_0x14a669(0xde)]() {
    const _0x484cd9 = a0_0x14a669;
    try {
      await this[_0x484cd9(0xf1)]["lock"]();
      if (!this["userId"]) return;
      let _0x364d29 = await getEcbDatabase()["getManualTrades"](
        this[_0x484cd9(0xc9)]
      );
      (_0x364d29 = _0x364d29["filter"](
        (_0x4c2669) =>
          _0x4c2669[_0x484cd9(0xff)] === this[_0x484cd9(0xff)] &&
          _0x4c2669[_0x484cd9(0xe9)] !== _0x484cd9(0x1d8)
      )),
        (this[_0x484cd9(0x9c)] = _0x364d29),
        Object["keys"](this["manualTradesSubscribers"])["forEach"](
          (_0x28bec0) => {
            const _0xecf47c = _0x484cd9;
            this["manualTradesSubscribers"][_0x28bec0]([
              ...this[_0xecf47c(0x9c)],
            ]);
          }
        );
    } finally {
      this[_0x484cd9(0xf1)][_0x484cd9(0x18e)]();
    }
  }
  async [a0_0x14a669(0x11d)](_0x2c98e7, _0x310cd4) {
    const _0x1fdc4d = a0_0x14a669;
    try {
      await this["manualTradesUpdateSubscribersMutex"][_0x1fdc4d(0xaa)](),
        (this[_0x1fdc4d(0x1e7)][_0x2c98e7] = _0x310cd4),
        _0x310cd4([...this["manualTrades"]]);
    } finally {
      this[_0x1fdc4d(0xf1)][_0x1fdc4d(0x18e)]();
    }
  }
  async [a0_0x14a669(0x1e8)](_0x2e6f6f) {
    const _0x24cc3e = a0_0x14a669;
    try {
      await this[_0x24cc3e(0xf1)][_0x24cc3e(0xaa)](),
        delete this[_0x24cc3e(0x1e7)][_0x2e6f6f];
    } finally {
      this[_0x24cc3e(0xf1)]["release"]();
    }
  }
  async [a0_0x14a669(0x13a)](
    _0x26f520,
    _0x4c07c2,
    _0x2a6a98,
    _0x3ef0b7,
    _0x3f6183,
    _0x42887d
  ) {
    const _0x3173d5 = a0_0x14a669;
    try {
      if (!_0x4c07c2 || !_0x2a6a98) return;
      await this[_0x3173d5(0xd6)]["lock"]();
      let _0x259e76 = _0x4c07c2 + "-" + _0x2a6a98;
      if (!this["chartData"][_0x259e76]) {
        let _0x492baa = new Date();
        _0x492baa[_0x3173d5(0x16c)](_0x492baa[_0x3173d5(0x98)]() + 0x3c);
        while (
          !this[_0x3173d5(0xe0)] ||
          this[_0x3173d5(0xe0)][_0x3173d5(0x189)] !==
            this[_0x3173d5(0xe0)][_0x3173d5(0x14a)]
        ) {
          if (_0x492baa < new Date()) break;
          await sleep(0x64);
        }
        delete this["chartDataToUnsubscribe"][_0x259e76],
          (this[_0x3173d5(0x12a)][_0x259e76] = {
            subscribers: {},
            data: [],
            type: _0x3f6183,
            startDate: _0x42887d,
          }),
          (this[_0x3173d5(0x12a)][_0x259e76][_0x3173d5(0x1aa)][_0x26f520] =
            _0x3ef0b7);
        let _0x1e1645 = new Date(),
          _0xc70ec = new Date();
        switch (_0x2a6a98) {
          case "1m":
            _0x1e1645["setHours"](_0x1e1645["getHours"]() - 0xf);
            break;
          case "5m":
            _0x1e1645["setHours"](_0x1e1645["getHours"]() - 0x4b);
            break;
          case _0x3173d5(0xf2):
            _0x1e1645[_0x3173d5(0x1d4)](_0x1e1645[_0x3173d5(0x155)]() - 0xe1);
            break;
          case "30m":
            _0x1e1645[_0x3173d5(0x1d4)](_0x1e1645["getHours"]() - 0x1c2);
            break;
          case "1h":
            _0x1e1645[_0x3173d5(0x104)](_0x1e1645[_0x3173d5(0xc5)]() - 0x26);
            break;
          case "4h":
            _0x1e1645["setDate"](_0x1e1645[_0x3173d5(0xc5)]() - 0x96);
            break;
          case "12h":
            _0x1e1645[_0x3173d5(0x104)](_0x1e1645[_0x3173d5(0xc5)]() - 0x1c2);
            break;
          case "1d":
            _0x1e1645[_0x3173d5(0x104)](_0x1e1645["getDate"]() - 0x384);
            break;
          case "1w":
            _0x1e1645[_0x3173d5(0x104)](_0x1e1645["getDate"]() - 0x189c);
            break;
          case "1M":
            _0x1e1645[_0x3173d5(0x135)](_0x1e1645[_0x3173d5(0x15a)]() - 0x3c);
            break;
          default:
        }
        await sleep(0x1f4),
          _0x42887d && _0x42887d < _0x1e1645 && (_0x1e1645 = _0x42887d),
          this[_0x3173d5(0x171)](
            new Date()[_0x3173d5(0xe8)](),
            _0x4c07c2,
            getTimeframeFromInterval(_0x2a6a98),
            _0x1e1645[_0x3173d5(0xe8)](),
            _0xc70ec[_0x3173d5(0xe8)](),
            _0x3f6183 === _0x3173d5(0x199)
          )["then"]((_0x45b6d8) => {
            const _0x52cee8 = _0x3173d5;
            try {
              _0x45b6d8 &&
                _0x45b6d8[_0x52cee8(0xc2)] > 0x0 &&
                this[_0x52cee8(0x12a)][_0x259e76] &&
                ((this["chartData"][_0x259e76]["data"] = _0x45b6d8),
                _0x3ef0b7(this[_0x52cee8(0x12a)][_0x259e76][_0x52cee8(0xb1)]));
            } catch (_0x2bd337) {}
          }),
          this[_0x3173d5(0xe0)] &&
            this[_0x3173d5(0xe0)][_0x3173d5(0x189)] ===
              this[_0x3173d5(0xe0)]["OPEN"] &&
            this[_0x3173d5(0xe0)][_0x3173d5(0xa9)](
              JSON[_0x3173d5(0x159)]({
                method: "SUBSCRIBE",
                params: [
                  _0x4c07c2["toLowerCase"]() + _0x3173d5(0x1c5) + _0x2a6a98,
                ],
                id: new Date()[_0x3173d5(0xe8)](),
              })
            );
      } else {
        delete this[_0x3173d5(0xac)][_0x259e76],
          (this[_0x3173d5(0x12a)][_0x259e76][_0x3173d5(0x1aa)][_0x26f520] =
            _0x3ef0b7);
        if (
          _0x3f6183 === _0x3173d5(0x199) &&
          (_0x3f6183 !== this["chartData"][_0x259e76][_0x3173d5(0x1f5)] ||
            _0x42887d < this[_0x3173d5(0x12a)][_0x259e76][_0x3173d5(0x170)])
        ) {
          (this["chartData"][_0x259e76][_0x3173d5(0x170)] = _0x42887d),
            (this[_0x3173d5(0x12a)][_0x259e76][_0x3173d5(0x1f5)] = _0x3f6183);
          let _0x4a47af = getTimeframeFromInterval(_0x2a6a98);
          this[_0x3173d5(0x171)](
            new Date()[_0x3173d5(0xe8)](),
            _0x4c07c2,
            _0x4a47af,
            _0x42887d["getTime"](),
            new Date()[_0x3173d5(0xe8)](),
            _0x3f6183 === "full"
          )[_0x3173d5(0xa0)]((_0xb0f626) => {
            const _0x3eef25 = _0x3173d5;
            try {
              _0xb0f626 &&
                _0xb0f626[_0x3eef25(0xc2)] > 0x0 &&
                this[_0x3eef25(0x12a)][_0x259e76] &&
                ((this["chartData"][_0x259e76][_0x3eef25(0xb1)] = _0xb0f626),
                _0x3ef0b7(this[_0x3eef25(0x12a)][_0x259e76][_0x3eef25(0xb1)]));
            } catch (_0x5d9356) {}
          });
        } else _0x3ef0b7(this[_0x3173d5(0x12a)][_0x259e76][_0x3173d5(0xb1)]);
      }
    } finally {
      this["chartDataMutex"][_0x3173d5(0x18e)]();
    }
  }
  async [a0_0x14a669(0x1f7)](_0xa608f0) {
    const _0x576a35 = a0_0x14a669;
    try {
      await this[_0x576a35(0xd6)][_0x576a35(0xaa)]();
      for (let _0x752d14 in this[_0x576a35(0x12a)]) {
        let _0x163d38 = this[_0x576a35(0x12a)][_0x752d14];
        if (_0x163d38 && _0x163d38[_0x576a35(0x1aa)][_0xa608f0]) {
          delete _0x163d38[_0x576a35(0x1aa)][_0xa608f0];
          if (
            Object[_0x576a35(0x119)](_0x163d38[_0x576a35(0x1aa)])[
              _0x576a35(0xc2)
            ] === 0x0
          ) {
            let _0x471bab = new Date();
            _0x471bab[_0x576a35(0x1d4)](_0x471bab[_0x576a35(0x155)]() + 0x1),
              (this[_0x576a35(0xac)][_0x752d14] = _0x471bab["getTime"]());
          }
          break;
        }
      }
    } finally {
      this[_0x576a35(0xd6)]["release"]();
    }
  }
  async [a0_0x14a669(0xdd)]() {
    const _0x567c72 = a0_0x14a669;
    while (!![]) {
      await sleep(0x3e8 * 0x3c * 0xa);
      try {
        await this[_0x567c72(0xd6)][_0x567c72(0xaa)]();
        let _0xa69f23 = [];
        Object[_0x567c72(0x119)](this[_0x567c72(0xac)])[_0x567c72(0xc6)](
          (_0x49ddbb) => {
            const _0x4d5fc8 = _0x567c72;
            new Date()[_0x4d5fc8(0xe8)]() > this[_0x4d5fc8(0xac)][_0x49ddbb] &&
              _0xa69f23[_0x4d5fc8(0x132)](_0x49ddbb);
          }
        );
        if (_0xa69f23[_0x567c72(0xc2)] === 0x0) continue;
        let _0x23f2a6 = new Date();
        _0x23f2a6[_0x567c72(0x16c)](_0x23f2a6[_0x567c72(0x98)]() + 0x3c);
        while (
          !this[_0x567c72(0xe0)] ||
          this[_0x567c72(0xe0)]["readyState"] !==
            this[_0x567c72(0xe0)][_0x567c72(0x14a)]
        ) {
          if (_0x23f2a6 < new Date()) break;
          await sleep(0x64);
        }
        if (
          this[_0x567c72(0xe0)] &&
          this["priceListenSocket"][_0x567c72(0x189)] ===
            this[_0x567c72(0xe0)]["OPEN"]
        ) {
          let _0x219980 = _0xa69f23[_0x567c72(0x12e)]((_0x2d6277) => {
            const _0x3341b0 = _0x567c72;
            let [_0x1628e5, _0x142f2d] = _0x2d6277["split"]("-");
            return (
              delete this["chartData"][_0x2d6277],
              _0x1628e5[_0x3341b0(0x12c)]() + "@kline_" + _0x142f2d
            );
          });
          (this[_0x567c72(0xac)] = {}),
            this[_0x567c72(0xe0)]["send"](
              JSON["stringify"]({
                method: _0x567c72(0x1f8),
                params: _0x219980,
                id: new Date()[_0x567c72(0xe8)](),
              })
            );
        }
      } catch (_0x4c6d7e) {
      } finally {
        this[_0x567c72(0xd6)][_0x567c72(0x18e)]();
      }
    }
  }
  async [a0_0x14a669(0x17c)](_0x45027c, _0x4250ee) {
    const _0x3daaa6 = a0_0x14a669;
    try {
      await this[_0x3daaa6(0x15e)]["lock"](),
        (this[_0x3daaa6(0xb9)][_0x45027c] = _0x4250ee),
        _0x4250ee(this[_0x3daaa6(0xc4)], this[_0x3daaa6(0x191)]);
    } finally {
      this[_0x3daaa6(0x15e)][_0x3daaa6(0x18e)]();
    }
  }
  async ["unsubscribeToPricesUpdate"](_0x1f410b) {
    const _0x100ce7 = a0_0x14a669;
    try {
      await this["priceUpdateSubscribersMutex"][_0x100ce7(0xaa)](),
        delete this[_0x100ce7(0xb9)][_0x1f410b];
    } finally {
      this[_0x100ce7(0x15e)][_0x100ce7(0x18e)]();
    }
  }
  async ["subscribeToBalanceSnapshots"](_0x28bdcf, _0x525417) {
    const _0x33a9ec = a0_0x14a669;
    try {
      await this["balanceSnapshotsSubscribersMutex"][_0x33a9ec(0xaa)](),
        (this["balanceSnapshotsUpdateSubscribers"][_0x28bdcf] = _0x525417),
        _0x525417(this[_0x33a9ec(0x1b7)]);
    } finally {
      this[_0x33a9ec(0xce)]["release"]();
    }
  }
  async [a0_0x14a669(0x118)](_0x1b6207) {
    const _0x559ad1 = a0_0x14a669;
    try {
      await this[_0x559ad1(0xce)]["lock"](),
        delete this[_0x559ad1(0x179)][_0x1b6207];
    } finally {
      this[_0x559ad1(0xce)][_0x559ad1(0x18e)]();
    }
  }
  async [a0_0x14a669(0xa8)](_0x57305c, _0x33995b) {
    const _0x44d22e = a0_0x14a669;
    try {
      await this[_0x44d22e(0xed)][_0x44d22e(0xaa)](),
        (this[_0x44d22e(0xa5)][_0x57305c] = _0x33995b),
        _0x33995b(this[_0x44d22e(0x13c)]);
    } finally {
      this[_0x44d22e(0xed)]["release"]();
    }
  }
  async [a0_0x14a669(0x157)](_0x4e7a0f, _0x222d12) {
    const _0x56991f = a0_0x14a669;
    try {
      await this[_0x56991f(0x195)]["lock"](),
        (this[_0x56991f(0x164)][_0x4e7a0f] = _0x222d12);
    } finally {
      this[_0x56991f(0x195)]["release"]();
    }
  }
  async [a0_0x14a669(0xca)](_0xc69553) {
    const _0x2db4a1 = a0_0x14a669;
    try {
      await this[_0x2db4a1(0x195)][_0x2db4a1(0xaa)](),
        delete this[_0x2db4a1(0x164)][_0xc69553];
    } finally {
      this[_0x2db4a1(0x195)]["release"]();
    }
  }
  async [a0_0x14a669(0x197)](_0x5b8b18 = 0x2, _0x4f4121 = 0x1) {
    const _0x4040b7 = a0_0x14a669,
      _0x4b20ca = this[_0x4040b7(0xa4)] + _0x4040b7(0x1e2);
    try {
      const _0x3eaa37 = await fetch(_0x4b20ca, {
        method: "post",
        headers: {
          Accept: _0x4040b7(0x1d1),
          "Content-Type": "application/json",
          "X-MBX-APIKEY": this[_0x4040b7(0x1da)],
        },
      });
      if (_0x3eaa37[_0x4040b7(0xe9)] === 0xc8) {
        const _0x57a966 = await _0x3eaa37[_0x4040b7(0x19a)]();
        return _0x57a966[_0x4040b7(0x1f4)];
      } else {
        if (_0x4f4121 < _0x5b8b18)
          return (
            await sleep(0x3e8),
            await this[_0x4040b7(0x197)](_0x5b8b18, _0x4f4121 + 0x1)
          );
        return null;
      }
    } catch (_0x1bc2d2) {
      if (_0x4f4121 < _0x5b8b18)
        return (
          await sleep(0x3e8),
          await this[_0x4040b7(0x197)](_0x5b8b18, _0x4f4121 + 0x1)
        );
      return null;
    }
  }
  async [a0_0x14a669(0x108)]() {
    const _0x46f36c = a0_0x14a669;
    let _0x45f5e2 = this[_0x46f36c(0x136)];
    while (!![]) {
      if (_0x45f5e2 !== this[_0x46f36c(0x136)]) break;
      let _0x214eef = await this[_0x46f36c(0x197)]();
      (!_0x214eef || _0x214eef !== this[_0x46f36c(0x1f4)]) &&
        ((this[_0x46f36c(0x1f4)] = _0x214eef), this[_0x46f36c(0xf4)]()),
        await sleep(0x3e8 * 0x3c * 0x1e);
    }
  }
  async [a0_0x14a669(0xcc)](_0x331b01) {
    const _0x3ed53d = a0_0x14a669;
    try {
      if (
        this[_0x3ed53d(0xf7)] &&
        this[_0x3ed53d(0xf7)][_0x3ed53d(0x189)] ===
          this["listenSocket"][_0x3ed53d(0x14a)]
      ) {
        (this[_0x3ed53d(0x174)] = "11" + new Date()[_0x3ed53d(0xe8)]()),
          (this[_0x3ed53d(0x174)] = parseInt(this[_0x3ed53d(0x174)])),
          (this["listenSocketPong"] = ![]),
          this[_0x3ed53d(0xf7)]["send"](
            JSON["stringify"]({
              method: "LIST_SUBSCRIPTIONS",
              id: this["listenSocketPingId"],
            })
          );
        let _0x40ddeb = new Date();
        _0x40ddeb[_0x3ed53d(0x16c)](_0x40ddeb[_0x3ed53d(0x98)]() + 0x5a);
        while (!this[_0x3ed53d(0x1a7)]) {
          await sleep(0x1f4);
          if (_0x40ddeb < new Date()) break;
        }
        delete this[_0x3ed53d(0x174)];
        if (this["listenSocketPong"]) {
          if (
            this["listenSocketPong"][_0x3ed53d(0xc2)] === 0x0 ||
            this[_0x3ed53d(0x1a7)][0x0] !== this[_0x3ed53d(0x1f4)]
          )
            return ![];
        } else {
          if (!_0x331b01) return await this[_0x3ed53d(0xcc)](!![]);
        }
        return this[_0x3ed53d(0x1a7)];
      }
      return ![];
    } catch (_0x41f33a) {
      return ![];
    }
  }
  async ["ensureListenSocketIsOpen"]() {
    const _0x2183bc = a0_0x14a669;
    this[_0x2183bc(0x115)] += 0x1;
    this[_0x2183bc(0x115)] === 0xf4240 && (this[_0x2183bc(0x115)] = 0x1);
    let _0x546d79 = this[_0x2183bc(0x115)];
    while (!![]) {
      !(await this[_0x2183bc(0xcc)]()) && this[_0x2183bc(0x126)]();
      await sleep(0x3e8 * 0x3c * 0x3);
      if (_0x546d79 !== this[_0x2183bc(0x115)]) break;
    }
  }
  async [a0_0x14a669(0x126)]() {
    const _0x941cdb = a0_0x14a669;
    try {
      if (this["listenSocket"])
        try {
          this[_0x941cdb(0xf7)][_0x941cdb(0x121)]();
        } catch (_0x2f0745) {
          console[_0x941cdb(0x1c3)]("err\x20on\x20listenSocket.close()");
        }
      let _0x4d19c9 = await this["getAccountBalance"](
        this["key"],
        this[_0x941cdb(0x1f0)]
      );
      if (_0x4d19c9[0x0]) {
        (this[_0x941cdb(0x13c)] = { ..._0x4d19c9[0x0][_0x941cdb(0x192)] }),
          this["getDailyAccountSpanshot"](
            this[_0x941cdb(0x1da)],
            this[_0x941cdb(0x1f0)],
            _0x941cdb(0xc8),
            0x1e
          );
        try {
          await this[_0x941cdb(0xed)][_0x941cdb(0xaa)](),
            Object[_0x941cdb(0x119)](this[_0x941cdb(0xa5)])[_0x941cdb(0xc6)](
              (_0x4c2ad7) =>
                this[_0x941cdb(0xa5)][_0x4c2ad7](this[_0x941cdb(0x13c)])
            );
        } finally {
          this[_0x941cdb(0xed)][_0x941cdb(0x18e)]();
        }
      }
      (this[_0x941cdb(0xf7)] = new WebSocket(
        this[_0x941cdb(0xcb)] + "/" + this[_0x941cdb(0x1f4)]
      )),
        (this[_0x941cdb(0xf7)]["onopen"] = (_0x5f4872) => {
          const _0x373214 = _0x941cdb;
          executionReportServiceRestart(this[_0x373214(0xff)]),
            this["ensureListenSocketIsOpen"]();
        }),
        (this[_0x941cdb(0xf7)]["onmessage"] = async (_0x12753d) => {
          const _0x5ada44 = _0x941cdb;
          let _0x1da2c6 = JSON["parse"](_0x12753d[_0x5ada44(0xb1)]);
          if (this["listenSocketPingId"]) {
            if (_0x1da2c6 && _0x1da2c6["id"] == this[_0x5ada44(0x174)]) {
              (this["listenSocketPong"] = _0x1da2c6[_0x5ada44(0x1b6)]),
                delete this["listenSocketPingId"];
              return;
            }
          }
          if (_0x1da2c6 && _0x1da2c6["e"] === _0x5ada44(0x168)) {
            let _0x136490 = new Date(_0x1da2c6["E"]);
            if (
              !this[_0x5ada44(0x1d6)] ||
              this["accountInfoTime"] < _0x136490
            ) {
              (this[_0x5ada44(0x1d6)] = _0x136490),
                _0x1da2c6["B"][_0x5ada44(0xc6)]((_0x335c04) => {
                  const _0x1af71f = _0x5ada44;
                  let _0x965c88 = Number["parseFloat"](_0x335c04["f"]),
                    _0x11b51b = Number[_0x1af71f(0x144)](_0x335c04["l"]),
                    _0x59c0ee = _0x965c88 + _0x11b51b;
                  if (_0x59c0ee === 0x0 && this["assets"][_0x335c04["a"]])
                    this[_0x1af71f(0x13c)][_0x335c04["a"]] = {
                      total: _0x965c88 + _0x11b51b,
                      free: _0x965c88,
                      locked: _0x11b51b,
                    };
                  else
                    _0x59c0ee > 0x0 &&
                      (this["assets"][_0x335c04["a"]] = {
                        total: _0x965c88 + _0x11b51b,
                        free: _0x965c88,
                        locked: _0x11b51b,
                      });
                });
              try {
                await this["balanceUpdateSubscribersMutex"][_0x5ada44(0xaa)](),
                  Object[_0x5ada44(0x119)](this[_0x5ada44(0xa5)])["forEach"](
                    (_0x590751) =>
                      this[_0x5ada44(0xa5)][_0x590751](this[_0x5ada44(0x13c)])
                  );
              } finally {
                this[_0x5ada44(0xed)][_0x5ada44(0x18e)]();
              }
            }
          } else {
            if (_0x1da2c6 && _0x1da2c6["e"] === _0x5ada44(0x1d5))
              try {
                await this[_0x5ada44(0x195)]["lock"]();
                let _0x53c2a3 = ![];
                Object[_0x5ada44(0x119)](this[_0x5ada44(0x164)])[
                  _0x5ada44(0xc6)
                ]((_0x5bda3d) => {
                  _0x5bda3d == _0x1da2c6["i"] &&
                    ((_0x53c2a3 = !![]),
                    this["orderUpdateSubscribers"][_0x5bda3d](_0x1da2c6));
                });
                !_0x53c2a3 &&
                  (_0x53c2a3 = await this[_0x5ada44(0x1e1)](_0x1da2c6));
                if (!_0x53c2a3)
                  try {
                    await this[_0x5ada44(0x102)][_0x5ada44(0xaa)]();
                    let _0x1f499c = new Date();
                    _0x1f499c[_0x5ada44(0x1a9)](
                      _0x1f499c[_0x5ada44(0x9e)]() + 0xa
                    ),
                      this[_0x5ada44(0x1ee)]["push"]({
                        expDate: _0x1f499c[_0x5ada44(0xe8)](),
                        data: _0x1da2c6,
                      });
                  } finally {
                    this[_0x5ada44(0x102)][_0x5ada44(0x18e)]();
                  }
              } finally {
                this[_0x5ada44(0x195)]["release"]();
              }
          }
        });
    } catch (_0x46e3c3) {
      console[_0x941cdb(0x1c3)](_0x941cdb(0x17f), _0x46e3c3);
    }
  }
  async [a0_0x14a669(0x1e1)](_0x5b14a3) {
    const _0x6caccf = a0_0x14a669;
    let _0x29d2e4 = ![];
    try {
      await this[_0x6caccf(0xf1)][_0x6caccf(0xaa)]();
      let _0x59a7cf = this[_0x6caccf(0x9c)][_0x6caccf(0xe2)](
          (_0x1376a9, _0x2301a2) =>
            _0x1376a9[_0x6caccf(0x124)] == _0x5b14a3["i"]
        ),
        _0x11922c;
      _0x59a7cf
        ? (_0x11922c = _0x6caccf(0x1fc))
        : ((_0x59a7cf = this[_0x6caccf(0x9c)][_0x6caccf(0xe2)](
            (_0x22c9df, _0x39fab7) =>
              _0x22c9df[_0x6caccf(0xbc)] == _0x5b14a3["i"]
          )),
          (_0x11922c = _0x6caccf(0x121)));
      if (_0x59a7cf) {
        _0x29d2e4 = !![];
        let _0x282188 = {
          sizeInBase: +_0x5b14a3["z"],
          sizeInQuoted: +_0x5b14a3["Z"],
          fills: [
            {
              p: +_0x5b14a3["L"],
              q: +_0x5b14a3["l"],
              c: {
                bnb:
                  _0x5b14a3["N"] === "BNB" &&
                  _0x59a7cf[_0x6caccf(0x15d)]["baseAsset"] !==
                    _0x6caccf(0xa2) &&
                  _0x59a7cf[_0x6caccf(0x15d)][_0x6caccf(0xf0)] !== "BNB"
                    ? fixNumber(+_0x5b14a3["n"], 0xc)
                    : 0x0,
                base:
                  _0x5b14a3["N"] ===
                  _0x59a7cf[_0x6caccf(0x15d)][_0x6caccf(0x163)]
                    ? fixNumber(+_0x5b14a3["n"], 0xc)
                    : 0x0,
                quote:
                  _0x5b14a3["N"] ===
                  _0x59a7cf[_0x6caccf(0x15d)][_0x6caccf(0xf0)]
                    ? fixNumber(+_0x5b14a3["n"], 0xc)
                    : 0x0,
              },
            },
          ],
          date: _0x5b14a3["E"],
          status: _0x5b14a3["X"],
          price: _0x5b14a3["z"]
            ? roundQuotedValue(
                +_0x5b14a3["Z"] / +_0x5b14a3["z"],
                _0x59a7cf["pairDetails"]
              )
            : 0x0,
          id: _0x5b14a3["i"],
        };
        switch (_0x282188[_0x6caccf(0xe9)]) {
          case _0x6caccf(0x182):
            this["removeManualTrade"](_0x59a7cf["id"]);
            break;
          case _0x6caccf(0x14d):
          case _0x6caccf(0x145):
            if (_0x11922c === _0x6caccf(0x1fc)) {
              _0x282188[_0x6caccf(0x95)][_0x6caccf(0xc6)]((_0x116856) =>
                _0x59a7cf[_0x6caccf(0x1cd)][_0x6caccf(0x132)](_0x116856)
              ),
                (_0x59a7cf[_0x6caccf(0x150)] = _0x59a7cf[_0x6caccf(0x1cd)][
                  "reduce"
                ](
                  (_0x3d8a51, _0x2a5796) => {
                    const _0x5e4ddd = _0x6caccf;
                    return (
                      (_0x3d8a51["bnb"] +=
                        _0x2a5796["c"] && _0x2a5796["c"]["bnb"]
                          ? _0x2a5796["c"]["bnb"]
                          : 0x0),
                      (_0x3d8a51[_0x5e4ddd(0xd5)] +=
                        _0x2a5796["c"] && _0x2a5796["c"][_0x5e4ddd(0xd5)]
                          ? _0x2a5796["c"][_0x5e4ddd(0xd5)]
                          : 0x0),
                      (_0x3d8a51[_0x5e4ddd(0x120)] +=
                        _0x2a5796["c"] && _0x2a5796["c"]["quote"]
                          ? _0x2a5796["c"]["quote"]
                          : 0x0),
                      _0x3d8a51
                    );
                  },
                  { bnb: 0x0, base: 0x0, quote: 0x0 }
                ));
              if (
                _0x59a7cf[_0x6caccf(0xe9)] === _0x6caccf(0x14d) &&
                !_0x59a7cf["cStatus"]
              )
                return (
                  getEcbDatabase()[_0x6caccf(0x190)](_0x59a7cf),
                  Object[_0x6caccf(0x119)](this[_0x6caccf(0x1e7)])["forEach"](
                    (_0x3eb2f5) => {
                      const _0x248fc4 = _0x6caccf;
                      this[_0x248fc4(0x1e7)][_0x3eb2f5]([
                        ...this[_0x248fc4(0x9c)],
                      ]);
                    }
                  ),
                  !![]
                );
              (_0x59a7cf[_0x6caccf(0xe9)] = _0x282188[_0x6caccf(0xe9)]),
                (_0x59a7cf["o"] = _0x282188[_0x6caccf(0x18a)]),
                (_0x59a7cf[_0x6caccf(0x1b0)] = _0x282188[_0x6caccf(0x1f9)]),
                (_0x59a7cf[_0x6caccf(0x16e)] = _0x282188[_0x6caccf(0x134)]),
                (_0x59a7cf[_0x6caccf(0x1bb)] = new Date()[_0x6caccf(0xe8)]()),
                getEcbDatabase()[_0x6caccf(0x190)](_0x59a7cf),
                Object["keys"](this[_0x6caccf(0x1e7)])[_0x6caccf(0xc6)](
                  (_0x2c864c) => {
                    const _0x18b6fa = _0x6caccf;
                    this[_0x18b6fa(0x1e7)][_0x2c864c]([
                      ...this[_0x18b6fa(0x9c)],
                    ]);
                  }
                );
            } else {
              _0x282188[_0x6caccf(0x95)][_0x6caccf(0xc6)]((_0x5e0fb8) =>
                _0x59a7cf[_0x6caccf(0x11a)][_0x6caccf(0x132)](_0x5e0fb8)
              ),
                (_0x59a7cf[_0x6caccf(0x1c9)] = _0x59a7cf[_0x6caccf(0x11a)][
                  _0x6caccf(0x14f)
                ](
                  (_0x11efa8, _0x45ce18) => {
                    const _0x453245 = _0x6caccf;
                    return (
                      (_0x11efa8["bnb"] +=
                        _0x45ce18["c"] && _0x45ce18["c"][_0x453245(0xaf)]
                          ? _0x45ce18["c"]["bnb"]
                          : 0x0),
                      (_0x11efa8[_0x453245(0xd5)] +=
                        _0x45ce18["c"] && _0x45ce18["c"]["base"]
                          ? _0x45ce18["c"]["base"]
                          : 0x0),
                      (_0x11efa8["quote"] +=
                        _0x45ce18["c"] && _0x45ce18["c"][_0x453245(0x120)]
                          ? _0x45ce18["c"][_0x453245(0x120)]
                          : 0x0),
                      _0x11efa8
                    );
                  },
                  { bnb: 0x0, base: 0x0, quote: 0x0 }
                )),
                (_0x59a7cf[_0x6caccf(0x1b4)] = _0x282188[_0x6caccf(0xe9)]),
                (_0x59a7cf["c"] = _0x282188[_0x6caccf(0x18a)]),
                (_0x59a7cf[_0x6caccf(0x1af)] = _0x282188[_0x6caccf(0x1f9)]),
                (_0x59a7cf[_0x6caccf(0x129)] = _0x282188[_0x6caccf(0x134)]),
                (_0x59a7cf["editDate"] = new Date()[_0x6caccf(0xe8)]());
              if (_0x59a7cf[_0x6caccf(0x1b4)] === "FILLED") {
                let _0x23b5f6 =
                    getQuotedValue(
                      _0x59a7cf["oComm"][_0x6caccf(0xaf)],
                      "BNB",
                      _0x59a7cf["pairDetails"][_0x6caccf(0xf0)],
                      this[_0x6caccf(0xc4)]
                    ) +
                    _0x59a7cf[_0x6caccf(0x150)][_0x6caccf(0xd5)] *
                      _0x59a7cf["o"] +
                    _0x59a7cf[_0x6caccf(0x150)][_0x6caccf(0x120)],
                  _0x58fe16 =
                    getQuotedValue(
                      _0x59a7cf["cComm"][_0x6caccf(0xaf)],
                      _0x6caccf(0xa2),
                      _0x59a7cf[_0x6caccf(0x15d)]["quoteAsset"],
                      this[_0x6caccf(0xc4)]
                    ) +
                    _0x59a7cf["cComm"][_0x6caccf(0xd5)] * _0x59a7cf["c"] +
                    _0x59a7cf["cComm"]["quote"];
                (_0x59a7cf[_0x6caccf(0x149)] =
                  _0x59a7cf[_0x6caccf(0x129)] -
                  _0x59a7cf[_0x6caccf(0x16e)] -
                  _0x23b5f6 -
                  _0x58fe16),
                  (_0x59a7cf[_0x6caccf(0x1b6)] =
                    (_0x59a7cf[_0x6caccf(0x149)] / _0x59a7cf["oSizeInQuoted"]) *
                    0x64),
                  getEcbDatabase()[_0x6caccf(0x1ca)](_0x59a7cf),
                  this[_0x6caccf(0x1ec)](_0x59a7cf["id"], !![]);
              } else
                getEcbDatabase()[_0x6caccf(0x190)](_0x59a7cf),
                  Object[_0x6caccf(0x119)](this["manualTradesSubscribers"])[
                    _0x6caccf(0xc6)
                  ]((_0x43f7b6) => {
                    const _0x5da31a = _0x6caccf;
                    this[_0x5da31a(0x1e7)][_0x43f7b6]([
                      ...this[_0x5da31a(0x9c)],
                    ]);
                  });
            }
            break;
          case _0x6caccf(0x113):
            break;
          default:
            this[_0x6caccf(0x1ec)](_0x59a7cf["id"]);
        }
      }
    } finally {
      this[_0x6caccf(0xf1)][_0x6caccf(0x18e)]();
    }
    return _0x29d2e4;
  }
  async [a0_0x14a669(0x15f)]() {
    const _0x5bbd9a = a0_0x14a669;
    while (!![]) {
      try {
        await this["orderUpdatesUnknownMutex"][_0x5bbd9a(0xaa)]();
        for (let _0x42c19a of this[_0x5bbd9a(0x1ee)]) {
          Object["keys"](this[_0x5bbd9a(0x164)])[_0x5bbd9a(0xc6)](
            (_0xbd7b96) => {
              const _0x4b7750 = _0x5bbd9a;
              _0xbd7b96 == _0x42c19a["data"]["i"] &&
                ((_0x42c19a[_0x4b7750(0x1a1)] = !![]),
                this[_0x4b7750(0x164)][_0xbd7b96](_0x42c19a["data"]));
            }
          ),
            !_0x42c19a[_0x5bbd9a(0x1a1)] &&
              (_0x42c19a[_0x5bbd9a(0x1a1)] = await this[
                "checkManualOrderStatusUpdate"
              ](_0x42c19a[_0x5bbd9a(0xb1)])),
            !_0x42c19a[_0x5bbd9a(0x1a1)] &&
              _0x42c19a["expDate"] < new Date()[_0x5bbd9a(0xe8)]() &&
              (_0x42c19a[_0x5bbd9a(0x1a1)] = !![]);
        }
        this[_0x5bbd9a(0x1ee)] = this[_0x5bbd9a(0x1ee)][_0x5bbd9a(0x1cf)](
          (_0x5ec0f4) => !_0x5ec0f4["toRemove"]
        );
      } finally {
        this[_0x5bbd9a(0x102)][_0x5bbd9a(0x18e)]();
      }
      await sleep(0x3e8);
    }
  }
  [a0_0x14a669(0xa7)]() {
    return this["prices"];
  }
  async ["getPrice"](_0x33d05a, _0x3f04ba = 0xa) {
    const _0x9b2466 = a0_0x14a669;
    let _0x119e1c = new Date();
    _0x119e1c[_0x9b2466(0x16c)](_0x119e1c["getSeconds"]() + _0x3f04ba),
      (_0x119e1c = _0x119e1c[_0x9b2466(0xe8)]());
    while (
      (!this[_0x9b2466(0x1bd)] || !this[_0x9b2466(0xc4)][_0x33d05a]) &&
      _0x119e1c > new Date()[_0x9b2466(0xe8)]()
    ) {
      await sleep(0x3e8 * 0x2);
    }
    return this[_0x9b2466(0x1bd)] ? this[_0x9b2466(0xc4)][_0x33d05a] : null;
  }
  [a0_0x14a669(0xb7)]() {
    const _0x2bc366 = a0_0x14a669;
    return this[_0x2bc366(0x13c)];
  }
  [a0_0x14a669(0xe7)](_0x23ab78) {
    const _0x56f210 = a0_0x14a669;
    this[_0x56f210(0x13c)] = _0x23ab78;
  }
  ["setDemoSnapshotsData"](_0x3463ee) {
    const _0x40c455 = a0_0x14a669;
    this[_0x40c455(0x1b7)] = _0x3463ee;
  }
  [a0_0x14a669(0xfa)]() {
    const _0x58b1ba = a0_0x14a669;
    let _0x597883 = this["getPrices"](),
      _0x2f4778 = Object["keys"](this[_0x58b1ba(0x13c)])["reduce"](
        (_0x5d06c0, _0x7d9f5c) => {
          const _0x404432 = _0x58b1ba;
          let _0xcbc0f0 = getUsdValue(
            this[_0x404432(0x13c)][_0x7d9f5c]["total"],
            _0x7d9f5c,
            _0x597883
          );
          if (_0xcbc0f0) return _0x5d06c0 + _0xcbc0f0;
          return _0x5d06c0;
        },
        0x0
      );
    return _0x2f4778;
  }
  [a0_0x14a669(0x18c)](_0x5d44a6) {
    const _0x57ecca = a0_0x14a669;
    return this[_0x57ecca(0x13c)][_0x5d44a6]
      ? this[_0x57ecca(0x13c)][_0x5d44a6]
      : 0x0;
  }
  async [a0_0x14a669(0x1c1)](_0x2d4ea3) {
    const _0x427170 = a0_0x14a669;
    try {
      if (
        this["priceListenSocket"] &&
        this[_0x427170(0xe0)]["readyState"] ===
          this[_0x427170(0xe0)][_0x427170(0x14a)]
      ) {
        (this["priceListeningPingId"] = "12" + new Date()[_0x427170(0xe8)]()),
          (this[_0x427170(0x1ae)] = parseInt(this["priceListeningPingId"])),
          (this["priceListeningPong"] = null),
          this["priceListenSocket"][_0x427170(0xa9)](
            JSON[_0x427170(0x159)]({
              method: _0x427170(0x101),
              id: this[_0x427170(0x1ae)],
            })
          );
        let _0x5087bb = new Date();
        _0x5087bb[_0x427170(0x16c)](_0x5087bb[_0x427170(0x98)]() + 0x3c);
        while (!this[_0x427170(0x93)]) {
          await sleep(0x1f4);
          if (_0x5087bb < new Date()) break;
        }
        delete this[_0x427170(0x1ae)];
        if (this[_0x427170(0x93)])
          (this[_0x427170(0x93)]["length"] === 0x0 ||
            this[_0x427170(0x93)][0x0] !== _0x427170(0x15b)) &&
            this[_0x427170(0xe0)][_0x427170(0xa9)](
              JSON[_0x427170(0x159)]({
                method: _0x427170(0x1a4),
                params: [_0x427170(0x15b)],
                id: new Date()[_0x427170(0xe8)](),
              })
            );
        else {
          if (!_0x2d4ea3) return await this[_0x427170(0x1c1)](!![]);
        }
        return this[_0x427170(0x93)];
      }
      return ![];
    } catch (_0x2a23dc) {
      return ![];
    }
  }
  async [a0_0x14a669(0x94)]() {
    const _0x3e08c2 = a0_0x14a669;
    this["ensurePriceListenSocketIsOpenId"] += 0x1;
    this[_0x3e08c2(0x10f)] === 0xf4240 && (this[_0x3e08c2(0x10f)] = 0x1);
    let _0x50d73f = this[_0x3e08c2(0x10f)];
    while (!![]) {
      !(await this["checkPriceListeningWebsocket"]()) &&
        this[_0x3e08c2(0x1c2)]();
      await sleep(0x3e8 * 0x3c * 0x3);
      if (_0x50d73f !== this[_0x3e08c2(0x10f)]) break;
    }
  }
  async [a0_0x14a669(0x1c2)]() {
    const _0x252bc4 = a0_0x14a669;
    try {
      if (this["priceListenSocket"])
        try {
          this[_0x252bc4(0xe0)][_0x252bc4(0x121)]();
        } catch (_0x122296) {
          console[_0x252bc4(0x1c3)](_0x252bc4(0x1f3));
        }
      (this["priceListenSocket"] = new WebSocket(this[_0x252bc4(0xcb)])),
        (this["priceListenSocket"][_0x252bc4(0x18b)] = async (_0x2d67b3) => {
          const _0x3c93d0 = _0x252bc4;
          let _0x86e7e2 = [_0x3c93d0(0x15b)];
          Object[_0x3c93d0(0x119)](this[_0x3c93d0(0x12a)])[_0x3c93d0(0xc6)](
            (_0x2e2124) => {
              const _0x2fb9b2 = _0x3c93d0;
              let [_0x33b441, _0x28250c] = _0x2e2124[_0x2fb9b2(0xd4)]("-");
              _0x86e7e2["push"](
                _0x33b441[_0x2fb9b2(0x12c)]() + _0x2fb9b2(0x1c5) + _0x28250c
              );
            }
          ),
            this[_0x3c93d0(0xe0)][_0x3c93d0(0xa9)](
              JSON[_0x3c93d0(0x159)]({
                method: _0x3c93d0(0x1a4),
                params: _0x86e7e2,
                id: new Date()["getTime"](),
              })
            ),
            this[_0x3c93d0(0x94)]();
        });
      let _0x50eb71 = null;
      this[_0x252bc4(0xe0)][_0x252bc4(0x173)] = async (_0x28a701) => {
        const _0x1da4a1 = _0x252bc4;
        let _0x445b44 = JSON[_0x1da4a1(0xef)](_0x28a701[_0x1da4a1(0xb1)]);
        if (_0x445b44["e"] && _0x445b44["e"] === _0x1da4a1(0xb3)) {
          try {
            let _0x6cf760 = _0x445b44["s"] + "-" + _0x445b44["k"]["i"];
            if (this[_0x1da4a1(0x12a)][_0x6cf760]) {
              let _0x31ad6e =
                this["chartData"][_0x6cf760][_0x1da4a1(0xb1)][
                  this[_0x1da4a1(0x12a)][_0x6cf760][_0x1da4a1(0xb1)][
                    _0x1da4a1(0xc2)
                  ] - 0x1
                ];
              if (_0x31ad6e) {
                let _0x4b9052 = Number[_0x1da4a1(0x144)](_0x445b44["k"]["o"]),
                  _0x1ae407 = Number[_0x1da4a1(0x144)](_0x445b44["k"]["h"]),
                  _0x29e022 = Number["parseFloat"](_0x445b44["k"]["l"]),
                  _0x455089 = Number[_0x1da4a1(0x144)](_0x445b44["k"]["c"]),
                  _0xd8ef7a = Number[_0x1da4a1(0x144)](_0x445b44["k"]["v"]),
                  _0x6fcf6c = _0x445b44["k"]["n"];
                _0x445b44["k"]["T"] === _0x31ad6e["cd"]
                  ? ((_0x31ad6e["o"] = _0x4b9052),
                    (_0x31ad6e["h"] = _0x1ae407),
                    (_0x31ad6e["l"] = _0x29e022),
                    (_0x31ad6e["c"] = _0x455089),
                    (_0x31ad6e["v"] = _0xd8ef7a),
                    (_0x31ad6e["tn"] = _0x6fcf6c))
                  : ((this[_0x1da4a1(0x12a)][_0x6cf760][_0x1da4a1(0x1f5)] !==
                      _0x1da4a1(0x199) ||
                      this["chartData"][_0x6cf760][_0x1da4a1(0xb1)][
                        _0x1da4a1(0xc2)
                      ] > 0x186a0) &&
                      this["chartData"][_0x6cf760][_0x1da4a1(0xb1)]["splice"](
                        0x0,
                        0x1
                      ),
                    this[_0x1da4a1(0x12a)][_0x6cf760][_0x1da4a1(0xb1)][
                      _0x1da4a1(0x132)
                    ]({
                      d: _0x445b44["k"]["t"],
                      cd: _0x445b44["k"]["T"],
                      o: _0x4b9052,
                      h: _0x1ae407,
                      l: _0x29e022,
                      c: _0x455089,
                      v: _0xd8ef7a,
                      tn: _0x6fcf6c,
                    })),
                  Object["keys"](
                    this["chartData"][_0x6cf760][_0x1da4a1(0x1aa)]
                  )["forEach"]((_0x1e0f36) => {
                    const _0x89742e = _0x1da4a1;
                    this[_0x89742e(0x12a)][_0x6cf760][_0x89742e(0x1aa)][
                      _0x1e0f36
                    ](this[_0x89742e(0x12a)][_0x6cf760][_0x89742e(0xb1)]);
                  });
              }
            }
          } catch (_0x45c35) {
            return;
          } finally {
          }
          return;
        }
        if (this[_0x1da4a1(0x1ae)]) {
          if (_0x445b44 && _0x445b44["id"] == this["priceListeningPingId"]) {
            (this[_0x1da4a1(0x93)] = _0x445b44[_0x1da4a1(0x1b6)]),
              delete this[_0x1da4a1(0x1ae)];
            return;
          }
        }
        if (_0x445b44 && _0x445b44[_0x1da4a1(0xc2)]) {
          _0x445b44[_0x1da4a1(0xc6)]((_0x236276) => {
            const _0x4bf9ef = _0x1da4a1;
            (this["prices"][_0x236276["s"]] = Number["parseFloat"](
              _0x236276["c"]
            )),
              (this[_0x4bf9ef(0x191)][_0x236276["s"]] = {
                s: _0x236276["s"],
                c: Number[_0x4bf9ef(0x144)](_0x236276["c"]),
                o: Number[_0x4bf9ef(0x144)](_0x236276["o"]),
                h: Number[_0x4bf9ef(0x144)](_0x236276["h"]),
                l: Number[_0x4bf9ef(0x144)](_0x236276["l"]),
                ch: Number[_0x4bf9ef(0x144)](_0x236276["p"]),
                chP: +Number[_0x4bf9ef(0x144)](_0x236276["P"]),
                tn: +Number["parseFloat"](_0x236276["n"]),
                volQ: +Number["parseFloat"](_0x236276["q"]),
                volB: +Number[_0x4bf9ef(0x144)](_0x236276["v"]),
                bid: +Number[_0x4bf9ef(0x144)](_0x236276["b"]),
                bidQ: +Number[_0x4bf9ef(0x144)](_0x236276["B"]),
                ask: +Number[_0x4bf9ef(0x144)](_0x236276["a"]),
                askQ: +Number[_0x4bf9ef(0x144)](_0x236276["A"]),
              });
          });
          let _0x2b4b6f = new Date();
          if (_0x50eb71 && _0x50eb71 > _0x2b4b6f) return;
          (_0x50eb71 = _0x2b4b6f),
            _0x50eb71[_0x1da4a1(0x16c)](_0x50eb71[_0x1da4a1(0x98)]() + 0x2),
            this[_0x1da4a1(0x17e)]();
          try {
            await this[_0x1da4a1(0x15e)][_0x1da4a1(0xaa)](),
              Object["keys"](this["priceUpdateSubscribers"])[_0x1da4a1(0xc6)](
                (_0x15acaf) => {
                  const _0x448c07 = _0x1da4a1;
                  this[_0x448c07(0xb9)][_0x15acaf] &&
                    this[_0x448c07(0xb9)][_0x15acaf](
                      { ...this[_0x448c07(0xc4)] },
                      { ...this[_0x448c07(0x191)] }
                    );
                }
              );
          } finally {
            this[_0x1da4a1(0x15e)][_0x1da4a1(0x18e)]();
          }
        }
      };
    } catch (_0xfa9093) {
      console[_0x252bc4(0x1c3)]("ERROR\x201", _0xfa9093);
    }
  }
  async ["checkManualTrades"]() {
    const _0x52b746 = a0_0x14a669;
    try {
      await this[_0x52b746(0xf1)][_0x52b746(0xaa)]();
      let _0x406638 = this[_0x52b746(0x9c)][_0x52b746(0x1cf)](
        (_0x2dc476) =>
          _0x2dc476[_0x52b746(0xe9)] === _0x52b746(0x14d) &&
          !_0x2dc476["cError"] &&
          !_0x2dc476["cStatus"] &&
          (_0x2dc476[_0x52b746(0xf9)] || _0x2dc476[_0x52b746(0x154)])
      );
      _0x406638[_0x52b746(0xc6)]((_0x1a1901) => {
        const _0x2ecd53 = _0x52b746;
        if (_0x1a1901[_0x2ecd53(0x1d2)]) return;
        let _0x16f5a7 = this[_0x2ecd53(0x191)][_0x1a1901[_0x2ecd53(0x125)]],
          _0x4fbc70 = ![];
        if (_0x16f5a7) {
          let _0x42a5fc, _0x11ea46;
          _0x1a1901[_0x2ecd53(0xf9)] &&
            (_0x1a1901[_0x2ecd53(0xf3)] === "Buy"
              ? ((_0x4fbc70 =
                  _0x16f5a7[_0x2ecd53(0xa6)] &&
                  _0x16f5a7["bid"] <= _0x1a1901["stoploss"]),
                (_0x42a5fc = _0x16f5a7["bid"]),
                (_0x11ea46 = _0x2ecd53(0xf9)))
              : ((_0x4fbc70 =
                  _0x16f5a7[_0x2ecd53(0x1be)] &&
                  _0x16f5a7[_0x2ecd53(0x1be)] >= _0x1a1901[_0x2ecd53(0xf9)]),
                (_0x42a5fc = _0x16f5a7[_0x2ecd53(0x1be)]),
                (_0x11ea46 = _0x2ecd53(0xf9)))),
            !_0x4fbc70 &&
              _0x1a1901[_0x2ecd53(0x154)] &&
              (_0x1a1901["direction"] === _0x2ecd53(0xbb)
                ? ((_0x4fbc70 =
                    _0x16f5a7[_0x2ecd53(0xa6)] &&
                    _0x16f5a7[_0x2ecd53(0xa6)] >= _0x1a1901[_0x2ecd53(0x154)]),
                  (_0x42a5fc = _0x16f5a7[_0x2ecd53(0xa6)]),
                  (_0x11ea46 = _0x2ecd53(0x154)))
                : ((_0x4fbc70 =
                    _0x16f5a7[_0x2ecd53(0x1be)] &&
                    _0x16f5a7[_0x2ecd53(0x1be)] <= _0x1a1901[_0x2ecd53(0x154)]),
                  (_0x42a5fc = _0x16f5a7[_0x2ecd53(0x1be)]),
                  (_0x11ea46 = _0x2ecd53(0x154)))),
            _0x4fbc70 &&
              ((_0x1a1901[_0x2ecd53(0x1d2)] = !![]),
              this[_0x2ecd53(0x1f6)](_0x1a1901, _0x42a5fc, _0x11ea46));
        }
      });
    } finally {
      this[_0x52b746(0xf1)]["release"]();
    }
  }
  async [a0_0x14a669(0x1a3)](_0x18b04a, _0x7f8174) {
    const _0x456b20 = a0_0x14a669;
    let _0x570009 = await this[_0x456b20(0x133)](_0x18b04a, _0x7f8174);
    if (_0x570009[0x0])
      return (
        (this[_0x456b20(0x13c)] = { ..._0x570009[0x0][_0x456b20(0x192)] }),
        (this["key"] = _0x18b04a),
        (this[_0x456b20(0x1f0)] = _0x7f8174),
        (this[_0x456b20(0x148)] = !![]),
        this[_0x456b20(0x136)]++,
        this[_0x456b20(0x108)](),
        _0x570009
      );
    return (this[_0x456b20(0x148)] = !![]), _0x570009;
  }
  async [a0_0x14a669(0xd8)](
    _0x1af25e,
    _0x2f84d3,
    _0x61a2e4,
    _0x279df9,
    _0x4cfcbc = 0x3,
    _0x1db915 = 0x1
  ) {
    const _0x18eda8 = a0_0x14a669;
    let _0x37d1de = new Date();
    _0x37d1de["setSeconds"](
      _0x37d1de[_0x18eda8(0x98)]() - 0xa,
      _0x37d1de["getMilliseconds"]()
    );
    let _0x463592 = await this[_0x18eda8(0xae)](),
      _0x3f394c = new URLSearchParams({
        recvWindow: 0xe678,
        timestamp: _0x463592 ? _0x463592 : _0x37d1de[_0x18eda8(0xe8)](),
      });
    try {
      let _0x595002 = CryptoJS[_0x18eda8(0x10b)](
        _0x3f394c[_0x18eda8(0xd3)](),
        _0x2f84d3
      );
      const _0x2577a0 = await fetch(
        this[_0x18eda8(0xa4)] +
          _0x18eda8(0xd2) +
          _0x3f394c +
          "&signature=" +
          _0x595002,
        {
          method: "get",
          headers: {
            Accept: _0x18eda8(0x1d1),
            "Content-Type": _0x18eda8(0x1d1),
            "X-MBX-APIKEY": _0x1af25e,
          },
        }
      );
      if (_0x2577a0[_0x18eda8(0xe9)] === 0xc8) {
        const _0x2cae6b = await _0x2577a0[_0x18eda8(0x19a)]();
        let _0x10733d = {
          [_0x61a2e4[_0x18eda8(0x12c)]()]: {
            balances: {},
            totalAssetOfBtc: 0x0,
            snapshots: [],
          },
        };
        return (
          _0x2cae6b[_0x18eda8(0x192)][_0x18eda8(0xc6)]((_0x563856) => {
            const _0x2bdfdb = _0x18eda8;
            let _0x3667e6 = Number[_0x2bdfdb(0x144)](
                _0x563856[_0x2bdfdb(0x187)]
              ),
              _0x5510be = Number[_0x2bdfdb(0x144)](_0x563856[_0x2bdfdb(0x9a)]);
            _0x10733d[_0x61a2e4["toLowerCase"]()]["balances"][
              _0x563856[_0x2bdfdb(0x153)]
            ] = {
              total: _0x3667e6 + _0x5510be,
              free: _0x3667e6,
              locked: _0x5510be,
            };
          }),
          [_0x10733d]
        );
      } else {
        if (_0x1db915 < _0x4cfcbc)
          return (
            await sleep(0x3e8),
            await this[_0x18eda8(0xd8)](
              _0x1af25e,
              _0x2f84d3,
              _0x61a2e4,
              _0x279df9,
              _0x4cfcbc,
              _0x1db915 + 0x1
            )
          );
        const _0x54ed94 = await _0x2577a0[_0x18eda8(0x19a)]();
        return [
          null,
          _0x18eda8(0xfc) +
            (_0x54ed94["msg"]
              ? _0x54ed94[_0x18eda8(0xf8)]
              : JSON[_0x18eda8(0x159)](_0x54ed94)),
        ];
      }
    } catch (_0x1e13f3) {
      if (_0x1db915 < _0x4cfcbc)
        return (
          await sleep(0x3e8),
          await this[_0x18eda8(0xd8)](
            _0x1af25e,
            _0x2f84d3,
            _0x61a2e4,
            _0x279df9,
            _0x4cfcbc,
            _0x1db915 + 0x1
          )
        );
      return [null, "Connection\x20issue.\x20Please\x20try\x20later."];
    }
  }
  async [a0_0x14a669(0x137)](
    _0x9a033,
    _0x1668cc,
    _0x2b9d44,
    _0x5a166c,
    _0x1e49ef = 0x3,
    _0x47a4a1 = 0x1
  ) {
    const _0xc45956 = a0_0x14a669;
    let _0x5d894b = new Date();
    _0x5d894b[_0xc45956(0x16c)](
      _0x5d894b[_0xc45956(0x98)]() - 0xa,
      _0x5d894b[_0xc45956(0x175)]()
    );
    let _0x20f095 = await this["getServerTime"](),
      _0x3edc79 = new URLSearchParams({
        recvWindow: 0xe678,
        timestamp: _0x20f095 ? _0x20f095 : _0x5d894b[_0xc45956(0xe8)](),
        type: _0x2b9d44,
        limit: _0x5a166c,
      });
    try {
      let _0x541538 = CryptoJS[_0xc45956(0x10b)](
        _0x3edc79[_0xc45956(0xd3)](),
        _0x1668cc
      );
      const _0x53c9b6 = await fetch(
        this[_0xc45956(0xa4)] +
          _0xc45956(0x169) +
          _0x3edc79 +
          "&signature=" +
          _0x541538,
        {
          method: _0xc45956(0x147),
          headers: {
            Accept: _0xc45956(0x1d1),
            "Content-Type": _0xc45956(0x1d1),
            "X-MBX-APIKEY": _0x9a033,
          },
        }
      );
      if (_0x53c9b6["status"] === 0xc8) {
        const _0x4a0ee7 = await _0x53c9b6[_0xc45956(0x19a)]();
        let _0x3d16e6 = [];
        _0x4a0ee7["snapshotVos"] &&
          _0x4a0ee7[_0xc45956(0x1c4)][_0xc45956(0xc2)] > 0x0 &&
          (_0x3d16e6 = _0x4a0ee7[_0xc45956(0x1c4)][_0xc45956(0x12e)](
            (_0x1b6787) => ({
              d: new Date(_0x1b6787[_0xc45956(0x196)]),
              btc: Number[_0xc45956(0x144)](
                _0x1b6787["data"]["totalAssetOfBtc"]
              ),
            })
          ));
        await this[_0xc45956(0x14c)](_0x3d16e6),
          (this[_0xc45956(0x1b7)] = _0x3d16e6);
        try {
          await this[_0xc45956(0xce)]["lock"](),
            Object["keys"](this[_0xc45956(0x179)])[_0xc45956(0xc6)](
              (_0x359287) => {
                const _0x2c337d = _0xc45956;
                this[_0x2c337d(0x179)][_0x359287] &&
                  this["balanceSnapshotsUpdateSubscribers"][_0x359287](
                    _0x3d16e6
                  );
              }
            );
        } finally {
          this[_0xc45956(0xce)][_0xc45956(0x18e)]();
        }
      } else {
        if (_0x47a4a1 < _0x1e49ef)
          return (
            await sleep(0x3e8),
            await this[_0xc45956(0x137)](
              _0x9a033,
              _0x1668cc,
              _0x2b9d44,
              _0x5a166c,
              _0x1e49ef,
              _0x47a4a1 + 0x1
            )
          );
        const _0x4cc706 = await _0x53c9b6["json"]();
        return [
          null,
          _0xc45956(0xfc) +
            (_0x4cc706[_0xc45956(0xf8)]
              ? _0x4cc706[_0xc45956(0xf8)]
              : JSON[_0xc45956(0x159)](_0x4cc706)),
        ];
      }
    } catch (_0x5bc59a) {
      if (_0x47a4a1 < _0x1e49ef)
        return (
          await sleep(0x3e8),
          await this[_0xc45956(0x137)](
            _0x9a033,
            _0x1668cc,
            _0x2b9d44,
            _0x5a166c,
            _0x1e49ef,
            _0x47a4a1 + 0x1
          )
        );
      return [null, _0xc45956(0x18f)];
    }
  }
  async [a0_0x14a669(0x14c)](_0x4cfe8b) {
    const _0x2c7329 = a0_0x14a669;
    let _0x326d44 = new Date();
    _0x4cfe8b[0x0]
      ? (_0x326d44 = _0x4cfe8b[0x0]["d"])
      : _0x326d44[_0x2c7329(0x104)](_0x326d44[_0x2c7329(0xc5)]() - 0x1e);
    let _0x588313 = new Date(),
      _0x5d10c0 = await this[_0x2c7329(0x17d)](
        _0x2c7329(0xb4),
        _0x2c7329(0x111),
        [_0x2c7329(0x160)],
        _0x326d44,
        _0x588313,
        null,
        !![]
      );
    if (
      !_0x5d10c0 ||
      !_0x5d10c0["1\x20day"] ||
      _0x5d10c0["1\x20day"]["length"] < _0x4cfe8b[_0x2c7329(0xc2)]
    )
      return;
    _0x4cfe8b["forEach"]((_0x48de80, _0x1187f6) => {
      const _0x3a8d2d = _0x2c7329;
      _0x48de80[_0x3a8d2d(0x1e5)] =
        _0x48de80[_0x3a8d2d(0x1db)] *
        _0x5d10c0[_0x3a8d2d(0x160)][_0x1187f6]["c"];
    });
  }
  async [a0_0x14a669(0x133)](
    _0x556db2,
    _0x44db60,
    _0x368402 = 0x3,
    _0x396678 = 0x1
  ) {
    const _0x32fb0b = a0_0x14a669;
    let _0x2f5779 = await this[_0x32fb0b(0xd8)](
      _0x556db2,
      _0x44db60,
      "SPOT",
      0x1e
    );
    if (!_0x2f5779[0x0]) return _0x2f5779;
    return [_0x2f5779[0x0][_0x32fb0b(0x14b)]];
  }
  [a0_0x14a669(0xe6)](_0x9aa1c6) {
    this["resetPairsCallback"] = _0x9aa1c6;
  }
  async [a0_0x14a669(0x146)]() {
    const _0x329d74 = a0_0x14a669;
    this[_0x329d74(0x15d)] &&
      ((this[_0x329d74(0x1fb)] = this["pairs"]["map"](
        (_0x9acbfa) => _0x9acbfa
      )),
      (this[_0x329d74(0x97)] = { ...this[_0x329d74(0x15d)] }),
      (this[_0x329d74(0x178)] = null),
      (this[_0x329d74(0x15d)] = []),
      await this[_0x329d74(0x16a)](),
      !this[_0x329d74(0x178)] || this[_0x329d74(0x178)][_0x329d74(0xc2)] === 0x0
        ? ((this[_0x329d74(0x178)] = this[_0x329d74(0x1fb)]),
          (this[_0x329d74(0x15d)] = this[_0x329d74(0x97)]))
        : this["resetPairsCallback"] &&
          this["resetPairsCallback"](this[_0x329d74(0x15d)]));
  }
  async ["getPairs"](_0x5285f7 = 0x2, _0x16a163 = 0x1) {
    const _0x39928a = a0_0x14a669;
    let _0x15b9f9 = ![];
    try {
      await this[_0x39928a(0xeb)][_0x39928a(0xaa)]();
      if (
        this[_0x39928a(0x178)] === null ||
        this[_0x39928a(0x178)][_0x39928a(0xc2)] === 0x0
      ) {
        let _0x4bf16f = ![],
          _0x369cb2 = null,
          _0x44acd1 = _0x39928a(0x147),
          _0x405ee5 = this[_0x39928a(0x16d)]
            ? this[_0x39928a(0x13f)] + _0x39928a(0x92)
            : _0x39928a(0x1de) + this["exchange"][_0x39928a(0x1ab)]("\x20", "");
        _0x16a163 > 0x1 &&
          ((_0x405ee5 =
            _0x39928a(0x1de) + this[_0x39928a(0xff)]["replace"]("\x20", "")),
          (_0x44acd1 = "get"));
        const _0x497aae = { "Content-Type": "application/json" };
        try {
          let _0x1c6380 = {
            method: _0x44acd1,
            headers: {
              ..._0x497aae,
              Accept: _0x39928a(0x1d1),
              "Content-Type": _0x39928a(0x1d1),
            },
          };
          _0x369cb2 && (_0x1c6380["body"] = JSON[_0x39928a(0x159)](_0x369cb2));
          const _0x2d1f14 = await fetch(_0x405ee5, _0x1c6380);
          if (_0x2d1f14[_0x39928a(0xe9)] === 0xc8) {
            const _0x2d8c02 = await _0x2d1f14[_0x39928a(0x19a)]();
            this[_0x39928a(0x178)] = _0x2d8c02[_0x39928a(0x166)]["map"](
              (_0x44790a) => {
                const _0x1521d4 = _0x39928a;
                let _0x1c09d5 = {
                  baseMinQty: 0x0,
                  baseMaxQty: 0x0,
                  baseStepSize: 0x0,
                  minPrice: 0x0,
                  maxPrice: 0x0,
                  tickSize: 0x0,
                  baseDecimal: 0x0,
                  quotedDecimal: 0x0,
                };
                if (_0x44790a[_0x1521d4(0x1b8)]) {
                  let _0xef9479 = _0x44790a["filters"][_0x1521d4(0xe2)](
                      (_0xf93cb0) =>
                        _0xf93cb0[_0x1521d4(0x116)] === _0x1521d4(0xa3)
                    ),
                    _0x30f98b = _0x44790a[_0x1521d4(0x1b8)][_0x1521d4(0xe2)](
                      (_0x54b311) => _0x54b311[_0x1521d4(0x116)] === "LOT_SIZE"
                    );
                  if (_0xef9479) {
                    (_0x1c09d5[_0x1521d4(0x9d)] = fixNumber(
                      Number[_0x1521d4(0x144)](_0xef9479["minPrice"]),
                      0xa
                    )),
                      (_0x1c09d5["maxPrice"] = fixNumber(
                        Number["parseFloat"](_0xef9479[_0x1521d4(0x10a)]),
                        0xa
                      )),
                      (_0x1c09d5[_0x1521d4(0x1d9)] = fixNumber(
                        Number[_0x1521d4(0x144)](_0xef9479[_0x1521d4(0x1d9)]),
                        0xa
                      ));
                    let _0x452218 = _0xef9479[_0x1521d4(0x1d9)]["indexOf"]("1");
                    _0x452218 !== -0x1 &&
                      (_0x1c09d5[_0x1521d4(0x1b2)] = Math[_0x1521d4(0x17b)](
                        0x0,
                        _0x452218 - 0x1
                      ));
                  }
                  if (_0x30f98b) {
                    (_0x1c09d5[_0x1521d4(0xe5)] = fixNumber(
                      Number[_0x1521d4(0x144)](_0x30f98b[_0x1521d4(0xfe)]),
                      0xa
                    )),
                      (_0x1c09d5["baseMaxQty"] = fixNumber(
                        Number["parseFloat"](_0x30f98b[_0x1521d4(0x19c)]),
                        0xa
                      )),
                      (_0x1c09d5[_0x1521d4(0x1a0)] = fixNumber(
                        Number["parseFloat"](_0x30f98b[_0x1521d4(0x16b)]),
                        0xa
                      ));
                    let _0x298dc9 = _0x30f98b["stepSize"][_0x1521d4(0xf6)]("1");
                    _0x298dc9 !== -0x1 &&
                      (_0x1c09d5[_0x1521d4(0x9f)] = Math[_0x1521d4(0x17b)](
                        0x0,
                        _0x298dc9 - 0x1
                      ));
                  }
                }
                return (
                  (_0x1c09d5[_0x1521d4(0x163)] = _0x44790a[_0x1521d4(0x163)]),
                  (_0x1c09d5["quoteAsset"] = _0x44790a[_0x1521d4(0xf0)]),
                  (this[_0x1521d4(0x15d)][_0x44790a[_0x1521d4(0x12f)]] =
                    _0x1c09d5),
                  _0x44790a[_0x1521d4(0x12f)]
                );
              }
            );
          } else _0x4bf16f = !![];
        } catch (_0x11c6ef) {
          _0x4bf16f = !![];
        }
        if (_0x4bf16f) {
          if (_0x16a163 < _0x5285f7)
            return (
              (_0x15b9f9 = !![]),
              this[_0x39928a(0xeb)][_0x39928a(0x18e)](),
              await this[_0x39928a(0x16a)](_0x5285f7, _0x16a163 + 0x1)
            );
        }
        return null;
      }
      return [this[_0x39928a(0x178)], this[_0x39928a(0x15d)]];
    } finally {
      !_0x15b9f9 && this[_0x39928a(0xeb)][_0x39928a(0x18e)]();
    }
  }
  [a0_0x14a669(0x11b)](_0x2acf52, _0x120a7c) {
    const _0x4abf45 = a0_0x14a669;
    this[_0x4abf45(0x1e3)] = _0x2acf52;
  }
  [a0_0x14a669(0xb2)](_0x17f35b, _0x4f615f, _0x3804c6, _0x52b631, _0x3bde60) {
    const _0x306a21 = a0_0x14a669;
    let _0xb1de05 = _0x17f35b + "_" + _0x4f615f;
    if (this["cachedData"][_0xb1de05]) {
      if (
        _0x3804c6 >= this[_0x306a21(0x140)][_0xb1de05][_0x306a21(0x170)] &&
        _0x52b631 <= this[_0x306a21(0x140)][_0xb1de05][_0x306a21(0x158)]
      )
        return this[_0x306a21(0x140)][_0xb1de05]["data"][_0x306a21(0x1cf)](
          (_0x52a880) =>
            _0x52a880["d"] >= _0x3804c6 && _0x52a880["cd"] <= _0x52b631
        );
    }
    let _0xb92275 = this[_0x306a21(0xff)]
      [_0x306a21(0x1ab)](/ /g, "")
      ["toLowerCase"]();
    return new Promise(async (_0x419633, _0x175963) => {
      const _0x4e7b0d = _0x306a21;
      await fetchWithRetry(
        _0x3bde60
          ? _0x4e7b0d(0x1c6) +
              _0x17f35b +
              _0x4e7b0d(0x1ac) +
              _0x4f615f +
              "&cm=" +
              _0x3bde60[_0x4e7b0d(0x1e9)]()
          : _0x4e7b0d(0x1c6) +
              _0x17f35b +
              _0x4e7b0d(0x1ac) +
              _0x4f615f +
              _0x4e7b0d(0x167) +
              _0xb92275,
        "get",
        null,
        (_0x5c6882) => {
          const _0x334a55 = _0x4e7b0d;
          let _0x525acd = [];
          _0x5c6882 &&
            (_0x525acd = _0x5c6882["map"]((_0x113774) => {
              const _0x293faa = a0_0x35e4;
              return {
                d: _0x113774[0x0],
                o: Number[_0x293faa(0x144)](_0x113774[0x1]),
                h: Number[_0x293faa(0x144)](_0x113774[0x2]),
                l: Number[_0x293faa(0x144)](_0x113774[0x3]),
                c: Number[_0x293faa(0x144)](_0x113774[0x4]),
                v: Number[_0x293faa(0x144)](_0x113774[0x5]),
                cd: _0x113774[0x6],
              };
            })),
            (this[_0x334a55(0x140)][_0xb1de05] = {
              startDate: _0x3804c6,
              endDate: _0x52b631,
              data: _0x525acd,
            }),
            _0x419633(
              this[_0x334a55(0x140)][_0xb1de05][_0x334a55(0xb1)][
                _0x334a55(0x1cf)
              ](
                (_0x4379ee) =>
                  _0x4379ee["d"] >= _0x3804c6 && _0x4379ee["cd"] <= _0x52b631
              )
            );
        },
        () => {
          _0x419633([]);
        }
      );
    });
  }
  ["getECBHistoricalChartData"](_0x323899, _0x4c9043, _0x2f3354, _0x517b55) {
    const _0x4cbac6 = a0_0x14a669;
    let _0x2f805c = _0x323899 + "_" + _0x4c9043;
    if (this["cachedData"][_0x2f805c]) {
      if (
        _0x2f3354 >= this["cachedData"][_0x2f805c][_0x4cbac6(0x170)] &&
        _0x517b55 <= this[_0x4cbac6(0x140)][_0x2f805c]["endDate"]
      )
        return this[_0x4cbac6(0x140)][_0x2f805c][_0x4cbac6(0xb1)][
          _0x4cbac6(0x1cf)
        ](
          (_0x572747) =>
            _0x572747["d"] >= _0x2f3354 && _0x572747["cd"] <= _0x517b55
        );
    }
    let _0x535a32 = this["exchange"]
      [_0x4cbac6(0x1ab)](/ /g, "")
      [_0x4cbac6(0x12c)]();
    return new Promise(async (_0x25c48e, _0xb4371c) => {
      const _0x28c402 = _0x4cbac6;
      await fetchWithRetry(
        _0x28c402(0x117),
        _0x28c402(0x1e0),
        {
          id: _0x28c402(0x1ce),
          s: _0x323899,
          tf: _0x4c9043,
          e: _0x535a32,
          f: _0x2f3354,
          t: _0x517b55,
        },
        async (_0x5b4d4b) => {
          const _0x1ef716 = _0x28c402;
          if (_0x5b4d4b[_0x1ef716(0x103)]) {
            let _0x2f7f6d = new Date(_0x5b4d4b[_0x1ef716(0x103)] * 0x3e8);
            _0x2f7f6d = _0x2f7f6d[_0x1ef716(0xe8)]() - new Date()["getTime"]();
            _0x2f7f6d > 0x0 && (await sleep(_0x2f7f6d + 0x3e8 * 0x5));
            let _0xf5a18e = await this["getECBHistoricalChartData"](
              _0x323899,
              _0x4c9043,
              _0x2f3354,
              _0x517b55
            );
            _0x25c48e(_0xf5a18e);
            return;
          }
          let _0x1f2a0d = [];
          _0x5b4d4b &&
            (_0x1f2a0d = _0x5b4d4b[_0x1ef716(0x12e)]((_0x199319) => {
              const _0x42d216 = _0x1ef716;
              return {
                d: _0x199319[0x0],
                o: Number[_0x42d216(0x144)](_0x199319[0x1]),
                h: Number["parseFloat"](_0x199319[0x2]),
                l: Number[_0x42d216(0x144)](_0x199319[0x3]),
                c: Number[_0x42d216(0x144)](_0x199319[0x4]),
                v: Number[_0x42d216(0x144)](_0x199319[0x5]),
                cd: _0x199319[0x6],
              };
            })),
            (this["cachedData"][_0x2f805c] = {
              startDate: _0x2f3354,
              endDate: _0x517b55,
              data: _0x1f2a0d,
            }),
            _0x25c48e(_0x1f2a0d);
        },
        () => {
          _0x25c48e([]);
        }
      );
    });
  }
  [a0_0x14a669(0x105)](_0x56d4ee) {
    const _0x96088d = a0_0x14a669;
    let _0x25f0d3 = _0x56d4ee[_0x96088d(0x15a)]() + 0x1;
    return (
      _0x56d4ee[_0x96088d(0x176)]() +
      "-" +
      (_0x25f0d3 < 0xa ? "0" + _0x25f0d3 : _0x25f0d3)
    );
  }
  [a0_0x14a669(0xc1)](_0x64a836, _0x19885c) {
    const _0x186d1a = a0_0x14a669;
    let _0x593d72 = new Date(_0x64a836[_0x186d1a(0xe8)]()),
      _0x459084 = new Date(_0x19885c[_0x186d1a(0xe8)]());
    _0x593d72[_0x186d1a(0x104)](0xa), _0x459084[_0x186d1a(0x104)](0x14);
    let _0x38cbe7 = [];
    while (_0x593d72 < _0x459084) {
      let _0x5499a8 = _0x593d72[_0x186d1a(0x15a)]() + 0x1;
      _0x38cbe7["push"](
        _0x593d72["getFullYear"]() +
          "-" +
          (_0x5499a8 < 0xa ? "0" + _0x5499a8 : _0x5499a8)
      ),
        _0x593d72[_0x186d1a(0x135)](_0x593d72[_0x186d1a(0x15a)]() + 0x1);
    }
    return _0x38cbe7;
  }
  [a0_0x14a669(0xfb)](_0x53acb7) {
    const _0x265146 = a0_0x14a669;
    let _0x15e77a = _0x53acb7["getMonth"]() + 0x1;
    return (
      _0x53acb7[_0x265146(0x176)]() +
      "-" +
      (_0x15e77a < 0xa ? "0" + _0x15e77a : _0x15e77a)
    );
  }
  [a0_0x14a669(0x1bf)](_0x3182b5, _0x3715db, _0x3582e7) {
    const _0x10e708 = a0_0x14a669;
    if (_0x3715db >= _0x3582e7) return [];
    let _0x4b1ceb = new Date(_0x3715db[_0x10e708(0xe8)]());
    _0x4b1ceb[_0x10e708(0x104)](0xa);
    let _0x19f82c = [];
    while (!![]) {
      let _0x13f829 = _0x3182b5["find"](
          (_0x3c8872) =>
            _0x3c8872[_0x10e708(0xd7)] === this[_0x10e708(0x105)](_0x4b1ceb)
        ),
        _0x447232 = new Date(_0x3582e7[_0x10e708(0xe8)]());
      _0x13f829 &&
        _0x447232[_0x10e708(0x1a9)](_0x447232[_0x10e708(0x9e)]() - 0x2);
      (!_0x13f829 ||
        (!_0x13f829["isFull"] &&
          _0x13f829["lastDownload"] < _0x447232[_0x10e708(0xe8)]())) &&
        _0x19f82c[_0x10e708(0x132)]({
          monthId: this[_0x10e708(0x105)](_0x4b1ceb),
          dateFromUtc: Date[_0x10e708(0x161)](
            _0x4b1ceb[_0x10e708(0x176)](),
            _0x4b1ceb[_0x10e708(0x15a)](),
            0x1,
            0x0,
            0x0,
            0x0,
            0x0
          ),
          dateToUtc: Date["UTC"](
            _0x4b1ceb[_0x10e708(0x176)](),
            _0x4b1ceb[_0x10e708(0x15a)]() + 0x1,
            0x0,
            0x17,
            0x3b,
            0x3b,
            0x3e7
          ),
        });
      if (
        _0x4b1ceb[_0x10e708(0x176)]() === _0x3582e7[_0x10e708(0x176)]() &&
        _0x4b1ceb[_0x10e708(0x15a)]() === _0x3582e7[_0x10e708(0x15a)]()
      )
        break;
      _0x4b1ceb["setMonth"](_0x4b1ceb[_0x10e708(0x15a)]() + 0x1, 0xa);
    }
    return _0x19f82c;
  }
  ["getDataFromHistoricalChunks"](_0x5c00ff, _0x44300f, _0x30b137, _0x2b8244) {
    const _0x202609 = a0_0x14a669;
    let _0x5d58a7 = [];
    return (
      _0x5c00ff[_0x202609(0x1cf)]((_0x8ed71f) => _0x8ed71f["tf"] === _0x44300f)[
        _0x202609(0xc6)
      ]((_0x28fb9d) =>
        _0x28fb9d[_0x202609(0xb1)][_0x202609(0xc6)]((_0x4d600f, _0x2555aa) => {
          const _0x2edec4 = _0x202609;
          let _0x35aede = new Date(_0x4d600f["d"]),
            _0x3b689b = new Date(_0x4d600f["cd"]),
            _0x466d0e =
              _0x2555aa < _0x28fb9d[_0x2edec4(0xb1)][_0x2edec4(0xc2)]
                ? new Date(_0x28fb9d["data"][_0x2555aa]["d"])
                : null;
          _0x35aede >= _0x30b137 &&
            (!_0x466d0e || _0x466d0e < _0x2b8244) &&
            _0x5d58a7[_0x2edec4(0x132)]({
              ..._0x4d600f,
              d: _0x35aede,
              cd: _0x3b689b,
            });
        })
      ),
      _0x5d58a7["sort"]((_0x2efe90, _0x2ba089) =>
        _0x2efe90["d"] > _0x2ba089["d"] ? 0x1 : -0x1
      ),
      _0x5d58a7
    );
  }
  async ["fillHistoricalData"](
    _0x12218f,
    _0xdf0154,
    _0x2f7443,
    _0x241297,
    _0x5b1704,
    _0x1289b5
  ) {
    const _0x4d8bc0 = a0_0x14a669;
    let _0x18aa6e = [];
    for (let _0x5266a6 of _0xdf0154) {
      _0x1289b5[_0x5266a6] = [];
      let _0x46a818 = _0x5b1704
          ? new Date(_0x2f7443[_0x4d8bc0(0xe8)]())
          : getStartDate(_0x5266a6, _0x2f7443),
        _0x1ec9e7 = new Date(_0x241297[_0x4d8bc0(0xe8)]()),
        _0x400587 = await getEcbDatabase()[_0x4d8bc0(0x1ad)](
          this[_0x4d8bc0(0xff)],
          _0x12218f,
          _0x5266a6,
          this[_0x4d8bc0(0xc1)](_0x46a818, _0x1ec9e7)
        ),
        _0x5515f9 = this[_0x4d8bc0(0x1bf)](_0x400587, _0x46a818, _0x1ec9e7);
      if (_0x5515f9[_0x4d8bc0(0xc2)] === 0x0) {
        _0x1289b5[_0x5266a6] = this[_0x4d8bc0(0x1ba)](
          _0x400587,
          _0x5266a6,
          _0x46a818,
          _0x1ec9e7
        );
        continue;
      } else
        _0x5515f9[_0x4d8bc0(0xc6)]((_0x558427) => {
          const _0xf25f31 = _0x4d8bc0;
          _0x18aa6e[_0xf25f31(0x132)]({
            timeframe: _0x5266a6,
            dateFrom: _0x558427[_0xf25f31(0x131)],
            dateTo: _0x558427[_0xf25f31(0x1c0)],
            monthId: _0x558427["monthId"],
          });
        });
    }
    return _0x18aa6e;
  }
  ["getChartHistoricalData"](
    _0x5ecf54,
    _0x3a7ad9,
    _0x2fcf70,
    _0xa7becf,
    _0x123d9d,
    _0x353c4b
  ) {
    const _0x2929dc = a0_0x14a669;
    if (this["demoData"]) {
    }
    if (isNaN(_0xa7becf || _0xa7becf)) return [];
    if (!this[_0x2929dc(0x16d)])
      return new Promise(async (_0x56ee31, _0x4d1df8) => {
        const _0x332d8c = _0x2929dc;
        try {
          let _0x27e589 = getInterval(_0x2fcf70),
            _0x286e41 = await this[_0x332d8c(0x142)](
              _0x3a7ad9,
              _0x27e589,
              _0xa7becf,
              _0x123d9d
            );
          _0x56ee31(_0x286e41);
        } catch (_0x1d8404) {}
        _0x56ee31([]);
      });
    if (_0x353c4b)
      return new Promise(async (_0xf90d49, _0xca54ce) => {
        const _0x2b28ab = _0x2929dc;
        let _0x34fede = await this[_0x2b28ab(0x17d)](
          _0x5ecf54,
          _0x3a7ad9,
          [_0x2fcf70],
          new Date(_0xa7becf),
          new Date(_0x123d9d)
        );
        if (
          _0x34fede &&
          _0x34fede[_0x2fcf70] &&
          _0x34fede[_0x2fcf70][_0x2b28ab(0xc2)] > 0x0
        ) {
          _0x34fede[_0x2fcf70][_0x2b28ab(0xc6)]((_0x4374d8) => {
            const _0x36074c = _0x2b28ab;
            (_0x4374d8["d"] = _0x4374d8["d"][_0x36074c(0xe8)]()),
              (_0x4374d8["cd"] = _0x4374d8["cd"][_0x36074c(0xe8)]());
          }),
            _0xf90d49(_0x34fede[_0x2fcf70]);
          return;
        }
        _0xf90d49(null);
      });
    return new Promise(async (_0x1629fe, _0x22f8ea) => {
      const _0x5d5a43 = _0x2929dc;
      try {
        let _0xb4cce4 = await initializeWorker(
          this[_0x5d5a43(0x1d7)],
          _0x5ecf54,
          _0x5d5a43(0xc7)
        );
        if (!_0xb4cce4) {
          _0x1629fe(null);
          return;
        }
        try {
          (this[_0x5d5a43(0x1d7)][_0x5ecf54]["onmessage"] = async (
            _0x1d18ab
          ) => {
            const _0x1a8d98 = _0x5d5a43;
            try {
              if (this[_0x1a8d98(0x1d7)][_0x5ecf54])
                switch (_0x1d18ab["data"][_0x1a8d98(0x18d)]) {
                  case "COMPLETED":
                    this[_0x1a8d98(0x1d7)][_0x5ecf54][_0x1a8d98(0x11f)](),
                      delete this["historicalWorkers"][_0x5ecf54],
                      _0x1629fe(_0x1d18ab["data"][_0x1a8d98(0xb1)]);
                    break;
                  default:
                }
            } finally {
              this[_0x1a8d98(0xdb)][_0x1a8d98(0x18e)]();
            }
          }),
            this[_0x5d5a43(0x1d7)][_0x5ecf54]["postMessage"]({
              cmd: _0x5d5a43(0x13d),
              exchange: this[_0x5d5a43(0xff)],
              pair: _0x3a7ad9,
              timeframe: _0x2fcf70,
              dateFrom: _0xa7becf,
              dateTo: _0x123d9d,
            });
        } catch (_0x5a6d0f) {
          _0x1629fe(null);
        }
      } catch (_0x27473a) {
        _0x1629fe(null);
      }
    });
  }
  [a0_0x14a669(0x17d)](
    _0x347da6,
    _0x49151f,
    _0x1faf6b,
    _0x46c4bb,
    _0x534bfc,
    _0x3178d4,
    _0x24e1ab,
    _0x32e190
  ) {
    const _0x7c3e90 = a0_0x14a669;
    if (this["demoData"])
      return Promise[_0x7c3e90(0x1c7)](this[_0x7c3e90(0x1e3)]);
    if (!this[_0x7c3e90(0x16d)])
      return new Promise(async (_0x144055, _0x578a0c) => {
        const _0x5e608f = _0x7c3e90;
        let _0x1ca869 = {};
        try {
          for (let _0x3a9d66 of _0x1faf6b) {
            let _0x3de168 = getInterval(_0x3a9d66),
              _0x25f56a = await this["getECBHistoricalData"](
                _0x49151f,
                _0x3de168,
                _0x46c4bb[_0x5e608f(0xe8)](),
                _0x534bfc[_0x5e608f(0xe8)](),
                _0x32e190
              );
            _0x1ca869[_0x3a9d66] = _0x25f56a;
          }
        } catch (_0x243da2) {}
        _0x144055(_0x1ca869);
      });
    return new Promise(async (_0x3c1100, _0x5865f3) => {
      const _0x4659d2 = _0x7c3e90;
      try {
        let _0x4d9b5e = {},
          _0x1fd0d9 = await this[_0x4659d2(0x19f)](
            _0x49151f,
            _0x1faf6b,
            _0x46c4bb,
            _0x534bfc,
            _0x24e1ab,
            _0x4d9b5e
          );
        if (_0x1fd0d9[_0x4659d2(0xc2)] > 0x0) {
          let _0x2ef79f = await initializeWorker(
            this[_0x4659d2(0x1d7)],
            _0x347da6,
            _0x4659d2(0xc7)
          );
          if (!_0x2ef79f) {
            _0x3c1100(null);
            return;
          }
          try {
            await this[_0x4659d2(0x139)][_0x4659d2(0xaa)](),
              (this[_0x4659d2(0x183)] = _0x347da6);
            if (!this[_0x4659d2(0x1d7)][_0x347da6]) {
              this[_0x4659d2(0x139)][_0x4659d2(0x18e)](), _0x3c1100(null);
              return;
            }
            _0x1fd0d9 = await this[_0x4659d2(0x19f)](
              _0x49151f,
              _0x1faf6b,
              _0x46c4bb,
              _0x534bfc,
              _0x24e1ab,
              _0x4d9b5e
            );
            if (_0x1fd0d9[_0x4659d2(0xc2)] === 0x0) {
              this[_0x4659d2(0x139)][_0x4659d2(0x18e)](), _0x3c1100(_0x4d9b5e);
              return;
            }
            let _0x1466fd = 0x0;
            (this["historicalWorkers"][_0x347da6][_0x4659d2(0x173)] = async (
              _0x2b8bda
            ) => {
              const _0x5ac648 = _0x4659d2;
              try {
                await this[_0x5ac648(0xdb)][_0x5ac648(0xaa)]();
                if (this["historicalWorkers"][_0x347da6])
                  switch (_0x2b8bda[_0x5ac648(0xb1)]["cmd"]) {
                    case _0x5ac648(0x12b):
                      _0x3178d4 &&
                        (_0x1466fd <
                          _0x2b8bda[_0x5ac648(0xb1)][_0x5ac648(0xf5)] &&
                          (await _0x3178d4(
                            _0x2b8bda[_0x5ac648(0xb1)]["completed"]
                          )),
                        (_0x1466fd =
                          _0x2b8bda[_0x5ac648(0xb1)][_0x5ac648(0xf5)]));
                      break;
                    case "CANCELED":
                      this[_0x5ac648(0x139)][_0x5ac648(0x18e)](),
                        (this["historicalDataMutexId"] = null),
                        this[_0x5ac648(0x1d7)][_0x347da6][_0x5ac648(0x11f)](),
                        delete this[_0x5ac648(0x1d7)][_0x347da6],
                        _0x3c1100(null);
                      break;
                    case _0x5ac648(0x1d8):
                      try {
                        this[_0x5ac648(0x1d7)][_0x347da6]["terminate"](),
                          delete this[_0x5ac648(0x1d7)][_0x347da6];
                        if (_0x2b8bda[_0x5ac648(0xb1)]["dataChunks"])
                          for (let _0x587f3c of _0x2b8bda[_0x5ac648(0xb1)][
                            "dataChunks"
                          ]) {
                            if (
                              _0x587f3c &&
                              _0x587f3c["data"][_0x5ac648(0xc2)] > 0x0
                            ) {
                              let _0x1fff2b = !![],
                                _0x48d54c = new Date(),
                                _0x2b781f =
                                  _0x587f3c["data"][
                                    _0x587f3c[_0x5ac648(0xb1)][
                                      _0x5ac648(0xc2)
                                    ] - 0x1
                                  ];
                              _0x48d54c[_0x5ac648(0x104)](
                                _0x48d54c["getDate"]() - 0x7
                              );
                              _0x48d54c[_0x5ac648(0xe8)]() < _0x2b781f["cd"] &&
                                (_0x1fff2b = ![]);
                              if (_0x1fff2b) {
                                let _0x4c07df = 0x0,
                                  _0x23edd6 =
                                    _0x587f3c[_0x5ac648(0x1dd)][
                                      _0x5ac648(0xd4)
                                    ]("-")[0x1] === "02"
                                      ? 0x9470
                                      : 0x9c40;
                                switch (_0x587f3c[_0x5ac648(0xbf)]) {
                                  case _0x5ac648(0x13b):
                                    _0x4c07df = _0x23edd6;
                                    break;
                                  case _0x5ac648(0x1b9):
                                    _0x4c07df = _0x23edd6 / 0x5;
                                    break;
                                  case _0x5ac648(0xc3):
                                    _0x4c07df = _0x23edd6 / 0x5;
                                    break;
                                  case "30\x20minutes":
                                    _0x4c07df = _0x23edd6 / 0x1e;
                                    break;
                                  case _0x5ac648(0x10d):
                                    _0x4c07df = _0x23edd6 / 0x3c;
                                    break;
                                  case "4\x20hours":
                                    _0x4c07df = _0x23edd6 / 0xf0;
                                    break;
                                  case _0x5ac648(0x141):
                                    _0x4c07df = _0x23edd6 / 0x2d0;
                                    break;
                                  case _0x5ac648(0x160):
                                    _0x4c07df = _0x23edd6 / 0x5a0;
                                    break;
                                  case _0x5ac648(0x130):
                                    _0x4c07df = _0x23edd6 / (0x5a0 * 0x7);
                                    break;
                                  case _0x5ac648(0x1eb):
                                    _0x4c07df = _0x23edd6 / (0x5a0 * 0x1e);
                                    break;
                                  default:
                                }
                                _0x1fff2b =
                                  _0x4c07df <
                                  _0x587f3c["data"][_0x5ac648(0xc2)];
                              }
                              await getEcbDatabase()[_0x5ac648(0x1fa)](
                                this[_0x5ac648(0xff)],
                                _0x49151f,
                                _0x587f3c[_0x5ac648(0xbf)],
                                _0x587f3c[_0x5ac648(0x1dd)],
                                _0x587f3c[_0x5ac648(0xb1)],
                                new Date()[_0x5ac648(0xe8)](),
                                _0x1fff2b
                              );
                            }
                          }
                        let _0x51415a = {};
                        for (let _0x4efbb0 of _0x1faf6b) {
                          let _0x42a96a = _0x24e1ab
                              ? new Date(_0x46c4bb["getTime"]())
                              : getStartDate(_0x4efbb0, _0x46c4bb),
                            _0x1a80c7 = await getEcbDatabase()[
                              _0x5ac648(0x1ad)
                            ](
                              this[_0x5ac648(0xff)],
                              _0x49151f,
                              _0x4efbb0,
                              this[_0x5ac648(0xc1)](_0x42a96a, _0x534bfc)
                            );
                          _0x51415a[_0x4efbb0] = this[_0x5ac648(0x1ba)](
                            _0x1a80c7,
                            _0x4efbb0,
                            _0x42a96a,
                            _0x534bfc
                          );
                        }
                        _0x3c1100(_0x51415a);
                      } finally {
                        this["historicalDataMutex"]["release"](),
                          (this[_0x5ac648(0x183)] = null);
                      }
                      break;
                    default:
                  }
              } finally {
                this["historicalDownloadMutex"][_0x5ac648(0x18e)]();
              }
            }),
              this[_0x4659d2(0x1d7)][_0x347da6][_0x4659d2(0xfd)]({
                cmd: _0x4659d2(0x1d3),
                exchange: this["exchange"],
                pair: _0x49151f,
                dataChunks: _0x1fd0d9,
              });
          } catch (_0x472a3d) {
            console[_0x4659d2(0x1c3)](
              _0x4659d2(0x1df) + _0x472a3d[_0x4659d2(0xe3)]
            ),
              this[_0x4659d2(0x139)][_0x4659d2(0x18e)](),
              (this[_0x4659d2(0x183)] = null),
              _0x3c1100(null);
          }
        } else _0x3c1100(_0x4d9b5e);
      } catch (_0x186cee) {
        console["log"](_0x4659d2(0x1df) + _0x186cee[_0x4659d2(0xe3)]),
          _0x3c1100(null);
      }
    });
  }
  async [a0_0x14a669(0x186)](_0x427e6e) {
    const _0x679f1 = a0_0x14a669;
    try {
      await this[_0x679f1(0xdb)][_0x679f1(0xaa)](),
        this["historicalWorkers"][_0x427e6e] &&
          (this[_0x679f1(0x183)] !== _0x427e6e
            ? delete this[_0x679f1(0x1d7)][_0x427e6e]
            : this["historicalWorkers"][_0x427e6e][_0x679f1(0xfd)]({
                cmd: _0x679f1(0xb0),
              }));
    } finally {
      this[_0x679f1(0xdb)][_0x679f1(0x18e)]();
    }
  }
}
function a0_0x35e4(_0x40bde2, _0x147148) {
  const _0x2b0c54 = a0_0x2b0c();
  return (
    (a0_0x35e4 = function (_0x35e48b, _0x13a521) {
      _0x35e48b = _0x35e48b - 0x92;
      let _0x253d35 = _0x2b0c54[_0x35e48b];
      return _0x253d35;
    }),
    a0_0x35e4(_0x40bde2, _0x147148)
  );
}
class BinanceCom extends Binance {
  constructor(_0x4ccb38, _0x12fcc8, _0x25c987, _0x2ee4bd) {
    const _0x1564f0 = a0_0x14a669;
    super(
      _0x4ccb38,
      _0x12fcc8,
      _0x25c987,
      _0x2ee4bd,
      _0x1564f0(0x180),
      _0x1564f0(0x188),
      _0x1564f0(0x194),
      _0x1564f0(0xa1)
    );
  }
}
class BinanceUS extends Binance {
  constructor(_0x418020, _0x471fe7, _0x1e91a5, _0xaa50ee) {
    const _0x4ebcb7 = a0_0x14a669;
    super(
      _0x418020,
      _0x471fe7,
      _0x1e91a5,
      _0xaa50ee,
      "Binance\x20US",
      _0x4ebcb7(0x128),
      _0x4ebcb7(0x128),
      "wss://stream.binance.us:9443/ws"
    );
  }
}
let exchangesInitialized = ![];
function a0_0x2b0c() {
  const _0x36ccbf = [
    "https://binance.com",
    "readyState",
    "price",
    "onopen",
    "getAssetBalance",
    "cmd",
    "release",
    "Connection\x20issue.\x20Please\x20try\x20later.",
    "storeManualTrade",
    "pricesStats",
    "balances",
    "MARKET",
    "https://api.binance.com",
    "orderUpdateSubscribersMutex",
    "updateTime",
    "getNewListenKey",
    "parseOrder",
    "full",
    "json",
    "executedQty",
    "maxQty",
    "Error\x20",
    "maxAPIWeith",
    "fillHistoricalData",
    "baseStepSize",
    "toRemove",
    "transactTime",
    "verifyApiKey",
    "SUBSCRIBE",
    "Connection\x20to\x20",
    "timestamp",
    "listenSocketPong",
    "Error\x20canceling\x20",
    "setMinutes",
    "subscribers",
    "replace",
    "&tf=",
    "getHistoricalDataChunks",
    "priceListeningPingId",
    "cSizeInBase",
    "oSizeInBase",
    "maxAPIWeithTimeOutDate",
    "quotedDecimal",
    "sizeType",
    "cStatus",
    "x-mbx-used-weight-1m",
    "result",
    "snapshots",
    "filters",
    "5\x20minutes",
    "getDataFromHistoricalChunks",
    "editDate",
    "orderId",
    "pices",
    "ask",
    "getMissingMonths",
    "dateToUtc",
    "checkPriceListeningWebsocket",
    "startPriceListening",
    "log",
    "snapshotVos",
    "@kline_",
    "https://api2.easycryptobot.com/historical-data.php?id=ecb&s=",
    "resolve",
    "convertAssetsToBnb",
    "cComm",
    "storeManualCloseTrade",
    "807654QnqEty",
    "time",
    "oFills",
    "ECB",
    "filter",
    "Invalid\x20API-key\x20or\x20your\x20IP\x20address\x20is\x20not\x20in\x20the\x20trusted\x20IPs\x20list\x20on\x20Binance.",
    "application/json",
    "closing",
    "GET_HISTORICAL_DATA",
    "setHours",
    "executionReport",
    "accountInfoTime",
    "historicalWorkers",
    "COMPLETED",
    "tickSize",
    "key",
    "btc",
    "SELL",
    "monthId",
    "https://api2.easycryptobot.com/pairs.php?id=",
    "ERROR\x20",
    "post",
    "checkManualOrderStatusUpdate",
    "/api/v3/userDataStream",
    "demoData",
    "errorMsg",
    "usdt",
    "commissionAsset",
    "manualTradesSubscribers",
    "unSubscribeToManualTrades",
    "toUpperCase",
    "errorCode",
    "1\x20month",
    "removeManualTrade",
    "floor",
    "orderUpdatesUnknown",
    "demo",
    "secret",
    "582552tHntSX",
    "binanceUs",
    "err\x20on\x20priceListenSocket.close()",
    "listenKey",
    "type",
    "closeManualTrade",
    "unsubscribeToChartUpdate",
    "UNSUBSCRIBE",
    "sizeInBase",
    "storeHistoricalDataChunks",
    "oldPairs",
    "open",
    "/api/v3/exchangeInfo",
    "priceListeningPong",
    "ensurePriceListeningSocketIsOpen",
    "fills",
    "531660SlpTmG",
    "oldpairDetails",
    "getSeconds",
    "/api/v3/time",
    "locked",
    "Exchange\x20order\x20status\x20429\x20(Too\x20many\x20requests)!!!",
    "manualTrades",
    "minPrice",
    "getMinutes",
    "baseDecimal",
    "then",
    "wss://stream.binance.com:9443/ws",
    "BNB",
    "PRICE_FILTER",
    "apiUrl",
    "balanceUpdateSubscribers",
    "bid",
    "getPrices",
    "subscribeToBalanceUpdate",
    "send",
    "lock",
    "&signature=",
    "chartDataToUnsubscribe",
    "qty",
    "getServerTime",
    "bnb",
    "CANCEL_GET_HISTORICAL_DATA",
    "data",
    "getECBHistoricalData",
    "kline",
    "btcusdt",
    "The\x20trade\x20size\x20is\x20too\x20small.",
    "commission",
    "getAssets",
    "recvWindow",
    "priceUpdateSubscribers",
    "side",
    "Buy",
    "cExId",
    "GET",
    "market",
    "timeframe",
    "timeInForce",
    "getDbMonths",
    "length",
    "15\x20minutes",
    "prices",
    "getDate",
    "forEach",
    "binance.worker.js",
    "SPOT",
    "userId",
    "unsubscribeToOrderUpdate",
    "streamingEndpoint",
    "checkListenWebsocket",
    "proxy",
    "balanceSnapshotsSubscribersMutex",
    "8nEKedV",
    "delete",
    "creationDate",
    "/api/v3/account?",
    "toString",
    "split",
    "base",
    "chartDataMutex",
    "month",
    "getAccount",
    "baseSize",
    "Message\x20from\x20exchange:\x20",
    "historicalDownloadMutex",
    "3846430LwannD",
    "unsubscribeToChart",
    "startManualTrading",
    "fixSizeInQioted",
    "priceListenSocket",
    "320703Xazhni",
    "find",
    "stack",
    "https://binance.com/api/v3/exchangeInfo",
    "baseMinQty",
    "setResetPairsCallback",
    "setDemoAssets",
    "getTime",
    "status",
    "origQty",
    "getPairsMutex",
    "quoteSize",
    "balanceUpdateSubscribersMutex",
    "serverTime",
    "parse",
    "quoteAsset",
    "manualTradesUpdateSubscribersMutex",
    "15m",
    "direction",
    "ensureListenSocketIsOpen",
    "completed",
    "indexOf",
    "listenSocket",
    "msg",
    "stoploss",
    "getAcountBalanceInUsd",
    "getLastDateOfMonth",
    "Error\x20from\x20exchange:\x20",
    "postMessage",
    "minQty",
    "exchange",
    "cancelOrder",
    "LIST_SUBSCRIPTIONS",
    "orderUpdatesUnknownMutex",
    "date",
    "setDate",
    "getDbMonth",
    "LIMIT",
    "exchangeOrderWithRetry",
    "autoUpdateListenKey",
    "\x20failed.",
    "maxPrice",
    "HmacSHA256",
    "Order\x20Rejected\x20by\x20Binance.\x20Details:\x20",
    "1\x20hour",
    "binance",
    "ensurePriceListenSocketIsOpenId",
    "Exchange\x20order\x20status\x20418\x20(IP\x20BAN)",
    "BTCUSDT",
    "x-mbx-used-weight",
    "NEW",
    "tradeDetails",
    "ensureListenSocketIsOpenId",
    "filterType",
    "https://api2.easycryptobot.com/historical-data2.php",
    "unsubscribeToBalanceSnapshots",
    "keys",
    "cFills",
    "setDemoHistoricalData",
    "cachedChartData",
    "subscribeToManualTrades",
    "random",
    "terminate",
    "quote",
    "close",
    "cError",
    "quoteOrderQty",
    "oExId",
    "pair",
    "startListening",
    "655548ZDNfxf",
    "https://api.binance.us",
    "cSizeInQuoted",
    "chartData",
    "COMPLETED_PERCENT",
    "toLowerCase",
    "buying",
    "map",
    "symbol",
    "1\x20week",
    "dateFromUtc",
    "push",
    "getAccountBalance",
    "sizeInQuoted",
    "setMonth",
    "listenKeyUpdateId",
    "getDailyAccountSpanshot",
    "manualTrade",
    "historicalDataMutex",
    "subscribeToChartUpdate",
    "1\x20minute",
    "assets",
    "GET_CHART_HISTORICAL_DATA",
    "headers",
    "url",
    "cachedData",
    "12\x20hours",
    "getECBHistoricalChartData",
    "BUY",
    "parseFloat",
    "PARTIALLY_FILLED",
    "resetPairs",
    "get",
    "assetsInitialized",
    "resultInQuoted",
    "OPEN",
    "spot",
    "getUsdAssetSnapshots",
    "FILLED",
    "fixSizeInBase",
    "reduce",
    "oComm",
    "orderType",
    "quantity",
    "asset",
    "target",
    "getHours",
    "\x20order.\x20",
    "subscribeToOrderUpdate",
    "endDate",
    "stringify",
    "getMonth",
    "!ticker@arr",
    "deleteManualTrade",
    "pairDetails",
    "priceUpdateSubscribersMutex",
    "orderUpdateCheckUnkown",
    "1\x20day",
    "UTC",
    "193072KrchvO",
    "baseAsset",
    "orderUpdateSubscribers",
    "traficSaver",
    "symbols",
    "&e=",
    "outboundAccountPosition",
    "/sapi/v1/accountSnapshot?",
    "getPairs",
    "stepSize",
    "setSeconds",
    "app",
    "oSizeInQuoted",
    "code",
    "startDate",
    "getChartHistoricalData",
    "Binance\x20US",
    "onmessage",
    "listenSocketPingId",
    "getMilliseconds",
    "getFullYear",
    "exchangeDustTransferWithRetry",
    "pairs",
    "balanceSnapshotsUpdateSubscribers",
    "manual",
    "max",
    "subscribeToPricesUpdate",
    "getHistoricalData",
    "checkManualTrades",
    "ERROR\x202",
    "Binance",
    "Sleep\x2030\x20seconds\x20as\x20the\x20API\x20rate\x20limit\x20is\x20close",
    "CANCELED",
    "historicalDataMutexId",
    "exchangeOrder",
    "order",
    "cancelGetHistoricalData",
    "free",
  ];
  a0_0x2b0c = function () {
    return _0x36ccbf;
  };
  return a0_0x2b0c();
}
const exchanges = {};
export default function getExchange(
  _0x45c054,
  _0x369ed4,
  _0x7c6e41,
  _0x29093a
) {
  const _0x1f2514 = a0_0x14a669;
  if (!exchangesInitialized) {
    if (_0x369ed4) initializeExchanges(_0x7c6e41, _0x29093a);
    else return null;
  }
  switch (_0x45c054) {
    case _0x1f2514(0x180):
      return exchanges[_0x1f2514(0x10e)];
    case _0x1f2514(0x172):
      return exchanges[_0x1f2514(0x1f2)];
    default:
      return null;
  }
}
async function checkCORSEnabled() {
  const _0x222f8a = a0_0x14a669,
    _0x2ccbec = _0x222f8a(0xe4);
  try {
    const _0x5d89ce = await fetch(_0x2ccbec, {
      method: "get",
      headers: { Accept: _0x222f8a(0x1d1), "Content-Type": _0x222f8a(0x1d1) },
    });
    if (_0x5d89ce[_0x222f8a(0xe9)] === 0xc8) {
      const _0x350631 = await _0x5d89ce[_0x222f8a(0x19a)]();
      return !![];
    } else return ![];
  } catch (_0x2f1a1c) {
    return ![];
  }
}
function initializeExchanges(
  _0x39e8a7,
  _0x5d4f1b,
  _0x3e506f,
  _0x1221f8,
  _0x29c0e0
) {
  const _0x30d450 = a0_0x14a669;
  (!exchangesInitialized || _0x3e506f) &&
    ((exchangesInitialized = !![]),
    (exchanges[_0x30d450(0x10e)] = new BinanceCom(
      _0x39e8a7,
      _0x5d4f1b,
      _0x1221f8,
      _0x29c0e0
    )),
    (exchanges[_0x30d450(0x1f2)] = new BinanceUS(
      _0x39e8a7,
      _0x5d4f1b,
      _0x1221f8,
      _0x29c0e0
    )),
    !_0x29c0e0 &&
      (exchanges[_0x30d450(0x10e)][_0x30d450(0x94)](),
      exchanges[_0x30d450(0x1f2)][_0x30d450(0x94)]()));
}
export { initializeExchanges, checkCORSEnabled };
