import React, { useState, useContext, useEffect, useRef, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faPlay,
  faStop,
  faBan,
  faExclamationTriangle,
  faQuestionCircle,
  faLink,
  faSnowflake,
  faSync,
  faArrowsAltV,
  faSearch,
  faInfoCircle,
  faUndo,
  faPlusCircle,
  faThumbsUp,
  faThumbsDown,
  faClipboardList,
  faRocket,
  faChartPie,
  faChartLine,
  faSearchPlus,
  faRandom,
  faCog,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { globalContext } from "./Context.js";
import {
  showInfoDialog,
  showConfirmDialog,
  getUsdValue,
  formatDateTime,
  updateExecutionStatus,
  formatDate2,
  sleep,
  getInterval,
  roundQuotedValue,
  getTimeframeFromInterval,
  attachCumulativesData,
  attachTradesData,
  getDatesDiff,
  roundBaseValue,
  showTime,
  getFromLocalStorage,
  getStrategyTrades,
  sendRemoteStrategyStop,
  getStartDateForSingleBinanceCall,
  getUserTrades,
  getUserEvents,
  getStrategiesDetail,
  saveStrategTrade,
  fixNumber,
  formatDateTime4,
  formatDateTime5,
} from "./utils.js";
import ReactEcharts from "echarts-for-react";
import $ from "jquery";
import getExchange from "./Exchange.js";
import echarts from "echarts/lib/echarts";
import loadingReverced from "./assets/images/loading-reverced.gif";
import { addTrading, getBalance, manualBuy, manualSell, removeTrading, updateStrategy } from "./Bot.js";
import { RadioButtons } from "./RadioButtons.js";
import { ModalConfirm } from "./Modal.js";
import { parseBacktestResult, marketReturn, getTimeframes } from "./tatools.js";
import { LiveChartWithIndicators, StaticChartWithIndicators } from "./Charts";
import { AddExchangeModal } from "./Settings.js";
import { Modal } from "./Modal.js";
import { InspectStrategy, InspectStrategyPopup } from "./Strategies.js";
import { startTrading, stopTrading } from "./Bot.js";
import getEcbDatabase from "./EcbDatabase.js";

function userAllowedToStartStrategies(state, dispatch, type, startingNew) {
  if (
    state.user.isExpired &&
    (type !== "simulation" || Object.keys(state.simStatuses).length > (startingNew ? 2 : 3))
  ) {
    showInfoDialog(
      dispatch,
      <div className="text-danger">
        <FontAwesomeIcon icon={faExclamationTriangle} /> Subscription has expired
      </div>,
      <div>
        Your account is now limited to the Free plan.
        <br />
        {type === "simulation"
          ? "Your subscription plan supports only 1 simultaneous simulation."
          : "Live trading is not available for your subscription plan."}
      </div>
    );
    return false;
  }
  if (
    state.user.subscriptionType === "beginner" &&
    type === "simulation" &&
    Object.keys(state.simStatuses).length > (startingNew ? 4 : 5)
  ) {
    showInfoDialog(
      dispatch,
      <div className="text-warning">
        <FontAwesomeIcon icon={faExclamationTriangle} /> Beginner Plan
      </div>,
      <div>Your subscription plan does not support more than 5 simultaneous simulations.</div>
    );
    return false;
  }
  if (
    state.user.subscriptionType === "beginner" &&
    type !== "simulation" &&
    Object.keys(state.liveStatuses).length > (startingNew ? 0 : 1)
  ) {
    showInfoDialog(
      dispatch,
      <div className="text-warning">
        <FontAwesomeIcon icon={faExclamationTriangle} /> Beginner Plan
      </div>,
      <div>Your subscription plan does not support more than 1 simultaneous strategies in live trading.</div>
    );
    return false;
  }
  return true;
}

function getFilteredSortedStrategies(strategies, filter, tradingSort, statuses) {
  return strategies
    .filter((strategy) => {
      if (!filter) {
        return true;
      }
      let status = statuses[strategy.id];
      return (
        strategy.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
        strategy.pair.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
        strategy.buyRules.find((rule) => {
          return (
            rule.indicator.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
            rule.timeframe.toLowerCase().indexOf(filter.toLowerCase()) !== -1
          );
        }) ||
        strategy.sellRules.find((rule) => {
          return (
            rule.indicator.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
            rule.timeframe.toLowerCase().indexOf(filter.toLowerCase()) !== -1
          );
        }) ||
        (status && status.status.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
      );
    })
    .map((el) => ({ ...el }))
    .sort((strategyA, strategyB) => {
      if (!strategyA || !strategyB) {
        return 0;
      }
      switch (tradingSort) {
        case "name":
          return strategyA.name.localeCompare(strategyB.name);
        case "nameR":
          return strategyB.name.localeCompare(strategyA.name);
        case "pair":
          return strategyA.pair.localeCompare(strategyB.pair);
        case "pairR":
          return strategyB.pair.localeCompare(strategyA.pair);
        case "result":
          if (!statuses[strategyA.id]) {
            return 1;
          }
          if (!statuses[strategyB.id]) {
            return -1;
          }
          if (statuses[strategyA.id].result !== undefined && statuses[strategyA.id].result !== null) {
            if (statuses[strategyB.id].result !== undefined && statuses[strategyB.id].result !== null) {
              return statuses[strategyA.id].result - statuses[strategyB.id].result;
            } else {
              return 1;
            }
          } else {
            if (statuses[strategyB.id].result !== undefined && statuses[strategyB.id].result !== null) {
              return -1;
            } else {
              return 0;
            }
          }

        case "resultR":
          if (!statuses[strategyA.id]) {
            return -1;
          }
          if (!statuses[strategyB.id]) {
            return 1;
          }
          if (statuses[strategyA.id].result !== undefined && statuses[strategyA.id].result !== null) {
            if (statuses[strategyB.id].result !== undefined && statuses[strategyB.id].result !== null) {
              return statuses[strategyB.id].result - statuses[strategyA.id].result;
            } else {
              return -1;
            }
          } else {
            if (statuses[strategyB.id].result !== undefined && statuses[strategyB.id].result !== null) {
              return 1;
            } else {
              return 0;
            }
          }
        case "statusR":
          return !statuses[strategyA.id] || !statuses[strategyB.id]
            ? 0
            : statuses[strategyB.id].status.localeCompare(statuses[strategyA.id].status);
        case "status":
          return !statuses[strategyA.id] || !statuses[strategyB.id]
            ? 0
            : statuses[strategyA.id].status.localeCompare(statuses[strategyB.id].status);
        case "openTradeR":
          return !statuses[strategyA.id] || !statuses[strategyB.id]
            ? 0
            : statuses[strategyA.id].activeTrade
            ? statuses[strategyB.id].activeTrade
              ? 0
              : 1
            : statuses[strategyB.id].activeTrade
            ? -1
            : 0;
        case "openTrade":
          return !statuses[strategyA.id] || !statuses[strategyB.id]
            ? 0
            : statuses[strategyA.id].activeTrade
            ? statuses[strategyB.id].activeTrade
              ? 0
              : -1
            : statuses[strategyB.id].activeTrade
            ? 1
            : 0;
        case "tradeCountR":
          return !statuses[strategyA.id] || !statuses[strategyB.id]
            ? 0
            : statuses[strategyA.id].tradesCount - statuses[strategyB.id].tradesCount;
        case "tradeCount":
          return !statuses[strategyA.id] || !statuses[strategyB.id]
            ? 0
            : statuses[strategyB.id].tradesCount - statuses[strategyA.id].tradesCount;
        default:
          return strategyA.name.localeCompare(strategyB.name);
      }
    });
}

const ResultsSection = (props) => {
  let { dispatch } = useContext(globalContext);
  const [cumulativesSeries, setCumulativesSeries] = useState([]);
  const [emphasisStyle] = useState({
    itemStyle: {
      barBorderWidth: 1,
      shadowBlur: 10,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      shadowColor: "rgba(0,0,0,0.5)",
    },
  });

  useEffect(() => {
    let cumulativesToUse =
      props.stats && props.stats.cumulatives
        ? props.stats.cumulatives
        : props.cumulativeData
        ? props.cumulativeData.cumulatives
        : null;
    if (cumulativesToUse) {
      let cumulativesSeries = [
        {
          name: "Cumulative P/L %",
          type: "line",
          symbol: "none",
          smooth: true,
          itemStyle: {
            color: "#1ebdd6",
          },

          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#1ebdd6",
              },
              {
                offset: 1,
                color: "#0a2240",
              },
            ]),
          },
          data: cumulativesToUse.returns,
        },
        {
          name: props.pair,
          type: "line",
          symbol: "none",
          smooth: false,
          lineStyle: {
            opacity: 0.8,
            width: 1,
          },
          yAxisIndex: 1,
          data: cumulativesToUse.prices,
        },
      ];
      setCumulativesSeries(cumulativesSeries);
    }
  }, [props.stats, props.cumulativeData]);

  return (
    <div>
      <div className="row custom-hr pb-4 pt-2 mx-0 mb-4">
        <div className="col-md-5 mb-4 pl-md-0">
          <h6 className="mb-2">
            <FontAwesomeIcon icon={faRocket} /> Performance
          </h6>
          <div className="table-responsive border border-dark rounded mb-2">
            <table className="table table-new-dark table-sm small">
              <tbody>
                <tr>
                  <td>Total Return:</td>
                  <td
                    className={
                      props.stats.totalReturn > 0 ? "text-success" : props.stats.totalReturn < 0 ? "text-danger" : ""
                    }
                  >
                    {props.stats.tradesCount !== 0 && <>{props.stats.totalReturn.toFixed(2)}%</>}
                  </td>
                </tr>
                <tr>
                  <td>
                    Consistent Return:
                    <a
                      className="text-info ml-2"
                      href="#/"
                      title="Consistent return"
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                        showInfoDialog(
                          dispatch,
                          <div className="text-info">
                            <FontAwesomeIcon icon={faInfoCircle} /> Info
                          </div>,
                          <div>Consistent return - return from all trades that are within 2 standard deviations.</div>
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </a>
                  </td>
                  <td
                    className={
                      props.stats.totalReturnStdDev2 > 0
                        ? "text-success"
                        : props.stats.totalReturnStdDev2 < 0
                        ? "text-danger"
                        : ""
                    }
                  >
                    {props.stats.tradesCount !== 0 && <>{props.stats.totalReturnStdDev2.toFixed(2)}%</>}
                  </td>
                </tr>
                <tr>
                  <td>
                    Max. Drawdown:
                    <a
                      className="text-info ml-2"
                      href="#/"
                      title="Maximum drawdown"
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                        showInfoDialog(
                          dispatch,
                          <div className="text-info">
                            <FontAwesomeIcon icon={faInfoCircle} /> Info
                          </div>,
                          <div>
                            Maximum drawdown - the maximum observed loss from a peak to a trough of a strategy, before a
                            new peak is attained. It is an indicator of the downside risk over the specified period.
                          </div>
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </a>
                  </td>
                  <td className={props.stats.maxDrawdown < 0 ? "text-danger" : ""}>
                    {props.stats.tradesCount !== 0 && <>{props.stats.maxDrawdown.toFixed(2)}%</>}
                  </td>
                </tr>
                <tr>
                  <td>Win Rate:</td>
                  <td className={props.stats.winRate >= 50 ? "text-success" : "text-danger"}>
                    {props.stats.winRate !== null && <>{props.stats.winRate.toFixed(2)}%</>}
                  </td>
                </tr>
                <tr>
                  <td>Win/Loss Ratio:</td>
                  <td
                    className={
                      props.stats.winLossRatio !== null
                        ? props.stats.winLossRatio >= 0.5
                          ? "text-success"
                          : "text-danger"
                        : ""
                    }
                  >
                    {props.stats.winLossRatio !== null && props.stats.winLossRatio.toFixed(2)}
                  </td>
                </tr>

                <tr className="">
                  <td>Win/Loss {props.pairDetails.quoteAsset}:</td>
                  <td
                    className={
                      "text-nowrap " +
                      (props.stats.resultInQuoted > 0
                        ? "text-success"
                        : props.stats.resultInQuoted < 0
                        ? "text-danger"
                        : "")
                    }
                  >
                    {props.stats.tradesCount !== 0 && roundQuotedValue(props.stats.resultInQuoted, props.pairDetails)}
                  </td>
                </tr>
                <tr className="">
                  <td>
                    {props.pair} Return:
                    <a
                      className="text-info ml-2"
                      href="#/"
                      title="Market return"
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                        showInfoDialog(
                          dispatch,
                          <div className="text-info">
                            <FontAwesomeIcon icon={faInfoCircle} /> Info
                          </div>,
                          <div>
                            From <span className="text-info">{formatDate2(props.dateFrom)}</span> to{" "}
                            <span className="text-info">
                              {formatDate2(props.dateTo)} {props.pair}
                            </span>{" "}
                            had a return of{" "}
                            <span
                              className={
                                props.marketReturn > 0 ? "text-success" : props.marketReturn < 0 ? "text-danger" : ""
                              }
                            >
                              {props.marketReturn !== null && <>{props.marketReturn.toFixed(2)}%</>}
                            </span>
                          </div>
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </a>
                  </td>
                  <td
                    className={
                      "text-nowrap " +
                      (props.marketReturn > 0 ? "text-success" : props.marketReturn < 0 ? "text-danger" : "")
                    }
                  >
                    {props.marketReturn !== null && <>{props.marketReturn.toFixed(2)}%</>}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-7 pr-md-0">
          {cumulativesSeries.length > 0 ? (
            <ReactEcharts
              lazyUpdate={false}
              notMerge={false}
              style={{
                height: "242px",
                left: 0,
                top: 0,
                right: 0,
                width: "100%",
              }}
              opts={{ renderer: "svg" }}
              className="bg-new-dark"
              option={{
                replaceMerge: ["xAxis", "yAxis", "series"],
                animation: false,
                tooltip: {
                  backgroundColor: "#343a40",
                  borderColor: "#17a2b8",
                  borderWidth: 1,
                  axisPointer: {
                    type: "cross",
                    label: { color: "#fff", backgroundColor: "#343a40" },
                  },
                  textStyle: {
                    align: "left",
                    fontSize: 12,
                  },
                  trigger: "axis",
                },
                legend: {
                  data: cumulativesSeries.map((el) => el.name),
                  textStyle: { color: "#fff", fontSize: 11 },
                  icon: "pin",
                },
                axisPointer: {
                  link: { xAxisIndex: "all" },
                },
                grid: {
                  top: 28,
                  left: 40,
                  right: 8,
                  bottom: 20,
                },
                xAxis: {
                  type: "category",
                  data: props.stats.cumulatives
                    ? props.stats.cumulatives.dates
                    : props.cumulativeData.cumulatives.dates,
                  scale: true,
                  splitNumber: 5,
                  boundaryGap: false,
                  axisLine: {
                    onZero: true,
                    lineStyle: { color: "transparent", width: "0.5" },
                  },
                  splitLine: {
                    show: true,
                    lineStyle: { color: "#17a2b8", width: "0.5" },
                  },
                  axisLabel: { show: true, color: "#fff", fontSize: 11 },
                },
                yAxis: [
                  {
                    scale: true,
                    splitNumber: 3,
                    splitArea: {
                      show: false,
                    },
                    axisLine: {
                      lineStyle: { color: "transparent", width: "0.5" },
                    },
                    splitLine: {
                      show: true,
                      lineStyle: { color: "#17a2b8", width: "0.5" },
                    },
                    axisLabel: {
                      show: true,
                      color: "#fff",
                      fontSize: 11,
                      align: "right",
                    },
                  },
                  {
                    scale: true,
                    splitNumber: 3,
                    splitArea: {
                      show: false,
                    },
                    axisLine: {
                      lineStyle: { color: "transparent", width: "0.5" },
                    },
                    splitLine: {
                      show: false,
                      lineStyle: { color: "#17a2b8", width: "0.5" },
                    },
                    axisLabel: {
                      show: true,
                      color: "#fff",
                      fontSize: 11,
                      align: "right",
                    },
                  },
                ],

                series: cumulativesSeries,
              }}
            />
          ) : (
            <div className="text-center">
              <div className="loading-white fade-in-fast cursor-help my-5" title="Loading..">
                <img src={loadingReverced} alt="" />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row custom-hr pb-4 pt-2 mx-0 mb-4">
        <div className="col-md-5 mb-4 pl-md-0">
          <h6 className="mb-2">
            <FontAwesomeIcon icon={faChartPie} /> Trades
          </h6>
          <div className="table-responsive border border-dark rounded mb-2 ">
            <table className="table table-new-dark table-sm small">
              <tbody>
                <tr>
                  <td>Trades Count:</td>
                  <td> {props.stats.tradesCount}</td>
                </tr>
                <tr>
                  <td>Positive Trades Count:</td>
                  <td> {props.stats.winTrades}</td>
                </tr>
                <tr>
                  <td>Negative Trades Count:</td>
                  <td> {props.stats.lostTrades}</td>
                </tr>
                <tr>
                  <td>Average Positive Trade:</td>
                  <td className="text-success">
                    {props.stats.avPosTrade > 0 ? props.stats.avPosTrade.toFixed(2) + "%" : ""}
                  </td>
                </tr>
                <tr>
                  <td>Average Negative Trade:</td>
                  <td className="text-danger">
                    {props.stats.avNegTrade < 0 ? props.stats.avNegTrade.toFixed(2) + "%" : ""}
                  </td>
                </tr>
                <tr>
                  <td>Average Trade Duration:</td>
                  <td>{props.stats.tradesCount > 0 && showTime(props.stats.avTradeDuration)}</td>
                </tr>
                <tr>
                  <td>Trade Size:</td>
                  <td>
                    {props.tradeSize && props.tradeSize.value > 0
                      ? `${props.tradeSize.value} ${
                          props.tradeSize.type === "base" ? props.pairDetails.baseAsset : props.pairDetails.quoteAsset
                        }`
                      : ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-7 pr-md-0">
          {props.stats && props.stats.chartData && props.stats.chartData.trades && (
            <>
              <ReactEcharts
                lazyUpdate={false}
                notMerge={false}
                opts={{ renderer: "svg" }}
                className="bg-new-dark"
                onEvents={{
                  click: (e) => {
                    let trade = props.stats.trades[e.dataIndex - 1];
                    if (trade && props.setTradeToShow) {
                      props.setTradeToShow(trade);
                    }
                    let chart = document.getElementById(props.chartId);
                    if (chart) {
                      chart.scrollIntoView({
                        behavior: "smooth",
                        block: "nearest",
                        inline: "start",
                      });
                    }
                  },
                }}
                style={{
                  height: "242px",
                  left: 0,
                  top: 0,
                  right: 0,
                  width: "100%",
                }}
                option={{
                  replaceMerge: ["xAxis", "yAxis", "series"],
                  animation: false,
                  tooltip: {},
                  legend: {
                    data: [props.stats.trades.length > 100 ? "Last 100 Trades" : "Trades"],
                    textStyle: { color: "#fff", fontSize: 11 },
                    icon: "none",
                  },
                  axisPointer: {
                    link: { xAxisIndex: "all" },
                  },
                  dataZoom: [
                    {
                      type: "inside",
                      xAxisIndex: [0],
                      start: 0,
                      end: 100,
                    },
                  ],
                  grid: {
                    top: 28,
                    left: 40,
                    right: 8,
                    bottom: 20,
                  },
                  xAxis: {
                    type: "category",
                    data: props.stats.chartData.trades.xAxis,
                    scale: true,
                    splitNumber: 5,
                    boundaryGap: false,
                    axisLine: {
                      onZero: true,
                      lineStyle: { color: "transparent", width: "0.5" },
                    },
                    splitLine: {
                      show: true,
                      lineStyle: { color: "#17a2b8", width: "0.5" },
                    },
                    axisLabel: { show: true, color: "#fff", fontSize: 11 },
                  },
                  yAxis: {
                    min: props.stats.chartData.trades.min < 0 ? props.stats.chartData.trades.min * 1.3 : 0,
                    max: props.stats.chartData.trades.max > 0 ? props.stats.chartData.trades.max * 1.3 : 0,
                    splitArea: {
                      show: false,
                    },
                    axisLine: {
                      lineStyle: { color: "transparent", width: "0.5" },
                    },
                    splitLine: {
                      show: true,
                      lineStyle: { color: "#17a2b8", width: "0.5" },
                    },
                    axisLabel: {
                      show: true,
                      color: "#fff",
                      fontSize: 11,
                      align: "right",
                      showMinLabel: false,
                      showMaxLabel: false,
                    },
                    minorTick: { show: false },
                  },

                  series: {
                    name: props.stats.trades.length > 100 ? "Last 100 Trades" : "Trades",
                    type: "bar",
                    stack: "one",
                    symbol: "none",
                    emphasis: emphasisStyle,
                    data: props.stats.chartData.trades.data,
                  },
                }}
              />
              <div className="small text-center text-secondary">Click on a trade to see it on the chart below</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const TradeDetails = (props) => {
  let { dispatch } = useContext(globalContext);

  const detailsCallback = useCallback(
    (details) => {
      showInfoDialog(
        dispatch,
        <div className="">
          <FontAwesomeIcon icon={faClipboardList} /> Trade Details
        </div>,
        <div className="">
          <div>
            <div className="min-w-200 d-inline-block">Order Status:</div>
            <span className="text-info">{props.status === "NEW" ? `PENDING LIMIT ${props.type}` : props.status}</span>
          </div>
          {props.exchId && (
            <div>
              <div className="min-w-200 d-inline-block">Exchange ID:</div>
              <span className="text-info one-click-select">{props.exchId}</span>
            </div>
          )}
          {props.commInQuote > 0 && (
            <div>
              <div className="min-w-200 d-inline-block">Fee</div>
              <span className="text-info one-click-select">
                {props.commInQuote} {props.pairDetails.quoteAsset}
              </span>
            </div>
          )}

          <div>
            <div className="min-w-200 d-inline-block">Total trade size:</div>
            <span className="text-info">
              {props.size ? roundBaseValue(props.size, props.pairDetails) : 0} {props.pairDetails.baseAsset}
            </span>
          </div>
          {props.trade && props.trade.notSoldInBase > 0 && (
            <div>
              <div className="min-w-200 d-inline-block">
                {props.trade.pInB ? "Profit left in base coin:" : "Leftover:"}
              </div>
              <span className="text-info">
                {props.size ? roundBaseValue(props.trade.notSoldInBase, props.pairDetails) : 0}{" "}
                {props.pairDetails.baseAsset}
              </span>
            </div>
          )}
          <div>
            <div className="min-w-200 d-inline-block">
              {props.status === "NEW" ? `Pending ${props.type} at` : `Average ${props.type} price`}:
            </div>
            <span className="text-info">{roundQuotedValue(props.avPrice, props.pairDetails)}</span>
          </div>
          {details && (
            <div>
              {details.cwp !== 0 && details.cwp && (
                <div>
                  <div className="min-w-200 d-inline-block">Sell trigger price:</div>
                  <span className="text-info">{details.cwp}</span>
                </div>
              )}
              {details.ct && (
                <div>
                  <div className="min-w-200 d-inline-block">Sell type:</div>
                  <span className="text-info">{details.ct === "trailingTarget" ? "trailing target" : details.ct}</span>
                </div>
              )}
              {details.s !== 0 && details.s && (
                <div>
                  <div className="min-w-200 d-inline-block">Stoploss at:</div>
                  <span className="text-info">{details.s}</span>
                </div>
              )}
              {details.sT !== 0 && details.sT && (
                <div>
                  <div className="min-w-200 d-inline-block">Sl. timer started at:</div>
                  <span className="text-info">{formatDateTime5(details.sT)}</span>
                </div>
              )}
              {details.t !== 0 && details.t && (
                <div>
                  <div className="min-w-200 d-inline-block">Target at:</div>
                  <span className="text-info">{details.t}</span>
                </div>
              )}
              {details.tt !== 0 && details.tt && (
                <div>
                  <div className="min-w-200 d-inline-block">Trailing target at:</div>
                  <span className="text-info">{details.tt}</span>
                </div>
              )}
              {details.hp !== 0 && details.hp && (
                <div>
                  <div className="min-w-200 d-inline-block">Highest price after target:</div>
                  <span className="text-info">{details.hp}</span>
                </div>
              )}
              {details.tc !== 0 && details.tc && (
                <div>
                  <div className="min-w-200 d-inline-block">Time close:</div>
                  <span className="text-info">{formatDateTime(new Date(details.tc))}</span>
                </div>
              )}
            </div>
          )}
          {props.fills && (
            <>
              <div className="text-center mt-2 mb-1">All {props.type} fills:</div>
              <div className="table-responsive border-top border-dark">
                <table className="table table-sm small table-hover">
                  <thead className="border-dark border-bottom">
                    <tr>
                      <th className="text-left pl-2 text-nowrap">Trading price</th>
                      <th className="text-left text-nowrap">Executed ({props.pairDetails.baseAsset})</th>
                      <th className="text-left text-nowrap">Executed ({props.pairDetails.quoteAsset})</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.fills
                      .sort((a, b) => (props.type === "BUY" ? a.p - b.p : b.p - a.p))
                      .map((fill, index) => (
                        <tr key={index}>
                          <td className="pl-2 ">{roundQuotedValue(fill.p, props.pairDetails)}</td>
                          <td>{roundBaseValue(fill.q, props.pairDetails)}</td>
                          <td>{roundQuotedValue(fill.q * fill.p, props.pairDetails)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          {props.comm && (
            <div className="table-responsive border border-dark rounded mt-4 mb-1">
              <table className="table table-sm small table-hover">
                <thead className="thead-new-dark">
                  <tr>
                    <th className="text-nowrap text-center" colSpan="2">
                      Trading fees:
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.comm.bnb > 0 && (
                    <tr>
                      <td className="pl-2 ">BNB</td>
                      <td>{props.comm.bnb}</td>
                    </tr>
                  )}
                  {props.comm.base > 0 && (
                    <tr>
                      <td className="pl-2 ">{props.pairDetails.baseAsset}</td>
                      <td>{props.comm.base}</td>
                    </tr>
                  )}
                  {props.comm.quote > 0 && (
                    <tr>
                      <td className="pl-2 ">{props.pairDetails.quoteAsset}</td>
                      <td>{props.comm.quote}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      );
    },
    [props.tradeDetails, props.exchId, props.fills, props.avPrice, props.details]
  );

  return (
    <a
      className="text-info ml-1"
      href="#/"
      title="Trade Details"
      onClick={(e) => {
        e.preventDefault();
        document.activeElement.blur();
        if (props.showTradeDetails && props.strategyId && props.resType) {
          detailsCallback(props.tradeDetails);
          /*(props.resType === "simulation"
            ? getEcbDatabase().getSimStrategyDetails(props.strategyId)
            : getEcbDatabase().getLiveStrategyDetails(props.strategyId)
          ).then((details) => {
            detailsCallback(details);
          });*/
        } else {
          detailsCallback();
        }
      }}
    >
      <FontAwesomeIcon icon={faInfoCircle} />
    </a>
  );
};

const TradesTable = (props) => {
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [itemsOnPage] = useState(5);

  useEffect(() => {
    setPage(0);
    let pages = [];
    for (let i = 0; i < props.trades.length / itemsOnPage; i++) {
      pages.push(i);
    }
    setPages(pages);
  }, [props.stats, props.trades]);

  return (
    <div className="mx-1">
      <div className="table-responsive border border-dark rounded mb-2 ">
        <table className="table table-new-dark table-sm small table-hover rounded">
          <thead className="thead-new-dark">
            <tr>
              <th className="text-left pl-2"></th>
              <th className="text-left"></th>
              <th className="text-left text-nowrap">Dates</th>
              <th className="text-left">Duration</th>
              <th className="text-left text-nowrap">Prices</th>
              {props.stats.params.tradeSize.value ? (
                <th className="text-left text-nowrap">Size {props.pairDetails.quoteAsset}</th>
              ) : null}
              {props.stats.params.tradeSize.value ? (
                <th className="text-left text-nowrap">Profit in {props.pairDetails.baseAsset}</th>
              ) : null}
              {props.stats.params.tradeSize.value ? (
                <th className="text-left text-nowrap">Result {props.pairDetails.quoteAsset}</th>
              ) : null}
              <th className="text-left text-nowrap">Result %</th>
            </tr>
          </thead>
          <tbody>
            {props.trades
              .map((trade) => trade)
              .sort((a, b) => {
                return b.od - a.od;
              })
              .filter((el, index) => index >= page * itemsOnPage && index < page * itemsOnPage + itemsOnPage)
              .map((trade, index) => {
                let icon = null;
                let colorClass = "";
                if (trade.closeId) {
                  if (trade.result > 0) {
                    icon = faThumbsUp;
                    colorClass = "text-success";
                  } else if (trade.result < 0) {
                    icon = faThumbsDown;
                    colorClass = "text-danger";
                  }
                }

                let colorClassQuoted = "";
                if (trade.resultInQuoted) {
                  if (trade.resultInQuoted > 0) {
                    colorClassQuoted = "text-success";
                  } else if (trade.resultInQuoted < 0) {
                    colorClassQuoted = "text-danger";
                  }
                }
                let od = "";
                if (trade.od) {
                  let arr = formatDateTime(new Date(trade.od)).split(" ");
                  od = (
                    <>
                      <span className="text-nowrap">{arr[0]}</span> <span className="text-nowrap">{arr[1]}</span>
                    </>
                  );
                }
                let cd = "";
                if (trade.cd) {
                  let arr = formatDateTime(trade.cd).split(" ");
                  cd = (
                    <>
                      <span className="text-nowrap">{arr[0]}</span> <span className="text-nowrap">{arr[1]}</span>
                    </>
                  );
                }

                let validOpenStatuses = ["NEW", "PARTIALLY_FILLED", "FILLED", null, undefined];
                let validCloseStatuses = ["NEW", "PARTIALLY_FILLED", "FILLED", "FILLED_WITH_LEFTOVER", null, undefined];

                return (
                  <tr className="cursor-pointer" key={index}>
                    {validOpenStatuses.includes(trade.openStatus) && validCloseStatuses.includes(trade.closeStatus) && (
                      <>
                        <td
                          className="text-left pl-2 align-middle"
                          onClick={() => {
                            if (trade && props.setTradeToShow) {
                              props.setTradeToShow(trade);
                              let chart = document.getElementById(props.chartId);
                              if (chart) {
                                chart.scrollIntoView({
                                  behavior: "smooth",
                                  block: "nearest",
                                  inline: "start",
                                });
                              }
                            }
                          }}
                        >
                          {icon && <FontAwesomeIcon className={colorClass} icon={icon} />}
                        </td>
                        <td
                          className="text-left"
                          onClick={() => {
                            if (trade && props.setTradeToShow) {
                              props.setTradeToShow(trade);
                            }
                            let chart = document.getElementById(props.chartId);
                            if (chart) {
                              chart.scrollIntoView({
                                behavior: "smooth",
                                block: "nearest",
                                inline: "start",
                              });
                            }
                          }}
                        >
                          <div className="text-nowrap">Buy</div>
                          <div className="text-nowrap">Sell</div>
                        </td>
                        <td
                          className="text-left"
                          onClick={() => {
                            if (trade && props.setTradeToShow) {
                              props.setTradeToShow(trade);
                            }
                            let chart = document.getElementById(props.chartId);
                            if (chart) {
                              chart.scrollIntoView({
                                behavior: "smooth",
                                block: "nearest",
                                inline: "start",
                              });
                            }
                          }}
                        >
                          <div className="text-nowrap">{od}</div>
                          <div className="text-nowrap">{cd}</div>
                        </td>
                        <td
                          className="text-left text-nowrap"
                          onClick={() => {
                            if (trade && props.setTradeToShow) {
                              props.setTradeToShow(trade);
                            }
                            let chart = document.getElementById(props.chartId);
                            if (chart) {
                              chart.scrollIntoView({
                                behavior: "smooth",
                                block: "nearest",
                                inline: "start",
                              });
                            }
                          }}
                        >
                          {getDatesDiff(trade.od, trade.cd)}
                        </td>
                        <td className="text-left">
                          <div className="text-nowrap">
                            <span
                              onClick={() => {
                                if (trade && props.setTradeToShow) {
                                  props.setTradeToShow(trade);
                                }
                                let chart = document.getElementById(props.chartId);
                                if (chart) {
                                  chart.scrollIntoView({
                                    behavior: "smooth",
                                    block: "nearest",
                                    inline: "start",
                                  });
                                }
                              }}
                            >
                              {roundQuotedValue(trade.o, props.pairDetails)}
                            </span>
                            <TradeDetails
                              strategyId={props.strategyId}
                              resType={props.type}
                              status={trade.openStatus}
                              exchId={trade.openIdExch !== "sim" ? trade.openIdExch : null}
                              fills={trade.openFills}
                              pairDetails={props.pairDetails}
                              type="BUY"
                              size={trade.sizeInBase}
                              avPrice={trade.o}
                              showTradeDetails={!trade.c}
                              commInQuote={trade.oCommInQuote}
                              tradeDetails={props.statusDetails}
                            />
                          </div>
                          <div className="text-nowrap">
                            {trade.c !== 0 && trade.c && (
                              <>
                                <span
                                  onClick={() => {
                                    if (trade && props.setTradeToShow) {
                                      props.setTradeToShow(trade);
                                    }
                                    let chart = document.getElementById(props.chartId);
                                    if (chart) {
                                      chart.scrollIntoView({
                                        behavior: "smooth",
                                        block: "nearest",
                                        inline: "start",
                                      });
                                    }
                                  }}
                                >
                                  {roundQuotedValue(trade.c, props.pairDetails)}
                                </span>
                                <TradeDetails
                                  status={trade.closeStatus}
                                  exchId={trade.openIdExch !== "sim" ? trade.closeId : null}
                                  fills={trade.closeFills}
                                  pairDetails={props.pairDetails}
                                  type="SELL"
                                  size={trade.sellSizeInBase}
                                  avPrice={trade.c}
                                  tradeDetails={trade.details}
                                  commInQuote={trade.cCommInQuote}
                                  trade={trade}
                                  resType={props.type}
                                  showTradeDetails={true}
                                  strategyId={props.strategyId}
                                />
                              </>
                            )}
                          </div>
                        </td>
                        {props.stats.params.tradeSize.value ? (
                          <td
                            className="text-left"
                            onClick={() => {
                              if (trade && props.setTradeToShow) {
                                props.setTradeToShow(trade);
                              }
                              let chart = document.getElementById(props.chartId);
                              if (chart) {
                                chart.scrollIntoView({
                                  behavior: "smooth",
                                  block: "nearest",
                                  inline: "start",
                                });
                              }
                            }}
                          >
                            <div className="text-nowrap">
                              {roundQuotedValue(trade.sizeInQuoted, props.pairDetails)}{" "}
                            </div>
                            <div className="text-nowrap">
                              {trade.sellSizeInQuoted
                                ? roundQuotedValue(trade.sellSizeInQuoted, props.pairDetails)
                                : ""}
                            </div>
                          </td>
                        ) : null}
                        {props.stats.params.tradeSize.value ? (
                          <td
                            className="text-left text-success"
                            onClick={() => {
                              if (trade && props.setTradeToShow) {
                                props.setTradeToShow(trade);
                              }
                              let chart = document.getElementById(props.chartId);
                              if (chart) {
                                chart.scrollIntoView({
                                  behavior: "smooth",
                                  block: "nearest",
                                  inline: "start",
                                });
                              }
                            }}
                          >
                            {trade.pInB && trade.notSoldInBase > 0 && (
                              <div className="text-nowrap">
                                {roundBaseValue(trade.notSoldInBase, props.pairDetails)}
                              </div>
                            )}
                          </td>
                        ) : null}
                        {props.stats.params.tradeSize.value ? (
                          <td
                            className={"text-left " + colorClassQuoted}
                            onClick={() => {
                              if (trade && props.setTradeToShow) {
                                props.setTradeToShow(trade);
                              }
                              let chart = document.getElementById(props.chartId);
                              if (chart) {
                                chart.scrollIntoView({
                                  behavior: "smooth",
                                  block: "nearest",
                                  inline: "start",
                                });
                              }
                            }}
                          >
                            {trade.c !== 0 &&
                              trade.c &&
                              !trade.pInB &&
                              roundQuotedValue(trade.resultInQuoted, props.pairDetails)}
                          </td>
                        ) : null}
                        <td
                          className={"text-left " + colorClass}
                          onClick={() => {
                            if (trade && props.setTradeToShow) {
                              props.setTradeToShow(trade);
                            }
                            let chart = document.getElementById(props.chartId);
                            if (chart) {
                              chart.scrollIntoView({
                                behavior: "smooth",
                                block: "nearest",
                                inline: "start",
                              });
                            }
                          }}
                        >
                          {trade.c !== 0 &&
                          trade.c &&
                          trade.result !== undefined &&
                          trade.result !== "" &&
                          trade.result !== null
                            ? trade.result.toFixed(2) + "%"
                            : ""}
                        </td>
                      </>
                    )}
                    {trade.openStatus === "ERROR" && (
                      <>
                        <td className="text-left text-danger">
                          {<FontAwesomeIcon className="text-danger" icon={faExclamationTriangle} />}
                        </td>
                        <td className="text-left text-danger" colSpan="7">
                          On {od} error BUY. {trade.openMsg}
                        </td>
                      </>
                    )}
                    {trade.openStatus === "CANCELED" && trade.openStatus !== "ERROR" && (
                      <>
                        <td className="text-left text-info">
                          {<FontAwesomeIcon className="text-info" icon={faExclamationTriangle} />}
                        </td>
                        <td className="text-left text-info" colSpan="7">
                          On {od} canceled BUY. {trade.openMsg}
                        </td>
                      </>
                    )}
                    {trade.closeStatus === "ERROR" && trade.openStatus !== "CANCELED" && trade.openStatus !== "ERROR" && (
                      <>
                        <td className="text-left text-danger">
                          {<FontAwesomeIcon className="text-danger" icon={faExclamationTriangle} />}
                        </td>
                        <td className="text-left text-danger" colSpan="7">
                          On {od} error SELL. {trade.closeMsg}
                        </td>
                      </>
                    )}
                    {trade.closeStatus === "CANCELED" &&
                      trade.openStatus !== "CANCELED" &&
                      trade.openStatus !== "ERROR" && (
                        <>
                          <td className="text-left text-info">
                            {<FontAwesomeIcon className="text-info" icon={faExclamationTriangle} />}
                          </td>
                          <td className="text-left text-info" colSpan="7">
                            On {od} canceled SELL. {trade.closeMsg}
                          </td>
                        </>
                      )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="overflow-auto">
        <nav aria-label="page navigation">
          <ul className="pagination">
            <li className="page-item" key="prev">
              <a
                className="page-link small px-2 py-1"
                aria-label="Previous"
                href="#/"
                onClick={(e) => {
                  e.preventDefault();
                  if (page > 0) {
                    setPage(page - 1);
                  }
                }}
              >
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Previous</span>
              </a>
            </li>
            {pages.map((el) => {
              return (
                <li className={"page-item " + (page === el ? "active" : "")} key={el}>
                  <a
                    className="page-link small px-2 py-1"
                    href="#/"
                    onClick={(e) => {
                      e.preventDefault();
                      setPage(el);
                    }}
                  >
                    {el + 1}
                  </a>
                </li>
              );
            })}

            <li className="page-item" key="next">
              <a
                className="page-link small px-2 py-1"
                href="#/"
                aria-label="Next"
                onClick={(e) => {
                  e.preventDefault();
                  if (page < Math.floor(props.trades.length / itemsOnPage)) {
                    setPage(page + 1);
                  }
                }}
              >
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="small text-center text-secondary">Click on a trade to see it on the chart above</div>
    </div>
  );
};

const UsdResult = (props) => {
  const [resultUsd, setResultUsd] = useState("");
  const [prices, setPrices] = useState({});
  const [isSubscribed, setIsSubscribed] = useState(false);

  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!isSubscribed && getExchange(props.strategy.exchange)) {
      setIsSubscribed(true);
      getExchange(props.strategy.exchange).subscribeToPricesUpdate(props.id, (prices, pricesStats) => {
        if (componentIsMounted.current) {
          setPrices({
            prices: prices,
            pricesStats: pricesStats,
          });
        }
      });
    }
    return () => {
      if (getExchange(props.strategy.exchange)) {
        getExchange(props.strategy.exchange).unsubscribeToPricesUpdate(props.id);
      }
    };
  }, [props.strategy]);

  useEffect(() => {
    let resultUsd = "";
    if (prices && prices.prices && Object.keys(prices.prices).length > 0) {
      resultUsd = getUsdValue(props.status.resultInQuoted, props.strategy.pairDetails.quoteAsset, prices.prices);
    }
    setResultUsd(resultUsd);
  }, [prices, props.status]);

  return (
    <td
      className={`text-left cursor-pointer ${
        props.status.resultInQuoted > 0 ? "text-success" : props.status.resultInQuoted < 0 ? "text-danger" : ""
      }`}
      onClick={(e) => {
        if (props.onClickTd) {
          props.onClickTd(e);
        }
      }}
    >
      {props.resultInQuote ? (
        props.status.resultInQuoted > 0.01 || props.status.resultInQuoted < -0.01 ? (
          props.status.resultInQuoted.toFixed(2)
        ) : (
          roundQuotedValue(props.status.resultInQuoted, props.strategy.pairDetails)
        )
      ) : (
        <> {resultUsd !== "" ? "$" + resultUsd.toFixed(2) : "-"}</>
      )}
    </td>
  );
};

const OpenTrade = (props) => {
  const [openTradeResult, setOpenTradeResult] = useState("");
  const [prices, setPrices] = useState({});
  const [tradeId, setTradeId] = useState(null);
  let { dispatch } = useContext(globalContext);

  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.status.activeTrade) {
      if (!tradeId) {
        if (!props.status.lastTrade) {
          return;
        }
        let newTradeId = props.status.lastTrade.openId;
        let newId = `${props.idPrefix ? props.idPrefix : ""}${newTradeId}`;
        setTradeId(newId);
        if (getExchange(props.strategy.exchange)) {
          getExchange(props.strategy.exchange).subscribeToPricesUpdate(newId, (prices, pricesStats) => {
            if (componentIsMounted.current) {
              setPrices({
                prices: prices,
                pricesStats: pricesStats,
              });
            }
          });
        }
      }
    } else if (tradeId) {
      setTradeId(null);
      if (getExchange(props.strategy.exchange)) {
        getExchange(props.strategy.exchange).unsubscribeToPricesUpdate(tradeId);
      }
    }
    return () => {
      if (props.status.activeTrade && getExchange(props.strategy.exchange)) {
        getExchange(props.strategy.exchange).unsubscribeToPricesUpdate(props.status.lastTrade.openId);
      }
    };
  }, [props.status]);

  useEffect(() => {
    let openTradeResult = "";
    //BID
    if (props.status.activeTrade && props.status.lastTrade) {
      if (prices && prices.pricesStats && prices.pricesStats[props.strategy.pair]) {
        let trade = props.status.lastTrade;
        let commissionRate =
          props.type === "simulation" ? props.strategy.sim.commissionRate : props.strategy.live.commissionRate;
        let percent =
          ((prices.pricesStats[props.strategy.pair].bid - trade.o) / trade.o) * 100 -
          (commissionRate ? commissionRate * 2 : 0);
        if (trade.openStatus === "NEW") {
          openTradeResult = <span className="text-info nowrap">Pending BUY</span>;
        } else if (trade.openStatus === "PARTIALLY_FILLED") {
          openTradeResult = (
            <>
              <span className="text-info nowrap">Partial BUY</span>
              {
                (openTradeResult = percent && !isNaN(percent) && (
                  <>
                    {" "}
                    <span className={percent > 0 ? "text-success" : percent < 0 ? "text-danger" : ""}>
                      &nbsp;{percent.toFixed(2)}%
                    </span>
                  </>
                ))
              }
            </>
          );
        } else if (trade.closeStatus === "NEW") {
          openTradeResult = <span className="text-info nowrap">Pending SELL</span>;
        } else if (trade.closeStatus === "PARTIALLY_FILLED") {
          openTradeResult = <span className="text-info nowrap">Partial SELL</span>;
        } else {
          openTradeResult = percent && !isNaN(percent) && (
            <span className={percent > 0 ? "text-success" : percent < 0 ? "text-danger" : ""}>
              &nbsp;{percent.toFixed(2)}%
            </span>
          );
        }
      }
    }

    if (props.status.activeTrade && !openTradeResult) {
      openTradeResult = <FontAwesomeIcon icon={faCheck} />;
    }
    setOpenTradeResult(openTradeResult);
  }, [props.status, prices]);

  return (
    <div className="d-inline-block cursor-help tooltip-parent">
      {openTradeResult}
      {props.status &&
        props.status.details &&
        (props.status.details.s || props.status.details.t || props.status.details.tt || props.status.details.tc) && (
          <>
            <div className="text-left tooltip-on-hover border border-info rounded bg-new-dark small p-2">
              {props.status.details.s !== 0 && props.status.details.s && (
                <div>
                  <div className="min-w-120 d-inline-block text-light">Stoploss at:</div>
                  <span className="text-light-green">{props.status.details.s}</span>
                </div>
              )}
              {props.status.details.sT !== 0 && props.status.details.sT && (
                <div>
                  <div className="min-w-120 d-inline-block text-light">Sl. timer started at:</div>
                  <span className="text-light-green">{formatDateTime5(props.status.details.sT)}</span>
                </div>
              )}
              {props.status.details.t !== 0 && props.status.details.t && (
                <div>
                  <div className="min-w-120 d-inline-block text-light">Target at:</div>
                  <span className="text-light-green">{props.status.details.t}</span>
                </div>
              )}
              {props.status.details.tt !== 0 && props.status.details.tt && (
                <div>
                  <div className="min-w-120 d-inline-block text-light">Trailing target at:</div>
                  <span className="text-light-green">{props.status.details.tt}</span>
                </div>
              )}
              {props.status.details.hp !== 0 && props.status.details.hp && (
                <div>
                  <div className="min-w-120 d-inline-block text-light">Highest price after target:</div>
                  <span className="text-light-green">{props.status.details.hp}</span>
                </div>
              )}
              {props.status.details.tc !== 0 && props.status.details.tc && (
                <div>
                  <div className="min-w-120 d-inline-block text-light">Time close:</div>
                  <span className="text-light-green">{formatDateTime(new Date(props.status.details.tc))}</span>
                </div>
              )}
            </div>
          </>
        )}
    </div>
  );
};

const TradingParamsModal = (props) => {
  useEffect(() => {
    if (props.strategy) {
      if (!$("#tradingParamsModal").is(":visible")) {
        $("#tradingParamsModal").modal("toggle");
      }
    } else {
      if ($("#tradingParamsModal").is(":visible")) {
        $("#tradingParamsModal").modal("toggle");
      }
    }
  }, [props.strategy]);

  return (
    <Modal
      id="tradingParamsModal"
      okText="Cancel"
      header={
        <div className="text-info">
          {props.type === "simulation" ? (
            <>
              <FontAwesomeIcon icon={faRandom} /> Edit Simulation
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faChartLine} /> Edit Live Trading
            </>
          )}
        </div>
      }
      content={
        <div className="text-center small">
          {props.strategy && (
            <TradingParams
              strategy={props.strategy}
              type={props.type}
              setExchange={props.setExchange}
              closeFn={() => props.setStrategy(null)}
            />
          )}
        </div>
      }
      func={async () => {}}
      closeFn={() => {
        props.setStrategy(null);
      }}
      dark
    />
  );
};

const TradingParams = (props) => {
  let { state, dispatch } = useContext(globalContext);

  const [maxDrawdown, setMaxDrawdown] = useState("");
  const [maxNegTrades, setMaxNegTrades] = useState("");
  const [maxLoss, setMaxLoss] = useState("");
  const [tradeSize, setTradeSize] = useState(
    props.type === "simulation" && state.tradeSize.type === "percent"
      ? { type: "base", value: "", pair: "" }
      : state.tradeSize
  );
  const [order, setOrder] = useState({ type: "market", value: "" });
  const [commissionRate, setCommissionRate] = useState(state.commissionRate);
  const [executionUpdated, setExecutionUpdated] = useState(false);
  const [profitInBase, setProfitInBase] = useState(false);
  const [startWithSell, setStartWithSell] = useState(false);
  const [upfrontBuyPrice, setUpfrontBuyPrice] = useState("");
  const selectedStrategy = useRef(null);
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.strategy) {
      let execution = props.type === "simulation" ? props.strategy.sim : props.strategy.live;
      setTradeSize({ ...execution.tradeSize });
      setMaxDrawdown(execution.maxDrawdown ? execution.maxDrawdown : "");
      setMaxLoss(execution.maxLoss ? execution.maxLoss : "");
      setMaxNegTrades(execution.maxNegTrades ? execution.maxNegTrades : "");
      setOrder({ ...execution.orderDetails });
      setProfitInBase(execution.profitInBase);
      if (selectedStrategy.current !== props.strategy.id) {
        selectedStrategy.current = props.strategy.id;
        setStartWithSell(false);
        setUpfrontBuyPrice("");
      }
    } else {
      setTradeSize({ type: "base", value: "", pair: "" });
      setMaxDrawdown("");
      setMaxLoss("");
      setMaxNegTrades("");
      setOrder({ type: "market", value: "" });
      setStartWithSell(false);
      setUpfrontBuyPrice("");
      setProfitInBase(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.strategy, props.type]);

  return (
    <div className="d-inline-block text-left w-350">
      <div className="pb-2 text-nowrap">
        <div className="min-w-150 d-inline-block">Trade size type:</div>
        <RadioButtons
          options={[
            {
              optionClasses: "text-light ",
              checkedColor: "text-info",
              uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
              checked: tradeSize.type === "base",
              check: () => {
                let newTradeSize = {
                  type: "base",
                  value: tradeSize.value,
                };
                setTradeSize(newTradeSize);
                dispatch({
                  type: "tradeSize",
                  payload: newTradeSize,
                });
              },
              text: "Base",
              disabled: !state.app && !state.demo && tradeSize.type !== "base",
            },
            {
              optionClasses: "text-light ",
              checkedColor: "text-info",
              uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
              checked: tradeSize.type === "quoted",
              check: () => {
                let newTradeSize = {
                  type: "quoted",
                  value: tradeSize.value,
                };
                setTradeSize(newTradeSize);
                dispatch({
                  type: "tradeSize",
                  payload: newTradeSize,
                });
              },
              text: "Quote",
              disabled: !state.app && !state.demo && tradeSize.type !== "quoted",
            },
            {
              optionClasses: "text-light ",
              checkedColor: "text-info",
              uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
              checked: tradeSize.type === "percent",
              check: () => {
                let newTradeSize = {
                  type: "percent",
                  value: tradeSize.value,
                };
                setTradeSize(newTradeSize);
                dispatch({
                  type: "tradeSize",
                  payload: newTradeSize,
                });
              },
              text: "Percent",
              hidden: props.type !== "trading",
              disabled: !state.app && !state.demo && tradeSize.type !== "percent",
            },
          ]}
        />
      </div>
      <div className="pb-2 pt-1 text-nowrap">
        <div className="min-w-150 d-inline-block">Trade size:</div>
        <input
          type="number"
          className={`setup-info  ${tradeSize.type === "percent" ? "w-80" : "w-165"}`}
          disabled={!state.app && !state.demo}
          value={tradeSize.value}
          placeholder="<Number>"
          onChange={(e) => {
            let value = Math.abs(parseFloat(e.target.value));

            let newTradeSize = {
              type: tradeSize.type,
              value: isNaN(value) || value < 0 ? "" : value,
            };
            setTradeSize(newTradeSize);
            dispatch({
              type: "tradeSize",
              payload: newTradeSize,
            });
          }}
        />
        <span className="text-info">
          {tradeSize.type === "percent"
            ? `% of ${props.strategy.pairDetails.quoteAsset} balance`
            : tradeSize.type === "base"
            ? props.strategy.pairDetails.baseAsset
            : props.strategy.pairDetails.quoteAsset}
        </span>
      </div>
      {props.type === "trading" && (
        <div className="pb-2 pt-1 text-nowrap">
          <div className="min-w-150 d-inline-block">Orders type:</div>
          <div className="d-inline-flex flex-column">
            <RadioButtons
              options={[
                {
                  optionClasses: "text-light pb-2",
                  checkedColor: "text-info",
                  uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                  checked: order.type === "market",
                  check: () => setOrder({ type: "market", value: order.value }),
                  text: "Market",
                  disabled: !state.app && !state.demo && order.type !== "market",
                },
                {
                  optionClasses: "text-light pb-2",
                  checkedColor: "text-info",
                  uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                  checked: order.type === "limit",
                  check: () => setOrder({ type: "limit", value: order.value }),
                  text: "Limit",
                  disabled: !state.app && !state.demo && order.type !== "limit",
                },
                {
                  optionClasses: "text-light pb-2",
                  checkedColor: "text-info",
                  uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                  checked: order.type === "mBuyLSell",
                  check: () => setOrder({ type: "mBuyLSell", value: order.value }),
                  text: "Market Buys, Limit Sells",
                  disabled: !state.app && !state.demo && order.type !== "mBuyLSell",
                },
                {
                  optionClasses: "text-light",
                  checkedColor: "text-info",
                  uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                  checked: order.type === "lBuyMSell",
                  check: () => setOrder({ type: "lBuyMSell", value: order.value }),
                  text: "Limit Buys, Market Sells",
                  disabled: !state.app && !state.demo && order.type !== "lBuyMSell",
                },
              ]}
            />
            {(order.type === "limit" || order.type === "mBuyLSell" || order.type === "lBuyMSell") && (
              <small className="text-info text-wrap max-w-200 mt-2">
                *Stoploss and Time stop are always Market orders
              </small>
            )}
          </div>
        </div>
      )}
      {(order.type === "limit" || order.type === "mBuyLSell" || order.type === "lBuyMSell") && (
        <div className="pb-2 pt-1 text-nowrap fade-in">
          <div className="min-w-150 d-inline-block">Orders price tolerance:</div>
          <input
            type="number"
            className="setup-info  w-165 optional"
            disabled={!state.app && !state.demo}
            value={order.value ? order.value : ""}
            placeholder="<Optional>"
            onChange={(e) => {
              let value = Math.abs(parseFloat(e.target.value));
              setOrder({
                type: order.type,
                value: isNaN(value) || value < 0 ? "" : value,
              });
            }}
          />
          <span className="text-info">%</span>
        </div>
      )}
      {false && (
        <div className="my-3 pb-2 pt-1 text-nowrap">
          <div className="min-w-150 d-inline-block">Leave profit in base coin:</div>
          <RadioButtons
            options={[
              {
                optionClasses: "text-light",
                checkedColor: "text-info",
                uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                checked: !profitInBase,
                check: () => setProfitInBase(false),
                text: "No",
                disabled: !state.app && !state.demo && profitInBase,
              },
              {
                optionClasses: "text-light",
                checkedColor: "text-info",
                uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                checked: profitInBase,
                check: () => setProfitInBase(true),
                text: "Yes",
                disabled: !state.app && !state.demo && !profitInBase,
              },
            ]}
          />
          <a
            href="#/"
            className="text-info"
            title="What's Leave profit
                    in base"
            onClick={(e) => {
              e.preventDefault();
              document.activeElement.blur();
              showInfoDialog(
                dispatch,
                <span className="text-info">
                  <FontAwesomeIcon icon={faInfoCircle} /> Leave profit in base
                </span>,
                "The profit of a trade will not be converted to the quote coin on close of the trade, but will stay in the base coin. In time you will see accumulation of the base coin in your account after positive trades."
              );
            }}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </a>
        </div>
      )}
      {props.type === "simulation" && (
        <div className="pb-2 pt-1 text-nowrap">
          <div className="min-w-150 d-inline-block">Commission Rate:</div>
          <input
            type="number"
            disabled={!state.app && !state.demo}
            className="setup-info  w-165"
            value={commissionRate}
            placeholder="<Number>"
            onChange={(e) => {
              let value = parseFloat(e.target.value);
              setCommissionRate(isNaN(value) ? "" : Math.abs(value));
            }}
          />
          <span className="text-info">%</span>
        </div>
      )}
      <div className="pb-2 pt-1 text-nowrap text-info">Automatic stop options:</div>
      <div className="pb-2 pt-1 text-nowrap">
        <div className="min-w-150 d-inline-block">Total loss exceeds:</div>
        <input
          type="number"
          className="setup-info  w-165 optional"
          disabled={!state.app && !state.demo}
          value={maxLoss}
          placeholder="<Optional>"
          onChange={(e) => {
            let value = parseFloat(e.target.value);
            setMaxLoss(isNaN(value) ? "" : Math.abs(value));
          }}
        />
        <span className="text-info">%</span>
      </div>
      <div className="pb-2 pt-1 text-nowrap">
        <div className="min-w-150 d-inline-block">Drawdown exceeds:</div>
        <input
          type="number"
          className="setup-info  w-165 optional"
          disabled={!state.app && !state.demo}
          value={maxDrawdown}
          placeholder="<Optional>"
          onChange={(e) => {
            let value = parseFloat(e.target.value);
            setMaxDrawdown(isNaN(value) ? "" : Math.abs(value));
          }}
        />
        <span className="text-info">%</span>
      </div>
      <div className="pb-2 pt-1 text-nowrap">
        <div className="min-w-150 d-inline-block">Negative trades in a row:</div>
        <input
          type="number"
          className="setup-info  w-165 optional"
          disabled={!state.app && !state.demo}
          value={maxNegTrades}
          placeholder="<Optional>"
          onChange={(e) => {
            let value = parseInt(e.target.value);
            setMaxNegTrades(isNaN(value) || value === 0 ? "" : Math.abs(value));
          }}
        />
      </div>
      {!(props.type === "simulation" ? state.simStatuses : state.liveStatuses)[props.strategy.id] && (
        <>
          <div className="text-nowrap text-info mt-3 pb-2 pt-1">
            <div className="min-w-150 d-inline-block">Start with a sell (short):</div>
            <RadioButtons
              options={[
                {
                  optionClasses: "text-light",
                  checkedColor: "text-info",
                  uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                  checked: !startWithSell,
                  check: () => setStartWithSell(false),
                  text: "No",
                  disabled: !state.app && !state.demo && startWithSell,
                },
                {
                  optionClasses: "text-light",
                  checkedColor: "text-info",
                  uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                  checked: startWithSell,
                  check: () => setStartWithSell(true),
                  text: "Yes",
                  disabled: !state.app && !state.demo && !startWithSell,
                },
              ]}
            />
            <a
              href="#/"
              className="text-info"
              title="What's Start with a sell"
              onClick={(e) => {
                e.preventDefault();
                document.activeElement.blur();
                showInfoDialog(
                  dispatch,
                  <span className="text-info">
                    <FontAwesomeIcon icon={faInfoCircle} /> Start with a sell (short)
                  </span>,
                  <>
                    <p>
                      If you already have the base coin in your account the strategy can start with a direct Sell
                      according to its selling options. After the start, the bot will calculate the sell options and
                      when a sell signal is reached, it will place a sell order with the specified size and type. It
                      will then proceed with a standard Buy.
                    </p>
                    <p>
                      Please provide the price at which you previously bought the pair, then the stoploss and target
                      will be calculated based on this price.
                    </p>
                  </>
                );
              }}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </a>
          </div>

          {startWithSell && (
            <div className="text-nowrap pb-2 pt-1">
              <div className="min-w-150 d-inline-block">"I've bought at price:"</div>
              <input
                type="number"
                className="setup-info w-165 required"
                disabled={!state.app && !state.demo}
                value={upfrontBuyPrice}
                placeholder="<Required>"
                onChange={(e) => {
                  let value = parseFloat(e.target.value);
                  setUpfrontBuyPrice(isNaN(value) ? "" : Math.abs(value));
                }}
              />
            </div>
          )}
        </>
      )}
      {(state.app || state.demo) && (
        <div className="text-center mt-4">
          {executionUpdated ? (
            <div className="loading-white fade-in-fast" title="Loading..">
              <img src={loadingReverced} alt="" />
            </div>
          ) : (
            <button
              className={`btn btn-sm  min-w-100 fade-in ${
                (props.type === "simulation" ? state.simStatuses : state.liveStatuses)[props.strategy.id]
                  ? "btn-info"
                  : "btn-light-green"
              }`}
              onClick={async () => {
                if (tradeSize.value <= 0) {
                  showInfoDialog(
                    dispatch,
                    <span className="text-danger">
                      <FontAwesomeIcon icon={faExclamationTriangle} /> Invalid trade size
                    </span>,
                    "Enter a trade size."
                  );
                  return;
                }

                if (startWithSell && !upfrontBuyPrice) {
                  showInfoDialog(
                    dispatch,
                    <span className="text-danger">
                      <FontAwesomeIcon icon={faExclamationTriangle} /> Invalid price.
                    </span>,
                    "Enter a price at which you've bought the pair."
                  );
                  return;
                }

                setExecutionUpdated(true);
                sleep(100).then(() => {
                  if (componentIsMounted.current) {
                    setExecutionUpdated(false);
                  }
                });
                dispatch({
                  type: "commissionRate",
                  payload: commissionRate,
                });

                let execution = props.type === "simulation" ? props.strategy.sim : props.strategy.live;

                execution.maxDrawdown = maxDrawdown;
                execution.maxLoss = maxLoss;
                execution.maxNegTrades = maxNegTrades;
                execution.tradeSize = tradeSize;
                execution.orderDetails = order;
                execution.commissionRate = commissionRate;
                execution.profitInBase = profitInBase;

                props.strategy.editDate = new Date().getTime();
                updateStrategy(props.strategy);
                dispatch({
                  type: "strategy",
                  payload: props.strategy,
                });

                if (props.type === "trading") {
                  if (!state.exchanges[props.strategy.exchange] || !state.exchanges[props.strategy.exchange].valid) {
                    if (props.setExchange) {
                      props.setExchange(props.strategy.exchange);
                      if (!$("#addApiKeys").is(":visible")) {
                        $("#addApiKeys").modal("toggle");
                      }
                      return;
                    } else {
                      if (props.closeFn) {
                        props.closeFn();
                      }
                    }
                  }
                }
                let status = (props.type === "simulation" ? state.simStatuses : state.liveStatuses)[props.strategy.id];
                let start = false;
                if (!status) {
                  start = true;
                  status = {
                    id: props.strategy.id,
                    creationDate: new Date().getTime(),
                    editDate: new Date().getTime(),
                    details: {},
                    nextTrade: "buy",
                    status: "Loading",
                    tradesCount: 0,
                    userId: props.strategy.userId,
                  };

                  if (startWithSell) {
                    let date = new Date().getTime();
                    let sizeInBase = 0;
                    let sizeInQuoted = 0;

                    switch (execution.tradeSize.type) {
                      case "base":
                        sizeInBase = execution.tradeSize.value;
                        sizeInQuoted = execution.tradeSize.value * upfrontBuyPrice;
                        break;
                      case "quoted":
                        sizeInBase = execution.tradeSize.value / upfrontBuyPrice;
                        sizeInQuoted = execution.tradeSize.value;
                        break;
                      case "percent":
                        let quotedBalance = getBalance(props.strategy.pairDetails.quoteAsset, props.strategy.exchange);
                        if (!quotedBalance) {
                          showInfoDialog(
                            dispatch,
                            <span className="text-danger">
                              <FontAwesomeIcon icon={faExclamationTriangle} /> Cannot connect to{" "}
                              {props.strategy.exchange}
                            </span>,
                            `Cannot calculate the current ${props.strategy.pairDetails.quoteAsset} balance of your account. Please try later.`
                          );
                          return;
                        }
                        sizeInQuoted = quotedBalance * (execution.tradeSize.value / 100);
                        sizeInBase = sizeInQuoted / upfrontBuyPrice;
                        break;
                      default:
                    }
                    let trade = {
                      id: `${props.strategy.id}-${date}`,
                      editDate: date,
                      sId: props.strategy.id,
                      openId: `${props.strategy.id}-${date}`,
                      openIdExch: `unknown-${date}`,
                      od: date,
                      o: fixNumber(upfrontBuyPrice, 10),
                      openStatus: "FILLED",
                      openFills: [],
                      sizeInBase: sizeInBase,
                      sizeInQuoted: sizeInQuoted,
                      sellSizeInQuoted: 0,
                      comm: { bnb: 0, base: 0 },
                      sellComm: { bnb: 0, base: 0 },
                      origQtyInQuoted: sizeInQuoted,
                    };
                    saveStrategTrade(props.type, trade, state.user.token);

                    if (props.strategy.stoploss) {
                      status.details.s = fixNumber(trade.o * (1 - props.strategy.stoploss / 100), 10);
                    }
                    if (props.strategy.target) {
                      status.details.t = fixNumber(trade.o * (1 + props.strategy.target / 100), 10);
                    }
                    if (props.strategy.timeClose) {
                      let tc = new Date(trade.od);
                      tc.setHours(
                        tc.getHours() + props.strategy.timeClose,
                        tc.getMinutes(),
                        tc.getSeconds(),
                        tc.getMilliseconds()
                      );
                      status.details.tc = tc.getTime();
                    }
                    status.activeTrade = true;
                    status.nextTrade = "sell";
                    status.lastTrade = trade;
                    status.tradesCount = 1;
                  }
                }
                //Check account status
                if (start) {
                  if (!userAllowedToStartStrategies(state, dispatch, props.type, true)) {
                    return;
                  }
                }
                dispatch({
                  type: props.type === "simulation" ? "simStatus" : "liveStatus",
                  payload: { status: status },
                });

                if (start) {
                  if (props.strategy.external) {
                    delete props.strategy.external;
                    updateStrategy(props.strategy);
                    dispatch({
                      type: "strategy",
                      payload: props.strategy,
                    });
                  }

                  startTrading(props.strategy, status, props.type, dispatch, state.demo);
                }
                if (props.closeFn) {
                  props.closeFn();
                }
              }}
            >
              {(props.type === "simulation" ? state.simStatuses : state.liveStatuses)[props.strategy.id] ? (
                "Update"
              ) : startWithSell ? (
                <>
                  Start with Sell
                  <FontAwesomeIcon icon={faPlay} className="ml-2" />
                </>
              ) : (
                <>
                  Start
                  <FontAwesomeIcon icon={faPlay} className="ml-2" />
                </>
              )}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

const TradingSetup = (props) => {
  const { state, dispatch } = useContext(globalContext);
  const [status, setStatus] = useState(null);
  const [timeframes] = useState(["1m", "5m", "15m", "30m", "1h", "4h", "1d", "1w", "1M"]);
  const [timeframe, setTimeframe] = useState(null);
  const [resetManualBuy, setResetManualBuy] = useState({});
  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");
  const [exchange, setExchange] = useState("");
  const componentIsMounted = useRef(true);
  const statusIdRef = useRef(null);
  const [trades, setTrades] = useState([]);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (props.strategy) {
      statusIdRef.current = `${props.strategy.id}-${props.type}`;
      setStatus((props.type === "simulation" ? state.simStatuses : state.liveStatuses)[props.strategy.id]);
      let statusIdRefTmp = statusIdRef.current;
      (props.type === "simulation"
        ? getEcbDatabase().getSimStrategyTrades(props.strategy.id)
        : getEcbDatabase().getLiveStrategyTrades(props.strategy.id)
      ).then((newTrades) => {
        if (componentIsMounted.current && statusIdRefTmp === statusIdRef.current) {
          if (
            newTrades.length > 0 &&
            (newTrades.length !== trades.length || newTrades[newTrades.length - 1].c !== trades[trades.length - 1].c)
          ) {
            setTrades(newTrades);
          }
        }
      });
    } else {
      setStatus(null);
      setTrades([]);
      statusIdRef.current = null;
    }
  }, [props.strategy, props.type, state.simStatuses, state.liveStatuses]);

  useEffect(() => {
    if (props.strategy) {
      setTimeframe(getInterval(getTimeframes(props.strategy)[0]));
    }
  }, [props.strategy]);

  return (
    props.strategy && (
      <>
        <div
          className={`${
            props.rightPanelWidth > 650 && !props.setupHidden ? "d-flex flex-wrap" : "pr-1"
          } small user-select-none fade-in pl-1`}
        >
          {!props.setupHidden && (
            <div
              className={`${
                props.rightPanelWidth > 650 ? "w-350 border-right" : "border-bottom text-center pb-4 mb-4"
              }`}
            >
              <TradingParams strategy={props.strategy} setExchange={setExchange} type={props.type} />
            </div>
          )}

          <div
            className={props.rightPanelWidth > 650 && !props.setupHidden ? "pl-2" : ""}
            style={props.rightPanelWidth > 650 && !props.setupHidden ? { width: props.rightPanelWidth - 400 } : {}}
          >
            <div className="d-flex pl-1">
              <div className="min-w-50">Status:</div>
              <div
                className={`flex-grow-1 " + ${
                  status && status.status === "Running"
                    ? "text-light-green"
                    : status && status.errorLevel === "error"
                    ? "text-danger"
                    : "text-warning"
                }`}
              >
                {!status ? (
                  "Not started"
                ) : status.status === "Loading" ? (
                  <div className="loading-white fade-in-fast" title="Loading..">
                    <img src={loadingReverced} alt="" />
                  </div>
                ) : (
                  <div className="d-inline-block fade-in-fast">{status.status}</div>
                )}
                {status && status.status !== "Loading" && status.error && !state.user.isAdmin && (
                  <a
                    href="#/"
                    className={`mx-2 ${
                      status.errorLevel === "error"
                        ? "text-danger"
                        : status.errorLevel === "warning"
                        ? "text-warning"
                        : status.errorLevel === "info"
                        ? "text-info"
                        : ""
                    }`}
                    title="Event"
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();

                      showInfoDialog(
                        dispatch,
                        <span
                          className={
                            status.errorLevel === "error"
                              ? "text-danger"
                              : status.errorLevel === "warning"
                              ? "text-warning"
                              : status.errorLevel === "info"
                              ? "text-info"
                              : ""
                          }
                        >
                          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />
                          Trading event
                        </span>,
                        <>
                          <div>{status.error}</div>
                        </>
                      );
                    }}
                  >
                    {status.error.indexOf("Connection to") !== -1 ? (
                      <FontAwesomeIcon icon={faLink} />
                    ) : status.error.indexOf("Trading freezed") !== -1 ? (
                      <FontAwesomeIcon icon={faSnowflake} />
                    ) : (
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    )}
                  </a>
                )}
                {status && !state.user.isAdmin && props.strategy.external && (
                  <a
                    href="#/"
                    className="mx-2 text-info"
                    title="Event"
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();

                      showInfoDialog(
                        dispatch,
                        <span className="text-info">
                          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />
                          Strategy updated
                        </span>,
                        <>
                          <div>The strategy was updated on another ECB app and was automatically stopped.</div>
                        </>
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  </a>
                )}
              </div>

              {!state.app && !state.demo && status && status.status === "Running" && !state.user.isAdmin && (
                <a
                  href="#/"
                  className="text-warning mr-1"
                  title="Stop"
                  onClick={(e) => {
                    e.preventDefault();
                    document.activeElement.blur();
                    showConfirmDialog(
                      dispatch,
                      <span className="text-info">
                        <FontAwesomeIcon icon={faQuestionCircle} /> Remote stop
                      </span>,
                      <>
                        Do you want to execute a remote stop?
                        <br />
                        The stop may take up to 1 minute to execute.
                      </>,
                      () => {
                        status.status = "Loading";
                        dispatch({
                          type: props.type === "simulation" ? "simStatus" : "liveStatus",
                          payload: {
                            status: status,
                          },
                        });
                        sendRemoteStrategyStop(state.user.token, props.strategy.id, props.type, dispatch);
                      }
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faStop} />
                </a>
              )}
              {(state.app || state.demo) && status && status.status !== "Loading" && (
                <>
                  {status.status !== "Running" ? (
                    <a
                      href="#/"
                      className="text-green mr-2"
                      title="Start"
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();

                        if (
                          !state.exchanges[props.strategy.exchange] ||
                          !state.exchanges[props.strategy.exchange].valid
                        ) {
                          if (props.type === "trading") {
                            setExchange(props.strategy.exchange);

                            if (!$("#addApiKeys").is(":visible")) {
                              $("#addApiKeys").modal("toggle");
                            }
                            return;
                          }
                        }
                        if (!userAllowedToStartStrategies(state, dispatch, props.type)) {
                          return;
                        }
                        if (props.strategy.external) {
                          delete props.strategy.external;
                          updateStrategy(props.strategy);
                          dispatch({
                            type: "strategy",
                            payload: props.strategy,
                          });
                        }
                        startTrading(props.strategy, status, props.type, dispatch, state.demo);
                      }}
                    >
                      <FontAwesomeIcon icon={faPlay} />
                    </a>
                  ) : (
                    <a
                      href="#/"
                      className="text-warning mr-2"
                      title="Stop"
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                        stopTrading(props.strategy, props.type, dispatch, state.demo, status);
                      }}
                    >
                      <FontAwesomeIcon icon={faStop} />
                    </a>
                  )}
                  <a
                    href="#/"
                    className="text-info mr-2"
                    title="Reset Trades"
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      if (state.demo) {
                        return;
                      }
                      showConfirmDialog(
                        dispatch,
                        <span className="text-info">
                          <FontAwesomeIcon icon={faQuestionCircle} /> Reset Trades
                        </span>,
                        <>
                          The reset will remove all trades and events.
                          <br />
                          {props.type === "trading" && (
                            <>
                              The open positions <b>will not be closed</b>.
                            </>
                          )}
                        </>,
                        () => {
                          let wasRunning = status.status === "Running";
                          dispatch({
                            type: props.type === "simulation" ? "simStatus" : "liveStatus",
                            payload: {
                              status: {
                                ...status,
                                status: "Loading",
                                details: {},
                              },
                            },
                          });

                          (async () => {
                            await removeTrading(props.strategy, status, props.type, dispatch, state.demo);
                            let statusNew = {
                              id: props.strategy.id,
                              creationDate: new Date().getTime(),
                              editDate: new Date().getTime(),
                              nextTrade: "buy",
                              status: "Stopped",
                              tradesCount: 0,
                              userId: props.strategy.userId,
                            };
                            dispatch({
                              type: props.type === "simulation" ? "simStatus" : "liveStatus",
                              payload: { status: statusNew },
                            });
                            addTrading(props.strategy, statusNew, props.type, wasRunning);
                          })();
                        }
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faUndo} className="" />
                  </a>

                  <a
                    href="#/"
                    className="text-danger pr-1"
                    title="Remove"
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      showConfirmDialog(
                        dispatch,
                        <span className="text-danger">
                          <FontAwesomeIcon icon={faQuestionCircle} /> Remove{" "}
                          {props.type === "trading" ? "Live trading" : "Simulation"}
                        </span>,
                        <>
                          <div>
                            Are you sure you want to remove the{" "}
                            {props.type === "trading" ? "Live trading" : "Simulation"} of strategy{" "}
                            <b>{props.strategy.name}</b>?
                          </div>
                          {status.status === "Running" && (
                            <div className="mt-3">
                              <FontAwesomeIcon className="text-danger" icon={faExclamationTriangle} /> The{" "}
                              {props.type === "trading" ? "Live trading" : "Simulation"} is running!
                            </div>
                          )}
                          {status.activeTrade && props.type === "trading" && (
                            <div className="mt-3">
                              <FontAwesomeIcon className="text-danger" icon={faExclamationTriangle} /> There is an
                              opened trade that will NOT be closed!
                            </div>
                          )}
                        </>,
                        () => {
                          removeTrading(props.strategy, status, props.type, dispatch, state.demo);
                        }
                      );
                    }}
                  >
                    <FontAwesomeIcon className="" icon={faTimesCircle} />
                  </a>
                </>
              )}
            </div>
            <div className="fade-in mt-2">
              <div className="d-flex pl-1">
                <div className="min-w-50 mb-1 mr-2">Open Trade:</div>

                {status ? (
                  status.activeTrade ? (
                    <>
                      <div className="flex-grow-1">
                        <OpenTrade strategy={props.strategy} status={status} idPrefix={"setup"} type={props.type} />
                      </div>
                      {state.exchangesInitialized && (state.app || state.demo) && status.status !== "Loading" && (
                        <div className="">
                          {status.manualSellAt && status.manualSellAt > new Date().getTime() ? (
                            <div className="loading-white fade-in-fast" title="Loading..">
                              <img src={loadingReverced} alt="" />
                            </div>
                          ) : (
                            <a
                              href="#/"
                              className="text-danger pr-1"
                              title={
                                status.lastTrade
                                  ? status.lastTrade.openStatus === "NEW"
                                    ? "Cancel Trade"
                                    : "Close Trade"
                                  : ""
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                document.activeElement.blur();

                                showConfirmDialog(
                                  dispatch,
                                  <span className="text-info">
                                    <FontAwesomeIcon icon={faQuestionCircle} />{" "}
                                    {status.lastTrade
                                      ? status.lastTrade.openStatus === "NEW"
                                        ? "Cancel Penging BUY"
                                        : `Manual Sell`
                                      : ""}
                                  </span>,
                                  <div>
                                    Are you sure you want to{" "}
                                    {status.lastTrade
                                      ? status.lastTrade.openStatus === "NEW"
                                        ? "cancel the pending BUY order?"
                                        : `place a MARKET SELL ORDER?`
                                      : ""}
                                  </div>,
                                  () => {
                                    let now = new Date();
                                    now.setSeconds(now.getSeconds() + 60);
                                    status.editDate = new Date().getTime();
                                    dispatch({
                                      type: props.type === "simulation" ? "simStatus" : "liveStatus",
                                      payload: {
                                        status: {
                                          ...status,
                                          manualSellAt: now.getTime(),
                                        },
                                      },
                                    });
                                    manualSell(props.strategy, props.type, now.getTime(), state.demo);
                                  }
                                );
                              }}
                            >
                              <FontAwesomeIcon icon={faBan} className="" />
                            </a>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="flex-grow-1 text-info">no</div>
                      {state.app && (
                        <div className="">
                          {status.manualBuyAt && status.manualBuyAt > new Date().getTime() ? (
                            <div className="loading-white fade-in-fast" title="Loading..">
                              <img src={loadingReverced} alt="" />
                            </div>
                          ) : (
                            <a
                              href="#/"
                              className="text-green pr-1"
                              title="Manual BUY order"
                              onClick={(e) => {
                                e.preventDefault();
                                document.activeElement.blur();
                                setResetManualBuy({});
                                if (!$("#manualBuyTradeConfirm").is(":visible")) {
                                  $("#manualBuyTradeConfirm").modal("toggle");
                                }
                              }}
                            >
                              <FontAwesomeIcon icon={faPlusCircle} />
                            </a>
                          )}
                        </div>
                      )}
                    </>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
            {props.strategy && (
              <div className="mt-3">
                <div className="text-right mb-2">
                  {timeframes.map((time, index) => {
                    return (
                      <a
                        key={time}
                        href="/#"
                        className={timeframe === time ? "bg-info text-light rounded px-1" : "text-info px-1"}
                        onClick={(e) => {
                          e.preventDefault();
                          document.activeElement.blur();
                          setTimeframe(time);
                        }}
                      >
                        {time}
                      </a>
                    );
                  })}
                </div>
                <LiveChartWithIndicators
                  strategy={props.strategy}
                  pair={props.strategy.pair}
                  exchange={props.strategy.exchange}
                  timeframe={timeframe}
                  id={`setupChart${props.type}`}
                  datapointLimit={70}
                  showZoomSlider={true}
                  height={Math.max(180, props.topPanelHeight - 230)}
                  trades={trades}
                />
              </div>
            )}
          </div>
        </div>
        <ManualBuyOrder id="manualBuyTradeConfirm" strategy={props.strategy} reset={resetManualBuy} type={props.type} />
        <AddExchangeModal
          apiKey={apiKey}
          setApiKey={setApiKey}
          apiSecret={apiSecret}
          setApiSecret={setApiSecret}
          exchange={exchange}
          setExchange={setExchange}
          editExchange={exchange ? true : false}
          message="No valid API KEY. Add and try again"
          fn={(exchange) => {
            setApiKey("");
            setApiSecret("");
            setExchange("");
          }}
          closeFn={() => {
            setApiKey("");
            setApiSecret("");
            setExchange("");
          }}
        />
      </>
    )
  );
};

const ManualBuyOrder = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const [manualBuyPrice, setManualBuyPrice] = useState("");
  const [manualOrderType, setManualOrderType] = useState("market");
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (!props.strategy) {
      return;
    }
    setManualBuyPrice("");
    setManualOrderType("market");
    let prices = getExchange(props.strategy.exchange).getPrices();
    let price = prices[props.strategy.pair];
    if (price) {
      setManualBuyPrice(price);
    }
    setStatus(
      props.type === "simulation" ? state.simStatuses[props.strategy.id] : state.liveStatuses[props.strategy.id]
    );
  }, [props.strategy, props.reset, props.type]);

  return (
    <ModalConfirm
      id={props.id}
      header={
        <span className="text-info">
          <FontAwesomeIcon icon={faQuestionCircle} /> Manual BUY order
        </span>
      }
      content={
        <div className="font-normal">
          Are you sure you want to place a manual BUY order?
          {status && props.type === "trading" && (
            <div className="mt-2">
              <div className="min-w-100 d-inline-block">Order type:</div>
              <RadioButtons
                options={[
                  {
                    optionClasses: "text-dark",
                    checkedColor: "text-info",
                    uncheckedColor: "text-white border rounded-circle border-info",
                    checked: manualOrderType === "market",
                    check: () => setManualOrderType("market"),
                    text: "MARKET",
                  },
                  {
                    optionClasses: "text-dark",
                    checkedColor: "text-info",
                    uncheckedColor: "text-white border rounded-circle border-info",
                    checked: manualOrderType === "limit",
                    check: () => {
                      if (!manualBuyPrice) {
                        let prices = getExchange(props.strategy.exchange).getPrices();
                        let price = prices[props.strategy.pair];
                        if (price) {
                          setManualBuyPrice(price);
                        }
                      }
                      setManualOrderType("limit");
                    },
                    text: "LIMIT",
                  },
                ]}
              />
              {manualOrderType === "limit" && (
                <div className="text-nowrap mt-2">
                  <div className="min-w-100 d-inline-block">Limit Price:</div>
                  <input
                    type="number"
                    className="d-inline-block btn-bottom-outline-info w-150 "
                    value={manualBuyPrice}
                    placeholder="<Number>"
                    onChange={(e) => {
                      let value = Math.abs(parseFloat(e.target.value));
                      if (!isNaN(value) && value > 0) {
                        setManualBuyPrice(value);
                      }
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      }
      func={() => {
        let now = new Date();
        now.setSeconds(now.getSeconds() + 60);
        status.editDate = new Date().getTime();
        dispatch({
          type: props.type === "simulation" ? "simStatus" : "liveStatus",
          payload: { status: { ...status, manualBuyAt: now.getTime() } },
        });
        manualBuy(
          props.strategy,
          props.type,
          manualOrderType,
          roundQuotedValue(manualBuyPrice, props.strategy.pairDetails),
          now.getTime(),
          state.demo
        );
      }}
    />
  );
};

function getTableSetup(smallWidthDevice) {
  let defaultSetup = {
    name: true,
    pair: !smallWidthDevice,
    size: !smallWidthDevice,
    tradesNumber: true,
    openTrade: true,
    resultP: true,
    result: !smallWidthDevice,
    status: true,
    actions: true,
  };
  try {
    let storedSetup = localStorage.getItem("botTradingTableSetup");
    if (storedSetup) {
      storedSetup = JSON.parse(storedSetup);
      return storedSetup;
    }
  } catch (e) {}
  return defaultSetup;
}

const ExecutionsTable = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const componentIsMounted = useRef(true);
  const [strategies, setStrategies] = useState([]);
  const [tradingSort, setTradingSort] = useState(getFromLocalStorage("tradingSort", "name"));
  const [tableSetup, setTableSetup] = useState(getTableSetup(props.smallWidthDevice));
  const [tableSetupEdit, setTableSetupEdit] = useState({ ...tableSetup });
  const [filteredStrategies, setFilteredStrategies] = useState([]);
  const [hasLoadingExecutions, setHasLoadingExecutions] = useState(false);
  const [strategyToManualBuyTrade, setStrategyToManualBuyTrade] = useState(null);
  const [resetManualBuy, setResetManualBuy] = useState({});
  const [strategyToShow, setStrategyToShow] = useState({});

  const [tableColumns] = useState([
    { id: "name", label: "Strategy Name" },
    { id: "pair", label: "Pair" },
    { id: "size", label: "Trade Size" },
    { id: "tradesNumber", label: "Trades Number" },
    { id: "openTrade", label: "Open Trade" },
    { id: "resultP", label: "Result %" },
    { id: "result", label: "Result $" },
    { id: "status", label: "Status" },
    { id: "actions", label: "Actions" },
  ]);
  const [tradeInfoSound, setTradeInfoSound] = useState(state.tradeInfoSound);
  const [manualTradeConfirm, setManualTradeConfirm] = useState(state.manualTradeConfirm);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [executionsPerPage, setExecutionsPerPage] = useState(
    getFromLocalStorage("executionsPerPage", "10", "number", true)
  );
  const [checkedStrategies, setCheckedStrategies] = useState([]);
  const [checkAllStrategies, setCheckAllStrategies] = useState(false);
  const [filter, setFilter] = useState("");
  const [strategyToEdit, setStrategyToEdit] = useState(null);
  const [strategyToInspect, setStrategyToInspect] = useState(null);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.activeGroup) {
      let hasLoading = false;
      let statusesToUse = props.type === "simulation" ? state.simStatuses : state.liveStatuses;
      let newStrategies = state.strategies.filter((strategy) => {
        let include = statusesToUse[strategy.id] && props.activeGroup.strategies.includes(strategy.id);
        if (include && !hasLoading) {
          hasLoading = statusesToUse[strategy.id].status === "Loading";
        }
        return include;
      });
      setStrategies(newStrategies);
      setHasLoadingExecutions(hasLoading);
    }
  }, [props.activeGroup, state.strategies, props.type, state.simStatuses, state.liveStatuses]);

  useEffect(() => {
    setFilteredStrategies(
      getFilteredSortedStrategies(
        strategies,
        filter,
        tradingSort,
        props.type === "simulation" ? state.simStatuses : state.liveStatuses
      )
    );
    let allCheckedStillShown = checkedStrategies.reduce((acc, id) => {
      if (acc) {
        return strategies.find((strategy) => strategy.id === id);
      }
      return acc;
    }, true);
    if (!allCheckedStillShown) {
      setCheckedStrategies([]);
      setCheckAllStrategies(false);
    }
    if (checkAllStrategies && (checkedStrategies.length !== strategies.length || strategies.length === 0)) {
      setCheckAllStrategies(false);
    }
    if (!checkAllStrategies && strategies.length > 0 && checkedStrategies.length === strategies.length) {
      setCheckAllStrategies(true);
    }
  }, [strategies, filter, props.type, tradingSort]);

  useEffect(() => {
    let pages = [];
    for (
      let i = 0;
      i < filteredStrategies.length / (executionsPerPage ? executionsPerPage : filteredStrategies.length);
      i++
    ) {
      pages.push(i);
    }
    setPages(pages);
    setStartIndex((executionsPerPage ? executionsPerPage : 0) * page);
    setEndIndex(
      (executionsPerPage ? executionsPerPage : 0) * page +
        (executionsPerPage ? executionsPerPage : filteredStrategies.length)
    );
    if (page > pages.length - 1) {
      setPage(Math.max(pages.length - 1, 0));
    }
  }, [page, filteredStrategies, executionsPerPage]);

  return (
    <>
      {strategies.length > 0 ? (
        <>
          <ManualBuyOrder
            id="manualBuyTradeConfirmTable"
            strategy={strategyToManualBuyTrade}
            reset={resetManualBuy}
            type={props.type}
          />
          <div className="d-flex my-2 pl-3">
            {(state.app || state.demo) && !state.user.isAdmin && (
              <div className="text-nowrap">
                <div className="custom-control custom-checkbox d-inline-block" key="All">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="allTradingStrategies"
                    checked={checkAllStrategies}
                    onChange={(e) => {
                      if (checkAllStrategies) {
                        setCheckedStrategies([]);
                      } else {
                        setCheckedStrategies(filteredStrategies.slice(startIndex, endIndex).map((el) => el.id));
                      }
                      setCheckAllStrategies(!checkAllStrategies);
                    }}
                  />
                  <label
                    type="text"
                    className="custom-control-label font-weight-light font-italic"
                    htmlFor="allTradingStrategies"
                  ></label>
                </div>
                <div
                  className="d-inline-block"
                  style={{
                    opacity: hasLoadingExecutions || checkedStrategies.length === 0 ? 0.5 : 1,
                  }}
                >
                  <a
                    href="#/"
                    className="text-green"
                    title="Start selected strategies"
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      if (hasLoadingExecutions || checkedStrategies.length === 0) {
                        showInfoDialog(
                          dispatch,
                          <span className="text-info">
                            <FontAwesomeIcon icon={faInfoCircle} /> No Strategies Selected
                          </span>,
                          <>Select strategies first.</>
                        );
                        return;
                      }
                      if (!userAllowedToStartStrategies(state, dispatch, props.type)) {
                        return;
                      }
                      let showNoExchangeApiKeys = false;
                      checkedStrategies.forEach((id) => {
                        let strategy = strategies.find((el) => el.id === id);

                        if (
                          (!state.exchanges[strategy.exchange] || !state.exchanges[strategy.exchange].valid) &&
                          props.type === "trading"
                        ) {
                          showNoExchangeApiKeys = true;
                          return;
                        }

                        let status =
                          props.type === "simulation"
                            ? state.simStatuses[strategy.id]
                            : state.liveStatuses[strategy.id];
                        if (status.status !== "Running") {
                          if (strategy.external) {
                            delete strategy.external;
                            updateStrategy(strategy);
                            dispatch({
                              type: "strategy",
                              payload: strategy,
                            });
                          }
                          startTrading(strategy, status, props.type, dispatch, state.demo);
                        }
                      });

                      if (showNoExchangeApiKeys) {
                        if (!$("#addApiKeys").is(":visible")) {
                          $("#addApiKeys").modal("toggle");
                        }
                        return;
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faPlay} className="mr-2" />
                  </a>
                  <a
                    href="#/"
                    className="text-warning"
                    title="Stop selected strategies"
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      if (hasLoadingExecutions || checkedStrategies.length === 0) {
                        showInfoDialog(
                          dispatch,
                          <span className="text-info">
                            <FontAwesomeIcon icon={faInfoCircle} /> No Strategies Selected
                          </span>,
                          <>Select strategies first.</>
                        );
                        return;
                      }

                      checkedStrategies.forEach((id) => {
                        let strategy = strategies.find((el) => el.id === id);
                        let status =
                          props.type === "simulation"
                            ? state.simStatuses[strategy.id]
                            : state.liveStatuses[strategy.id];
                        if (status.status === "Running") {
                          stopTrading(strategy, props.type, dispatch, state.demo, status);
                        }
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faStop} className="mr-2" />
                  </a>
                  <a
                    href="#/"
                    className="text-danger"
                    title="Close trades and stop selected strategies"
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      if (hasLoadingExecutions || checkedStrategies.length === 0) {
                        showInfoDialog(
                          dispatch,
                          <span className="text-info">
                            <FontAwesomeIcon icon={faInfoCircle} /> No Strategies Selected
                          </span>,
                          <>Select strategies first.</>
                        );
                        return;
                      }

                      showConfirmDialog(
                        dispatch,
                        <span className="text-info">
                          <FontAwesomeIcon icon={faQuestionCircle} /> Close trades and stop
                        </span>,
                        <>
                          {props.type === "trading" ? (
                            <>
                              Are you sure you want to close all open trades with <b>MARKET SELL</b> order and stop the
                              selected live trading strategies?
                            </>
                          ) : (
                            "Are you sure you want to close all open trades and stop all simulations?"
                          )}
                        </>,
                        () => {
                          checkedStrategies.forEach((id) => {
                            let strategy = strategies.find((el) => el.id === id);
                            let status =
                              props.type === "simulation"
                                ? state.simStatuses[strategy.id]
                                : state.liveStatuses[strategy.id];
                            if (status.status === "Running") {
                              stopTrading(strategy, props.type, dispatch, state.demo, status);
                            }
                            if (status.activeTrade) {
                              let now = new Date();
                              now.setSeconds(now.getSeconds() + 60);
                              status.editDate = new Date().getTime();
                              dispatch({
                                type: props.type === "simulation" ? "simStatus" : "liveStatus",
                                payload: {
                                  status: {
                                    ...status,
                                    manualSellAt: now.getTime(),
                                  },
                                },
                              });
                              manualSell(props.strategy, props.type, now.getTime(), state.demo);
                            }
                          });
                        }
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faBan} className="mr-2" />
                  </a>
                  <a
                    href="#/"
                    className="text-info"
                    title="Reset selected executions"
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      if (state.demo) {
                        return;
                      }
                      if (checkedStrategies.length === 0) {
                        showInfoDialog(
                          dispatch,
                          <span className="text-info">
                            <FontAwesomeIcon icon={faInfoCircle} /> No Strategies Selected
                          </span>,
                          <>Select strategies first.</>
                        );
                      } else {
                        showConfirmDialog(
                          dispatch,
                          <span className="text-info">
                            <FontAwesomeIcon icon={faQuestionCircle} /> Reset Selected
                          </span>,
                          <>
                            The reset will remove all trades and events.
                            <br />
                            {props.type === "trading" && (
                              <>
                                The open positions <b>will not be closed</b>.
                              </>
                            )}
                          </>,
                          () => {
                            checkedStrategies.forEach((id) => {
                              let strategy = strategies.find((el) => el.id === id);
                              let status =
                                props.type === "simulation"
                                  ? state.simStatuses[strategy.id]
                                  : state.liveStatuses[strategy.id];
                              let wasRunning = status.status === "Running";
                              status.editDate = new Date().getTime();
                              dispatch({
                                type: props.type === "simulation" ? "simStatus" : "liveStatus",
                                payload: {
                                  status: {
                                    ...status,
                                    status: "Loading",
                                    details: {},
                                  },
                                },
                              });

                              (async () => {
                                await removeTrading(strategy, status, props.type, dispatch, state.demo);
                                let statusNew = {
                                  id: strategy.id,
                                  nextTrade: "buy",
                                  creationDate: new Date().getTime(),
                                  editDate: new Date().getTime(),
                                  status: "Stopped",
                                  tradesCount: 0,
                                  userId: strategy.userId,
                                  details: {},
                                };
                                dispatch({
                                  type: props.type === "simulation" ? "simStatus" : "liveStatus",
                                  payload: { status: statusNew },
                                });
                                addTrading(strategy, statusNew, props.type, wasRunning);
                              })();
                            });
                          }
                        );
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faUndo} className="mr-2" />
                  </a>
                  <a
                    href="#/"
                    className="text-danger"
                    title="Remove selected"
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      if (checkedStrategies.length === 0) {
                        showInfoDialog(
                          dispatch,
                          <span className="text-info">
                            <FontAwesomeIcon icon={faInfoCircle} /> No Strategies Selected
                          </span>,
                          <>Select strategies first.</>
                        );
                      } else {
                        showConfirmDialog(
                          dispatch,
                          <span className="text-danger">
                            <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" /> Remove
                            {props.type === "trading" ? "Live trading" : "Simulations"}
                          </span>,
                          <>
                            Are you sure you want to remove the{" "}
                            {props.type === "trading" ? "live trading with" : "simulations of"} the selected strategies?
                          </>,
                          () => {
                            checkedStrategies.forEach((id) => {
                              let strategy = strategies.find((el) => el.id === id);
                              let status =
                                props.type === "simulation"
                                  ? state.simStatuses[strategy.id]
                                  : state.liveStatuses[strategy.id];
                              removeTrading(strategy, status, props.type, dispatch, state.demo);
                            });
                            setCheckedStrategies([]);
                          }
                        );
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
                  </a>
                </div>
              </div>
            )}
            <div className="d-flex flex-wrap flex-sm-nowrap flex-grow-1">
              <div className="text-nowrap mr-auto ml-2">
                <FontAwesomeIcon className="text-info mr-2" icon={faSearch} />
                <div className="d-inline-block small">
                  <input
                    type="text"
                    className="search-bar text-left w-100-px px-1"
                    value={filter}
                    placeholder="Search in group"
                    onChange={(e) => {
                      setFilter(e.target.value);
                      setCheckAllStrategies(false);
                      setCheckedStrategies([]);
                    }}
                  ></input>
                </div>
              </div>
              <div className="text-left ml-auto text-nowrap user-select-none">
                <span className="small mr-2">Show:</span>
                <div className="d-inline-block input-group-sm">
                  <input
                    type="number"
                    className="search-bar text-left small w-25-px"
                    value={executionsPerPage}
                    placeholder="All"
                    onChange={(e) => {
                      let value = Math.abs(Number.parseInt(e.target.value));
                      let exPerPage = !isNaN(value) && value > 0 ? value : "";
                      setExecutionsPerPage(exPerPage);
                      localStorage.setItem("executionsPerPage", exPerPage);
                      setCheckAllStrategies(false);
                      setCheckedStrategies([]);
                    }}
                  ></input>
                </div>
                <a
                  className="text-info small ml-2"
                  href="/#"
                  onClick={(e) => {
                    e.preventDefault();
                    document.activeElement.blur();
                    setTableSetupEdit({ ...tableSetup });
                    if (!$("#tradingTableSetup").is(":visible")) {
                      $("#tradingTableSetup").modal("toggle");
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faCog} />
                </a>
              </div>
            </div>
          </div>

          <div
            className={`table-responsive border border-dark rounded mb-2 ${
              props.panelHeight ? "" : "max-h-fullscreen"
            }`}
            style={props.panelHeight ? { maxHeight: Math.max(100, props.panelHeight - 174) } : {}}
          >
            <table className="table table-sm table-new-dark table-hover rounded small user-select-none">
              <thead className="thead-new-dark">
                <tr>
                  {(state.app || state.demo) && <th></th>}
                  {tableSetup.name && (
                    <th
                      className="text-left sortable"
                      onClick={() => {
                        let sort = tradingSort !== "name" ? "name" : "nameR";
                        setTradingSort(sort);
                        localStorage.setItem("tradingSort", sort);
                      }}
                    >
                      Strategy
                      {(tradingSort === "name" || tradingSort === "nameR") && (
                        <FontAwesomeIcon icon={faArrowsAltV} className="ml-1" />
                      )}
                    </th>
                  )}

                  {tableSetup.pair && (
                    <th
                      className="text-left text-nowrap sortable"
                      onClick={() => {
                        let sort = tradingSort !== "pair" ? "pair" : "pairR";
                        setTradingSort(sort);
                        localStorage.setItem("tradingSort", sort);
                      }}
                    >
                      Pair
                      {(tradingSort === "pair" || tradingSort === "pairR") && (
                        <FontAwesomeIcon icon={faArrowsAltV} className="ml-1" />
                      )}
                    </th>
                  )}
                  {tableSetup.size && <th className="text-left text-nowrap">Trade Size</th>}
                  {tableSetup.tradesNumber && (
                    <th
                      className="text-center text-nowrap sortable"
                      onClick={() => {
                        let sort = tradingSort !== "tradeCount" ? "tradeCount" : "tradeCountR";
                        setTradingSort(sort);
                        localStorage.setItem("tradingSort", sort);
                      }}
                    >
                      Trades Nb.
                      {(tradingSort === "tradeCount" || tradingSort === "tradeCountR") && (
                        <FontAwesomeIcon icon={faArrowsAltV} className="ml-1" />
                      )}
                    </th>
                  )}
                  {tableSetup.openTrade && (
                    <th
                      className="text-center text-nowrap sortable"
                      onClick={() => {
                        let sort = tradingSort !== "openTrade" ? "openTrade" : "openTradeR";
                        setTradingSort(sort);
                        localStorage.setItem("tradingSort", sort);
                      }}
                    >
                      Open Trade
                      {(tradingSort === "openTrade" || tradingSort === "openTradeR") && (
                        <FontAwesomeIcon icon={faArrowsAltV} className="ml-1" />
                      )}
                    </th>
                  )}
                  {tableSetup.resultP && (
                    <th
                      className="text-left text-nowrap sortable"
                      onClick={() => {
                        let sort = tradingSort !== "result" ? "result" : "resultR";
                        setTradingSort(sort);
                        localStorage.setItem("tradingSort", sort);
                      }}
                    >
                      Result %
                      {(tradingSort === "result" || tradingSort === "resultR") && (
                        <FontAwesomeIcon icon={faArrowsAltV} className="ml-1" />
                      )}
                    </th>
                  )}
                  {tableSetup.result && (
                    <th className="text-left text-nowrap">Result{props.resultInQuote ? "" : " $"}</th>
                  )}
                  {tableSetup.status && (
                    <th
                      className="text-left text-nowrap sortable"
                      onClick={() => {
                        let sort = tradingSort !== "status" ? "status" : "statusR";
                        setTradingSort(sort);
                        localStorage.setItem("tradingSort", sort);
                      }}
                    >
                      Status
                      {(tradingSort === "status" || tradingSort === "statusR") && (
                        <FontAwesomeIcon icon={faArrowsAltV} className="ml-1" />
                      )}
                    </th>
                  )}
                  {tableSetup.status && <th className="text-center px-0"></th>}
                  {tableSetup.actions && <th className="text-center "></th>}
                </tr>
              </thead>
              <tbody>
                {filteredStrategies.slice(startIndex, endIndex).map((strategy) => {
                  let trading = props.type === "simulation" ? strategy.sim : strategy.live;
                  let status =
                    props.type === "simulation" ? state.simStatuses[strategy.id] : state.liveStatuses[strategy.id];
                  if (!status || !trading) {
                    return <tr key={strategy.id}></tr>;
                  }
                  let tradeSize = "";
                  switch (trading.tradeSize.type) {
                    case "percent":
                      tradeSize = `${trading.tradeSize.value.toFixed(1)}% of ${strategy.pairDetails.quoteAsset}`;
                      break;
                    case "base":
                      tradeSize = `${trading.tradeSize.value} ${strategy.pairDetails.baseAsset}`;
                      break;
                    case "quoted":
                      tradeSize = `${trading.tradeSize.value} ${strategy.pairDetails.quoteAsset}`;
                      break;
                    default:
                  }

                  let isChecked = checkedStrategies.includes(strategy.id);

                  return (
                    <tr key={strategy.id} className={strategy.id === props.selectedStrategyId ? "selected" : ""}>
                      {(state.app || state.demo) && (
                        <td className="text-left pl-3">
                          <div className="custom-control custom-checkbox d-inline-block ">
                            <input
                              type="checkbox"
                              id={`${strategy.id}CheckTrading`}
                              className="custom-control-input"
                              checked={isChecked}
                              onChange={(e) => {
                                if (isChecked) {
                                  let newCheckedStrategies = checkedStrategies.filter((el) => el !== strategy.id);
                                  setCheckedStrategies(newCheckedStrategies);
                                  setCheckAllStrategies(false);
                                } else {
                                  checkedStrategies.push(strategy.id);
                                  if (
                                    checkedStrategies.length === filteredStrategies.slice(startIndex, endIndex).length
                                  ) {
                                    setCheckAllStrategies(true);
                                  }
                                  setCheckedStrategies([...checkedStrategies]);
                                }
                              }}
                            />
                            <label
                              type="text"
                              className="custom-control-label font-weight-light font-italic"
                              htmlFor={`${strategy.id}CheckTrading`}
                            ></label>
                          </div>
                        </td>
                      )}
                      {tableSetup.name && (
                        <td
                          className="text-left cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            document.activeElement.blur();
                            props.setSelectedStrategyId(strategy.id);
                          }}
                        >
                          {strategy.name}
                        </td>
                      )}
                      {tableSetup.pair && (
                        <td
                          className="text-left cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            document.activeElement.blur();
                            props.setSelectedStrategyId(strategy.id);
                          }}
                        >
                          {strategy.pair}
                        </td>
                      )}
                      {tableSetup.size && (
                        <td
                          className="text-left text-nowrap cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            document.activeElement.blur();
                            props.setSelectedStrategyId(strategy.id);
                          }}
                        >
                          {tradeSize}
                        </td>
                      )}
                      {tableSetup.tradesNumber && (
                        <td
                          className="text-center cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            document.activeElement.blur();
                            props.setSelectedStrategyId(strategy.id);
                          }}
                        >
                          {status.tradesCount}
                        </td>
                      )}
                      {tableSetup.openTrade && (
                        <td
                          className="text-center text-nowrap cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            document.activeElement.blur();
                            props.setSelectedStrategyId(strategy.id);
                          }}
                        >
                          {status.activeTrade && (
                            <>
                              <OpenTrade status={status} strategy={strategy} idPrefix={"table"} type={props.type} />
                              {(state.app || state.demo) && (
                                <>
                                  {status.manualSellAt && status.manualSellAt > new Date().getTime() ? (
                                    <div className="loading-white fade-in-fast ml-1" title="Loading..">
                                      <img src={loadingReverced} alt="" />
                                    </div>
                                  ) : (
                                    <a
                                      href="#/"
                                      className="text-danger ml-1"
                                      title={
                                        status.lastTrade && status.lastTrade.openStatus === "NEW"
                                          ? "Cancel Trade"
                                          : "Close Trade"
                                      }
                                      onClick={(e) => {
                                        e.preventDefault();
                                        document.activeElement.blur();

                                        if (state.manualTradeConfirm == 1) {
                                          showConfirmDialog(
                                            dispatch,
                                            <span className="text-info">
                                              <FontAwesomeIcon icon={faQuestionCircle} />{" "}
                                              {status.lastTrade.openStatus === "NEW"
                                                ? "Cancel Penging BUY"
                                                : `Manual Sell`}
                                            </span>,
                                            <div>
                                              Are you sure you want to{" "}
                                              {status.lastTrade.openStatus === "NEW"
                                                ? "cancel the pending BUY order?"
                                                : `place a MARKET SELL ORDER?`}
                                            </div>,
                                            () => {
                                              let now = new Date();
                                              now.setSeconds(now.getSeconds() + 60);
                                              status.editDate = new Date().getTime();
                                              dispatch({
                                                type: props.type === "simulation" ? "simStatus" : "liveStatus",
                                                payload: {
                                                  status: {
                                                    ...status,
                                                    manualSellAt: now.getTime(),
                                                  },
                                                },
                                              });
                                              manualSell(strategy, props.type, now.getTime(), state.demo);
                                            }
                                          );
                                        } else {
                                          let now = new Date();
                                          now.setSeconds(now.getSeconds() + 60);
                                          status.editDate = new Date().getTime();
                                          dispatch({
                                            type: props.type === "simulation" ? "simStatus" : "liveStatus",
                                            payload: {
                                              status: {
                                                ...status,
                                                manualSellAt: now.getTime(),
                                              },
                                            },
                                          });
                                          manualSell(strategy, props.type, now.getTime(), state.demo);
                                        }
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faBan} className="" />
                                    </a>
                                  )}
                                </>
                              )}
                            </>
                          )}

                          {!status.activeTrade &&
                            (state.app || state.demo) &&
                            (status.manualBuyAt && status.manualBuyAt > new Date().getTime() ? (
                              <div className="loading-white fade-in-fast" title="Loading..">
                                <img src={loadingReverced} alt="" />
                              </div>
                            ) : (
                              <a
                                href="#/"
                                className="text-green "
                                title="Manual BUY order"
                                onClick={(e) => {
                                  e.preventDefault();
                                  document.activeElement.blur();
                                  if (state.manualTradeConfirm == 1) {
                                    setResetManualBuy({});
                                    setStrategyToManualBuyTrade(strategy);
                                    if (!$("#manualBuyTradeConfirmTable").is(":visible")) {
                                      $("#manualBuyTradeConfirmTable").modal("toggle");
                                    }
                                  } else {
                                    let now = new Date();
                                    now.setSeconds(now.getSeconds() + 60);
                                    status.editDate = new Date().getTime();
                                    dispatch({
                                      type: props.type === "simulation" ? "simStatus" : "liveStatus",
                                      payload: {
                                        status: {
                                          ...status,
                                          manualBuyAt: now.getTime(),
                                        },
                                      },
                                    });
                                    manualBuy(strategy, props.type, "market", null, now.getTime(), state.demo);
                                  }
                                }}
                              >
                                <FontAwesomeIcon icon={faPlusCircle} />
                              </a>
                            ))}
                        </td>
                      )}
                      {tableSetup.resultP && (
                        <td
                          className={`text-left cursor-pointer ${
                            status.result > 0 ? "text-success" : status.result < 0 ? "text-danger" : ""
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            document.activeElement.blur();
                            props.setSelectedStrategyId(strategy.id);
                          }}
                        >
                          {status.result !== undefined && status.result !== null && status.result.toFixed(2) + "%"}
                        </td>
                      )}
                      {tableSetup.result && (
                        <UsdResult
                          resultInQuote={props.resultInQuote}
                          strategy={strategy}
                          status={status}
                          onClickTd={(e) => {
                            e.preventDefault();
                            document.activeElement.blur();
                            props.setSelectedStrategyId(strategy.id);
                          }}
                        />
                      )}
                      {tableSetup.status && (
                        <td
                          className={`text-left cursor-pointer ${
                            status.status === "Running"
                              ? "text-light-green"
                              : status.errorLevel === "error"
                              ? "text-danger"
                              : ""
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            document.activeElement.blur();
                            props.setSelectedStrategyId(strategy.id);
                          }}
                        >
                          {status.status === "Loading" ? (
                            <div className="loading-white fade-in-fast pl-3" title="Loading..">
                              <img src={loadingReverced} alt="" />
                            </div>
                          ) : (
                            <div className="d-flex justify-content-between">
                              <div className="mr-2">{status.status}</div>
                              {(state.app || state.demo) &&
                                !state.user.isAdmin &&
                                status.status !== "Loading" &&
                                state.exchangesInitialized && (
                                  <div>
                                    {status.status !== "Running" ? (
                                      <a
                                        href="#/"
                                        className="text-green pr-2 "
                                        title="Start"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          document.activeElement.blur();

                                          if (
                                            !state.exchanges[strategy.exchange] ||
                                            !state.exchanges[strategy.exchange].valid
                                          ) {
                                            if (props.type === "trading") {
                                              if (!$("#addApiKeys").is(":visible")) {
                                                $("#addApiKeys").modal("toggle");
                                                return;
                                              }
                                            }
                                          }
                                          if (!userAllowedToStartStrategies(state, dispatch, props.type)) {
                                            return;
                                          }
                                          if (strategy.external) {
                                            delete strategy.external;
                                            updateStrategy(strategy);
                                            dispatch({
                                              type: "strategy",
                                              payload: strategy,
                                            });
                                          }
                                          startTrading(strategy, status, props.type, dispatch, state.demo);
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faPlay} />
                                      </a>
                                    ) : (
                                      <a
                                        href="#/"
                                        className="text-warning pr-2 "
                                        title="Stop"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          document.activeElement.blur();
                                          stopTrading(strategy, props.type, dispatch, state.demo, status);
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faStop} />
                                      </a>
                                    )}
                                  </div>
                                )}
                              {!state.app && !state.demo && status.status === "Running" && !state.user.isAdmin && (
                                <a
                                  href="#/"
                                  className="text-warning pr-2 "
                                  title="Stop"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    document.activeElement.blur();
                                    showConfirmDialog(
                                      dispatch,
                                      <span className="text-info">
                                        <FontAwesomeIcon icon={faQuestionCircle} /> Remote stop
                                      </span>,
                                      <>
                                        Do you want to execute a remote stop?
                                        <br />
                                        The stop may take up to 1 minute to execute.
                                      </>,
                                      () => {
                                        status.status = "Loading";
                                        dispatch({
                                          type: props.type === "simulation" ? "simStatus" : "liveStatus",
                                          payload: {
                                            status: status,
                                          },
                                        });
                                        sendRemoteStrategyStop(state.user.token, strategy.id, props.type, dispatch);
                                      }
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon icon={faStop} />
                                </a>
                              )}
                            </div>
                          )}
                        </td>
                      )}
                      {tableSetup.status && (
                        <td
                          className="text-center text-nowrap cursor-pointer px-0"
                          style={{ minWidth: "20px" }}
                          onClick={(e) => {
                            e.preventDefault();
                            document.activeElement.blur();
                            props.setSelectedStrategyId(strategy.id);
                          }}
                        >
                          {status.status !== "Loading" && state.exchangesInitialized && status.error && (
                            <a
                              href="#/"
                              className={` ${
                                status.errorLevel === "error"
                                  ? "text-danger"
                                  : status.errorLevel === "warning"
                                  ? "text-warning"
                                  : status.errorLevel === "info"
                                  ? "text-info"
                                  : ""
                              }`}
                              title="Event"
                              onClick={(e) => {
                                e.preventDefault();
                                document.activeElement.blur();

                                showInfoDialog(
                                  dispatch,
                                  <span
                                    className={
                                      status.errorLevel === "error"
                                        ? "text-danger"
                                        : status.errorLevel === "warning"
                                        ? "text-warning"
                                        : status.errorLevel === "info"
                                        ? "text-info"
                                        : ""
                                    }
                                  >
                                    <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />
                                    Trading event
                                  </span>,
                                  <>
                                    <div>{status.error}</div>
                                  </>
                                );
                              }}
                            >
                              {status.error.indexOf("Connection to") !== -1 ? (
                                <FontAwesomeIcon icon={faLink} />
                              ) : status.error.indexOf("Trading freezed") !== -1 ? (
                                <FontAwesomeIcon icon={faSnowflake} />
                              ) : (
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                              )}
                            </a>
                          )}
                          {status && !state.user.isAdmin && strategy.external && (
                            <a
                              href="#/"
                              className="mx-2 text-info"
                              title="Event"
                              onClick={(e) => {
                                e.preventDefault();
                                document.activeElement.blur();

                                showInfoDialog(
                                  dispatch,
                                  <span className="text-info">
                                    <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />
                                    Strategy updated
                                  </span>,
                                  <>
                                    <div>
                                      The strategy was updated on another ECB app and was automatically stopped.
                                    </div>
                                  </>
                                );
                              }}
                            >
                              <FontAwesomeIcon icon={faExclamationTriangle} />
                            </a>
                          )}
                        </td>
                      )}
                      {tableSetup.actions && (
                        <td
                          className={`${
                            !state.app && !state.demo ? "text-left" : "text-center"
                          } text-nowrap cursor-pointer pr-3`}
                          onClick={(e) => {
                            e.preventDefault();
                            document.activeElement.blur();
                            props.setSelectedStrategyId(strategy.id);
                          }}
                        >
                          {!state.app && !state.demo && (
                            <a
                              href="#/"
                              className="text-info pr-2 "
                              title="Show Result"
                              onClick={(e) => {
                                e.preventDefault();
                                document.activeElement.blur();
                                setStrategyToShow(strategy);
                              }}
                            >
                              <FontAwesomeIcon icon={faChartLine} />
                            </a>
                          )}
                          {(state.app || state.demo) &&
                            !state.user.isAdmin &&
                            status.status !== "Loading" &&
                            state.exchangesInitialized && (
                              <>
                                <a
                                  href="#/"
                                  className="text-info pr-2 "
                                  title="Show Strategy"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    document.activeElement.blur();
                                    setStrategyToInspect(strategy);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faSearchPlus} />
                                </a>
                                <a
                                  href="#/"
                                  className="text-info pr-2 "
                                  title="Show Result"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    document.activeElement.blur();
                                    setStrategyToShow(strategy);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faChartLine} />
                                </a>
                                <a
                                  href="#/"
                                  className="text-info pr-2 "
                                  title="Edit"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    document.activeElement.blur();
                                    setStrategyToEdit(strategy);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faCog} />
                                </a>
                                <a
                                  href="#/"
                                  className="text-danger"
                                  title="Remove"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    document.activeElement.blur();
                                    showConfirmDialog(
                                      dispatch,
                                      <span className="text-danger">
                                        <FontAwesomeIcon icon={faQuestionCircle} /> Remove{" "}
                                        {props.type === "trading" ? "Live trading" : "Simulation"}
                                      </span>,
                                      <>
                                        <div>
                                          Are you sure you want to remove the{" "}
                                          {props.type === "trading" ? "Live trading" : "Simulation"} of strategy{" "}
                                          <b>{strategy.name}</b>?
                                        </div>
                                        {status.status === "Running" && (
                                          <div className="mt-3">
                                            <FontAwesomeIcon className="text-danger" icon={faExclamationTriangle} /> The{" "}
                                            {props.type === "trading" ? "Live trading" : "Simulation"} is running!
                                          </div>
                                        )}
                                        {status.activeTrade && props.type === "trading" && (
                                          <div className="mt-3">
                                            <FontAwesomeIcon className="text-danger" icon={faExclamationTriangle} />{" "}
                                            There is an opened trade that will NOT be closed!
                                          </div>
                                        )}
                                      </>,
                                      () => {
                                        removeTrading(strategy, status, props.type, dispatch, state.demo);
                                      }
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTimesCircle} />
                                </a>
                              </>
                            )}
                        </td>
                      )}
                    </tr>
                  );
                })}
                {/* <tr>
                  <td colSpan="15" className="h-60"></td>
                </tr> */}
              </tbody>
            </table>
          </div>
          {pages.length > 0 && (
            <div className="overflow-auto  mb-2">
              <nav aria-label="page navigation">
                <ul className="pagination">
                  <li className="page-item" key="prev">
                    <a
                      className="page-link small px-2 py-1"
                      aria-label="Previous"
                      href="#/"
                      onClick={(e) => {
                        e.preventDefault();
                        if (page > 0) {
                          setPage(page - 1);
                          setCheckAllStrategies(false);
                          setCheckedStrategies([]);
                        }
                      }}
                    >
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </a>
                  </li>
                  {pages.map((el) => {
                    return (
                      <li className={"page-item " + (page === el ? "active" : "")} key={el}>
                        <a
                          className="page-link small px-2 py-1"
                          href="#/"
                          onClick={(e) => {
                            e.preventDefault();
                            setPage(el);
                            setCheckAllStrategies(false);
                            setCheckedStrategies([]);
                          }}
                        >
                          {el + 1}
                        </a>
                      </li>
                    );
                  })}

                  <li className="page-item" key="next">
                    <a
                      className="page-link small px-2 py-1"
                      href="#/"
                      aria-label="Next"
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          page <
                          Math.floor(
                            filteredStrategies.length /
                              (executionsPerPage ? executionsPerPage : filteredStrategies.length)
                          )
                        ) {
                          setPage(page + 1);
                          setCheckAllStrategies(false);
                          setCheckedStrategies([]);
                        }
                      }}
                    >
                      <span aria-hidden="true">&raquo;</span>
                      <span className="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          )}

          {!state.app && !state.demo && state.executionsLastUpdate && (
            <div className="text-center small text-info mb-4">
              Last updated on {formatDateTime(state.executionsLastUpdate)}
              <a
                href="#/"
                className="text-info ml-2"
                title="Update Now"
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  updateExecutionStatus(
                    state.user.token,
                    state.user.id,
                    dispatch,
                    false,
                    state.user.isAdmin,
                    state.adminGetUser,
                    state.trafic === "saver"
                  );
                }}
              >
                <FontAwesomeIcon icon={faSync} />
              </a>
            </div>
          )}
        </>
      ) : state.app || state.demo ? (
        <div className="mt-3 text-secondary">
          <h2 className="h4">No running strategies</h2>
          <span className="text-info font-weight-bold">Info:</span> Select a strategy in the table above. On the right
          panel go to <span className="text-info">{props.type === "trading" ? "Trade Live" : "Simulation"}</span> tab.
          Setup the execution and hit the start button.
        </div>
      ) : !state.executionsLastUpdate ? (
        <div className="text-center">
          <div className="loading-img fade-in-fast cursor-help mt-3 mb-5" title="Loading..">
            <img src={loadingReverced} alt="" />
          </div>
        </div>
      ) : (
        <h2 className="h4 text-secondary mt-3">No running strategies</h2>
      )}
      {strategyToShow && (
        <ExecutionResults
          strategy={strategyToShow}
          smallWidthDevice={props.smallWidthDevice}
          type={props.type}
          closeFn={() => {
            setStrategyToShow(null);
          }}
        />
      )}
      {strategyToInspect && (
        <InspectStrategyPopup
          setStrategyToInspect={(strategy) => {
            if (componentIsMounted.current) {
              setStrategyToInspect(strategy);
            }
          }}
          strategy={strategyToInspect}
        />
      )}

      {strategyToEdit && (
        <TradingParamsModal
          strategy={strategyToEdit}
          setStrategy={setStrategyToEdit}
          type={props.type}
          setExchange={props.setExchange}
        />
      )}
      <ModalConfirm
        id="tradingTableSetup"
        header={
          <div className="text-info">
            <FontAwesomeIcon icon={faCog} /> Setup
          </div>
        }
        okText="SAVE"
        content={
          <div>
            <div className="pb-1 pt-1 text-nowrap">
              <div className="min-w-170 d-inline-block">Confirm manual trade:</div>
              <RadioButtons
                options={[
                  {
                    optionClasses: "text-dark",
                    checkedColor: "text-info",
                    uncheckedColor: "text-white border rounded-circle border-info",
                    // eslint-disable-next-line eqeqeq
                    checked: manualTradeConfirm == 1,
                    check: () => setManualTradeConfirm(1),
                    text: "yes",
                  },
                  {
                    optionClasses: "text-dark",
                    checkedColor: "text-info",
                    uncheckedColor: "text-white border rounded-circle border-info",
                    // eslint-disable-next-line eqeqeq
                    checked: manualTradeConfirm == 0,
                    check: () => setManualTradeConfirm(0),
                    text: "no",
                  },
                ]}
              />
            </div>
            <div className="pb-1 pt-1 text-nowrap">
              <div className="min-w-170 d-inline-block">Play sound on trade:</div>
              <RadioButtons
                options={[
                  {
                    optionClasses: "text-dark",
                    checkedColor: "text-info",
                    uncheckedColor: "text-white border rounded-circle border-info",
                    // eslint-disable-next-line eqeqeq
                    checked: tradeInfoSound == 1,
                    check: () => setTradeInfoSound(1),
                    text: "yes",
                  },
                  {
                    optionClasses: "text-dark",
                    checkedColor: "text-info",
                    uncheckedColor: "text-white border rounded-circle border-info",
                    // eslint-disable-next-line eqeqeq
                    checked: tradeInfoSound == 0,
                    check: () => setTradeInfoSound(0),
                    text: "no",
                  },
                ]}
              />
            </div>
            Table Columns:
            <div className="d-flex flex-column mx-3 pt-1">
              {tableColumns.map((column) => (
                <div className="custom-control custom-checkbox w-100 text-truncate" key={column.id}>
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`trTable${column.id}`}
                    checked={tableSetupEdit[column.id]}
                    onChange={(e) => {
                      tableSetupEdit[column.id] = !tableSetup[column.id];
                      setTableSetupEdit({ ...tableSetupEdit });
                    }}
                  />
                  <label type="text" className="custom-control-label" htmlFor={`trTable${column.id}`}>
                    {column.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        }
        func={() => {
          dispatch({
            type: "tradeInfoSound",
            payload: tradeInfoSound,
          });
          dispatch({
            type: "manualTradeConfirm",
            payload: manualTradeConfirm,
          });
          setTableSetup({ ...tableSetupEdit });
          localStorage.setItem("botTradingTableSetup", JSON.stringify(tableSetupEdit));
        }}
        closeFn={() => {}}
      />
    </>
  );
};

const ExecutionsCharts = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const componentIsMounted = useRef(true);
  const [strategies, setStrategies] = useState([]);
  const [hasLoadingExecutions, setHasLoadingExecutions] = useState(false);
  const [strategyToManualBuyTrade, setStrategyToManualBuyTrade] = useState(null);
  const [resetManualBuy, setResetManualBuy] = useState({});
  const [chartsPerPage] = useState(8);
  const [width, setWidth] = useState("25%");
  const [widthIndex, setWidthIndex] = useState(getFromLocalStorage("tradingChartWidth", 1));
  const [height, setHeight] = useState(200);
  const [heightIndex, setHeightIndex] = useState(getFromLocalStorage("tradingChartHeight", 2));
  const [tradeCharts, setTradeCharts] = useState([]);
  const [chartPage, setChartPage] = useState(0);
  const [chartPages, setChartPages] = useState([]);
  const [chartStartIndex, setChartStartIndex] = useState(0);
  const [chartEndIndex, setChartEndIndex] = useState(0);
  const chartRefreshIndex = useRef(0);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    switch (+widthIndex) {
      case 1:
        setWidth("25%");
        break;
      case 2:
        setWidth("33%");
        break;
      case 3:
        setWidth("50%");
        break;
      case 4:
        setWidth("100%");
        break;
      default:
        setWidth("25%");
    }
  }, [widthIndex]);
  useEffect(() => {
    if (props.panelHeight) {
      switch (+heightIndex) {
        case 1:
          setHeight(Math.max(200, props.panelHeight / 4 - 12));
          break;
        case 2:
          setHeight(Math.max(200, props.panelHeight / 4 - 12));
          break;
        case 3:
          setHeight(Math.max(200, props.panelHeight / 3 - 16));
          break;
        case 4:
          setHeight(Math.max(200, props.panelHeight / 2 - 25));
          break;
        case 5:
          setHeight(Math.max(200, props.panelHeight / 1 - 50));
          break;
        default:
          setHeight(Math.max(200, props.panelHeight / 3 - 20));
      }
    } else {
      switch (+heightIndex) {
        case 1:
          setHeight(200);
          break;
        case 2:
          setHeight(330);
          break;
        case 3:
          setHeight(450);
          break;
        case 4:
          setHeight(550);
          break;
        case 5:
          setHeight(700);
          break;
        default:
          setHeight(330);
      }
    }
  }, [heightIndex, props.panelHeight]);

  useEffect(() => {
    if (props.activeGroup) {
      let hasLoading = false;
      let statusesToUse = props.type === "simulation" ? state.simStatuses : state.liveStatuses;
      let newStrategies = state.strategies.filter((strategy) => {
        let include = statusesToUse[strategy.id] && props.activeGroup.strategies.includes(strategy.id);
        if (include && !hasLoading) {
          hasLoading = statusesToUse[strategy.id].status === "Loading";
        }
        return include;
      });
      setStrategies(newStrategies);
      setHasLoadingExecutions(hasLoading);
    }
  }, [props.activeGroup, state.strategies, props.type, state.simStatuses, state.liveStatuses]);

  useEffect(() => {
    if (!hasLoadingExecutions) {
      let newTradeCharts = [];
      strategies.forEach((strategy) => {
        let status = props.type === "simulation" ? state.simStatuses[strategy.id] : state.liveStatuses[strategy.id];
        if (!status) {
          return;
        }
        let chart = tradeCharts.find((el) => el.id === strategy.id);
        if (!chart) {
          chart = {
            id: strategy.id,
            strategy: strategy,
            trades: [],
            tradesCount: 0,
            lastTradeCloseStatus: null,
          };
        }
        chart.status = status;

        chart.timeframes = getTimeframes(strategy).map((el) => getInterval(el));
        if (!chart.timeframes.includes(chart.timeframe)) {
          chart.timeframe = chart.timeframes[0];
        }
        newTradeCharts.push(chart);
      });
      newTradeCharts.sort((a, b) => a.strategy.name.localeCompare(a.strategy.name));
      chartRefreshIndex.current++;
      if (chartRefreshIndex.current > 1000) {
        chartRefreshIndex.current = 0;
      }
      let tmpValue = chartRefreshIndex.current;
      setTradeCharts(newTradeCharts);
      (async () => {
        for (let chart of newTradeCharts) {
          if (
            chart.tradesCount !== chart.status.tradesCount ||
            (chart.status.lastTrade && chart.lastTradeCloseStatus !== chart.status.lastTrade.closeStatus)
          ) {
            let trades =
              props.type === "simulation"
                ? await getEcbDatabase().getSimStrategyTrades(chart.strategy.id)
                : await getEcbDatabase().getLiveStrategyTrades(chart.strategy.id);
            if (!componentIsMounted.current || tmpValue !== chartRefreshIndex.current) {
              return;
            }
            chart.lastTradeCloseStatus = chart.status.lastTrade ? chart.status.lastTrade.closeStatus : null;
            chart.tradesCount = chart.status.tradesCount;
            chart.trades = trades;
          }
        }
        if (!componentIsMounted.current || tmpValue !== chartRefreshIndex.current) {
          return;
        }
        setTradeCharts([...newTradeCharts]);
      })();

      let pages = [];
      for (let i = 0; i < newTradeCharts.length / (chartsPerPage ? chartsPerPage : newTradeCharts.length); i++) {
        pages.push(i);
      }
      setChartPages(pages);
      setChartStartIndex((chartsPerPage ? chartsPerPage : 0) * chartPage);
      setChartEndIndex(
        (chartsPerPage ? chartsPerPage : 0) * chartPage + (chartsPerPage ? chartsPerPage : newTradeCharts.length)
      );

      if (chartPage > newTradeCharts.length - 1) {
        setChartPage(Math.max(pages.length - 1, 0));
      }
    }
  }, [props.type, chartPage, strategies, hasLoadingExecutions, state.simStatuses, state.liveStatuses]);

  return (
    <>
      {strategies.length > 0 ? (
        <>
          <ManualBuyOrder
            id="manualBuyTradeConfirmCharts"
            strategy={strategyToManualBuyTrade}
            reset={resetManualBuy}
            type={props.type}
          />

          {strategies.length > 0 && strategies.length !== tradeCharts.length ? (
            <div className="text-center mt-5">
              <div className="loading-white fade-in-fast" title="Loading..">
                <img src={loadingReverced} alt="" />
              </div>
            </div>
          ) : (
            <div>
              <div
                className={`overflow-auto ${props.panelHeight ? "" : "max-h-fullscreen"}`}
                style={props.panelHeight ? { maxHeight: Math.max(100, props.panelHeight - 145) } : {}}
              >
                <div className="text-right small mb-4">
                  Width:
                  <div className="d-inline-block w-80 mx-3">
                    <input
                      type="range"
                      min="1"
                      max="4"
                      value={widthIndex}
                      className="slider"
                      onChange={(e) => {
                        setWidthIndex(e.target.value);
                        localStorage.setItem("tradingChartWidth", e.target.value);
                      }}
                    ></input>
                  </div>
                  Height:
                  <div className="d-inline-block w-80 mx-3">
                    <input
                      type="range"
                      min="1"
                      max="5"
                      value={heightIndex}
                      className="slider"
                      onChange={(e) => {
                        setHeightIndex(e.target.value);
                        localStorage.setItem("tradingChartHeight", e.target.value);
                      }}
                    ></input>
                  </div>
                </div>

                <div className="d-flex flex-wrap justify-content-start small">
                  {tradeCharts.slice(chartStartIndex, chartEndIndex).map((chart) => {
                    return (
                      <div key={chart.id} style={{ width: width, height: height }}>
                        <div className="mx-3 mb-5">
                          <div className="text-truncate text-info pl-4">
                            <a
                              href="#/"
                              className="text-info"
                              onClick={(e) => {
                                e.preventDefault();
                                document.activeElement.blur();
                                props.setSelectedStrategyId(chart.strategy.id);
                              }}
                            >
                              {chart.strategy.name}
                            </a>
                          </div>
                          <div className="d-flex text-nowrap py-1 pl-4">
                            {chart.status.activeTrade ? (
                              <div className="d-flex text-nowrap mr-auto">
                                <div className="mr-2">
                                  <OpenTrade
                                    strategy={chart.strategy}
                                    status={chart.status}
                                    idPrefix={`chart${chart.strategy.id}`}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="mr-auto">
                                {chart.status.manualBuyAt && chart.status.manualBuyAt > new Date().getTime() ? (
                                  <div className="loading-white fade-in-fast" title="Loading..">
                                    <img src={loadingReverced} alt="" />
                                  </div>
                                ) : (
                                  <a
                                    href="#/"
                                    className="text-green pr-2"
                                    title="Manual BUY order"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      document.activeElement.blur();
                                      setResetManualBuy({});
                                      setStrategyToManualBuyTrade(chart.strategy);
                                      if (!$("#manualBuyTradeConfirmCharts").is(":visible")) {
                                        $("#manualBuyTradeConfirmCharts").modal("toggle");
                                      }
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                  </a>
                                )}
                              </div>
                            )}

                            {(state.app || state.demo) && chart.status.status !== "Loading" && (
                              <>
                                {chart.status.status !== "Running" ? (
                                  <a
                                    href="#/"
                                    className="text-green mr-2"
                                    title="Start"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      document.activeElement.blur();
                                      if (
                                        !state.exchanges[chart.strategy.exchange] ||
                                        !state.exchanges[chart.strategy.exchange].valid
                                      ) {
                                        if (props.type === "trading") {
                                          if (!$("#addApiKeys").is(":visible")) {
                                            $("#addApiKeys").modal("toggle");
                                          }
                                          return;
                                        }
                                      }

                                      if (!userAllowedToStartStrategies(state, dispatch, props.type)) {
                                        return;
                                      }
                                      if (chart.strategy.external) {
                                        delete chart.strategy.external;
                                        updateStrategy(chart.strategy);
                                        dispatch({
                                          type: "strategy",
                                          payload: chart.strategy,
                                        });
                                      }
                                      startTrading(chart.strategy, chart.status, props.type, dispatch, state.demo);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faPlay} />
                                  </a>
                                ) : (
                                  <a
                                    href="#/"
                                    className="text-warning mr-2"
                                    title="Stop"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      document.activeElement.blur();
                                      stopTrading(chart.strategy, props.type, dispatch, state.demo, chart.status);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faStop} />
                                  </a>
                                )}
                              </>
                            )}

                            <div className="text-right">
                              {chart.timeframes.map((time) => {
                                return (
                                  <a
                                    key={time}
                                    href="/#"
                                    className={
                                      chart.timeframe === time ? "bg-info text-light rounded px-1" : "text-info px-1"
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      document.activeElement.blur();
                                      chart.timeframe = time;
                                      setTradeCharts([...tradeCharts]);
                                    }}
                                  >
                                    {time}
                                  </a>
                                );
                              })}
                            </div>
                          </div>
                          <LiveChartWithIndicators
                            strategy={chart.strategy}
                            pair={chart.strategy.pair}
                            exchange={chart.strategy.exchange}
                            timeframe={chart.timeframe}
                            id={chart.id}
                            showZoomSlider={false}
                            datapointLimit={70}
                            height={height - 90}
                            trades={chart.trades}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {chartPages.length > 0 && (
                <div className="overflow-auto my-2">
                  <nav aria-label="page navigation">
                    <ul className="pagination">
                      <li className="page-item" key="prev">
                        <a
                          className="page-link small px-2 py-1"
                          aria-label="Previous"
                          href="#/"
                          onClick={(e) => {
                            e.preventDefault();
                            if (chartPage > 0) {
                              setChartPage(chartPage - 1);
                            }
                          }}
                        >
                          <span aria-hidden="true">&laquo;</span>
                          <span className="sr-only">Previous</span>
                        </a>
                      </li>
                      {chartPages.map((el) => {
                        return (
                          <li className={"page-item " + (chartPage === el ? "active" : "")} key={el}>
                            <a
                              className="page-link small px-2 py-1"
                              href="#/"
                              onClick={(e) => {
                                e.preventDefault();
                                setChartPage(el);
                              }}
                            >
                              {el + 1}
                            </a>
                          </li>
                        );
                      })}

                      <li className="page-item" key="next">
                        <a
                          className="page-link small px-2 py-1"
                          href="#/"
                          aria-label="Next"
                          onClick={(e) => {
                            e.preventDefault();
                            if (
                              chartPage <
                              Math.floor(tradeCharts.length / (chartsPerPage ? chartsPerPage : tradeCharts.length))
                            ) {
                              setChartPage(chartPage + 1);
                            }
                          }}
                        >
                          <span aria-hidden="true">&raquo;</span>
                          <span className="sr-only">Next</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              )}
            </div>
          )}

          {!state.app && !state.demo && state.executionsLastUpdate && (
            <div className="text-center small text-info mb-4">
              Last updated on {formatDateTime(state.executionsLastUpdate)}
              <a
                href="#/"
                className="text-info ml-2"
                title="Update Now"
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  updateExecutionStatus(
                    state.user.token,
                    state.user.id,
                    dispatch,
                    false,
                    state.user.isAdmin,
                    state.adminGetUser,
                    state.trafic === "saver"
                  );
                }}
              >
                <FontAwesomeIcon icon={faSync} />
              </a>
            </div>
          )}
        </>
      ) : state.app || state.demo ? (
        <div className="mt-3 text-secondary">
          <h2 className="h4">No running strategies</h2>
        </div>
      ) : !state.executionsLastUpdate ? (
        <div className="text-center">
          <div className="loading-img fade-in-fast cursor-help mt-3 mb-5" title="Loading..">
            <img src={loadingReverced} alt="" />
          </div>
        </div>
      ) : (
        <h2 className="h4 text-secondary mt-3">No running strategies</h2>
      )}
    </>
  );
};

const EventsTable = (props) => {
  const [page, setPage] = useState(0);
  const itemsOnPage = 10;

  useEffect(() => {
    setPage(0);
  }, [props.id]);

  let pages = [];
  for (let i = 0; i < props.events.length / itemsOnPage; i++) {
    pages.push(i);
  }

  return (
    <div className="mx-1 pt-2">
      <div className="text-center h6">Events:</div>
      <div className="table-responsive border border-dark rounded mb-2 ">
        <table className="table table-new-dark table-sm small rounded">
          <thead className="thead-new-dark">
            <tr>
              <th className="text-left pl-2 text-nowrap">Date</th>
              <th className="text-left text-nowrap">Type</th>
              <th className="text-left">Content</th>
            </tr>
          </thead>
          <tbody>
            {props.events
              .filter((el) => el !== undefined && el != null)
              .map((event) => event)
              .sort((a, b) => {
                if (!a || !a.creationDate) {
                  return -1;
                }
                if (!b || !b.creationDate) {
                  return 1;
                }
                return b.creationDate - a.creationDate;
              })
              .filter((el, index) => index >= page * itemsOnPage && index < page * itemsOnPage + itemsOnPage)
              .map((event, index) => {
                return (
                  <tr
                    key={index}
                    className={`text-left ${
                      event.type === "ERROR" ? "text-danger" : event.type === "WARNING" ? "text-warning" : "text-white"
                    }`}
                  >
                    <td className="text-left text-nowrap pr-2">{formatDateTime(event.creationDate)}</td>
                    <td className="text-left text-nowrap pr-2">{event.type}</td>
                    <td className="text-left w-100">{event.content}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="overflow-auto  mb-2">
        <nav aria-label="page navigation">
          <ul className="pagination">
            <li className="page-item" key="prev">
              <a
                className="page-link small px-2 py-1"
                aria-label="Previous"
                href="#/"
                onClick={(e) => {
                  e.preventDefault();
                  if (page > 0) {
                    setPage(page - 1);
                  }
                }}
              >
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Previous</span>
              </a>
            </li>
            {pages.map((el) => {
              return (
                <li className={"page-item " + (page === el ? "active" : "")} key={el}>
                  <a
                    className="page-link small px-2 py-1"
                    href="#/"
                    onClick={(e) => {
                      e.preventDefault();
                      setPage(el);
                    }}
                  >
                    {el + 1}
                  </a>
                </li>
              );
            })}

            <li className="page-item" key="next">
              <a
                className="page-link small px-2 py-1"
                href="#/"
                aria-label="Next"
                onClick={(e) => {
                  e.preventDefault();
                  if (page < Math.floor(props.events.length / itemsOnPage)) {
                    setPage(page + 1);
                  }
                }}
              >
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

const ExecutionResults = (props, type) => {
  const { state } = useContext(globalContext);
  const [status, setStatus] = useState(null);
  const [id, setId] = useState(props.id ? props.id : "executionBugResultModal");

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.strategy) {
      let status = props.bugReport
        ? props.status
        : props.type === "simulation"
        ? state.simStatuses[props.strategy.id]
        : state.liveStatuses[props.strategy.id];
      if (status) {
        setStatus(status);
      } else {
        setStatus(null);
      }
    } else {
      setStatus(null);
    }
  }, [props.strategy]);

  useEffect(() => {
    if (status) {
      if (!$(`#${id}`).is(":visible")) {
        $(`#${id}`).modal("toggle");
      }
    } else {
      if ($(`#${id}`).is(":visible")) {
        $(`#${id}`).modal("toggle");
      }
    }
  }, [status]);

  return (
    <Modal
      id={id}
      okText="Close"
      closeFn={() => {
        props.closeFn();
      }}
      header={
        status && props.type === "trading" ? (
          <>
            <FontAwesomeIcon icon={faChartLine} /> Trading Results
            <div>{props.strategy ? props.strategy.name : ""}</div>
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faRandom} /> Simulation Results
            <div>{props.strategy ? props.strategy.name : ""}</div>
          </>
        )
      }
      content={
        status && (
          <div className="px-3">
            <TradingResult
              strategy={props.strategy}
              type={props.type}
              setStrategyActiveSubTabResult={() => {}}
              topPanelHeight={700}
              inspectStrategy
              status={props.status}
              details={props.details}
              trades={props.trades}
              events={props.events}
              bugReport={props.bugReport}
              smallWidthDevice={props.smallWidthDevice}
            />
          </div>
        )
      }
      large={true}
      dark={true}
    />
  );
};

const TradingResult = (props) => {
  let { state } = useContext(globalContext);
  const [tradeToShow, setTradeToShow] = useState(null);
  const [stats, setStats] = useState(null);
  const [status, setStatus] = useState(null);
  const [dateTo, setDateTo] = useState(new Date());
  const [timeframes, setTimeframes] = useState([]);
  const [selectedTimeframe, setSelectedTimeframe] = useState("");
  const [historicalData, setHistoricalData] = useState(null);
  const [chartHistoricalData, setChartHistoricalData] = useState([]);
  const [events, setEvents] = useState([]);
  const [marketReturnCalculated, setMarketReturn] = useState([]);
  const [cumulativeData, setCumulativeData] = useState(null);
  const strategyIdRef = useRef(null);
  const typeRef = useRef(null);
  const componentIsMounted = useRef(true);
  const [details, setDetails] = useState(null);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.strategy) {
      if (strategyIdRef.current !== props.strategy.id || typeRef.current !== props.type) {
        setStats(null);
        setChartHistoricalData([]);
        setHistoricalData(null);
        setMarketReturn(null);
        setCumulativeData(null);
        setEvents([]);
      }
      strategyIdRef.current = props.strategy.id;
      typeRef.current = props.type;
      setStatus(
        props.bugReport
          ? props.status
          : props.type === "simulation"
          ? state.simStatuses[props.strategy.id]
          : state.liveStatuses[props.strategy.id]
      );

      let timeframes = getTimeframes(props.strategy).map((tf) => getInterval(tf));
      setTimeframes(timeframes);
      setSelectedTimeframe(timeframes[0]);
    } else {
      setTimeframes([]);
      setSelectedTimeframe("");
      setChartHistoricalData([]);
      setStatus(null);
    }
  }, [props.strategy, state.simStatuses, state.liveStatuses, props.type]);

  useEffect(() => {
    let strategyIdRefTmp = strategyIdRef.current;
    let typeTmp = typeRef.current;
    if (status) {
      setDetails(status.details);
    } else {
      setDetails(null);
    }
    if (status) {
      (async () => {
        let trades = [];
        if (!state.app && !state.demo) {
          trades = props.bugReport
            ? props.trades
            : state.user.isAdmin
            ? await getUserTrades(
                state.user.token,
                props.type,
                state.user.isAdmin,
                state.adminGetUser,
                props.strategy.id
              )
            : await getStrategyTrades(state.user.token, props.type, props.strategy.id);

          if (state.user.isAdmin) {
            getStrategiesDetail(state.user.token, state.adminGetUser, props.type, props.strategy.id).then((details) => {
              console.log("Details", details);
            });
            console.log("Strategy", props.strategy);
            console.log("Status", status);
            console.log("Trades", trades);
          }
        } else {
          if (state.demo) {
            trades = status.trades;
          } else if (props.bugReport) {
            trades = props.trades;
          } else if (state.user.isAdmin) {
            trades = await getUserTrades(
              state.user.token,
              props.type,
              state.user.isAdmin,
              state.adminGetUser,
              props.strategy.id
            );
          } else {
            trades =
              props.type === "simulation"
                ? await getEcbDatabase().getSimStrategyTrades(props.strategy.id)
                : await getEcbDatabase().getLiveStrategyTrades(props.strategy.id);
          }
        }
        let events = props.bugReport
          ? props.events
          : state.user.isAdmin
          ? await getUserEvents(state.user.token, props.type, state.user.isAdmin, state.adminGetUser, props.strategy.id)
          : props.type === "simulation"
          ? await getEcbDatabase().getSimStrategyEvents(props.strategy.id)
          : await getEcbDatabase().getLiveStrategyEvents(props.strategy.id);
        if (!componentIsMounted.current || strategyIdRefTmp !== strategyIdRef.current || typeTmp !== typeRef.current) {
          return;
        }
        setEvents(events);
        if (!trades) {
          trades = [];
        }
        let stats = parseBacktestResult(trades);
        if (!stats) {
          return;
        }
        stats.params = {
          tradeSize: props.type === "simulation" ? props.strategy.sim.tradeSize : props.strategy.live.tradeSize,
        };
        let startDate = new Date(status.creationDate);
        let endDate = new Date();
        if (status.status !== "Running") {
          let lastTrade = trades[trades.length - 1];
          if (lastTrade) {
            endDate = lastTrade.cd ? new Date(lastTrade.cd) : new Date(lastTrade.od);
          }
        }
        setDateTo(endDate);
        attachTradesData(stats);
        setStats(stats);

        let startDateForChart = new Date(startDate.getTime());
        let endDateForChart = new Date(endDate.getTime());
        let timeframes = getTimeframes(props.strategy);
        switch (timeframes[0]) {
          case "1 minute":
            endDateForChart.setMinutes(endDate.getMinutes() + 10);
            startDateForChart.setMinutes(startDate.getMinutes() - 20);
            break;
          case "5 minutes":
            endDateForChart.setMinutes(endDate.getMinutes() + 50);
            startDateForChart.setMinutes(startDate.getMinutes() - 100);
            break;
          case "15 minutes":
            endDateForChart.setMinutes(endDate.getMinutes() + 150);
            startDateForChart.setMinutes(startDate.getMinutes() - 300);
            break;
          case "30 minutes":
            endDateForChart.setMinutes(endDate.getMinutes() + 300);
            startDateForChart.setMinutes(startDate.getMinutes() - 600);
            break;
          case "1 hour":
            endDateForChart.setHours(endDate.getHours() + 10);
            startDateForChart.setHours(startDate.getHours() - 20);
            break;
          case "4 hours":
            endDateForChart.setHours(endDate.getHours() + 40);
            startDateForChart.setHours(startDate.getHours() - 80);
            break;
          case "12 hours":
            endDateForChart.setHours(endDate.getHours() + 120);
            startDateForChart.setHours(startDate.getHours() - 240);
            break;
          case "1 day":
            endDateForChart.setHours(endDate.getHours() + 240);
            startDateForChart.setHours(startDate.getHours() - 580);
            break;
          case "1 week":
            endDateForChart.setHours(endDate.getHours() + 240 * 7);
            startDateForChart.setHours(startDate.getHours() - 580 * 7);
            break;
          case "1 month":
            endDateForChart.setHours(endDate.getHours() + 240 * 30);
            startDateForChart.setHours(startDate.getHours() - 580 * 30);
            break;
          default:
            endDateForChart.setHours(endDate.getHours() + 5);
            startDateForChart.setHours(startDate.getHours() - 5);
        }
        let historicalData = {};
        let datesDiff = (dateTo.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
        let use1DayForCumulativesChart = datesDiff > 30;
        if (props.contestMonth) {
          endDateForChart.setDate(+state.selectedContest.end);
          endDateForChart.setHours(23, 59, 59);
          historicalData = await getExchange(props.strategy.exchange).getHistoricalData(
            `showRes-${Math.random() * 10000000 + 10000000}`,
            props.strategy.pair,
            timeframes,
            startDateForChart,
            endDateForChart,
            null,
            false,
            props.contestMonth
          );
        } else if (state.app || state.demo) {
          if (!state.demo && use1DayForCumulativesChart) {
            getExchange(props.strategy.exchange)
              .getHistoricalData(
                `showRes-${Math.random() * 10000000 + 10000000}`,
                props.strategy.pair,
                ["1 day"],
                startDate,
                dateTo,
                null,
                false
              )
              .then((historicalData) => {
                if (
                  !componentIsMounted.current ||
                  strategyIdRefTmp !== strategyIdRef.current ||
                  typeTmp !== typeRef.current ||
                  !historicalData
                ) {
                  return;
                }
                let smallTfHistoricalData = historicalData["1 day"];
                if (!smallTfHistoricalData || smallTfHistoricalData.length === 0) {
                  return;
                }
                let cumulatives = { trades: trades };
                attachCumulativesData(cumulatives, smallTfHistoricalData, startDate);
                setCumulativeData(cumulatives);
              });
          }

          historicalData = await getExchange(props.strategy.exchange).getHistoricalData(
            `showRes-${Math.random() * 10000000 + 10000000}`,
            props.strategy.pair,
            timeframes,
            startDateForChart,
            endDateForChart,
            null,
            props.contestMonth
          );
        } else {
          let endDate = new Date();
          for (let tf of timeframes) {
            let startDate = getStartDateForSingleBinanceCall(endDate, tf);
            if (startDate < startDateForChart) {
              startDate = startDateForChart;
            }
            historicalData[tf] = await getExchange(props.strategy.exchange).getChartHistoricalData(
              `showRes-${Math.random() * 10000000 + 10000000}`,
              props.strategy.pair,
              tf,
              startDate.getTime(),
              endDate.getTime(),
              true
            );
          }
        }
        if (!componentIsMounted.current || strategyIdRefTmp !== strategyIdRef.current || typeTmp !== typeRef.current) {
          return;
        }
        if (!historicalData) {
          return;
        }

        let smallTfHistoricalData = historicalData[timeframes[0]];
        if (!smallTfHistoricalData || smallTfHistoricalData.length === 0) {
          return;
        }
        if (!state.app || !use1DayForCumulativesChart || state.demo || props.contestMonth) {
          let cumulatives = { trades: trades };
          attachCumulativesData(cumulatives, smallTfHistoricalData, startDate);
          setCumulativeData(cumulatives);
        }
        setChartHistoricalData(smallTfHistoricalData);
        setHistoricalData(historicalData);
        setMarketReturn(marketReturn(new Date(status.creationDate), smallTfHistoricalData));
      })();
    } else {
      setStats(null);
      setChartHistoricalData([]);
      setHistoricalData(null);
      setMarketReturn(null);
      setCumulativeData(null);
      setEvents([]);
    }
  }, [status]);

  useEffect(() => {
    if (status && historicalData) {
      let tf = getTimeframeFromInterval(selectedTimeframe);
      setChartHistoricalData(historicalData[tf]);
    }
  }, [status, selectedTimeframe, historicalData]);

  return status ? (
    stats ? (
      <div className="fade-in pr-2">
        <div className="position-relative text-center custom-hr mb-4">
          {formatDate2(new Date(status.creationDate))} - {formatDate2(dateTo)}
          {!props.smallWidthDevice && props.inspectStrategy && (
            <div className="position-absolute top-2-px right-0">
              <div className="cursor-help tooltip-parent">
                <div className="h6 text-info">
                  Strategy
                  <FontAwesomeIcon className="ml-2" icon={faSearchPlus} />
                </div>

                <div className="tooltip-on-hover right-0 top-3 border border-info rounded bg-new-dark min-w-hover-strategy p-3">
                  <div className="pb-2">
                    <InspectStrategy
                      labelsColor="text-light-green"
                      strategy={props.strategy}
                      componentIsMounted={componentIsMounted.current}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {status.tradesCount > 1 || (status.tradesCount === 1 && !status.activeTrade) ? (
          <ResultsSection
            labelsColor="text-info"
            dateFrom={new Date(status.creationDate)}
            dateTo={dateTo}
            marketReturn={marketReturnCalculated}
            tradeSize={props.type === "simulation" ? props.strategy.sim.tradeSize : props.strategy.live.tradeSize}
            stats={stats}
            cumulativeData={cumulativeData}
            commissionRate={
              props.type === "simulation" ? props.strategy.sim.commissionRate : props.strategy.live.commissionRate
            }
            exchange={props.strategy.exchange}
            pair={props.strategy.pair}
            pairDetails={props.strategy.pairDetails}
            setTradeToShow={setTradeToShow}
            chartId={`tradingChart${props.type}`}
          />
        ) : (
          <>
            <div className="text-center custom-hr pb-4 mb-4">No closed trades yet</div>
          </>
        )}

        <div className="custom-hr pb-4 mb-4">
          <div className="text-right mb-2 mr-1">
            {timeframes.map((time) => {
              return (
                <a
                  key={time}
                  href="/#"
                  className={
                    selectedTimeframe === time ? "small bg-info text-light rounded px-1" : "small text-info px-1"
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    document.activeElement.blur();
                    setSelectedTimeframe(time);
                  }}
                >
                  {time}
                </a>
              );
            })}
          </div>
          {chartHistoricalData ? (
            <StaticChartWithIndicators
              strategy={props.strategy}
              pair={props.strategy.pair}
              exchange={props.strategy.exchange}
              timeframe={selectedTimeframe}
              id={`tradingChart${props.type}`}
              showZoomSlider={true}
              height={Math.max(250, props.topPanelHeight - 200)}
              trades={stats.trades}
              historicalData={chartHistoricalData}
              markPointBig={true}
              tradeToShow={tradeToShow}
            />
          ) : (
            <div className="text-center">
              <div className="loading-img fade-in-fast cursor-help my-5" title="Loading..">
                <img src={loadingReverced} alt="" />
              </div>
            </div>
          )}
        </div>
        {stats.trades.length > 0 && (
          <div className="custom-hr pb-4 pt-2 mb-4">
            <TradesTable
              strategyId={props.strategy.id}
              type={props.type}
              setTradeToShow={setTradeToShow}
              trades={stats.trades}
              stats={stats}
              exchange={props.strategy.exchange}
              pair={props.strategy.pair}
              chartId={`tradingChart${props.type}`}
              pairDetails={props.strategy.pairDetails}
              statusDetails={details}
            />
          </div>
        )}
        {events && events.length > 0 && <EventsTable id={props.strategy.id} events={events} />}
      </div>
    ) : (
      <div className="text-center">
        {state.demo ? (
          <h5 className="text-secondary">
            The actual {props.type} is not performed in DEMO mode.
            <br />
            Create an account to run your strategies.
          </h5>
        ) : (
          <div className="loading-img fade-in-fast cursor-help my-5" title="Loading..">
            <img src={loadingReverced} alt="" />
          </div>
        )}
      </div>
    )
  ) : (
    <div className="text-center">
      <h5 className="text-secondary px-5">Trading results will be displayed here</h5>
      <a
        href="#/"
        className="text-info mt-5"
        title="Trading setup"
        onClick={(e) => {
          e.preventDefault();
          document.activeElement.blur();
          props.setStrategyActiveSubTabResult(false);
        }}
      >
        Setup up now
      </a>
    </div>
  );
};

export {
  ExecutionsTable,
  ExecutionsCharts,
  TradingSetup,
  TradesTable,
  TradingResult,
  ResultsSection,
  ExecutionResults,
  TradeDetails,
};
