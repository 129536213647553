import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faAt,
} from "@fortawesome/free-solid-svg-icons";

const ExpandableCard = (props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="card bg-transparent border-0 mb-4 user-select-none">
      <h4
        className={`h6 card-header d-flex cursor-pointer ${props.classHeader}`}
        onClick={(e) => {
          e.preventDefault();
          document.activeElement.blur();
          setExpanded(!expanded);
        }}
      >
        <div className="flex-grow-1">{props.label}</div>
        {expanded ? (
          <FontAwesomeIcon icon={faAngleUp} />
        ) : (
          <FontAwesomeIcon icon={faAngleDown} className="blink" />
        )}
      </h4>
      <div className={expanded ? "d-block fade-in" : "d-none"}>
        <div className="card-body p-2">
          <div className="card-text text-justify">{props.toExpand}</div>
        </div>
      </div>
    </div>
  );
};
const Questions = (props) => {
  return (
    <div className={props.classDiv}>
      <h2 className={props.classH2}>Frequently Asked Questions</h2>
      <div className="row row-cols-1 row-cols-md-2">
        <div className="col">
          <ExpandableCard
            classHeader="bg-white"
            label="1. What is EasyCryptoBot?"
            toExpand={
              <>
                EasyCryptoBot enables you to create your own, unique trading
                strategies. The bot will execute your strategies 24/7 without
                you having to do anything more.
              </>
            }
          />
          <ExpandableCard
            classHeader="bg-white"
            label="2. Why use crypto trading bots?"
            toExpand={
              <>
                Don't miss a trading opportunity! It's a nightmare keeping up
                with the market without automation trading. With EasyCryptoBot
                you are trading even while you are sleeping. Take back your free
                time and let the bot take care of the trading.
              </>
            }
          />
          <ExpandableCard
            classHeader="bg-white"
            label="3. Is EasyCryptoBot secure?"
            toExpand={
              <>
                EasyCryptoBot is completely safe and secure. It runs entirely on
                your computer. However, if you prefer cloud service, it is
                available on request (not included in the standard pricing
                plans).
              </>
            }
          />
          <ExpandableCard
            classHeader="bg-white"
            label="4. Which exchanges are supported?"
            toExpand={
              <>
                We offer Binance and Binance US. We are constantly adding new
                features and improving the app. So, if you don't find your
                favorite exchange send us a quick message.
              </>
            }
          />
          <ExpandableCard
            classHeader="bg-white"
            label="5. Do you plan on adding new Exchanges?"
            toExpand={
              <>
                Yes, we do! We value security and accuracy the most. That is why
                we do not use software from 3rd parties to handle connections to
                the crypto exchanges like most of the other crypto bots. We
                build our own connection model for each exchange that we add.
                Our plan is to include many more exchanges. If your exchange is
                not supported yet, please contact us and we will focus on it!
              </>
            }
          />
          <ExpandableCard
            classHeader="bg-white"
            label="6. Which cryptocurrencies can I trade?"
            toExpand={
              <>
                You can use every coin pair that is available in your preferred
                exchange.
              </>
            }
          />
          <ExpandableCard
            classHeader="bg-white"
            label="7. Are there examples of trading strategies?"
            toExpand={
              <>
                Yes, there are. Load a template, choose the desired coin pair
                and run an optimization. This will make the strategy fit better
                to the movements of the coin you chose.
              </>
            }
          />
          <ExpandableCard
            classHeader="bg-white"
            label="8. Do you plan on adding new Indicators?"
            toExpand={
              <>
                We are constantly adding new features, new instruments and new
                options to the existing ones. We never stop developing new
                functionalities. So, if you do not see your favorite indicator
                or have an idea, contact us to discuss it!
              </>
            }
          />
        </div>
        <div className="col">
          <ExpandableCard
            classHeader="bg-white"
            label="9. How to connect to my exchange account?"
            toExpand={
              <>
                First, create an API key in your exchange account. Then, enter
                the API key while starting a strategy to trade live or before
                that under the Account tab on the Trading page. You can also
                enter it on the Settings tab. For Binance exchange you can
                create API keys by following this{" "}
                <a
                  href="https://www.binance.com/en/support/articles/360002502072"
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  className="text-dark font-weight-bold"
                >
                  tutorial.
                </a>
              </>
            }
          />
          <ExpandableCard
            classHeader="bg-white"
            label="10. How to install EasyCryptoBot?"
            toExpand={
              <>
                Go to{" "}
                <a className="text-dark font-weight-bold" href="/download-app">
                  downloads
                </a>{" "}
                section and download the version for your operating system. When
                download is complete extract the content of the archive.
                Navigate to the folder where you extracted the archive and start
                EasyCryptoBot.exe
                <br/>If you have any difficulties, do not hesitate to contact us.
              </>
            }
          />
          <ExpandableCard
            classHeader="bg-white"
            label="11. Can I use the cloud instead of downloading the app?"
            toExpand={
              <>
                Cloud service is available on request. It is not included in the
                standard pricing plans. Contact us at:
                <br /> info
                <FontAwesomeIcon icon={faAt} />
                easycryptobot.com
              </>
            }
          />
          <ExpandableCard
            classHeader="bg-white"
            label="12. Can I use it in the browser or on my Smartphone?"
            toExpand={
              <>
                Most of our functionalities are available in the browser without
                downloading the App but with limitations. You can create
                watchlists, use the charts, create strategies, backtest and
                optimize, participate in contests. You can check how your trading is performing and stop
                it if needed. For maximum security you cannot make changes to
                strategies already in trading or start new ones.
              </>
            }
          />
          <ExpandableCard
            classHeader="bg-white"
            label="13. Is there a referal program?"
            toExpand={
              <>
                Yes there is! You can find your referral code under Settings /
                Subscription page. Give this code to your friends. They will
                receive one extra month when they subscribe for the first time.
                You will receive one month of subscription for every new user
                that uses the code and subscribes.
              </>
            }
          />
          <ExpandableCard
            classHeader="bg-white"
            label="14. What payment methods do you accept?"
            toExpand={
              <>
                We accept Paypal and Credit card payments. If you would like to
                make a payment with another method contact us!
              </>
            }
          />
          <ExpandableCard
            classHeader="bg-white"
            label="15. Where can I find instructions?"
            toExpand={
              <>
                You can find detailed information on how to use the bot on the{" "}
                <a className="text-dark font-weight-bold" href="/manual">
                  manual page
                </a>
              </>
            }
          />
          <ExpandableCard
            classHeader="bg-white"
            label="16. What level of support do you provide?"
            toExpand={
              <>
                We are here for you on every step of the way. You can contact us
                anytime with any questions or difficulties that you encounter.
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Questions;
