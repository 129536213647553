import React, { useState, useEffect } from "react";
import { Player } from "video-react";
import strategyVideo from "./assets/videos/strategy.mp4";
import backtestVideo from "./assets/videos/backtest.mp4";
import optimizeVideo from "./assets/videos/optimize.mp4";
import simulationVideo from "./assets/videos/simulation.mp4";
import tradingVideo from "./assets/videos/trading.mp4";

const Videos = (props) => {
  const [allVideos, setAllVideos] = useState([
    { title: "Create a strategy", video: strategyVideo },
    { title: "Run a backtest", video: backtestVideo },
    { title: "Optimize", video: optimizeVideo },
    { title: "Run a simulation", video: simulationVideo },
    { title: "Start trading", video: tradingVideo },
  ]);

  useEffect(() => {
    if (props.freeBacktest) {
      setAllVideos([
        { title: "Create a strategy", video: strategyVideo },
        { title: "Run a backtest", video: backtestVideo },
        { title: "Optimize", video: optimizeVideo },
      ]);
    }
  }, []);

  return (
    <>
      {allVideos.map((el, index) => {
        return (
          <div className={props.classesDiv} key={index}>
            <div className="card rounded bg-info text-white mb-3">
              <h3 className="h5 card-header py-1 py-lg-2 py-xl-3 my-0">{el.title}</h3>
              <Player
                className={props.classesVideo}
                width="100%"
                height="auto"
                playsInline
                light={true}
                volume
                muted
                src={el.video}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Videos;
