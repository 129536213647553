import React, { useContext, useState, useEffect, useCallback } from "react";
import { globalContext } from "./Context.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimesCircle, faEdit, faChartLine, faQuestionCircle, faCog } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import {
  checkInvalidIndicatorSettingsAll,
  deleteMultiChart,
  getFromLocalStorage,
  getIndicatorSetup,
  getTimeframeFromInterval,
  resetIndicatorSetup,
  saveMultiChart,
  showConfirmDialog,
} from "./utils.js";
import { Dropdown, DropdownWithSearch } from "./Dropdown";
import { ModalConfirm } from "./Modal";
import { LiveChartWithIndicators } from "./Charts.js";
import { indicators } from "./constants/consts.json";
import { IndicatorSettingsTable } from "./Rules.js";
import loading from "./assets/images/loading-reverced.gif";

const MiniCharts = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const [timeframes] = useState(["1m", "5m", "15m", "30m", "1h", "4h", "1d", "1w", "1M"]);
  const [smallChartPairSearch, setSmallChartPairSearch] = useState("");
  const [editPairSmallChartId, setEditPairSmallChartId] = useState();
  const [width, setWidth] = useState("25%");
  const [widthIndex, setWidthIndex] = useState(getFromLocalStorage("miniChartWidth", 3));
  const [height, setHeight] = useState(200);
  const [heightIndex, setHeightIndex] = useState(getFromLocalStorage("miniChartHeight", 3));
  const [indicatorsEditList, setIndicatorsEditList] = useState([]);
  const [chartToEditIndicatorsId, setChartToEditIndicatorsId] = useState(null);
  const [strategies, setStrategies] = useState({});
  const [noStratey] = useState({ buyRules: [], sellRules: [] });
  const [chartName, setChartName] = useState("");
  const [editChartList, setEditChartList] = useState(false);
  const [cloneChartList, setCloneChartList] = useState(false);

  let [currentMultiChart, setCurrentMultiChart] = useState(null);
  let [currentMultiChartId, setCurrentMultiChartId] = useState(
    getFromLocalStorage("currentMultiChartId", state.watchlists[0].id)
  );

  useEffect(() => {
    if (props.smallWidthDevice) {
      if (!localStorage.getItem("miniChartWidth")) {
        setWidthIndex(6);
      }
      if (!localStorage.getItem("miniChartHeight")) {
        setHeightIndex(2);
      }
    } else {
      if (!localStorage.getItem("miniChartWidth")) {
        setWidthIndex(3);
      }
      if (!localStorage.getItem("miniChartHeight")) {
        setHeightIndex(3);
      }
    }
  }, [props.smallWidthDevice]);

  useEffect(() => {
    switch (+widthIndex) {
      case 1:
        setWidth("16%");
        break;
      case 2:
        setWidth("20%");
        break;
      case 3:
        setWidth("25%");
        break;
      case 4:
        setWidth("33%");
        break;
      case 5:
        setWidth("50%");
        break;
      case 6:
        setWidth("100%");
        break;
      default:
        setWidth("25%");
    }
  }, [widthIndex]);

  useEffect(() => {
    if (props.smallWidthDevice) {
      switch (+heightIndex) {
        case 1:
          setHeight(200);
          break;
        case 2:
          setHeight(300);
          break;
        case 3:
          setHeight(400);
          break;
        case 4:
          setHeight(500);
          break;
        case 5:
          setHeight(600);
          break;
        default:
          setHeight(300);
      }
    } else {
      switch (+heightIndex) {
        case 1:
          setHeight(200);
          break;
        case 2:
          setHeight(330);
          break;
        case 3:
          setHeight(450);
          break;
        case 4:
          setHeight(550);
          break;
        case 5:
          setHeight(700);
          break;
        default:
          setHeight(330);
      
      }
    }
  }, [heightIndex, props.smallWidthDevice]);

  useEffect(() => {
    if (state.multiChartsInitialized) {
      let mch = state.multiCharts.find((ch) => ch.id === currentMultiChartId);
      if (mch) {
        setCurrentMultiChart({ ...mch });
      } else {
        setCurrentMultiChart(state.multiCharts[0]);
      }
    }
  }, [currentMultiChartId, state.multiCharts, state.multiChartsInitialized]);

  useEffect(() => {
    if (currentMultiChart) {
      let strategies = {};
      currentMultiChart.charts.forEach((chart) => {
        if (chart.indicators && chart.indicators.length > 0) {
          let [exchange, pair] = chart.pair.split("-");
          exchange = exchange === "BINANCE" ? "Binance" : "Binance US";
          let strategy = {
            pair: pair,
            exchange: exchange,
            buyRules: !chart.indicators
              ? []
              : chart.indicators.map((indicator) => ({
                  ...indicator,
                  mode: "close",
                  timeframe: getTimeframeFromInterval(chart.time),
                })),
            sellRules: [],
          };
          strategies[chart.id] = strategy;
        } else {
          strategies[chart.id] = noStratey;
        }
        setStrategies(strategies);
      });
    }
  }, [currentMultiChart]);

  const setCurrentMultiChartIdCallback = useCallback((newId) => {
    localStorage.setItem("currentMultiChartId", newId);
    setCurrentMultiChartId(newId);
  }, []);

  return (
    <div className="fade-in">
      {state.multiChartsInitialized ? (
        <>
          <div className="d-sm-flex">
            <div className="min-w-100 text-left">
              <Dropdown
                btnClasses="d-flex text-light hover-border-bottom info pt-0 pl-0 pb-2"
                arrow
                arrowClasses="mt-1"
                selectedClasses="text-truncate max-w-270 mr-1"
                menuClasses={`bg-new-dark border border-secondary rounded text-left small w-250 py-1 ${
                  props.smallWidthDevice ? "max-h-fullscreen" : ""
                }`}
                menuStyle={!props.smallWidthDevice ? { maxHeight: Math.max(100, height - 52) } : {}}
                menuItemsClasses="text-light hover-info px-3 py-2"
                selected={currentMultiChart ? currentMultiChart.name : state.multiCharts[0].name}
                setSelected={(currentMultiChart) => {
                  setCurrentMultiChartIdCallback(currentMultiChart.id);
                }}
                items={state.multiCharts}
                additionalOptions={[
                  {
                    label: (
                      <div className="d-flex">
                        Create Chart List
                        <FontAwesomeIcon className="ml-auto mt-1" icon={faPlus} />
                      </div>
                    ),
                    classes: "text-info px-3 pt-1 pb-2",
                    fn: () => {
                      if (!$("#setMultiChartName").is(":visible")) {
                        $("#setMultiChartName").modal("toggle");
                      }
                    },
                  },
                  {
                    label: "Change name",
                    classes: "text-info px-3 py-2",
                    fn: () => {
                      setChartName(currentMultiChart.name);
                      setEditChartList(true);
                      setCloneChartList(false);
                      if (!$("#setMultiChartName").is(":visible")) {
                        $("#setMultiChartName").modal("toggle");
                      }
                    },
                  },
                  {
                    label: "Duplicate list",
                    classes: "text-info px-3 py-2",
                    fn: () => {
                      setChartName(currentMultiChart.name);
                      setEditChartList(true);
                      setCloneChartList(true);
                      if (!$("#setMultiChartName").is(":visible")) {
                        $("#setMultiChartName").modal("toggle");
                      }
                    },
                  },
                  {
                    label: "Delete list",
                    classes: "text-info border-bottom border-secondary px-3 py-2 mb-2",
                    fn: () => {
                      deleteMultiChart(currentMultiChart.id, state.user.token, dispatch);
                      setCurrentMultiChartId(state.multiCharts[0].id);
                    },
                  },
                ]}
              />
            </div>

            <div className="text-center ml-auto small mb-4">
              Width:
              <div className="d-inline-block w-80 mx-3">
                <input
                  type="range"
                  min="1"
                  max="6"
                  value={widthIndex}
                  className="slider"
                  onChange={(e) => {
                    setWidthIndex(e.target.value);
                    localStorage.setItem("miniChartWidth", e.target.value);
                  }}
                ></input>
              </div>
              Height:
              <div className="d-inline-block w-80 mx-3">
                <input
                  type="range"
                  min="1"
                  max="5"
                  value={heightIndex}
                  className="slider"
                  onChange={(e) => {
                    setHeightIndex(e.target.value);
                    localStorage.setItem("miniChartHeight", e.target.value);
                  }}
                ></input>
              </div>
            </div>
          </div>

          <div className="d-flex flex-wrap justify-content-start small">
            {currentMultiChart &&
              currentMultiChart.charts.map((chart, index) => {
                let [exchange, pair] = chart.pair.split("-");
                exchange = exchange === "BINANCE" ? "Binance" : "Binance US";
                return (
                  <div key={index} style={{ width: width, height: height }}>
                    <div className="text-white mx-3 mb-5">
                      <div className="d-flex text-nowrap">
                        <div className="text-left">
                          <DropdownWithSearch
                            classesInput="search-bar text-left h-33 w-100-p px-2"
                            dropMenuClasses={`bg-new-dark text-left border border-secondary rounded w-220 left-0`}
                            dropMenuItemsClasses="text-light items-info"
                            menuStyle={height ? { maxHeight: Math.max(100, height - 40) } : {}}
                            placeholder="Select pair"
                            selected={chart.pair}
                            setSelected={(pair) => {
                              if (props.pairsAll.includes(pair)) {
                                chart.pair = pair;
                                saveMultiChart(currentMultiChart, state.user.token, dispatch);
                              }
                            }}
                            onBlur={() => {}}
                            showOnFocus={true}
                            closeOnEsc={true}
                            items={props.pairsAll}
                          />
                        </div>
                        <div className="ml-auto mt-1">
                          <a
                            href="/#"
                            className="text-light-green mx-1"
                            title="Indicators"
                            onClick={(e) => {
                              e.preventDefault();
                              document.activeElement.blur();
                              if (!chart.indicators) {
                                chart.indicators = [];
                              }
                              setChartToEditIndicatorsId(chart.id);
                              setIndicatorsEditList([...chart.indicators]);
                              if (!$("#setIndicatorsMinucharts").is(":visible")) {
                                $("#setIndicatorsMinucharts").modal("toggle");
                              }
                            }}
                          >
                            <FontAwesomeIcon className="" icon={faChartLine} />
                          </a>
                          <a
                            href="/#"
                            className="text-danger ml-1"
                            title="Remove"
                            onClick={(e) => {
                              e.preventDefault();
                              document.activeElement.blur();
                              showConfirmDialog(
                                dispatch,
                                <span className="text-info">
                                  <FontAwesomeIcon icon={faQuestionCircle} /> Remove Chart
                                </span>,
                                <>Are you sure you want to remove the {chart.pair} chart?</>,
                                () => {
                                  currentMultiChart.charts = currentMultiChart.charts.filter(
                                    (el) => el.id !== chart.id
                                  );
                                  saveMultiChart(currentMultiChart, state.user.token, dispatch);
                                  //setCurrentMultiChart({ ...currentMultiChart });
                                }
                              );
                            }}
                          >
                            <FontAwesomeIcon className="" icon={faTimesCircle} />
                          </a>
                        </div>
                      </div>
                      <div className="text-right overflow-hidden">
                        {timeframes.map((time) => {
                          return (
                            <a
                              key={time}
                              href="/#"
                              className={chart.time === time ? "bg-info text-light rounded px-1" : "text-info px-1"}
                              onClick={(e) => {
                                e.preventDefault();
                                document.activeElement.blur();
                                let toUpdate = currentMultiChart.charts.find((el) => el.id === chart.id);
                                toUpdate.time = time;
                                saveMultiChart(currentMultiChart, state.user.token, dispatch);
                              }}
                            >
                              {time}
                            </a>
                          );
                        })}
                      </div>
                      <LiveChartWithIndicators
                        strategy={strategies[chart.id]}
                        pair={pair}
                        exchange={exchange}
                        timeframe={chart.time}
                        id={chart.id}
                        showZoomSlider={false}
                        datapointLimit={70}
                        height={height - 90}
                      />
                    </div>
                  </div>
                );
              })}

            {currentMultiChart && currentMultiChart.charts.length < 12 && (
              <div
                className="d-flex flex-column align-items-center justify-content-center text-info hover-bg-info rounded min-w-270 mb-4"
                style={{ width: width, height: height }}
                onClick={(e) => {
                  e.preventDefault();
                  setEditPairSmallChartId("");
                  setSmallChartPairSearch("");
                  if (!$("#newSmallChart").is(":visible")) {
                    $("#newSmallChart").modal("toggle");
                  }
                }}
              >
                <FontAwesomeIcon className="h2" icon={faPlus} />
                <div>Add new chart</div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="text-center mx-auto">
          <div className="loading-img cursor-help my-5" title="Loading..">
            <img src={loading} alt="" />
          </div>
        </div>
      )}
      <ModalConfirm
        id="newSmallChart"
        dark
        header={editPairSmallChartId ? "Edit Small Chart" : "New Small Chart"}
        okText="OK"
        content={
          <div className="d-inline-block">
            <div className="d-inline-block mx-3">
              <span className="">Pair:</span>
            </div>
            <div className="d-inline-block">
              <DropdownWithSearch
                classesInput="search-bar text-left border-bottom-light hover-border-bottom-info focus-border-bottom-info text-left px-2"
                dropMenuClasses="bg-new-dark border border-info rounded max-h-180 w-220"
                dropMenuItemsClasses="text-light items-info"
                placeholder="Select pair"
                selected={smallChartPairSearch}
                setSelected={(pair) => {
                  setSmallChartPairSearch(pair);
                }}
                onBlur={() => {
                  if (!props.pairsAll.includes(smallChartPairSearch)) {
                  }
                }}
                showOnFocus={true}
                closeOnEsc={true}
                items={props.pairsAll}
              />
            </div>
          </div>
        }
        okDisabled={!props.pairsAll.includes(smallChartPairSearch)}
        func={() => {
          if (props.pairsAll.includes(smallChartPairSearch)) {
            if (editPairSmallChartId) {
              let toUpdate = currentMultiChart.charts.find((el) => el.id === editPairSmallChartId);
              toUpdate.pair = smallChartPairSearch;
              saveMultiChart(currentMultiChart, state.user.token, dispatch);
            } else {
              currentMultiChart.charts.push({
                id: new Date().getTime(),
                pair: smallChartPairSearch,
                time: "1h",
                indicators: [],
              });
              saveMultiChart(currentMultiChart, state.user.token, dispatch);
            }
          }
        }}
      />
      <ModalConfirm
        id="setIndicatorsMinucharts"
        dark
        header={
          <div className="text-info">
            <FontAwesomeIcon icon={faChartLine} /> Chart Indicators
          </div>
        }
        okText="OK"
        content={
          <div className="mx-3">
            <div className="mb-2">
              Indicators:
              <DropdownWithSearch
                classesInput="strategy text-left h-33 w-150 px-2 mt-1"
                dropMenuClasses="bg-new-dark border border-secondary rounded small w-220 max-h-180"
                dropMenuItemsClasses="text-light items-light-green"
                placeholder="Add +"
                selected={""}
                setSelected={(indicator) => {
                  let tmpRule = resetIndicatorSetup({}, indicator);
                  indicatorsEditList.forEach((el) => delete el.changeBg);
                  indicatorsEditList.splice(0, 0, { ...tmpRule, changeBg: true, id: new Date().getTime() });
                  setIndicatorsEditList([...indicatorsEditList]);
                }}
                deleteSearchText={true}
                closeOnEsc={true}
                showOnFocus={true}
                onBlur={() => {}}
                items={indicators.filter((el) => {
                  const notUsed = [indicators[0], "the price", "the candlestick", "Manual order"];
                  return !notUsed.includes(el);
                })}
              />
            </div>
            {indicatorsEditList.map((el, index) => {
              return (
                <div key={`${el.id}`} className={`${el.changeBg ? "bg-change rounded" : ""} mt-2`}>
                  <a
                    href="#/"
                    className="text-danger mx-3"
                    title="Remove"
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      indicatorsEditList.splice(index, 1);
                      setIndicatorsEditList([...indicatorsEditList]);
                    }}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </a>
                  <div className="d-inline-block ml-3">
                    <Dropdown
                      btnClasses="mr-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white border border-light-green rounded text-nowrap max-h-180"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={el.indicator}
                      setSelected={(indicator) => {
                        if (el.indicator !== indicator) {
                          let tmpRule = resetIndicatorSetup({}, indicator);
                          indicatorsEditList[index] = tmpRule;
                          setIndicatorsEditList([...indicatorsEditList]);
                        }
                      }}
                      items={indicators.filter((el) => {
                        const notUsed = ["the price", "the candlestick", "Manual order"];
                        return !notUsed.includes(el);
                      })}
                    />
                    {getIndicatorSetup(el)}
                    {el.indicator !== indicators[0] && (
                      <a
                        href="#/"
                        className="text-light-green ml-2"
                        title="Settings"
                        onClick={(e) => {
                          e.preventDefault();
                          document.activeElement.blur();
                          el.showSettings = el.showSettings ? false : true;
                          setIndicatorsEditList([...indicatorsEditList]);
                        }}
                      >
                        <FontAwesomeIcon icon={faCog} />
                      </a>
                    )}
                  </div>
                  {el.showSettings && (
                    <div className="fade-in small ml-5 mr-2 pl-5 mt-1">
                      <IndicatorSettingsTable
                        rule={el}
                        saveSettings={() => {
                          setIndicatorsEditList([...indicatorsEditList]);
                        }}
                        hide={() => {
                          el.showSettings = el.showSettings ? false : true;
                          setIndicatorsEditList([...indicatorsEditList]);
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        }
        func={() => {
          indicatorsEditList.forEach((el) => {
            delete el.showSettings;
            delete el.changeBg;
            delete el.timeframe;
            delete el.mode;
            delete el.conditionsOrder;
          });
          let newIndicators = indicatorsEditList.filter((el) => !checkInvalidIndicatorSettingsAll(el));
          let toUpdate = currentMultiChart.charts.find((el) => el.id === chartToEditIndicatorsId);
          toUpdate.indicators = newIndicators;
          saveMultiChart(currentMultiChart, state.user.token, dispatch);
        }}
        closeFn={() => {}}
      />
      <ModalConfirm
        id="setMultiChartName"
        dark
        header={cloneChartList ? "Clone Chart List" : "Chart List Name"}
        okText="OK"
        content={
          <div className="overflow-auto d-flex max-h-lists">
            <div className="px-3">Name:</div>
            <div className="flex-grow-1 mr-3">
              <input
                type="text"
                className="text-light border-bottom-light hover-border-bottom-info focus-border-bottom-info w-100"
                value={chartName}
                onChange={(e) => {
                  setChartName(e.target.value);
                }}
              />
            </div>
          </div>
        }
        func={() => {
          if (editChartList) {
            if (cloneChartList) {
              let newChart = {
                id: `${state.user.id}_${new Date().getTime()}`,
                userId: state.user.id,
                name: chartName,
                charts: currentMultiChart.charts.map((el) => ({
                  ...el,
                  indicators: el.indicators.map((ind) => ({ ...ind })),
                })),
              };

              saveMultiChart(newChart, state.user.token, dispatch);
              setCurrentMultiChartId(newChart.id);
            } else {
              currentMultiChart.name = chartName;
              saveMultiChart(currentMultiChart, state.user.token, dispatch);
              setCurrentMultiChart({ ...currentMultiChart });
            }

            setEditChartList(false);
            setCloneChartList(false);
          } else {
            let id = `${state.user.id}_${new Date().getTime()}`;
            let chart = {
              id: id,
              userId: state.user.id,
              name: chartName,
              charts: [],
            };
            setCurrentMultiChartIdCallback(id);
            saveMultiChart(chart, state.user.token, dispatch);
            setCurrentMultiChart({ ...chart });
          }
          setChartName("");
        }}
      />
    </div>
  );
};

export default MiniCharts;
