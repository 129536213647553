import {
  faCircle,
  faInfoCircle,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import { getBalance } from "./Bot.js";
import { globalContext } from "./Context.js";
import getExchange from "./Exchange.js";
import {
  fixNumber,
  formatDate,
  formatDateTime3,
  getUsdValue,
} from "./utils.js";
import Dropdown from "./Dropdown.js";
import { exchanges } from "./constants/consts.json";
import $ from "jquery";
import { AddExchangeModal } from "./Settings.js";
import ReactEcharts from "echarts-for-react";
import echarts from "echarts/lib/echarts";
import loading from "./assets/images/loading-reverced.gif";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { RadioButtons } from "./RadioButtons.js";
import getEcbDatabase from "./EcbDatabase";
import { ExecutionResults } from "./Trading.js";

const pricesUpdateDelay = 15;
const BotStats = (props) => {
  let { state } = useContext(globalContext);
  const [totalResultP, setTotalResultP] = useState("-");
  const [totalResultUsd, setTotalResultUsd] = useState("-");
  const [tradingCapital, setTradingCapital] = useState("-");
  const [capitalInOpenTrades, setCapitalInOpenTrades] = useState("-");
  const [stats, setStats] = useState(null);
  const [statsType, setStatsType] = useState("percent");
  const [pairSeries, setPairSeries] = useState([]);
  const [strategyToShow, setStrategyToShow] = useState({});
  const [smallWidthDevice, setSmallWidthDevice] = useState(false);
  const [pL1Day, setPL1Day] = useState();
  const [pL7Day, setPL7Day] = useState();
  const [pL30Day, setPL30Day] = useState();
  const [index7, setIndex7] = useState();
  const [index30, setIndex30] = useState();
  const [dailyPLs, setDailyPLs] = useState([]);
  const [plSeries, setPlSeries] = useState([]);
  const [plSeriesDates, setPlSeriesDates] = useState([]);

  // const [pls] = useState({
  //   sim: [
  //     {
  //       d: new Date("2021-9-10"),
  //       "0_00": { all: 1000, run: 500 },
  //       "322862_1615569016478": { all: 300, run: 500 },
  //     },
  //     {
  //       d: new Date("2021-09-27"),
  //       "0_00": { all: -100, run: 235 },
  //       "322862_1615569016478": { all: 50, run: 70 },
  //     },
  //     {
  //       d: new Date("2021-10-3"),
  //       "0_00": { all: 25, run: -6 },
  //       "322862_1615569016478": { all: 50, run: 50 },
  //     },
  //     {
  //       d: new Date("2021-10-14"),
  //       "0_00": { all: 80, run: 30 },
  //       "322862_1615569016478": { all: 1, run: -50 },
  //     },
  //     {
  //       d: new Date("2021-10-15"),
  //       "0_00": { all: 500, run: 560 },
  //       "322862_1615569016478": { all: 100, run: 100 },
  //     },
  //   ],
  //   live: [
  //     {
  //       d: new Date("2021-9-10"),
  //       "0_00": { all: 700, run: 500 },
  //       "322862_1615569016478": { all: 3000, run: 500 },
  //     },
  //     {
  //       d: new Date("2021-09-27"),
  //       "0_00": { all: -100, run: 235 },
  //       "322862_1615569016478": { all: -50, run: 70 },
  //     },
  //     {
  //       d: new Date("2021-10-3"),
  //       "0_00": { all: 250, run: -6 },
  //       "322862_1615569016478": { all: 50, run: 50 },
  //     },
  //     {
  //       d: new Date("2021-10-7"),
  //       "0_00": { all: 800, run: 30 },
  //       "322862_1615569016478": { all: 1, run: -50 },
  //     },
  //     {
  //       d: new Date("2021-10-15"),
  //       "0_00": { all: 500, run: 560 },
  //       "322862_1615569016478": { all: 100, run: 100 },
  //     },
  //     // { d: new Date("2021-9-10"), v: 45 },
  //     // { d: new Date("2021-09-27"), v: 200 },
  //     // { d: new Date("2021-10-3"), v: 3 },
  //     // { d: new Date("2021-10-4"), v: 15 },
  //     // { d: new Date("2021-10-15"), v: 134 },
  //   ],
  // });
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);
  useEffect(() => {
    props.pls.sort((a, b) => a.d - b.d);
    let filteredPLs = [];
    if (props.activeGroup) {
      filteredPLs = props.pls.filter((el) =>
        props.onlyRunning && el[props.activeGroup.id]
          ? el[props.activeGroup.id].run
          : el[props.activeGroup.id]
      );
      //Find the closest date with >= diff from today to the one needed
      let index7;
      let index30;
      let today = Date.now();

      for (let i = filteredPLs.length - 1; i >= 0; i--) {
        let diff = Math.trunc((today - filteredPLs[i].d) / 1000/ 60 / 60 / 24 );
   
        if (diff >= 7) {
          index7 = i;
          break;
        }
      }
      for (let i = filteredPLs.length - 1; i >= 0; i--) {
        let diff = Math.trunc((today - filteredPLs[i].d) / 1000 / 60 / 60 / 24);

        if (diff >= 30) {
          index30 = i;
          break;
        }
      }
      let plSeries = [];
      let plSeriesDates = [];
      if (filteredPLs.length > 0) {
        plSeries = filteredPLs.map((el) =>
          props.onlyRunning
            ? el[props.activeGroup.id].run
            : el[props.activeGroup.id].all
        );

        plSeriesDates = filteredPLs.map((el) => formatDate(new Date(el.d)));
      }

      setDailyPLs(filteredPLs);
      setIndex7(index7);
      setIndex30(index30);
      setPlSeries(plSeries);
      setPlSeriesDates(plSeriesDates);
      setPL1Day();
      setPL7Day();
      setPL30Day();
    }
  }, [props.type, props.pls, props.activeGroup, props.onlyRunning]);

  useEffect(() => {
    if (dailyPLs.length > 0) {
      setPL1Day(
        isNaN(totalResultUsd)
          ? null
          : props.onlyRunning
          ? ((totalResultUsd -
              dailyPLs[dailyPLs.length - 1][props.activeGroup.id].run) /
              Math.abs(
                dailyPLs[dailyPLs.length - 1][props.activeGroup.id].run
              )) *
            100
          : ((totalResultUsd -
              dailyPLs[dailyPLs.length - 1][props.activeGroup.id].all) /
              Math.abs(
                dailyPLs[dailyPLs.length - 1][props.activeGroup.id].all
              )) *
            100
      );

      if (!isNaN(index7)) {
        setPL7Day(
          isNaN(totalResultUsd)
            ? null
            : props.onlyRunning
            ? ((totalResultUsd - dailyPLs[index7][props.activeGroup.id].run) /
                Math.abs(dailyPLs[index7][props.activeGroup.id].run)) *
              100
            : ((totalResultUsd - dailyPLs[index7][props.activeGroup.id].all) /
                Math.abs(dailyPLs[index7][props.activeGroup.id].all)) *
              100
        );
      }
      if (!isNaN(index30)) {
        setPL30Day(
          isNaN(totalResultUsd)
            ? null
            : props.onlyRunning
            ? ((totalResultUsd - dailyPLs[index30][props.activeGroup.id].run) /
                Math.abs(dailyPLs[index30][props.activeGroup.id].run)) *
              100
            : ((totalResultUsd - dailyPLs[index30][props.activeGroup.id].all) /
                Math.abs(dailyPLs[index30][props.activeGroup.id].all)) *
              100
        );
      }
    }
  }, [dailyPLs, totalResultUsd, props.onlyRunning]);

  useEffect(() => {
    let totalResultUsd = 0;
    let hasResultUsd = false;
    if (!props.activeGroup) {
      setTotalResultP("-");
      setTotalResultUsd("-");
      setTradingCapital("-");
      setCapitalInOpenTrades("-");
      setStats(null);
      setPairSeries([]);
      return;
    }

    let statuses = [];
    for (let id of props.activeGroup.strategies) {
      let status = props.statuses[id];
      if (status) {
        if (props.onlyRunning) {
          if (status.status === "Running") {
            statuses.push(status);
          }
        } else {
          statuses.push(status);
        }
      }
    }

    let tradingCapital = 0;
    let capitalInOpenTrades = 0;
    let hadOne = false;
    let pairsUsdValue = {};
    let stats = {
      min: Number.MAX_VALUE,
      max: Number.MIN_VALUE,
      strategies: [],
      xAxis: [],
      data: [],
    };

    statuses.forEach((status) => {
      let strategy = state.strategies.find((el) => el.id === status.id);
      if (!strategy) {
        return;
      }

      let statToUse = null;
      if (statsType === "percent") {
        statToUse = props.statuses[strategy.id].result;
      }

      let prices =
        strategy.exchange === "Binance"
          ? props.pricesBinance
          : props.pricesBinanceUs;
      if (prices && prices.prices && Object.keys(prices.prices).length > 0) {
        let result = getUsdValue(
          props.statuses[strategy.id].resultInQuoted,
          strategy.pairDetails.quoteAsset,
          prices.prices
        );
        if (result !== "") {
          hasResultUsd = true;
          totalResultUsd += result;
          if (statsType === "usd") {
            statToUse = result;
          }
        }
      }
      let tradeSizeInUsd = "";

      if (statToUse != null) {
        stats.min = Math.min(stats.min, statToUse);
        stats.max = Math.max(stats.max, statToUse);
        stats.strategies.push({
          strategy: strategy,
          stat: fixNumber(statToUse, 2),
        });
      }

      switch (strategy[props.type].tradeSize.type) {
        case "base":
          tradeSizeInUsd = getUsdValue(
            strategy[props.type].tradeSize.value,
            strategy.pairDetails.baseAsset,
            prices.prices
          );
          break;
        case "quoted":
          tradeSizeInUsd = getUsdValue(
            strategy[props.type].tradeSize.value,
            strategy.pairDetails.quoteAsset,
            prices.prices
          );
          break;
        case "percent":
          let balance = getBalance(
            strategy.pairDetails.quoteAsset,
            strategy.exchange
          );
          tradeSizeInUsd = balance
            ? getUsdValue(
                balance * (strategy[props.type].tradeSize.value / 100),
                strategy.pairDetails.quoteAsset,
                prices.prices
              )
            : 0;
          break;
        default:
      }
      if (tradeSizeInUsd !== "") {
        hadOne = true;
        tradingCapital += tradeSizeInUsd;
        if (!pairsUsdValue[strategy.pair]) {
          pairsUsdValue[strategy.pair] = 0;
        }
        pairsUsdValue[strategy.pair] += tradeSizeInUsd;
        if (status.activeTrade) {
          capitalInOpenTrades += tradeSizeInUsd;
        }
      }
    });

    let pairs = Object.keys(pairsUsdValue);

    if (pairs.length === 0) {
      setPairSeries([]);
    } else {
      let pairSeries = pairs.map((pair) => {
        return {
          name: pair,
          value: pairsUsdValue[pair],
        };
      });
      pairSeries.sort((a, b) => b.value - a.value);

      setPairSeries(pairSeries);
    }

    setTotalResultP("-");
    setTotalResultUsd("-");
    setTradingCapital("-");
    if (capitalInOpenTrades === 0) {
      setCapitalInOpenTrades("-");
    } else {
      setCapitalInOpenTrades(capitalInOpenTrades);
    }
    setStats(null);

    if (stats.strategies.length > 0) {
      stats.strategies.sort((a, b) => b.stat - a.stat);
      stats.xAxis = stats.strategies.map((el) => el.strategy.name);
      stats.data = stats.strategies.map((el) =>
        el.stat > 0
          ? {
              value: fixNumber(el.stat, 2),
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#33d458",
                  },
                  {
                    offset: 1,
                    color: "#1c7831", //#28a745
                  },
                ]),
                barBorderWidth: 0,
                barBorderColor: "#1c7831",
              },
            }
          : {
              value: fixNumber(el.stat, 2),
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#ab0b00",
                  },
                  {
                    offset: 1,
                    color: "#ff483b", //#dc3545
                  },
                ]),
                barBorderWidth: 0,
                barBorderColor: "#ab0b00",
              },
            }
      );

      stats.xAxis.unshift("");
      stats.data.unshift(null);

      for (let i = 20 - stats.xAxis.length; i > 0; i--) {
        stats.xAxis.push("");
        stats.data.push(null);
      }
      stats.absMax = Math.max(stats.max, Math.abs(stats.min));
      setStats(stats);
    }

    if (hasResultUsd) {
      setTotalResultUsd(totalResultUsd);
    }
    if (hadOne) {
      setTradingCapital(tradingCapital);
      if (hasResultUsd) {
        setTotalResultP(
          tradingCapital ? (totalResultUsd / tradingCapital) * 100 : "-"
        );
      }
    }
  }, [
    props.activeGroup,
    props.onlyRunning,
    props.statuses,
    props.pricesBinance,
    props.pricesBinanceUs,
    statsType,
  ]);

  useEffect(() => {
    const sendSizeChangeDiv = (e) => {
      let root = document.getElementById("root");
      if (root) {
        if (smallWidthDevice && +root.clientWidth >= 768) {
          setSmallWidthDevice(false);
        }
        if (!smallWidthDevice && +root.clientWidth < 768) {
          setSmallWidthDevice(true);
        }
      }
    };
    sendSizeChangeDiv();

    window.addEventListener("resize", sendSizeChangeDiv);
    return () => {
      window.removeEventListener("resize", sendSizeChangeDiv);
    };
  }, [smallWidthDevice]);

  return (
    <>
      <div className="py-4">
        <div className="text-left mb-3">
          <div className="d-flex">
            <div className="col text-nowrap fade-in">
              <div className="small text-gray">1 day P/L % ch.</div>
              {pL1Day ? (
                <>
                  <h2
                    className={`d-inline ${
                      pL1Day > 0
                        ? "text-success"
                        : pL1Day < 0
                        ? "text-danger"
                        : ""
                    }`}
                  >
                    {pL1Day.toFixed(2)}
                  </h2>
                  <span
                    className={
                      pL1Day > 0
                        ? "text-success"
                        : pL1Day < 0
                        ? "text-danger"
                        : ""
                    }
                  >
                    %
                  </span>
                </>
              ) : (
                <span className="muted">-</span>
              )}
            </div>

            <div className="col text-nowrap fade-in">
              <div className="small text-gray">7 days P/L % ch.</div>
              {pL7Day ? (
                <>
                  <h2
                    className={`d-inline ${
                      pL7Day > 0
                        ? "text-success"
                        : pL7Day < 0
                        ? "text-danger"
                        : ""
                    }`}
                  >
                    {pL7Day.toFixed(2)}
                  </h2>
                  <span
                    className={
                      pL7Day > 0
                        ? "text-success"
                        : pL7Day < 0
                        ? "text-danger"
                        : ""
                    }
                  >
                    %
                  </span>
                </>
              ) : (
                <span className="muted">-</span>
              )}
            </div>

            <div className="col text-nowrap fade-in">
              <div className="small text-gray">30 days P/L % ch.</div>
              {pL30Day ? (
                <>
                  <h2
                    className={`d-inline ${
                      pL30Day > 0
                        ? "text-success"
                        : pL30Day < 0
                        ? "text-danger"
                        : ""
                    }`}
                  >
                    {pL30Day.toFixed(2)}
                  </h2>
                  <span
                    className={
                      pL30Day > 0
                        ? "text-success"
                        : pL30Day < 0
                        ? "text-danger"
                        : ""
                    }
                  >
                    %
                  </span>
                </>
              ) : (
                <span className="muted">-</span>
              )}
            </div>
          </div>
        </div>
        {plSeries.length > 1 && (
          <div className="flex-grow-1 text-center fade-in">
            <ReactEcharts
              lazyUpdate={false}
              notMerge={false}
              style={{ height: "242px", left: 0, top: 0 }}
              opts={{ renderer: "svg" }}
              className="bg-new-dark"
              option={{
                replaceMerge: ["xAxis", "yAxis", "series"],
                animation: false,
                tooltip: {
                  backgroundColor: "#343a40",
                  borderColor: "#17a2b8",
                  borderWidth: 1,
                  axisPointer: {
                    type: "cross",
                    label: { color: "#fff", backgroundColor: "#343a40" },
                  },
                  textStyle: {
                    align: "left",
                    fontSize: 12,
                  },
                  trigger: "axis",
                },
                legend: {
                  data: ["P/L($)"],
                  textStyle: { color: "#fff", fontSize: 11 },
                  icon: "pin",
                },
                axisPointer: {
                  link: { xAxisIndex: "all" },
                },
                grid: {
                  top: 28,
                  left: 40,
                  bottom: 20,
                  right: 10,
                },
                xAxis: {
                  type: "category",
                  data: plSeriesDates,
                  scale: true,
                  splitNumber: 5,
                  boundaryGap: false,
                  axisLine: {
                    onZero: true,
                    lineStyle: { color: "transparent", width: "0.5" },
                  },
                  splitLine: {
                    show: true,
                    lineStyle: { color: "#17a2b8", width: "0.5" },
                  },
                  axisLabel: { show: true, color: "#fff", fontSize: 11 },
                },
                yAxis: [
                  {
                    scale: true,
                    splitNumber: 3,
                    splitArea: {
                      show: false,
                    },
                    axisLine: {
                      lineStyle: { color: "transparent", width: "0.5" },
                    },
                    splitLine: {
                      show: true,
                      lineStyle: { color: "#17a2b8", width: "0.5" },
                    },
                    axisLabel: {
                      show: true,
                      color: "#fff",
                      fontSize: 11,
                      align: "right",
                    },
                  },
                  {
                    scale: true,
                    splitNumber: 3,
                    splitArea: {
                      show: false,
                    },
                    axisLine: {
                      lineStyle: { color: "transparent", width: "0.5" },
                    },
                    splitLine: {
                      show: false,
                      lineStyle: { color: "#17a2b8", width: "0.5" },
                    },
                    axisLabel: {
                      show: true,
                      color: "#fff",
                      fontSize: 11,
                      align: "right",
                    },
                  },
                ],

                series: [
                  {
                    name: "P/L($)",
                    type: "line",
                    symbol: "none",
                    smooth: false,
                    itemStyle: {
                      color: "#1ebdd6",
                    },

                    areaStyle: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: "#1ebdd6",
                        },
                        {
                          offset: 1,
                          color: "#0a2240",
                        },
                      ]),
                    },
                    data: plSeries,
                    dates: plSeriesDates,
                  },
                ],
              }}
            />
          </div>
        )}
      </div>

      <div className="d-block d-lg-flex ">
        {strategyToShow && (
          <ExecutionResults
            strategy={strategyToShow}
            id={`portfolioExecutionDetails_${props.type}`}
            smallWidthDevice={smallWidthDevice}
            type={props.type === "sim" ? "simulation" : "trading"}
            closeFn={() => {
              setStrategyToShow(null);
            }}
          />
        )}
        <div className="min-w-md-300">
          <div className="table-responsive mt-2 mb-3">
            <table className="table table-sm table-new-dark text-left">
              <tbody>
                <tr>
                  <td>
                    P/L %:{" "}
                    <div className="d-inline-block text-info cursor-help tooltip-parent">
                      <FontAwesomeIcon icon={faInfoCircle} />
                      <div className="tooltip-on-hover border border-info rounded bg-new-dark small min-w-170 p-2">
                        P/L in USD divided by the capital required in USD.
                      </div>
                    </div>
                  </td>
                  <td
                    className={
                      totalResultP > 0
                        ? "text-success"
                        : totalResultP < 0
                        ? "text-danger"
                        : ""
                    }
                  >
                    {isNaN(totalResultP)
                      ? totalResultP
                      : totalResultP.toFixed(2) + "%"}
                  </td>
                </tr>
                <tr>
                  <td>P/L $:</td>
                  <td
                    className={
                      totalResultUsd > 0
                        ? "text-success"
                        : totalResultUsd < 0
                        ? "text-danger"
                        : ""
                    }
                  >
                    {isNaN(totalResultUsd)
                      ? totalResultUsd
                      : "$" + totalResultUsd.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>Capital required:</td>
                  <td className="">
                    {typeof tradingCapital === "number"
                      ? "$" + tradingCapital.toFixed(2)
                      : "-"}{" "}
                  </td>
                </tr>
                <tr>
                  <td>Capital in open trades:</td>
                  <td className="">
                    {typeof capitalInOpenTrades === "number"
                      ? "$" + capitalInOpenTrades.toFixed(2)
                      : "-"}{" "}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {pairSeries.length > 0 && (
          <div className="text-center min-w-350 fade-in mb-3">
            <ReactEcharts
              lazyUpdate={true}
              style={{
                height: "242px",
                left: 0,
                top: 0,
                right: 0,
              }}
              /*opts={{ renderer: "svg" }}*/
              option={{
                backgroundColor: "transparent",
                title: {
                  text: "$ Allocation",
                  left: "center",
                  top: 0,
                  textStyle: {
                    color: "#dee2e6",
                    fontSize: 14,
                    fontWeight: "normal",
                  },
                },

                tooltip: {
                  trigger: "item",
                },

                series: [
                  {
                    name: "$ Allocation",
                    type: "pie",
                    radius: "55%",
                    center: ["50%", "55%"],
                    data:
                      pairSeries.length !== 0
                        ? pairSeries
                        : [
                            {
                              name: "NONE",
                              value: 100,
                            },
                          ],
                    label: {
                      color: "rgba(255, 255, 255, 0.7)",
                    },
                    labelLine: {
                      lineStyle: {
                        color: "rgba(255, 255, 255, 0.7)",
                      },
                    },
                    itemStyle: {
                      shadowBlur: 5,
                      shadowColor: "rgba(255, 255, 255, 0.7)",
                    },

                    emphasis: {
                      itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                      },
                    },

                    animationType: "scale",
                    animationEasing: "elasticOut",
                    animationDelay: function (idx) {
                      return Math.random() * 200;
                    },
                  },
                ],
              }}
            />
          </div>
        )}
        {stats && (
          <div className="position-relative text-center fade-in flex-fill mb-3">
            <div className="position-absolute top-0 right-0 z-index-100 small">
              <RadioButtons
                options={[
                  {
                    id: 1,
                    optionClasses: "text-light",
                    checkedColor: "text-info",
                    uncheckedColor:
                      "text-new-dark border border-info rounded-circle",
                    checked: statsType === "percent",
                    check: () => {
                      setStatsType("percent");
                    },
                    text: "%",
                  },
                  {
                    id: 2,
                    optionClasses: "text-light",
                    checkedColor: "text-info",
                    uncheckedColor:
                      "text-new-dark border border-info rounded-circle",
                    checked: statsType === "usd",
                    check: () => {
                      setStatsType("usd");
                    },
                    text: "$",
                  },
                ]}
              />
            </div>
            <ReactEcharts
              lazyUpdate={false}
              notMerge={false}
              opts={{ renderer: "svg" }}
              className="bg-new-dark"
              onEvents={{
                click: (e) => {
                  let strategyItem = stats.strategies[e.dataIndex - 1];
                  if (strategyItem && strategyItem.strategy) {
                    setStrategyToShow(strategyItem.strategy);
                  }
                  /*
              if (trade && props.setTradeToShow) {
                props.setTradeToShow(trade);
              }
              let chart = document.getElementById(props.chartId);
              if (chart) {
                chart.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
              }*/
                },
              }}
              style={{
                height: "242px",
                left: 0,
                top: 0,
                right: 0,
                width: "100%",
              }}
              option={{
                title: {
                  text: [
                    `Strategies ${
                      statsType === "percent" ? "% Return" : "USD Return"
                    }`,
                  ],
                  left: "center",
                  top: 0,
                  textStyle: {
                    color: "#dee2e6",
                    fontSize: 14,
                    fontWeight: "normal",
                  },
                },

                replaceMerge: ["xAxis", "yAxis", "series"],
                animation: false,
                tooltip: {},
                legend: {
                  show: false,
                  data: [
                    `Strategies ${
                      statsType === "percent" ? "% Return" : "USD Return"
                    }`,
                  ],
                  textStyle: { color: "#fff", fontSize: 11 },
                  icon: "none",
                },
                axisPointer: {
                  link: { xAxisIndex: "all" },
                },
                dataZoom: [
                  {
                    type: "inside",
                    xAxisIndex: [0],
                    start: 0,
                    end: 100,
                  },
                ],
                grid: {
                  top: 28,
                  left: 40,
                  right: 8,
                  bottom: 20,
                },
                xAxis: {
                  type: "category",
                  data: stats.xAxis,
                  scale: true,
                  splitNumber: 5,
                  boundaryGap: false,
                  axisLine: {
                    onZero: true,
                    lineStyle: { color: "transparent", width: "0.5" },
                  },
                  splitLine: {
                    show: true,
                    lineStyle: { color: "#17a2b8", width: "0.5" },
                  },
                  axisLabel: { show: true, color: "#fff", fontSize: 11 },
                },
                yAxis: {
                  min: -stats.absMax * 1.1,
                  max: stats.absMax * 1.1,
                  splitArea: {
                    show: false,
                  },
                  axisLine: {
                    lineStyle: { color: "transparent", width: "0.5" },
                  },
                  splitLine: {
                    show: true,
                    lineStyle: { color: "#17a2b8", width: "0.5" },
                  },
                  axisLabel: {
                    show: true,
                    color: "#fff",
                    fontSize: 11,
                    align: "right",
                    showMinLabel: false,
                    showMaxLabel: false,
                  },
                  minorTick: { show: false },
                },

                series: {
                  name: `Strategies ${
                    statsType === "percent" ? "% Return" : "USD Return"
                  }`,
                  type: "bar",
                  stack: "one",
                  symbol: "none",
                  emphasis: {
                    itemStyle: {
                      barBorderWidth: 1,
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowOffsetY: 0,
                      shadowColor: "rgba(0,0,0,0.5)",
                    },
                  },
                  data: stats.data,
                },
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

const Portfolio = (props) => {
  // return <ComingSoon title="Portfolio" />;
  let { state, dispatch } = useContext(globalContext);
  const [activeSimStrategies, setActiveSimStrategies] = useState([]);
  const [activeLiveStrategies, setActiveLiveStrategies] = useState([]);
  const [activeManualTrades, setActiveManualTrades] = useState([]);
  const [accBalance, setAccBalance] = useState("-");
  const [pricesBinance, setPricesBinance] = useState({});
  const [pricesBinanceUs, setPricesBinanceUs] = useState({});
  const [subscribedExchanges, setSubscribedExchanges] = useState({});
  const [selectedAccount, setSelectedAccount] = useState(null);
  const pricesBinanceLastUpdate = useRef(null);
  const pricesBinanceUsLastUpdate = useRef(null);
  const componentIsMounted = useRef(true);
  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");
  const [exchange, setExchange] = useState(exchanges[0]);
  const [editExchange, setEditExchange] = useState(false);
  const [change1Day, setChange1Day] = useState("");
  const [change7Days, setChange7Days] = useState("");
  const [change30Days, setChange30Days] = useState("");
  const [assetsSerries, setAssetsSerries] = useState(null);
  const [activeGroup, setActiveGroup] = useState(null);
  const [onlyRunning, setOnlyRunning] = useState(false);
  const [closeTrades, setCloseTrades] = useState([]);
  const [manualPlPercent, setManualPlPercent] = useState(0);
  const [manualPlUsd, setManualPlUsd] = useState(0);
  const [manualCapitalUsed, setManualCapitalUsed] = useState(0);
  const [manualPairSeries, setManualPairSeries] = useState([]);
  const [manualTradesStats, setManualTradesStats] = useState(null);
  const [statsType, setStatsType] = useState("percent");
  const prevSelectedAcc = useRef(null);
  const [plSim, setPlSim] = useState([]);
  const [plLive, setPlLive] = useState([]);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getEcbDatabase()
      .getDailyPLSims(state.user.id)
      .then((result) => {
        if (result && componentIsMounted) {
          setPlSim(result.pls);
        }
      });
    getEcbDatabase()
      .getDailyPLLive(state.user.id)
      .then((result) => {
        if (result && componentIsMounted) {
          setPlLive(result.pls);
        }
      });
  }, []);

  useEffect(() => {
    if (!activeGroup) {
      setActiveGroup(state.strategyGroups[0]);
      return;
    }
  }, [activeGroup, state.strategyGroups]);

  useEffect(() => {
    let hasBinance = false;
    let hasBinanceUS = false;
    for (let strategy of activeLiveStrategies) {
      if (hasBinance && hasBinanceUS) {
        break;
      }
      if (!hasBinance && strategy.exchange === "Binance") {
        hasBinance = true;
      }
      if (!hasBinanceUS && strategy.exchange === "Binance US") {
        hasBinanceUS = true;
      }
    }
    for (let strategy of activeSimStrategies) {
      if (hasBinance && hasBinanceUS) {
        break;
      }
      if (!hasBinance && strategy.exchange === "Binance") {
        hasBinance = true;
      }
      if (!hasBinanceUS && strategy.exchange === "Binance US") {
        hasBinanceUS = true;
      }
    }

    let newSubscribedExchanges = {};

    if (hasBinance && !subscribedExchanges["Binance"]) {
      newSubscribedExchanges["Binance"] = true;
      getExchange("Binance").subscribeToPricesUpdate(
        "PORTFOLIO",
        (prices, pricesStats) => {
          if (componentIsMounted.current) {
            let now = new Date();
            if (
              !pricesBinanceLastUpdate.current ||
              now.getTime() > pricesBinanceLastUpdate.current
            ) {
              now.setSeconds(now.getSeconds() + pricesUpdateDelay);
              pricesBinanceLastUpdate.current = now.getTime();
              setPricesBinance({
                prices: prices,
                pricesStats: pricesStats,
              });
            }
          }
        }
      );
    } else if (hasBinance) {
      subscribedExchanges["Binance"] = true;
    } else if (!hasBinance && subscribedExchanges["Binance"]) {
      getExchange("Binance").unsubscribeToPricesUpdate("PORTFOLIO");
    }

    if (hasBinanceUS && !subscribedExchanges["Binance US"]) {
      newSubscribedExchanges["Binance US"] = true;
      getExchange("Binance US").subscribeToPricesUpdate(
        "PORTFOLIO",
        (prices, pricesStats) => {
          if (componentIsMounted.current) {
            let now = new Date();
            if (
              !pricesBinanceUsLastUpdate.current ||
              now.getTime() > pricesBinanceUsLastUpdate.current
            ) {
              now.setSeconds(now.getSeconds() + pricesUpdateDelay);
              pricesBinanceUsLastUpdate.current = now.getTime();
              setPricesBinanceUs({
                prices: prices,
                pricesStats: pricesStats,
              });
            }
          }
        }
      );
    } else if (hasBinanceUS) {
      subscribedExchanges["Binance US"] = true;
    } else if (!hasBinanceUS && subscribedExchanges["Binance US"]) {
      getExchange("Binance US").unsubscribeToPricesUpdate("PORTFOLIO");
    }

    setSubscribedExchanges(newSubscribedExchanges);
    return () => {
      getExchange("Binance").unsubscribeToPricesUpdate("PORTFOLIO");
      getExchange("Binance US").unsubscribeToPricesUpdate("PORTFOLIO");
    };
  }, [activeSimStrategies, activeLiveStrategies]);

  useEffect(() => {
    if (state.exchangesInitialized && !selectedAccount) {
      for (let exchange in state.exchanges) {
        setSelectedAccount(exchange);
        break;
      }
    }
  }, [state.exchanges, state.exchangesInitialized]);

  useEffect(() => {
    if (state.exchangesInitialized) {
      if (!selectedAccount) {
        return;
      }
      if (selectedAccount !== prevSelectedAcc.current) {
        setAssetsSerries(null);
        setChange1Day("");
        setChange7Days("");
        setChange30Days("");
      }
      prevSelectedAcc.current = selectedAccount;
      let accBalance = getExchange(selectedAccount).getAcountBalanceInUsd();
      setAccBalance(accBalance);
    }
  }, [
    selectedAccount,
    pricesBinance,
    pricesBinanceUs,
    state.exchanges,
    state.exchangesInitialized,
  ]);

  useEffect(() => {
    if (selectedAccount) {
      getExchange(selectedAccount).subscribeToBalanceSnapshots(
        "PORTFOLIO_PAGE",
        (accBalanceSnapshots) => {
          if (accBalanceSnapshots) {
            let accBalance =
              getExchange(selectedAccount).getAcountBalanceInUsd();
            if (accBalanceSnapshots.length > 1) {
              let prevValue =
                accBalanceSnapshots[accBalanceSnapshots.length - 2].usdt;
              if (prevValue) {
                setChange1Day(((accBalance - prevValue) / prevValue) * 100);
              }
            }
            if (accBalanceSnapshots.length > 7) {
              let prevValue =
                accBalanceSnapshots[accBalanceSnapshots.length - 8].usdt;
              if (prevValue) {
                setChange7Days(((accBalance - prevValue) / prevValue) * 100);
              }
            }
            if (accBalanceSnapshots.length > 29) {
              let prevValue =
                accBalanceSnapshots[accBalanceSnapshots.length - 30].usdt;
              if (prevValue) {
                setChange30Days(((accBalance - prevValue) / prevValue) * 100);
              }
            }

            let dates = accBalanceSnapshots.map((snap) => formatDate(snap.d));
            let returns = accBalanceSnapshots.map((snap, index) => {
              if (index === 0 || !snap.usdt) {
                return 0;
              }
              return fixNumber(
                ((snap.usdt - accBalanceSnapshots[index - 1].usdt) /
                  accBalanceSnapshots[index - 1].usdt) *
                  100,
                2
              );
            });
            let usdt = accBalanceSnapshots.map((snap) =>
              snap.usdt !== undefined ? fixNumber(snap.usdt, 2) : ""
            );
            if (usdt[0] !== "") {
              let assetsSerries = [
                {
                  name: "USDT",
                  type: "line",
                  symbol: "none",
                  smooth: false,
                  itemStyle: {
                    color: "#1ebdd6",
                  },

                  areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "#1ebdd6",
                      },
                      {
                        offset: 1,
                        color: "#0a2240",
                      },
                    ]),
                  },
                  data: usdt,
                  dates: dates,
                },
                {
                  name: "D/D % change",
                  type: "line",
                  symbol: "none",
                  smooth: false,
                  lineStyle: {
                    opacity: 0.9,
                    width: 2,
                  },
                  yAxisIndex: 1,
                  data: returns,
                },
              ];
              if (dates.length > 0) {
                setAssetsSerries(assetsSerries);
              } else {
                setAssetsSerries(null);
              }
            }
          } else {
            setAssetsSerries(null);
            setChange1Day("");
            setChange7Days("");
            setChange30Days("");
          }
        }
      );
    }
    return () => {
      getExchange("Binance").unsubscribeToBalanceSnapshots("PORTFOLIO_PAGE");
      getExchange("Binance US").unsubscribeToBalanceSnapshots("PORTFOLIO_PAGE");
    };
  }, [selectedAccount, accBalance]);

  useEffect(() => {
    let newSimStrategies = state.strategies.filter(
      (strategy) => state.simStatuses[strategy.id]
    );
    setActiveSimStrategies(newSimStrategies);
  }, [state.simStatuses]);

  useEffect(() => {
    let newLiveStrategies = state.strategies.filter(
      (strategy) => state.liveStatuses[strategy.id]
    );
    setActiveLiveStrategies(newLiveStrategies);
  }, [state.liveStatuses]);

  useEffect(() => {
    getEcbDatabase()
      .getManualCloseTrades(state.user.id)
      .then((trades) => {
        if (componentIsMounted.current) {
          setCloseTrades(trades);
        }
      });
  }, [state.exchanges, state.exchangesInitialized]);

  useEffect(() => {
    let tradeSizeInUsd = 0;
    let resulInUsd = 0;
    let manualPairsSeries = {};
    let stats = {
      min: Number.MAX_VALUE,
      max: Number.MIN_VALUE,
      trades: [],
      xAxis: [],
      data: [],
    };

    closeTrades.forEach((trade) => {
      let prices =
        trade.exchange === "Binance" ? pricesBinance : pricesBinanceUs;
      if (prices && prices.prices && Object.keys(prices.prices).length > 0) {
        let sizeInUsd = getUsdValue(
          trade.oSizeInQuoted,
          trade.pairDetails.quoteAsset,
          prices.prices
        );
        if (sizeInUsd) {
          tradeSizeInUsd += sizeInUsd;
          if (!manualPairsSeries[trade.pair]) {
            manualPairsSeries[trade.pair] = sizeInUsd;
          } else {
            manualPairsSeries[trade.pair] += sizeInUsd;
          }
        }
        let result = getUsdValue(
          trade.resultInQuoted,
          trade.pairDetails.quoteAsset,
          prices.prices
        );
        let statToUse = null;
        if (statsType === "percent") {
          statToUse = trade.result;
        }

        if (result) {
          resulInUsd += result;
          if (statsType === "usd") {
            statToUse = result;
          }
        }
        if (statToUse != null) {
          stats.min = Math.min(stats.min, statToUse);
          stats.max = Math.max(stats.max, statToUse);
          stats.trades.push({ trade: trade, stat: fixNumber(statToUse, 2) });
        }
      }
    });
    setManualCapitalUsed(tradeSizeInUsd);
    setManualPlUsd(resulInUsd);
    let manualPlPercent =
      tradeSizeInUsd !== 0 ? (resulInUsd / tradeSizeInUsd) * 100 : 0;
    setManualPlPercent(manualPlPercent);

    let pairs = Object.keys(manualPairsSeries);
    if (pairs.length === 0) {
      setManualPairSeries([]);
    } else {
      let pairSeries = pairs.map((pair) => {
        return {
          name: pair,
          value: manualPairsSeries[pair],
        };
      });
      pairSeries.sort((a, b) => b.value - a.value);

      setManualPairSeries(pairSeries);
    }

    if (stats.trades.length > 0) {
      stats.trades.sort((a, b) => a.cd - b.cd);
      stats.xAxis = stats.trades
        .filter((el, index) => stats.trades.length - index < 102)
        .map((el) => `${el.trade.pair} ${formatDateTime3(el.trade.cd)}`);
      stats.data = stats.trades
        .filter((el, index) => stats.trades.length - index < 102)
        .map((el) =>
          el.stat > 0
            ? {
                value: fixNumber(el.stat, 2),
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#33d458",
                    },
                    {
                      offset: 1,
                      color: "#1c7831", //#28a745
                    },
                  ]),
                  barBorderWidth: 0,
                  barBorderColor: "#1c7831",
                },
              }
            : {
                value: fixNumber(el.stat, 2),
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#ab0b00",
                    },
                    {
                      offset: 1,
                      color: "#ff483b", //#dc3545
                    },
                  ]),
                  barBorderWidth: 0,
                  barBorderColor: "#ab0b00",
                },
              }
        );

      stats.xAxis.unshift("");
      stats.data.unshift(null);

      for (let i = 20 - stats.xAxis.length; i > 0; i--) {
        stats.xAxis.push("");
        stats.data.push(null);
      }
      stats.absMax = Math.max(stats.max, Math.abs(stats.min));
      setManualTradesStats(stats);
    }
  }, [closeTrades, pricesBinance, pricesBinanceUs, statsType]);

  return (
    //<CommingSoon title="Portfolio" />
    <div className="page mx-4">
      <AddExchangeModal
        apiKey={apiKey}
        setApiKey={setApiKey}
        apiSecret={apiSecret}
        setApiSecret={setApiSecret}
        exchange={exchange}
        setExchange={setExchange}
        editExchange={editExchange}
        fn={(exchange) => {
          setSelectedAccount(exchange);
        }}
      />

      {(state.app || state.demo) && (
        <>
          <div className="py-4">
            <div className="text-left mb-3">
              <Dropdown
                btnClasses="text-info hover-border-bottom info pl-0 pb-2"
                arrow
                arrowClasses="mt-1"
                selectedClasses="text-truncate max-w-270 mr-1"
                menuClasses="bg-new-dark border border-secondary rounded text-left small max-h-fullscreen w-150 py-1"
                menuItemsClasses="text-light hover-info px-3 py-1"
                selected={selectedAccount ? selectedAccount : "Accounts"}
                setSelected={(exchange) => {
                  setSelectedAccount(exchange);
                }}
                items={Object.keys(state.exchanges)}
                additionalOptions={[
                  {
                    label: (
                      <div className="d-flex">
                        Add account
                        <FontAwesomeIcon
                          className="ml-auto mt-1"
                          icon={faPlus}
                        />
                      </div>
                    ),
                    classes:
                      "text-info border-bottom border-secondary px-3 pt-1 pb-2",
                    fn: () => {
                      setExchange(exchanges[0]);
                      setEditExchange(false);
                      setApiKey("");
                      setApiSecret("");
                      if (!$("#addApiKeys").is(":visible")) {
                        $("#addApiKeys").modal("toggle");
                      }
                    },
                  },
                ]}
              />
              {!state.exchangesInitialized && (
                <div className="text-center mx-auto mt-5">
                  <div
                    className="loading-img cursor-help my-5"
                    title="Loading.."
                  >
                    <img src={loading} alt="" />
                  </div>
                </div>
              )}
              {state.exchanges[selectedAccount] &&
              !state.exchanges[selectedAccount].valid ? (
                <div className="text-secondary mb-3">
                  Invalid API-key or your IP address is not in the trusted IPs
                  list on Binance
                </div>
              ) : (
                <div className="d-flex">
                  {selectedAccount && (
                    <div className="col text-nowrap pl-0 fade-in">
                      <div className="small text-gray">Cash in USDT</div>
                      {typeof accBalance === "number" ? (
                        <>
                          $<h2 className="d-inline">{accBalance.toFixed(2)}</h2>
                        </>
                      ) : (
                        <h2 className="d-inline">-</h2>
                      )}
                    </div>
                  )}
                  {change1Day !== "" && (
                    <div className="col text-nowrap fade-in">
                      <div className="small text-gray">1 day % ch.</div>
                      <h2
                        className={`d-inline ${
                          change1Day > 0
                            ? "text-success"
                            : change1Day < 0
                            ? "text-danger"
                            : ""
                        }`}
                      >
                        {change1Day.toFixed(2)}
                      </h2>
                      <span
                        className={
                          change1Day > 0
                            ? "text-success"
                            : change1Day < 0
                            ? "text-danger"
                            : ""
                        }
                      >
                        %
                      </span>
                    </div>
                  )}
                  {change7Days !== "" && (
                    <div className="col text-nowrap fade-in">
                      <div className="small text-gray">7 days % ch.</div>
                      <h2
                        className={`d-inline ${
                          change7Days > 0
                            ? "text-success"
                            : change7Days < 0
                            ? "text-danger"
                            : ""
                        }`}
                      >
                        {change7Days.toFixed(2)}
                      </h2>
                      <span
                        className={
                          change7Days > 0
                            ? "text-success"
                            : change7Days < 0
                            ? "text-danger"
                            : ""
                        }
                      >
                        %
                      </span>
                    </div>
                  )}
                  {change30Days !== "" && (
                    <div className="col text-nowrap fade-in">
                      <div className="small text-gray">30 days % ch.</div>
                      <h2
                        className={`d-inline ${
                          change30Days > 0
                            ? "text-success"
                            : change30Days < 0
                            ? "text-danger"
                            : ""
                        }`}
                      >
                        {change30Days.toFixed(2)}
                      </h2>
                      <span
                        className={
                          change30Days > 0
                            ? "text-success"
                            : change30Days < 0
                            ? "text-danger"
                            : ""
                        }
                      >
                        %
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
            {state.exchanges[selectedAccount] &&
              state.exchanges[selectedAccount].valid &&
              assetsSerries && (
                <div className="flex-grow-1 text-center fade-in">
                  <ReactEcharts
                    lazyUpdate={false}
                    notMerge={false}
                    style={{ height: "242px", left: 0, top: 0 }}
                    opts={{ renderer: "svg" }}
                    className="bg-new-dark"
                    option={{
                      replaceMerge: ["xAxis", "yAxis", "series"],
                      animation: false,
                      tooltip: {
                        backgroundColor: "#343a40",
                        borderColor: "#17a2b8",
                        borderWidth: 1,
                        axisPointer: {
                          type: "cross",
                          label: { color: "#fff", backgroundColor: "#343a40" },
                        },
                        textStyle: {
                          align: "left",
                          fontSize: 12,
                        },
                        trigger: "axis",
                      },
                      legend: {
                        data: assetsSerries.map((el) => el.name),
                        textStyle: { color: "#fff", fontSize: 11 },
                        icon: "pin",
                      },
                      axisPointer: {
                        link: { xAxisIndex: "all" },
                      },
                      grid: {
                        top: 28,
                        left: 40,
                        bottom: 20,
                        right: 10,
                      },
                      xAxis: {
                        type: "category",
                        data: assetsSerries[0].dates,
                        scale: true,
                        splitNumber: 5,
                        boundaryGap: false,
                        axisLine: {
                          onZero: true,
                          lineStyle: { color: "transparent", width: "0.5" },
                        },
                        splitLine: {
                          show: true,
                          lineStyle: { color: "#17a2b8", width: "0.5" },
                        },
                        axisLabel: { show: true, color: "#fff", fontSize: 11 },
                      },
                      yAxis: [
                        {
                          scale: true,
                          splitNumber: 3,
                          splitArea: {
                            show: false,
                          },
                          axisLine: {
                            lineStyle: { color: "transparent", width: "0.5" },
                          },
                          splitLine: {
                            show: true,
                            lineStyle: { color: "#17a2b8", width: "0.5" },
                          },
                          axisLabel: {
                            show: true,
                            color: "#fff",
                            fontSize: 11,
                            align: "right",
                          },
                        },
                        {
                          scale: true,
                          splitNumber: 3,
                          splitArea: {
                            show: false,
                          },
                          axisLine: {
                            lineStyle: { color: "transparent", width: "0.5" },
                          },
                          splitLine: {
                            show: false,
                            lineStyle: { color: "#17a2b8", width: "0.5" },
                          },
                          axisLabel: {
                            show: true,
                            color: "#fff",
                            fontSize: 11,
                            align: "right",
                          },
                        },
                      ],

                      series: assetsSerries,
                    }}
                  />
                </div>
              )}
          </div>
          <div className="custom-hr text-info"></div>
        </>
      )}
      <div className="py-2">
        <div className="text-left text-gray h4 mb-3">Bot Trading</div>
        <div className="text-left mb-1">
          <Dropdown
            btnClasses="d-flex text-info hover-border-bottom info pl-0 pb-2 mr-5"
            arrow
            arrowClasses="mt-1"
            selectedClasses="text-truncate max-w-270 mr-1"
            menuClasses={`bg-new-dark border border-secondary rounded small w-250 py-1 ${
              props.panelHeight ? "" : "max-h-250"
            }`}
            menuItemsClasses="text-light text-left hover-info px-3 py-1"
            selected={
              activeGroup ? activeGroup.name : state.strategyGroups[0].name
            }
            setSelected={(group) => {
              setActiveGroup(group);
            }}
            items={state.strategyGroups.filter((gr) => !gr.isDefault)}
            additionalOptions={[
              {
                label: state.strategyGroups[0].name,
                classes:
                  "text-light text-left border-bottom hover-info border-secondary px-3 pt-1 pb-2",
                fn: () => {
                  setActiveGroup(state.strategyGroups[0]);
                },
              },
            ]}
          />
          <a
            href="#/"
            className="text-info text-nowrap hover-dark-info-children"
            onClick={(e) => {
              e.preventDefault();
              document.activeElement.blur();
              setOnlyRunning(!onlyRunning);
            }}
          >
            {onlyRunning ? (
              <FontAwesomeIcon className="mr-2" icon={faCheckCircle} />
            ) : (
              <FontAwesomeIcon
                className="text-new-dark border border-info rounded-circle mr-2"
                icon={faCircle}
              />
            )}
            Include running strategies only
          </a>
        </div>

        <div className="text-left text-gray">Live Trading</div>
        <BotStats
          onlyRunning={onlyRunning}
          activeGroup={activeGroup}
          statuses={state.liveStatuses}
          type="live"
          pricesBinance={pricesBinance}
          pricesBinanceUs={pricesBinanceUs}
          pls={plLive}
        />
      </div>
      <div className="py-4">
        <div className="text-left text-gray">Simulations</div>
        <BotStats
          onlyRunning={onlyRunning}
          activeGroup={activeGroup}
          statuses={state.simStatuses}
          type="sim"
          pricesBinance={pricesBinance}
          pricesBinanceUs={pricesBinanceUs}
          pls={plSim}
        />
      </div>
      <div className="custom-hr text-info"></div>
      <div className="pt-2 pb-5">
        <div className="text-left text-gray h4 mb-3">Manual Trading</div>
        <div className="d-block d-lg-flex ">
          <div className="min-w-md-300">
            <div className="table-responsive mt-2 mb-3">
              <table className="table table-sm table-new-dark text-left">
                <tbody>
                  <tr>
                    <td>
                      P/L %:{" "}
                      <div className="d-inline-block text-info cursor-help tooltip-parent">
                        <FontAwesomeIcon icon={faInfoCircle} />
                        <div className="tooltip-on-hover border border-info rounded bg-new-dark small min-w-170 p-2">
                          P/L in USD divided by the traded volume in USD.
                        </div>
                      </div>
                    </td>
                    <td
                      className={
                        manualPlPercent > 0
                          ? "text-success"
                          : manualPlPercent < 0
                          ? "text-danger"
                          : ""
                      }
                    >
                      {manualPlPercent.toFixed(2)}%
                    </td>
                  </tr>
                  <tr>
                    <td>P/L $:</td>
                    <td
                      className={
                        manualPlUsd > 0
                          ? "text-success"
                          : manualPlUsd < 0
                          ? "text-danger"
                          : ""
                      }
                    >
                      ${manualPlUsd.toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td>Traded volume:</td>
                    <td className="">${manualCapitalUsed.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {manualPairSeries.length > 0 && (
            <div className="text-center min-w-350 fade-in mb-3">
              <ReactEcharts
                lazyUpdate={true}
                style={{
                  height: "242px",
                  left: 0,
                  top: 0,
                  right: 0,
                }}
                /*opts={{ renderer: "svg" }}*/
                option={{
                  backgroundColor: "transparent",
                  title: {
                    text: "$ Traded Volume",
                    left: "center",
                    top: 0,
                    textStyle: {
                      color: "#dee2e6",
                      fontSize: 14,
                      fontWeight: "normal",
                    },
                  },

                  tooltip: {
                    trigger: "item",
                  },

                  series: [
                    {
                      name: "$ Traded Volume",
                      type: "pie",
                      radius: "55%",
                      center: ["50%", "55%"],
                      data:
                        manualPairSeries.length !== 0
                          ? manualPairSeries
                          : [
                              {
                                name: "NONE",
                                value: 100,
                              },
                            ],
                      label: {
                        color: "rgba(255, 255, 255, 0.7)",
                      },
                      labelLine: {
                        lineStyle: {
                          color: "rgba(255, 255, 255, 0.7)",
                        },
                      },
                      itemStyle: {
                        shadowBlur: 5,
                        shadowColor: "rgba(255, 255, 255, 0.7)",
                      },

                      emphasis: {
                        itemStyle: {
                          shadowBlur: 10,
                          shadowOffsetX: 0,
                          shadowColor: "rgba(0, 0, 0, 0.5)",
                        },
                      },

                      animationType: "scale",
                      animationEasing: "elasticOut",
                      animationDelay: function (idx) {
                        return Math.random() * 200;
                      },
                    },
                  ],
                }}
              />
            </div>
          )}

          {manualTradesStats && (
            <div className="position-relative text-center fade-in flex-fill mb-3">
              <div className="position-absolute top-0 right-0 z-index-100 small">
                <RadioButtons
                  options={[
                    {
                      id: 1,
                      optionClasses: "text-light",
                      checkedColor: "text-info",
                      uncheckedColor:
                        "text-new-dark border border-info rounded-circle",
                      checked: statsType === "percent",
                      check: () => {
                        setStatsType("percent");
                      },
                      text: "%",
                    },
                    {
                      id: 2,
                      optionClasses: "text-light",
                      checkedColor: "text-info",
                      uncheckedColor:
                        "text-new-dark border border-info rounded-circle",
                      checked: statsType === "usd",
                      check: () => {
                        setStatsType("usd");
                      },
                      text: "$",
                    },
                  ]}
                />
              </div>
              <ReactEcharts
                lazyUpdate={false}
                notMerge={false}
                opts={{ renderer: "svg" }}
                className="bg-new-dark"
                style={{
                  height: "242px",
                  left: 0,
                  top: 0,
                  right: 0,
                  width: "100%",
                }}
                option={{
                  title: {
                    text: [
                      `${
                        manualTradesStats.trades.length < 100
                          ? "Trades"
                          : "Last 100 Trades"
                      } ${statsType === "percent" ? "% Return" : "USD Return"}`,
                    ],
                    left: "center",
                    top: 0,
                    textStyle: {
                      color: "#dee2e6",
                      fontSize: 14,
                      fontWeight: "normal",
                    },
                  },

                  replaceMerge: ["xAxis", "yAxis", "series"],
                  animation: false,
                  tooltip: {},
                  legend: {
                    show: false,
                    data: [
                      `${
                        manualTradesStats.trades.length < 100
                          ? "Trades"
                          : "Last 100 Trades"
                      } ${statsType === "percent" ? "% Return" : "USD Return"}`,
                    ],
                    textStyle: { color: "#fff", fontSize: 11 },
                    icon: "none",
                  },
                  axisPointer: {
                    link: { xAxisIndex: "all" },
                  },
                  dataZoom: [
                    {
                      type: "inside",
                      xAxisIndex: [0],
                      start: 0,
                      end: 100,
                    },
                  ],
                  grid: {
                    top: 28,
                    left: 40,
                    right: 8,
                    bottom: 20,
                  },
                  xAxis: {
                    type: "category",
                    data: manualTradesStats.xAxis,
                    scale: true,
                    splitNumber: 5,
                    boundaryGap: false,
                    axisLine: {
                      onZero: true,
                      lineStyle: { color: "transparent", width: "0.5" },
                    },
                    splitLine: {
                      show: true,
                      lineStyle: { color: "#17a2b8", width: "0.5" },
                    },
                    axisLabel: { show: true, color: "#fff", fontSize: 11 },
                  },
                  yAxis: {
                    min: -manualTradesStats.absMax * 1.1,
                    max: manualTradesStats.absMax * 1.1,
                    splitArea: {
                      show: false,
                    },
                    axisLine: {
                      lineStyle: { color: "transparent", width: "0.5" },
                    },
                    splitLine: {
                      show: true,
                      lineStyle: { color: "#17a2b8", width: "0.5" },
                    },
                    axisLabel: {
                      show: true,
                      color: "#fff",
                      fontSize: 11,
                      align: "right",
                      showMinLabel: false,
                      showMaxLabel: false,
                    },
                    minorTick: { show: false },
                  },

                  series: {
                    name: [
                      `${
                        manualTradesStats.trades.length < 100
                          ? "Trades"
                          : "Last 100 Trades"
                      } ${statsType === "percent" ? "% Return" : "USD Return"}`,
                    ],
                    type: "bar",
                    stack: "one",
                    symbol: "none",
                    emphasis: {
                      itemStyle: {
                        barBorderWidth: 1,
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowOffsetY: 0,
                        shadowColor: "rgba(0,0,0,0.5)",
                      },
                    },
                    data: manualTradesStats.data,
                  },
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
