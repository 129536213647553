import React, { useState, useContext, useEffect, useRef } from "react";
import getExchange, { initializeExchanges } from "./Exchange.js";
import { globalContext } from "./Context.js";
import getEcbDatabase from "./EcbDatabase.js";
import { StrategyGroups, StrategyToEdit } from "./Strategies.js";
import { StrategyActions } from "./StrategiesMain.js";

import ResizablePanels from "./ResizablePanels.js";

const FreeBacktest = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const [panelRightExpanded, setPanelRightExpanded] = useState(false);
  const [panel] = useState({ type: 2 });
  const [editType, setEditType] = useState("New Strategy");
  const [strategyToEdit, setStrategyToEdit] = useState(null);
  const [selectedStrategyId, setSelectedStrategyId] = useState();
  const [selectedStrategy, setSelectedStrategy] = useState(null);
  const [rightPanelWidth, setRightPanelWidth] = useState(400);
  const [panelHeight, setPanelHeight] = useState(600);
  const [freeBacktestPageWidth, setFreeBacktestPageWidth] = useState(600);
  const [smallWidthDevice, setSmallWidthDevice] = useState(false);
  const selectedStrategyIdRef = useRef(null);
  const [strategyActiveSubTab] = useState("Backtest");
  const [activePage, setActivePage] = useState("Strategies");
  const componentIsMounted = useRef(true);
  const [strategyActiveSubTabResult, setStrategyActiveSubTabResult] = useState(false);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    dispatch({
      type: "user",
      payload: {
        token: "demo",
        id: 100,
        name: "Guest",
        email: "guest@easycryptobot.com",
        expireDate: "2100-01-01 00:00:00",
        isExpired: false,
        refCode: "none",
        subscriptionType: "trial",
        dispatch: dispatch,
      },
    });
    initializeExchanges(state.app, false, true, 100);
    //getExchange("Binance", true, state.app, false);

    (async () => {
      let strategies = await getEcbDatabase().getStrategies(100);
      dispatch({ type: "strategies", payload: { strategies: strategies } });
    })();
  }, []);

  useEffect(() => {
    const sendSizeChangeDiv = (e) => {
      let root = document.getElementById("root");
      if (root) {
        if (smallWidthDevice && +root.clientWidth >= 768) {
          setSmallWidthDevice(false);
        }
        if (!smallWidthDevice && +root.clientWidth < 768) {
          setSmallWidthDevice(true);
        }
        let freeBacktestPage = document.getElementById("freeBacktestPage");
        if (freeBacktestPage) {
          setFreeBacktestPageWidth(+freeBacktestPage.clientWidth);
        }
      }
    };
    sendSizeChangeDiv();

    window.addEventListener("resize", sendSizeChangeDiv);
    return () => {
      window.removeEventListener("resize", sendSizeChangeDiv);
    };
  }, [smallWidthDevice]);

  useEffect(() => {
    let selected = state.strategies.find((el) => el.id === selectedStrategyId);
    if (selected) {
      setSelectedStrategy(selected);
      selectedStrategyIdRef.current = selectedStrategyId;
    } else {
      setSelectedStrategy(null);
      selectedStrategyIdRef.current = null;
    }
  }, [selectedStrategyId]);

  return (
    <div id="freeBacktestPage" className="page position-relative text-left fade-in pt-3 px-3 px-md-5 pb-4">
      {smallWidthDevice ? (
        <div>
          <div className="text-center user-select-none active-border hover-white mb-4" style={{ height: "auto" }}>
            <a
              className={`text-info px-2 pb-2 mr-2 ${activePage === "Strategies" ? "active" : ""}`}
              href="#/"
              onClick={(e) => {
                e.preventDefault();
                document.activeElement.blur();
                setActivePage("Strategies");
              }}
            >
              Strategies
            </a>
            <a
              className={`text-info px-2 pb-2 mr-2 ${activePage === "Backtest" ? "active" : ""}`}
              href="#/"
              onClick={(e) => {
                e.preventDefault();
                document.activeElement.blur();
                setActivePage("Backtest");
              }}
            >
              Backtest
            </a>
          </div>
          {activePage === "Strategies" && (
            <StrategyGroups
              activeGroup={state.strategyGroups[0]}
              setStrategyToEdit={setStrategyToEdit}
              setEditType={setEditType}
              setSaveFromOptimization={() => {}}
              selectedStrategy={selectedStrategy}
              selectedStrategyId={selectedStrategyId}
              setSelectedStrategy={setSelectedStrategy}
              setSelectedStrategyIdCallback={setSelectedStrategyId}
              selectedStrategyIdRef={() => {}}
              hideGroup={true}
            />
          )}
          {activePage === "Backtest" && (
            <StrategyActions
              activeGroup={state.strategyGroups[0]}
              selectedStrategy={selectedStrategy}
              selectedStrategyId={selectedStrategyId}
              selectedStrategyIdRef={selectedStrategyIdRef}
              setStrategyToEdit={setStrategyToEdit}
              setEditType={setEditType}
              setSaveFromOptimization={() => {}}
              strategyActiveSubTab={strategyActiveSubTab}
              setStrategyActiveSubTabCallback={() => {}}
              strategyActiveSubTabResult={strategyActiveSubTabResult}
              setStrategyActiveSubTabResult={setStrategyActiveSubTabResult}
              setSelectedStrategyIdCallback={setSelectedStrategyId}
              panelWidth={freeBacktestPageWidth}
              smallWidthDevice={smallWidthDevice}
              singlePage={true}
              freeBacktest
            />
          )}
        </div>
      ) : (
        <div>
          <div id="panelTop" className="overflow-hidden fade-in d-flex py-2 mt-2">
            <div id="panelLeft" className="pr-3">
              <StrategyGroups
                activeGroup={state.strategyGroups[0]}
                setStrategyToEdit={setStrategyToEdit}
                setEditType={setEditType}
                setSaveFromOptimization={() => {}}
                selectedStrategy={selectedStrategy}
                selectedStrategyId={selectedStrategyId}
                setSelectedStrategy={setSelectedStrategy}
                setSelectedStrategyIdCallback={setSelectedStrategyId}
                selectedStrategyIdRef={() => {}}
                hideGroup={true}
              />
            </div>
            <div id="dragX"></div>
            <div id="panelRight" className="pl-3">
              <StrategyActions
                activeGroup={state.strategyGroups[0]}
                selectedStrategy={selectedStrategy}
                selectedStrategyId={selectedStrategyId}
                selectedStrategyIdRef={selectedStrategyIdRef}
                setStrategyToEdit={setStrategyToEdit}
                setEditType={setEditType}
                setSaveFromOptimization={() => {}}
                strategyActiveSubTab={strategyActiveSubTab}
                setStrategyActiveSubTabCallback={() => {}}
                strategyActiveSubTabResult={strategyActiveSubTabResult}
                setStrategyActiveSubTabResult={setStrategyActiveSubTabResult}
                setSelectedStrategyIdCallback={setSelectedStrategyId}
                panelWidth={rightPanelWidth}
                panelHeight={panelHeight}
                panelExpanded={panelRightExpanded}
                setPanelExpanded={setPanelRightExpanded}
                smallWidthDevice={smallWidthDevice}
                singlePage={panelRightExpanded}
                freeBacktest
              />
            </div>
          </div>
          <div id="dragY"></div>
          <div id="panelBottom"></div>

          <ResizablePanels
            minLeft={320}
            leftPanelSizeDefault={485}
            panelRightExpanded={panelRightExpanded}
            topHeightOnChange={setPanelHeight}
            rightWidthOnChange={setRightPanelWidth}
            panel={panel}
            storePanel={() => {}}
          />
        </div>
      )}

      {strategyToEdit && (
        <StrategyToEdit
          setStrategyToEdit={(strategy) => {
            if (componentIsMounted.current) {
              setStrategyToEdit(strategy);
            }
          }}
          strategy={strategyToEdit}
          editType={editType}
          demo={state.demo}
          freeBacktest={props.freeBacktest}
          saveFromOptimization={false}
          addStrategyToActiveGroup={() => {}}
          setSelectedStrategyId={setSelectedStrategyId}
        />
      )}
    </div>
  );
};

export default FreeBacktest;
