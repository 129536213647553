import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import { globalContext } from "./Context.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faArrowsAltV,
  faPlus,
  faCog,
  faTimesCircle,
  faChartLine,
  faCompressAlt,
  faExpandArrowsAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  roundQuotedValue,
  getFromLocalStorage,
  pricesUpdateDelay,
  binanceMinPairs,
  binanceUSMinPairs,
  getTimeframeFromInterval,
  getIndicatorSetup,
  resetIndicatorSetup,
  checkInvalidIndicatorSettingsAll,
  saveIndicators,
  getIndicators,
} from "./utils.js";
import getExchange from "./Exchange.js";
import $ from "jquery";
import loading from "./assets/images/loading-reverced.gif";
import { saveWatchlist, deleteWatchlist } from "./utils.js";
import Dropdown, { DropdownWithSearch } from "./Dropdown";
import { ModalConfirm } from "./Modal";
import { LiveChartWithIndicators } from "./Charts.js";
import { indicators } from "./constants/consts.json";
import { IndicatorSettingsTable } from "./Rules.js";
import ResizablePanels from "./ResizablePanels.js";
import ManualTrading from "./ManualTrading.js";

const PairsTableContent = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const [pricesStatsBinance, setPricesStatsBinance] = useState({});
  const [pricesStatsBinanceUs, setPricesStatsBinanceUs] = useState({});
  const [pricesStatsAll, setPricesStatsAll] = useState({});
  const [pairsToShow, setPairsToShow] = useState([]);
  const pricesBinanceLastUpdate = useRef(null);
  const pricesBinanceUsLastUpdate = useRef(null);
  const pricesBinancePairsCount = useRef(0);
  const pricesBinanceUsPairsCount = useRef(0);
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getExchange("Binance").subscribeToPricesUpdate("watchlists", (prices, pricesStats) => {
      if (componentIsMounted.current) {
        let now = new Date();
        if (
          !pricesBinanceLastUpdate.current ||
          now.getTime() > pricesBinanceLastUpdate.current ||
          pricesBinancePairsCount.current < binanceMinPairs
        ) {
          now.setSeconds(now.getSeconds() + pricesUpdateDelay);
          pricesBinanceLastUpdate.current = now.getTime();
          pricesBinancePairsCount.current = Object.keys(prices).length;
          setPricesStatsBinance(pricesStats);
        }
      }
    });

    getExchange("Binance US").subscribeToPricesUpdate("watchlists", (prices, pricesStats) => {
      if (componentIsMounted.current) {
        let now = new Date();
        if (
          !pricesBinanceUsLastUpdate.current ||
          now.getTime() > pricesBinanceUsLastUpdate.current ||
          pricesBinanceUsPairsCount.current < binanceUSMinPairs
        ) {
          now.setSeconds(now.getSeconds() + pricesUpdateDelay);
          pricesBinanceUsLastUpdate.current = now.getTime();
          pricesBinanceUsPairsCount.current = Object.keys(prices).length;
          setPricesStatsBinanceUs(pricesStats);
        }
      }
    });

    return () => {
      getExchange("Binance").unsubscribeToPricesUpdate("watchlists");
      getExchange("Binance US").unsubscribeToPricesUpdate("watchlists");
    };
  }, [state.user]);

  useEffect(() => {
    let pricesStatsAll = {};
    if (Object.keys(pricesStatsBinance).length > 0) {
      Object.keys(pricesStatsBinance).forEach((key) => {
        pricesStatsAll[`BINANCE-${key}`] = pricesStatsBinance[key];
        pricesStatsAll[`BINANCE-${key}`].e = "BINANCE";
      });
    }

    if (Object.keys(pricesStatsBinanceUs).length > 0) {
      Object.keys(pricesStatsBinanceUs).forEach((key) => {
        pricesStatsAll[`BINANCEUS-${key}`] = pricesStatsBinanceUs[key];
        pricesStatsAll[`BINANCEUS-${key}`].e = "BINANCEUS";
      });
    }
    setPricesStatsAll(pricesStatsAll);
  }, [pricesStatsBinance, pricesStatsBinanceUs]);

  useEffect(() => {
    if (!props.currentWatchlist) {
      return;
    }
    let pairsToSort = [];
    if (props.currentWatchlist.isDefault) {
      let statsToUse = props.watchlistExchange === "Binance" ? pricesStatsBinance : pricesStatsBinanceUs;

      let pricesStats = [];
      Object.keys(statsToUse).forEach((el) => {
        pricesStats.push({ s: el, c: statsToUse[el].chP });
      });

      if (props.currentWatchlist.id === "0_00") {
        pricesStats.sort((a, b) => b.c - a.c);
      }
      if (props.currentWatchlist.id === "0_01") {
        pricesStats.sort((a, b) => a.c - b.c);
      }
      pairsToSort = pricesStats
        .slice(0, 10)
        .map((el) => `${props.watchlistExchange === "Binance" ? "BINANCE" : "BINANCEUS"}-${el.s}`);
      setPairsToShow([...pairsToSort]);
      return;
    } else {
      pairsToSort = props.currentWatchlist.pairs;

      pairsToSort.sort((pairA, pairB) => {
        let a = pricesStatsAll[pairA];
        let b = pricesStatsAll[pairB];
        if (!a && !b) {
          return 0;
        }
        if (!a) {
          return 1;
        }
        if (!b) {
          return -1;
        }
        switch (props.pairsSort) {
          case "chR":
            return a.ch - b.ch;
          case "ch":
            return b.ch - a.ch;
          case "chPR":
            return a.chP - b.chP;
          case "chP":
            return b.chP - a.chP;
          case "s":
            return a.s.localeCompare(b.s);
          case "sR":
            return b.s.localeCompare(a.s);
          case "e":
            return a.exchange.localeCompare(b.exchange);
          case "eR":
            return b.exchange.localeCompare(a.exchange);
          case "pR":
            return a.c - b.c;
          case "p":
            return b.c - a.c;
          case "prR":
            return (a.c - a.l) / (a.h - a.l) - (b.c - b.l) / (b.h - b.l);
          case "pr":
            return (b.c - b.l) / (b.h - b.l) - (a.c - a.l) / (a.h - a.l);
          default:
            return b.chP - a.chP;
        }
      });
      setPairsToShow([...pairsToSort]);
    }
  }, [props.currentWatchlist, props.watchlistExchange, props.pairsSort, pricesStatsBinance, pricesStatsBinanceUs]);

  return pairsToShow.map((pair, index) => {
    let [exchange, symbol] = pair.split("-");
    let asset = { s: symbol, e: exchange };
    if (pricesStatsAll[pair]) {
      asset = pricesStatsAll[pair];
    }
    return (
      <tr key={index} className={props.tradingPair === pair ? "selected" : ""}>
        <td
          className="text-left cursor-pointer pl-3"
          onClick={() => {
            props.setTradingPair(pair);
            localStorage.setItem("tradingPair", pair);
          }}
        >
          {asset.s}
        </td>
        <td
          className="text-left cursor-pointer d-none d-md-table-cell"
          onClick={() => {
            props.setTradingPair(pair);
            localStorage.setItem("tradingPair", pair);
          }}
        >
          {asset.e}
        </td>
        <td
          className={`text-left cursor-pointer ${asset.chP > 0 ? "text-success" : "text-danger"}`}
          onClick={() => {
            props.setTradingPair(pair);
            localStorage.setItem("tradingPair", pair);
          }}
        >
          {asset.chP !== undefined ? asset.chP.toFixed(2) + "%" : ""}
        </td>
        <td
          className={`text-left cursor-pointer ${asset.chP > 0 ? "text-success" : "text-danger"}`}
          onClick={() => {
            props.setTradingPair(pair);
            localStorage.setItem("tradingPair", pair);
          }}
        >
          {asset.c
            ? props.pairDetailsAll && props.pairDetailsAll[pair]
              ? roundQuotedValue(asset.c, props.pairDetailsAll[pair])
              : asset.c.toFixed(8)
            : ""}
        </td>
        <td
          title={asset.l && asset.h ? `L: ${asset.l} H:${asset.h}` : ""}
          className="py-0 cursor-pointer"
          onClick={() => {
            props.setTradingPair(pair);
            localStorage.setItem("tradingPair", pair);
          }}
        >
          <div className="w-custom cursor-pointer">
            {asset.l && asset.h && asset.c && (
              <input
                className={`custom-range cursor-pointer ${
                  (asset.h - asset.l) / 2 + asset.l > asset.c ? "red" : "green"
                }`}
                type="range"
                min={(asset.l * 100000000).toFixed(0)}
                max={(asset.h * 100000000).toFixed(0)}
                value={(asset.c * 100000000).toFixed(0)}
                disabled="disabled"
              />
            )}
          </div>
        </td>
        {props.currentWatchlist && !props.currentWatchlist.isDefault && (
          <td className="text-center">
            <a
              className="text-danger"
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                props.currentWatchlist.pairs.splice(props.currentWatchlist.pairs.indexOf(pair), 1);
                props.currentWatchlist.editDate = new Date().getTime();
                saveWatchlist(props.currentWatchlist, state.user.token, dispatch);
                props.setCurrentWatchlist({
                  ...props.currentWatchlist,
                  pairs: props.currentWatchlist.pairs.map((el) => el),
                });
              }}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </a>
          </td>
        )}
      </tr>
    );
  });
};

const BigChart = (props) => {
  let { state, dispatch } = useContext(globalContext);
  return (
    <>
      <div className="d-flex flex-wrap mr-1">
        <div className="text-nowrap">
          <FontAwesomeIcon className="text-info mr-2" icon={faSearch} />
          <DropdownWithSearch
            classesInput="search-bar text-left h-33 pb-2 px-2"
            dropMenuClasses={`bg-new-dark text-left border border-secondary rounded w-220 small left-0 ${
              props.topPanelHeight ? "" : "max-h-fullscreen"
            }`}
            dropMenuItemsClasses="text-light items-info"
            menuStyle={
              props.topPanelHeight
                ? { maxHeight: Math.max(100, props.topPanelHeight - props.dropdownHeightSubtract) }
                : {}
            }
            placeholder="Select pair"
            selected={props.tradingPair}
            setSelected={(pair) => {
              if (props.pairsAll.includes(pair)) {
                props.setTradingPair(pair);
                localStorage.setItem("tradingPair", pair);
              }
            }}
            onBlur={() => {
              // if (!props.pairsAll.includes(props.bigChartPairSearch)) {
              //   props.setBigChartPairSearch(props.tradingPair);
              // }
            }}
            showOnFocus={true}
            closeOnEsc={true}
            items={props.pairsAll}
          />
        </div>
        <div className="text-nowrap user-select-none overflow-hidden ml-auto">
          {props.showIndicatorsBtn && (
            <a
              href="/#"
              className="text-light-green mr-3"
              title="Indicators"
              onClick={(e) => {
                e.preventDefault();
                document.activeElement.blur();
                props.setIndicatorsEditList(props.indicatorsList.map((el) => ({ ...el })));
                if (!$("#setIndicators").is(":visible")) {
                  $("#setIndicators").modal("toggle");
                }
              }}
            >
              <FontAwesomeIcon className="small" icon={faChartLine} />
            </a>
          )}
          {props.timeframes.map((time) => {
            return (
              <a
                key={time}
                href="/#"
                className={
                  props.bigChartTimeframe === time ? "small bg-info text-light rounded px-1" : "small text-info px-1"
                }
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  props.setBigChartTimeframe(time);
                  localStorage.setItem("bigChartTimeframe", time);
                }}
              >
                {time}
              </a>
            );
          })}
          <a
            href="/#"
            className="text-light-green ml-3"
            title={state.watchlistPanelRightExpanded ? "Collapse" : "Expand"}
            onClick={(e) => {
              e.preventDefault();
              document.activeElement.blur();
              dispatch({ type: "watchlistPanelRightExpanded", payload: !state.watchlistPanelRightExpanded });
              props.setPanelRightExpanded(!state.watchlistPanelRightExpanded);
            }}
          >
            {state.watchlistPanelRightExpanded ? (
              <FontAwesomeIcon className="small fade-in" icon={faCompressAlt} />
            ) : (
              <FontAwesomeIcon className="small fade-in" icon={faExpandArrowsAlt} />
            )}
          </a>
        </div>
      </div>
      {props.pair && props.exchange && props.bigChartTimeframe && (
        <div className="mt-1">
          <LiveChartWithIndicators
            strategy={props.bigChartStrategy}
            pair={props.pair}
            exchange={props.exchange}
            timeframe={props.bigChartTimeframe}
            id="bigChartHomePage"
            showZoomSlider={true}
            height={Math.max(140, props.topPanelHeight ? props.topPanelHeight - 75 : 450)}
          />
        </div>
      )}
    </>
  );
};

const WatchlistTable = (props) => {
  let { state, dispatch } = useContext(globalContext);

  return state.watchlistsInitialized && props.currentWatchlist ? (
    <>
      <div className="d-flex justify-content-between flex-wrap mb-2">
        <Dropdown
          btnClasses="d-flex text-light hover-border-bottom info pt-0 pl-0 pb-2"
          arrow
          arrowClasses="mt-1"
          selectedClasses="text-truncate max-w-270 mr-1"
          menuClasses={`bg-new-dark border border-secondary rounded text-left small w-250 py-1 ${
            props.topPanelHeight ? "" : "max-h-fullscreen"
          }`}
          menuStyle={
            props.topPanelHeight
              ? { maxHeight: Math.max(100, props.topPanelHeight - props.dropdownHeightSubtract) }
              : {}
          }
          menuItemsClasses="text-light hover-info px-3 py-1"
          selected={props.currentWatchlist ? props.currentWatchlist.name : state.watchlists[0].name}
          setSelected={(watchlist) => {
            props.setCurrentWatchlistIdCallback(watchlist.id);
          }}
          items={state.watchlists.filter((wl) => !wl.isDefault)}
          additionalOptions={[
            {
              label: (
                <div className="d-flex">
                  Create List
                  <FontAwesomeIcon className="ml-auto mt-1" icon={faPlus} />
                </div>
              ),
              classes: "text-info border-bottom border-secondary px-3 pt-1 pb-2",
              fn: () => {
                if (!$("#setWatchlistName").is(":visible")) {
                  $("#setWatchlistName").modal("toggle");
                }
              },
            },
            {
              label: state.watchlists[0].name,
              classes: "text-light hover-info px-3 pt-2 pb-1",
              fn: () => {
                props.setCurrentWatchlistIdCallback(state.watchlists[0].id);
              },
            },
            {
              label: state.watchlists[1].name,
              classes: `text-light hover-info px-3 pt-1 pb-2 ${
                state.watchlists.length > 2 ? "border-bottom border-secondary mb-1" : ""
              }`,
              fn: () => {
                props.setCurrentWatchlistIdCallback(state.watchlists[1].id);
              },
            },
          ]}
        />

        {props.currentWatchlist && !props.currentWatchlist.isDefault ? (
          <>
            <div className="ml-2">
              <DropdownWithSearch
                classesInput="search-bar text-left h-33 w-150 px-2 pb-2"
                dropMenuClasses={`bg-new-dark border border-secondary rounded w-250 small left-0 ${
                  props.topPanelHeight ? "" : "max-h-fullscreen"
                }`}
                menuStyle={
                  props.topPanelHeight
                    ? { maxHeight: Math.max(100, props.topPanelHeight - props.dropdownHeightSubtract) }
                    : {}
                }
                dropMenuItemsClasses="text-light items-info"
                placeholder="Add pair to list +"
                selected={props.watchlistPairSearch}
                setSelected={(pair) => {
                  props.setWatchlistPairSearch("");
                  if (props.currentWatchlist.pairs.includes(pair)) {
                    return;
                  }
                  props.currentWatchlist.pairs.push(pair);
                  props.currentWatchlist.editDate = new Date().getTime();
                  saveWatchlist(props.currentWatchlist, state.user.token, dispatch);
                  props.setCurrentWatchlist({
                    ...props.currentWatchlist,
                    pairs: props.currentWatchlist.pairs.map((el) => el),
                  });
                }}
                deleteSearchText={true}
                closeOnEsc={true}
                showOnFocus={true}
                onBlur={() => {}}
                items={props.pairsAll}
              />
            </div>
            <Dropdown
              btnClasses="text-info hover-light"
              selectedClasses=""
              menuClasses="bg-new-dark border border-secondary rounded small text-left text-nowrap max-h-fullscreen right-0 py-1"
              menuItemsClasses="text-light hover-info px-3 py-1"
              selected={<FontAwesomeIcon className="hover-light" icon={faCog} />}
              setSelected={() => {}}
              items={[]}
              additionalOptions={[
                {
                  label: "Change name",
                  classes: "text-info border-bottom border-secondary px-3 pt-1 pb-2",
                  fn: () => {
                    props.setWatchlistName(props.currentWatchlist.name);
                    props.setEditWatchlist(true);
                    if (!$("#setWatchlistName").is(":visible")) {
                      $("#setWatchlistName").modal("toggle");
                    }
                  },
                },
                {
                  label: "Delete list",
                  classes: "text-info px-3 pt-2 pb-1",
                  fn: () => {
                    deleteWatchlist(props.currentWatchlist.id, state.user.token, dispatch);
                    props.setCurrentWatchlistIdCallback(state.watchlists[0].id);
                  },
                },
              ]}
            />
          </>
        ) : (
          <div className="active-border hover-white user-select-none ml-2 mb-1">
            {props.exchanges.map((exchange, index) => (
              <a
                key={index}
                href="#/"
                className={`text-info text-nowrap mx-1 px-3 pb-2 ${
                  props.watchlistExchange === exchange ? "active" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  props.setWatchlistExchangeCallback(exchange);
                }}
              >
                {exchange}
              </a>
            ))}
          </div>
        )}
      </div>
      <div
        className={`table-responsive overflow-auto border border-dark rounded user-select-none ${
          props.topPanelHeight ? "" : "max-h-fullscreen"
        }`}
        style={props.topPanelHeight ? { maxHeight: Math.max(100, props.topPanelHeight - 60) } : {}}
      >
        <table className="table table-sm table-hover table-new-dark rounded sortable-table small mb-0 ">
          <thead className="thead-new-dark">
            <tr className="user-select-none">
              <th
                className="text-left pl-3"
                onClick={() => {
                  if (!props.currentWatchlist.isDefault) {
                    props.setPairsSortCallback(props.pairsSort !== "s" ? "s" : "sR");
                  }
                }}
              >
                Pair
                {!props.currentWatchlist.isDefault && (props.pairsSort === "s" || props.pairsSort === "sR") && (
                  <FontAwesomeIcon className="ml-2" icon={faArrowsAltV} />
                )}
              </th>
              <th className="text-left text-nowrap d-none d-md-table-cell">Exchange</th>
              <th
                className="text-left text-nowrap"
                onClick={() => {
                  if (!props.currentWatchlist.isDefault) {
                    props.setPairsSortCallback(props.pairsSort !== "chP" ? "chP" : "chPR");
                  }
                }}
              >
                24h % ch.
                {(props.pairsSort === "chP" || props.pairsSort === "chPR" || props.currentWatchlist.isDefault) && (
                  <FontAwesomeIcon className="ml-2" icon={faArrowsAltV} />
                )}
              </th>
              <th
                className="text-left text-nowrap"
                onClick={() => {
                  if (!props.currentWatchlist.isDefault) {
                    props.setPairsSortCallback(props.pairsSort !== "p" ? "p" : "pR");
                  }
                }}
              >
                Price
                {!props.currentWatchlist.isDefault && (props.pairsSort === "p" || props.pairsSort === "pR") && (
                  <FontAwesomeIcon className="ml-2" icon={faArrowsAltV} />
                )}
              </th>
              <th
                className="text-left text-nowrap"
                onClick={() => {
                  if (!props.currentWatchlist.isDefault) {
                    props.setPairsSortCallback(props.pairsSort !== "pr" ? "pr" : "prR");
                  }
                }}
              >
                24h range
                {!props.currentWatchlist.isDefault && (props.pairsSort === "pr" || props.airsSort === "prR") && (
                  <FontAwesomeIcon className="ml-2" icon={faArrowsAltV} />
                )}
              </th>
              {props.currentWatchlist && !props.currentWatchlist.isDefault && (
                <th className="text-left text-nowrap"></th>
              )}
            </tr>
          </thead>
          <tbody>
            <PairsTableContent
              pairDetailsAll={props.pairDetailsAll}
              pairsSort={props.pairsSort}
              setPairsSort={props.setPairsSort}
              currentWatchlist={props.currentWatchlist}
              setTradingPair={props.setTradingPair}
              tradingPair={props.tradingPair}
              setCurrentWatchlist={props.setCurrentWatchlist}
              watchlistExchange={props.watchlistExchange}
            />
          </tbody>
        </table>
      </div>
    </>
  ) : (
    <div className="text-center mx-auto">
      <div className="loading-white cursor-help my-5" title="Loading..">
        <img src={loading} alt="" />
      </div>
    </div>
  );
};

const Watchlist = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const [exchanges] = useState(["Binance", "Binance US"]);
  const [timeframes] = useState(["1m", "5m", "15m", "30m", "1h", "4h", "1d", "1w", "1M"]);
  const [watchlistExchange, setWatchlistExchange] = useState(getFromLocalStorage("watchListExchange", exchanges[0]));
  let [currentWatchlistId, setCurrentWatchlistId] = useState(
    getFromLocalStorage("currentWatchlistId", state.watchlists[0].id)
  );
  let [currentWatchlist, setCurrentWatchlist] = useState(null);
  const [watchlistName, setWatchlistName] = useState("");
  const [editWatchlist, setEditWatchlist] = useState(false);
  const [watchlistPairSearch, setWatchlistPairSearch] = useState("");
  const [bigChartTimeframe, setBigChartTimeframe] = useState(getFromLocalStorage("bigChartTimeframe", "1h"));
  const [pair, setPair] = useState("");
  const [exchange, setExchange] = useState("");
  const [indicatorsList, setIndicatorsList] = useState([]);
  const [indicatorsEditList, setIndicatorsEditList] = useState([]);
  const [bigChartStrategy, setBigChartStrategy] = useState(null);
  const [showIndicatorsBtn, setShowIndicatorsBtn] = useState(false);
  const componentIsMounted = useRef(true);
  const [dropdownHeightSubtract] = useState(52);
  const [pairsSort, setPairsSort] = useState(getFromLocalStorage("pairsSort", "chP"));
  const [panelRightExpanded, setPanelRightExpanded] = useState(state.watchlistPanelRightExpanded);
  const [topPanelHeight, setTopPanelHeight] = useState(395);
  const [panel] = useState({ type: 3 });

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let [exchange, pair] = props.tradingPair.split("-");
    exchange = exchange === "BINANCE" ? "Binance" : "Binance US";
    setPair(pair);
    setExchange(exchange);
  }, [props.tradingPair]);

  useEffect(() => {
    getIndicators(state.user.id, state.user.token).then((indicators) => {
      if (componentIsMounted.current) {
        if (indicators && indicators.params) setIndicatorsList(indicators.params);
        setShowIndicatorsBtn(true);
      }
    });
  }, [state.user]);

  useEffect(() => {
    if (indicatorsList.length > 0) {
      setBigChartStrategy({
        pair: pair,
        exchange: exchange,
        buyRules: indicatorsList.map((indicator) => ({
          ...indicator,
          mode: "close",
          timeframe: getTimeframeFromInterval(bigChartTimeframe),
        })),
        sellRules: [],
      });
    } else {
      setBigChartStrategy(null);
    }
  }, [indicatorsList, bigChartTimeframe, pair, exchange]);

  useEffect(() => {
    if (state.watchlistsInitialized) {
      let wl = state.watchlists.find((wl) => wl.id === currentWatchlistId);
      if (wl) {
        if (!wl.isDefault) {
          let pairsSortFromDb = getFromLocalStorage("pairsSort", "chP");
          if (pairsSortFromDb !== pairsSort) {
            setPairsSort(pairsSortFromDb);
          }
        }
        setCurrentWatchlist({ ...wl });
      } else {
        setCurrentWatchlist(state.watchlists[0]);
      }
    }
  }, [currentWatchlistId, state.watchlists, state.watchlistsInitialized]);

  const setCurrentWatchlistIdCallback = useCallback((newId) => {
    localStorage.setItem("currentWatchlistId", newId);
    setCurrentWatchlistId(newId);
  }, []);

  const setWatchlistExchangeCallback = useCallback((exchange) => {
    localStorage.setItem("watchListExchange", exchange);
    setWatchlistExchange(exchange);
  }, []);

  const setPairsSortCallback = useCallback((pairsSort) => {
    localStorage.setItem("pairsSort", pairsSort);
    setPairsSort(pairsSort);
  }, []);

  return (
    <div>
      {!props.smallWidthDevice ? (
        <>
          <div id="panelTop" className="overflow-hidden fade-in d-flex py-2">
            <div id="panelLeft" className="pr-3">
              <WatchlistTable
                currentWatchlist={currentWatchlist}
                topPanelHeight={topPanelHeight}
                dropdownHeightSubtract={dropdownHeightSubtract}
                setCurrentWatchlistIdCallback={setCurrentWatchlistIdCallback}
                watchlistPairSearch={watchlistPairSearch}
                setWatchlistPairSearch={setWatchlistPairSearch}
                setCurrentWatchlist={setCurrentWatchlist}
                setWatchlistName={setWatchlistName}
                setEditWatchlist={setEditWatchlist}
                exchanges={exchanges}
                watchlistExchange={watchlistExchange}
                setWatchlistExchangeCallback={setWatchlistExchangeCallback}
                setPairsSortCallback={setPairsSortCallback}
                pairsSort={pairsSort}
                setPairsSort={setPairsSort}
                pairDetailsAll={props.pairDetailsAll}
                setTradingPair={props.setTradingPair}
                tradingPair={props.tradingPair}
                pairsAll={props.pairsAll}
              />
            </div>

            <div id="dragX"></div>
            <div id="panelRight" className="pl-3">
              <BigChart
                topPanelHeight={topPanelHeight}
                dropdownHeightSubtract={dropdownHeightSubtract}
                setTradingPair={props.setTradingPair}
                tradingPair={props.tradingPair}
                showIndicatorsBtn={showIndicatorsBtn}
                setIndicatorsEditList={setIndicatorsEditList}
                indicatorsList={indicatorsList}
                timeframes={timeframes}
                bigChartTimeframe={bigChartTimeframe}
                setBigChartTimeframe={setBigChartTimeframe}
                setPanelRightExpanded={setPanelRightExpanded}
                pair={pair}
                pairsAll={props.pairsAll}
                exchange={exchange}
                bigChartStrategy={bigChartStrategy}
              />
            </div>
          </div>
          <div id="dragY"></div>
          <div id="panelBottom" className="overflow-auto">
            <ManualTrading
              tradingPair={props.tradingPair}
              setTradingPair={props.setTradingPair}
              selectedAccount={props.selectedAccount}
              setSelectedAccount={props.setSelectedAccount}
            />
          </div>
          <ResizablePanels
            minLeft={485}
            leftPanelSizeDefault={485}
            topPanelSizeDefault={"60vh"}
            panelRightExpanded={panelRightExpanded}
            minTop={180}
            topHeightOnChange={setTopPanelHeight}
            panel={panel}
            storePanel={() => {}}
          />
        </>
      ) : (
        <div className="fade-in py-2">
          <div className="w-100 custom-hr mb-5 pb-5">
            <WatchlistTable
              currentWatchlist={currentWatchlist}
              dropdownHeightSubtract={dropdownHeightSubtract}
              setCurrentWatchlistIdCallback={setCurrentWatchlistIdCallback}
              watchlistPairSearch={watchlistPairSearch}
              setWatchlistPairSearch={setWatchlistPairSearch}
              setCurrentWatchlist={setCurrentWatchlist}
              setWatchlistName={setWatchlistName}
              setEditWatchlist={setEditWatchlist}
              exchanges={exchanges}
              watchlistExchange={watchlistExchange}
              setWatchlistExchangeCallback={setWatchlistExchangeCallback}
              setPairsSortCallback={setPairsSortCallback}
              pairsSort={pairsSort}
              setPairsSort={setPairsSort}
              pairDetailsAll={props.pairDetailsAll}
              setTradingPair={props.setTradingPair}
              tradingPair={props.tradingPair}
            />
          </div>
          <div className="pb-5">
            <BigChart
              dropdownHeightSubtract={dropdownHeightSubtract}
              tradingPair={props.tradingPair}
              setTradingPair={props.setTradingPair}
              showIndicatorsBtn={showIndicatorsBtn}
              setIndicatorsEditList={setIndicatorsEditList}
              indicatorsList={indicatorsList}
              timeframes={timeframes}
              bigChartTimeframe={bigChartTimeframe}
              setBigChartTimeframe={setBigChartTimeframe}
              setPanelRightExpanded={props.setPanelRightExpanded}
              pair={pair}
              pairsAll={props.pairsAll}
              exchange={exchange}
              bigChartStrategy={bigChartStrategy}
            />
          </div>
        </div>
      )}
      <ModalConfirm
        id="setWatchlistName"
        dark
        header="Watchlist Name"
        okText="OK"
        content={
          <div className="overflow-auto d-flex max-h-lists">
            <div className="px-3">Name:</div>
            <div className="flex-grow-1 mr-3">
              <input
                type="text"
                className="text-light border-bottom-light hover-border-bottom-info focus-border-bottom-info w-100"
                value={watchlistName}
                onChange={(e) => {
                  setWatchlistName(e.target.value);
                }}
              />
            </div>
          </div>
        }
        func={() => {
          if (editWatchlist) {
            currentWatchlist.name = watchlistName;
            currentWatchlist.editDate = new Date().getTime();
            setEditWatchlist(false);
            saveWatchlist(currentWatchlist, state.user.token, dispatch);
            setCurrentWatchlist({ ...currentWatchlist });
          } else {
            let id = `${state.user.id}_${new Date().getTime()}`;
            let wl = {
              id: id,
              name: watchlistName,
              pairs: [],
              userId: state.user.id,
              editDate: new Date().getTime(),
            };
            setCurrentWatchlistIdCallback(id);
            saveWatchlist(wl, state.user.token, dispatch);
            setCurrentWatchlist({ ...wl });
          }
          setWatchlistName("");
        }}
      />
      <ModalConfirm
        id="setIndicators"
        dark
        header={
          <div className="text-info">
            <FontAwesomeIcon icon={faChartLine} /> Chart Indicators
          </div>
        }
        okText="OK"
        content={
          <div className="mx-3">
            <div className="mb-2">
              Indicators:
              <DropdownWithSearch
                classesInput="strategy text-left h-33 w-150 px-2 mt-1"
                dropMenuClasses="bg-new-dark border border-secondary rounded small w-220 max-h-180"
                dropMenuItemsClasses="text-light items-light-green"
                placeholder="Add +"
                selected={""}
                setSelected={(indicator) => {
                  let tmpRule = resetIndicatorSetup({}, indicator);
                  indicatorsEditList.forEach((el) => delete el.changeBg);
                  indicatorsEditList.splice(0, 0, { ...tmpRule, changeBg: true, id: new Date().getTime() });
                  setIndicatorsEditList([...indicatorsEditList]);
                }}
                deleteSearchText={true}
                closeOnEsc={true}
                showOnFocus={true}
                onBlur={() => {}}
                items={indicators.filter((el) => {
                  const notUsed = [indicators[0], "the price", "the candlestick", "Manual order"];
                  return !notUsed.includes(el);
                })}
              />
            </div>
            {indicatorsEditList.map((el, index) => {
              return (
                <div key={`${el.id}`} className={`${el.changeBg ? "bg-change rounded" : ""} mt-2`}>
                  <a
                    href="#/"
                    className="text-danger mx-3"
                    title="Remove"
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      indicatorsEditList.splice(index, 1);
                      setIndicatorsEditList([...indicatorsEditList]);
                    }}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </a>
                  <div className="d-inline-block ml-3">
                    <Dropdown
                      btnClasses="mr-2"
                      placeholder="select"
                      selectedClasses="text-light-green hover-light-green"
                      menuClasses="bg-white border border-light-green rounded text-nowrap max-h-180"
                      menuItemsClasses="text-dark border-bottom border-light-green hover-bg-light-green py-1"
                      selected={el.indicator}
                      setSelected={(indicator) => {
                        if (el.indicator !== indicator) {
                          let tmpRule = resetIndicatorSetup({}, indicator);
                          indicatorsEditList[index] = tmpRule;
                          setIndicatorsEditList([...indicatorsEditList]);
                        }
                      }}
                      items={indicators.filter((el) => {
                        const notUsed = ["the price", "the candlestick", "Manual order"];
                        return !notUsed.includes(el);
                      })}
                    />
                    {getIndicatorSetup(el)}
                    {el.indicator !== indicators[0] && (
                      <a
                        href="#/"
                        className="text-light-green ml-2"
                        title="Settings"
                        onClick={(e) => {
                          e.preventDefault();
                          document.activeElement.blur();
                          el.showSettings = el.showSettings ? false : true;
                          setIndicatorsEditList([...indicatorsEditList]);
                        }}
                      >
                        <FontAwesomeIcon icon={faCog} />
                      </a>
                    )}
                  </div>
                  {el.showSettings && (
                    <div className="fade-in small ml-5 mr-2 pl-5 mt-1">
                      <IndicatorSettingsTable
                        rule={el}
                        saveSettings={() => {
                          setIndicatorsEditList([...indicatorsEditList]);
                        }}
                        hide={() => {
                          el.showSettings = el.showSettings ? false : true;
                          setIndicatorsEditList([...indicatorsEditList]);
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        }
        func={() => {
          indicatorsEditList.forEach((el) => {
            delete el.showSettings;
            delete el.changeBg;
            delete el.timeframe;
            delete el.mode;
            delete el.conditionsOrder;
          });
          let newIndicators = indicatorsEditList.filter((el) => !checkInvalidIndicatorSettingsAll(el));
          setIndicatorsList(newIndicators);
          saveIndicators(
            {
              id: state.user.id,
              params: newIndicators,
              editDate: new Date().getTime(),
            },
            state.user.token
          );
        }}
        closeFn={() => {}}
      />
    </div>
  );
};

export default Watchlist;
