import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactEcharts from "echarts-for-react";
import loadingReverced from "./assets/images/loading-reverced.gif";
import { formatDateTime, roundQuotedValue, getTimeframeFromInterval, attachChartData } from "./utils.js";
import getExchange from "./Exchange.js";
import { addIndicatorsData } from "./tatools.js";

function addBlankDate(date, timeframe) {
  let newDate = new Date(date.getTime());
  switch (timeframe) {
    case "1m":
      newDate.setMinutes(newDate.getMinutes() + 1);
      break;
    case "5m":
      newDate.setMinutes(newDate.getMinutes() + 5);
      break;
    case "15m":
      newDate.setMinutes(newDate.getMinutes() + 15);
      break;
    case "30m":
      newDate.setMinutes(newDate.getMinutes() + 30);
      break;
    case "1h":
      newDate.setHours(newDate.getHours() + 1);
      break;
    case "4h":
      newDate.setHours(newDate.getHours() + 4);
      break;
    case "12h":
      newDate.setHours(newDate.getHours() + 12);
      break;
    case "1d":
      newDate.setDate(newDate.getDate() + 1);
      break;
    case "1w":
      newDate.setDate(newDate.getDate() + 7);
      break;
    case "1M":
      newDate.setMonth(newDate.getMonth() + 1);
      break;
    default:
  }
  return newDate;
}

const ChartWithIndicators = (props) => {
  const [chartShow, setChartShow] = useState({ value: false });
  const [reMount, setReMount] = useState({});

  const chartRef = useRef(null);
  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setReMount(null);
  }, [props.pair, props.exchange, props.timeframe, props.trades, props.strategy, props.height, props.tradeToShow]);

  const setChartDataCallback = useCallback(
    (chartData) => {
      if (chartData.length > 0) {
        let seriesData = [];
        let dates = [];
        let startPoint = props.datapointLimit ? chartData.length - props.datapointLimit : 0;

        let startDate = null;

        let stats = { chartData: {}, trades: [] };
        if (props.strategy) {
          let tf = getTimeframeFromInterval(props.timeframe);
          let indicatorsData = {};
          let strategyToUse = {
            ...props.strategy,
            buyRules: props.strategy.buyRules.filter((rule) => rule.timeframe === tf),
            sellRules: props.strategy.sellRules.filter((rule) => rule.timeframe === tf),
          };
          strategyToUse.buyRules = strategyToUse.buyRules.map((rule) => ({ ...rule, mode: "close" }));
          strategyToUse.sellRules = strategyToUse.sellRules.map((rule) => ({ ...rule, mode: "close" }));
          addIndicatorsData(indicatorsData, strategyToUse, { [tf]: chartData });

          if (Object.keys(indicatorsData).length > 0) {
            attachChartData(
              stats,
              chartData,
              indicatorsData,
              startDate,
              `${props.strategy.pair}-${tf}`,
              tf,
              strategyToUse
            );
          }
          if (stats.chartData.candleChart) {
            dates = stats.chartData.candleChart.dates;
          }
        }

        if (dates.length === 0) {
          chartData.forEach((candle, index) => {
            if (index < startPoint) {
              return;
            }
            if (!startDate) {
              startDate = new Date(candle.d);
            }
            dates.push(formatDateTime(new Date(candle.d)));
            seriesData.push([candle.o, candle.c, candle.l, candle.h]);
          });
        }

        let blankDate = new Date(chartData[chartData.length - 1].d);
        for (let i = 0; i < 10; i++) {
          blankDate = addBlankDate(blankDate, props.timeframe);
          dates.push(formatDateTime(blankDate));
        }
        if (componentIsMounted.current) {
          let options = {};
          if (!chartShow.value) {
            let start = Math.max(0, 100 - (45 * 100) / dates.length);
            let end = 100;
            if (props.tradeToShow && props.trades) {
              let startIndex = 0;
              chartData.find((el, i) => {
                startIndex = i;
                return el.d > props.tradeToShow.od;
              });

              startIndex -= 10;
              if (startIndex < 0) {
                startIndex = 0;
              }
              start = Math.max(0, (startIndex / dates.length) * 100);

              if (props.tradeToShow.cd) {
                let endIndex = 0;
                chartData.find((el, i) => {
                  endIndex = i;
                  return el.d > props.tradeToShow.cd;
                });
                endIndex += 10;
                if (endIndex - startIndex < 20) {
                  endIndex = startIndex + 20;
                }
                end = Math.min(100, (endIndex / dates.length) * 100);
              }
            }

            options.dataZoom = [
              {
                type: "inside",
                xAxisIndex: [0],
                start: start,
                end: end,
              },
            ];
            if (props.showZoomSlider) {
              options.dataZoom.push({
                show: true,
                type: "slider",
                xAxisIndex: [0],
                borderColor: "transparent",
                fillerColor: "rgba(23, 162, 184, 0.30)",
                dataBackground: {
                  areaStyle: {
                    color: "#17a2b8",
                  },
                },
                showDetail: false,
                start: start,
                end: end,
              });
            }
          }
          options.series = stats.chartData.candleChart
            ? stats.chartData.candleChart.series
            : [
                {
                  name: `${props.exchange.replace(" ", "").toUpperCase()}-${props.pair}`,
                  type: "candlestick",
                  data: seriesData,
                  itemStyle: {
                    color: "#28a745",
                    borderColor: "#28a745",
                    color0: "#dc3545",
                    borderColor0: "#dc3545",
                  },
                  markPoint: {
                    symbol: "none",
                  },
                },
              ];

          if ((props.trades && props.trades.length > 0) || props.live) {
            options.series[0].markPoint = {
              itemStyle: {
                opacity: 0.7,
              },
              data: [],
              symbolSize: props.markPointBig ? 50 : 30,
              symbol: "pin",
            };
            options.series[0].markLine = {
              symbol: ["circle", "circle"],
              symbolSize: 3,
              lineStyle: {
                type: "dashed",
                width: 1.2,
                color: "#fff",
                opacity: 0.7,
              },
              label: {
                show: false,
              },
              emphasis: {
                label: {
                  show: false,
                },
              },
              data: [],
            };
          }
          if (props.live) {
            options.series[0].markLine.data.push([
              {
                name: chartData[chartData.length - 1].c,
                coord: [formatDateTime(new Date(chartData[chartData.length - 1].d)), chartData[chartData.length - 1].c],
                symbol: "none",
              },
              {
                name: chartData[chartData.length - 1].c,
                coord: [dates[dates.length - 9], chartData[chartData.length - 1].c],
                symbol: "none",
                label: {
                  normal: {
                    show: true,
                    position: "end",
                    color: "#d8f58e",
                    fontSize: 10,
                  },
                },
                lineStyle: {
                  type: "solid",
                  color: "#d8f58e",
                },
              },
            ]);
          }

          if (props.trades && props.trades.length > 0) {
            let index = 0;
            let tradesDates = [];

            props.trades.forEach((el) => {
              let tradeOpenDate = new Date(el.od);
              let tradeCloseDate = el.cd ? new Date(el.cd) : null;
              let xChoordOpen = null;
              let xChoordClose = null;

              for (let i = index; i < chartData.length; i++) {
                let candleStartTime = new Date(chartData[i].d);

                let candleEndTime = new Date(chartData[i].cd);

                if (!xChoordOpen && candleStartTime <= tradeOpenDate && tradeOpenDate <= candleEndTime) {
                  xChoordOpen = formatDateTime(candleStartTime);
                  index = i;
                  if (!tradeCloseDate) {
                    break;
                  }
                }
                if (tradeCloseDate && candleStartTime <= tradeCloseDate && tradeCloseDate <= candleEndTime) {
                  xChoordClose = formatDateTime(candleStartTime);
                  index = i;
                  break;
                }
              }

              tradesDates.push({
                o: tradeOpenDate,
                c: tradeCloseDate ? tradeCloseDate : tradeOpenDate,
              });
              options.series[0].markPoint.data.push({
                trade: el,
                name: "Opened",
                coord: [xChoordOpen, el.o],
                value: props.markPointBig ? "Buy" : "B",
                itemStyle: {
                  color: "#fff",
                  borderColor: "#17a2b8",
                  borderWidth: 1,
                },
                label: {
                  color: "#107281",
                  fontSize: 10,
                },
              });

              if (el.result != null && el.result !== undefined) {
                options.series[0].markPoint.data.push({
                  trade: el,
                  name: "Closed",
                  coord: [xChoordClose, el.c],
                  value: props.markPointBig ? el.result.toFixed(2) + "%" : "S",
                  itemStyle: {
                    color: "#17a2b8",
                    borderColor: "#17a2b8",
                    borderWidth: 1,
                  },
                  label: {
                    color: "#fff",
                    fontSize: 9,
                  },
                });

                options.series[0].markLine.data.push([
                  {
                    name: "Opened",
                    coord: [xChoordOpen, el.o],
                  },
                  {
                    name: "Closed",
                    coord: [xChoordClose, el.c],
                  },
                ]);
              }
            });
          }

          options.xAxis = {
            type: "category",
            data: dates,
            scale: true,
            boundaryGap: false,
            axisLine: {
              onZero: false,
              lineStyle: { color: "transparent", width: "0.5" },
            },
            splitLine: {
              show: true,
              lineStyle: { color: "#17a2b8", width: "0.5" },
            },
            axisLabel: { show: true, color: "#fff", fontSize: 11 },
          };
          options.yAxis = {
            scale: true,
            splitArea: {
              show: false,
            },
            axisLine: { lineStyle: { color: "transparent", width: "0.5" } },
            splitLine: {
              show: true,
              lineStyle: { color: "#17a2b8", width: "0.5" },
            },
            axisLabel: {
              show: true,
              color: "#fff",
              fontSize: 11,
              align: "right",
            },
          };
          options.grid = [
            {
              top: "9px",
              left: "55px",
              height: `${props.height - (props.showZoomSlider ? 76 : 30)}px`,
              right: "6px",
            },
          ];

          if (stats.chartData.candleChart && stats.chartData.candleChart.axisCount > 0) {
            try {
              options.xAxis = [options.xAxis];
              options.yAxis = [options.yAxis];
              options.xAxis[0].axisLabel.show = false;
              let indDif = 20;
              let indHeight = Math.min(60, props.height / 3);
              let totalIndHeight = stats.chartData.candleChart.axisCount * (indHeight + indDif);
              let availableHeight = props.height - (props.showZoomSlider ? 76 : 30);
              let chartHeight = availableHeight - totalIndHeight;

              if (availableHeight / 2 > chartHeight) {
                chartHeight = availableHeight / 2;
                indHeight = availableHeight / 2 / stats.chartData.candleChart.axisCount;

                if (indHeight > 50) {
                  indHeight -= 20;
                } else {
                  let tmp = indHeight;
                  indHeight = tmp * 0.6;
                  indDif = tmp * 0.4;
                }
              }
              options.grid = [
                {
                  top: "9px",
                  height: `${chartHeight}px`,
                  left: "55px",
                  right: "6px",
                },
              ];
              for (let index = 1; index <= stats.chartData.candleChart.axisCount; index++) {
                if (options.dataZoom && !chartShow.value) {
                  options.dataZoom[0].xAxisIndex.push(index);
                  if (props.showZoomSlider) {
                    options.dataZoom[1].xAxisIndex.push(index);
                  }
                }

                options.xAxis.push({
                  type: "category",
                  gridIndex: index,
                  data: dates,
                  scale: true,
                  boundaryGap: false,
                  splitNumber: 20,
                  axisLine: {
                    onZero: false,
                    lineStyle: { color: "transparent", width: "0.5" },
                  },
                  splitLine: {
                    show: true,
                    lineStyle: { color: "#17a2b8", width: "0.5" },
                  },
                  axisLabel: {
                    show: stats.chartData.candleChart.axisCount === index,
                    color: "#fff",
                    fontSize: 11,
                  },
                });

                let serie = stats.chartData.candleChart.series.find((el) => el.xAxisIndex === index);
                options.yAxis.push({
                  name: serie.name,
                  nameTextStyle: {
                    align: "left",
                    verticalAlign: "top",
                    color: "#fff",
                    fontSize: 11,
                  },
                  scale: true,
                  gridIndex: index,
                  splitNumber: indHeight > 40 ? 2 : 1,
                  splitArea: {
                    show: false,
                  },
                  axisLine: {
                    lineStyle: { color: "transparent", width: "0.5" },
                  },
                  splitLine: {
                    show: true,
                    lineStyle: { color: "#17a2b8", width: "0.5" },
                  },
                  axisLabel: {
                    show: true,
                    color: "#fff",
                    fontSize: 11,
                    align: "right",
                  },
                });

                options.grid.push({
                  top: `${9 + chartHeight + indHeight * (index - 1) + indDif * index}px`,
                  height: `${indHeight}px`,
                  left: "55px",
                  right: "6px",
                });
              }
            } catch (e) {}
          }

          options.tooltip = {
            backgroundColor: "#343a40",
            borderColor: "#17a2b8",
            borderWidth: 1,
            axisPointer: {
              type: "cross",
              label: { color: "#fff", backgroundColor: "#343a40" },
            },
            textStyle: {
              align: "left",
              fontSize: 12,
            },
            trigger: "axis",
          };

          options.tooltip.formatter = (data) => {
            let mainSeries = "";

            let candle = options.series[0].data[data[0].dataIndex];
            let prevCandle = options.series[0].data[data[0].dataIndex - 1];
            if (data && data[0] && candle) {
              mainSeries = `${options.series[0].name} - ${data[0].name}`;
              let move = candle[1] - candle[0];
              let moveColorClass = move > 0 ? "text-success-light" : move < 0 ? "text-danger-light" : "";
              let moveLh = candle[3] - candle[2];
              let movePrevClose = prevCandle ? candle[1] - prevCandle[1] : 0;

              mainSeries += `<br />O: <span class="text-info-light">${
                candle[0]
              }</span> H: <span class="text-info-light">${candle[3]}</span>
          L: <span class="text-info-light">${candle[2]}</span> C: <span class="text-info-light">${candle[1]}</span> ${
                movePrevClose === 0
                  ? "0%"
                  : `
        <span class="${movePrevClose > 0 ? "text-success-light" : "text-danger-light"}">(${
                      candle[0] !== 0 ? ((movePrevClose / prevCandle[1]) * 100).toFixed(2) : "0"
                    }%)</span>`
              } <br />Open to Close: ${
                move === 0
                  ? "0%"
                  : `
        <span class="${moveColorClass}">${roundQuotedValue(move, props.pairDetails)} (${
                      candle[0] !== 0 ? ((move / candle[0]) * 100).toFixed(2) : "0"
                    }%)</span>`
              }<br />Low to High: ${
                moveLh === 0
                  ? "0%"
                  : `
        <span class="text-success-light">${roundQuotedValue(moveLh, props.pairDetails)} (${
                      candle[2] !== 0 ? ((moveLh / candle[2]) * 100).toFixed(2) : "0"
                    }%)</span>`
              }
        
        
        `;

              let indicators = "";
              if (options.series.length > 0) {
                for (let i = 1; i < options.series.length; i++) {
                  let value = options.series[i].data[data[0].dataIndex];
                  if (value !== null && value !== undefined) {
                    if (options.series[i].type === "candlestick") {
                      indicators += `<br /> ${options.series[i].name}: O: <span class="text-info-light">${value[0]}</span> H: <span class="text-info-light">${value[3]}</span>
                L: <span class="text-info-light">${value[2]}</span> C: <span class="text-info-light">${value[1]}</span>`;
                    }

                    indicators += `<br /><span class="rounded-circle" style="background-color:${options.series[i].color}">&nbsp&nbsp&nbsp&nbsp&nbsp</span>`;
                    if (options.series[i].name.startsWith("MomentumSqueeze-Status")) {
                      indicators += ` ${options.series[i].name}: <span class="text-info-light">${
                        value.valueOrg ? "in a squeeze" : "out of a squeeze"
                      }</span>`;
                    } else if (value.value) {
                      indicators += ` ${options.series[i].name}: <span class="text-info-light">${value.value}</span>`;
                    } else {
                      indicators += ` ${options.series[i].name}: <span class="text-info-light">${value}</span>`;
                    }
                  }
                }
              }

              let tradeData = "";
              if (options.series[0].markPoint && options.series[0].markPoint.data) {
                let trades = options.series[0].markPoint.data.filter((el) => el.coord[0] === data[0].name);
                trades.forEach((trade) => {
                  if (trade.name === "Opened") {
                    tradeData += `<br />Open trade at <span class="text-info-light">${
                      trade.trade.o
                    }</span> on <span class="text-info-light">${formatDateTime(trade.trade.od)}</span>`;
                  } else if (trade.name === "Closed") {
                    tradeData += `<br />Close trade at <span class="text-info-light">${
                      trade.trade.c
                    }</span> on <span class="text-info-light">${formatDateTime(trade.trade.cd)}</span> <span class="${
                      trade.trade.result > 0 ? "text-success-light" : trade.trade.result < 0 ? "text-danger-light" : ""
                    }">(${trade.trade.result.toFixed(2)}%)</span>`;
                  }
                });
              }

              return `<div class="small">${mainSeries}${indicators}${tradeData}</div>`;
            }
            return "";
          };

          /*options.legend = {
            data: options.series.map((el) => el.name),
            textStyle: { color: "#fff", fontSize: 11 },
            icon: "pin",
          };*/

          if (chartRef.current && componentIsMounted.current) {
            try {
              chartRef.current.getEchartsInstance().setOption(options);
              if (!chartShow.value) {
                setChartShow({ value: true });
              }
            } catch (e) {}
          }
        }
      }
    },
    [chartShow, reMount]
  );

  useEffect(() => {
    props.historicalDataProviderFn(setChartDataCallback);
    return () => {
      if (props.historicalDataCleanUpFn) {
        props.historicalDataCleanUpFn();
      }
    };
  }, [setChartDataCallback, props.historicalDataProviderFn, props.historicalDataCleanUpFn]);

  useEffect(() => {
    if (!reMount) {
      setReMount({});
      setChartShow({ value: false });
    }
  }, [reMount]);

  return reMount ? (
    <>
      <div id={props.id} className={chartShow.value ? "" : "d-none"}>
        <ReactEcharts
          ref={chartRef}
          lazyUpdate={false}
          notMerge={false}
          style={{
            height: `${props.height}px`,
            left: 0,
            top: 0,
            right: 0,
            width: "100%",
          }}
          opts={{ renderer: "svg" }}
          className="bg-new-dark"
          option={{
            replaceMerge: ["xAxis", "yAxis", "series", "dataZoom", "grid"],
            animation: false,
            title:
              props.live && props.showZoomSlider
                ? {
                    text: "Live",
                    textStyle: {
                      color: "#c8e381",
                      fontSize: 13,
                    },
                    bottom: 0,
                    left: 10,
                  }
                : {},
            tooltip: {
              backgroundColor: "#343a40",
              borderColor: "#17a2b8",
              borderWidth: 1,
              axisPointer: {
                type: "cross",
                label: { color: "#fff", backgroundColor: "#343a40" },
              },
              textStyle: {
                align: "left",
                fontSize: 12,
              },
              trigger: "axis",
            },

            axisPointer: {
              link: { xAxisIndex: "all" },
            },
            grid: [
              {
                top: "9px",
                left: "55px",
                height: `${props.height - (props.showZoomSlider ? 76 : 30)}px`,
                right: "6px",
              },
            ],
            xAxis: {},
            yAxis: {},
            series: [],
          }}
        />
      </div>
      <div className={chartShow.value ? "d-none" : "text-center mx-auto"}>
        <div className="loading-white cursor-help my-5" title="Loading..">
          <img src={loadingReverced} alt="" />
        </div>
      </div>
    </>
  ) : (
    <div className={chartShow.value ? "d-none" : "text-center mx-auto"}>
      <div className="loading-white cursor-help my-5" title="Loading..">
        <img src={loadingReverced} alt="" />
      </div>
    </div>
  );
};

const LiveChartWithIndicators = (props) => {
  /*
  useEffect(()=>{
    console.log("strategy")
  },[props.strategy])
  useEffect(()=>{
    console.log("pair")
  },[props.pair])
  useEffect(()=>{
    console.log("exchange")
  },[props.exchange])
  useEffect(()=>{
    console.log("timeframe")
  },[props.timeframe])
  useEffect(()=>{
    console.log("id")
  },[props.id])
  useEffect(()=>{
    console.log("datapointLimit")
  },[props.datapointLimit])
  useEffect(()=>{
    console.log("showZoomSlider")
  },[props.showZoomSlider])
  useEffect(()=>{
    console.log("height")
  },[props.height])
  useEffect(()=>{
    console.log("trades")
  },[props.trades])
*/

  const historicalDataProviderFn = useCallback(
    (fn) => {
      getExchange(props.exchange).subscribeToChartUpdate(
        props.id,
        props.pair,
        props.timeframe,
        fn,
        props.type,
        props.startDate
      );
    },
    [props.id, props.pair, props.timeframe, props.type, props.startDate]
  );

  const historicalDataCleanUpFn = useCallback(() => {
    getExchange("Binance").unsubscribeToChartUpdate(props.id);
    getExchange("Binance US").unsubscribeToChartUpdate(props.id);
  }, [props.id]);

  return (
    <ChartWithIndicators
      strategy={props.strategy}
      pair={props.pair}
      exchange={props.exchange}
      timeframe={props.timeframe}
      id={props.id}
      datapointLimit={props.datapointLimit}
      showZoomSlider={props.showZoomSlider}
      height={props.height}
      trades={props.trades}
      historicalDataProviderFn={historicalDataProviderFn}
      historicalDataCleanUpFn={historicalDataCleanUpFn}
      markPointBig={props.markPointBig}
      live={true}
    />
  );
};

const StaticChartWithIndicators = (props) => {
  const historicalDataProviderFn = useCallback(
    (fn) => {
      if (props.historicalData) {
        fn(props.historicalData);
      } else {
        fn([]);
      }
    },
    [props.id, props.pair, props.timeframe, props.type, props.startDate, props.historicalData, props.tradeToShow]
  );

  return (
    <ChartWithIndicators
      strategy={props.strategy}
      pair={props.pair}
      exchange={props.exchange}
      timeframe={props.timeframe}
      id={props.id}
      datapointLimit={props.datapointLimit}
      showZoomSlider={props.showZoomSlider}
      height={props.height}
      trades={props.trades}
      historicalDataProviderFn={historicalDataProviderFn}
      markPointBig={props.markPointBig}
      tradeToShow={props.tradeToShow}
    />
  );
};

export { StaticChartWithIndicators, LiveChartWithIndicators };
