import React, { useEffect } from "react";
import $ from "jquery";

const Modal = (props) => {
  useEffect(() => {
    if (props.closeFn) {
      $(`#${props.id}`).on("hidden.bs.modal", function () {
        props.closeFn();
      });
    }
    return () => {
      if ($(`#${props.id}`).is(":visible")) {
        $(`#${props.id}`).modal("hide");
      }
    };
  }, []);

  let buttonOkText = props.okText ? props.okText : "OK";
  let cancelBtn = props.cancelText && (
    <button
      type="button"
      className={`btn btn-sm ${props.dark ? "btn-new-dark" : "btn-secondary"} col-4 col-sm-2`}
      onClick={() => {
        $(`#${props.id}`).modal("hide");
      }}
    >
      {props.cancelText}
    </button>
  );
  let content = props.content && (
    <div className={"modal-body text-left " + (props.dark && props.large ? "px-md-5" : "")}>
      <div className={props.dark ? " bg-new-dark text-white py-4 intro rounded" : ""}>{props.content}</div>
    </div>
  );
  return (
    <div
      className="modal fade user-select-none"
      id={props.id}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
      onKeyDown={(e) => {
        let keycode = e.keyCode ? e.keyCode : e.which;
        if (props.closeOnEnter && keycode === 13) {
          $(`#${props.id}`).modal("hide");
          if (props.func !== undefined) {
            props.func();
          }
        }
      }}
    >
      <div
        className={`modal-dialog ${props.large ? "modal-large" : ""}  ${props.center ? "modal-dialog-centered" : ""}`}
        role="document"
      >
        <div className="modal-content modal-content-slide-down">
          <div className="modal-header">
            {props.large ? (
              <h1 className="h4 modal-title text-center w-100 m-auto" id="modalLabel">
                {props.header}
              </h1>
            ) : (
              <h1 className="h4 modal-title" id="modalLabel">
                {props.header}
              </h1>
            )}

            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => {
                $(`#${props.id}`).modal("hide");
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {content}
          <div className="modal-footer">
            <button
              type="button"
              className={`btn btn-sm ${props.dark ? "btn-new-dark" : "btn-secondary"}  col-4 col-sm-2 ${
                props.okDisabled ? "disabled" : ""
              }`}
              disabled={props.okDisabled ? "disabled" : ""}
              onClick={() => {
                $(`#${props.id}`).modal("hide");
                if (props.func !== undefined) {
                  props.func();
                }
              }}
            >
              {buttonOkText}
            </button>
            {cancelBtn}
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalConfirm = (props) => {
  return (
    <Modal
      id={props.id}
      header={props.header}
      content={props.content}
      okText={props.okText ? props.okText : "YES"}
      okDisabled={props.okDisabled}
      cancelText="CANCEL"
      func={props.func}
      large={props.large}
      dark={props.dark}
      closeFn={props.closeFn}
    />
  );
};

export default Modal;
export { Modal, ModalConfirm };
