import { createContext } from "react";
import {
  ss,
  deleteApiKey,
  encrypt,
  addItemToArrayInLocalStore,
  removeItemFromArrayInLocalStore,
  sendStrategy,
  sendRemoveStrategiesNotSynced,
  comparePairDetails,
  formatDate2,
  removeTrades,
  removeEvents,
  sendStrategyStatus,
  sendRemoveStrategyStatus,
  sendNewOptimization,
  sleep,
  generateUniqueId,
  roundQuotedValue,
} from "./utils.js";
import getEcbDatabase from "./EcbDatabase.js";
import { ensureDate } from "./tatools.js";
import {
  updateExchangeApiKeys,
  stopBacktest,
  stopOptimization,
  stopTradingWorkers,
  stopTrading,
  updateStrategy,
} from "./Bot.js";
import infoSound from "./assets/sounds/info.mp3";
import { checkUserExpire } from "./SignIn.js";
/* eslint-disable no-unused-expressions */
/* eslint-disable no-func-assign */
/* eslint-disable no-sequences */
const a0_0x29f9ed = a0_0x3d33;
(function (_0x36d718, _0x1d4d95) {
  const _0x3fe6d6 = a0_0x3d33,
    _0x4e87a4 = _0x36d718();
  while (!![]) {
    try {
      const _0x2777e6 =
        (parseInt(_0x3fe6d6(0x138)) / 0x1) *
          (parseInt(_0x3fe6d6(0x19a)) / 0x2) +
        parseInt(_0x3fe6d6(0x17c)) / 0x3 +
        (parseInt(_0x3fe6d6(0x18b)) / 0x4) *
          (parseInt(_0x3fe6d6(0x19b)) / 0x5) +
        -parseInt(_0x3fe6d6(0x15f)) / 0x6 +
        -parseInt(_0x3fe6d6(0x152)) / 0x7 +
        (parseInt(_0x3fe6d6(0x18f)) / 0x8) *
          (-parseInt(_0x3fe6d6(0x1af)) / 0x9) +
        parseInt(_0x3fe6d6(0x1c5)) / 0xa;
      if (_0x2777e6 === _0x1d4d95) break;
      else _0x4e87a4["push"](_0x4e87a4["shift"]());
    } catch (_0x31c56d) {
      _0x4e87a4["push"](_0x4e87a4["shift"]());
    }
  }
})(a0_0x40c6, 0xb537a);
function getInitialDateFrom() {
  const _0x3ad181 = a0_0x3d33;
  let _0x28873a = new Date();
  return (
    _0x28873a[_0x3ad181(0x1d8)](_0x28873a[_0x3ad181(0x19e)]() - 0x1),
    _0x28873a["setHours"](0x0, 0x0, 0x0, 0x0),
    _0x28873a
  );
}
function getInitialDateTo() {
  const _0x3048de = a0_0x3d33;
  let _0x2d784a = new Date();
  return _0x2d784a[_0x3048de(0x1be)](0x0, 0x0, 0x0, 0x0), _0x2d784a;
}
let initialState = {
    user: {
      token: "",
      id: "",
      name: "",
      email: "",
      expireDate: "",
      isExpired: ![],
      refCode: "",
      subscriptionType: "",
    },
    app: null,
    demo: ![],
    strategies: [],
    simStatuses: {},
    liveStatuses: {},
    simStatusesInitialized: ![],
    liveStatusesInitialized: ![],
    strategiesInitialized: ![],
    exchanges: {},
    assets: {},
    exchangesInitialized: ![],
    confirmDialog: { header: "", content: "", func: null },
    infoDialog: { header: "", content: "" },
    version: a0_0x29f9ed(0x162),
    build: "100943",
    serverVersion: null,
    contests: [],
    selectedContest: {
      month: "",
      submit: "",
      start: "",
      end: "",
      pair: "",
      prizes: [],
      executions: null,
      page: 0x0,
      total: 0x0,
      loading: !![],
      lastUpdated: null,
    },
    nextContest: {
      month: "",
      submit: "",
      start: "",
      end: "",
      pair: "",
      maxStr: "",
      prizes: [],
    },
    executionsLastUpdate: null,
    orderId: null,
    subscriptionIsExpiring: ![],
    tradeInfoSound: 0x0,
    manualTradeConfirm: 0x1,
    editEmail: ![],
    users: [],
    reportedBugs: [],
    login: () => console[a0_0x29f9ed(0x1e3)](a0_0x29f9ed(0x175)),
    logout: async function () {
      const _0x349d0e = a0_0x29f9ed;
      stopTradingWorkers(),
        localStorage[_0x349d0e(0x16a)](_0x349d0e(0x191)),
        !this["app"]
          ? window["location"][_0x349d0e(0x174)](_0x349d0e(0x1ae))
          : navigator[_0x349d0e(0x178)]
          ? navigator[_0x349d0e(0x178)]
              [_0x349d0e(0x165)]()
              [_0x349d0e(0x161)]((_0x5c6c40) => {
                const _0x4aab65 = _0x349d0e;
                for (let _0x241827 of _0x5c6c40) {
                  _0x241827[_0x4aab65(0x1dd)]();
                }
                window[_0x4aab65(0x160)][_0x4aab65(0x198)]();
              })
          : window[_0x349d0e(0x160)]["reload"]();
    },
    watchlists: [
      { id: a0_0x29f9ed(0x13f), name: a0_0x29f9ed(0x1c1), isDefault: !![] },
      { id: a0_0x29f9ed(0x1c8), name: "Top\x20Losers", isDefault: !![] },
    ],
    multiCharts: [],
    strategyGroups: [
      {
        id: a0_0x29f9ed(0x13f),
        name: a0_0x29f9ed(0x15e),
        strategies: [],
        isDefault: !![],
      },
      {
        id: a0_0x29f9ed(0x1c8),
        name: a0_0x29f9ed(0x13e),
        strategies: [],
        isDefault: !![],
      },
      {
        id: a0_0x29f9ed(0x1e1),
        name: a0_0x29f9ed(0x1e8),
        strategies: [],
        isDefault: !![],
      },
      {
        id: "0_03",
        name: "Recently\x20Optimized",
        strategies: [],
        isDefault: !![],
      },
    ],
    commissionRate: 0.075,
    dateTo: getInitialDateTo(),
    dateFrom: getInitialDateFrom(),
    btSize: { type: a0_0x29f9ed(0x16b), value: "" },
    optAdditionalOptions: {
      minTrades: 0x1,
      changeStoploss: ![],
      changeSlTimer: ![],
      changeTBuy: ![],
      changeTBuyWait: ![],
      changeTarget: ![],
      changeTrailingTarget: ![],
      changeTimeClose: ![],
      changeCondition: ![],
      changeSlCooldown: ![],
      changeWCooldown: ![],
      stoplossMin: 0.25,
      stoplossMax: 0xa,
      slTimerMin: 0x1,
      slTimerMax: 0xa,
      slCooldownMin: 0x0,
      slCooldownMax: 0x3c,
      wCooldownMin: 0x0,
      wCooldownMax: 0x3c,
      targetMin: 0.25,
      targetMax: 0xa,
      tTargetMin: 0.25,
      tTargetMax: 0xa,
      timeCloseMin: 0x1,
      timeCloseMax: 0x18,
      tBuyMin: 0.5,
      tBuyMax: 0x3,
      tBuyWaitMin: 0x2,
      tBuyWaitMax: 0xa,
    },
    tradeSize: { type: a0_0x29f9ed(0x16b), value: "", pair: "" },
    adminGetUser: "",
    setupHidden: ![],
    watchlistPanelRightExpanded: ![],
    watchlistTradingPanelBottomExpanded: ![],
    locked: ![],
    trafic: a0_0x29f9ed(0x15b),
  },
  globalContext = createContext(initialState),
  globalContextReducer = (_0x567279, _0x270eaa) => {
    const _0xe0ae1e = a0_0x29f9ed;
    switch (_0x270eaa[_0xe0ae1e(0x17f)]) {
      case _0xe0ae1e(0x1c0):
        return { ..._0x567279, trafic: _0x270eaa[_0xe0ae1e(0x1e7)] };
      case _0xe0ae1e(0x140):
        return { ..._0x567279, locked: _0x270eaa[_0xe0ae1e(0x1e7)] };
      case _0xe0ae1e(0x19f):
        let _0x10cebf = _0x567279[_0xe0ae1e(0x17d)][_0xe0ae1e(0x130)](
          (_0x2f1729) => _0x2f1729["id"] === _0x270eaa[_0xe0ae1e(0x1e7)]["id"]
        );
        if (_0x10cebf) {
          let _0x341194 =
            _0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x1b2)] === _0xe0ae1e(0x190)
              ? _0x567279[_0xe0ae1e(0x1b0)]
              : _0x567279[_0xe0ae1e(0x1b9)];
          _0x341194[_0x10cebf["id"]] &&
            _0x341194[_0x10cebf["id"]][_0xe0ae1e(0x156)] === _0xe0ae1e(0x1ec) &&
            stopTrading(
              _0x10cebf,
              _0x270eaa[_0xe0ae1e(0x1e7)]["stopType"],
              _0x270eaa[_0xe0ae1e(0x1e7)]["dispath"],
              _0x567279["demo"]
            );
        }
        return _0x567279;
      case _0xe0ae1e(0x13a):
        sleep(0x1f4)[_0xe0ae1e(0x161)](() => _0x567279["logout"]());
        return _0x567279;
      case _0xe0ae1e(0x14a):
        return {
          ..._0x567279,
          watchlistPanelRightExpanded: _0x270eaa[_0xe0ae1e(0x1e7)],
        };
      case _0xe0ae1e(0x154):
        return {
          ..._0x567279,
          watchlistTradingPanelBottomExpanded: _0x270eaa[_0xe0ae1e(0x1e7)],
        };
      case _0xe0ae1e(0x177):
        return { ..._0x567279, setupHidden: _0x270eaa[_0xe0ae1e(0x1e7)] };
      case _0xe0ae1e(0x196):
        return { ..._0x567279, adminGetUser: _0x270eaa[_0xe0ae1e(0x1e7)] };
      case _0xe0ae1e(0x1d7):
        return {
          ..._0x567279,
          optAdditionalOptions: _0x270eaa[_0xe0ae1e(0x1e7)],
        };
      case "multiChart":
        let _0x1f509a = _0x567279["multiCharts"][_0xe0ae1e(0x132)](
          (_0x22ed97) => _0x22ed97["id"] !== _0x270eaa[_0xe0ae1e(0x1e7)]["id"]
        );
        _0x1f509a["push"](_0x270eaa[_0xe0ae1e(0x1e7)]),
          _0x1f509a["sort"]((_0x1e644b, _0x4ddb43) => {
            const _0x1f3ba4 = _0xe0ae1e;
            return _0x1e644b[_0x1f3ba4(0x19d)] || _0x4ddb43[_0x1f3ba4(0x19d)]
              ? _0x1e644b["id"][_0x1f3ba4(0x183)](_0x4ddb43["id"])
              : _0x1e644b["name"][_0x1f3ba4(0x183)](
                  _0x4ddb43[_0x1f3ba4(0x14e)]
                );
          });
        return { ..._0x567279, multiCharts: _0x1f509a };
      case "multiCharts":
        return {
          ..._0x567279,
          multiCharts: [..._0x270eaa["payload"]],
          multiChartsInitialized: !![],
        };
      case "multiChartRemove":
        let _0x203ae1 = _0x567279[_0xe0ae1e(0x1cf)][_0xe0ae1e(0x132)](
          (_0x8c39e2) => _0x8c39e2["id"] !== _0x270eaa[_0xe0ae1e(0x1e7)]
        );
        _0x203ae1["length"] === 0x0 &&
          _0x203ae1[_0xe0ae1e(0x164)]({
            id:
              _0x567279[_0xe0ae1e(0x1c7)]["id"] +
              "_" +
              new Date()[_0xe0ae1e(0x1bb)](),
            userId: _0x567279[_0xe0ae1e(0x1c7)]["id"],
            name: _0xe0ae1e(0x176),
            charts: [],
            editDate: new Date()["getTime"](),
          });
        return { ..._0x567279, multiCharts: _0x203ae1 };
      case "watchlistRemove":
        let _0x28e3d3 = _0x567279[_0xe0ae1e(0x180)]["filter"](
          (_0x1c6175) => _0x1c6175["id"] !== _0x270eaa[_0xe0ae1e(0x1e7)]
        );
        return { ..._0x567279, watchlists: _0x28e3d3 };
      case _0xe0ae1e(0x1dc):
        let _0x1d5ebb = _0x567279[_0xe0ae1e(0x180)][_0xe0ae1e(0x132)](
          (_0x3fe2e7) => _0x3fe2e7["id"] !== _0x270eaa[_0xe0ae1e(0x1e7)]["id"]
        );
        _0x1d5ebb["push"](_0x270eaa[_0xe0ae1e(0x1e7)]),
          _0x1d5ebb[_0xe0ae1e(0x153)]((_0x1cafa0, _0x13ee2a) => {
            const _0x358d8f = _0xe0ae1e;
            return _0x1cafa0["isDefault"] || _0x13ee2a["isDefault"]
              ? _0x1cafa0["id"][_0x358d8f(0x183)](_0x13ee2a["id"])
              : _0x1cafa0[_0x358d8f(0x14e)][_0x358d8f(0x183)](
                  _0x13ee2a[_0x358d8f(0x14e)]
                );
          });
        return { ..._0x567279, watchlists: _0x1d5ebb };
      case _0xe0ae1e(0x180):
        let _0x2a3565 = [..._0x567279[_0xe0ae1e(0x180)]][_0xe0ae1e(0x155)](
          _0x270eaa[_0xe0ae1e(0x1e7)]
        );
        _0x2a3565[_0xe0ae1e(0x153)]((_0x402e27, _0x4041ba) => {
          const _0x422c2c = _0xe0ae1e;
          return _0x402e27[_0x422c2c(0x19d)] || _0x4041ba["isDefault"]
            ? _0x402e27["id"][_0x422c2c(0x183)](_0x4041ba["id"])
            : _0x402e27[_0x422c2c(0x14e)]["localeCompare"](
                _0x4041ba[_0x422c2c(0x14e)]
              );
        });
        return {
          ..._0x567279,
          watchlists: _0x2a3565,
          watchlistsInitialized: !![],
        };
      case _0xe0ae1e(0x1b3):
        let _0x3a39c9 = _0x567279[_0xe0ae1e(0x1c9)][_0xe0ae1e(0x132)](
          (_0xec0a1f) => _0xec0a1f["id"] !== _0x270eaa["payload"]
        );
        return { ..._0x567279, strategyGroups: _0x3a39c9 };
      case "strategyGroup":
        let _0x2b9c8c = _0x567279["strategyGroups"][_0xe0ae1e(0x132)](
          (_0x4ffb09) => _0x4ffb09["id"] !== _0x270eaa[_0xe0ae1e(0x1e7)]["id"]
        );
        _0x2b9c8c["push"](_0x270eaa[_0xe0ae1e(0x1e7)]),
          _0x2b9c8c["sort"]((_0x5308ce, _0x5c2adc) => {
            const _0x3cac89 = _0xe0ae1e;
            return _0x5308ce["isDefault"] || _0x5c2adc[_0x3cac89(0x19d)]
              ? _0x5308ce["id"][_0x3cac89(0x183)](_0x5c2adc["id"])
              : _0x5308ce[_0x3cac89(0x14e)][_0x3cac89(0x183)](
                  _0x5c2adc[_0x3cac89(0x14e)]
                );
          });
        return { ..._0x567279, strategyGroups: _0x2b9c8c };
      case "strategyGroups":
        let _0x23b256 = [..._0x567279[_0xe0ae1e(0x1c9)]][_0xe0ae1e(0x155)](
          _0x270eaa[_0xe0ae1e(0x1e7)]
        );
        _0x23b256[_0xe0ae1e(0x153)]((_0x16305c, _0x5dea6e) => {
          const _0x3f4171 = _0xe0ae1e;
          return _0x16305c["isDefault"] || _0x5dea6e[_0x3f4171(0x19d)]
            ? _0x16305c["id"][_0x3f4171(0x183)](_0x5dea6e["id"])
            : _0x16305c[_0x3f4171(0x14e)][_0x3f4171(0x183)](
                _0x5dea6e[_0x3f4171(0x14e)]
              );
        });
        return {
          ..._0x567279,
          strategyGroups: _0x23b256,
          strategyGroupsInitialized: !![],
        };
      case _0xe0ae1e(0x1cd):
        let _0x46b355 = -0x1;
        _0x567279["strategyGroups"][0x2][_0xe0ae1e(0x17d)][_0xe0ae1e(0x130)](
          (_0x24bcee, _0x29104d) => {
            return (_0x46b355 = _0x29104d), _0x24bcee === _0x270eaa["payload"];
          }
        ) &&
          _0x567279[_0xe0ae1e(0x1c9)][0x2]["strategies"][_0xe0ae1e(0x1d6)](
            _0x46b355,
            0x1
          );
        _0x567279[_0xe0ae1e(0x1c9)][0x2]["strategies"][_0xe0ae1e(0x164)](
          _0x270eaa[_0xe0ae1e(0x1e7)]
        );
        _0x567279[_0xe0ae1e(0x1c9)][0x2][_0xe0ae1e(0x17d)][_0xe0ae1e(0x189)] >
          0xf &&
          _0x567279["strategyGroups"][0x2][_0xe0ae1e(0x17d)][_0xe0ae1e(0x1d6)](
            0x0,
            0x1
          );
        return {
          ..._0x567279,
          strategyGroups: [..._0x567279[_0xe0ae1e(0x1c9)]],
        };
      case _0xe0ae1e(0x144):
        _0x567279[_0xe0ae1e(0x1c9)][0x2][_0xe0ae1e(0x17d)] = _0x567279[
          _0xe0ae1e(0x1c9)
        ][0x2]["strategies"][_0xe0ae1e(0x132)](
          (_0x55caa7) => _0x55caa7 !== _0x270eaa[_0xe0ae1e(0x1e7)]
        );
        return {
          ..._0x567279,
          strategyGroups: [..._0x567279[_0xe0ae1e(0x1c9)]],
        };
      case _0xe0ae1e(0x1eb):
        let _0x42fe4e = -0x1;
        _0x567279[_0xe0ae1e(0x1c9)][0x3][_0xe0ae1e(0x17d)]["find"](
          (_0x291686, _0x2808da) => {
            return (_0x42fe4e = _0x2808da), _0x291686 === _0x270eaa["payload"];
          }
        ) &&
          _0x567279[_0xe0ae1e(0x1c9)][0x3][_0xe0ae1e(0x17d)][_0xe0ae1e(0x1d6)](
            _0x42fe4e,
            0x1
          );
        _0x567279[_0xe0ae1e(0x1c9)][0x3][_0xe0ae1e(0x17d)][_0xe0ae1e(0x164)](
          _0x270eaa[_0xe0ae1e(0x1e7)]
        );
        _0x567279[_0xe0ae1e(0x1c9)][0x3][_0xe0ae1e(0x17d)]["length"] > 0xf &&
          _0x567279[_0xe0ae1e(0x1c9)][0x3][_0xe0ae1e(0x17d)][_0xe0ae1e(0x1d6)](
            0x0,
            0x1
          );
        return {
          ..._0x567279,
          strategyGroups: [..._0x567279[_0xe0ae1e(0x1c9)]],
        };
      case _0xe0ae1e(0x1b1):
        _0x567279[_0xe0ae1e(0x1c9)][0x3][_0xe0ae1e(0x17d)] = _0x567279[
          _0xe0ae1e(0x1c9)
        ][0x3][_0xe0ae1e(0x17d)][_0xe0ae1e(0x132)](
          (_0x41c42a) => _0x41c42a !== _0x270eaa["payload"]
        );
        return {
          ..._0x567279,
          strategyGroups: [..._0x567279[_0xe0ae1e(0x1c9)]],
        };
      case _0xe0ae1e(0x145):
        return { ..._0x567279, orderId: _0x270eaa[_0xe0ae1e(0x1e7)] };
      case _0xe0ae1e(0x198):
        navigator[_0xe0ae1e(0x178)]
          ? navigator[_0xe0ae1e(0x178)]
              [_0xe0ae1e(0x165)]()
              ["then"]((_0x1ba1a5) => {
                const _0x2ca692 = _0xe0ae1e;
                for (let _0xd7ed40 of _0x1ba1a5) {
                  _0xd7ed40[_0x2ca692(0x1dd)]();
                }
                window[_0x2ca692(0x160)][_0x2ca692(0x198)]();
              })
          : window[_0xe0ae1e(0x160)]["reload"]();
        return _0x567279;
      case _0xe0ae1e(0x175):
        return { ..._0x567279, login: _0x270eaa[_0xe0ae1e(0x1e7)] };
      case _0xe0ae1e(0x179):
        return {
          ..._0x567279,
          serverVersion: _0x270eaa[_0xe0ae1e(0x1e7)]["version"],
        };
      case _0xe0ae1e(0x184):
        return { ..._0x567279, editEmail: _0x270eaa[_0xe0ae1e(0x1e7)] };
      case _0xe0ae1e(0x1e6):
        let _0xd11beb = _0x270eaa[_0xe0ae1e(0x1e7)];
        _0xd11beb["sort"](
          (_0x31fe0c, _0x360ca1) =>
            _0x360ca1["creationDate"] - _0x31fe0c[_0xe0ae1e(0x12c)]
        );
        !_0x567279["strategyGroups"][0x4] &&
          _0x567279["strategyGroups"][_0xe0ae1e(0x164)]({
            id: _0xe0ae1e(0x16d),
            name: _0xe0ae1e(0x1e4),
            strategies: [],
          });
        let _0x268a78 = [],
          _0x54ac28 = {};
        (_0x567279[_0xe0ae1e(0x1c9)][0x4][_0xe0ae1e(0x17d)] = []),
          _0xd11beb["forEach"]((_0x5163cf, _0x32e194) => {
            const _0x5e5ceb = _0xe0ae1e;
            _0x5163cf[_0x5e5ceb(0x195)][_0x5e5ceb(0x1c6)](
              (_0xdbca74, _0x3ae739) => {
                const _0x36f0fe = _0x5e5ceb;
                if (_0xdbca74[_0x36f0fe(0x1f1)]) {
                  let _0x4ff3db =
                      "" + _0xdbca74["strategy"]["id"] + _0x32e194 + _0x3ae739,
                    _0x298208 = {
                      ..._0xdbca74[_0x36f0fe(0x1f1)],
                      name:
                        "R(" +
                        formatDate2(
                          new Date(_0x5163cf["creationDate"] * 0x3e8)
                        ) +
                        ")\x20" +
                        _0xdbca74[_0x36f0fe(0x1f1)][_0x36f0fe(0x14e)],
                      orgId: _0xdbca74[_0x36f0fe(0x1f1)]["id"],
                      id: _0x4ff3db,
                    };
                  !_0x298208[_0x36f0fe(0x199)] &&
                    (_0x298208[_0x36f0fe(0x199)] = {
                      maxDrawdown: "",
                      maxLoss: "",
                      maxNegTrades: "",
                      commissionRate: "",
                      orderDetails: { type: "market", value: "" },
                      tradeSize: { type: _0x36f0fe(0x16b), value: "" },
                    }),
                    !_0x298208["live"] &&
                      (_0x298208[_0x36f0fe(0x18a)] = {
                        maxDrawdown: "",
                        maxLoss: "",
                        maxNegTrades: "",
                        commissionRate: "",
                        orderDetails: { type: _0x36f0fe(0x1a7), value: "" },
                        tradeSize: { type: "base", value: "" },
                      }),
                    _0x268a78[_0x36f0fe(0x164)](_0x298208),
                    _0x567279[_0x36f0fe(0x17d)]["push"](_0x268a78),
                    _0x567279[_0x36f0fe(0x1c9)][0x4][_0x36f0fe(0x17d)][
                      _0x36f0fe(0x164)
                    ](_0x298208["id"]),
                    (_0x54ac28[_0x4ff3db] = {
                      simStatus: _0xdbca74[_0x36f0fe(0x141)],
                      simDetails: _0xdbca74[_0x36f0fe(0x134)],
                      simTrades: _0xdbca74[_0x36f0fe(0x1a6)],
                      simEvents: _0xdbca74[_0x36f0fe(0x15d)],
                      liveStatus: _0xdbca74[_0x36f0fe(0x14d)],
                      liveDetails: _0xdbca74[_0x36f0fe(0x1d1)],
                      liveTrades: _0xdbca74[_0x36f0fe(0x1cb)],
                      liveEvents: _0xdbca74[_0x36f0fe(0x1a9)],
                    });
                }
              }
            );
          });
        return {
          ..._0x567279,
          reportedBugs: _0xd11beb,
          strategyGroups: [..._0x567279[_0xe0ae1e(0x1c9)]],
          reportedStrategies: _0x268a78,
          reportedStrategiesData: _0x54ac28,
          strategies: [..._0x567279[_0xe0ae1e(0x17d)]],
          strategiesInitialized: !![],
          strategyGroupsInitialized: !![],
        };
      case _0xe0ae1e(0x1a3):
        return { ..._0x567279, users: _0x270eaa[_0xe0ae1e(0x1e7)] };
      case _0xe0ae1e(0x188):
        return { ..._0x567279, app: !![] };
      case "removeToken":
        localStorage["removeItem"](_0xe0ae1e(0x191));
        let _0x50946d = { ..._0x567279[_0xe0ae1e(0x1c7)], token: null };
        return { ..._0x567279, user: _0x50946d };
      case _0xe0ae1e(0x1e0):
        return {
          ..._0x567279,
          user: { ..._0x567279["user"], isExpired: !![] },
        };
      case "user":
        localStorage[_0xe0ae1e(0x1ed)](
          _0xe0ae1e(0x191),
          encrypt(_0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x170)])
        );
        let _0x4221ab = {
            ..._0x270eaa[_0xe0ae1e(0x1e7)],
            isSubAcc: _0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x18d)]
              ? !![]
              : ![],
            isAdmin: _0x270eaa[_0xe0ae1e(0x1e7)]["email"] === _0xe0ae1e(0x194),
            isContestRunner:
              _0x270eaa[_0xe0ae1e(0x1e7)]["email"] === _0xe0ae1e(0x1ea),
          },
          _0x4eb6b6 = ensureDate(_0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x127)]),
          _0x32eff1 = new Date();
        _0x32eff1[_0xe0ae1e(0x1be)](_0x32eff1["getHours"]() + 0x18 * 0x7);
        let _0x35b0ec =
          !_0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x1d5)] &&
          _0x32eff1 > _0x4eb6b6;
        checkUserExpire(
          {
            expireDate: _0x270eaa["payload"][_0xe0ae1e(0x127)],
            isExpired: _0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x1d5)],
          },
          _0x270eaa["payload"][_0xe0ae1e(0x1ce)]
        );
        return {
          ..._0x567279,
          user: _0x4221ab,
          subscriptionIsExpiring: _0x35b0ec,
        };
      case _0xe0ae1e(0x1ee):
        return {
          ..._0x567279,
          subscriptionIsExpiring: _0x270eaa[_0xe0ae1e(0x1e7)],
        };
      case _0xe0ae1e(0x163):
        return {
          ..._0x567279,
          exchanges: _0x270eaa["payload"],
          exchangesInitialized: !![],
        };
      case _0xe0ae1e(0x1da):
        _0x567279["exchanges"][_0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x12b)]] =
          _0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x1a1)];
        !_0x567279["demo"] &&
          !_0x567279[_0xe0ae1e(0x1c7)]["isAdmin"] &&
          (ss({
            user: _0x567279["user"]["id"],
            exchange: _0x270eaa[_0xe0ae1e(0x1e7)]["exchange"],
            key: _0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x1a1)]["key"],
            secret: _0x270eaa[_0xe0ae1e(0x1e7)]["data"]["secret"],
          }),
          updateExchangeApiKeys(
            _0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x12b)],
            _0x270eaa["payload"][_0xe0ae1e(0x1a1)][_0xe0ae1e(0x1ef)],
            _0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x1a1)][_0xe0ae1e(0x1b6)],
            _0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x1a1)][_0xe0ae1e(0x18e)]
          ));
        return { ..._0x567279, exchanges: { ..._0x567279[_0xe0ae1e(0x163)] } };
      case _0xe0ae1e(0x186):
        _0x567279[_0xe0ae1e(0x17d)][_0xe0ae1e(0x1c6)]((_0x3751a9) => {
          const _0x3a2057 = _0xe0ae1e;
          let _0x7645b2 = _0x567279[_0x3a2057(0x1b0)][_0x3751a9["id"]],
            _0x2bb48c = _0x567279[_0x3a2057(0x1b9)][_0x3751a9["id"]];
          _0x7645b2 &&
            _0x7645b2[_0x3a2057(0x156)] === "Running" &&
            stopTrading(
              _0x3751a9,
              _0x3a2057(0x190),
              _0x270eaa["payload"][_0x3a2057(0x142)],
              _0x567279[_0x3a2057(0x139)],
              _0x7645b2
            ),
            _0x2bb48c &&
              _0x2bb48c["status"] === _0x3a2057(0x1ec) &&
              stopTrading(
                _0x3751a9,
                _0x3a2057(0x13b),
                _0x270eaa[_0x3a2057(0x1e7)][_0x3a2057(0x142)],
                _0x567279["demo"],
                _0x2bb48c
              );
        });
        return _0x567279;
      case _0xe0ae1e(0x192):
        delete _0x567279[_0xe0ae1e(0x163)][_0x270eaa[_0xe0ae1e(0x1e7)]],
          delete _0x567279[_0xe0ae1e(0x12a)][_0x270eaa[_0xe0ae1e(0x1e7)]];
        !_0x567279["demo"] &&
          !_0x567279[_0xe0ae1e(0x1c7)][_0xe0ae1e(0x12f)] &&
          (deleteApiKey(
            _0x270eaa["payload"],
            _0x567279[_0xe0ae1e(0x1c7)]["id"],
            _0x567279["user"][_0xe0ae1e(0x151)]
          ),
          updateExchangeApiKeys(_0x270eaa[_0xe0ae1e(0x1e7)], null, null, ![]));
        return {
          ..._0x567279,
          exchanges: { ..._0x567279["exchanges"] },
          assets: { ..._0x567279["assets"] },
        };
      case "addOptimizationLimit":
        if (
          !_0x567279[_0xe0ae1e(0x1c7)][_0xe0ae1e(0x1d5)] &&
          _0x567279["user"][_0xe0ae1e(0x148)] !== "beginner"
        )
          return _0x567279;
        let _0x2ece57 = _0x567279[_0xe0ae1e(0x193)]
          ? _0x567279["limits"]
          : { opt: 0x0 };
        _0x2ece57[_0xe0ae1e(0x1f0)]++,
          sendNewOptimization(_0x567279[_0xe0ae1e(0x1c7)][_0xe0ae1e(0x170)]);
        return { ..._0x567279, limits: _0x2ece57 };
      case _0xe0ae1e(0x193):
        return { ..._0x567279, limits: _0x270eaa[_0xe0ae1e(0x1e7)] };
      case _0xe0ae1e(0x12a):
        _0x567279[_0xe0ae1e(0x12a)][_0x270eaa[_0xe0ae1e(0x1e7)]["id"]] =
          _0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x12a)];
        return { ..._0x567279, assets: { ..._0x567279["assets"] } };
      case _0xe0ae1e(0x1b4):
        return { ..._0x567279, confirmDialog: _0x270eaa["payload"] };
      case _0xe0ae1e(0x143):
        return { ..._0x567279, infoDialog: _0x270eaa[_0xe0ae1e(0x1e7)] };
      case _0xe0ae1e(0x19c):
        localStorage[_0xe0ae1e(0x1ed)](
          _0xe0ae1e(0x19c),
          _0x270eaa[_0xe0ae1e(0x1e7)]
        );
        return { ..._0x567279, commissionRate: _0x270eaa[_0xe0ae1e(0x1e7)] };
      case _0xe0ae1e(0x158):
        return { ..._0x567279, dateFrom: _0x270eaa[_0xe0ae1e(0x1e7)] };
      case _0xe0ae1e(0x171):
        return { ..._0x567279, dateTo: _0x270eaa[_0xe0ae1e(0x1e7)] };
      case _0xe0ae1e(0x1a0):
        return { ..._0x567279, btSize: _0x270eaa["payload"] };
      case _0xe0ae1e(0x14f):
        return { ..._0x567279, tradeSize: _0x270eaa["payload"] };
      case _0xe0ae1e(0x139):
        (_0x567279[_0xe0ae1e(0x1c7)] = {
          id: _0xe0ae1e(0x139),
          token: _0xe0ae1e(0x139),
          name: "Demo\x20account",
          email: _0xe0ae1e(0x1d0),
          expireDate: _0xe0ae1e(0x168),
          isExpired: ![],
          subscriptionType: _0xe0ae1e(0x1e9),
        }),
          (_0x567279[_0xe0ae1e(0x163)]["Binance"] = {
            key: _0xe0ae1e(0x139),
            secret: _0xe0ae1e(0x139),
            valid: !![],
          });
        return {
          ..._0x567279,
          demo: _0x270eaa[_0xe0ae1e(0x1e7)],
          user: _0x567279[_0xe0ae1e(0x1c7)],
          exchanges: _0x567279[_0xe0ae1e(0x163)],
          exchangesInitialized: !![],
          watchlistsInitialized: !![],
          strategyGroupsInitialized: !![],
          strategiesInitialized: !![],
          multiCharts: [
            {
              id: _0xe0ae1e(0x187),
              userId: _0xe0ae1e(0x139),
              name: _0xe0ae1e(0x176),
              charts: [
                {
                  id: 0x1785fe0589e,
                  pair: _0xe0ae1e(0x182),
                  time: "1h",
                  indicators: [
                    {
                      id: 0x178c77fdd4f,
                      indicator: _0xe0ae1e(0x1b8),
                      period: 0x14,
                      period2: 0x2,
                      period3: 0x14,
                      period4: 0x1,
                      formula: _0xe0ae1e(0x1df),
                    },
                  ],
                },
                {
                  id: 0x1785fe06356,
                  pair: "BINANCE-ETHBTC",
                  time: "4h",
                  indicators: [
                    {
                      id: 0x178c7807fba,
                      indicator: _0xe0ae1e(0x149),
                      period: 0xe,
                      period2: 0x3,
                      period3: 0x3,
                    },
                    {
                      id: 0x178c77f75ac,
                      indicator: _0xe0ae1e(0x166),
                      period: 0x14,
                    },
                  ],
                },
                {
                  id: 0x1785fe07759,
                  pair: _0xe0ae1e(0x1a2),
                  time: "1h",
                  indicators: [
                    {
                      id: 0x178c77f9ba0,
                      indicator: _0xe0ae1e(0x1c4),
                      period: 0xc,
                      period2: 0x1a,
                      period3: 0x9,
                    },
                  ],
                },
                {
                  id: 0x178c77f53db,
                  pair: _0xe0ae1e(0x1e5),
                  time: "1h",
                  indicators: [
                    {
                      id: 0x178c77fbc02,
                      indicator: _0xe0ae1e(0x1d2),
                      period: 0xe,
                    },
                  ],
                },
              ],
              editDate: 0x178c78085da,
            },
          ],
          multiChartsInitialized: !![],
        };
      case _0xe0ae1e(0x1a4):
        return {
          ..._0x567279,
          contests: _0x270eaa[_0xe0ae1e(0x1e7)]["contests"],
          nextContest: _0x270eaa[_0xe0ae1e(0x1e7)]["next"],
        };
      case _0xe0ae1e(0x13c):
        let _0x4a8ec2 = _0x567279[_0xe0ae1e(0x1a4)]["find"](
          (_0x1e9232) => _0x1e9232["month"] === _0x270eaa[_0xe0ae1e(0x1e7)]
        );
        (_0x4a8ec2["executions"] = null),
          (_0x4a8ec2[_0xe0ae1e(0x137)] = 0x0),
          (_0x4a8ec2[_0xe0ae1e(0x16e)] = 0x0),
          (_0x4a8ec2["loading"] = !![]),
          (_0x4a8ec2[_0xe0ae1e(0x16f)] = null);
        return { ..._0x567279, selectedContest: _0x4a8ec2 };
      case _0xe0ae1e(0x1c3):
        return {
          ..._0x567279,
          selectedContest: {
            ..._0x567279["selectedContest"],
            executions: _0x270eaa[_0xe0ae1e(0x1e7)]["executions"],
            total: _0x270eaa["payload"][_0xe0ae1e(0x16e)],
            loading: ![],
            lastUpdated: new Date(),
          },
        };
      case _0xe0ae1e(0x146):
        return {
          ..._0x567279,
          selectedContest: {
            ..._0x567279["selectedContest"],
            winners: _0x270eaa[_0xe0ae1e(0x1e7)],
          },
        };
      case _0xe0ae1e(0x129):
        return _0x567279["selectedContest"]["page"] ===
          _0x270eaa[_0xe0ae1e(0x1e7)]
          ? _0x567279
          : {
              ..._0x567279,
              selectedContest: {
                ..._0x567279[_0xe0ae1e(0x13c)],
                page: _0x270eaa[_0xe0ae1e(0x1e7)],
                loading: !![],
              },
            };
      case _0xe0ae1e(0x1b7):
        localStorage[_0xe0ae1e(0x1ed)](
          _0xe0ae1e(0x1b7),
          _0x270eaa[_0xe0ae1e(0x1e7)]
        );
        return {
          ..._0x567279,
          manualTradeConfirm: _0x270eaa[_0xe0ae1e(0x1e7)],
        };
      case "tradeInfoSound":
        localStorage[_0xe0ae1e(0x1ed)](
          _0xe0ae1e(0x157),
          _0x270eaa[_0xe0ae1e(0x1e7)]
        );
        return { ..._0x567279, tradeInfoSound: _0x270eaa[_0xe0ae1e(0x1e7)] };
      case _0xe0ae1e(0x17d):
        _0x567279[_0xe0ae1e(0x1aa)] &&
          _0x567279["reportedStrategies"][_0xe0ae1e(0x1c6)]((_0x33b43b) =>
            _0x270eaa[_0xe0ae1e(0x1e7)]["strategies"][_0xe0ae1e(0x164)](
              _0x33b43b
            )
          );
        _0x567279[_0xe0ae1e(0x1c9)][0x0][_0xe0ae1e(0x17d)] = _0x270eaa[
          _0xe0ae1e(0x1e7)
        ]["strategies"][_0xe0ae1e(0x12d)]((_0x57ab79) => _0x57ab79["id"]);
        return {
          ..._0x567279,
          strategies: [..._0x270eaa["payload"][_0xe0ae1e(0x17d)]],
          simStatuses: { ..._0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x1b0)] },
          liveStatuses: { ..._0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x1b9)] },
          strategiesInitialized: !![],
          strategyGroups: [..._0x567279["strategyGroups"]],
        };
      case _0xe0ae1e(0x131):
        let _0x197d32 = { ..._0x270eaa[_0xe0ae1e(0x1e7)] };
        (_0x197d32["id"] = generateUniqueId(
          _0x567279[_0xe0ae1e(0x17d)],
          _0x567279[_0xe0ae1e(0x1c7)]["id"],
          "id"
        )),
          (_0x197d32[_0xe0ae1e(0x1bd)] = new Date()[_0xe0ae1e(0x1bb)]()),
          (_0x197d32[_0xe0ae1e(0x1a5)] = _0x567279[_0xe0ae1e(0x1c7)]["id"]),
          _0x567279[_0xe0ae1e(0x1c9)][0x0][_0xe0ae1e(0x17d)][_0xe0ae1e(0x164)](
            _0x197d32["id"]
          ),
          _0x567279["strategies"][_0xe0ae1e(0x164)](_0x197d32);
        !_0x567279[_0xe0ae1e(0x139)] &&
          !_0x567279["user"]["isAdmin"] &&
          (getEcbDatabase()[_0xe0ae1e(0x1de)](_0x197d32),
          sendStrategy(_0x567279[_0xe0ae1e(0x1c7)]["token"], _0x197d32));
        return {
          ..._0x567279,
          strategies: [..._0x567279[_0xe0ae1e(0x17d)]],
          strategyGroups: [..._0x567279[_0xe0ae1e(0x1c9)]],
        };
      case _0xe0ae1e(0x173):
        let _0xa5797a = ![];
        for (let _0x176854 of _0x567279[_0xe0ae1e(0x17d)]) {
          _0x176854[_0xe0ae1e(0x12b)] ===
            _0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x12b)] &&
            comparePairDetails(
              _0x176854,
              _0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x173)]
            ) &&
            ((_0xa5797a = !![]),
            (_0x176854[_0xe0ae1e(0x173)] =
              _0x270eaa["payload"][_0xe0ae1e(0x173)][
                _0x176854[_0xe0ae1e(0x169)]
              ]),
            _0x567279[_0xe0ae1e(0x1cc)] &&
              !_0x567279[_0xe0ae1e(0x139)] &&
              !_0x567279[_0xe0ae1e(0x1c7)][_0xe0ae1e(0x12f)] &&
              (getEcbDatabase()[_0xe0ae1e(0x1de)](_0x176854),
              sendStrategy(_0x567279[_0xe0ae1e(0x1c7)]["token"], _0x176854),
              updateStrategy(_0x176854)));
        }
        return _0xa5797a
          ? { ..._0x567279, strategies: [..._0x567279[_0xe0ae1e(0x17d)]] }
          : _0x567279;
      case _0xe0ae1e(0x1f1):
        let _0x290913 = -0x1,
          _0x11aaa7 = { ..._0x270eaa[_0xe0ae1e(0x1e7)] };
        if (
          _0x567279[_0xe0ae1e(0x17d)][_0xe0ae1e(0x130)](
            (_0x4ae9ae, _0x597312) => {
              if (_0x4ae9ae["id"] === _0x11aaa7["id"])
                return (_0x290913 = _0x597312), !![];
              return ![];
            }
          )
        ) {
          let [_0xa7d641] = _0x567279[_0xe0ae1e(0x17d)][_0xe0ae1e(0x1d6)](
              _0x290913,
              0x1,
              _0x11aaa7
            ),
            _0x49b14d = _0x567279[_0xe0ae1e(0x1b0)][_0x11aaa7["id"]],
            _0x5cb359 = ![],
            _0x3bab94 = _0x567279["liveStatuses"][_0x11aaa7["id"]],
            _0x3b27bf = ![];
          _0xa7d641[_0xe0ae1e(0x12e)] !== _0x11aaa7["stoploss"] &&
            (_0x49b14d &&
              _0x49b14d[_0xe0ae1e(0x181)] &&
              _0x49b14d["lastTrade"] &&
              ((_0x49b14d[_0xe0ae1e(0x18c)]["s"] = _0x11aaa7["stoploss"]
                ? roundQuotedValue(
                    _0x49b14d["lastTrade"]["o"] *
                      (0x1 - _0x11aaa7[_0xe0ae1e(0x12e)] / 0x64),
                    _0x11aaa7[_0xe0ae1e(0x173)]
                  )
                : null),
              (_0x5cb359 = !![])),
            _0x3bab94 &&
              _0x3bab94[_0xe0ae1e(0x181)] &&
              _0x3bab94["lastTrade"] &&
              ((_0x3bab94["details"]["s"] = _0x11aaa7[_0xe0ae1e(0x12e)]
                ? roundQuotedValue(
                    _0x3bab94[_0xe0ae1e(0x17a)]["o"] *
                      (0x1 - _0x11aaa7[_0xe0ae1e(0x12e)] / 0x64),
                    _0x11aaa7[_0xe0ae1e(0x173)]
                  )
                : null),
              (_0x3b27bf = !![])));
          _0xa7d641[_0xe0ae1e(0x133)] !== _0x11aaa7[_0xe0ae1e(0x133)] &&
            (_0x49b14d &&
              _0x49b14d[_0xe0ae1e(0x181)] &&
              _0x49b14d[_0xe0ae1e(0x17a)] &&
              ((_0x49b14d[_0xe0ae1e(0x18c)]["t"] = _0x11aaa7["target"]
                ? roundQuotedValue(
                    _0x49b14d["lastTrade"]["o"] *
                      (0x1 + _0x11aaa7[_0xe0ae1e(0x133)] / 0x64),
                    _0x11aaa7[_0xe0ae1e(0x173)]
                  )
                : null),
              (_0x5cb359 = !![])),
            _0x3bab94 &&
              _0x3bab94[_0xe0ae1e(0x181)] &&
              _0x3bab94[_0xe0ae1e(0x17a)] &&
              ((_0x3bab94[_0xe0ae1e(0x18c)]["t"] = _0x11aaa7[_0xe0ae1e(0x133)]
                ? roundQuotedValue(
                    _0x3bab94["lastTrade"]["o"] *
                      (0x1 + _0x11aaa7[_0xe0ae1e(0x133)] / 0x64),
                    _0x11aaa7[_0xe0ae1e(0x173)]
                  )
                : null),
              (_0x3b27bf = !![])));
          if (_0xa7d641[_0xe0ae1e(0x17e)] !== _0x11aaa7[_0xe0ae1e(0x17e)]) {
            if (
              _0x49b14d &&
              _0x49b14d["activeTrade"] &&
              _0x49b14d[_0xe0ae1e(0x17a)]
            ) {
              let _0x9e0129 = null;
              _0x49b14d[_0xe0ae1e(0x18c)]["tt"] &&
                (_0x9e0129 =
                  _0x49b14d[_0xe0ae1e(0x18c)]["tt"] *
                  (0x1 + _0xa7d641[_0xe0ae1e(0x17e)] / 0x64)),
                _0x9e0129 &&
                  ((_0x49b14d[_0xe0ae1e(0x18c)]["tt"] = _0x11aaa7[
                    _0xe0ae1e(0x17e)
                  ]
                    ? roundQuotedValue(
                        _0x9e0129 * (0x1 - _0x11aaa7[_0xe0ae1e(0x17e)] / 0x64),
                        _0x11aaa7[_0xe0ae1e(0x173)]
                      )
                    : null),
                  (_0x5cb359 = !![]));
            }
            if (
              _0x3bab94 &&
              _0x3bab94[_0xe0ae1e(0x181)] &&
              _0x3bab94["lastTrade"]
            ) {
              let _0xc5a6f2 = null;
              _0x3bab94[_0xe0ae1e(0x18c)]["tt"] &&
                (_0xc5a6f2 =
                  _0x3bab94[_0xe0ae1e(0x18c)]["tt"] *
                  (0x1 + _0xa7d641[_0xe0ae1e(0x17e)] / 0x64)),
                _0xc5a6f2 &&
                  ((_0x3bab94[_0xe0ae1e(0x18c)]["tt"] = _0x11aaa7[
                    "trailingTarget"
                  ]
                    ? roundQuotedValue(
                        _0xc5a6f2 * (0x1 - _0x11aaa7["trailingTarget"] / 0x64),
                        _0x11aaa7[_0xe0ae1e(0x173)]
                      )
                    : null),
                  (_0x3b27bf = !![]));
            }
          }
          if (_0xa7d641[_0xe0ae1e(0x1b5)] !== _0x11aaa7[_0xe0ae1e(0x1b5)]) {
            if (
              _0x49b14d &&
              _0x49b14d[_0xe0ae1e(0x181)] &&
              _0x49b14d[_0xe0ae1e(0x17a)]
            ) {
              _0x49b14d[_0xe0ae1e(0x18c)]["tc"] = null;
              if (_0x11aaa7[_0xe0ae1e(0x1b5)]) {
                let _0x243279 = new Date(_0x49b14d["lastTrade"]["od"]);
                _0x243279[_0xe0ae1e(0x1be)](
                  _0x243279["getHours"]() + _0x11aaa7["timeClose"],
                  _0x243279[_0xe0ae1e(0x14b)](),
                  _0x243279[_0xe0ae1e(0x136)](),
                  _0x243279[_0xe0ae1e(0x1d3)]()
                ),
                  (_0x49b14d[_0xe0ae1e(0x18c)]["tc"] = _0x243279["getTime"]());
              }
              _0x5cb359 = !![];
            }
            if (
              _0x3bab94 &&
              _0x3bab94["activeTrade"] &&
              _0x3bab94[_0xe0ae1e(0x17a)]
            ) {
              _0x3bab94[_0xe0ae1e(0x18c)]["tc"] = null;
              if (_0x11aaa7["timeClose"]) {
                let _0x2b6f80 = new Date(_0x3bab94["lastTrade"]["od"]);
                _0x2b6f80[_0xe0ae1e(0x1be)](
                  _0x2b6f80[_0xe0ae1e(0x172)]() + _0x11aaa7[_0xe0ae1e(0x1b5)],
                  _0x2b6f80[_0xe0ae1e(0x14b)](),
                  _0x2b6f80[_0xe0ae1e(0x136)](),
                  _0x2b6f80[_0xe0ae1e(0x1d3)]()
                ),
                  (_0x3bab94["details"]["tc"] = _0x2b6f80[_0xe0ae1e(0x1bb)]());
              }
              _0x3b27bf = !![];
            }
          }
          _0x5cb359 &&
            !_0x567279[_0xe0ae1e(0x139)] &&
            _0x567279[_0xe0ae1e(0x1cc)] &&
            !_0x567279["user"]["isAdmin"] &&
            (getEcbDatabase()[_0xe0ae1e(0x14c)](_0x49b14d),
            sendStrategyStatus(
              _0x567279[_0xe0ae1e(0x1c7)]["token"],
              "simulation",
              _0x49b14d
            )),
            _0x3b27bf &&
              !_0x567279[_0xe0ae1e(0x139)] &&
              _0x567279[_0xe0ae1e(0x1cc)] &&
              !_0x567279[_0xe0ae1e(0x1c7)][_0xe0ae1e(0x12f)] &&
              (getEcbDatabase()[_0xe0ae1e(0x13d)](_0x3b27bf),
              sendStrategyStatus(
                _0x567279[_0xe0ae1e(0x1c7)]["token"],
                "trading",
                _0x3b27bf
              ));
        } else
          _0x567279[_0xe0ae1e(0x1c9)][0x0][_0xe0ae1e(0x17d)][_0xe0ae1e(0x164)](
            _0x11aaa7["id"]
          ),
            _0x567279[_0xe0ae1e(0x17d)][_0xe0ae1e(0x164)](_0x11aaa7);
        !_0x567279["demo"] &&
          !_0x567279[_0xe0ae1e(0x1c7)]["isAdmin"] &&
          (getEcbDatabase()[_0xe0ae1e(0x1de)](_0x11aaa7),
          sendStrategy(_0x567279["user"][_0xe0ae1e(0x170)], _0x11aaa7));
        return {
          ..._0x567279,
          strategies: [..._0x567279["strategies"]],
          strategyGroups: [..._0x567279[_0xe0ae1e(0x1c9)]],
          simStatuses: { ..._0x567279[_0xe0ae1e(0x1b0)] },
          liveStatuses: { ..._0x567279[_0xe0ae1e(0x1b9)] },
        };
      case _0xe0ae1e(0x17b):
        (_0x567279[_0xe0ae1e(0x17d)] = _0x567279[_0xe0ae1e(0x17d)][
          _0xe0ae1e(0x132)
        ](
          (_0xb43ee1) =>
            !_0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x135)](_0xb43ee1["id"])
        )),
          _0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x1c6)]((_0x90f50f) => {
            const _0x4d861e = _0xe0ae1e;
            stopBacktest(_0x90f50f),
              getEcbDatabase()[_0x4d861e(0x16c)](_0x90f50f),
              stopOptimization(_0x90f50f),
              getEcbDatabase()[_0x4d861e(0x128)](_0x90f50f),
              !_0x567279["demo"] &&
                !_0x567279[_0x4d861e(0x1c7)][_0x4d861e(0x12f)] &&
                (getEcbDatabase()[_0x4d861e(0x1bc)](_0x90f50f),
                addItemToArrayInLocalStore("removedStrategiesIds", _0x90f50f),
                _0x567279[_0x4d861e(0x1b0)][_0x90f50f] &&
                  (delete _0x567279[_0x4d861e(0x1b0)][_0x90f50f],
                  getEcbDatabase()[_0x4d861e(0x1ad)](_0x90f50f),
                  getEcbDatabase()[_0x4d861e(0x197)](_0x90f50f),
                  removeTrades(_0x4d861e(0x190), _0x90f50f),
                  removeEvents(_0x4d861e(0x190), _0x90f50f),
                  getEcbDatabase()[_0x4d861e(0x147)](_0x90f50f),
                  removeItemFromArrayInLocalStore(_0x4d861e(0x150), _0x90f50f),
                  addItemToArrayInLocalStore(_0x4d861e(0x15a), _0x90f50f)),
                _0x567279[_0x4d861e(0x1b9)][_0x90f50f] &&
                  (delete _0x567279[_0x4d861e(0x1b9)][_0x90f50f],
                  getEcbDatabase()[_0x4d861e(0x1ac)](_0x90f50f),
                  getEcbDatabase()[_0x4d861e(0x15c)](_0x90f50f),
                  removeTrades(_0x4d861e(0x13b), _0x90f50f),
                  removeEvents(_0x4d861e(0x13b), _0x90f50f),
                  getEcbDatabase()[_0x4d861e(0x159)](_0x90f50f),
                  removeItemFromArrayInLocalStore(_0x4d861e(0x1c2), _0x90f50f),
                  addItemToArrayInLocalStore(_0x4d861e(0x1d9), _0x90f50f)));
          });
        !_0x567279[_0xe0ae1e(0x139)] &&
          !_0x567279[_0xe0ae1e(0x1c7)][_0xe0ae1e(0x12f)] &&
          sleep(0x3e8 * 0x2)[_0xe0ae1e(0x161)](() =>
            sendRemoveStrategiesNotSynced(_0x567279["user"][_0xe0ae1e(0x170)])
          );
        _0x567279[_0xe0ae1e(0x1c9)]["forEach"]((_0x496778) => {
          const _0x11a7ed = _0xe0ae1e;
          let _0x3c7b12 = _0x496778[_0x11a7ed(0x17d)][_0x11a7ed(0x189)];
          (_0x496778["strategies"] = _0x496778[_0x11a7ed(0x17d)][
            _0x11a7ed(0x132)
          ](
            (_0x3c8273) =>
              _0x3c8273 &&
              !_0x270eaa[_0x11a7ed(0x1e7)][_0x11a7ed(0x135)](_0x3c8273["id"])
          )),
            _0x3c7b12 !== _0x496778[_0x11a7ed(0x17d)][_0x11a7ed(0x189)] &&
              addItemToArrayInLocalStore(_0x11a7ed(0x1a8), _0x496778["id"]);
        }),
          (_0x567279[_0xe0ae1e(0x1c9)][0x0][_0xe0ae1e(0x17d)] = _0x567279[
            _0xe0ae1e(0x17d)
          ][_0xe0ae1e(0x12d)]((_0x191d97) => _0x191d97["id"])),
          (_0x567279[_0xe0ae1e(0x1c9)][0x1][_0xe0ae1e(0x17d)] = _0x567279[
            _0xe0ae1e(0x1c9)
          ][0x1][_0xe0ae1e(0x17d)]["filter"](
            (_0x132b92) => !_0x270eaa["payload"][_0xe0ae1e(0x135)](_0x132b92)
          )),
          (_0x567279[_0xe0ae1e(0x1c9)][0x2][_0xe0ae1e(0x17d)] = _0x567279[
            _0xe0ae1e(0x1c9)
          ][0x2][_0xe0ae1e(0x17d)][_0xe0ae1e(0x132)](
            (_0x37a90f) =>
              !_0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x135)](_0x37a90f)
          )),
          (_0x567279[_0xe0ae1e(0x1c9)][0x3][_0xe0ae1e(0x17d)] = _0x567279[
            "strategyGroups"
          ][0x3][_0xe0ae1e(0x17d)][_0xe0ae1e(0x132)](
            (_0x505103) => !_0x270eaa["payload"][_0xe0ae1e(0x135)](_0x505103)
          ));
        return {
          ..._0x567279,
          strategies: [..._0x567279[_0xe0ae1e(0x17d)]],
          simStatuses: { ..._0x567279[_0xe0ae1e(0x1b0)] },
          liveStatuses: { ..._0x567279["liveStatuses"] },
          strategyGroups: [..._0x567279["strategyGroups"]],
        };
      case _0xe0ae1e(0x1b0):
        let _0x27e3e5 = _0x270eaa[_0xe0ae1e(0x1e7)];
        Object["keys"](_0x27e3e5)[_0xe0ae1e(0x1c6)]((_0x56ac3b) => {
          const _0x5abc74 = _0xe0ae1e;
          let _0xe810c4 = _0x27e3e5[_0x56ac3b];
          _0xe810c4["status"] !== _0x5abc74(0x1ec) &&
            _0xe810c4[_0x5abc74(0x156)] !== _0x5abc74(0x1bf) &&
            !_0xe810c4["starting"] &&
            (_0xe810c4[_0x5abc74(0x156)] = _0x5abc74(0x1bf)),
            !_0x567279[_0x5abc74(0x1c9)][0x1]["strategies"][_0x5abc74(0x135)](
              _0x56ac3b
            ) &&
              _0x567279[_0x5abc74(0x1c9)][0x1][_0x5abc74(0x17d)]["push"](
                _0x56ac3b
              );
        });
        return {
          ..._0x567279,
          strategyGroups: [..._0x567279["strategyGroups"]],
          simStatuses: { ..._0x27e3e5 },
          executionsLastUpdate: new Date(),
        };
      case _0xe0ae1e(0x1b9):
        let _0x258f76 = _0x270eaa[_0xe0ae1e(0x1e7)];
        Object["keys"](_0x258f76)[_0xe0ae1e(0x1c6)]((_0x2c817f) => {
          const _0x2d92c2 = _0xe0ae1e;
          let _0x4c20c4 = _0x258f76[_0x2c817f];
          _0x4c20c4[_0x2d92c2(0x156)] !== _0x2d92c2(0x1ec) &&
            _0x4c20c4[_0x2d92c2(0x156)] !== _0x2d92c2(0x1bf) &&
            !_0x4c20c4[_0x2d92c2(0x167)] &&
            (_0x4c20c4[_0x2d92c2(0x156)] = _0x2d92c2(0x1bf)),
            !_0x567279[_0x2d92c2(0x1c9)][0x1]["strategies"][_0x2d92c2(0x135)](
              _0x2c817f
            ) &&
              _0x567279[_0x2d92c2(0x1c9)][0x1][_0x2d92c2(0x17d)][
                _0x2d92c2(0x164)
              ](_0x2c817f);
        });
        return {
          ..._0x567279,
          strategyGroups: [..._0x567279["strategyGroups"]],
          liveStatuses: { ..._0x258f76 },
          executionsLastUpdate: new Date(),
        };
      case _0xe0ae1e(0x141):
        let _0xa8dad7 = _0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x156)];
        !_0xa8dad7[_0xe0ae1e(0x18c)] && (_0xa8dad7[_0xe0ae1e(0x18c)] = {});
        _0x567279[_0xe0ae1e(0x1b0)][_0xa8dad7["id"]] = { ..._0xa8dad7 };
        !_0x567279[_0xe0ae1e(0x139)] &&
          _0x567279["app"] &&
          !_0x567279["user"][_0xe0ae1e(0x12f)] &&
          (getEcbDatabase()["storeSimStrategyStatus"](_0xa8dad7),
          sendStrategyStatus(
            _0x567279[_0xe0ae1e(0x1c7)]["token"],
            _0xe0ae1e(0x190),
            _0xa8dad7
          ));
        !_0x567279[_0xe0ae1e(0x1c9)][0x1][_0xe0ae1e(0x17d)]["includes"](
          _0xa8dad7["id"]
        ) &&
          _0x567279["strategyGroups"][0x1][_0xe0ae1e(0x17d)][_0xe0ae1e(0x164)](
            _0xa8dad7["id"]
          );
        if (
          _0x567279[_0xe0ae1e(0x157)] > 0x0 &&
          _0x270eaa[_0xe0ae1e(0x1e7)]["trade"]
        ) {
          let _0x46f099 = new Audio(infoSound);
          _0x46f099 && _0x46f099[_0xe0ae1e(0x185)]();
        }
        return {
          ..._0x567279,
          strategyGroups: [..._0x567279[_0xe0ae1e(0x1c9)]],
          simStatuses: { ..._0x567279["simStatuses"] },
        };
      case "liveStatus":
        let _0x51cb8b = _0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x156)];
        !_0x51cb8b["details"] && (_0x51cb8b[_0xe0ae1e(0x18c)] = {});
        _0x567279[_0xe0ae1e(0x1b9)][_0x51cb8b["id"]] = { ..._0x51cb8b };
        !_0x567279[_0xe0ae1e(0x139)] &&
          _0x567279[_0xe0ae1e(0x1cc)] &&
          !_0x567279[_0xe0ae1e(0x1c7)]["isAdmin"] &&
          (getEcbDatabase()["storeLiveStrategyStatus"](_0x51cb8b),
          sendStrategyStatus(
            _0x567279["user"][_0xe0ae1e(0x170)],
            "trading",
            _0x51cb8b
          ));
        !_0x567279[_0xe0ae1e(0x1c9)][0x1][_0xe0ae1e(0x17d)][_0xe0ae1e(0x135)](
          _0x51cb8b["id"]
        ) &&
          _0x567279[_0xe0ae1e(0x1c9)][0x1][_0xe0ae1e(0x17d)][_0xe0ae1e(0x164)](
            _0x51cb8b["id"]
          );
        if (
          _0x567279[_0xe0ae1e(0x157)] > 0x0 &&
          _0x270eaa[_0xe0ae1e(0x1e7)][_0xe0ae1e(0x1ba)]
        ) {
          let _0x3fe676 = new Audio(infoSound);
          _0x3fe676 && _0x3fe676[_0xe0ae1e(0x185)]();
        }
        return {
          ..._0x567279,
          strategyGroups: [..._0x567279[_0xe0ae1e(0x1c9)]],
          liveStatuses: { ..._0x567279["liveStatuses"] },
        };
      case _0xe0ae1e(0x1d4):
        if (_0x270eaa["payload"][_0xe0ae1e(0x17f)] === _0xe0ae1e(0x190)) {
          let _0x3790a1 =
            _0x567279["simStatuses"][
              _0x270eaa[_0xe0ae1e(0x1e7)]["strategy"]["id"]
            ];
          if (_0x3790a1)
            return (
              (_0x3790a1["status"] = _0xe0ae1e(0x1e2)),
              { ..._0x567279, simStatuses: { ..._0x567279["simStatuses"] } }
            );
        } else {
          let _0x10c1db =
            _0x567279[_0xe0ae1e(0x1b9)][
              _0x270eaa["payload"][_0xe0ae1e(0x1f1)]["id"]
            ];
          if (_0x10c1db)
            return (
              (_0x10c1db["status"] = _0xe0ae1e(0x1e2)),
              { ..._0x567279, liveStatuses: { ..._0x567279[_0xe0ae1e(0x1b9)] } }
            );
        }
        return _0x567279;
      case _0xe0ae1e(0x1ca):
        let _0x536a9d = _0x270eaa[_0xe0ae1e(0x1e7)];
        delete _0x567279[_0xe0ae1e(0x1b0)][_0x536a9d];
        !_0x567279[_0xe0ae1e(0x139)] &&
          !_0x567279[_0xe0ae1e(0x1c7)][_0xe0ae1e(0x12f)] &&
          (getEcbDatabase()[_0xe0ae1e(0x1ad)](_0x536a9d),
          getEcbDatabase()[_0xe0ae1e(0x197)](_0x536a9d),
          removeTrades(_0xe0ae1e(0x190), _0x536a9d),
          removeEvents(_0xe0ae1e(0x190), _0x536a9d),
          getEcbDatabase()[_0xe0ae1e(0x147)](_0x536a9d),
          sendRemoveStrategyStatus(
            _0x567279[_0xe0ae1e(0x1c7)][_0xe0ae1e(0x170)],
            _0xe0ae1e(0x190),
            _0x536a9d
          ));
        !_0x567279["liveStatuses"][_0x536a9d] &&
          (_0x567279[_0xe0ae1e(0x1c9)][0x1][_0xe0ae1e(0x17d)] = _0x567279[
            "strategyGroups"
          ][0x1][_0xe0ae1e(0x17d)][_0xe0ae1e(0x132)](
            (_0x5756d9) => _0x5756d9 !== _0x536a9d
          ));
        return {
          ..._0x567279,
          strategyGroups: [..._0x567279[_0xe0ae1e(0x1c9)]],
          simStatuses: { ..._0x567279[_0xe0ae1e(0x1b0)] },
        };
      case _0xe0ae1e(0x1db):
        let _0x3eb0dd = _0x270eaa[_0xe0ae1e(0x1e7)];
        delete _0x567279[_0xe0ae1e(0x1b9)][_0x3eb0dd];
        !_0x567279[_0xe0ae1e(0x139)] &&
          !_0x567279[_0xe0ae1e(0x1c7)][_0xe0ae1e(0x12f)] &&
          (getEcbDatabase()[_0xe0ae1e(0x1ac)](_0x3eb0dd),
          getEcbDatabase()[_0xe0ae1e(0x15c)](_0x3eb0dd),
          removeTrades(_0xe0ae1e(0x13b), _0x3eb0dd),
          removeEvents(_0xe0ae1e(0x13b), _0x3eb0dd),
          getEcbDatabase()[_0xe0ae1e(0x159)](_0x3eb0dd),
          sendRemoveStrategyStatus(
            _0x567279["user"][_0xe0ae1e(0x170)],
            _0xe0ae1e(0x13b),
            _0x3eb0dd
          ));
        !_0x567279[_0xe0ae1e(0x1b0)][_0x3eb0dd] &&
          (_0x567279["strategyGroups"][0x1][_0xe0ae1e(0x17d)] = _0x567279[
            _0xe0ae1e(0x1c9)
          ][0x1][_0xe0ae1e(0x17d)][_0xe0ae1e(0x132)](
            (_0x5589f3) => _0x5589f3 !== _0x3eb0dd
          ));
        return {
          ..._0x567279,
          strategyGroups: [..._0x567279["strategyGroups"]],
          liveStatuses: { ..._0x567279[_0xe0ae1e(0x1b9)] },
        };
      default: {
        return (
          console[_0xe0ae1e(0x1e3)](
            _0xe0ae1e(0x1ab) + _0x270eaa[_0xe0ae1e(0x17f)]
          ),
          { ..._0x567279 }
        );
      }
    }
  };
function a0_0x3d33(_0x11bf60, _0x5e45d9) {
  const _0x40c6ba = a0_0x40c6();
  return (
    (a0_0x3d33 = function (_0x3d337d, _0x42a799) {
      _0x3d337d = _0x3d337d - 0x127;
      let _0xad5405 = _0x40c6ba[_0x3d337d];
      return _0xad5405;
    }),
    a0_0x3d33(_0x11bf60, _0x5e45d9)
  );
}
export { initialState, globalContext, globalContextReducer };
function a0_0x40c6() {
  const _0x528029 = [
    "All\x20In\x20Trading",
    "0_00",
    "locked",
    "simStatus",
    "dispath",
    "infoDialog",
    "removeRecentBacktest",
    "orderId",
    "selectedContestWinners",
    "deleteSimStrategyEvents",
    "subscriptionType",
    "Stochastic",
    "watchlistPanelRightExpanded",
    "getMinutes",
    "storeSimStrategyStatus",
    "liveStatus",
    "name",
    "tradeSize",
    "simStatusNotSync",
    "email",
    "5510372sxeorF",
    "sort",
    "watchlistTradingPanelBottomExpanded",
    "concat",
    "status",
    "tradeInfoSound",
    "dateFrom",
    "deleteLiveStrategyEvents",
    "simStatusRemovedSync",
    "notChoosen",
    "deleteLiveStrategyDetails",
    "simEvents",
    "All",
    "5077584weySrd",
    "location",
    "then",
    "2.44",
    "exchanges",
    "push",
    "getRegistrations",
    "SMA",
    "starting",
    "2100-01-01\x2000:00:00",
    "pair",
    "removeItem",
    "base",
    "deleteBacktests",
    "0_04",
    "total",
    "lastUpdated",
    "token",
    "dateTo",
    "getHours",
    "pairDetails",
    "assign",
    "login",
    "Default\x20List",
    "setupHidden",
    "serviceWorker",
    "serverVersion",
    "lastTrade",
    "removeStrategies",
    "1612035wnVNXw",
    "strategies",
    "trailingTarget",
    "type",
    "watchlists",
    "activeTrade",
    "BINANCE-BTCUSDT",
    "localeCompare",
    "editEmail",
    "play",
    "stopAllExecutions",
    "demo_1615917633924",
    "setIsApp",
    "length",
    "live",
    "4YUqVZv",
    "details",
    "parent",
    "isValid",
    "351232sOicPJ",
    "simulation",
    "ecbToken",
    "removeExchange",
    "limits",
    "admin@easycryptobot.com",
    "report",
    "adminGetUser",
    "deleteSimStrategyDetails",
    "reload",
    "sim",
    "254GoYJFR",
    "1089385FhysLL",
    "commissionRate",
    "isDefault",
    "getMonth",
    "remoteStrategyStop",
    "btSize",
    "data",
    "BINANCE-BNBUSDT",
    "users",
    "contests",
    "userId",
    "simTrades",
    "market",
    "groupsNotSynced",
    "liveEvents",
    "reportedStrategies",
    "UNKNOWN\x20CONTEXT\x20:\x20",
    "deleteLiveStrategyStatus",
    "deleteSimStrategyStatus",
    "https://easycryptobot.com",
    "189MpIqQz",
    "simStatuses",
    "removeRecentOptimization",
    "stopType",
    "strategyGroupRemove",
    "confirmDialog",
    "timeClose",
    "secret",
    "manualTradeConfirm",
    "Momentum\x20Squeeze",
    "liveStatuses",
    "trade",
    "getTime",
    "deleteStrategy",
    "editDate",
    "setHours",
    "Stopped",
    "trafic",
    "Top\x20Gainers",
    "liveStatusNotSync",
    "selectedContestRanking",
    "MACD",
    "22209240tuZiZz",
    "forEach",
    "user",
    "0_01",
    "strategyGroups",
    "removeSimStatus",
    "liveTrades",
    "app",
    "addRecentBacktest",
    "dispatch",
    "multiCharts",
    "demo@easycryptobot.com",
    "liveDetails",
    "RSI",
    "getMilliseconds",
    "setStatusLoading",
    "isExpired",
    "splice",
    "optAdditionalOptions",
    "setMonth",
    "liveStatusRemovedSync",
    "addExchange",
    "removeLiveStatus",
    "watchlist",
    "unregister",
    "storeStrategy",
    "standard",
    "userExpired",
    "0_02",
    "Loading",
    "log",
    "Reported",
    "BINANCE-SRMUSDT",
    "reportedBugs",
    "payload",
    "Recently\x20Backtested",
    "lifetime",
    "contest@easycryptobot.com",
    "addRecentOptimization",
    "Running",
    "setItem",
    "subscriptionIsExpiring",
    "key",
    "opt",
    "strategy",
    "expireDate",
    "deleteOptimizations",
    "selectedContestRankingPage",
    "assets",
    "exchange",
    "creationDate",
    "map",
    "stoploss",
    "isAdmin",
    "find",
    "importStrategy",
    "filter",
    "target",
    "simDetails",
    "includes",
    "getSeconds",
    "page",
    "2532yIYMyp",
    "demo",
    "logout",
    "trading",
    "selectedContest",
    "storeLiveStrategyStatus",
  ];
  a0_0x40c6 = function () {
    return _0x528029;
  };
  return a0_0x40c6();
}
