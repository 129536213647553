import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";

const RadioButtons = (props) => {
  return (
    <>
      {props.options.map((option) => {
        return (
          <div
            key={option.id ? option.id : option.text}
            className={`${option.hidden ? "d-none" : "d-inline-block"} mr-3 text-nowrap ${option.optionClasses} ${
              option.disabled ? "text-gray" : ""
            }`}
            checked={option.checked}
            onClick={(e) => {
              e.preventDefault();
              //document.activeElement.blur();
              if (!option.disabled) {
                option.check();
              }
            }}
          >
            {option.disabled ? (
              <FontAwesomeIcon className="text-gray" icon={faCircle} />
            ) : option.checked ? (
              <FontAwesomeIcon className={option.checkedColor} icon={faArrowAltCircleRight} />
            ) : (
              <FontAwesomeIcon className={option.uncheckedColor} icon={faCircle} />
            )}{" "}
            {option.text}
          </div>
        );
      })}
    </>
  );
};

export { RadioButtons };
